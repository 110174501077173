import React, { useEffect, useState } from 'react';
import SettingLeftNavigation from '../../setting/components/SettingLeftNavigation';
import language from '../../../helpers/language';
import REACT_APP_API from '../../../config/environment';
import auth from '../../../helpers/auth';
import { useHistory } from 'react-router-dom';

export default function ChangeEmail() {
  const [lang] = useState(language.getLang());
  const [password, setPassword] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();

  useEffect(async () => {}, []);

  const confirmPassword = async (e) => {
    e.preventDefault();
    let token = await auth.getAccessToken();
    let obj = { password: password };
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/confirm/password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          locale: language.getLang()?.lan,
        },
        body: JSON.stringify(obj),
      }
    );
    let response = await result.json();
    if (response.success === 200 && response.status == true) {
      history.push(`/reset-email`);
    } else if (response.success == 422) {
      setError(response.errors);
    } else {
      setError(response.message);
    }
  };
  return (
    <div className="home__page b2b settings">
      <div className="servics_blocks">
        <div className="site-align top-align">
          <SettingLeftNavigation />
          <div className="servics_blocks middle-long__section-block middleBlockBg">
            <div className="medium_title">
              <h1 className="font-18 text-black">{lang?.change_email}</h1>
            </div>
            <div className="md:w-3/4 mt-6">
              <form onSubmit={(e) => confirmPassword(e)}>
                <h4 className="font-16 text-normal mb-4">
                  {lang?.change_password_text}
                </h4>
                <div className="md:w-2/4">
                  <div className="form__item">
                    <label className="font-16 text-normal">
                      {lang?.Current_Password}
                    </label>
                    <div className="relative">
                      <span
                        class="eye_show"
                        onClick={() => setPasswordShown(!passwordShown)}
                        style={{ cursor: 'pointer' }}
                      >
                        <i class={passwordShown ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i>
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        placeholder={lang?.Current_Password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="error">
                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                </div>
                <div className="btnBox">
                  <button type="submit" className="btn btnPrimary">
                    {lang?.only_Submit}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
