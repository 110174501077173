import React from 'react'
import { Link } from 'react-router-dom'

export default function NotificationListing() {
    return (
        <>
            <div className="product-title">
                <div className="flex flex-wrap justify-between items-center">
                    <h4 className="mb-0">
                        Notifications
                    </h4>
                </div>
            </div>
            <div className="product-list notification_list">
                <div className='recentOrder secBlockGap'>
                    <div className='itemTable'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Notification</th>
                                    <th><div className='notificationDate'>Date & Time</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Purchase made by Customer name at store name of Product Name $99.00</td>
                                    <td>21/06/2023 18:37 PM</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Purchase made by Customer name at store name of Product Name $99.00</td>
                                    <td>21/06/2023 18:37 PM</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
