import React, { useEffect, useState } from "react";
import SettingLeftNavigation from "./SettingLeftNavigation";
import language from "../../../helpers/language";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import {
  getSubscription,
  subscriptionHandler,
  getFreeSubscription,
  CancelSubscription,
  subscriptionStatically,
} from "../module/UserSetting";
import REACT_APP_API from "../../../config/environment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkSubscription } from "../../home/modules/HomeModule";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import moment from "moment";

export default function Subscription() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const session_id = params.get("session_id");
  const dispatch = useDispatch();
  const { subscriptionData, subscriptionPaymentStatus, userSettingSpinner } =
    useSelector((state) => state.userSettingState);
  const { get_Subscription_Status_Data } = useSelector(
    (state) => state.activityFeedStatus
  );

  const { planExpireDate } = useSelector((state) => state.auth);

  const [lang] = useState(language.getLang());
  const [allBenefits, setallBenefits] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [redirectUrl, setRedirecturl] = useState();
  const [upgradeStatus, setUpgradestatus] = useState();
  const [plan, setPlan] = useState();
  const [plan_id, setPlan_id] = useState();
  const [isThank, setIsThank] = useState(false);
  const [cancel, setCancel] = useState();
  const [planName, setPlanName] = useState();

  console.log(planExpireDate, "planExpireDate");

  const currentPlainPrice =
    get_Subscription_Status_Data?.subscription?.subscription_plan?.price;

  console.log(get_Subscription_Status_Data, "plan");

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleThankYouModal = (bool, data = false) => {
    if (bool) {
      setIsThank(true);
    } else {
      setCancel(data);
      setIsThank(false);
    }
  };

  console.log(userSettingSpinner, "userSettingSpinner");

  const handleOpenModal = (data,current) => {
    console.log(current,"cuee")
    if(current !=='Current'){
      if (data) {
        setIsOpen(true);
        setCancel(data);
      } else {
        setIsOpen(true);
      }
    }
   

    // setRedirecturl(payment_url);
  };

  const cancelSubscriptionHandler = () => {
    dispatch(CancelSubscription());
    if (userSettingSpinner == false) {
      setCancelSubscription(false);
    }
  };

  useEffect(() => {
    // if(!get_Subscription_Status_Data){
    dispatch(getSubscription());
    // }
  }, []);

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem("USER_TYPE"));
    console.log(role.role_id, "out");
    if (role.role_id !== 3) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (session_id) {
      dispatch(subscriptionHandler(session_id));
    }
  }, [session_id]);

  useEffect(() => {
    dispatch(checkSubscription());
  }, []);

  const freeSubscription = async () => {
    dispatch(getFreeSubscription());
    if (userSettingSpinner == false) {
      setIsOpen(false);
    }
  };

  const handlerToggle = () => {
    setIsSwitchOn((prevState) => !prevState);
  };

  const handleSubscriptionStatically = async () => {
    let data = { plan_type: plan, subscription_id: plan_id };
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    let staically_Data = !cancel
      ? await dispatch(subscriptionStatically(formData))
      : await dispatch(CancelSubscription());
    if (cancel) {
      setIsOpen(false);
      handleThankYouModal(true);
      // setCancel('')
    }
    console.log(staically_Data, "stacll");
    if (staically_Data) {
      setIsOpen(false);
      handleThankYouModal(true);
      setCancel("");
    }
  };

  // console.log(plan_id,"plan ...  id")
  // console.log(plan,"plan")

  return (
    <>
      <div className={`home__page b2b hubMember`}>
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title">
                <h1 className="font-18 text-black abc">Your Subscription</h1>
              </div>
              {/* <div className="medium_title"><h1 className="font-18 text-black">{language?.Subscription}</h1></div> */}
              <div className="subscriptionPlans">
                <div className="flexBox planTopBlock">
                  <div className="planLeftBlock">
                    <div className="currentPlan">
                      <div className="flexBox">
                        {get_Subscription_Status_Data?.subscription ? (
                          <>
                            <p className="fs-18">Current Plan</p>
                            <p className="fs-18">
                              {`${get_Subscription_Status_Data?.subscription?.subscription_plan?.plan
                                .charAt(0)
                                .toUpperCase()}${get_Subscription_Status_Data?.subscription?.subscription_plan?.plan.slice(
                                1
                              )}`}
                            </p>
                          </>
                        ) : (
                          <p className="fs-18">No Plan</p>
                        )}
                      </div>
                      {get_Subscription_Status_Data?.subscription && (
                        <h3 className="planAmount boxTitle flex justify-between">
                          <span>
                            {
                              get_Subscription_Status_Data?.subscription
                                ?.subscription_plan?.name
                            }{" "}
                            Membership{" "}
                          </span>

                          <span className="font-bold">
                            $
                            {
                              get_Subscription_Status_Data?.subscription
                                ?.subscription_plan?.price
                            }
                          </span>
                        </h3>
                      )}
                    </div>

                    <div className="renewsPlan">
                      <p className="fs-13">
                        {`You have been granted the Diamond Membership at zero cost until ${
                          planExpireDate && planExpireDate
                            ? moment(planExpireDate).format("MMM Do YYYY")
                            : "June 30th 2025"
                        }`}
                        .
                        <br />
                        <br />
                        {`Please renew your Plan by ${
                          planExpireDate && planExpireDate
                            ? moment(planExpireDate).format("MMM Do YYYY")
                            : "June 30th 2025"
                        }.`}
                        {/* Plan renews on June 30, 2025 */}
                        {/* {moment(get_Subscription_Status_Data?.subscription?.end_date).format('MMMM Do, YYYY')} */}
                      </p>

                      {currentPlainPrice !== 0 && (
                        <button
                          type="button"
                          className="btn-blue fs-13 "
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "14px",
                          }}
                          onClick={
                            () => handleOpenModal(true)
                            // onClick={() =>handleThankYouModal(true,true)
                            // {setCancelSubscription(!cancelSubscription)}
                          }
                        >
                          Cancel Subscription
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="planRightBlock">
                    <p className="usageTitle fs-18">Plan Usages</p>
                    <div className="">
                      <div className="flexBox">
                        <p className="fs-13">
                          Products displayed in the B2B Marketplace Store
                        </p>
                        <div>
                          <div className="progressBar"></div>
                          <p className="usageAmt font-bold">
                            {get_Subscription_Status_Data?.product_count
                              ? get_Subscription_Status_Data?.product_count
                              : 0}
                            /{" "}
                            {get_Subscription_Status_Data?.subscription !== null
                              ? get_Subscription_Status_Data?.subscription
                                  ?.subscription_plan?.product_limit == -1
                                ? "Unlimited"
                                : get_Subscription_Status_Data?.subscription
                                    ?.subscription_plan?.product_limit
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="flexBox">
                        <p className="fs-13">Alysei Hubs Subscription</p>
                        <div>
                          <div className="progressBar"></div>
                          <p className="usageAmt font-bold">
                            {get_Subscription_Status_Data?.hub_count
                              ? get_Subscription_Status_Data?.hub_count
                              : 0}{" "}
                            /{" "}
                            {get_Subscription_Status_Data?.subscription !== null
                              ? get_Subscription_Status_Data?.subscription
                                  ?.subscription_plan?.hubs_subscription == -1
                                ? "Unlimited"
                                : get_Subscription_Status_Data?.subscription
                                    ?.subscription_plan?.hubs_subscription
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="flexBox">
                        <p className="fs-13">Buyer Sourcing Engine</p>
                        <div>
                          <div className="progressBar"></div>
                          <p className="usageAmt font-bold">
                            {get_Subscription_Status_Data?.buying_sourcing_engine
                              ? get_Subscription_Status_Data?.buying_sourcing_engine
                              : 0}{" "}
                            /{" "}
                            {get_Subscription_Status_Data?.subscription !== null
                              ? get_Subscription_Status_Data?.subscription
                                  ?.subscription_plan?.buyer_sourcing_engine ==
                                -1
                                ? "Unlimited"
                                : get_Subscription_Status_Data?.subscription
                                    ?.subscription_plan?.buyer_sourcing_engine
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div className="flexBox">
                        <p className="fs-13">B2B Connection with Importers</p>
                        <div>
                          <div className="progressBar"></div>
                          <p className="usageAmt font-bold">
                            {" "}
                            {get_Subscription_Status_Data?.connection_request
                              ? get_Subscription_Status_Data?.connection_request
                              : 0}
                            /{" "}
                            {get_Subscription_Status_Data?.subscription !== null
                              ? get_Subscription_Status_Data?.subscription
                                  ?.subscription_plan
                                  ?.b2b_connection_importer == -1
                                ? "Unlimited"
                                : get_Subscription_Status_Data?.subscription
                                    ?.subscription_plan?.b2b_connection_importer
                              : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="memberList planBottomBlock">
                  <p className="upgradeTitle font-bold">
                    Upgrade your Plan to get more out of your Alysei
                    subscription
                  </p>
                  <div className="flexBox w-full justify-center m-2">
                    {/* <p>Choose Your Plan </p> */}
                    <div className="taoggle_switch flexBox ">
                      <label className={` font-bold mr-3`}> Monthly</label>
                      <div className="gridColRight text-right">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={handlerToggle}
                            checked={isSwitchOn}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <label className={` font-bold ml-3`}>Yearly</label>
                    </div>
                  </div>
                  <div className="cardList grid4">
                    {!isSwitchOn
                      ? subscriptionData?.monthly_plans?.map((data, i) => {
                        let currentPlain=data.name ==
                        get_Subscription_Status_Data
                          ?.subscription?.subscription_plan
                          ?.name
                        && "Current"
                          return (
                            <div className='subscriptionCard' key={i}>
                              <p>{data.name} Membership</p>
                              <h4>
                                {/* {subscriptionData?.monthly_plans}$ */}
                                ${parseFloat(data.price).toFixed(2)}
                                {data.name == "Free"
                                  ? ""
                                  : `/${data.plan.slice(0, 2)}`}
                              </h4>
                              <div className='memberButton pricingTableRow'>
                                {
                                  data.price ==
                                  get_Subscription_Status_Data?.subscription
                                    ?.subscription_plan?.price ? (
                                    <Link to='/subscription'>
                                      <button
                                        onClick={() => {
                                          setPlan_id(
                                            data?.subscription_plan_id
                                          );
                                          setPlan(data?.plan);
                                          setPlanName(data?.name);
                                        }}
                                        type='button'
                                        className={`btn btn2 ${
                                          data.price ==
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.price && "btn3"
                                        } `}
                                      >
                                        {data.name ==
                                        get_Subscription_Status_Data
                                          ?.subscription?.subscription_plan
                                          ?.name ? (
                                          userSettingSpinner ? (
                                            <FormSpinner />
                                          ) : (
                                            "Current"
                                          )
                                        ) : currentPlainPrice ? (
                                          data.price >
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.price ? (
                                            "Upgrade"
                                          ) : (
                                            "Downgrade"
                                          )
                                        ) : (
                                          "Upgrade"
                                        )}
                                      </button>
                                    </Link>
                                  ) : data.name == "Free" ? (
                                    <div
                                      onClick={() => {
                                        // handleOpenModal("free");
                                        handleOpenModal(false,currentPlain);
                                        setPlanName(data?.name);
                                        setPlan_id(data?.subscription_plan_id);
                                        setPlan(data?.plan);
                                        data.price > currentPlainPrice
                                          ? setUpgradestatus("Upgrade")
                                          : setUpgradestatus("Downgrade");
                                      }}
                                    >
                                      <button
                                        type='button'
                                        className={`btn btn2 ${
                                          data.name ==
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.name && "btn3"
                                        } `}
                                      >
                                        {currentPlainPrice
                                          ? data.name ==
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.name
                                            ? "Current"
                                            : data.price >
                                              get_Subscription_Status_Data
                                                ?.subscription
                                                ?.subscription_plan?.price
                                            ? "Upgrade"
                                            : "Downgrade"
                                          : "Upgrade"}
                                      </button>
                                    </div>
                                  ) : (
                                    // <a href={data.payment_link}>
                                    <div
                                      onClick={() => {
                                        // handleOpenModal(data.payment_link);
                                        handleOpenModal(false,currentPlain);
                                        setPlan_id(data?.subscription_plan_id);
                                        setPlan(data?.plan);
                                        setPlanName(data?.name);
                                        data.price > currentPlainPrice
                                          ? setUpgradestatus("Upgrade")
                                          : setUpgradestatus("Downgrade");
                                      }}
                                    >
                                      <button
                                        type='button'
                                        className={`btn btn2 ${
                                          data.name ==
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.name && "btn3"
                                        } `}
                                      >
                                        {currentPlainPrice
                                          ? data.name ==
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.name
                                            ? "Current"
                                            : data.price >
                                              get_Subscription_Status_Data
                                                ?.subscription
                                                ?.subscription_plan?.price
                                            ? "Upgrade"
                                            : "Downgrade"
                                          : "Upgrade"}
                                      </button>
                                    </div>

                                    // </a>
                                  )

                                  // )
                                }
                              </div>
                            </div>
                          );
                        })
                      : subscriptionData?.yearly_plans?.map((data, i) => {
                          return (
                            <div className="subscriptionCard" key={i}>
                              <p>{data.name} Membership</p>
                              <h4>
                                ${parseFloat(data.price).toFixed(2)}
                                {data.name == "Free"
                                  ? ""
                                  : `/${data.plan.slice(0, 4)}`}
                              </h4>
                              <div className="memberButton pricingTableRow">
                                {data.price ==
                                get_Subscription_Status_Data?.subscription
                                  ?.subscription_plan?.price ? (
                                  <Link to="/subscription">
                                    <button
                                      onClick={() => {
                                        setPlan_id(data?.subscription_plan_id);
                                        setPlan(data?.plan);
                                          setPlanName(data?.name);
                                          // setPrice(data?.price)
                                      }}
                                      type="button"
                                      className={`btn btn2 ${
                                        data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name && "btn3"
                                      } `}
                                    >
                                      {data.name ==
                                      get_Subscription_Status_Data?.subscription
                                        ?.subscription_plan?.name
                                        ? "Current"
                                        : data.price >
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.price
                                        ? "Upgrade"
                                        : "Downgrade"}
                                    </button>
                                  </Link>
                                ) : data.name == "Free" ? (
                                  <div
                                    onClick={() => {
                                      // handleOpenModal("free");
                                      handleOpenModal(false);
                                      setPlan_id(data?.subscription_plan_id);
                                      setPlan(data?.plan);
                                      setPlanName(data?.name);
                                      data.price > currentPlainPrice
                                        ? setUpgradestatus("Upgrade")
                                        : setUpgradestatus("Downgrade");
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn btn2 ${
                                        data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name && "btn3"
                                      } `}
                                    >
                                      {currentPlainPrice
                                        ? data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name
                                          ? "Current"
                                          : data.price >
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.price
                                          ? "Upgrade"
                                          : "Downgrade"
                                        : "Upgrade"}
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      handleOpenModal(false);
                                      // handleOpenModal(data.payment_link);
                                      setPlan_id(data?.subscription_plan_id);
                                      setPlan(data?.plan);
                                      setPlanName(data?.name);
                                      data.price > currentPlainPrice
                                        ? setUpgradestatus("Upgrade")
                                        : setUpgradestatus("Downgrade");
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className={`btn btn2 ${
                                        data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name && "btn3"
                                      } `}
                                    >
                                      {currentPlainPrice
                                        ? data.name ==
                                          get_Subscription_Status_Data
                                            ?.subscription?.subscription_plan
                                            ?.name
                                          ? "Current"
                                          : data.price >
                                            get_Subscription_Status_Data
                                              ?.subscription?.subscription_plan
                                              ?.price
                                          ? "Upgrade"
                                          : "Downgrade"
                                        : "Upgrade"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  <div className="grid4 ">
                    <div>
                      <ul className="benefit-list">
                        {subscriptionData?.monthly_plans?.map((data, i) => {
                          return (
                            data.name == "Free" && (
                              <>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Company
                                  Profile and On-Line Store
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>
                                  Subscription up to {
                                    data?.hubs_subscription
                                  }{" "}
                                  Hubs
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Up to{" "}
                                  {data?.b2b_connection_importer} connections
                                  with Importers and Distributors
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Unlimited
                                  connection with all other Alysei Member
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Up to{" "}
                                  {data?.product_limit} Products in the B2B
                                  Marketplace
                                </li>
                              </>
                            )
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <ul className="benefit-list">
                        {subscriptionData?.monthly_plans?.map((data, i) => {
                          return (
                            data.name == "Gold" && (
                              <>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Up to{" "}
                                  {data.buyer_sourcing_engine} Buyer Sourcing
                                  Engine Leads per month
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>
                                  Subscription up to {
                                    data?.hubs_subscription
                                  }{" "}
                                  Hubs
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Up to{" "}
                                  {data?.b2b_connection_importer} connections
                                  per month with Importers and Distributors
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Unlimited
                                  connection with all other Alysei Members
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Up to{" "}
                                  {data?.product_limit} Products in the B2B
                                  Marketplace
                                </li>
                              </>
                            )
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <ul className=" benefit-list">
                        {subscriptionData?.monthly_plans?.map((data, i) => {
                          return (
                            data.name == "Platinum" && (
                              <>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Up to{" "}
                                  {data.buyer_sourcing_engine == -1
                                    ? "Unlimited"
                                    : data.buyer_sourcing_engine}{" "}
                                  Buyer Sourcing Engine Leads per month
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>
                                  Subscription up to{" "}
                                  {data?.hubs_subscription == -1
                                    ? "Unlimited"
                                    : data?.hubs_subscription}{" "}
                                  Hubs
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Unlimited
                                  connections with all Members
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>
                                  {data?.product_limit == -1
                                    ? "Unlimited"
                                    : data?.product_limit}{" "}
                                  Products in the B2B Marketplace
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>1 Alysei
                                  Academy Demo per month
                                </li>
                              </>
                            )
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <ul className=" benefit-list">
                        {subscriptionData?.monthly_plans?.map((data, i) => {
                          return (
                            data.name == "Diamond" && (
                              <>
                                <li>
                                  <i className="fas fa-check mr-2"></i>
                                  {data.buyer_sourcing_engine == -1
                                    ? "Unlimited"
                                    : data.buyer_sourcing_engine}{" "}
                                  Buyer Sourcing Engine Leads
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Access to
                                  Members contact information
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>Unlimited
                                  connections with all Members
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>
                                  {data?.product_limit == -1
                                    ? "Unlimited"
                                    : data?.product_limit}{" "}
                                  Products in the B2B Marketplace
                                </li>
                                <li>
                                  <i className="fas fa-check mr-2"></i>2 Alysei
                                  Academy Demo per month
                                </li>
                              </>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="memberList planBottomBlock">
                  <div className="cardList grid4"></div>

                  <div className="memberButton subscription_Benefit">
                    <Link to="/subscription-modal">
                      <button type="button" className="btn">
                        See All Benefits
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal
          className="fullWidthModal benefitModal"
          ariaHideApp={false}
          isOpen={allBenefits}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              padding: "0",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center">
            <a
              onClick={() => setallBenefits(false)}
              className="close_btn--modal"
            >
              {lang?.close}
            </a>
          </div>
        </Modal>

        {/* Cancel Subscription */}

        {/* Modal */}
        <Modal
          isOpen={cancelSubscription}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
              position: "absolute",
              top: "0",
              left: "0%",
              right: "0%",
              bottom: "200px",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              padding: "0",
              border: "0",
              borderRadius: "8px",
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center popupheaderBg">
            <h4 className="page__title">Cancel Subscription</h4>
            <button
              className="close_btn--modal"
              onClick={() => handleThankYouModal(true)}
              // onClick={() => setCancelSubscription(!cancelSubscription)}
            >
              {lang?.close}
            </button>
          </div>
          <div className="card card--block modal_box">
            <p className="warning">
              Are you sure you want to cancel your subscription?
            </p>
            <div className="right__btn text-right mt-4">
              <Link
                className="btn btn1"
                to="#"
                onClick={() => setCancelSubscription()}
              >
                Back
              </Link>
              <Link
                className="btn btn1 btn2 ml-3"
                to="#"
                onClick={() => {
                  cancelSubscriptionHandler();
                  handleThankYouModal(true);
                }}
              >
                Cancel
              </Link>
            </div>
          </div>
        </Modal>

        {/*subscription warnning modal*/}
        <Modal
          isOpen={isOpen}
          // onRequestClose={handleCloseModal}
          contentLabel="Upgrade Modal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          
          <span
            className="absolute top-2 right-2 text-base"
            onClick={() => handleCloseModal(false)}
          >
            <i className="fa fa-times cursor-pointer"></i>
          </span>
          <div className="w-16 m-auto mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 47.5 47.5"
              viewBox="0 0 47.5 47.5"
              id="diamond"
            >
              <path
                fill="#bdddf4"
                d="M0 0h-6l-7-9h10l3 9z"
                transform="matrix(1.25 0 0 -1.25 17.5 5)"
              ></path>
              <path
                fill="#5dadec"
                d="m0 0-7 9h-6l3-9H0z"
                transform="matrix(1.25 0 0 -1.25 46.25 16.25)"
              ></path>
              <path
                fill="#4289c1"
                d="M0 0h10L-8-21 0 0Z"
                transform="matrix(1.25 0 0 -1.25 33.75 16.25)"
              ></path>
              <path
                fill="#8ccaf7"
                d="M0 0h-10L8-21 0 0Z"
                transform="matrix(1.25 0 0 -1.25 13.75 16.25)"
              ></path>
              <path
                fill="#8ccaf7"
                d="m0 0-3-9h16l-3 9H0Z"
                transform="matrix(1.25 0 0 -1.25 17.5 5)"
              ></path>
              <path
                fill="#5dadec"
                d="m0 0-8 21H8L0 0Z"
                transform="matrix(1.25 0 0 -1.25 23.75 42.5)"
              ></path>
            </svg>
          </div>
          <h2 className="text-2xl font-bold mb-4  ">
            {/* {currentPlainPrice ? upgradeStatus : "Upgrade"} Your Plan */}
            {cancel ? "Cancel Subscription" : "Downgrade Your Plan"}
          </h2>

          <p>
            <p>
              Are you sure you want to {cancel ? "cancel" : "Downgrade"} your
              Subscription?
            </p>
            <br />
            <span className=" text-black">
              {/* Are you sure you want to {currentPlainPrice ? upgradeStatus : "Upgrade"} your Current Plan? */}
              {cancel
                ? `

                  Your subscription will be cancelled upon expiration of the current Plan on ${
                    planExpireDate && planExpireDate
                      ? moment(planExpireDate).format("MMM Do YYYY")
                      : "June 30th 2025"
                  }. 
                  Until that date, you will be able to take advantage of the benefits of the current Plan.`
                : `
                 
                  
                  The new Membership will be activated upon expiration of the current Plan on ${
                    planExpireDate && planExpireDate
                      ? moment(planExpireDate).format("MMM Do YYYY")
                      : "June 30th 2025"
                  }. 
                  Until that date, you will be able to take advantage of the benefits of the current Plan.`}
            </span>
          </p>
          {redirectUrl == "free" ? (
            // <div onClick={() => freeSubscription()}>
            <div
             
            >
              <button className="btn btn-blue stripe text-white font-bold mt-6"  onClick={() => {
                handleSubscriptionStatically();
              }}>
                {userSettingSpinner ? <FormSpinner /> : "Ok"}
              </button>
            </div>
          ) : (
            <div
              
            >
              <button className="btn btn-blue stripe text-white font-bold mt-6" onClick={() => {
                handleSubscriptionStatically();
              }}>
                {
                  // cancel?(
                  //   "Ok"
                  // ):(
                  //   "Ok"
                  // )
                  userSettingSpinner ? <FormSpinner /> : "Ok"
                }
              </button>
            </div>
          )}
        </Modal>
        {/*subscription confirm modal*/}
        <Modal
          isOpen={isThank}
          // onRequestClose={handleThankYouModal(false)}
          contentLabel="Confirm Modal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <div className="w-25 m-auto mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="96"
              height="96"
              viewBox="0 0 48 48"
            >
              <linearGradient
                id="IMoH7gpu5un5Dx2vID39Ra_pIPl8tqh3igN_gr1"
                x1="9.858"
                x2="38.142"
                y1="9.858"
                y2="38.142"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#9dffce"></stop>
                <stop offset="1" stop-color="#50d18d"></stop>
              </linearGradient>
              <path
                fill="url(#IMoH7gpu5un5Dx2vID39Ra_pIPl8tqh3igN_gr1)"
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              ></path>
              <linearGradient
                id="IMoH7gpu5un5Dx2vID39Rb_pIPl8tqh3igN_gr2"
                x1="13"
                x2="36"
                y1="24.793"
                y2="24.793"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".824" stop-color="#135d36"></stop>
                <stop offset=".931" stop-color="#125933"></stop>
                <stop offset="1" stop-color="#11522f"></stop>
              </linearGradient>
              <path
                fill="url(#IMoH7gpu5un5Dx2vID39Rb_pIPl8tqh3igN_gr2)"
                d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414	c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414	c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z"
              ></path>
            </svg>
          </div>
          <h2 className="text-2xl font-bold mb-4  ">{/* ThankYou  */}</h2>

          <p>
            <span className=" text-black">
              Your Request has been accepted successfully.
              <br />
              <br />
              {cancel
                ? ` You have requested to cancel your Plan which will be cancel upon expiration of you current Plan"`
                : `You have requested to downgrade your Plan to ${planName} which will be activated upon expiration of your current Plan.`}
              {/* Your Plan has been {currentPlainPrice ? upgradeStatus : "Upgrade"} successfully */}
              {/* ${moment(get_Subscription_Status_Data?.subscription?.end_date).format('DD MMMM YYYY')} */}
              {
                // "Your request has been accepted successfully"
                // `Your request has been accepted successfully.
                // You have requested to downgrade your Plan to ${planName} which will be activated upon expiration of your current Plan.
                // `
                // `You have requested to downgrade your Plan to ${planName} which will be activated upon expiration of your current Plan.`
                // cancel?(
                //   `You will be able to cancel your Membership 30 days prior to the expiration of the current Plan which is set to expire on June 30, 2025`
                // ):(
                //   "Your request has been accepted successfully."
                // )
              }
            </span>
          </p>
          <button
            className="btn btn-blue stripe text-white font-bold mt-6"
            onClick={() => handleThankYouModal(false)}
          >
            Close
          </button>
        </Modal>
      </div>
    </>
  );
}
