import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import constant from "../../../helpers/constant";
import NetworkLeftNavigation from "./NetworkLeftNavigation";
import { fetchConnectionTabs, acceptRejectRequest, getConnectionTabsData } from "../modules/networkConnection";
import NotFound from "../../../components/NetworkConnection/NotFound";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ReactPaginate from 'react-paginate';
import ConnectionModal from '../../../components/ConnectionModals/ConnectionModal';
import MemberType from '../../../components/MemeberType';
import language from '../../../helpers/language';
import ContentLoader from 'react-content-loader'

export default function Connection() {
    const [lang] = useState(language.getLang());
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [errorText, setErrorText] = useState('');
    const [openConnectionModel, setOpenConnectionModel] = useState(false);
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [isTrue, setIsTrue] = useState(false);

    useEffect(async () => {
        await dispatch(fetchConnectionTabs(constant.tabs.connection, page));
        setIsTrue(true);
    }, []);

    const { connectionSpinner, connectionTabs } = useSelector((state) => state.networkConnectionState);

    const removeRequest = async (items) => {
        // await setUserId(item?.connection_id);
        // await setUserName(item?.user?.company_name ?? item?.user?.restaurant_name);
        // await setOpenConnectionModel(true);
        const response = await dispatch(acceptRejectRequest({ connection_id: parseInt(items?.connection_id), accept_or_reject: 2 }));
        if (response.success === 200) {
            const newdata = connectionTabs.data?.data?.filter(item => item.connection_id !== parseInt(items?.connection_id));
            const finalValue = {
                ...connectionTabs,
                data: { ...connectionTabs.data, data: newdata }
            }
            await dispatch(getConnectionTabsData(finalValue));
        } else {
            setErrorText(response?.errors?.exception)
        }
        // await dispatch(fetchConnectionTabs(constant.tabs.pending));
    }

    const handlePageClick = async (e) => {
        let pageNo = e.selected + 1
        await setPage(page);
        await dispatch(fetchConnectionTabs(constant.tabs.connection, pageNo));
    };
    const handleModal = (newValue) => {
        setOpenConnectionModel(newValue);
    }
    return (
        <div className="home__page b2b hubMember">
            {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, network: true, from: "Remove", userData: connectionTabs }} />}
            <div className="servics_blocks">
                <div className="site-align top-align">
                    {/* Left Block */}
                    <NetworkLeftNavigation />
                    {/* Middle Block */}
                    <div className="servics_blocks middle-long__section-block middleBlockBg">
                        <div className="">
                            {connectionTabs?.errors || connectionTabs?.data?.data?.length === 0 ?
                                <Fragment><div className="medium_title"><h1 className="font-18 text-black">{lang?.Connections}</h1></div><NotFound errors={connectionTabs?.data?.data?.length === 0 ? `${lang?.No_connection_found_right}` : `${lang?.No_connection_found_right}`} /></Fragment>
                                : <div className="medium_title"><h1 className="font-18 text-black">{lang?.Connections}</h1></div>
                            }
                            <p style={{ color: "red" }}>{errorText}</p>
                            {connectionSpinner ? <FormSpinner /> :
                                <div className="card--section site-align top-align flex-wrap">
                                    {connectionTabs?.data?.data.length > 0 && connectionTabs?.data?.data?.map((item, index) => {
                                        return (
                                            <div className="card card--block" key={index} >
                                                <div className="hubUser">
                                                    <div className="feed-user-info mb-2">
                                                        <div className="user__pic" onClick={() => history.push(`${"/visitor-profile/"}${item.user && item.user.user_id}`)}>
                                                            {item && item.user && item.user.avatar_id ?
                                                                <img src={`${item.user.avatar_id.base_url}${item.user.avatar_id.attachment_large_url}`} alt="User Image" /> :
                                                                {/* <ContentLoader viewBox="0 0 380 70">
                                                                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader> */}
                                                            }
                                                        </div>
                                                        <div className="user__detail-data">
                                                            <span className="user__name">
                                                            {item && item.user ? 
                                                                <Link to={`${"/visitor-profile/"}${item?.user?.user_id}`}>
                                                                    {(item && item.user && item.user.first_name !== null && item && item.user && item.user.last_name !== null &&
                                                                        `${item && item.user && item.user.first_name} ${item && item.user && item.user.last_name}`) ||
                                                                        (item && item.user && item.user.company_name) || (item && item.user && item.user.restaurant_name)}
                                                                </Link>
                                                                :
                                                                {/* <ContentLoader viewBox="0 0 380 70">
                                                                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader> */}
                                                            }
                                                            </span>
                                                            {item && item.user ? 
                                                            <MemberType item={item?.user} />
                                                            :
                                                            {/* <ContentLoader viewBox="0 0 380 70">
                                                            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                            </ContentLoader> */}
                                                            }
                                                            <span className="text-blue">
                                                                {/* {item && item.user.followers_count} {" "}Followers */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="viewReq mt-4 ml-2">
                                                    {item && item.user ? 
                                                        <Link to="#" className="btn bg-Primary w-full" onClick={() => removeRequest(item)}>{lang?.only_Remove}</Link>
                                                        :
                                                        {/* <ContentLoader viewBox="0 0 380 70">
                                                        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                        </ContentLoader> */}
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            }
                        </div>
                        {isTrue && connectionTabs?.data?.total > 10 &&
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil((connectionTabs?.data?.total) / (connectionTabs?.data?.per_page))}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"} />}
                    </div>
                    {/* Right Block */}
                </div>
            </div>
        </div>
    )
}
