import React from 'react'
import nodataimg from '../../../../assets/images/no_data_icon.svg'

export default function NoRequestFound() {
  return (
    <>
        <div className='noDataBlock text-center'>
            <div className='Icon'>
                <img className="full-width" src={nodataimg} alt="No Data Found" />
            </div>
            <h4 className='fs-20 mt-2 font-bold'>No request created yet</h4>
        </div>
    </>
  )
}
