import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import auth from '../../../helpers/auth';
import constant from "../../../helpers/constant";
import conninactive from '../../../assets/images/connections-inactive.svg'
import connactive from '../../../assets/images/connections-active.svg'
import invitationinactive from '../../../assets/images/invitations-inactive.svg'
import invitationactive from '../../../assets/images/invitations-active.svg'
import pendinginactive from '../../../assets/images/pending-inactive.svg'
import pendingactive from '../../../assets/images/pending-active.svg'
import followersinactive from '../../../assets/images/followers-inactive.svg'
import followersactive from '../../../assets/images/followers-active.svg'
import language from "../../../helpers/language";
import { getConnectionTabsData } from "../modules/networkConnection";
import { useDispatch } from "react-redux";
export default function NetworkLeftNavigation() {
    const [lang] = useState(language.getLang());
    const [userType, setuserType] = useState()
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(async () => {
        const userType = await auth.getUserType()
        await setuserType(JSON.parse(userType))
        
    }, []);

    const setEmptyConnectionTabsData =async()=> await dispatch(getConnectionTabsData({}));

    return (
        <div className="left__section-block sm:mb-2 md:mb-2">
            <div className="card card--block">
                <div className="hubsTitle">
                    <h4 className="font-18">{lang?.Network}</h4>
                </div>
                <div className="card-form p-3">
                    <div className="leftNavigation">
                        <ul>
                            <li className={location.pathname === "/networking" ? "activeNav" : ""} onClick={()=>location.pathname !== "/networking" &&setEmptyConnectionTabsData()}>
                                <Link to="/networking" >
                                    <span className="navIcon disable_icon">
                                        <img src={invitationinactive} alt="Invitations Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={invitationactive} alt="Invitations Active" />
                                    </span>
                                    <span className="navText">{lang?.Invitations}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li className={location.pathname === "/connection" ? "activeNav" : ""} onClick={()=>location.pathname !== "/connection" &&setEmptyConnectionTabsData()}>
                                <Link to="/connection" >
                                    <span className="navIcon disable_icon">
                                        <img src={conninactive} alt="Connection Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={connactive} alt="Connection Active" />
                                    </span>
                                    <span className="navText">{lang?.Connections}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li className={location.pathname === "/pending" ? "activeNav" : ""} onClick={()=>location.pathname !== "/pending" && setEmptyConnectionTabsData()}>
                                <Link to="/pending" >
                                    <span className="navIcon disable_icon">
                                        <img src={pendinginactive} alt="Pending Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={pendingactive} alt="Pending Active" />
                                    </span>
                                    <span className="navText">{lang?.Pending}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                            <li className={location.pathname === "/following" ? "activeNav" : ""} onClick={()=>location.pathname !== "/following" && setEmptyConnectionTabsData()}>
                                <Link to="/following">
                                    <span className="navIcon disable_icon">
                                        <img src={followersinactive} alt="Followers Inactive" />
                                    </span>
                                    <span className="navIcon enable_icon">
                                        <img src={followersactive} alt="Followers Active" />
                                    </span>
                                    <span className="navText">{userType && userType.role_id === constant.rolesId.voyagers ? `${lang?.Following}` : `${lang?.Followers}`}
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
