import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import SettingLeftNavigation from './SettingLeftNavigation'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import language from '../../../helpers/language';

export default function SettingYourData() {
  const [lang] = useState(language.getLang());
  useEffect(() =>{
    //window.fcWidget.show() 
  },[]);
    return (
        <div>
          {/* Layout Content */}
          <div className="home__page b2b hubMember settings">
            <div className="servics_blocks">
                <div className="site-align top-align">
                  <SettingLeftNavigation />
                  <div className="servics_blocks middle-long__section-block middleBlockBg">
                    <div className="medium_title"><h1 className="font-18 text-black">{lang?.Your_Data}</h1></div>
                      <div className='md:w-3/4 mt-6'>
                        <h4 className='font-16 mt-4'>{lang?.Account_Data_Download}</h4>
                        <p className='mt-4 mb-6'>{lang?.Nothing_requested_yet}</p>
                        <div className='btnBox'>
                          <Link to="#" className='btn btnPrimary'>{lang?.Request_Data}</Link>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      );
}
