
import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment"
import headerType from "../../../helpers/headerType";
export const RECIPE_DIET = "RECIPE_DIET";
export const RECIPE_SELECT_COURSE = "RECIPE_SELECT_COURSE";
export const SELECT_FOOD_INTROLERANCE = "SELECT_FOOD_INTROLERANCE";
export const SELECT_COOKING_SKILL = "SELECT_COOKING_SKILL";
export const SELECT_REGION = "SELECT_REGION";
export const SELECT_CUISINE = "SELECT_CUISINE";
export const SELECTMEAL = "SELECTMEAL";
export const ADDLIST = "ADDLIST";
export const RECIPEGENRALINFORMATION = "RECIPEGENRALINFORMATION";
export const RECIPETOOLS = "RECIPETOOLS";
export const GET_ERROR_MSG = "GET_ERROR_MSG";
export const RECIPE_STEPS = "RECIPE_STEPS";
export const RECIPE_GET_PREFERENCES = "RECIPE_GET_PREFERENCES";
export const RECIPE_GET_PREFERENCES_INGREDIENT = "RECIPE_GET_PREFERENCES_INGREDIENT";
export const RECIPE_GET_EXPLORE = "RECIPE_GET_EXPLORE";
export const SELECT_INGREDIENTS = "SELECT_INGREDIENTS";
export const SELECTED_INGREDIENTS = "SELECTED_INGREDIENTS";
export const SELECTED_TOOLS = "SELECTED_TOOLS";
export const MY_ALL_RECIPE_DATA = "MY_ALL_RECIPE_DATA";
export const SUBMIT_POST_STEP_DATA = "SUBMIT_POST_STEP_DATA";
export const SEARCH_INGREDIENTS = "SEARCH_INGREDIENTS";
export const TOOL_TEMP_ID = "TOOL_TEMP_ID";
export const SET_EXPLORE_PERCENTAGE_SPINNER_STATUS = "SET_EXPLORE_PERCENTAGE_SPINNER_STATUS";
export const SET_MY_ALL_RECIPE_SPINNER_STATUS = "SET_MY_ALL_RECIPE_SPINNER_STATUS";
export const SET_MY_PREFERENCES_SPINNER_STATUS = "SET_MY_PREFERENCES_SPINNER_STATUS";
export const SINGLE_PAGE_RECIPE_DATA = "SINGLE_PAGE_RECIPE_DATA";
export const fAVOURITE_RECIPE_DATA = "fAVOURITE_RECIPE_DATA"
export const LIKE_RECIPE = "LIKE_RECIPE"
export const SEARCH_RECIPE_TOOL = "SEARCH_RECIPE_TOOL"
export const TAB_INDEX = "TAB_INDEX";
export const INGREDIENT_SPINNER = "INGREDIENT_SPINNER";
export const SET_TOOL_SPINNER = "SET_TOOL_SPINNER";
export const REVIEW_GET = "REVIEW_GET";
export const EDIT_BTN = "EDIT_BTN"
export const IS_EDIT_ING = "IS_EDIT_ING"





export function fetchDietData(data) {
  return {
    type: RECIPE_DIET,
    payload: data,
  };

}
export function reviewFun(data) {
  return {
    type: REVIEW_GET,
    payload: data,
  };

}
export function tabIndexFun(data) {
  return {
    type: TAB_INDEX,
    payload: data,
  };

}

export function searchRecipeToolData(data) {
  return {
    type: SEARCH_RECIPE_TOOL,
    payload: data,
  };
}
export function likeRecipe(data) {
  return {
    type: LIKE_RECIPE,
    payload: data,
  };
}
export function editButton(data) {

  return {
    type: EDIT_BTN,
    payload: data,
  };
}
export function isEditButton(data) {

  return {
    type: IS_EDIT_ING,
    payload: data,
  };
}

export function favouriteRecipeDataFun(data) {
  return {
    type: fAVOURITE_RECIPE_DATA,
    payload: data
  }
}
export function ToolTempId(data) {
  return {
    type: TOOL_TEMP_ID,
    payload: data,
  };
}
export function searchingIngredients(data) {
  return {
    type: SEARCH_INGREDIENTS,
    payload: data,
  };
}
export function selectedTool(data) {
  return {
    type: SELECTED_TOOLS,
    payload: data,
  };
}
export function setEXplorePercentageSpiner(flag) {
  return {
    type: SET_EXPLORE_PERCENTAGE_SPINNER_STATUS,
    payload: flag,
  };
}
export function setIngredientSpiner(flag) {
  return {
    type: INGREDIENT_SPINNER,
    payload: flag,
  };
}

export function setToolSpiner(flag) {

  return {
    type: SET_TOOL_SPINNER,
    payload: flag,
  };
}
export function setRecipePercentageSpiner(flag) {
  return {
    type: SET_MY_ALL_RECIPE_SPINNER_STATUS,
    payload: flag,
  };
}

export function setMyPreferencesPercentageSpiner(flag) {
  return {
    type: SET_MY_PREFERENCES_SPINNER_STATUS,
    payload: flag,
  };
}
export function selectedIngredients(data) {
  return {
    type: SELECTED_INGREDIENTS,
    payload: data,
  };
}
export function fetcPreferenceData(data) {
  return {
    type: RECIPE_GET_PREFERENCES,
    payload: data,
  };
}
export function fetcPreferenceIngredientData(data) {
  return {
    type: RECIPE_GET_PREFERENCES_INGREDIENT,
    payload: data,
  };
}

export function fetchExploreData(data) {
  return {
    type: RECIPE_GET_EXPLORE,
    payload: data,
  };
}

export function saveStepRecipe(data) {
  return {
    type: RECIPE_STEPS,
    payload: data,
  };
}
export function recipetools(data) {
  return {
    type: RECIPETOOLS,
    payload: data,
  };
}
export function recipeGeneralInformation(data) {
  return {
    type: RECIPEGENRALINFORMATION,
    payload: data,
  };
}
export function setFormError(message, fld) {
  return {
    type: GET_ERROR_MSG,
    payload: { msg: message, field: fld },
  };
}
export function addlist(data) {
  return {
    type: ADDLIST,
    payload: data,
  };
}

export function MyAllRecipeData(data) {
  return {
    type: MY_ALL_RECIPE_DATA,
    payload: data,
  };
}
export function recipeSinglePagefun(data) {
  return {
    type: SINGLE_PAGE_RECIPE_DATA,
    payload: data,
  };
}
export function submitRecipePostData(data) {
  return {
    type: SUBMIT_POST_STEP_DATA,
    payload: data,
  };
}

export async function submitRecipeData(data) {
  const token = auth.getAccessToken();
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/create/recipe`, {
    method: "POST",
    headers: headerType.HeadersWithTokenAndJson(token),
    body: JSON.stringify(data)
  }
  );
  let response = await result.json();
  if (response.success === 200) {
    //alert("Create Recipe successfully");

    return response;
  } else {
    //alert(response.errors)
    console.log(response.errors, "errp");
    return response;
  }
}

export async function racipeSaveInDraft(data) {
  const token = auth.getAccessToken();
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/save/update/draft/recipe`, {
    method: "POST",
    headers: headerType.HeadersWithTokenAndJson(token),
    body: JSON.stringify(data)
  }
  );
  let response = await result.json();
  if (response.success === 200) {
    //alert("Create Recipe successfully");
    return response;
  } else {
    //alert(response.errors)
    console.log(response.errors);
    return response;
  }
}

export async function updateDraftRecipe(data) {
  const id = data && data.params && data.params.recipeId
  const token = auth.getAccessToken();
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/save/update/draft/recipe/${id}`, {
    method: "POST",
    headers: headerType.HeadersWithTokenAndJson(token),
    body: JSON.stringify(data)
  }
  );
  let response = await result.json();
  if (response.success === 200) {
    //alert("Create Recipe successfully");

    return response;
  } else {
    //alert(response.errors)
    console.log(response.errors);
    return response;
  }
}

export async function fetchRecipeReview(id) {

  const token = auth.getAccessToken();


  const result = await fetch(
    `${REACT_APP_API.ENV.REACT_APP_API}/get/reviews?recipe_id=${id}`,
    {
      method: "GET",
      headers: headerType.HeadersWithTokenAndJson(token),
    }
  );
  let response = await result.json();
  if (response.success === 200) {

    return response
  } else {
    //alert("response.errors")
    console.log(response.errors);
    return response;
  }



}
//like and unlike
// favouriteUnFavouriteRecipe
export async function favouriteUnFavouriteRecipe(data, count) {
  var form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }
  const token = auth.getAccessToken();
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/favourite/unfavourite/recipe`, {
    method: "POST",
    headers: headerType.HeadersWithToken(token),
    body: form_data,
  });
  let response = await result.json();
  if (response.success === 200) {
    return response;

  } else {
    // alert(response.errors)
    console.log(response.errors);
    return response;
  }
}
//post Review......
export async function postReview(data) {
  var form_data = new FormData();
  for (var key in data) {
    form_data.append(key, data[key]);
  }
  const token = auth.getAccessToken();
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/do/review`, {
    method: "POST",
    headers: headerType.HeadersWithToken(token),
    body: form_data,
  });
  let response = await result.json();
  if (response.success === 200) {
    return response;
  } else {
    // alert(response.errors)
    console.log(response.errors);
    return response
  }
}
//update reviews..
export async function updateRecipeReview(data) {

  var form_data = new FormData();
  for (var key in data) {

    form_data.append(key, data[key]);
  }
  const token = auth.getAccessToken();

  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/review`, {
    method: "POST",
    headers: headerType.HeadersWithToken(token),
    body: form_data,
  });
  let response = await result.json();
  if (response.success === 200) {
    return response;

  } else {
    // alert(response.errors)
    console.log(response.errors);
  }
}
// update recipe
export async function recipeUpdateData(data) {
  const token = auth.getAccessToken();
  const id = data && data.params && data.params.recipeId

  const result = await fetch(
    `${REACT_APP_API.ENV.REACT_APP_API}/update/recipe/${id}`,
    {
      method: "POST",
      headers: headerType.HeadersWithTokenAndJson(token),
      body: JSON.stringify(data),
    }
  );
  let response = await result.json();
  return response;
  // if (response.success === 200) {
  //   return response;
  // } else {
  //   // alert(response.errors)
  //   console.log(response.errors);
  //   return response;
  // }
}

// search Tools
export function searchRecipeTool(title) {

  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    dispatch(setToolSpiner(true))
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search/tools?keyword=${title}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        dispatch(searchRecipeToolData(response.data))
        dispatch(setToolSpiner(false))
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
        return response
      }
    } catch (e) {
      console.log(e);
      dispatch(setToolSpiner(false))
    }
  };
}

//filter recipe
export function fetchFilterRecipe(data) {
  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/search/recipe?meal_type=${data.meal_type ?? ''}&child_ingredient=${data.child_ingredient.childId, data.child_ingredient.parent}&cook_time=${data.cook_time ?? ''}&cousin_id=${data.cousin_id ?? ''}&no_of_ingredients=${data.no_of_ingredients ?? ''}&keyword=${data.keyword}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        //console.log(response, "filter..data..");
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors, "error");
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}
//search recipe

export function searchRecipefun(title) {

  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    await dispatch(setEXplorePercentageSpiner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search/recipe?keyword=${title}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        return response;
      } else {
        // alert(response.errors)

        console.log(response, "eerrererh");
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}
// get tool
export function fetchRecipeTollFun(token) {

  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/tools`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {

        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}
// fetchMyFavouriteRecipe
export function fetchMyFavouriteRecipe() {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken()
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/my/favourite/recipes`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(favouriteRecipeDataFun(response.data));
        // return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}
//recipeDetail

export function getSingleRecipeDetail(id) {
  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    await dispatch(setEXplorePercentageSpiner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/detail/${id}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(recipeSinglePagefun(response));
        await dispatch(setEXplorePercentageSpiner(false));
        return response
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function removeSelectedToolAndIngredients(){
  return async (dispatch, getState) => {
    try {
     
        await dispatch(selectedIngredients());
        await dispatch(selectedTool());
        await dispatch(ToolTempId());
    } catch (e) {
      console.log(e);
    }
  }
}
export function getRecipeDetail(id) {
  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/detail/${id}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      return response;
      // if (response.success === 200) {
      //   console.log(response,"response");
      //   localStorage.setItem('generalInformation', JSON.stringify(response.recipe));
      //   localStorage.setItem('savedIngredients', JSON.stringify(response.used_ingredients));
      //   localStorage.setItem('savedTools', JSON.stringify(response.used_tools));
      //   localStorage.setItem('recipe_step', JSON.stringify(response.steps));
      //   // await dispatch(recipeGeneralInformation(response.recipe));
      //   // await dispatch(selectedIngredients(response.used_ingredients));
      //   // await dispatch(selectedTool(response.used_tools));
      //   await dispatch(submitRecipePostData(response));
        
      //   //console.log(response,"response");
      // } else {
      //   // alert(response.errors)
      //   console.log(response.errors);
      // }
    } catch (e) {
      console.log(e);
    }
  };
}

export function getIngredientsSearch(findIngredients) {
  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search/ingredients?keyword=${findIngredients}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      return response;
      
    } catch (e) {
      console.log(e);
    }
  };
}

//.....Search Api....
export function fetchIngredientsSearch(findIngredients) {
  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search/ingredients?keyword=${findIngredients}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(searchingIngredients(response.data));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
}
//end Search Api...

export async function submitSelectPreferencesData(data) {

  const token = await auth.getAccessToken();
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/save/preference`, {
    method: "POST",
    headers: headerType.HeadersWithTokenAndJson(token),
    body: JSON.stringify(data),
  });
  let response = await result.json();

  if (response.success === 200) {
    await localStorage.setItem("having_preferences", 1);
    return response;
  } else {
    //alert(JSON.stringify(response.errors))
    console.log(response.errors);
  }
}

//Remove Recipe 

export async function removeRecipe(id) {
  const token = await auth.getAccessToken()

  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/recipe/${id}`, {
    method: "POST",
    headers: headerType.HeadersWithTokenAndJson(token),

  });
  let response = await result.json();
  if (response.success === 200) {
    return response;

  } else {
    alert(response.errors)
    console.log(response.errors);
    return response;
  }
}

export function selectmeals(data) {
  return {
    type: SELECTMEAL,
    payload: data,
  };
}

export function recipeselectcourse(data) {
  return {
    type: RECIPE_SELECT_COURSE,
    payload: data,
  };
}
export function selectcuisine(data) {
  return {
    type: SELECT_CUISINE,
    payload: data,
  };
}
export function selectregion(data) {
  return {
    type: SELECT_REGION,
    payload: data,
  };
}
export function selectfoodintrolerance(data) {
  return {
    type: SELECT_FOOD_INTROLERANCE,
    payload: data,
  };
}
export function fetchCookSkillData(data) {
  return {
    type: SELECT_COOKING_SKILL,
    payload: data,
  };
}

export function fetchIngredientsData(data) {
  return {
    type: SELECT_INGREDIENTS,
    payload: data,
  };
}
export function fetchDiet(token) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/diet/list`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(fetchDietData(response));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchPreferences() {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken()
    await dispatch(setMyPreferencesPercentageSpiner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/saved/preferences`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setMyPreferencesPercentageSpiner(false));
        await dispatch(fetcPreferenceData(response.data));
        await dispatch(fetcPreferenceIngredientData(response.data[3]));
        return response
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchExplore() {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken()
    await dispatch(setEXplorePercentageSpiner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/home/screen`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setEXplorePercentageSpiner(false));
        await dispatch(fetchExploreData(response.data));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchMyAllRecipe() {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken()
    await dispatch(setRecipePercentageSpiner(true));
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/myrecipes`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setRecipePercentageSpiner(false));
        await dispatch(MyAllRecipeData(response.data));
        return response.data
      } else {
        // alert(response.errors)
        await dispatch(setRecipePercentageSpiner(false));
        return response;
      }
    } catch (e) {
      await dispatch(setRecipePercentageSpiner(false));
      console.log(e);
    }
  };
}

export function selectmealfun(token) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/meals`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(selectmeals(response));
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function fetchCuisine(token) {
  localStorage.setItem("cuisines-redirect", false);
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/all/cousins`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        localStorage.setItem("cuisines-redirect", true);
        await dispatch(selectcuisine(response.data));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchCourse(token) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/courses`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(recipeselectcourse(response));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function fetchFoodIntrolerence(token) {
  localStorage.setItem("intolerance-redirect", true);
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/food/intolerance`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        localStorage.setItem("intolerance-redirect", true);
        await dispatch(selectfoodintrolerance(response));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchCookingSkill(token) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/cooking/skills`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(fetchCookSkillData(response));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
// getchildIngredients

export function getchildIngredients(id) {
  const token = auth.getAccessToken()
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/child/ingredients/${id}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        // await dispatch(fetchIngredientsData(response));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}



export function fetchIngredients(token) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/ingredients`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(fetchIngredientsData(response));
        return response;
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function fetchRegion(token) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/recipe/regions?cousin_id=1`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(selectregion(response));
      } else {
        // alert(response.errors)
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export const initialState = {
  recipeDiet: [],
  recipecourse: [],
  foodintrolerance: [],
  cookingskill: [],
  region: [],
  cuisine: [],
  meal: [],
  IngradientsAddList: [],
  recipeSinglePageData: [],
  editRecipeBtn: true,
  isEditingredien: true,
  recipeinformation: [],
  recipeTools: [],
  recipeSeacrhToolData: [],
  likeOrUnlike: [],
  tabIndexData: 0,
  errMessage: {
    msg: "",
    field: "",
  },
  setSteps: [],
  recipePreference: [],
  recipePreferenceIngredient: [],
  recipeExplore: [],
  favouriteRecipeData: [],
  recipeIngredientData: [],
  selectedIngredientsData: [],
  selectedToolsData: [],
  recipeReviewData: [],
  myAllRecipeData: [],
  recipeSubmitStep: [],
  searchIngredientsData: [],
  toolsTemplId: [],
  explorePercentageSpinner: false,
  myRecipePercentageSpinner: false,
  myPreferencesPercentageSpinner: false,
  ingredientSpiner: true,
  toolSpinner: true,
};

const ACTION_HANDLERS = {
  [SET_EXPLORE_PERCENTAGE_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      explorePercentageSpinner: action.payload,
    };
  },
  [REVIEW_GET]: (state, action) => {
    return {
      ...state,
      recipeReviewData: action.payload,
    };
  },
  [INGREDIENT_SPINNER]: (state, action) => {
    return {
      ...state,
      ingredientSpiner: action.payload,
    };
  },
  [SET_TOOL_SPINNER]: (state, action) => {
    return {
      ...state,
      toolSpinner: action.payload,
    };
  },
  [TAB_INDEX]: (state, action) => {
    return {
      ...state,
      tabIndexData: action.payload,
    };
  },
  [EDIT_BTN]: (state, action) => {
    return {
      ...state,
      editRecipeBtn: action.payload,
    };
  },
  [IS_EDIT_ING]: (state, action) => {
    return {
      ...state,
      isEditingredien: action.payload,
    };
  },
  [SEARCH_RECIPE_TOOL]: (state, action) => {
    return {
      ...state,
      recipeSeacrhToolData: action.payload,
    };
  },
  [LIKE_RECIPE]: (state, action) => {
    return {
      ...state,
      likeOrUnlike: action.payload,
    };
  },
  [SET_MY_ALL_RECIPE_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myRecipePercentageSpinner: action.payload,
    };
  },
  [SET_MY_PREFERENCES_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      myPreferencesPercentageSpinner: action.payload,
    };
  },
  [RECIPE_DIET]: (state, action) => {
    return {
      ...state,
      recipeDiet: action.payload,
    };
  },
  [TOOL_TEMP_ID]: (state, action) => {
    return {
      ...state,
      toolsTemplId: action.payload,
    };
  },
  [SEARCH_INGREDIENTS]: (state, action) => {
    return {
      ...state,
      searchIngredientsData: action.payload,
    };
  },
  [SUBMIT_POST_STEP_DATA]: (state, action) => {
    return {
      ...state,
      recipeSubmitStep: action.payload,
    };
  },
  [SINGLE_PAGE_RECIPE_DATA]: (state, action) => {
    return {
      ...state,
      recipeSinglePageData: action.payload,
    };
  },
  [MY_ALL_RECIPE_DATA]: (state, action) => {
    return {
      ...state,
      myAllRecipeData: action.payload,
    };
  },
  [SELECTED_TOOLS]: (state, action) => {
    return {
      ...state,
      selectedToolsData: action.payload,
    };
  },
  [SELECTED_INGREDIENTS]: (state, action) => {
    return {
      ...state,
      selectedIngredientsData: action.payload,
    };
  },
  [SELECT_INGREDIENTS]: (state, action) => {
    return {
      ...state,
      recipeIngredientData: action.payload,
    };
  },
  [RECIPE_GET_EXPLORE]: (state, action) => {
    return {
      ...state,
      recipeExplore: action.payload,
    };
  },

  [RECIPE_GET_PREFERENCES]: (state, action) => {
    return {
      ...state,
      recipePreference: action.payload,
    };
  },
  [RECIPE_GET_PREFERENCES_INGREDIENT]: (state, action) => {
    return {
      ...state,
      recipePreferenceIngredient: action.payload,
    };
  },
  [RECIPE_STEPS]: (state, action) => {
    return {
      ...state,
      setSteps: action.payload,
    };
  },
  [GET_ERROR_MSG]: (state, action) => {
    return {
      ...state,
      errMessage: action.payload,
    };
  },
  [RECIPETOOLS]: (state, action) => {
    return {
      ...state,
      recipeTools: action.payload,
    };
  },
  [RECIPEGENRALINFORMATION]: (state, action) => {
    return {
      ...state,
      recipeinformation: action.payload,
    };
  },
  [ADDLIST]: (state, action) => {
    return {
      ...state,
      IngradientsAddList: action.payload,
    };
  },
  [RECIPE_SELECT_COURSE]: (state, action) => {
    return {
      ...state,
      recipecourse: action.payload,
    };
  },
  [SELECT_FOOD_INTROLERANCE]: (state, action) => {
    return {
      ...state,
      foodintrolerance: action.payload,
    };
  },
  [SELECT_COOKING_SKILL]: (state, action) => {
    return {
      ...state,
      cookingskill: action.payload,
    };
  },
  [SELECT_REGION]: (state, action) => {
    return {
      ...state,
      region: action.payload,
    };
  },
  [SELECT_CUISINE]: (state, action) => {
    return {
      ...state,
      cuisine: action.payload,
    };
  },
  [SELECTMEAL]: (state, action) => {
    return {
      ...state,
      meal: action.payload,
    };
  },
  [fAVOURITE_RECIPE_DATA]: (state, action) => {
    return {
      ...state,
      favouriteRecipeData: action.payload,
    };
  },
};

export default function RecipeModule(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
