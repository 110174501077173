import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormError from '../../../components/Errors/FormError';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import { Link } from 'react-router-dom';
import Cropper from 'react-cropper';
import Modal from 'react-modal';
import IncoTermModal from './IncoTermModal';
// import IncoTermModal from "../../routes/marketplace/components/IncoTermModal";

import {
  fetchStorePrefilledData,
  setPostStoreData,
  saveStoreData,
  fetchStoreData,
  setFormErrors,
  setResponseErrors,
} from '../modules/marketplace';
import language from '../../../helpers/language';

export function ConfigureYourStore() {
  const dispatch = useDispatch();
  const history = useHistory();
  const myRef = useRef(null);
  const [lang] = useState(language.getLang());
  const [selectedBannerImageUrl, setSelectedBannerImageUrl] = useState('');
  const [selectedLogoImageUrl, setSelectedLogoImageUrl] = useState('');
  const [bannerImageObject, setBannerImageObject] = useState([]);
  const [logoImageObject, setLogoImageObject] = useState([]);
  const [galleryImageObject, setGalleryImageObject] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isCropped, setIsCropped] = useState(false);
  const [targetImageId, setTargetImageId] = useState('');
  const [openModal, setOpenModal] = useState('');
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState('');
  const [fileName, setFileName] = useState('');
  const cropper = React.createRef(null);
  const [storeBannerImage, setStoreBannerImage] = useState('');
  const [coverImageHeight, setCoverImageHeight] = useState(284);
  const [coverImageWidth, setCoverImageWidth] = useState(1176);
  const divHeightWidth = useRef('store__banner');
  const [bannerCropButtonName, setBannerCropButtonName] = useState('Crop');
  const [coverPhotoSizeError, setCoverPhotoSizeError] = useState(false);
  const [incoterm, setIncoterms] = useState('');
  const [incotermText, setIncotermText] = useState('');
  const [incoTerm, setIncoTerm] = useState(false);
  const [bannerCroped, setBannerCroped] = useState(false);
  const [croppedBannerImage, setCroppedBannerImage] = useState("");

  useEffect(async () => {
    await setCoverImageHeight(divHeightWidth.current.clientHeight);
    await setCoverImageWidth(divHeightWidth.current.clientWidth);
  }, [coverImageHeight, coverImageWidth]);

  useEffect(async () => {
    await dispatch(fetchStorePrefilledData());
  }, []);

  const scrollToRef = (ref) => {
    window.scroll(0, ref.current && ref.current.offsetTop - 200);
  };

  let { storeData, postStoreData, marketPlaceSpinner, resError, errMessage } =
    useSelector((state) => state.marketPlaceReducerData);

  const handleLogoOrBannerImage = async (event) => {
    // await setOpenModal(true);
    let type = event.target.files[0];
    let fsize = event.target.files[0].size;
    let fSizeKB = Math.round((fsize / 1024));
    if (!type.name.match(/\.(jpg|jpeg|png)$/)) {
      dispatch(setFormErrors(lang?.imageType, event.target.id));
    } else if (fSizeKB > 5001) {
      dispatch(setFormErrors(lang?.selectImageSizeLessThan10, event.target.id));
    } else {

      await setTargetImageId(event.target.id);
      await setFileName(event.target.files[0].name);
      if (event.target.id === 'logo_id') {
        setOpenModal(true);
        // setLogoImageObject(event.target.files[0]);
        setTargetImageId(event.target.id);
        // setSelectedLogoImageUrl(URL.createObjectURL(event.target.files[0]));
        setImage(URL.createObjectURL(event.target.files[0]));
      } else if (event.target.id === 'banner_id') {
        await setBannerCroped(false);
        await setBannerCropButtonName("Crop");
        const imageDimensions = await imageSize(URL.createObjectURL(event.target.files[0]));
        if (imageDimensions.width < 1199) {
          await setCoverPhotoSizeError(true);
        }
        else {
          setTargetImageId(event.target.id);
          setStoreBannerImage(URL.createObjectURL(event.target.files[0]));
        }
      }
    }
    setTimeout(() => {
      dispatch(setFormErrors({ msg: '', field: '' }));
    }, 3000);
    return (event.target.value = null);
  };

  // get uploaded image dimension
  async function imageSize(url) {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  }

  // Start Cropping your Image
 const getCropData = async () => {
   setIsCropped(true);
   if (typeof cropper !== "undefined") {
     setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
     targetImageId === "logo_id" &&
       (await setSelectedLogoImageUrl(
         cropper.current.cropper.getCroppedCanvas().toDataURL()
       ));
     targetImageId === "gallery_images" &&
       (await galleryImages.push(
         cropper.current.cropper.getCroppedCanvas().toDataURL()
       ));
     await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL()).then(
       (res) =>
         res.blob().then(async (blob) => {
          targetImageId === "banner_id" &&
          (await setCroppedBannerImage(cropper.current.cropper.getCroppedCanvas().toDataURL()));

           const file = await new File([blob], `${fileName}`, {
             type: "image/png",
           });
           targetImageId === "logo_id" && (await setLogoImageObject(file));
           targetImageId === "banner_id" && (await setBannerImageObject(file));
           targetImageId === "gallery_images" &&
             (await galleryImageObject.push(file));
         })
     );
     await setIsCropped(false);
     await setOpenModal(false);
     if (targetImageId === "banner_id") {
       await setBannerCropButtonName("Cropped");
       await setBannerCroped(true);
     }
     await setGalleryImageObject(galleryImageObject);
     await setGalleryImages(galleryImages);
   }
 };

  const closeModal = async () => {
    await setCropData('');
    await setImage('');
    await setFileName('');
    await setTargetImageId('');
    await setCropData('');
    await setSelectedBannerImageUrl('');
    await setSelectedLogoImageUrl('');
    await setOpenModal(false);
    await setLogoImageObject([]);
    await setBannerImageObject([]);
  };
  // End of Cropping

  const handleGalleryImages = async (files) => {
    if (files.target.files.length + galleryImages.length > 5) {
      dispatch(setFormErrors(lang?.selectImageSizeLessThan5, files.target.id));
      scrollToRef(myRef);
    } else {
      Object.keys(files.target.files).map((item, i) => {
        if (
          !files.target.files[item].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)
        ) {
          dispatch(setFormErrors(lang?.imageType, files.target.id));
          scrollToRef(myRef);
        } else if (files.target.files[item].size > 2000000) {
          files.target.value = '';
          //alert(lang?.selectImageSize);
          dispatch(setFormErrors(lang?.selectImageSize, files.target.id));
          //scrollToRef(myRef);
          return true;
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(files.target.files[item]);
          reader.onload = (e) => {
            galleryImages.push(e.target.result);
          };
          galleryImageObject.push(files.target.files[item]);
        }
      });
      // await setGalleryImageObject(galleryImageArr);
    }
    setTimeout(() => {
      dispatch(setFormErrors({ msg: '', field: '' }));
    }, 3000);
  };

  const removeGallery = async (index) => {
    if (index > -1) {
      galleryImages.splice(index, 1);
      galleryImageObject.splice(index, 1);
      await setGalleryImages(galleryImages);
      await setGalleryImageObject(galleryImageObject);
    }
  };

  const createStore = async () => {
    if (bannerImageObject.length === 0) {
      dispatch(setFormErrors(lang?.selectBannerImage, 'banner_id'));
      scrollToRef(myRef);
    } else if (logoImageObject.length === 0) {
      dispatch(setFormErrors(lang?.selectLogoImage, 'logo_id'));
      scrollToRef(myRef);
    }
    else {
      postStoreData['banner_id'] = bannerImageObject;
      postStoreData['logo_id'] = logoImageObject;
      postStoreData['gallery_images'] = galleryImageObject;
      postStoreData['incoterm_id'] = incoterm;
      postStoreData['incoterm_text'] = incotermText;
      await dispatch(setPostStoreData(postStoreData));
      await dispatch(saveStoreData(postStoreData)).then((res) => {
        if (res && res.success === 200) {
          history.push({ pathname: '/marketplace/add-product', state: true });
        } else if (res && res.success === 409) {
          dispatch(setFormErrors(res.errors.exception, 'gallery_images'));
          scrollToRef(myRef);
        }
      });
    }

    setTimeout(() => {
      dispatch(setResponseErrors(''));
      dispatch(setFormErrors({ msg: '', field: '' }));
    }, 3000);
  };

  // Cancel Banner image when try to crop image
  const cancelCropData = async () => {
    await setTargetImageId('');
    await setStoreBannerImage('');
    await setBannerCropButtonName('Crop');
  }

  return (
    <>
      <div className="marketplace">
        {openModal && (
          <Modal
            className=""
            ariaHideApp={false}
            isOpen={openModal}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
              },
              content: {
                position: 'absolute',
                top: '0',
                left: '0%',
                right: '0%',
                bottom: '200px',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                padding: '0px',
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Crop_image}</h4>
              <button className="close_btn--modal" onClick={() => closeModal()}>
                {' '}
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <div className="crop_img_block">
                <div className="storeProfileImage ">
                  <Cropper
                    zoomTo={0}
                    style={{ width: '100%' }}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    dragMode={'move'}
                    cropBoxMovable={false}
                    responsive={true}
                    cropBoxResizable={false}
                    ref={cropper}
                    ready={() => {
                      cropper.current.cropper.setCropBoxData({
                        left: 130,
                        top: 70,
                        width: 170,
                        height: 170,
                      });
                    }}
                    center={true}
                    guides={false}
                  />
                </div>
              </div>
              <div className="form__action form__item mt-4">
                <button onClick={() => getCropData()}>
                  {' '}
                  {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`}{' '}
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="main__content">
          <div className="section__wrapper">
            <div className="bg--white page-main">
              <div className="flex justify-between items-center">
                <Link
                  href="#"
                  className="back__btn"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />{' '}
                  {lang?.Go_back}
                </Link>
              </div>
              <div className="configure">
                <div className="flex justify-between items-center">
                  <h4 className="font-20 font-semibold">
                    {lang?.Config_store}
                  </h4>
                  {resError && resError.length > 0 ? (
                    <div ref={myRef}>
                      <FormError msg={resError} />
                    </div>
                  ) : null}
                  <div className="inner-btn-box">
                    <Link
                      to="#"
                      onClick={() => createStore()}
                      className="btn btn-blue"
                    >
                      {marketPlaceSpinner ? <FormSpinner /> : 'Next'}
                    </Link>
                  </div>
                </div>
                  {errMessage.field == 'banner_id' ? (
                    <div ref={myRef}>
                      {' '}
                      <h3 className='font-18 text-red text-center'>{errMessage.msg}</h3>
                      {/* <FormError msg={errMessage.msg} /> */}
                    </div>
                  ) : null}

                  {/* Error for profile  */}
                  {errMessage.field == 'logo_id' ? (
                      <div ref={myRef}>
                        {' '}
                      <h3 className='font-18 text-red text-center'>{errMessage.msg}</h3>
                        {/* <FormError msg={errMessage.msg} /> */}
                      </div>
                    ) : null}
                <div className="right-banner mt-3 bg-gray-200 store__banner" ref={divHeightWidth}>
                  {croppedBannerImage && bannerCroped && (
                    <img src={croppedBannerImage} />
                  )}
                  {storeBannerImage && bannerCroped == false && (
                    <div className="storeBannerCropper" style={{ height: coverImageHeight }}>
                      <Cropper
                        zoomTo={0}
                        style={{ top: 0, left: 0 }}
                        preview=".img-preview"
                        src={storeBannerImage}
                        viewMode={1}
                        dragMode={'move'}
                        cropBoxMovable={false}
                        responsive={true}
                        cropBoxResizable={false}
                        ref={cropper}
                        ready={() => {
                          cropper.current.cropper.setCropBoxData({
                            left: 0,
                            top: 0,
                            width: coverImageWidth,
                            height: coverImageHeight,
                          });
                        }}
                        center={true}
                        guides={false}
                      />
                    </div>
                  )}

                  

                  {storeBannerImage && bannerCroped == false ?
                    <div className='image__cropped'>
                      <button type="button" className='cancel' onClick={() => cancelCropData()}>Cancel</button>
                      <button type="button" className='cropped' onClick={() => getCropData()}>{bannerCropButtonName}</button>
                    </div>
                    :
                    <div className="right-camera-icon">
                      <label className="hide-input">
                        <span className>
                          <i className="fa fa-camera" aria-hidden="true" />
                        </span>
                        <input
                          type="file"
                          id="banner_id"
                          name="banner_id"
                          style={{}}
                          accept="image/png,image/jpeg"
                          onChange={(e) => handleLogoOrBannerImage(e)}
                        />
                      </label>
                    </div>
                  }
                  <div className="profile-upload-circle">
                    {selectedLogoImageUrl && (
                      <img
                        src={selectedLogoImageUrl}
                        className="store-logo-image"
                      />
                    )}
                    {/* {errMessage.field == 'logo_id' ? (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    ) : null} */}
                    <label className="hide-input">
                      <span className>
                        <i className="fa fa-camera" aria-hidden="true" />
                      </span>
                      <input
                        type="file"
                        id="logo_id"
                        name="logo_id"
                        style={{}}
                        accept="image/png,image/jpeg"
                        onChange={(e) => handleLogoOrBannerImage(e)}
                      />
                    </label>
                  </div>
                </div>
                <div className="mrkt-place form mt-20">
                  <div className="card-form">
                    <form className="configure-store-form formGap">
                      <div className="flex flex-wrap main-row">
                        <div className="w-6/12 col-padding">
                          <div className="w-100">
                            <div className="form__item">
                              <label>{lang?.Store_Name}</label>
                              <input
                                type="text"
                                placeholder="Store Name"
                                id="store-name"
                                defaultValue={storeData.company_name}
                              />
                            </div>
                          </div>
                          <div className="col-100">
                            <div className="form__item mb-0">
                              <textarea
                                className="cmnt-write"
                                placeholder=""
                                defaultValue={storeData.about}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="w-100">
                            <div className="form__item">
                              <label>{lang?.Website}</label>
                              <input
                                type="email"
                                placeholder="storewebsite.com"
                                id="website"
                                defaultValue={storeData.website}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="w-100">
                            <div className="form__item">
                              <label>{lang?.Location}</label>
                              <input
                                type="text"
                                defaultValue={storeData && storeData.address}
                                name="address"
                                id="address"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="w-100">
                            <div className="form__item">
                              <label>{lang?.Store_Image_Heading}</label>
                              <p className='pl--0'>
                                {lang?.Photos} {galleryImages.length}/5{' '}
                                {lang?.ImageMaxLen5}.
                              </p>
                              {errMessage.field == 'gallery_images' ? (
                                <div ref={myRef}>
                                  {' '}
                                  <FormError msg={errMessage.msg} />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="w-6/12 col-padding">
                          <div className="col-100">
                            <div className="form__item">
                              <label>{lang?.Phone_number}</label>
                              <input
                                type="text"
                                placeholder=""
                                id="phone-number"
                                value={storeData?.phone && `+${parseInt(storeData.country_code)} ${storeData.phone}`}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-100">
                            <div className="form__item">
                              <label>{lang?.Email}</label>
                              <input
                                type="email"
                                placeholder="xxxx@gmail.com"
                                id="email"
                                defaultValue={storeData && storeData.email}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-100">
                            <div className="form__item">
                              <label>{lang?.Store_region}</label>
                              <input
                                type="email"
                                placeholder=""
                                id="store-region"
                                defaultValue={
                                  storeData &&
                                  storeData.state &&
                                  storeData.state.name
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-100">
                            <div className='form__item'>
                              <label>Incoterms 
                                <span className='infoIcon cursor pl-2'>
                                  <i
                                    class='fa fa-info-circle'
                                    aria-hidden='true'
                                    onClick={() => setIncoTerm(!incoTerm)}
                                  ></i>
                                </span>
                              </label>
                                <select value={incoterm} onChange={(e) => { setIncoterms(e.target.value); setIncotermText('') }}>
                                  <option value={''}>Select Incoterms</option>
                                  {storeData?.incoterms?.length > 0 && storeData?.incoterms?.map(({ id, incoterms }) => {
                                    return (
                                      <option key={id} value={id}>{incoterms}</option>
                                    )
                                  })}
                                </select>
                            </div>
                            <div className='form__item mt-2'>
                              <label>{lang?.Incoterm_text}</label>
                                <input type='text' name='incoterm_text' placeHolder={lang?.Incoterm_text} value={incotermText} onChange={(e) => setIncotermText(e.target.value)} />
                                <p className='pl--0 mt-2'>Sei a conoscenza di tutti i vincoli contrattuali collegati a questo termine di consegna? Per saperne di più: <a href="mailto:business&development@ormesani.it">business&development@ormesani.it</a></p>
                            </div>
                            {/* <div className="gallery_data">
                              <h4 className="product-title black">
                                Incoterms
                              </h4>
                              <div className="form__item relative searchImporter">
                                <select value={incoterm} onChange={(e) => { setIncoterms(e.target.value); setIncotermText('') }}>
                                  <option value={''}>Select Incoterms</option>
                                  {storeData?.incoterms?.length > 0 && storeData?.incoterms?.map(({ id, incoterms }) => {
                                    return (
                                      <option key={id} value={id}>{incoterms}</option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className="form__item relative searchImporter">
                                <div className='important-notes'>
                                  <label>{lang?.Incoterm_text}</label>
                                </div>
                                <input type='text' name='incoterm_text' placeHolder={lang?.Incoterm_text} value={incotermText} onChange={(e) => setIncotermText(e.target.value)} />
                                <p className='pl--0'>Sei a conoscenza di tutti i vincoli contrattuali collegati a questo termine di consegna? Per saperne di più: <a href="mailto:business&development@ormesani.it">business&development@ormesani.it</a></p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="w-6/12 col-padding">
                        </div>
                        <div className="w-full col-padding add--cover--photo ml-4 mr-4">
                          <div className>
                            <label>
                              <span className>
                                <i
                                  className="fa fa-picture-o"
                                  aria-hidden="true"
                                />
                              </span>
                              <input
                                type="file"
                                id="gallery_images"
                                name="gallery_images[]"
                                style={{}}
                                accept="image/png,image/jpeg"
                                onChange={(e) => handleGalleryImages(e)}
                                multiple
                              />{' '}
                              {lang?.Add_photos}
                            </label>
                          </div>
                        </div>
                        {/* <div className="w-full col-padding mt-4">
                          <p className="mt-5">
                            {lang?.Photos} {galleryImages.length}/5{' '}
                            {lang?.ImageMaxLen5}.
                          </p>
                        </div> */}
                        <div className="w-full col-padding">
                          <ul className="gallery__images flex">
                            {galleryImages.length > 0 &&
                              galleryImages.map((src, i) => {
                                return (
                                  <li key={i}>
                                    <div className="editDeleteBtn flex justify-end items-center p-2">
                                      <p>
                                        <Link
                                          to="#"
                                          className="remove-image"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => removeGallery(i)}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </Link>
                                      </p>
                                    </div>
                                    {/* <Link
                                    to="#"
                                    className="remove-image"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeGallery(i)}
                                  >
                                    &#215;
                                  </Link> */}
                                    <img src={src} key={i} />
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Incoterm Poppup */}
          {incoTerm && (
            <IncoTermModal
              lang={lang}
              incoTerm={incoTerm}
              setIncoTerm={setIncoTerm}
            />
          )}
          {/* Cover Photo uploaded dimesion Error Message  */}
          {coverPhotoSizeError && (
            <Modal
              className=""
              ariaHideApp={false}
              isOpen={coverPhotoSizeError}
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.75)',
                },
                content: {
                  position: 'absolute',
                  top: '0',
                  left: '0%',
                  right: '0%',
                  bottom: '200px',
                  border: '1px solid #ccc',
                  background: '#fff',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '4px',
                  padding: '0px',
                  // outline: 'none',
                },
              }}
            >
              <div className="popup_header flex justify-between items-center">
                <h4 className="page__title">{lang?.coverImageWidthInvalidHead}</h4>
                <button className="close_btn--modal" onClick={() => setCoverPhotoSizeError(!coverPhotoSizeError)}>
                  {' '}
                  {lang?.close}
                </button>
              </div>
              <div className="card card--block modal_box">
                <div className="crop_img_block">
                  <h3>{lang?.coverImageWidthInvalidMessage}</h3>
                </div>
              </div>

            </Modal>
          )}
        </div>
      </div>
    </>
  );
}
