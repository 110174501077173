import React, { Fragment } from 'react'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchRecipefun, fetchCuisine, setEXplorePercentageSpiner, fetchFilterRecipe, getchildIngredients, fetchIngredients, selectmealfun } from '../modules/RecipeModule'
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormSpinner from "../../../components/Spinners/FormSpinner";

import auth from '../../../helpers/auth'

import ReactStars from "react-rating-stars-component";
import language from '../../../helpers/language';

export default function Times(props) {
  const [lang] = useState(language.getLang());
  const [suggestedRecipes, SetsuggestedRecipes] = useState()
  const [name, setName] = useState()
  const [title, setTitle] = useState()
  const [noOfdIgredient, setNoOfIgredient] = useState(0)
  const [searchRecipe, setSearchRecipe] = useState()
  const [cookTime, setCookTime] = useState('')
  const [recipeFound, setRecipeFound] = useState()
  const [isFoundData, setIsFoundData] = useState(false)

  const [isIngChild, setIsiIngChild] = useState(false)
  const [mealType, setMealType] = useState()
  const [ingredient, setIngredints] = useState({ childId: '', parent: '' })
  const [cousineId, setCousineId] = useState()
  const [isCousine, setIsCousine] = useState(false)
  const [isIngredients, setIsIngredients] = useState(false)
  const [isTime, setIsTime] = useState(false)
  const [isMealType, setIsMealType] = useState(false)
  const [recipeData, setRecipeData] = useState()
  const [childData, setChildData] = useState()
  const dispatch = useDispatch()
  const { cuisine, meal } = useSelector((state) => state.recipedata)
  const { id, slug } = useParams();

  useEffect(async () => {
    await dispatch(setEXplorePercentageSpiner(true));
    let token = await auth.getAccessToken();
    // const slug = props && props.location && props.location.search.split("/id-");
    // const paramsSplit = props && props.location && props.location.search.split('?');
    // const id = await paramsSplit[paramsSplit.length - 1];
    // const str = id.split("-")
    // console.log(slug[1], "slug..")
    // const data=slug[0].split("-")
    // console.log(data[1],"data..  ")
    await setCookTime('')
    await setTitle(slug)
    await dispatch(fetchCuisine(token));
    await dispatch(selectmealfun(token));
    if (slug) {
      await dispatch(getchildIngredients(parseInt(id))).then((res) => {
        if (res && res.success === 200) {
          setChildData(res.data)
        }
      })
    }
    await dispatch(fetchIngredients(token)).then(async (res) => {
      if (res && res.success === 200) {
        await setIngredints(res.data)
      }
    });

    await dispatch(searchRecipefun(slug)).then(async (res) => {
      if (res && res.success === 200) {
        await dispatch(setEXplorePercentageSpiner(false));
        setIsFoundData(true)
        SetsuggestedRecipes(res.data.data.length)
        setRecipeData(res.data.data);
      }
      else if (res && res.success === 409) {
        await dispatch(setEXplorePercentageSpiner(false));
        setIsFoundData(false)
        setRecipeFound(res.errors.exception)
        setIsFoundData(true);
      }
    })
    window.scrollTo(0, 0);
  }, [])
  const { explorePercentageSpinner } = useSelector((state) => state.recipedata);

  const searchData = (e) => {
    if (searchRecipe != undefined && searchRecipe != "") {
      const obj = {
        cousin_id: cousineId,
        cook_time: cookTime,
        meal_type: mealType,
        child_ingredient: ingredient,
        no_of_ingredients: noOfdIgredient,
        keyword: searchRecipe
      }
      dispatch(fetchFilterRecipe(obj)).then(async (res) => {
        if (res && res.success === 200) {
          await SetsuggestedRecipes(res.data.data.length)
          await setIsFoundData(true)
          await setRecipeData(res.data.data)
          setIsTime(false);
          setIsIngredients(false);
          setIsCousine(false);
          setNoOfIgredient(0);
          setName()
          setCookTime('');
        } else if (res && res.success === 409) {
          setIsFoundData(false)
          setRecipeFound(res.errors.exception)
          setRecipeData()
          setIsTime(false);
          setSearchRecipe('');
          setIsIngredients(false);
          setIsMealType(false)
          setIsCousine(false);
          setNoOfIgredient(0);
          setIsiIngChild(false)
          setCousineId();
          setCookTime('');
          setMealType()
          setName()


        }
      })
      e.preventDefault();

    }


    //  else{
    //   dispatch(searchRecipefun(searchRecipe)).then(async(res)=>{
    //     if(res && res.success===200){
    //     // 

    //      SetsuggestedRecipes(res.data.data.length)
    //      setRecipeData(res.data.data)
    //     }
    // })

    // }


  }

  const ingredientsFun = (e) => {

    setNoOfIgredient(e.target.value)

  }

  const allResetData = async () => {
    setIsTime(false);
    setSearchRecipe('');
    setIsIngredients(false);
    setIsMealType(false)
    setIsCousine(false);
    setNoOfIgredient(0);
    setIsiIngChild(false)
    setCousineId();
    setCookTime('');
    setMealType()
    setName()

  }
  const childIngredientFun = (e, item) => {
    setSearchRecipe(e.target.value);
    setName(e.target.value)
    setIngredints({
      childId: item.recipe_ingredient_id,
      parent: item.parent
    })
  }

  return (
    <div>
      {explorePercentageSpinner ?
        <FormSpinner />
        : <div className="">
          <div className="section__wrapper">
            <div className="">
              <div className="recipe__section Ingredient">
                <form onSubmit={(e) => searchData(e)}>
                  <div className="banner__search-form__center justify-center">
                    <div className="top-search">
                      {
                        title === 'Ingredientstitle' ? '' : isFoundData && <Fragment>
                          <span className="search_icon"><i className="fas fa-search" aria-hidden="true" /></span>
                          <input type="text" placeholder="Search for recipes" value={searchRecipe} maxLength={20} onChange={(e) => setSearchRecipe(e.target.value)} />
                        </Fragment>
                      }
                      {
                        isFoundData &&

                        <span className="filter_icon" onClick={(e) => searchData(e)}>
                          {/* <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 0C9.70703 0 8.60547 0.84375 8.1875 2H0V4H8.1875C8.60547 5.15625 9.70703 6 11 6C12.293 6 13.3945 5.15625 13.8125 4H24V2H13.8125C13.3945 0.84375 12.293 0 11 0ZM11 2C11.5625 2 12 2.4375 12 3C12 3.5625 11.5625 4 11 4C10.4375 4 10 3.5625 10 3C10 2.4375 10.4375 2 11 2ZM18 8C16.707 8 15.6055 8.84375 15.1875 10H0V12H15.1875C15.6055 13.1562 16.707 14 18 14C19.293 14 20.3945 13.1562 20.8125 12H24V10H20.8125C20.3945 8.84375 19.293 8 18 8ZM18 10C18.5625 10 19 10.4375 19 11C19 11.5625 18.5625 12 18 12C17.4375 12 17 11.5625 17 11C17 10.4375 17.4375 10 18 10ZM7 16C5.70703 16 4.60547 16.8438 4.1875 18H0V20H4.1875C4.60547 21.1562 5.70703 22 7 22C8.29297 22 9.39453 21.1562 9.8125 20H24V18H9.8125C9.39453 16.8438 8.29297 16 7 16ZM7 18C7.5625 18 8 18.4375 8 19C8 19.5625 7.5625 20 7 20C6.4375 20 6 19.5625 6 19C6 18.4375 6.4375 18 7 18Z"
                              fill="#5B5B5B"
                            />
                          </svg> */}
                        </span>
                      }
                    </div>
                  </div>
                  <div className="search-recipe">
                    <Tabs>
                      <div className="tabs">
                        {/* <div className="flex justify-between items-center mb-8">
                          {
                            isFoundData ?
                              <Fragment>
                                <div className="">
                                  <TabList className="tabList flex">
                                    <Tab>
                                      <span tab={1} className="btn" >No Of Ingredients<div className="selected-no"  ><b> {noOfdIgredient}</b></div></span>
                                    </Tab>
                                    <Tab>
                                      <span tab={2} className="btn" onClick={() => setIsCousine(!isCousine)}>Cuisines</span>
                                    </Tab>
                                    <Tab>
                                      <span tab={3} className="btn" onClick={() => setIsTime(!isTime)}>Cook Time</span>
                                    </Tab>
                                    {
                                      title === 'Mealstitle' ? '' :
                                        <Tab>
                                          <span tab={4} className="btn" onClick={() => setIsMealType(!isMealType)}>Meal Type</span>

                                        </Tab>
                                    }
                                    {
                                      title === 'Ingredientstitle' ?
                                        <Tab>
                                          <span tab={5} className="btn" onClick={() => setIsiIngChild(!isIngChild)}>Ingredient Child</span>

                                        </Tab> : ''
                                    }
                                  </TabList>
                                </div>
                                <div className="top-reset">
                                  <input type="reset" defaultValue="Reset" onClick={() => { allResetData(); }} />
                                </div>
                              </Fragment>
                              : ''
                          }
                        </div> */}
                        {/* ingredient section */}
                        {/* <TabPanel>
                          <div className="cuisines-section">
                            <div className="cuisines">
                              {
                                isFoundData ?
                                  noOfdIgredientArray && noOfdIgredientArray.length > 0 && noOfdIgredientArray.map((res, indx) => {
                                    return (
                                      <div className="form-item-checkbox Cuisines" key={indx}>
                                        <input type="checkbox" checked={res === name ? 'true' : ''} value={res} id={indx} onClick={(e) => { ingredientsFun(e); setName(res) }} />
                                        <label htmlFor={indx}>{`<${res}`}</label>
                                      </div>
                                    )
                                  }) : ''
                              }
                            </div>
                          </div>
                        </TabPanel> */}
                        {/* cousine section */}
                        {/* <TabPanel>
                          <div className="cuisines-section">
                            <div className="cuisines">
                              {
                                cuisine && cuisine.length > 0 && cuisine.map((item, i) => {
                                  //
                                  return (
                                    <div className="form-item-checkbox Cuisines" key={i}>
                                      <input type="checkbox" id={item.cousin_id} value={item.cousin_id} onClick={(e) => setCousineId(e.target.value)} />
                                      <label htmlFor={item.cousin_id}>{item.name}</label>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </TabPanel> */}
                        {/* time Section */}
                        {/* <TabPanel>
                          {

                            <div className="cuisines-section">
                              <div className="cuisines time-checkbox">
                                {
                                  timeArray && timeArray.length > 0 && timeArray.map((time, index) => {
                                    return (
                                      <div className="form-item-checkbox time-check" key={index}>
                                        <input type="checkbox" id={index} checked={time === name ? 'true' : ''} value={cookTime} onClick={(e) => { setCookTime(time); setName(time) }} />
                                        <label htmlFor={index}>{`<${time}`} {time >= 3 ? 'Min' : 'Hr'}</label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>

                          }
                        </TabPanel> */}
                        {/* Meal_Type */}
                        {/* <TabPanel>
                          <div className="cuisines-section">
                            <div className="cuisines">
                              {
                                meal && meal.data && meal.data.length > 0 && meal.data.map((item, i) => {
                                  return (
                                    <div className="form-item-checkbox Cuisines" key={i}>
                                      <input type="checkbox" checked={item.name === name ? 'true' : ''}
                                        id={item.recipe_meal_id} value={item.recipe_meal_id}
                                        onClick={(e) => { setMealType(e.target.value); setName(item.name) }} />
                                      <label htmlFor={item.recipe_meal_id}>{item.name}</label>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </TabPanel> */}
                        {/* <TabPanel>
                          <div className="cuisines-section">
                            <div className="cuisines">
                              {
                                childData && childData.length > 0 && childData.map((item, i) => {
                                  return (
                                    <div className="form-item-checkbox Cuisines" key={i} >
                                      <input type="checkbox" checked={item.title === name ? 'true' : ''} id={i}
                                        value={item.title} onClick={(e) => { childIngredientFun(e, item) }}
                                      />
                                      <label htmlFor={i}>{item.title}</label>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </TabPanel> */}
                      </div>
                    </Tabs>
                  </div>
                </form>
                <div className="section__wrapper">
                  <div className="recipe__section">
                    <div className="section_heading text-center mb-3">
                      <h2 className="section_title"> {isFoundData && suggestedRecipes ? `${suggestedRecipes} ${lang?.Suggested} ${lang?.Recipe}` : recipeFound}</h2>
                    </div>
                    <div className="trending_List">
                      <ul>
                        {
                          recipeData && recipeData.length > 0 && recipeData.map((item, index) => {


                            return (
                              <Fragment key={index}>

                                <li className="recipe__detail">
                                  <Link to={`${'/recipes/recipe-view'}/${item.recipe_id}`} >
                                    <div className="trending_Img">
                                      <img src={`${item && item.base_url}${item && item.attachment_url}`} alt="Recipe" />

                                      <div className="recipe_rating">
                                        <p>
                                          <span className="recipe_name justify-start">{item.recipe_name}</span>
                                          <span className='star_rating'>
                                            <ReactStars
                                              count={5}
                                              value={item.avg_rating}
                                              size={24}
                                              edit={false}
                                              color2={'#ffd700'} />
                                          </span>
                                        </p>
                                        {/* <p>
                                  
                                  
                                  <span className="recipe_name">{item.username}</span>
                                  </p> */}

                                        <p>
                                          <span className="heart_Icon">
                                            {item && item.favourite_count > 0 ? (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="18"
                                                viewBox="0 0 20 17.648"
                                              >
                                                <path
                                                  id="like_icon_active"
                                                  d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                                  transform="translate(-5 -9)"
                                                  fill="#ff0046"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 20.653 18.099"
                                              >
                                                <path
                                                  id="icons8_heart"
                                                  d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                                  transform="translate(-2 -7)"
                                                />
                                              </svg>
                                            )}
                                          </span>
                                          <span className="likes">
                                            {item && item.favourite_count} {lang?.Likes}
                                          </span>
                                        </p>

                                      </div>
                                    </div>
                                    <div className="recipe_Info">
                                      <p className="mb-3">{item && item.username}</p>
                                      <p><span><i className="fa fa-clock-o" aria-hidden="true"></i></span>{item && item.hours === 0 ? '' : `${item && item.hours}${lang?.Hr}`} {item && item.minutes === 0 ? '' : `${item.minutes} ${lang?.Mins}`}  </p>
                                      <p><span><i className="fa fa-user" aria-hidden="true"></i></span>{item && item.serving}</p>
                                      <p><span><i className="fa fa-cutlery" aria-hidden="true"></i></span>{item && item.meal_name}</p>
                                    </div>
                                  </Link>
                                </li>
                              </Fragment>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
