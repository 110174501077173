import React from 'react'

export default function SectionTitle(props) {
  return (
    <>
        {props.secTitleLarge &&
          <h2 className="secTitleLarge">{props.secTitleLarge}</h2>
        }
    </>
  )
}
