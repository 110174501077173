import React, { useState, useEffect, useRef } from "react";
import "../../../assets/marketplacecss/mrktplace-style.css";
import product_item from "../../../assets/images/product-item.svg";
import category from "../../../assets/images/category.svg";
import rating from "../../../assets/images/rating.svg";
import inquiry from "../../../assets/images/inquiry.svg";
import action_img from "../../../assets/images/action.svg";
import graph1 from "../../../assets/images/graph1.png";
import graph2 from "../../../assets/images/graph2.png";
import item_image from "../../../assets/images/pizza-recipe.jpg";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import Cropper from "react-cropper";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import constant from "../../../helpers/constant";
import auth from "../../../helpers/auth";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import REACT_APP_API from "../../../config/environment";

import {
  getMarketplaceDashboard,
  getMarketplaceMyProducts,
  updateStoreData,
  deleteProductData,
  setMarketplaceProduct,
  fetchStoreData,
  setFormErrors,
  removeMarketplaceStoreGalleryImage,
  setMarketPlaceStoreData,
  setMyProductsList,
  analystMarketPlace,
  getMyAllOrderList,
  fetchProductCategories,
  getMyAllOrderTrasactionList,
  getCustomerList,
  getMyOffers,
  changeProductStatus,
  changeOrderProductStatus,
  fetchHomeScreenData,
  requestForTransactionPayment,
  getStripeConnectStatus,
  producerStripeConnect,
} from "../modules/marketplace";
import FormError from "../../../components/Errors/FormError";
import Inquiry from "./Inquiry";
import language from "../../../helpers/language";
import DashboardSideBar from "./DashboardSideBar";
import Product from "./Product";
import OrderManagement from "./OrderManagement";
import TransactionManagement from "./TransactionManagement";
import CustomerManagement from "./CustomerManagement";
import NotificationListing from "./NotificationListing";
import OfferManagement from "./OfferManagement";
import MyStore from "./MyStore";
import TaxManagement from "./TaxManagement";
import moment from "moment";
import PaginatedComponentView from "../../../components/PaginatedComponent/PaginatedComponentView";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import Dispute from "./Dispute";
import Payment from "./StripeConnect";
import NewLead from "./NewLead";

export function Dashboard() {
  const notes = null;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [lang] = useState(language.getLang());
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("tab") ?? "dashboard"
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedLogoImageUrl, setSelectedLogoImageUrl] = useState("");
  const [bannerImageObject, setBannerImageObject] = useState([]);
  const [logoImageObject, setLogoImageObject] = useState([]);
  const [galleryImageObject, setGalleryImageObject] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isflag, setIsflag] = useState(false);
  const myRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [isCropped, setIsCropped] = useState(false);
  const [targetImageId, setTargetImageId] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropData, setCropData] = useState("");
  const cropper = React.createRef(null);
  const [userId, setUserId] = useState();
  const [storeBannerImage, setStoreBannerImage] = useState("");
  const [coverImageHeight, setCoverImageHeight] = useState(284);
  const [coverImageWidth, setCoverImageWidth] = useState(1176);
  const divHeightWidth = useRef("bannerImg");
  const [bannerCropButtonName, setBannerCropButtonName] = useState("Crop");
  const [coverPhotoSizeError, setCoverPhotoSizeError] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [recentOrderListing, setRecentOrderListing] = useState([]);
  const [transationOrderListing, setTransactionOrderListing] = useState([]);
  const [customerListing, setCustomerListing] = useState([]);
  const [filterKeys, setFilterKeys] = useState({});
  const [myOffers, setMyOffers] = useState([]);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [selectItemId, setSelectItemId] = useState({ type: "", id: "" });
  const [confirmModalForOrder, setConfirmModalForOrder] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [incoterm, setIncoterms] = useState("");
  const [incotermText, setIncotermText] = useState("");
  const [toast, setToast] = useState(false);
  const [filterstatus, setFilterStatus] = useState();
  const [filter, setFilter] = useState("");
  const [index, setIndex] = useState();
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [bannerCroped, setBannerCroped] = useState(false);
  const [croppedBannerImage, setCroppedBannerImage] = useState("");

  const searchParams = new URLSearchParams(location.search);

  const tab = searchParams.get('tab');

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    } else {
      let data=localStorage.getItem("tab")
      setActiveTab(data);
    }
    
  },[tab])

  // console.log(tab,"tabbb")





  useEffect(async () => {
    await setCoverImageHeight(divHeightWidth?.current?.clientHeight);
    await setCoverImageWidth(divHeightWidth?.current?.clientWidth);
  }, [coverImageHeight, coverImageWidth]);

  const getAllRecentOrder = async (data, pageNo, filter) => {
    const recentOrderListing = await getMyAllOrderList(data, pageNo, filter);
    if (recentOrderListing) {
      setRecentOrderListing(recentOrderListing?.data);
    }
  };

  //all  recent order
  useEffect(() => {
    getAllRecentOrder({}, 1);
  }, []);

  useEffect(() => {
    stripeConnectedStatus();
  }, []);

  const stripeConnectedStatus = async () => {
    const response = await getStripeConnectStatus();
    if (response?.success === 200) {
      await setIsStripeConnected(response?.connected);
    }
  };

  //all order transaction
  useEffect(() => {
    getAllOrderTransactionList({}, 1);
  }, []);

  //all customer List
  useEffect(() => {
    customerList({}, 1);
  }, []);

  useEffect(async () => {
    if (filterstatus) {
      await getAllRecentOrder({ filterstatus }, 1);
    }
  }, [filterstatus, filter]);

  useEffect(async () => {
    if (filter) {
      //console.log(filter,"filter551521")
      await getAllRecentOrder({ filterstatus }, 1, filter);
    }
  }, [filter]);

  let {
    dashboardData,
    myProductsList,
    marketPlaceStore,
    marketPlaceDashboardSpinner,
    errMessage,
    productCategies,
  } = useSelector((state) => state.marketPlaceReducerData);
  const { profileData } = useSelector((state) => state.memberProfileData);

  useEffect(async () => {
    const activeTab = getParameterByName("tab");
   
    if (activeTab != null) {
      await setActiveTab(activeTab);
    }
    await dispatch(fetchProductCategories());
  }, []);

  useEffect(() => {
    myOffersListing(1);
  }, []);

  // get all my offers listing
  const myOffersListing = async (pageNum) => {
    const myOffersRes = await getMyOffers(pageNum);
    if (myOffersRes?.success === 200) {
      setMyOffers(myOffersRes?.data);
    } else {
      setMyOffers([]);
    }
  };

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  useEffect(() => {
    setUserId(profileData?.data?.user_data?.user_id);
  }, [profileData]);

 

  useEffect(async () => {
    parseInt(localStorage.getItem("storeStatus")) === 0 &&
      marketPlaceStore?.store_products?.length > 0 &&
      history.push("/marketplace/thankyou");
    parseInt(localStorage.getItem("storeStatus")) === 0 &&
      marketPlaceStore?.store_products?.length === 0 &&
      history.push("/marketplace/add-product");
    const user = await auth.getUserType();
    (await (JSON.parse(user).role_id !== constant.rolesId.producer)) &&
      history.push("/marketplace/error");
    dashboardData &&
      Object.keys(dashboardData).length <= 0 &&
      (await dispatch(getMarketplaceDashboard()));
    myProductsList &&
      Object.keys(myProductsList).length === 0 &&
      (await dispatch(getMarketplaceMyProducts(1, {})));
    marketPlaceStore &&
      Object.keys(marketPlaceStore).length === 0 &&
      (await dispatch(fetchStoreData()));
    await dispatch(setMarketplaceProduct([]));
  }, []);

  // console.log(marketPlaceStore,"marketpalce")

  useEffect(() => {
    setIncoterms(marketPlaceStore?.incoterm_id ?? "");
    setIncotermText(marketPlaceStore?.incoterm_text ?? "");
  }, [marketPlaceStore]);

  useEffect(() => {
    if (activeTab === "inquiry") {
      document.body.classList.add("producerInquiry");
    } else {
      document.body.classList.remove("producerInquiry");
    }
  }, [activeTab]);

  useEffect(async () => {
    if (
      location?.state === "open" ||
      location?.state === "new" ||
      location?.state === "close"
    ) {
      selectTab("inquiry");
    }
  }, [location?.state]);


  // Transaction Listing
  const getAllOrderTransactionList = async (data, page) => {
    const response = await getMyAllOrderTrasactionList(data, page);
    if (response?.success === 200) {
      setTransactionOrderListing(response?.data ?? []);
    } else {
      console.log(response);
    }
  };

  // customer Listing
  const customerList = async (data, page) => {
    const response = await getCustomerList(data, page);
    if (response.success === 200) {
      setCustomerListing(response?.data ?? []);
    } else {
      console.log(response);
    }
  };

  const scrollToRef = (myref) => {
    window.scrollTo(0, myref.current && myref.current.offsetTop - 200);
  };

  const selectTab = async (tab) => {
    setActiveTab(tab);

    if (tab === "dashboard") {
      dashboardData &&
        Object.keys(dashboardData).length <= 0 &&
        (await dispatch(getMarketplaceDashboard()));
    }
  };

  // get uploaded image dimension
  async function imageSize(url) {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  }

  const handleLogoOrBannerImage = async (event) => {
    // await setOpenModal(true);
    let type = event?.target?.files[0];
    if (!type?.name?.match(/\.(jpg|jpeg|png|JPEG)$/)) {
      dispatch(setFormErrors(lang?.imageType, event.target.id));
    } else if (event.target.files[0].size > 6200000) {
      await setErrorMsg(lang?.selectImageSizeLessThan10);
    } else {
      await setTargetImageId(event.target.id);
      await setFileName(event.target.files[0].name);
      if (event.target.id === "logo_id") {
        setOpenModal(true);
        setTargetImageId(event.target.id);
        // setSelectedLogoImageUrl(URL.createObjectURL(event.target.files[0]));
        setImage(URL.createObjectURL(event.target.files[0]));
        // await setLogoImageObject(event.target.files[0]);
      } else if (event.target.id === "banner_id") {
        await setBannerCroped(false);
        const imageDimensions = await imageSize(
          URL.createObjectURL(event.target.files[0])
        );
        if (imageDimensions.width < 1199) {
          await setCoverPhotoSizeError(true);
        } else {
          setTargetImageId(event.target.id);
          setStoreBannerImage(URL.createObjectURL(event.target.files[0]));
        }
      }
    }

    setTimeout(() => {
      dispatch(setFormErrors({ msg: "", field: "" }));
      setIsSuccess(false);
      setErrorMsg("");
    }, 3000);
    return (event.target.value = null);
  };

  // Cancel Banner image when try to crop image
  const cancelCropData = async () => {
    await setTargetImageId("");
    await setStoreBannerImage("");
    await setBannerCropButtonName("Crop");
  };

  // Start Cropping your Image
  const getCropData = async () => {
    setIsCropped(true);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
      targetImageId === "logo_id" &&
        (await setSelectedLogoImageUrl(
          cropper.current.cropper.getCroppedCanvas().toDataURL()
        ));
      targetImageId === "gallery_images" &&
        (await galleryImages.push(
          cropper.current.cropper.getCroppedCanvas().toDataURL()
        ));
      await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL()).then(
        (res) =>
          res.blob().then(async (blob) => {
            //console.log(cropper.current.cropper.getCroppedCanvas().toDataURL(),"bloblogo");
            targetImageId === "banner_id" &&
              (await setCroppedBannerImage(
                cropper.current.cropper.getCroppedCanvas().toDataURL()
              ));
            const file = await new File([blob], `${fileName}`, {
              type: "image/png",
            });
            targetImageId === "logo_id" && (await setLogoImageObject(file));
            targetImageId === "banner_id" && (await setBannerImageObject(file));
            targetImageId === "gallery_images" &&
              (await galleryImageObject.push(file));
          })
      );
      await setIsCropped(false);
      await setOpenModal(false);
      if (targetImageId === "banner_id") {
        await setBannerCropButtonName("Cropped");
        await setBannerCroped(true);
      }
      await setGalleryImageObject(galleryImageObject);
      await setGalleryImages(galleryImages);
    }
  };

  const closeModal = async () => {
    await setCropData("");
    await setImage("");
    await setTargetImageId("");
    await setCropData("");
    await setFileName("");
    await setSelectedLogoImageUrl("");
    await setOpenModal(false);
    await setLogoImageObject([]);
    await setBannerImageObject([]);
  };
  // End of Cropping
  const handleGalleryImages = async (event) => {
    Object.keys(event.target.files).map((item, i) => {
      if (
        !event.target.files[item].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)
      ) {
        dispatch(setFormErrors(lang?.imageType, event.target.id));
      } else if (event.target.files[item].size > 2000000) {
        dispatch(setFormErrors(lang?.selectImageSize, event.target.id));
        scrollToRef(myRef);
      } else {
        setOpenModal(true);
        setFileName(event.target.files[0].name);
        setTargetImageId(event.target.id);
        setImage(URL.createObjectURL(event.target.files[0]));
      }
    });
    setTimeout(() => {
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 3000);
    return (event.target.value = null);
  };

  const removeGallery = async (index) => {
    if (index > -1) {
      galleryImages.splice(index, 1);
      galleryImageObject.splice(index, 1);
      await setGalleryImages(galleryImages);
      await setGalleryImageObject(galleryImageObject);
    }
  };

  const updateStore = async () => {
    let isImageSelected = false;
    let postStoreData = {};
    postStoreData["banner_id"] = "";
    postStoreData["logo_id"] = "";
    postStoreData["gallery_images"] = "";
    postStoreData["incoterm_text"] = incotermText?.trim() ?? "";
    if (bannerImageObject.length !== 0) {
      isImageSelected = true;
      postStoreData["banner_id"] = bannerImageObject;
    }
    if (incoterm !== "") {
      postStoreData["incoterm_id"] = incoterm;
    }

    if (logoImageObject.length !== 0) {
      isImageSelected = true;
      postStoreData["logo_id"] = logoImageObject;
    }
    if (galleryImageObject.length !== 0) {
      isImageSelected = true;
      postStoreData["gallery_images"] = galleryImageObject;
    }
    if (isImageSelected || incoterm !== "") {
      await setUpdateStatus(true);
      await dispatch(updateStoreData(postStoreData)).then((res) => {
        if (res?.success === 200) {
          setIsSuccess(true);
          setGalleryImages([]);
          setGalleryImageObject([]);
          setLogoImageObject([]);
          setBannerImageObject([]);
          setCropData("");
          setImage("");
          setFileName("");
          setTargetImageId("");
          setCropData("");
          setOpenModal(false);
          setTargetImageId("");
          setStoreBannerImage("");
          setBannerCropButtonName("Crop");
          setErrorMsg("Updated successfully.");
          setUpdateStatus(false);
        } else {
          setErrorMsg(res?.errors?.exception);
        }
      });
    } else {
      setErrorMsg(lang?.selectLogoBannerGalleryToChange + " Or incoterm.");
    }

    setTimeout(() => {
      dispatch(setFormErrors({ msg: "", field: "" }));
      setIsSuccess(false);
      setErrorMsg("");
    }, 3000);
  };

  const deleteProductItem = async (id) => {
    setSelectItemId({ type: "", id });
    setIsDeleteConfirmationVisible(true);
  };

  const handleCancel = () => {
    setSelectItemId({ type: "", id: "" });
    setIsDeleteConfirmationVisible(false);
    setMessage("");
  };

  //product delete
  const handleDelete = async () => {
    const { type, id } = selectItemId;
    if (id) {
      setMessage("");
      setLoader(true);
      const item =
        myProductsList &&
        myProductsList.data.filter(
          (item, index) => item.marketplace_product_id !== id
        );
      const delete_data = {
        ...myProductsList,
        data: (myProductsList["data"] = item),
      };
      const response = await dispatch(deleteProductData(id));
      if (response?.success == 200) {
        setLoader(false);
        await dispatch(setMyProductsList(delete_data));
        handleCancel();
        await dispatch(getMarketplaceDashboard());
        await dispatch(fetchHomeScreenData());
      } else {
        setMessage(response?.errors?.exception ?? lang?.SomeThingsWrong);
        setLoader(false);
      }
    }
  };

  const deleteStoreGalleryImage = async (marketplace_store_gallery_id) => {
    const item =
      marketPlaceStore &&
      marketPlaceStore.store_gallery.filter(
        (item) =>
          item.marketplace_store_gallery_id !== marketplace_store_gallery_id
      );
    const delete_store_data = {
      ...marketPlaceStore,
      store_gallery: (marketPlaceStore["store_gallery"] = item),
    };
    const response = await dispatch(
      removeMarketplaceStoreGalleryImage(marketplace_store_gallery_id, 1)
    );
    if (response?.success === 200) {
      await dispatch(setMarketPlaceStoreData(delete_store_data));
      (marketPlaceStore?.store_products?.length === 0 ||
        myProductsList?.data?.length === 0) &&
        (await dispatch(fetchStoreData()));
    }
  };

  const textLimit = (text, limit) => {
    const CUTTING_EXPRESSION = /\s+[^\s]*$/;
    if (text?.length > limit) {
      const part = text.slice(0, limit - 3);
      if (part.match(CUTTING_EXPRESSION)) {
        return part.replace(CUTTING_EXPRESSION, " ...");
      }
      return part + "...";
    }
    return text;
  };

  // paginated product page
  const handlePageClick = async (e) => {
    const selectedPage = e.selected + 1;
    await dispatch(getMarketplaceMyProducts(selectedPage, {}));
  };

  // paginated  order page
  const handleRecentOrderPageClick = async (e) => {
    let selectedPage = e.selected + 1;
    await getAllRecentOrder({ filterstatus }, selectedPage, filter);
  };

  // paginated  order  transaction page
  const handleTransactionOrderPageClick = async (e) => {
    const selectedPage = e.selected + 1;
    await getAllOrderTransactionList({}, selectedPage);
  };

  // paginated customer page
  const handleCustomerPageClick = async (e) => {
    const selectedPage = e.selected + 1;
    await customerList({}, selectedPage);
  };

  // paginated offer page
  const handleOfferPageClick = async (e) => {
    const selectedPage = e.selected + 1;
    await myOffersListing(selectedPage);
  };

  // filter product by name, id, category
  const filterProducts = async (isfilter) => {
    if (isfilter)
      await dispatch(getMarketplaceMyProducts(null, { ...filterKeys }));
    else {
      await dispatch(getMarketplaceMyProducts());
      setFilterKeys({});
    }
  };

  // filter order by name, id, category
  const filterOrders = async (isfilter, currentPage) => {
    // setFilter(isfilter.status)

    setIndex(1);
    if (isfilter) await getAllRecentOrder(isfilter, currentPage);
    else {
      await getAllRecentOrder({}, "");
    }
  };

  // filter order transaction by date
  const filterOrderTransaction = async (isfilter) => {
    if (isfilter) await getAllOrderTransactionList(isfilter, "");
    else {
      await getAllOrderTransactionList({}, "");
    }
  };

  // change  transaction status
  const changeOrderTransactionStatus = async (order_id) => {
    if (order_id) {
      const response = await requestForTransactionPayment(order_id);
      if (response?.success === 200) {
        await getAllOrderTransactionList(
          {},
          transationOrderListing?.current_page ?? 1
        );
        return { status: true, message: response?.message };
      } else return { status: false, message: lang?.SomeThingsWrong };
    }
  };

  // filter customer by name and id
  const filterCustomerList = async (isfilter) => {
    if (isfilter) await customerList(isfilter, "");
    else {
      await customerList({}, "");
    }
  };

  // change product status
  const handleProuductStatus = async (id, status) => {
    const statusRes = await changeProductStatus(id, status);
    if (statusRes?.success === 200) {
      setMessage(
        statusRes?.message ?? "Product status has been change successfully."
      );
      const updatedList = myProductsList?.data.map((item) =>
        item.marketplace_product_id == id ? { ...item, status } : item
      );
      const updated_data = {
        ...myProductsList,
        data: (myProductsList["data"] = updatedList),
      };
      await dispatch(setMyProductsList(updated_data));
      await dispatch(fetchHomeScreenData());
    }
    setTimeout(() => {
      setMessage("");
    }, 2500);
  };

  const handleConfirmOrderChangeStatus = (id, status, isOpen) => {
    setSelectItemId({ type: status, id });
    setConfirmModalForOrder(isOpen);
    setMessage("");
  };
  // change product status
  const changeOrderStatus = async () => {
    const { type, id } = selectItemId;
    setMessage("");
    setLoader(true);
    const resStatus = await changeOrderProductStatus(id, type);
    if (resStatus?.success === 200) {
      const updatedList = recentOrderListing?.data?.map((item) =>
        item.order_id == id ? { ...item, status: type } : item
      );
      const updated_data = {
        ...recentOrderListing,
        data: (recentOrderListing["data"] = updatedList),
      };
      setRecentOrderListing(updated_data);

      setLoader(false);
      handleConfirmOrderChangeStatus("", "", false);
      type == "processing" && setToast(true);
    } else {
      setMessage(resStatus?.errors ?? lang?.SomeThingsWrong);
      setLoader(false);
    }
  };

  const openStripeUrl = () => {
    localStorage.setItem("tab", "payment");
  };

  console.log(myProductsList,"myProductsList")
  return (
    <>
      <div className='marketplace dashboard header'>
        {/* Banner Section */}
        <div>
          <div className='section__wrapper'>
            <div className='flex justify-between items-center'>
              <h6 className='back-btn-small'>
                <Link to='/marketplace'>
                  <i className='fa fa-arrow-left' aria-hidden='true' /> &nbsp;{" "}
                  {lang?.Go_back}
                </Link>
              </h6>
            </div>
          </div>
        </div>
        <div>
          {openModal && (
            <Modal
              className=''
              ariaHideApp={false}
              isOpen={openModal}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {
                  position: "absolute",
                  top: "0",
                  left: "0%",
                  right: "0%",
                  bottom: "200px",
                  border: "1px solid #ccc",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "4px",
                  padding: "0px",
                  // outline: 'none',
                },
              }}
            >
              <div className='popup_header flex justify-between items-center'>
                <h4 className='page__title'>{lang?.Crop_image}</h4>
                <button
                  className='close_btn--modal'
                  onClick={() => closeModal()}
                >
                  {" "}
                  {lang?.close}
                </button>
              </div>
              <div className='card card--block modal_box'>
                <div className='crop_img_block '>
                  {targetImageId === "logo_id" && (
                    <div className='storeProfileImage '>
                      <Cropper
                        zoomTo={0}
                        style={{ width: "100%" }}
                        preview='.img-preview'
                        src={image}
                        viewMode={1}
                        dragMode={"move"}
                        cropBoxMovable={false}
                        responsive={true}
                        cropBoxResizable={false}
                        ref={cropper}
                        ready={() => {
                          cropper.current.cropper.setCropBoxData({
                            left: 130,
                            top: 70,
                            width: 170,
                            height: 170,
                          });
                        }}
                        center={true}
                        guides={false}
                      />
                    </div>
                  )}
                  {targetImageId === "gallery_images" && (
                    <Cropper
                      zoomTo={0}
                      style={{ width: "100%" }}
                      preview='.img-preview'
                      src={image}
                      viewMode={1}
                      dragMode={"move"}
                      cropBoxMovable={false}
                      responsive={true}
                      cropBoxResizable={false}
                      ref={cropper}
                      ready={() => {
                        cropper.current.cropper.setCropBoxData({
                          left: 150,
                          top: 100,
                          width: 150,
                          height: 150,
                        });
                      }}
                      center={true}
                      guides={false}
                    />
                  )}
                </div>
                <div className='form__action form__item mt-4'>
                  <button onClick={() => getCropData()}>
                    {" "}
                    {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`}{" "}
                  </button>
                </div>
              </div>
            </Modal>
          )}

          {/* Cover Photo uploaded dimesion Error Message  */}
          {coverPhotoSizeError && (
            <Modal
              className=''
              ariaHideApp={false}
              isOpen={coverPhotoSizeError}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {
                  position: "absolute",
                  top: "0",
                  left: "0%",
                  right: "0%",
                  bottom: "200px",
                  border: "1px solid #ccc",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "4px",
                  padding: "0px",
                  // outline: 'none',
                },
              }}
            >
              <div className='popup_header flex justify-between items-center'>
                <h4 className='page__title'>
                  {lang?.coverImageWidthInvalidHead}
                </h4>
                <button
                  className='close_btn--modal'
                  onClick={() => setCoverPhotoSizeError(!coverPhotoSizeError)}
                >
                  {" "}
                  {lang?.close}
                </button>
              </div>
              <div className='card card--block modal_box'>
                <div className='crop_img_block'>
                  <h3>{lang?.coverImageWidthInvalidMessage}</h3>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>

      {/* New Dashboard Start */}
      <div className='servics_blocks dashboardView'>
        <div className='site-align top-align'>
          {/* Left Block */}
          <DashboardSideBar
            lang={lang}
            selectTab={selectTab}
            activeTab={activeTab}
          />
          {/* Right Block */}
          <div className='servics_blocks middle-long__section-block middleBlockBg'>
            {errMessage.field == "gallery_images" ? (
              <div ref={myRef}>
                <FormError msg={errMessage.msg} />{" "}
              </div>
            ) : null}
            {marketPlaceDashboardSpinner ? (
              <FormSpinner />
            ) : (
              // Dashboard Data
              <>
                <div
                  className={`tab home-section no-padding mb--28 ${
                    activeTab === "dashboard" ? "active-tab" : ""
                  }`}
                >
                  {!isStripeConnected && (
                    <div className='bgRed newBadge'>
                      <p onClick={openStripeUrl}>
                        {lang?.badge_Text_For_Stripe}
                        <a
                          href='/marketplace/dashboard'
                          className='font-bold text--black'
                        >
                          {" "}
                          {lang?.Click_Here}.
                        </a>
                      </p>
                    </div>
                  )}
                  {/* Tab Content Left Section */}
                  <h4 className='font-18 text-black mb-2 mt--28'>Dashboard</h4>
                  <p></p>
                  <div className='tab-content-left-section mt--28'>
                    <div className='grid-4 card--items'>
                      <div className='card--item--block productCard'>
                        <h4 className='font-20'>{lang?.Total_products}</h4>
                        <div className='item--count'>
                          <p className='flexBox font-28'>
                            {dashboardData?.product_counts}
                            <span>
                              <img src={product_item} alt='Icon' />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className='card--item--block categoryCard'>
                        <h4 className='font-20'>{lang?.Total_category}</h4>
                        <div className='item--count'>
                          <p className='flexBox font-28'>
                            {marketPlaceStore?.total_category}
                            <span>
                              <img src={category} alt='Icon' />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className='card--item--block ratingCard'>
                        <h4 className='font-20'>{lang?.Total_reviews}</h4>
                        <div className='item--count'>
                          <p className='flexBox font-28'>
                            {marketPlaceStore?.total_reviews}
                            <span>
                              <img src={rating} alt='Icon' />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className='card--item--block inquiriesCard'>
                        <h4 className='font-20'>{lang?.Total_inquiries}</h4>
                        <div className='item--count'>
                          <p className='flexBox font-28'>
                            {dashboardData.total_enquiries}
                            <span>
                              <img src={inquiry} alt='Icon' />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='grid-2 graphBlock mt--41 mb--41'>
                      <div className='graphView'>
                        <img src={graph1} alt='Graph 1' />
                      </div>
                      <div className='graphView'>
                        <img src={graph2} alt='Graph 2' />
                      </div>
                    </div>
                    {
                      <div className='recentOrder secBlockGap'>
                        <h4 className='secBlockTitle'>{lang?.Recent_orders}</h4>
                        <div className='itemTable itemTableScroll customer__table'>
                          <table>
                            <thead>
                              <tr>
                                <th>Order ID</th>
                                <th className='customer__name'>
                                  {lang?.Customer_Name}
                                </th>
                                <th>{lang?.Status}</th>
                                <th>{lang?.Total}</th>
                                <th>{lang?.Date}</th>
                                <th>{lang?.Action}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {recentOrderListing?.data?.length > 0 &&
                                recentOrderListing?.data?.map(
                                  ({
                                    total_seles,
                                    order_id,
                                    buyer_info,
                                    status,
                                    net_total,
                                    created_at,
                                  }) => {
                                    return (
                                      <tr key={order_id}>
                                        <td>{order_id}</td>
                                        <td className='customer__name'>
                                          {buyer_info?.company_name}
                                        </td>
                                        <td>{status}</td>
                                        <td>${total_seles}</td>
                                        <td>
                                          {moment(created_at).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className='actionBg'>
                                          <Link
                                            to={`/marketplace/producer-my-order-view/${order_id}`}
                                          >
                                            <span className='' title='View'>
                                              <img
                                                src={action_img}
                                                alt='Action'
                                              />
                                            </span>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                        <PaginatedComponentView
                          lastPage={recentOrderListing?.last_page}
                          handlePageClick={handleRecentOrderPageClick}
                          index={index}
                        />
                      </div>
                    }
                  </div>
                </div>
              </>
            )}
            {/* Product Data */}
            <Product
              handleProductStatus={handleProuductStatus}
              message={message}
              productCategies={productCategies}
              history={history}
              filterKeys={filterKeys}
              setFilterKeys={setFilterKeys}
              filterProducts={filterProducts}
              handlePageClick={handlePageClick}
              lang={lang}
              deleteProductItem={deleteProductItem}
              textLimit={textLimit}
              selectTab={selectTab}
              activeTab={activeTab}
              item_image={item_image}
              myProductsList={myProductsList}
            />
            {/* Order Management Data */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "order-management" ? "active-tab" : ""
              }`}
            >
              <div className='product-list'>
                <OrderManagement
                  toast={toast}
                  setToast={setToast}
                  changeOrderStatus={handleConfirmOrderChangeStatus}
                  data={recentOrderListing}
                  handlePageClick={handleRecentOrderPageClick}
                  filterOrders={filterOrders}
                  setFilterStatus={setFilterStatus}
                  setFilter={setFilter}
                />
              </div>
            </div>
            {/* Tax Management Data  */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "tax-management" ? "active-tab" : ""
              }`}
            >
              <div className='product-list'>
                <TaxManagement />
              </div>
            </div>
            {/* Transaction Management Data */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "transaction-management" ? "active-tab" : ""
              }`}
            >
              <TransactionManagement
                action_img={action_img}
                handleTransactionOrderPageClick={
                  handleTransactionOrderPageClick
                }
                data={transationOrderListing}
                filterOrderTransaction={filterOrderTransaction}
                changeOrderTransactionStatus={changeOrderTransactionStatus}
              />
            </div>
            {/* Customer Management Data */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "customer-management" ? "active-tab" : ""
              }`}
            >
              <CustomerManagement
                lang={lang}
                customerData={customerListing}
                action_img={action_img}
                filterCustomerList={filterCustomerList}
                handleCustomerPageClick={handleCustomerPageClick}
              />
            </div>
            {/* Notifications Data */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "Notifications-management" ? "active-tab" : ""
              }`}
            >
              <NotificationListing />
            </div>
            {/* Offer Management Data */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "offer-management" ? "active-tab" : ""
              }`}
            >
              <OfferManagement
                lang={lang}
                offer={myOffers}
                handleOfferPageClick={handleOfferPageClick}
                myOffersListing={myOffersListing}
              />
            </div>

            {/*  Store view  Data */}
            <MyStore
              setIncotermText={setIncotermText}
              incotermText={incotermText}
              setIncoterms={setIncoterms}
              incoterm={incoterm}
              storeBannerImage={storeBannerImage}
              activeTab={activeTab}
              updateStore={updateStore}
              lang={lang}
              updateStatus={updateStatus}
              marketPlaceStore={marketPlaceStore}
              cancelCropData={cancelCropData}
              getCropData={getCropData}
              handleLogoOrBannerImage={handleLogoOrBannerImage}
              croppedBannerImage={croppedBannerImage}
              isflag={isflag}
              handleGalleryImages={handleGalleryImages}
              galleryImages={galleryImages}
              cropper={cropper}
              bannerCropButtonName={bannerCropButtonName}
              bannerCroped={bannerCroped}
              removeGallery={removeGallery}
              deleteStoreGalleryImage={deleteStoreGalleryImage}
              coverImageHeight={coverImageHeight}
              coverImageWidth={coverImageWidth}
              isSuccess={isSuccess}
              errorMsg={errorMsg}
              divHeightWidth={divHeightWidth}
              selectedLogoImageUrl={selectedLogoImageUrl}
            />
            {/* Payment Setting */}
            <div
              className={`tab home-section no-padding sm:mt-2 ${
                activeTab === "payment" ? "active-tab" : ""
              }`}
            >
              <Payment
                lang={lang}
                handleOfferPageClick={handleOfferPageClick}
                isStripeConnected={isStripeConnected}
              />
            </div>

            {/*  Inquiry Data */}
            <div
              className={`tab home-section no-padding producer mt-1 ${
                activeTab === "inquiry" ? "active-tab" : ""
              }`}
            >
              <div className='product-title InquiryHead'>
                <div className='flex flex-wrap justify-between items-center'>
                  <h4 className='mb-0'>{lang?.Inquiries}</h4>
                </div>
              </div>
              <Inquiry type={location?.state} />
            </div>
            {/* Dispute Management */}
            <div
              className={`tab home-section no-padding producer mt-1 ${
                activeTab === "dispute" ? "active-tab" : ""
              }`}
            >
              <div className='product-title InquiryHead'>
                <div className='flex flex-wrap justify-between items-center'>
                  <h4 className='mb-0'>Disputes</h4>
                </div>
              </div>
              <Dispute />
            </div>

            {/* New Leads  */}
            <div
              className={`tab home-section no-padding producer mt-1 ${
                activeTab === "newlead" ? "active-tab" : ""
              }`}
            >
              <div className='product-title InquiryHead'>
                <div className='flex flex-wrap justify-between items-center'>
                  <h4 className='mb-0'>New Leads</h4>
                </div>
              </div>
              <NewLead />
            </div>
          </div>
        </div>
      </div>
      {/* New Dashboard End */}
      {isDeleteConfirmationVisible && (
        <DeleteConfirmation
          message={message}
          loader={loader}
          onDelete={handleDelete}
          btnText={"Delete"}
          heading={"Confirm Delete"}
          subHeading={"Are you sure you want to delete this"}
          notes={notes}
          onCancel={handleCancel}
          title={"product"}
        />
      )}
      {confirmModalForOrder && (
        <DeleteConfirmation
          message={message}
          loader={loader}
          onDelete={changeOrderStatus}
          btnText={"Confirm"}
          heading={"Confirm Status"}
          subHeading={"Are you sure you want to change this"}
          notes={notes}
          onCancel={() => handleConfirmOrderChangeStatus("", "", false)}
          title={"order status"}
        />
      )}
    </>
  );
}
