import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import MyOrderView from '../../../components/Dashboard/MyOrderView';
import language from '../../../helpers/language';
import DashboardSideBar from './DashboardSideBar';

export default function OrderManagementDetails() {
    const { id } = useParams();
    const history = useHistory();
    const [lang] = useState(language.getLang());
    const [activeTab, setActiveTab] = useState("order-management");


    const selectTab = async (tab) => {
        setActiveTab(tab);
    }

    const onTabChange = (tab) => {
        localStorage.setItem("tab", tab);
        history.push('/marketplace/dashboard');
    }

    return (
        <>
            <div className="marketplace dashboard header">
                <div>
                    <div className="section__wrapper">
                        <div className="flex justify-between items-center">
                            <h6 className="back-btn-small">
                                <Link to="/marketplace">
                                    <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;{" "}
                                    {lang?.Go_back}
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='servics_blocks dashboardView order_mgmt_detail'>
                <div className='site-align top-align'>
                    <div className='left__section-block sm:mb-2 md:mb-2'>
                        <div className='tabLeftBlock'>
                            <DashboardSideBar lang={lang} selectTab={selectTab} activeTab={activeTab} id={id} />
                        </div>
                    </div>
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>
                        <div className="product-list">
                            <div className='recentOrder secBlockGap'>
                                <div className='productFilter innderHeader mb--30 justify--between'>
                                    <MyOrderView id={id} linkUrl={'/marketplace/dashboard'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
