import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import language from '../../../helpers/language';
import DashboardSideBar from './DashboardSideBar';
import { createProductOffer, getImportersList, getProductLists, getSingleOfferDetails, } from '../modules/marketplace';
import ReactDatePicker from 'react-datepicker';
import Parse from 'html-react-parser';
import moment from 'moment';
import Modal from 'react-modal';
import IncoTermModal from './IncoTermModal';

const formKey = { user_id: "", offer_title: '', end_date: '', imp_notes: "", shipping_price: 0, include_shipping_charge: false, icoterm_id: '', incoterms_text: '' }

export default function CreateOffer() {
    const history = useHistory();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState("offer-management");
    const [offerManagementForm, setOfferManagementForm] = useState(formKey);
    const [formError, setFormErrors] = useState({ ...formKey, quantity: null, index: 0 });
    const [importerList, setImporterList] = useState([]);
    const [IcotermsList, setIcotermsList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [laoding, setLoading] = useState(false);
    const [message, setMessage] = useState({ status: false, text: "" });
    const [lang] = useState(language.getLang());
    const [incoTerm, setIncoTerm] = useState(false);

    const [formData, setFormData] = useState([
        { id: 1, product_id: [], unit_price: [], quantity: [] },
    ]);

    // get offer  details by id
    useEffect(() => {
        id && getSingleOffersDetails();
    }, [id]);

    // get importer user list 
    useEffect(() => {
        getImpList();
    }, []);

    // get product list 
    useEffect(() => {
        getProductList();
    }, []);

    //get single details of offer  by id
    const getSingleOffersDetails = async () => {
        const response = await getSingleOfferDetails(id);
        if (response?.success === 200) {
            Object.keys(formKey)?.map((key) => setOfferManagementForm((prev) => ({ ...prev, [key]: response?.data[key], user_id: response?.data?.buyer_id })))
            setFormData(response?.data?.get_map_offer?.map(({ product_id, unit_price, quantity, id }) => ({ id, ["product_id"]: [product_id], ["unit_price"]: [unit_price], ["quantity"]: [quantity] })) ?? []);
        } else {
            console.log(response?.errors);
        }
    }

    // get importer user list 
    const getImpList = async () => {
        const response = await getImportersList();
        if (response?.success === 200) {
            setImporterList(response?.data)
            setIcotermsList(response?.incoterms ?? [])
        } else {
            setImporterList([]);
            setIcotermsList([])
        }
    }

    // get product list 
    const getProductList = async () => {
        const response = await getProductLists();
        if (response?.success === 200) {
            setProductList(response?.data)
        } else {
            setProductList([]);
        }
    }

    //select tab for sidebar
    const selectTab = async (tab) => {
        setActiveTab(tab);
    }

    // input changes
    const handleInputChanges = (e, isAddMore) => {
        const { name, value, type, checked } = e.target;
        if (!isAddMore) {
            setOfferManagementForm((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }))
        } else {

        }
        setFormErrors((prev) => ({ ...prev, [name]: "" }))
    }

    // form submit
    const handleSubmitForm = async (e) => {
        console.log(e,"Eer")
        e.preventDefault();
        setFormErrors({});
        setLoading(true)
        const formDataObj = { ...offerManagementForm, ...getQtyPriceProdId()[0] };
        formDataObj['end_date'] = formDataObj.end_date&&moment(formDataObj.end_date).format('yyyy-MM-DD');
        if (handleFormValidation(formDataObj)) {
            const offerCreateRes = await createProductOffer(formDataObj);
            if (offerCreateRes?.success === 200) {
                setMessage({ text: offerCreateRes?.message, status: true });
                setOfferManagementForm(formKey);
                setTimeout(() => {
                    setLoading(false);
                    setFormData([{ id: 1, product_id: [], unit_price: [], quantity: [] }]);
                    history.push('/marketplace/dashboard');
                }, 1000);

            } else {
                setLoading(false);
                setMessage({ text: lang?.SomeThingsWrong, status: false });
            }
        } else {
            setLoading(false);
        }
    }

    //form validation
    const handleFormValidation = (data) => {
        let isValidationForm = true;
        for (let key in data) {
            if (key !== "shipping_price" && key !== 'icoterm_id' && key !== 'incoterms_text' && key !== 'imp_notes' && key !== "include_shipping_charge" && (typeof data[key] !== "object" && (data[key]).toString()?.trim()?.length === 0) || (typeof data[key] === "object" && data[key]?.length === 0)) {
                isValidationForm = false;
                setFormErrors((prev) => ({ ...prev, index: 0, [key]: "This field is required." }))
            }
        }
        return isValidationForm;
    }

    // select option
    const handleImportChanges = (userId, isImp) => {
        if (isImp) {
            setOfferManagementForm((prev) => ({ ...prev, ["user_id"]: userId }));
            setFormErrors((prev) => ({ ...prev, ["user_id"]: "" }))
        } else {
            setOfferManagementForm((prev) => ({ ...prev, incoterms_text: '', ["icoterm_id"]: userId }));
        }
    }

    //  handle "Add More" button click
    const handleAddMore = () => {
        setFormData((prevData) => [
            ...prevData,
            { id: Date.now(), product_id: [], unit_price: [], quantity: [] }, // Add a new empty input field
        ]);
    };

    // handle multiple form fields value like add more property
    const handleInputChange = (id, field, value, index) => {

        if (field === 'product_id') {
            const minQty = productList?.filter((item) => item?.marketplace_product_id === +value)?.map((item) => item?.min_order_quantity);
            setFormData((prevData) =>
                prevData.map((item) => (item.id === id ? { ...item, [field]: [parseInt(value)], min_order_quantity: minQty } : item))
            );
            setFormErrors((prev) => ({ ...prev, index, [field]: "" }))
        } else if (field === "quantity") {
            let quantityValue = formData[index]?.min_order_quantity[0];
            if (+value >= +quantityValue) {
                setFormErrors((prev) => ({ ...prev, index, quantity: null }));
                setFormData((prevData) =>
                    prevData.map((item) => (item.id === id ? { ...item, [field]: [parseInt(value)] } : item))
                );
            } else {
                setFormErrors((prev) => ({ ...prev, index, quantity: `Quantity should be greater than ${quantityValue} or equal to.` }))
            }
        } else {
            setFormData((prevData) =>
                prevData.map((item) => (item.id === id ? { ...item, [field]: [parseInt(value)] } : item))
            );
            setFormErrors((prev) => ({ ...prev, index, [field]: "" }))
        }
    };

    // get quantity price and product id 
    const getQtyPriceProdId = () => {
        if (Object.keys(formData).every(key => (formData[key] === '' || formData[key]?.length === 0))) {
            return ({ product_id: [], unit_price: [], quantity: [] });
        } else {
            let prod = [];
            let price = [];
            let qty = [];
            return formData?.map(({ product_id, unit_price, quantity }) => {
                prod.push(...product_id)
                price.push(...unit_price)
                qty.push(...quantity)
                return ({ product_id: prod, unit_price: price, quantity: qty })
            }
            );
        }
    }

    // remove fields from form
    const removeFields = (id) => {
        setFormData(formData?.filter((item) => item.id !== id));
    }

    
    return (
        <>
            <div className='servics_blocks dashboardView create-offer'>
                <div className='site-align top-align'>
                    <div className='left__section-block sm:mb-2 md:mb-2'>
                        <div className='tabLeftBlock'><DashboardSideBar lang={lang} selectTab={selectTab} activeTab={activeTab} /></div>
                    </div>
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>

                        <div className="product-title">
                            <div className="flex flex-wrap justify-between items-center">
                                <h4 className="mb-0">
                                    {id ? lang?.Edit : lang?.Send} {lang.Offer}
                                </h4>
                                {message?.text && <p>{message?.text}.</p>}
                                <div className="inner-btn-box">
                                    <Link
                                        to="#"
                                        className="btn btn-blue SaveOffer"
                                        onClick={(e) => !laoding && handleSubmitForm(e)}
                                    >
                                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
                                            <path d="M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.00089 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.00089 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z" fill="white" />
                                        </svg>
                                        {laoding ? `${lang?.Saving}...` : lang?.Send_offer}
                                    </Link>
                                    <Link to="/marketplace/dashboard" className="btn btn--gray" >
                                        <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                                            <path d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z" fill="black" />
                                        </svg>
                                        {lang?.Back}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='product-list'>
                            <div className='recentOrder secBlockGap'>
                                <form className='configure-store-form add-product-page'>
                                    <div className="flexBox gap-4">
                                        <div className="form__item relative searchImporter">
                                            <label>{lang?.Search_for_importer}</label>
                                            <select value={offerManagementForm?.user_id} name="user_id" onChange={(e) => handleImportChanges(e.target.value, 'impUser')}>
                                                <option value={''}>{lang?.Select_imp}</option>
                                                {importerList?.length > 0 && importerList?.map(({ user_id, company_name }) => {
                                                    return (
                                                        <option key={user_id} value={user_id}>{company_name}</option>
                                                    )
                                                })

                                                }
                                            </select>
                                            {formError?.user_id && <p style={{ color: "red" }}>{formError?.user_id}</p>}
                                        </div>
                                        <div className="form__item offer-field">
                                            <label>{lang?.Offer_title}</label>
                                            <input type='text' name='offer_title' placeholder={lang?.Offer_title} value={offerManagementForm?.offer_title} onChange={(e) => handleInputChanges(e, false)} />
                                            {formError?.offer_title && <p style={{ color: "red" }}>{formError?.offer_title}</p>}
                                        </div>

                                        <div className="form__item offer-field">
                                            <label>{lang?.Ending_date}</label>
                                            <ReactDatePicker
                                                selected={offerManagementForm?.end_date}
                                                onChange={(date) => setOfferManagementForm((prev) => ({ ...prev, end_date: date }))}
                                                minDate={new Date()}
                                                placeholderText={lang?.Select_a_date}
                                                dateFormat="dd/MM/yyyy"
                                            />

                                            {formError?.end_date && <p style={{ color: "red" }}>{formError?.end_date}</p>}
                                        </div>

                                        <div className="form__item offer-field">
                                            <div className='important-notes'>
                                                <label>{lang?.Important_notes}</label>
                                                <i class="fa fa-file-text" aria-hidden="true"></i>
                                            </div>
                                            <textarea name='imp_notes' className="cmnt-write" placeholder='Note' value={offerManagementForm?.imp_notes} onChange={(e) => handleInputChanges(e, false)} />
                                            {formError?.imp_notes && <p style={{ color: "red" }}>{formError?.imp_notes}</p>}
                                        </div>

                                    </div>
                                    <div className='flexBox flexStart gap-4'>

                                        <div className="form__item">
                                            <label className='relative'>{lang?.Incoterms}
                                                <span className='pl-2' title="This store follow Incoterms.">
                                                    <i className="fa fa-exclamation-circle infoIcon" aria-hidden="true" onClick={() => setIncoTerm(!incoTerm)}></i>
                                                </span>
                                            </label>

                                            <div className=''>
                                                <select value={offerManagementForm?.icoterm_id} name="icoterm_id" onChange={(e) => handleImportChanges(e.target.value)}>
                                                    <option value={''}>{lang?.Select_incoterms}</option>
                                                    {IcotermsList?.length > 0 && IcotermsList?.map(({ id, incoterms }) => {
                                                        return (
                                                            <option key={id} value={id}>{incoterms}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                                {formError?.icoterm_id > 0 && <p style={{ color: "red" }}>{formError?.icoterm_id}</p>}
                                            </div>

                                        </div>
                                        <div className="form__item offer-field destination">
                                            <div className='important-notes'>
                                                <label>{lang?.Incoterm_text} </label>
                                            </div>
                                            <input type="text" placeholder={lang?.Incoterm_text} name="incoterms_text" value={offerManagementForm?.incoterms_text} onChange={(e) => handleInputChanges(e, false)} />
                                            {formError?.incoterms_text > 0 && <p style={{ color: "red" }}>{formError?.incoterms_text}</p>}                                            
                                            <p className='pl--0'>Sei a conoscenza di tutti i vincoli contrattuali collegati a questo termine di consegna? Per saperne di più: <a href="mailto:business&development@ormesani.it">business&development@ormesani.it</a></p>
                                        </div>
                                    </div>
                                    <div className="outer">
                                        {formData?.map(({ id, quantity, unit_price, product_id }, i) => {
                                            return (
                                                <div className="flexBox" key={id}>
                                                    <div className="form__item">
                                                        <label>{lang?.Select_product}</label>
                                                        <select name="product_id" value={product_id || []} onChange={(e) => handleInputChange(id, 'product_id', e.target.value, i)}>
                                                            <option value={''}>{lang?.Select_product}</option>
                                                            {productList?.length > 0 && productList?.map(({ marketplace_product_id, title }) => {
                                                                return (
                                                                    <option value={marketplace_product_id} key={marketplace_product_id}>{title}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {formError?.product_id && <p style={{ color: "red" }}>{formError?.product_id}</p>}
                                                    </div>
                                                    <div className="form__item offer__quantity">
                                                        <label>{lang?.Quantity}</label>
                                                        <input type="number" name="quantity" disabled={product_id?.length > 0 ? false : true} defaultValue={quantity} placeholder="0" onChange={(e) => handleInputChange(id, 'quantity', e.target.value, i)} />

                                                        {(formError?.quantity && formError?.index === i) && <p style={{ color: "red" }}>{formError?.quantity}</p>}
                                                    </div>
                                                    <div className="form__item offer-price">
                                                        <label>{lang?.Per_unit_rice}</label>
                                                        <div className='withinfoIcon relative onlyUsd'>
                                                            <input type="number" name="unit_price" defaultValue={unit_price} placeholder="0.00" onChange={(e) => handleInputChange(id, 'unit_price', e.target.value, i)} minLength={10} />
                                                            {formError?.unit_price && <p style={{ color: "red" }}>{formError?.unit_price}</p>}
                                                            <span className="usd"><i className="fa fa-usd" aria-hidden="true"></i></span>
                                                        </div>
                                                    </div>
                                                    <div className='form__item'>
                                                        <label></label>
                                                        <div className=''>
                                                            <a type='button' onClick={() => formError?.quantity === null && handleAddMore()} className='btn btn-blue'>{lang?.Add_more}</a>
                                                        </div>
                                                    </div>
                                                    {formData?.length > 1 && <div className="form__item"><div className='iconGroup' onClick={() => removeFields(id)}>
                                                        <span className='deleteBlock'>
                                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                        </span>
                                                    </div></div>}
                                                </div>)
                                        })}

                                    </div>
                                    <div className="flexBox flexStart gap-4">
                                        <div className="form__item searchImporter">
                                            <label>{lang?.Shipping_price}</label>
                                            <div className='withinfoIcon relative onlyUsd'>
                                                <input type="text" placeholder={lang?.Shipping_price} name="shipping_price" value={offerManagementForm?.shipping_price} onChange={(e) => handleInputChanges(e, false)} />
                                                {formError?.shipping_price > 0 && <p style={{ color: "red" }}>{formError?.shipping_price}</p>}
                                                <span className="usd"><i className="fa fa-usd" aria-hidden="true"></i></span>
                                            </div>

                                        </div>
                                        <div className="form-checkbox-contents form__item ImportCharges">
                                            <div className="hub--checkbox--area">
                                                <label></label>
                                                <input className='form-check-input' type="checkbox" id="check_box" name="include_shipping_charge" checked={Boolean(offerManagementForm?.include_shipping_charge)} value={offerManagementForm?.include_shipping_charge} onChange={(e) => handleInputChanges(e, false)} />
                                                <label className="form-check-label" htmlFor="check_box">{lang?.Includes_shipping_charges}</label>
                                                {formError?.include_shipping_charge && <p style={{ color: "red" }}>{formError?.include_shipping_charge}</p>}
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    {/* Incoterm Poppup */}
                    {incoTerm &&
                        <IncoTermModal lang={lang} incoTerm={incoTerm} setIncoTerm={setIncoTerm}/>
                    }
                </div>
            </div >
        </>
    )
}
