import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from "react-router-dom";
import ConnectionModal from '../../../components/ConnectionModals/ConnectionModal';
import Certificate from '../../../components/user/certificates';
import constant from '../../../helpers/constant';
import language from '../../../helpers/language';
import { updateUserCertificate } from '../modules/networkConnection';

export default function UserCertificate(props) {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [openConnectionModel, setOpenConnectionModel] = useState(false);
    let userId = params.id;
    let userName = params.slug;

    const [fdaNo, setfdaNo] = useState('');
    const [vatNo, setvatNo] = useState('');
    const [lang] = useState(language.getLang());

    const { cuserCertificateSpinner } = useSelector((state) => state.networkConnectionState);
    const handleModal = (newValue) => {
        setOpenConnectionModel(newValue);
    }

    const handleConnet = async () => {
        const model = {
            vat_no: vatNo,
            fda_no: fdaNo,
            // user_field_option_id: fieldId
        }
        await dispatch(updateUserCertificate(model))
        await setOpenConnectionModel(!openConnectionModel)
    }


    return (
        <div className='viewRequest'>
            <div className='discover_page mb-4'>
                <div className='page_top_form'>
                    <div className="step">
                        <div className="cancel">
                            <Link to="#" onClick={() => history.goBack()}>
                                <span>
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;
                                    {lang?.View_Request}
                                </span>
                            </Link>
                        </div>
                        <div className="right__align_btn btn-white">
                        </div>
                    </div>
                </div>
            </div>
            <div className='tab__list'>
                <div className='pageWCenter pt-6 pb-6'>
                    <Certificate
                        handleFda={(val,vatNo) =>{ setfdaNo(val);setvatNo(vatNo)}}
                    />
                    {!cuserCertificateSpinner && <div className="view__hub__btn md:w-1/3 m-auto">
                        <a className="btn BgTeal" onClick={() => handleConnet()}>{lang?.Connect}</a>
                    </div>}
                </div>
                {/* connect Popup Start */}
                {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, type: constant.rolesId["importer&distributer"] }} />}
                {/* connect Popup End */}
            </div >
        </div>
    )
}
