/* @format */

import React, { useState, Fragment, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Autocomplete from "../../../components/Map/Address_search";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import mapUrl from "../../../assets/images/map.png";
import Map from "../../../components/Map/MapHubs";
import constant from "../../../helpers/constant";
import FormError from "../../../components/Errors/FormError";
import language from "../../../helpers/language";
import Modal from "react-modal";

import {
  getHubState,
  getHubsState,
  getStateOfCity,
  reviewHubs,
  getUpcomingCountries,
  postSlectedHubs,
  updateReviewHubs,
  deleteAllHubs,
  getHubStateBySearch,
} from "../modules/hubs";
import { checkSubscription } from "../../home/modules/HomeModule";

export function HubView(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isHubs, setIsHubs] = useState(false);
  const [isSelectedCountryId, setIsSelectCountryId] = useState();
  const [isSelectedHubName, setIsSelectHubName] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [lang] = useState(language.getLang());
  const [nextButton, setNextButton] = useState(false);
  const [msg, setMsg] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [radius, setRadius] = useState(0);
  const [mapZoom, setMapZoom] = useState(7);
  const [isSavedHubs, setIsSavedHubs] = useState(false);
  const [isStatesShow, setIsStatesShow] = useState(false);
  const [isCitiesShow, setIsCitiesShow] = useState(false);
  const [isSelectedHubs, setIsSelectedHubs] = useState([]);
  const [isSelectedCities, setIsSelectedCities] = useState([]);
  const [selectedHubsArray, setSelectedHubsArray] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedStateCityArray, setSelectedStateCityArray] = useState([]);
  const [ActiveNo, setActiveNo] = useState(0);
  const {
    getHubs,
    selectedState,
    hubsSpinner,
    reviewHubsData,
    upcomingHubCountries,
    selectedCityArray,
  } = useSelector((state) => state.hubs_State);
  const [selectedStatesData, setSelectedStatesData] = useState("");
  const [isCountryStatesData, setIsCountryStatesData] = useState("");

  const [selectedCitiesData, setSelectedCitiesData] = useState("");
  const [isCountryStatesCitiesData, setIsCountryStateCitiessData] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [onSavedBackLink, setOnSavedBackLink] = useState(true);
  const [onBackChooseHub, setOnBackChooseHub] = useState(true);
  const [isDeletedAllHub, setIsDeletedAllHub] = useState(false);
  const [deleteHubLoader, setDeleteHubLoader] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [error,setError]=useState()
  
  const [isOpen, setIsOpen] = useState(false);
  const { get_Subscription_Status_Data } = useSelector((state) => state.activityFeedStatus);
  let subscriptionData=get_Subscription_Status_Data?.subscription?.subscription_plan
  let subscriptionDataModified=subscriptionData?.hubs_subscription==-1?10000000000:subscriptionData?.hubs_subscription


console.log(isSelectedHubs,"isSelectedHubNameisSelectedHubName")
  useEffect(()=>{
    dispatch(checkSubscription())
  },[])

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
      setIsOpen(true);
    };


  useEffect(async () => {
    await setUserRole(upcomingHubCountries?.data?.role_id);
  }, [userRole, upcomingHubCountries]);

  useEffect(async () => {
    await dispatch(reviewHubs());
    await dispatch(getUpcomingCountries());
  }, []);

  useEffect(() => {
    reviewHubsData?.data &&
      reviewHubsData?.data?.map(async (selectedHub) => {
        await dispatch(getHubState(selectedHub.country_id));
        await setIsSelectCountryId(selectedHub.country_id);
        await setIsSelectHubName(selectedHub.country_name);
      });
  }, [reviewHubsData]);

  //console.log(reviewHubsData,"data00")

  useEffect(async () => {
    setIsCountryStatesData(selectedState.data);
    setSelectedStatesData(selectedState);
  }, [selectedState]);

  useEffect(async () => {
    setSelectedCitiesData(selectedCityArray.data);
    setIsCountryStateCitiessData(selectedCityArray);
  }, [selectedCityArray]);

 

  useEffect(() => {
  
    if (selectedHubsArray.length == 0) {
      
      getHubs.data &&
        getHubs.data.hubs.map((items, index) => {
          items.hubs_array.map(async (hubArray) => {
            console.log(hubArray,"hubArrar")
            if (hubArray.is_selected == true || hubArray.is_checked == true) {
              const selectHub = {
                hubId: hubArray.id,
                image: hubArray.image,
                title: hubArray.title,
                stateId: hubArray.state_id,
                countryId: hubArray.country_id,
                isSelected: hubArray.is_selected,
              };
              await setSelectedHubsArray((selectedHubsArray) => [
                ...selectedHubsArray,
                selectHub,
              ]);
              
              await setIsSelectedHubs((isSelectedHubs) => [
                ...isSelectedHubs,
                hubArray.id,
              ]);
            }
            if (index == 0) {
              await setLat(hubArray.latitude);
              await setLng(hubArray.longitude);
              await setRadius(hubArray.radius);
            }
          });
        });
    }
  }, [getHubs]);

  useEffect(async () => {
    if (selectedStateCityArray.length == 0) {
      reviewHubsData?.data &&
        reviewHubsData?.data.map((hubsSelectedCity) => {
          hubsSelectedCity.cities.length > 0 &&
            hubsSelectedCity.cities.map(async (selectedCity) => {
              const selectState = {
                state_name: selectedCity.state.name,
                state_id: selectedCity.state.id,
              };
              await setSelectedStates((selectedStates) => [
                ...selectedStates,
                selectState,
              ]);
              const selectCity = {
                city_name: selectedCity.city.name,
                state_name: selectedCity.state.name,
                state_id: selectedCity.state.id,
              };
              await setIsSelectedCities((isSelectedCities) => [
                ...isSelectedCities,
                selectCity,
              ]);

              const selected_State_City = {
                city_id: selectedCity.city_id,
                country_id: selectedCity.country_id,
                state_id: selectedCity.state.id,
              };
              await setSelectedStateCityArray((selectedStateCityArray) => [
                ...selectedStateCityArray,
                selected_State_City,
              ]);
            });
          setIsSavedHubs(true);
          setIsHubs(true);
        });
    }
  }, [reviewHubsData]);

  // Click hub next button
  const handleClick = async (countryId, countryName) => {
    console.log(countryId,countryName,"countyrrrrh")
    await dispatch(getHubState(countryId));
    await setIsSelectCountryId(countryId);
    await setIsSelectHubName(countryName);
    await dispatch(getHubsState(countryId));
    setNextButton(true);
  };

  const handleHUbs = async () => {
    await setIsHubs(true);
    await setIsSelected(true);
    await setOnSavedBackLink(false);
  };

  const deleteCity = async (indx) => {
    isSelectedCities.splice(indx, 1);
    await setIsSelectedCities([...isSelectedCities]);
    selectedStateCityArray.splice(indx, 1);
    await setSelectedStateCityArray([...selectedStateCityArray]);
  };

  const handleHubSelect = async (event, hubArray) => {
    let selectedMyHub = isSelectedHubs;
    if (event.target.checked) {
      const selectHub = {
        hubId: hubArray.id,
        image: hubArray.image,
        title: hubArray.title,
        stateId: hubArray.state_id,
        countryId: hubArray.country_id,
        isSelected: hubArray.is_selected,
      };
      await setSelectedHubsArray((selectedHubsArray) => [
        ...selectedHubsArray,
        selectHub,
      ]);

      await setIsSelectedHubs((isSelectedHubs) => [
        ...isSelectedHubs,
        hubArray.id,
      ]);
      const checkAddedHub = selectedMyHub.indexOf(hubArray.id);
      if (checkAddedHub === -1) {
        selectedMyHub.push(hubArray.id);
      }
    } else {
      const index = isSelectedHubs.indexOf(hubArray.id);
      const idex = selectedHubsArray.findIndex(
        (obj) => obj.hubId == hubArray.id
      );
      if (index > -1) {
        isSelectedHubs.splice(index, 1);
        await setIsSelectedHubs([...isSelectedHubs]);
        selectedHubsArray.splice(idex, 1);
        await setSelectedHubsArray([...selectedHubsArray]);
        selectedMyHub = isSelectedHubs;
      }
    }

    //console.log(selectedMyHub,"selectedMyHub");
    var selectedAllChecked = [];
    let checkedHubStateData = await getHubs?.data?.hubs.find(function (
      element
    ) {
      return element.state_id === hubArray.state_id;
    });

    checkedHubStateData &&
      checkedHubStateData.hubs_array.map((val) => {
        const index = selectedMyHub.indexOf(val.id);
        //console.log(selectedMyHub,"idex");
        if (index > -1) {
          selectedAllChecked.push(val.id);
        }
      });
    // console.log(selectedAllChecked,"selectedAllChecked");
    // console.log(checkedHubStateData.hubs_array,"checkedHubStateData");
    document.getElementById(hubArray.state_id).checked =
      selectedAllChecked.length === checkedHubStateData?.hubs_array.length
        ? true
        : false;
  };

  const nextToSaveSelectedHubs = async () => {
    if (isSelectedHubs.length > 0 || selectedStates.length > 0) {
      await setIsHubs(true);
      await setIsSelected(false);
      await setIsSavedHubs(true);
    } else {
      await setTextMsg(lang?.chooseHubOrStates);
      setMsg(true);
      setTimeout(() => {
        setMsg(false);
        setTextMsg("");
      }, 5000);
    }
  };


  const selectStates = async () => {
    await setIsSelected(false);
    await setIsStatesShow(true);
    await dispatch(getHubsState(isSelectedCountryId));
  };

  const selectStateWithId = async (event, stateData) => {
    if (event.target.checked) {
      const selectState = {
        state_name: stateData.name,
        state_id: stateData.id,
      };
      await setSelectedStates((selectedStates) => [
        ...selectedStates,
        selectState,
      ]);
    } else {
      const selectedStateArray = [];
      const newStateArray = [];
      const newStateCitiesArray = [];
      selectedStates.length > 0 &&
        selectedStates.map((state) => {
          if (state.state_id != stateData.id) {
            const selected_State_City = {
              state_name: state.state_name,
              state_id: state.state_id,
            };

            selectedStateArray.push(selected_State_City);
          }
        });
      await setSelectedStates(selectedStateArray);

      isSelectedCities.length > 0 &&
        isSelectedCities.map((state) => {
          if (state.state_id != stateData.id) {
            const selected_State_City = {
              city_name: state.city_name,
              state_name: state.state_name,
              state_id: state.state_id,
            };

            newStateArray.push(selected_State_City);
          }
        });
      await setIsSelectedCities(newStateArray);

      selectedStateCityArray.length > 0 &&
        selectedStateCityArray.map((state) => {
          if (state.state_id != stateData.id) {
            const selected_State_City = {
              city_id: state.city_id,
              country_id: state.country_id,
              state_id: state.state_id,
            };

            newStateCitiesArray.push(selected_State_City);
          }
        });
      await setSelectedStateCityArray(newStateCitiesArray);
    }
  };

  // search in states
  const handleSearchStates = (event) => {
    const stateName = event.target.value;
    if (stateName != "") {
      const results = selectedStatesData.data.filter((findState) => {
        return findState.name.toLowerCase().includes(stateName.toLowerCase());
      });
      //console.log(results, "stateName");
      setIsCountryStatesData(results);
    } else {
      setIsCountryStatesData(selectedStatesData.data);
    }
  };

  const handleclickNextCities = async () => {
    if (selectedStates.length > 0) {
      var result = selectedStates.reduce((unique, o) => {
        if (!unique.some((obj) => obj.state_id === o.state_id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      await setSelectedStates(result);
      await setIsStatesShow(false);
      await setIsCitiesShow(true);
      await dispatch(getStateOfCity(result[0].state_id));
    } else {
      await setTextMsg(lang?.onlyOneState);
      setMsg(true);
      setTimeout(() => {
        setMsg(false);
        setTextMsg("");
      }, 5000);
    }
  };

  // Click on State name on city tabs
  const getCityFun = async (id, index) => {
    setActiveNo(index);
    await dispatch(getStateOfCity(id));
  };

  const handleCityChange = async (event, cityData) => {
    //console.log(cityData, "cityData");
    if (event.target.checked) {
      const selected_State_City = {
        city_id: cityData.id,
        country_id: cityData.country_id,
        state_id: cityData.state_id,
      };
      await setSelectedStateCityArray((selectedStateCityArray) => [
        ...selectedStateCityArray,
        selected_State_City,
      ]);

      const selectCity = {
        city_name: cityData.name,
        state_name: cityData.state.name,
        state_id: cityData.state_id,
      };
      await setIsSelectedCities((isSelectedCities) => [
        ...isSelectedCities,
        selectCity,
      ]);
    } else {
      const idex = selectedStateCityArray.findIndex(
        (obj) => obj.city_id === cityData.id
      );
      if (idex > -1) {
        selectedStateCityArray.splice(idex, 1);
        await setSelectedStateCityArray([...selectedStateCityArray]);
      }

      const index = isSelectedCities.findIndex(
        (obj) => obj.city_name === cityData.name
      );
      if (index > -1) {
        isSelectedCities.splice(idex, 1);
        await setIsSelectedCities([...isSelectedCities]);
      }
    }
  };

  const searchCities = async (event) => {
    const cityName = event.target.value;
    if (cityName != "") {
      const results = isCountryStatesCitiesData.data.filter((findCity) => {
        return findCity.name.toLowerCase().includes(cityName.toLowerCase());
      });
      //console.log(results, "stateName");
      setSelectedCitiesData(results);
    } else {
      setSelectedCitiesData(isCountryStatesCitiesData.data);
    }
  };

  const nextToSubmitScreen = async () => {
    let errorMessage = "";
    selectedStates?.length > 0 &&
      selectedStates?.map(async (item) => {
        const checkSelectedCityId = selectedStateCityArray.findIndex(
          (obj) => obj.state_id === item.state_id
        );
        if (checkSelectedCityId === -1) {
          errorMessage = "empty";
        }
      });

    if (errorMessage != "") {
      await setTextMsg(lang?.onlyOneCity);
      setMsg(true);
      setTimeout(() => {
        setMsg(false);
        setTextMsg("");
      }, 3000);
    } else {
      await setIsCitiesShow(false);
      await setIsSavedHubs(true);
    }
  };

  const removeAllHubs = async () => {
    await setDeleteHubLoader(true);
    const response = dispatch(deleteAllHubs());
    //console.log(response, 'response');
    if (response) {
      await setOnBackChooseHub(true);
      await setIsSelectedHubs([]);
      await setIsSelectedCities([]);
      await setSelectedHubsArray([]);
      await setSelectedStates([]);
      await setSelectedStateCityArray([]);
      await setIsSelected(false);
      await setNextButton(false);
      await setIsStatesShow(false);
      await setIsCitiesShow(false);
      await setIsSavedHubs(false);
      await setIsHubs(false);
      await setDeleteHubLoader(false);
      await setIsDeletedAllHub(false);
    } else {
      await setIsDeletedAllHub(false);
    }
  };

  //Submit && Save Hubs States cities
  const submitPostHubs = async () => {
    if (isSelectedHubs.length > 0 || selectedStates.length > 0) {
      await setIsSubmit(true);
      const params = {
        add_or_update: 2, // for edit 2, for add 1
        selectedhubs: isSelectedHubs,
        selectedcity: selectedStateCityArray,
      };
      //console.log(params, "params");
      const response = await dispatch(postSlectedHubs(params));
      if (response.success === 200) {
        await dispatch(updateReviewHubs());
        await setIsSelectedHubs([]);
        await setIsSelectedCities([]);
        await setSelectedHubsArray([]);
        await setSelectedStates([]);
        await setSelectedStateCityArray([]);
        await setIsSubmit(false);
        history.push("/setting");
      } else {
        setError(`You cannot subscribe hubs more than ${get_Subscription_Status_Data?.subscription?.subscription_plan?.hubs_subscription}`)
        history.push('/hubs#error')
        await setIsSubmit(false);
        setTimeout(()=>{
          setError("")
        },4000)
      }
    } else {
      await setTextMsg(lang?.chooseHubOrStates);
      setMsg(true);
      setTimeout(() => {
        setMsg(false);
        setTextMsg("");
      }, 5000);
    }
  };

  const hubMoveOnBackStep = async () => {
    if (onBackChooseHub) {
      await setIsSelected(false);
      await setIsHubs(false);
      await setNextButton(false);
    } else {
      await setIsSelected(false);
      await setIsSavedHubs(true);
    }
  };

  const moveOnHubEditStep = async () => {
    await setIsStatesShow(false);
    await setIsSelected(true);
  };

  const moveOnStatesStep = async () => {
    await setIsCitiesShow(false);
    await setIsStatesShow(true);
  };


  const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  const selectAllHubs = async (e, stateId) => {
    var stateHubArray = getHubs.data.hubs.find(function (element) {
      return element.state_id === stateId;
    });
    if (e.target.checked) {
      stateHubArray?.hubs_array.map(async (hubArray) => {
        const hubSelected = isSelectedHubs.findIndex(
          (obj) => obj == hubArray.id
        );
        if (hubSelected === -1) {
          const selectHub = {
            hubId: hubArray.id,
            image: hubArray.image,
            title: hubArray.title,
            stateId: hubArray.state_id,
            countryId: hubArray.country_id,
            isSelected: hubArray.is_selected,
          };
          await setSelectedHubsArray((selectedHubsArray) => [
            ...selectedHubsArray,
            selectHub,
          ]);
          await setIsSelectedHubs((isSelectedHubs) => [
            ...isSelectedHubs,
            hubArray.id,
          ]);
        }
        document.getElementById(hubArray.id).checked = true;
      });
    } else {
      stateHubArray?.hubs_array.map(async (hubArray) => {
        const index = isSelectedHubs.indexOf(hubArray.id);
        const idex = selectedHubsArray.findIndex(
          (obj) => obj.hubId == hubArray.id
        );
        if (index > -1) {
          isSelectedHubs.splice(index, 1);
          setIsSelectedHubs([...isSelectedHubs]);
          selectedHubsArray.splice(idex, 1);
          setSelectedHubsArray([...selectedHubsArray]);
          document.getElementById(hubArray.id).checked = false;
        }
      });
    }
  };

  const getHubsInSearchArea = async (lat, log) => {
    await dispatch(getHubStateBySearch({ latitude: lat, longitude: log, country_id: isSelectedCountryId }));
    setTabIndex(0);
  }
  const handleDivClick = (e) => {
    e.preventDefault(); // Prevent the default behavior
    // Add your custom logic here, e.g., open a modal, execute a function, etc.
  };

  return (
    <Fragment>
      {/* Choose Country Code Start */}
      {!isHubs && (
        <div className="recipe__page">
          <div className="hub__selection--form bg-white ">
            <div className="step mb-4">
              <div className="cancel">
                <a href="/setting">
                  <span>
                    <i className="fa fa-arrow-left pr-3" aria-hidden="true"></i>
                  </span>
                </a>
              </div>
            </div>
            <form className="hub__form">
              <div className="form--inner--div">
                <div className="flex flex-wrap">
                  <div className="xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-full">
                    <div className="coln-left">
                      <h4 className="title-blue-font-30">
                        {lang?.Where_you_want_to_export}
                      </h4>
                      <p className="title-para">{lang?.Choose_Your_Country}</p>
                    </div>
                  </div>
                  <div className="xl:w-3/5 lg:w-3/5 md:w-3/5 sm:w-full">
                    <div className="coln-right">
                      <div className="hubMap">
                        <div className="country__button">
                          {upcomingHubCountries &&
                            upcomingHubCountries?.data?.active_countries
                              ?.length > 0 &&
                            upcomingHubCountries?.data?.active_countries?.map(
                              (country) => {
                                return (
                                  <button
                                    type="button"
                                    className="btn usa__flag"
                                    onClick={() =>
                                      handleClick(country.id, country.name)
                                    }
                                  >
                                    <img
                                      src={`${country?.flag_id?.base_url}${country?.flag_id?.attachment_url}`}
                                      alt="USA Flag"
                                    />
                                    {country.name}
                                  </button>
                                );
                              }
                            )}
                        </div>
                        <img src={mapUrl} alt="Map" />
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {!nextButton ? (
                  <div className="bg-btn-gray ">
                    <button type="button" className="btn ml-auto">
                      {lang?.Next}
                    </button>
                  </div>
                ) : (
                  <div className="bg-btn-gray sky-blue">
                    <button
                      type="button"
                      className="btn ml-auto"
                      onClick={() => handleHUbs()}
                    >
                      {lang?.Next}
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Choose Country Code End */}

      {/* Choose Hubs Code Start */}
      {isSelected && (
        <div className="recipe__page">
          <div className="hub__selection--form bg-white hubs">
            <div className="step mb-4">
              <div className="cancel">
                <div
                  className="link"
                  style={{ cursor: "pointer" }}
                  onClick={() => hubMoveOnBackStep()}
                >
                  <span>
                    <i className="fa fa-arrow-left pr-3" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <form className="hub__form padding--5" onSubmit={handleDivClick}>
              <div className="form--inner--div 1">
                <div className="topTitle top___Title">
                  <h4 className="title-blue-font-30 ">{lang?.Selected_Hubs}</h4>
                  <div className="alert alert-primary">
                    {reviewHubsData?.hubInfo?.message}.
                  </div>
                  <div>
                  </div>
                  <div className="alert alert-primary  location-map">
                    <span className="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                    {isSelected && <Autocomplete
                      onPlaceSelected={getHubsInSearchArea}
                      placeHolder={lang?.Enter_location}
                    />}
                  </div>
                </div>

                {/* Hub View City List Updated UI */}
                {hubsSpinner ? (
                  <FormSpinner />
                ) : (
                  <div className="hub--row">
                    <div className="hub--tab--left">
                      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList className="hubCityList">
                          {/* <div className='cityList select--all'>
                          <div className='checkBox'>
                            <input type="checkbox" name="hub-city" id="select-all" />
                            <label className='chicago-hub' htmlFor="select-all">Select All</label>
                          </div>
                        </div> */}
                          {getHubs?.data?.hubs?.map((item, index) => {
                            var selectedAllChecked = [];
                            item &&
                              item.hubs_array.map((val) => {
                              
                                const idex = isSelectedHubs.findIndex(
                                  (obj) => obj == val.id
                                );
                               
                                if (idex > -1) {
                                  selectedAllChecked.push(val.id);
                                }
                              });
                            return (
                              <Tab className="cityList" key={item.state_id}>
                                <div className="checkBox">
                                  <input
                                    type="checkbox"
                                    name="hub-city"
                                    defaultChecked={
                                      selectedAllChecked.length ===
                                        item.hubs_array.length
                                        ? true
                                        : false
                                    }
                                    id={item.state_id}
                                    key={index}
                                    onChange={(e) => selectAllHubs(e, item.state_id)}
                                  />
                                  <label
                                    className="chicago-hub"
                                    htmlFor={item.state_id}
                                  >
                                  </label>
                                  {item.state_name}
                                </div>
                              </Tab>
                            );
                          })}
                        </TabList>
                        {getHubs &&
                          getHubs.data &&
                          getHubs.data.hubs.map((item, index) => {
                            var selectedAllChecked = [];
                            item &&
                              item.hubs_array.map((val) => {
                                const idex = isSelectedHubs.findIndex(
                                  (obj) => obj == val.id
                                );
                                if (idex > -1) {
                                  selectedAllChecked.push(val.id);
                                }
                              });
                            return (
                              <TabPanel>
                                <div className="city--list--row">
                                  {item &&
                                    item.hubs_array.map((val, index) => {
                                      const idex = isSelectedHubs.findIndex(
                                        (obj) => obj == val.id
                                      );
                                      var checkeBoxDisabled = false;
                                      if (
                                        userRole ===
                                        constant?.rolesId?.restaurant &&
                                        isSelectedHubs.length === 1 &&
                                        !isSelectedHubs.includes(val.id)
                                      ) {
                                        checkeBoxDisabled = true;
                                      }
                                      return (
                                        <div className="hub--city--img hubcity___img">
                                          <div className="hub--tab">
                                            <div
                                              className="city--bg--img"
                                              style={{
                                                backgroundImage: `url("${val?.image?.base_url}${val?.image?.attachment_url}")`,
                                              }}
                                              onClick={() => {
                                                setLat(
                                                  parseFloat(val.latitude)
                                                );
                                                setLng(
                                                  parseFloat(
                                                    val.longitude
                                                  )
                                                );
                                                setRadius(val.radius);
                                                setMapZoom(8);
                                              }}
                                            ></div>
                                            <div className="hub--checkbox--area">
                                              {idex > -1 ? (
                                                <input
                                                  type="checkbox"
                                                  defaultChecked
                                                  id={val.id}
                                                  name={val.state_id}
                                                  // disabled={val?.is_checked}
                                                  onChange={(event) => {
                                                    handleHubSelect(event, val);
                                                  }}
                                                />
                                              ) : (
                                                <input
                                                  type="checkbox"
                                                  id={val.id}
                                                  name={val.state_id}
                                                  defaultChecked={
                                                    val?.is_checked
                                                  }
                                                  onChange={(event) => {
                                                    handleHubSelect(event, val);
                                                  }}
                                                  disabled={checkeBoxDisabled}
                                                />
                                              )}
                                              <label
                                                className="hub--city--name"
                                                htmlFor={val.id}
                                              >
                                                <span>{val.title}</span>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </TabPanel>
                            );
                          })}
                      </Tabs>
                    </div>
                    <div className="hub--tab--right">
                      <div className="map--view">
                        <Map
                          className="mapGroup"
                          google={props.google}
                          center={{ lat: lat, lng: lng }}
                          height="22rem"
                          zoom={mapZoom}
                          radius={radius * 1609.344}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="errorMessage">
                  {textMsg !== "" && msg && <FormError msg={textMsg} />}
                </div>
                <div className="flex justify-between items-center mt-2">
                  <div className="hub__footer">
                    <p>
                      {lang?.Do_not_recognize_hub}{" "}
                      <Link to="#">
                        <span onClick={() => selectStates()}>
                          {lang?.Click_Here}
                        </span>
                      </Link>
                    </p>
                  </div>
                  <div className="bg-btn-gray sky-blue">
                    {
                      userRole==3?(
                        selectedHubsArray.length<=subscriptionDataModified?
                        (
                          <button
                              type="button"
                              className="btn"
                              onClick={() => nextToSaveSelectedHubs()}
                            >
                              {lang?.Next}
                         </button>
                        ):(
                            <button
                            type="button"
                            className="btn"
                              onClick={() => handleOpenModal()}
                            >
                              {lang?.Next}
                           </button>
                        ) 
                      ):(
                        <>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => nextToSaveSelectedHubs()}
                          >
                            {lang?.Next}
                       </button>
                        </>
                      )
                    }
                    {/* {
                      selectedHubsArray.length<=subscriptionDataModified?
                      (
                        <button
                            type="button"
                            className="btn"
                            onClick={() => nextToSaveSelectedHubs()}
                          >
                            {lang?.Next}
                       </button>
                      ):(
                          <button
                          type="button"
                          className="btn"
                            onClick={() => handleOpenModal()}
                          >
                            {lang?.Next}
                         </button>
                      )
                    } */}
                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Choose Hubs Code End */}

      {/* Choose State code Start */}
      {isStatesShow && (
        <div className="recipe__page">
          <div className="hub__selection--form bg-white selectedState city">
            <div className="step mb-4">
              <div className="cancel">
                <div
                  className="link"
                  style={{ cursor: "pointer" }}
                  onClick={() => moveOnHubEditStep()}
                >
                  <span>
                    <i className="fa fa-arrow-left pr-3" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <form className="hub__form padding--5">
              <div className="form--inner--div 2">
                <div className="flex">
                  <div className="w-full">
                    <div className="coln-left">
                      <h4 className="title-blue-font-30">
                        {isSelectedHubName}
                      </h4>
                      <p className="title-para">{lang?.selectState}</p>
                      <div className="form__item searchIcon mt-3">
                        <span className="inputSearch">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                        <input
                          type="text"
                          className=""
                          placeholder="Search states"
                          onChange={(e) => handleSearchStates(e)}
                        />
                      </div>
                      <div className="flex justify-end mt-5 md:mb-4 sm:mb-4">
                        <div className="bg-btn-gray sky-blue">
                          <button
                            type="button"
                            className="btn"
                            onClick={handleclickNextCities}
                          >
                            {lang?.Next}
                          </button>
                        </div>
                      </div>
                      {textMsg !== "" && msg && <FormError msg={textMsg} />}
                      {hubsSpinner ? (
                        <FormSpinner />
                      ) : (
                        <div className="hub__tab padding--5">
                          <div className="choose__hub">
                            {isCountryStatesData &&
                              isCountryStatesData?.map((singleState, index) => {
                                const idex = selectedStates.findIndex(
                                  (obj) => obj.state_id == singleState.id
                                );
                                return (
                                  <div
                                    className="form__item form__checkbox country-check"
                                    key={index}
                                  >
                                    {idex > -1 ? (
                                      <input
                                        type="checkbox"
                                        id={singleState.id}
                                        defaultChecked={"true"}
                                        name={singleState.name}
                                        onClick={(e) =>
                                          selectStateWithId(e, singleState)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        id={singleState.id}
                                        name={singleState.name}
                                        onClick={(e) =>
                                          selectStateWithId(e, singleState)
                                        }
                                      />
                                    )}
                                    <label
                                      htmlFor={singleState.id}
                                      className="flex p-4 rounded-lg"
                                    >
                                      <span>{singleState.name}</span>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/*Choose State code end */}

      {/*Choose Cities code start */}
      {isCitiesShow && (
        <div className="recipe__page">
          <div className="hub__selection--form bg-white selectedState city">
            <div className="step mb-4">
              <div className="cancel">
                <div
                  className="link"
                  style={{ cursor: "pointer" }}
                  onClick={() => moveOnStatesStep()}
                >
                  <span>
                    <i className="fa fa-arrow-left pr-3" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <form className="hub__form padding--5">
              <div className="form--inner--div 3">
                <div className="flex">
                  <div className="w-full">
                    <div className="coln-left">
                      <h4 className="title-blue-font-30">
                        {isSelectedHubName}
                      </h4>
                      <p className="title-para">Select Cities</p>

                      <div className="form__item searchIcon mt-3">
                        <span className="inputSearch">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                        <input
                          type="text"
                          className=""
                          placeholder="Search City"
                          onChange={(e) => searchCities(e)}
                        />
                      </div>

                      <div className="flex justify-end mt-4 mb-4">
                        <div className="bg-btn-gray sky-blue">
                          {
                            <button
                              type="button"
                              className="btn"
                              onClick={nextToSubmitScreen}
                            >
                              {lang?.Next}
                            </button>
                          }
                        </div>
                      </div>
                      {/* Hub Selected List*/}
                      <div className="selectedHubList">
                        <ul className="flex flex-wrap pb-4 selected--city">
                          {selectedStates?.length > 0 &&
                            selectedStates?.map((item, index) => {
                              return (
                                <Fragment>
                                  <div className="selected-data" key={index}>
                                    <Link to="#">
                                      <a
                                        onClick={() =>
                                          getCityFun(item.state_id, index)
                                        }
                                      >
                                        <div
                                          className={
                                            ActiveNo === index
                                              ? "selected__data Active"
                                              : "selected__data "
                                          }
                                        >
                                          {item.state_name}
                                        </div>{" "}
                                      </a>{" "}
                                    </Link>
                                  </div>
                                </Fragment>
                              );
                            })}
                        </ul>
                      </div>
                      {textMsg !== "" && msg && <FormError msg={textMsg} />}
                      {hubsSpinner ? (
                        <FormSpinner />
                      ) : (
                        <div className="hub__tab padding--5">
                          <div className="choose__hub">
                            {/* cities from selected state */}
                            {selectedCitiesData?.length > 0 &&
                              selectedCitiesData?.map((singleCity) => {
                                const selected_city_id =
                                  selectedStateCityArray.find(
                                    ({ city_id }) => city_id === singleCity.id
                                  );
                                return (
                                  <div className="form__item form__checkbox country-check">
                                    {selected_city_id ? (
                                      <input
                                        type="checkbox"
                                        defaultChecked
                                        id={singleCity.id}
                                        name={singleCity.name}
                                        onChange={(event) =>
                                          handleCityChange(event, singleCity)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        id={singleCity.id}
                                        name={singleCity.name}
                                        onChange={(event) =>
                                          handleCityChange(event, singleCity)
                                        }
                                      />
                                    )}
                                    <label
                                      htmlFor={singleCity.id}
                                      className="flex p-4 rounded-lg"
                                    >
                                      <span>{singleCity.name}</span>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {/*Choose Cities code end */}
     

      {/* Save && Submit Selected Hubs and State, Cities Code Start */}
      {isSavedHubs && (
        <div className="recipe__page">
          <div className="hub__selection--form bg-white cityname">
            <div className="step mb-4">
              <div className="cancel">
                {onSavedBackLink ? (
                  <Link to="/setting">
                    <span>
                      <i
                        className="fa fa-arrow-left pr-3"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                ) : (
                  <div className="link" style={{ cursor: "pointer" }}>
                    <span>
                      <i
                        className="fa fa-arrow-left pr-3"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <form className="hub__form padding--5">
              <div className="form--inner--div">
                <div className="flex">
                  <div className="w-full">
                  
                    <div className="coln-left">

                      <h4 id="error" className="title-blue-font-30">
                        {isSelectedHubName}
                      
                      </h4>
                  
                      <p className="title-para">{lang?.selectedHubs}</p>
                      <div className="flex justify-center text-red-600 text-lg"> <p>{error}</p> </div>
                      
                      <div className="multiple--hubs forHubView">
                        {/* selected hubs... */}
                        {selectedHubsArray?.length > 0 &&
                          selectedHubsArray
                            ?.sort(dynamicSort("title"))
                            .map((item) => {
                              return (
                                // <div className="hub__tab padding--5 md:w-1/4">
                                <div className="hub__tab padding--5">
                                  <div className="choose__hub edit--hub">
                                    <div className="chicago--hub form__item form__checkbox country-check">
                                      <label
                                        htmlFor={item}
                                        className="chicago-hub flex p-4 rounded-lg"
                                        style={{
                                          backgroundImage: `url("${item?.image?.base_url}${item?.image?.attachment_url}")`,
                                        }}
                                      >
                                        <span className="default-hub font-bold font-16">
                                          {`  ${item.title} `}
                                          <span className="innerSelect font-bold font-14">
                                            {item?.state?.name}
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>

                      <div className="hub__tab padding--5">
                        <div className="choose__hub hub_bg_blue">
                          {isSelectedCities?.length > 0 &&
                            isSelectedCities?.map((item, index) => {
                              return (
                                <Fragment key={index}>
                                  <div className="selected__data">
                                    <div className="hub--block">
                                      <div className="hub-block-left">
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                          onClick={() => deleteCity(index)}
                                        ></i>
                                      </div>
                                      <div className="hub-block-right">
                                        <h4 className="font-16 font-bold">
                                          {item.state_name}
                                          <span>{item.city_name}</span>
                                        </h4>
                                      </div>
                                      <p className="pl-6"></p>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })}
                        </div>
                      </div>
                      
                      <div className="closeAll text-right mt-4">
                        <Link
                          to="#"
                          onClick={() => {
                            setIsSavedHubs(false);
                            setIsSelected(true);
                            setOnBackChooseHub(false);
                          }}
                        >
                          <p className="pb-2">
                            {lang?.Edit}{" "}
                            <span>
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </p>
                        </Link>
                        <Link to="#" onClick={() => setIsDeletedAllHub(true)}>
                          <p>
                            {lang?.Remove_all}{" "}
                            <span>
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </span>
                          </p>
                        </Link>
                      </div>
                      {textMsg !== "" && msg && <FormError msg={textMsg} />}
                      <div className="flex justify-between mt-4">
                        <div className="bg-btn-gray sky-blue">
                          <button
                            type="button"
                            className="btn"
                            onClick={submitPostHubs}
                          >
                            {isSubmit ? <FormSpinner /> : lang?.only_Submit}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Save && Submit Selected Hubs and State, Cities Code End */}

      {isDeletedAllHub && (
        <div className="hubConfirm">
          <Modal
            isOpen={isDeletedAllHub}
            ariaHideApp={false}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                padding: "0",
                border: "0",
                borderRadius: "8px",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center popupheaderBg">
              <h4 className="page__title font-14">
                {lang?.remobeAllHubsMessageTop}
              </h4>
              <button
                className="close_btn--modal"
                onClick={() => setIsDeletedAllHub(!isDeletedAllHub)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <p className="body-title warning">
                {lang?.remobeAllHubsMessageMiddle}
              </p>
              <br />
              <div className="right__btn text-right">
                <Link
                  className="btn btn1 mr-3"
                  to="#"
                  onClick={() => setIsDeletedAllHub(false)}
                >
                  No
                </Link>
                <Link
                  className="btn btn2"
                  onClick={() => removeAllHubs()}
                  to="#"
                >
                  {deleteHubLoader ? <FormSpinner /> : `Yes`}
                </Link>
              </div>
            </div>
            <div></div>
          </Modal>
            {/* upgrade subscription modal*/}
         
        </div>
      )}
       <Modal
          isOpen={isOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Upgrade Modal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          >
          <div className="w-16 m-auto mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 47.5 47.5" viewBox="0 0 47.5 47.5" id="diamond"><path fill="#bdddf4" d="M0 0h-6l-7-9h10l3 9z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-7 9h-6l3-9H0z" transform="matrix(1.25 0 0 -1.25 46.25 16.25)"></path><path fill="#4289c1" d="M0 0h10L-8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 33.75 16.25)"></path><path fill="#8ccaf7" d="M0 0h-10L8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 13.75 16.25)"></path><path fill="#8ccaf7" d="m0 0-3-9h16l-3 9H0Z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-8 21H8L0 0Z" transform="matrix(1.25 0 0 -1.25 23.75 42.5)"></path></svg></div>    
          <h2 className="text-2xl font-bold mb-4  ">Upgrade Your Plan</h2>
          {
            subscriptionData!==undefined?(
          
           <p>
           <span className=" text-black">As per your current Plan, you can add up to {subscriptionData?.hubs_subscription==-1?"Unlimited":subscriptionData?.hubs_subscription} Hubs.
        To add more, please upgrade your Membership Plan.</span>
       </p>
            ):(
              <p>
              <span className=" text-black">Sorry, You're unable to subscribe any hubs at the moment as you don't have an active subscription.</span>
          </p>  
            )
          }
         
          <Link to="/subscription">
          <button  className="btn btn-blue stripe text-white font-bold mt-6">Upgrade </button>
          </Link>
          
          </Modal>
    </Fragment>
  );
}
