import React from 'react'
import BuyerHeader from '../DashboardView/BuyerHeader'
import SideBar from '../SideBar'
import InnerSideBar from './SettingSideBar/InnerSideBar'
import ChangeContactInformation from './ChangeContactInformation/ChangeContactInformation'

export default function ChangeContactInfo() {
  return (
    <>
        <section className='buyerDashboard'>
            <div className='dashboardLayout'>
                <div className='headerBlock'>
                    <BuyerHeader
                        pageTitle="Settings"
                    />
                </div>
                <div className='sideBarBlock'>
                    <SideBar />
                </div>
                <div className='centerContent'>
                    <div className='pageContent'>
                        <div className=''>
                            <div className='newDashboard bgWhite'>
                                <div className='card card--block p-0 noRadius'>
                                    <div className='flexBox'>
                                        <InnerSideBar />
                                        <ChangeContactInformation />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
