import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../modules/login";
import Input from "../../../components/Input/Input";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import auth from "../../../helpers/auth";
import Modal from "react-modal";
import { resetPassword, resetPwdOtp, setResponseErrors, verifyUserEmaiId, voyagerRregister } from "../../register/modules/register";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from "../../../helpers/language";
import OtpInput from "react-otp-input";

export function LoginView(props) {
  const [lang] = useState(language.getLang());
  const history = useHistory()
  const dispatch = useDispatch();

  const [otp, SetOtp] = useState();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [voyagersOtp, SetVoyagersOtp] = useState(false);
  const [account_enabled, SetAccount_enabled] = useState();
  const [voyagersEmail, SetVoyagersEmail] = useState();
  const [validEmail, setValidEmail] = useState('');
  const [forgotPwd, setForgotPwd] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const [isEmailAndOtpTrue, setIsEmailAndOtpTrue] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState('');
  const [loginErrorMsg, setLoginErrorMsg] = useState('');


  const { loginSpinner } = useSelector((state) => state.auth);
  const { role_data: { roles } } = useSelector((state) => state.authWrapper);
  const { role } = props.match.params;
  const { resError } = useSelector((state) => state.register);

  useEffect(() => {
    const str = window.location.href;
    console.log(str,'str...........');
    const getUrl = str.split('/');
    const bodyUrl = getUrl[getUrl.length - 1]
    document.body.classList.add(bodyUrl)
  }, [])

  const handlechange = async (event) => {
    const name = event.target.id;
    const value = event.target.value;
    if (name === "email") {
      setEmail(value)
    } else if (name === "password") {
      setPassword(value)
    } else if (name === "confirmPwd") {
      setConfirmPwd(value);
    }
  };

  // const submitHandler = async (e) => {
  //   await e.preventDefault();
  //  const loginData={
  //    email:email,
  //    password:password
  //  }
  //   await dispatch(logIn({ ...loginData, history: props.history })).then((res) => {
  //     if (res && res.success === 200) {
  //       history.push(`/home`);
  //     }
  //   });
  // };
  
  const submitHandler = async (e) => {
    await e.preventDefault();
    const loginData = {
      email: email,
      password: password,
    };
    if (email === '' || password === '') {
      await setLoginErrorMsg(lang?.Please_Enter_email_and_pass);
    } else {
      await dispatch(logIn({ ...loginData, history: props.history })).then(
        async (res) => {
          if (res && res.success === 200) {
            if (res.data.account_enabled === "incomplete") {
              await dispatch(verifyUserEmaiId(res.data?.email));
              SetVoyagersOtp(true);
              SetVoyagersEmail(res.data.email);
              SetAccount_enabled(res.data.account_enabled);
            } else {
              // history.push(`/login/${role}`);
              document.body.classList.remove(`register`);
              await auth.setTokens(
                res && res.token,
                JSON.stringify(res && res.data.roles)
              );
              await localStorage.setItem("alysei_review", res?.data?.alysei_review);
              if(res?.data?.role_id === 11){
                  history.push(`/buyer-dashboard`);
              }
              else{
                history.push("/home");
              }
            }
          } else {
            setLoginErrorMsg(res?.error);
          }
        }
      );
    }
    setTimeout(() => {
      setLoginErrorMsg('')
    }, 3000);
  };

  const varifyOtp = async (e) => {
    const model = {
      account_enabled: account_enabled,
      email: voyagersEmail,
      otp: otp,
    };
    await dispatch(voyagerRregister(model)).then((res) => {
      if (res && res.success === 200) {
        // history.push(`/login/${role}`);
        document.body.classList.remove(`register`);
        if(res?.data?.roles?.role_id === 11){
            history.push(`/buyer-dashboard`);
        }
        else{
          history.push(`/home`);
        }
      }
    });
    SetVoyagersOtp(false);
  };

  const resendOtp = async () => {
    const response = await dispatch(verifyUserEmaiId(voyagersEmail));
    if (response.success === 200) {
      dispatch(setResponseErrors(lang?.OTP_has_been_send_successfully));
      setValidEmail()
      SetVoyagersEmail()
    } else {
      setValidEmail(lang?.SomeThingsWrong)
    }
    setTimeout(() => {
      setValidEmail('');
      dispatch(setResponseErrors(''));
    }, 3000)
  }


  const forgotPassword = async (event) => {
    event.preventDefault();
    if (email !== undefined) {
      const response = await dispatch(verifyUserEmaiId(email));
      if (response.success === 200) {
        setVerifyOtp(true);
        setForgotPwd(false);
      } else {
        setValidEmail(lang?.invalidEmail)
      }

    }
    setTimeout(() => {
      setValidEmail('')
    }, 3000)

  };


  const verifyPwdOtp = async (event) => {
    event.preventDefault();
    await setIsEmailAndOtpTrue(true);
    const model = {
      email: email,
      otp: otp,
    };
    await dispatch(resetPwdOtp(model)).then((res) => {
      if (res && res.success === 200) {
        setResetPwd(true);
        setIsEmailAndOtpTrue(false);
      } else {
        setIsEmailAndOtpTrue(false);
        setValidEmail(lang?.invalidOTP)
        SetOtp();
      }
    });
    setTimeout(() => {
      setValidEmail('')
    }, 3000)
  };

  const changePwd = async (event) => {
    event.preventDefault();
    var digit = /(\d+)/;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let result = format.test(password);
    let digitResult = digit.test(password);
    const model = {
      email: email,
      password: password,
      confirm_password: confirmPwd,
    };

    if (digitResult && result) {
      await dispatch(resetPassword(model)).then((res) => {
        if (res && res.success === 200) {
          SetOtp();
          setResetPwd(false);
          setForgotPwd(false);
          setVerifyOtp(false);

        } else {
          SetOtp();
        }
      });
    } else {
      dispatch(setResponseErrors(lang?.ValidatePass))

    }

    setTimeout(() => {
      setValidEmail('')
      dispatch(setResponseErrors(''));
    }, 3000)
  };


  const togglePassword = (type) => {
    type === 1 && setPasswordShown(!passwordShown);
    type === 2 && setConfirmPassword(!confirmPassword);

  };

  return (
    <Fragment>
      <div className="register_form">
        <div className="card--block">
          {/* <div className="card-title">
            <h1>
              <Link to={`/register/${role}`}>
                <i className="fas fa-chevron-left" />
              </Link>{" "}
              {roles && roles.find((i) => i.role_id == role).name}
            </h1>
            <hr />
          </div> */}
          <div className="card-form">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form__action form__item">
                <Input
                  id="email"
                  name="email"
                  placeholder={lang?.Email}
                  type="email"
                  onChange={(e) => handlechange(e)}
                />
                <Input
                  id="password"
                  name="password"
                  placeholder={lang?.only_password}
                  type="password"
                  onChange={(e) => handlechange(e)}
                />
                {/* <Link className="text-xs inline-block mt-6" >
                Lost password?
              </Link> */}
                <p style={{ color: "red" }}>{loginErrorMsg}</p>
                <div className="form__action form__item">
                  <button type="submit" className="btn w-full mt-2 mb-2">{loginSpinner ? <FormSpinner /> : `${lang?.Login}`}</button>
                </div>
                <Link
                  className="text-xs inline-block"
                  onClick={() => { setForgotPwd(!forgotPwd) }}>
                  Forgot Password? </Link>
                {/* <Link className="text-xs inline-block">
                  Forgot Password?
                </Link> */}
                <hr />
                <div className="text-center">
                  <Link
                    className="btn bg-color-blue mt-4"
                    // to={`/register/${role}`}
                    to={`/register`}
                  >
                    {lang?.Do_not_have_account}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* voyagersOtp */}
      {voyagersOtp &&
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={voyagersOtp}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "140px",
                left: "30%",
                right: "30%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                // outline: 'none',
                padding: "20px",
              },
            }}
          >
            <button className="close_btn--modal" onClick={() => SetVoyagersOtp(false)} >
              {lang?.close}
            </button>
            <p className="text-red-500">{validEmail}</p>
            <p className="text-green">{resError}</p>

            {
              <div className="form__action otp">
                <OtpInput
                  value={otp}
                  onChange={(otp) => SetOtp(otp)}
                  numInputs={6}
                // separator={<span>-</span>}
                />
                <div className="form__action form__item mt-4">
                  <button type="submit" onClick={(e) => varifyOtp(e)}>
                    {lang?.Save}
                  </button>
                </div>

                <p>
                  {lang?.Didnot_receive_the_OTP}{" "}
                  <Link to="#" onClick={() => resendOtp()} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                </p>
              </div>
            }
          </Modal>
        </div>
      }

      {/* Forgot Password Popup Start */}

      {forgotPwd && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={forgotPwd}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Forget_password}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setForgotPwd(!forgotPwd)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-center mb-6">{lang?.Enter_your_email_recovery}
              </p>
              <p style={{ color: 'red' }}>{validEmail}</p>
              <form className="" onSubmit={(event) => forgotPassword(event)}>
                <div className="form__action">
                  <div className="form__item">
                    <input
                      type="text"
                      placeholder="Email"
                      id="email"
                      name="email"
                      required
                      onChange={(e) => {
                        handlechange(e);
                        setEmail(e.target.value.toLowerCase())
                      }}
                    />
                  </div>
                  <button type="submit" className="btn w-full mt-6 mb-2">
                    {lang?.Forget_password}
                  </button>
                </div>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

      {/* Forgot Password Popup End */}


      {/* Verify Otp Start */}
      {verifyOtp && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={verifyOtp}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Alysei}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setVerifyOtp(!verifyOtp); setValidEmail('') }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-blue text-center mb-8">
                {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{" "}
                <strong>{email}</strong>
                <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
              </p>
              <p className="text-red-500">{validEmail}</p>
              <p className="text-green">{resError}</p>
              <form className="mb-2" onSubmit={(event) => verifyPwdOtp(event)}>
                <div className="form__action otp">
                  <OtpInput
                    value={otp}
                    onChange={(otp) => SetOtp(otp)}
                    numInputs={6}
                  // separator={<span>-</span>}
                  />
                  {/* <div className="flex items-center">
                    <div className="form__item mr-4">
                      <input type="text" placeholder="" maxlength="1" />
                    </div>
                    <div className="form__item mr-4">
                      <input type="text" placeholder="" maxlength="1" />
                    </div>
                    <div className="form__item mr-4">
                      <input type="text" placeholder="" maxlength="1" />
                    </div>
                    <div className="form__item mr-4">
                      <input type="text" placeholder="" maxlength="1" />
                    </div>
                    <div className="form__item mr-4">
                      <input type="text" placeholder="" maxlength="1" />
                    </div>
                    <div className="form__item">
                      <input type="text" placeholder="" maxlength="1" />
                    </div>
                  </div> */}
                  <button type="submit" className="btn w-full mt-6 mb-6">
                    {isEmailAndOtpTrue ? <FormSpinner /> : "Reset Password"}
                  </button>
                  <p>
                    {lang?.Didnot_receive_the_OTP}{" "}
                    <Link to="#" onClick={() => resendOtp(email)} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                  </p>
                </div>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}
      {/* Verify Otp End */}

      {/* Reset Password Popup Start */}
      {resetPwd && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={resetPwd}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Reset_Password}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setResetPwd(false); setVerifyOtp(false); SetOtp() }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p style={{ color: "red" }}>{resError}</p>
              <form className="mb-2" onSubmit={(event) => changePwd(event)}>
                <div className="form__action">
                  <div className="form__item relative">
                    <span className="eye_show">
                      <i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(1)} aria-hidden="false"></i>
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder={lang?.New_Password}
                      name="password"
                      id="password"
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                  <div className="form__item relative">
                    <span className="eye_show">
                      <i className={!confirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(2)} aria-hidden="false"></i>
                    </span>
                    <input
                      type={confirmPassword ? "text" : "password"}
                      placeholder={lang?.Confirm_Password}
                      name="confirmPwd"
                      id="confirmPwd"
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                  <p className="text-center mb-6 mt-6">
                    {lang?.PassWordValidation}
                  </p>
                  <button type="submit" className="btn w-full">
                    {lang?.only_Submit}
                  </button>
                </div>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

      {/* Reset Password Popup End */}
    </Fragment>
  );
}

export default LoginView;
