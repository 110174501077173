import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment"
import headerType from "../../../helpers/headerType";
import constant from "../../../helpers/constant";
export const GET_IMP_AND_DISTHUBS_SPINNER_STATUS = "GET_IMP_AND_DISTHUBS_SPINNER_STATUS";
export const GET_VOICE_OF_EXPORT_SPINNER_STATUS = "GET_VOICE_OF_EXPORT_SPINNER_STATUS";
export const GET_TRAVEL_AGENCIES_SPINNER_STATUS = "GET_TRAVEL_AGENCIES_SPINNER_STATUS";
export const GET_HUBS_MEMBER_SPINNER_STATUS = "GET_HUBS_MEMBER_SPINNER_STATUS";
export const GET_RESTAURANTS_SPINNER_STATUS = "GET_RESTAURANTS_SPINNER_STATUS";
export const GET_PRODUCER_SPINNER_STATUS = "GET_PRODUCER_SPINNER_STATUS";
export const GET_USERS_SPINNER_STATUS = "GET_USERS_SPINNER_STATUS";
export const GET_HUBS_SPINNER_STATUS = "GET_HUBS_SPINNER_STATUS";

export const GET_IMP_AND_DIST_SELECTED_HUBS = "GET_IMP_AND_DIST_SELECTED_HUBS";
export const GET_ALL_REASTAURANT_TYPE = "GET_ALL_REASTAURANT_TYPE";
export const GET_VOICE_OF_EXPORT_USER = "GET_VOICE_OF_EXPORT_USER";
export const GET_TRAVEL_AGENCIES_USER = "GET_TRAVEL_AGENCIES_USER";
export const GET_STATE_SELECTED_HUBS = "GET_STATE_SELECTED_HUBS";
export const GET_REASTAURANTS_USER = "GET_REASTAURANTS_USER";
export const GET_STATE_HUBS_MEMBER = "GET_STATE_HUBS_MEMBER";
export const GET_STATE_OF_COUNTRY = "GET_STATE_OF_COUNTRY";
export const GET_ALL_SPECIALITY = "GET_ALL_SPECIALITY";
export const GET_ALL_PRODUCT_TYPE = "GET_ALL_PRODUCT_TYPE";
export const GET_ALL_EXPERTISE = "GET_ALL_EXPERTISE";
export const GET_USER_BY_ROLE = "GET_USER_BY_ROLE";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_TITLE = "GET_ALL_TITLE";
export const GET_ALL_LABELS = "GET_ALL_LABELS";
export const GET_ALL_ITALIAN_FB_PRODUCER_LABELS = "GET_ALL_ITALIAN_FB_PRODUCER_LABELS";
export const GET_ALL_HUBS = "GET_ALL_HUBS";
export const GET_PRODUCER = "GET_PRODUCER";
export const GET_SUGGESTION_DATA = "GET_SUGGESTION_DATA";
export const GET_HUBMEMBER_CONNECTION_DATA = "GET_HUBMEMBER_CONNECTION_DATA";
export const GET_SINGLE_HUB_INFO = "GET_SINGLE_HUB_INFO";

//________________________________________________Spinners___________________________

export function getHubsSpinner(flag) {
  return {
    type: GET_HUBS_SPINNER_STATUS,
    payload: flag,
  };
}

export function getProducerHubsSpinner(flag) {
  return {
    type: GET_PRODUCER_SPINNER_STATUS,
    payload: flag,
  };
}

export function getImpAndDistHubsSpinner(flag) {
  return {
    type: GET_IMP_AND_DISTHUBS_SPINNER_STATUS,
    payload: flag,
  };
}

export function getRestaurantSpinner(flag) {
  return {
    type: GET_RESTAURANTS_SPINNER_STATUS,
    payload: flag,
  };
}

export function getVoiceOfExportSpinner(flag) {
  return {
    type: GET_VOICE_OF_EXPORT_SPINNER_STATUS,
    payload: flag,
  };
}

export function getTravelAgenciesSpinner(flag) {
  return {
    type: GET_TRAVEL_AGENCIES_SPINNER_STATUS,
    payload: flag,
  };
}

export function getHubsMemberSpinner(flag) {
  return {
    type: GET_HUBS_MEMBER_SPINNER_STATUS,
    payload: flag,
  };
}

export function getUserSpinner(flag) {
  return {
    type: GET_USERS_SPINNER_STATUS,
    payload: flag,
  };
}
// _____________________________________________END_____________________

//_________________________________Methods______________________________

export function getSelectedHubs(data) {
  return {
    type: GET_STATE_SELECTED_HUBS,
    payload: data,
  };
}

export function getProducer(data) {
  return {
    type: GET_PRODUCER,
    payload: data,
  };
}
export function getConnectionData(data) {
  return {
    type: GET_SUGGESTION_DATA,
    payload: data,
  };
}
export function getHubMemberConnectionData(data) {
  return {
    type: GET_HUBMEMBER_CONNECTION_DATA,
    payload: data,
  };
}

export function getImpAndDistHubs(data) {
  return {
    type: GET_IMP_AND_DIST_SELECTED_HUBS,
    payload: data,
  };
}

export function getRestaurentUsers(data) {
  return {
    type: GET_REASTAURANTS_USER,
    payload: data,
  };
}

export function getVoiceOfExportUsers(data) {
  return {
    type: GET_VOICE_OF_EXPORT_USER,
    payload: data,
  };
}

export function getTravelAgenciesUsers(data) {
  return {
    type: GET_TRAVEL_AGENCIES_USER,
    payload: data,
  };
}

export function getHubsMemberData(data) {
  return {
    type: GET_STATE_HUBS_MEMBER,
    payload: data,
  };
}


export function getHubsInfoData(data) {
  return {
    type: GET_SINGLE_HUB_INFO,
    payload: data,
  };
}

export function getUsersData(data) {
  return {
    type: GET_USER_BY_ROLE,
    payload: data,
  };
}

export function getStateData(data) {
  return {
    type: GET_STATE_OF_COUNTRY,
    payload: data,
  };
}

export function getAllHubsData(data) {
  return {
    type: GET_ALL_HUBS,
    payload: data,
  };
}

export function getAllRoleData(data) {
  return {
    type: GET_ALL_ROLES,
    payload: data,
  };
}

export function getAllRestarantData(data) {
  return {
    type: GET_ALL_REASTAURANT_TYPE,
    payload: data,
  };
}

export function getAllExpertiseData(data) {
  return {
    type: GET_ALL_EXPERTISE,
    payload: data,
  };
}

export function getAllTitleData(data) {
  return {
    type: GET_ALL_TITLE,
    payload: data,
  };
}

export function getAllLabelsData(data) {
  return {
    type: GET_ALL_LABELS,
    payload: data,
  };
}

export function getAllLabelsItalianFBProducer(data) {
  return {
    type: GET_ALL_ITALIAN_FB_PRODUCER_LABELS,
    payload: data,
  };
}

export function getAllSpecialityData(data) {
  return {
    type: GET_ALL_SPECIALITY,
    payload: data,
  };
}

export function getAllProductTypeData(data) {
  return {
    type: GET_ALL_PRODUCT_TYPE,
    payload: data,
  };
}

// _____________________________________________END_______________________


//___________________________For__Selected__Hubs__________________________
export function fetchSelectedHubs(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&keyword=${data.keyword}&state=${data.state}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getSelectedHubs(response));
        await dispatch(getHubsSpinner(false));
      } else {
        await dispatch(getSelectedHubs(response));
        await dispatch(getHubsSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getHubsSpinner(false));
    }
  };
}
// _____________________________________________END________________________________________________

//___________________________For_Producer__________________________
export function fetchProducer(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getProducerHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&role_id=${data.roleId}&hub_id=${data.hubsId ?? ""}&product_type=${data.productType ?? ""}&horeca=${data.heroca ?? ""}&private_label=${data.privateLabel ?? ""}&alysei_brand_label=${data.alyseiBrandLabel ?? ""}&page=${data.page ?? ""}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getProducer(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getProducerHubsSpinner(false));
      } else {
        await dispatch(getProducer(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getProducerHubsSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getProducerHubsSpinner(false));
    }
  };
}

// for suggestion connection
export function fetchDataConncetionSuggetion(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getProducerHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&&role_id=${data.roleId}&hub_id=${data.hubsId ?? ""}&product_type=${data.productType ?? ""}&horeca=${data.heroca ?? ""}&private_label=${data.privateLabel ?? ""}&alysei_brand_label=${data.alyseiBrandLabel ?? ""}&page=${data.page ?? ""}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getConnectionData(response));
        await dispatch(getProducerHubsSpinner(false));
      } else {
        await dispatch(getConnectionData(response));
        await dispatch(getProducerHubsSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getProducerHubsSpinner(false));
    }
  };
}
// for memberHubs Connection
export function fetchHubsMemberDataConncetion(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getProducerHubsSpinner(true));
    // let url = (parseInt(data.roleId) === constant.rolesId.voyagers) ?
    // `${REACT_APP_API.ENV.REACT_APP_API}/get/usersin/role?&role_id=${data.roleId}&hub_id=${data.hubId ?? ""}&page=${data.page ?? ""}`
    // : `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&role_id=${data.roleId}&hub_id=${data.hubId ?? ""}&product_type=${data.productType ?? ""}&horeca=${data.heroca ?? ""}&private_label=${data.privateLabel ?? ""}&alysei_brand_label=${data.alyseiBrandLabel ?? ""}&page=${data.page ?? ""}`
    let url = `${REACT_APP_API.ENV.REACT_APP_API}/get/usersin/role?&role_id=${data.roleId}&hub_id=${data.hubId ?? ""}&page=${data.page ?? ""}`;
    try {
      const result = await fetch(url, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getProducerHubsSpinner(false));
      } else {
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getProducerHubsSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getProducerHubsSpinner(false));
    }
  };
}
// _____________________________________________END_____________________

//___________________________For_Importers__&__Distributors__Selected__Hubs__________________________
export function fetchImpAndDistHubs(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getImpAndDistHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&&role_id=${data.roleId}&hub_id=${data.hubsId}&user_type=${data.userType}&product_type=${data.productType}&method=${data.method}&property=${data.property}&horeca=${data.heroca}&private_label=${data.privateLabel}&alysei_brand_label=${data.alyseiBrandLabel}&page=${data.page}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getImpAndDistHubs(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getImpAndDistHubsSpinner(false));
      } else {
        await dispatch(getImpAndDistHubs(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getImpAndDistHubsSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getImpAndDistHubsSpinner(false));
    }
  };
}
// _____________________________________________END_____________________

//___________________________For_Italian_Restaurants_in_US__________________________
export function fetchItalianRestHubs(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getRestaurantSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&role_id=${data.roleId}&hub_id=${data.hubsId}&restaurant_type=${data.restaurantType}&pickup=${data.pickup}&delivery=${data.delivery}&page=${data.page}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getRestaurentUsers(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getRestaurantSpinner(false));
      } else {
        await dispatch(getRestaurentUsers(response));
        await dispatch(getRestaurantSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getRestaurantSpinner(false));
    }
  };
}
// _____________________________________________END_____________________

//___________________________For_Voice_of_expert__________________________
export function fetchVoiceOfExport(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getVoiceOfExportSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&&role_id=${data.roleId}&hub_id=${data.hubsId}&expertise=${data.expertise}&title=${data.title}&country=${data.country}&region=${data.region}&page=${data.page}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getVoiceOfExportUsers(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getVoiceOfExportSpinner(false));
      } else {
        await dispatch(getVoiceOfExportUsers(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getVoiceOfExportSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getVoiceOfExportSpinner(false));
    }
  };
}
// _____________________________________________END_____________________

//___________________________For_Travel_Agencies__________________________
export function fetchTravelAgencies(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getTravelAgenciesSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.searchType}&&role_id=${data.roleId}&hub_id=${data.hubsId}&speciality=${data.speciality}&country=${data.country}&region=${data.region}&page=${data.page}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getTravelAgenciesUsers(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getTravelAgenciesSpinner(false));
      } else {
        await dispatch(getTravelAgenciesUsers(response));
        await dispatch(getHubMemberConnectionData(response));
        await dispatch(getTravelAgenciesSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getTravelAgenciesSpinner(false));
    }
  };
}
// _____________________________________________END______________________



// _______________________For_Hubs_Member_______________________________
export function fetchHubsMember(hubsId) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getHubsMemberSpinner(true));
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/roles/by/hubid/${hubsId}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getHubsMemberData(response));
        await dispatch(getHubsMemberSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getHubsMemberSpinner(false));
    }
  };
} 
// _____________________________________________END_____________________

// _______________________For_Hubs_Info_______________________________
export function fetchHubsInfo(hubsId) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/hub/${hubsId}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getHubsInfoData(response?.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
} 

// For users..
export function fetchUserByRole(model) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getUserSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/usersin/role?role_id=${model.roleId}&hub_id=${model.hubId}&page=${model.page}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getUsersData(response));
        await dispatch(getUserSpinner(false));
      } else {
        await dispatch(getUsersData(response));
        await dispatch(getUserSpinner(false));
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getUserSpinner(false));
    }
  };
}
// _____________________________________________END_________________________

//_____________________________________For_States___________________________
export function fetchStateOfCountry(country) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/mycountry/states?param=${country}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getStateData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END__________________________

//_____________________________________For_All_Hubs___________________________
export function fetchAllHubs() {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/all/hubs`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllHubsData(response.hubs));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_____________________________

//_____________________________________For_All_Roles___________________________
export function fetchAllRole() {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/roles`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllRoleData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________

//_____________________________________For_Reastaurant_Type___________________________
export function fetchRestarantType(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllRestarantData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________

//_____________________________________For_Expertise___________________________
export function fetchExpertise(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllExpertiseData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________

//_____________________________________For_Title___________________________
export function fetchTitle(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllTitleData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________

//_____________________________________For_Title___________________________
export function fetchLabels(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllLabelsData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}

export function fetchItalianFBProducerLabels(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllLabelsItalianFBProducer(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________

//_____________________________________For_Speciality_Trips___________________________
export function fetchSpeciality(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllSpecialityData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________

//_____________________________________For_Product_Type___________________________
export function productTypeField(Id) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/field/value/${Id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(getAllProductTypeData(response.data));
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
}
// _____________________________________________END_______________________________


export const subscribeUnsubscribeHub = (model) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken()
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/subscribe/unsubscribe/hub?hub_id=${model.hubId}&subscription_type=${model.subscriptionType}`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token)
        });
        let response = await result.json();
        if (response.success === 200) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    } else {
      console.log('You need to login first');
    }
  };
};



export const initialState = {
  impAndDistHubsSpinner: false,
  travelAgenSpinner: false,
  voiceOfExpertSpinner: false,
  restaurantSpinner: false,
  hubsMemberSpinner: false,
  producerSpinner: false,
  hubsSpinner: false,
  userSpinner: false,

  productField: [],
  getImpAndDistHubsData: [],
  getTravelAgenciesData: [],
  getVoiceOfExpertData: [],
  getAllRestaurantType: [],
  getSelectedHubsData: [],
  getRestaurantsData: [],
  getUsersByRoleData: [],
  getAllSpeciality: [],
  getHubMemberData: [],
  getHubInfoData: [],
  getAllExpertise: [],
  getStatesData: [],
  getProducerData: [],
  getAllTitle: [],
  getAllLabels : [],
  getAllHubs: [],
  getAllRole: [],
  userSuggestion: [],
  hubMemberConnection: [],
  getAllItalianFbProducerLabels : [],

};

const ACTION_HANDLERS = {
  [GET_STATE_SELECTED_HUBS]: (state, action) => {
    return {
      ...state,
      getSelectedHubsData: action.payload,
    };
  },
  [GET_PRODUCER]: (state, action) => {
    return {
      ...state,
      getProducerData: action.payload,
    };
  },
  [GET_IMP_AND_DIST_SELECTED_HUBS]: (state, action) => {
    return {
      ...state,
      getImpAndDistHubsData: action.payload,
    };
  },
  [GET_REASTAURANTS_USER]: (state, action) => {
    return {
      ...state,
      getRestaurantsData: action.payload,
    };
  },
  [GET_VOICE_OF_EXPORT_USER]: (state, action) => {
    return {
      ...state,
      getVoiceOfExpertData: action.payload,
    };
  },
  [GET_TRAVEL_AGENCIES_USER]: (state, action) => {
    return {
      ...state,
      getTravelAgenciesData: action.payload,
    };
  },
  
  [GET_STATE_HUBS_MEMBER]: (state, action) => {
    return {
      ...state,
      getHubMemberData: action.payload,
    };
  },
  [GET_SINGLE_HUB_INFO]: (state, action) => {
    return {
      ...state,
      getHubInfoData: action.payload,
    };
  },
  [GET_USER_BY_ROLE]: (state, action) => {
    return {
      ...state,
      getUsersByRoleData: action.payload,
    };
  },

  [GET_STATE_OF_COUNTRY]: (state, action) => {
    return {
      ...state,
      getStatesData: action.payload,
    };
  },

  [GET_ALL_HUBS]: (state, action) => {
    return {
      ...state,
      getAllHubs: action.payload,
    };
  },

  [GET_ALL_ROLES]: (state, action) => {
    return {
      ...state,
      getAllRole: action.payload,
    };
  },

  [GET_ALL_REASTAURANT_TYPE]: (state, action) => {
    return {
      ...state,
      getAllRestaurantType: action.payload,
    };
  },

  [GET_ALL_EXPERTISE]: (state, action) => {
    return {
      ...state,
      getAllExpertise: action.payload,
    };
  },

  [GET_ALL_TITLE]: (state, action) => {
    return {
      ...state,
      getAllTitle: action.payload,
    };
  },
  
  [GET_ALL_LABELS]: (state, action) => {
    return {
      ...state,
      getAllLabels: action.payload,
    };
  },

  [GET_ALL_ITALIAN_FB_PRODUCER_LABELS]: (state, action) => {
    return {
      ...state,
      getAllItalianFbProducerLabels: action.payload,
    };
  },

  [GET_ALL_SPECIALITY]: (state, action) => {
    return {
      ...state,
      getAllSpeciality: action.payload,
    };
  },

  [GET_ALL_PRODUCT_TYPE]: (state, action) => {
    return {
      ...state,
      productField: action.payload,
    };
  },

  [GET_HUBS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      hubsSpinner: action.payload,
    };
  },

  [GET_PRODUCER_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      producerSpinner: action.payload,
    };
  },

  [GET_IMP_AND_DISTHUBS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      impAndDistHubsSpinner: action.payload,
    };
  },

  [GET_RESTAURANTS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      restaurantSpinner: action.payload,
    };
  },

  [GET_VOICE_OF_EXPORT_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      voiceOfExpertSpinner: action.payload,
    };
  },

  [GET_TRAVEL_AGENCIES_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      travelAgenSpinner: action.payload,
    };
  },

  [GET_HUBS_MEMBER_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      hubsMemberSpinner: action.payload,
    };
  },

  [GET_USERS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      userSpinner: action.payload,
    };
  },
  [GET_SUGGESTION_DATA]: (state, action) => {
    return {
      ...state,
      userSuggestion: action.payload,
    };
  },
  [GET_HUBMEMBER_CONNECTION_DATA]: (state, action) => {
    return {
      ...state,
      hubMemberConnection: action.payload,
    };
  },

};

export default function b2bState(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
