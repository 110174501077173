export default {
  rolesId: {
    producer: 3,
    importer: 4,
    distributer: 5,
    "importer&distributer": 6,
    voiceOfExpert: 7,
    travelAgencies: 8,
    restaurant: 9,
    voyagers: 10,
    buyer : 11,
  },
  tabs: {
    invitation: 1,
    connection: 2,
    pending: 3,
    followers: 4,
  }
}