
import React, { useEffect, useState } from 'react';
import { getMyAllTaxesClassesList, getMyAllTaxesList, deleteTaxClasses, deleteTax } from '../modules/marketplace';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PaginatedComponentView from '../../../components/PaginatedComponent/PaginatedComponentView';
import language from '../../../helpers/language';
import DeleteConfirmation from '../../../components/DeleteConfirmation';


const TaxManagement = () => {
    const history = useHistory();
    const [taxClasses, setAllTaxClasses] = useState([]);
    const [allTaxes, setAllTaxes] = useState([]);
    const [notes, setNotes] = useState('');
    const [lang] = useState(language.getLang());
    const [responseErrors, setResponseErrors] = useState({ message: '', type: false });
    const [index, setIndex] = useState(localStorage.getItem('taxIndex') ?? 0);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [selectItemId, setSelectItemId] = useState({ type: '', id: '' });

    console.log(responseErrors,"responce Error")
    // Get my product tax classes
    useEffect(() => {
        getAllTaxClassList(1)
    }, []);

    // Get my product taxt
    useEffect(async () => {
        getAllTaxList(1)
    }, []);

    const getAllTaxList = async (page) => {
        const response = await getMyAllTaxesList(page);
        response?.data?.length > 0 ? setAllTaxes(response) : setAllTaxes([]);
    }

    const getAllTaxClassList = async (page) => {
        const response = await getMyAllTaxesClassesList(page);
        response?.data?.length > 0 ? setAllTaxClasses(response) : setAllTaxClasses([]);
    }

    // paginated  tax class page
    const taxClassPagination = async (e) => {
        const selectedPage = e.selected + 1;
        await getAllTaxClassList(selectedPage);
    };

    // paginated  tax page
    const handleTaxPagination = async (e) => {
        const selectedPage = e.selected + 1;
        await getAllTaxList(selectedPage);
    };

    const openConfirmModalByTaxType = (type, id, product) => {
        product && product > 0 && setNotes(`This tax class has been used in ${product} product.`)
        setSelectItemId({ type, id });
        setIsDeleteConfirmationVisible(true);
    }

    // delete tax class by id
    const deleteTaxClassById = async () => {
        const { id } = selectItemId;
        const response = await deleteTaxClasses(id);
        if (response?.success) {
            const updatedList = taxClasses?.data?.filter((item) => item?.tax_class_id !== id);
            const list = {
                ...taxClasses,
                data: taxClasses["data"] = updatedList
            }
            console.log(response,"responce00")
            setAllTaxClasses(list)
            setResponseErrors({ message: response?.message ?? lang?.Tax_class_create_successfully, type: true });
            handleCancel();
        } else {
            setResponseErrors({ message: response?.message ?? lang?.SomeThingsWrong, type: false })
            handleCancel();
        }

        setTimeout(() => {
            setResponseErrors({ message: "", type: false });
        }, 2000);
    }

    // delete tax by id
    const deleteTaxById = async () => {
        const { id } = selectItemId;
        const response = await deleteTax(id);
        if (response?.success) {
            const updatedList = allTaxes?.data?.filter((item) => item?.tax_id !== id);
            const list = {
                ...allTaxes,
                data: allTaxes["data"] = updatedList
            }
            setAllTaxes(list);
            setResponseErrors({ message: response?.message ?? lang?.Tax_create_successfully, type: true });
            handleCancel();
        } else {
            setResponseErrors({ message: response?.message ?? lang?.SomeThingsWrong, type: false });
            handleCancel();
        }
        setTimeout(() => {
            setResponseErrors({ message: "", type: false });
        }, 2000);
    }

    const handleCancel = () => {
        setSelectItemId({ type: "", id: "" });
        setNotes('');
        setIsDeleteConfirmationVisible(false);
    };

    const handleDelete = async () => {
        const { type } = selectItemId;
        type === "class" && deleteTaxClassById()
        type === "tax" && deleteTaxById()
    };

    return (
        <div className='tax__mgmt'>
            <div className="TabFlex">
                {responseErrors?.message && <h4 className={`mb-4 fs-22 font-bold ${responseErrors?.type ? "textGreen" : "textRed"}`} >{responseErrors?.message}</h4>}
                <Tabs className="Tax_Tab" defaultIndex={index} onSelect={(i) => { localStorage.setItem('taxIndex', i); setIndex(i) }}>
                    <TabList className="TaxTabFlex">
                        <Tab>{lang?.Tax_rate} <span><i className="fa fa-info-circle infoIcon" aria-hidden="true" title={lang ?. Tax_Rate_info}></i></span></Tab>
                        <Tab>{lang?.Tax_classes} <span><i className="fa fa-info-circle infoIcon" aria-hidden="true" title={lang ?. Tax_Class_info}></i></span></Tab>
                        {
                            <div className="product-title">
                                <div className="flex flex-wrap justify-between items-center">
                                    <div className="inner-btn-box Tax-btn-box">
                                        <Link
                                            to={`${+index === 0 ? '/marketplace/add-tax' : '/marketplace/add-tax-classes'}`}
                                            className="btn btn-blue"
                                        >
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                            {lang?.Add}
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        }
                    </TabList>

                    <div className='taxClassForm'>
                        <TabPanel>
                            <>
                                {
                                    <div className="product-title">
                                        <div className='itemTable itemTableScroll'>
                                            <table>
                                                <thead>
                                                    <tr>

                                                        <th className='Tax_Name'>{lang?.onlyName}</th>
                                                        <th>{lang?.Taxes_rate}</th>
                                                        <th>{lang?.Taxes_type}</th>
                                                        <th>{lang?.Action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allTaxes?.data?.length > 0 ? allTaxes?.data?.map(({ tax_id, tax_name, tax_rate, tax_type }, i) => {
                                                        return (
                                                            <tr key={tax_id}>
                                                                <td className='Tax_Name'>{tax_name}</td>
                                                                <td>{tax_type}</td>
                                                                <td>{tax_type === "percentage" ? `${tax_rate}%` : `$${tax_rate}`}</td>
                                                                <td>
                                                                    <div className='flexBox'>
                                                                        <span className='editBlock'>
                                                                            <button
                                                                                title="Edit"
                                                                                onClick={() => history.push(`/marketplace/edit-tax/${tax_id}`)}
                                                                            >
                                                                                <i
                                                                                    className="fa fa-pencil"
                                                                                    aria-hidden="true"
                                                                                ></i>

                                                                            </button>
                                                                        </span>
                                                                        <span className='deleteBlock' title="Delete" onClick={() => openConfirmModalByTaxType('tax', tax_id)}>
                                                                            <i className="fa fa-trash-o" aria-hidden="true"></i>

                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }):<p>{lang?.No_data_found}</p>}
                                                </tbody>
                                            </table>
                                        </div>
                                        <PaginatedComponentView lastPage={allTaxes?.last_page} handlePageClick={handleTaxPagination} />
                                    </div>}
                            </>

                        </TabPanel>
                        <TabPanel>
                            <>
                                {
                                    <div className='recentOrder secBlockGap'>
                                        <div className='itemTable'>
                                            <table>
                                                <thead>
                                                    <tr>

                                                        <th className='Tax_Name'>{lang?.Tax_class_title}</th>
                                                        <th>{lang?.Action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {taxClasses?.data?.length > 0 ? taxClasses?.data?.map(({ tax_class_id, name, product }, i) => {
                                                        return (
                                                            <tr key={tax_class_id}>
                                                                <td className='Tax_Name'>{name}</td>
                                                                <td>
                                                                    <div className='flexBox' >
                                                                        <span className='editBlock' onClick={() => history.push(`/marketplace/edit-tax-classes/${tax_class_id}`)}
                                                                            title="Edit">
                                                                            <i
                                                                                className="fa fa-pencil"
                                                                                aria-hidden="true"
                                                                            ></i>

                                                                        </span>

                                                                        <span className='deleteBlock' onClick={() => openConfirmModalByTaxType('class', tax_class_id, product)}>
                                                                            <i className="fa fa-trash-o" aria-hidden="true" title="Delete"></i>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }):<p>{lang?.No_data_found}</p>}
                                                </tbody>
                                            </table>
                                        </div>
                                        <PaginatedComponentView lastPage={taxClasses?.last_page} handlePageClick={taxClassPagination} />
                                    </div>
                                }
                            </>
                        </TabPanel>
                    </div>
                </Tabs>

            </div>
            {isDeleteConfirmationVisible && (
                <DeleteConfirmation onDelete={handleDelete} btnText={lang?.Delete} heading={lang?.Confirm_Delete} subHeading={lang?.Confirm_msg} notes={notes} onCancel={handleCancel} title={selectItemId?.type === 'class' ? `tax ${selectItemId?.type}` : selectItemId?.type} />
            )}
        </div>
    );
};

export default TaxManagement;
