import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import auth from "../../../helpers/auth";
import ReactStars from "react-rating-stars-component";

import { useDispatch, useSelector } from "react-redux";
import {  searchRecipefun} from '../modules/RecipeModule'

import imgs from "../../../assets/images/banner.jpg";
import hat from "../../../assets/images/hat-icon.svg";
import { Link } from "react-router-dom";
import language from "../../../helpers/language";


export default function RecipeIngredientsList(props) {
  const [lang] = useState(language.getLang());
  const [recipeList, setRecipeList] = useState()
  const [isRecipeFound,setIsRecipeFound]=useState(false)
  const dispatch = useDispatch()
  useEffect(async () => {
    const paramsSplit = props && props.location && props.location.search.split('?');
    const id = await paramsSplit[paramsSplit.length - 1];
    const str = id.split("-")
    console.log(str[0], "id...")
    if (str[0] === 'title') {
      await dispatch(searchRecipefun(str[1])).then(async (res) => {
        if (res && res.success === 200) {
          res && res.data && res.data.length > 0&& setIsRecipeFound(true)
          setRecipeList(res.data)

        }
      })
    }
  }, [])
  return (
    <Fragment>
      
      <div className="site__banner">
        <div className="banner__image">
          <img src={imgs} alt="Banner Image" />
        </div>
        <div className="banner__content">
          <div className="site__width text-center">
            <div className="banner__search-form">
              {
                isRecipeFound&&  <h4>{lang?.What_are_you_craving}</h4>
              }
             
              <form>
                <div className="top-search">
                  <span className="search_icon">
                    <i className="fas fa-search" />
                  </span>
                  <input type="text" placeholder="Search for recipes" />
                  <span className="filter_icon">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 0C9.70703 0 8.60547 0.84375 8.1875 2H0V4H8.1875C8.60547 5.15625 9.70703 6 11 6C12.293 6 13.3945 5.15625 13.8125 4H24V2H13.8125C13.3945 0.84375 12.293 0 11 0ZM11 2C11.5625 2 12 2.4375 12 3C12 3.5625 11.5625 4 11 4C10.4375 4 10 3.5625 10 3C10 2.4375 10.4375 2 11 2ZM18 8C16.707 8 15.6055 8.84375 15.1875 10H0V12H15.1875C15.6055 13.1562 16.707 14 18 14C19.293 14 20.3945 13.1562 20.8125 12H24V10H20.8125C20.3945 8.84375 19.293 8 18 8ZM18 10C18.5625 10 19 10.4375 19 11C19 11.5625 18.5625 12 18 12C17.4375 12 17 11.5625 17 11C17 10.4375 17.4375 10 18 10ZM7 16C5.70703 16 4.60547 16.8438 4.1875 18H0V20H4.1875C4.60547 21.1562 5.70703 22 7 22C8.29297 22 9.39453 21.1562 9.8125 20H24V18H9.8125C9.39453 16.8438 8.29297 16 7 16ZM7 18C7.5625 18 8 18.4375 8 19C8 19.5625 7.5625 20 7 20C6.4375 20 6 19.5625 6 19C6 18.4375 6.4375 18 7 18Z"
                        fill="#5B5B5B"
                      />
                    </svg>
                  </span>
                </div>
              </form>
              {/* <div className="create_recipe-btn">
                        <a href="javascript:script(0)"><img src={hat} />Create Recipe</a>
                      </div> */}
            </div>
            <div className="banner_text my-6">
              <div className="banner_flex">
                <div className="banner_text_wrapper">
                  <div className="banner_small-title">
                    <p>{lang?.Personalize_your_experience}</p>
                  </div>
                  <div className="banner-title">
                   
                  </div>
                </div>              
              </div>
            </div>

            <div className="section__wrapper">
              <div className="site__width">
                <div className="recipe__section">
                  <div className="recipe__section">
                    <div className="trending_List">
                      <ul>
                        {
                          recipeList && recipeList.data && recipeList.data.length > 0 && recipeList.data.map((item) => {
                            return (
                              <Fragment>
                                <li>
                                <Link to={`${"/SingleRecipeView"}?id-${item.recipe_id}`} >
                                  <div className="trending_Img">
                                   
                                      <img src={`${item.base_url}${item.attachment_url}`} />
                                  
                                    <div className="recipe_rating">
                                      <p>
                                        <span className="recipe_Name">{item.recipe_name} </span>
                                        <span className="star_rating">
                                          <ReactStars
                                            count={5}
                                            value={item.avg_rating}
                                            size={24}
                                            edit={false}
                                            color2={'#ffd700'} />
                                        </span>
                                      </p>
                                      <p>
                                      <span className="heart_Icon">
                                        {
                                          item.is_favourite>0?
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="18"
                                            viewBox="0 0 20 17.648"
                                          >
                                            <path
                                              id="like_icon_active"
                                              d="M15,26.648l-.318-.264c-.5-.414-1.172-.871-1.947-1.4C9.654,22.883,5,19.707,5,14.643a5.688,5.688,0,0,1,10-3.664,5.688,5.688,0,0,1,10,3.664c0,5.064-4.654,8.24-7.734,10.342-.777.529-1.447.986-1.947,1.4Z"
                                              transform="translate(-5 -9)"
                                              fill="#ff0046"
                                            />
                                          </svg>
                                          :<svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 20.653 18.099"
                                        >
                                          <path
                                            id="icons8_heart"
                                            d="M7.837,7A5.843,5.843,0,0,0,2,12.837c0,6.628,7.5,9.9,10.046,12.024l.281.238.281-.238c2.55-2.126,10.046-5.4,10.046-12.024A5.8,5.8,0,0,0,12.327,9.175,5.789,5.789,0,0,0,7.837,7Zm0,.9a4.922,4.922,0,0,1,4.111,2.217l.379.561.379-.561a4.932,4.932,0,0,1,9.05,2.722c0,5.791-6.468,8.788-9.429,11.126C9.366,21.625,2.9,18.628,2.9,12.837A4.933,4.933,0,0,1,7.837,7.9Z"
                                            transform="translate(-2 -7)"
                                          />
                                        </svg>
                                        }
                                       
                                          
                                          
                                        </span>
                                        <span className="likes">
                                          {item.is_favourite}{lang?.Likes}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="recipe_Info">
                                    <p className="mb-3">{item.username} </p>
                                    <p><span><i className="fa fa-clock-o" aria-hidden="true"></i></span>{`${item.hours}${lang?.Hr} ${item.minutes}${lang?.Mins}`} </p>
                                    <p><span><i className="fa fa-user" aria-hidden="true"></i></span>{item.serving}</p>
                                    <p><span><i className="fa fa-cutlery" aria-hidden="true"></i></span>{item.meal_name}</p>
                                  </div>
                                  </Link>
                                </li>
                              </Fragment>
                            )
                          })
                        }


                      </ul>


                    </div>
                  </div>
                </div>
              </div>
            </div>
              
          </div>
        </div>
        {
          recipeList===undefined&& <b>Recipe Not Found</b>
        }
        
      </div>



    </Fragment>
  );
}
