import React, { useEffect, useState } from 'react';
import language from '../../helpers/language';
import { Link } from 'react-router-dom';
import ImporterInquiryForm from '../../routes/marketplace/components/ImporterInquiryForm';
import { getMyAllOrderList } from '../../routes/marketplace/modules/marketplace';
import MyOrderList from './MyOrderList';
import MyWishlist from './MyWishlist';
import MyOffers from './MyOffers';
import { useSelector } from 'react-redux';
import MyAddress from './MyAddress';
import ImporterSideBar from './ImporterSideBar';
import Disputes from './Disputes';

export default function MyDashboard() {
    const [lang] = useState(language.getLang());
    const [recentOrderListing, setRecentOrderListing] = useState([]);

    let { myDashBoardStatus } = useSelector((state) => state.marketPlaceReducerData);
    const [activeTab, setActiveTab] = useState(myDashBoardStatus !== "" ? myDashBoardStatus : "inqirey");
    const [filterstatus,setFilterStatus]=useState()

    //all  recent order
    useEffect(() => {
        getAllRecentOrder({}, 1)
    }, []);


    const getAllRecentOrder = async (data, pageNo) => {
        const recentOrderListing = await getMyAllOrderList(data, pageNo);
        if (recentOrderListing) {
            setRecentOrderListing(recentOrderListing?.data)
        }
    }

    const handleTabChanges = (tab) => {
        setActiveTab(tab)
    }

    // paginated  order page
    const handleRecentOrderPageClick = async (e) => {
        const selectedPage = e.selected + 1;
        await getAllRecentOrder({filterstatus}, selectedPage)
    };

    // filter order by name, id, category
    const filterOrders = async (isfilter, pageNo) => {
        if (isfilter) await getAllRecentOrder(isfilter, pageNo);
        else {
            await getAllRecentOrder({}, '');
        }
    }

    
    return (
        <>
            <div className="marketplace dashboard header">
                {/* Banner Section */}
                <div>
                    <div className="section__wrapper">
                        <div className="flex justify-between items-center">
                            <h6 className="back-btn-small">
                                <Link to="/marketplace">
                                    <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;{" "}
                                    {lang?.Go_back}
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="servics_blocks dashboardView dashboardInquiry">
                <div className="site-align top-align">
                    <div className="left__section-block sm:mb-2 md:mb-2" >
                        <ImporterSideBar handleTabChanges={handleTabChanges} activeTab={activeTab} lang={lang} />
                    </div>

                    {activeTab === "inqirey" && <ImporterInquiryForm lang={lang}/>}
                    {activeTab === "myOrder" && <MyOrderList data={recentOrderListing} handlePageClick={handleRecentOrderPageClick} filterOrders={filterOrders} lang={lang} setFilterStatus={setFilterStatus} />}
                    {activeTab === "myAddress" && <MyAddress lang={lang}/>}
                    {/*activeTab === "myWishList" && <MyWishlist />*/}
                    {activeTab === "myOffers" && <MyOffers lang={lang}/>}
                    {activeTab === "Disputes" && <Disputes lang={lang}/>}
                </div>
                <br />
                <br />
            </div>
        </>
    )
}
