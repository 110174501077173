import React from 'react'

export default function Input({ type, inputTitle, name, options, country, onChange, errors, formData }) {
    const optionList = (type === "select" && inputTitle === 'State*') ? options : country;
    return (
        <div>

            <div className="form-item">
                {type === "text" ?
                    <input className='form-group' type={type} name={name} onChange={onChange} placeholder={inputTitle} value={formData[name]} />
                    : type === "textArea" ? <textarea className='form-group' name={name} onChange={onChange} placeholder={inputTitle} value={formData[name]} /> :
                        type === "select" ?
                            <select className='form-group' id={name} name={name} onChange={onChange} value={formData[name]}>
                                <option value="">Select {inputTitle}</option>
                                {optionList?.map(({ name, id, value }) => {
                                    return (<option value={inputTitle === 'State*' ? name : value} key={id}>{name}</option>)
                                })}
                            </select> : null
                }
            </div>
            {errors[name]?.length > 0 && <p style={{ color: "red" }}>{errors[name]?.length > 0 && errors[name]}</p>}
        </div>
    )
}
