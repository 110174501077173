import auth from '../../../helpers/auth'
import REACT_APP_API from "../../../config/environment";
import headerType from '../../../helpers/headerType';

export const PROFILE_DATA = "PROFILE_DATA"
export const VISITOR_PROFILE_DATA = "VISITOR_PROFILE_DATA"
export const SET_PROFILE_UPDATE_VALUES = "SET_PROFILE_UPDATE_VALUES";
export const SET_PROFILE_PROGRESS = "SET_PROFILE_PROGRESS";
export const SET_FEATURE_PRODUCT = "SET_FEATURE_PRODUCT";
export const ADD_FEATURE_PRODUCT_LIST = "ADD_FEATURE_PRODUCT_LIST";
export const SET_USER_PROFILE_FORM_FIELD = "SET_USER_PROFILE_FORM_FIELD";
export const SET_AWARD_LIST_DATA = "SET_AWARD_LIST_DATA";
export const SET_REGISTER_FORM_FIELD_VALUES = "SET_REGISTER_FORM_FIELD_VALUES";
export const SET_UPDATE_CONTACT_US = "SET_UPDATE_CONTACT_US"
export const SET_REGISTER_ERR_MESSAGE = "SET_REGISTER_ERR_MESSAGE";
export const SET_UPLOAD_IMAGE_ERR_MESSAGE = "SET_UPLOAD_IMAGE_ERR_MESSAGE";
export const SET_AVATAR_SPINNER_STATUS = "SET_AVATAR_SPINNER_STATUS";
export const SET_COVER_SPINNER_STATUS = "SET_COVER_SPINNER_STATUS";
export const SET_UPDATE_PROFILE_SPINNER_STATUS = "SET_UPDATE_PROFILE_SPINNER_STATUS";
export const SET_UPDATE_USER_FIELD_SPINNER_STATUS = "SET_UPDATE_USER_FIELD_SPINNER_STATUS";
export const SET_UPDATE_CONTACT_SPINNER_STATUS = "SET_UPDATE_CONTACT_SPINNER_STATUS";
export const SET_FEATURED_PRODUCT_SPINNER_STATUS = "SET_FEATURED_PRODUCT_SPINNER_STATUS";
export const SET_PROFILE_PERCENTAGE_SPINNER_STATUS = "SET_PROFILE_PERCENTAGE_SPINNER_STATUS";
export const SET_AWARD_SPINNER_STATUS = "SET_AWARD_SPINNER_STATUS";
export const SET_UPDATE_FEATURE_PRODUCT = "SET_UPDATE_FEATURE_PRODUCT";
export const CLEAR_FEATURE_PRODUCT_DATA = "CLEAR_FEATURE_PRODUCT_DATA";
export const SET_PROFILE_TAB_INDEX = "SET_PROFILE_TAB_INDEX";


export function setAvatarSpinner(flag) {
    return {
        type: SET_AVATAR_SPINNER_STATUS,
        payload: flag,
    };
}
export function setProfileTabIndex(index) {
    return {
        type: SET_PROFILE_TAB_INDEX,
        payload: index,
    };
}

export function setClearFeatureProductValue(value) {
    return {
        type: CLEAR_FEATURE_PRODUCT_DATA,
        payload: value,
    };
}

export function setCoverSpinner(flag) {
    return {
        type: SET_COVER_SPINNER_STATUS,
        payload: flag,
    };
}

export function setUpdateProfileSpinner(flag) {
    return {
        type: SET_UPDATE_PROFILE_SPINNER_STATUS,
        payload: flag,
    };
}

export function setUpdateUserFieldSpinner(flag) {
    return {
        type: SET_UPDATE_USER_FIELD_SPINNER_STATUS,
        payload: flag,
    };
}

export function setFeaturedProductSpinner(flag) {
    return {
        type: SET_FEATURED_PRODUCT_SPINNER_STATUS,
        payload: flag,
    };
}

export function setUpdateContactSpinner(flag) {
    return {
        type: SET_UPDATE_CONTACT_SPINNER_STATUS,
        payload: flag,
    };
}

export function setProfilePercentageSpinner(flag) {
    return {
        type: SET_PROFILE_PERCENTAGE_SPINNER_STATUS,
        payload: flag,
    };
}

export function setAwardSpinner(flag) {
    return {
        type: SET_AWARD_SPINNER_STATUS,
        payload: flag,
    };
}

export function getProfileData(data) {
    return {
        type: PROFILE_DATA,
        payload: data,
    };
}

export function getVisitorProfile(data) {
    return {
        type: VISITOR_PROFILE_DATA,
        payload: data,
    };
}

export function setProfileUpdateValues(data) {
    return {
        type: SET_PROFILE_UPDATE_VALUES,
        payload: data,
    };
}

export function setProfileProgress(data) {
    return {
        type: SET_PROFILE_PROGRESS,
        payload: data,
    };
}

export function setFeatureProduct(data) {
    return {
        type: SET_FEATURE_PRODUCT,
        payload: data,
    };
}

export function addFeatureProductList(data) {
    return {
        type: ADD_FEATURE_PRODUCT_LIST,
        payload: data,
    };
}

export function getUserProfileFormField(data) {
    return {
        type: SET_USER_PROFILE_FORM_FIELD,
        payload: data,
    };
}

export function getAwardList(data) { return { type: SET_AWARD_LIST_DATA, payload: data } }

export function setFieldValues(data) {
    return {
        type: SET_REGISTER_FORM_FIELD_VALUES,
        payload: data,
    };
}

export function updateContactUs(data) {
    return {
        type: SET_UPDATE_CONTACT_US,
        payload: data,
    };
}
export function updateFeatureProductData(data) {
    return {
        type: SET_UPDATE_FEATURE_PRODUCT,
        payload: data,
    };
}


export function setFormErrors(message, fld) {
    return {
        type: SET_REGISTER_ERR_MESSAGE,
        payload: { msg: message, field: fld },
    };
}
// type section end

export function setUploadImageErrors(message, fld) {
    return {
        type: SET_UPLOAD_IMAGE_ERR_MESSAGE,
        payload: { msg: message, field: fld },
    };
}

export function fetchAwardListing(visitorId) {
    const token = auth.getAccessToken();
    return async (dispatch, getState) => {
        await dispatch(setAwardSpinner(true));
        let url = visitorId
            ? `${REACT_APP_API.ENV.REACT_APP_API}/get/award/listing?visitor_profile_id=${visitorId}`
            : `${REACT_APP_API.ENV.REACT_APP_API}/get/award/listing`

        try {
            const result = await fetch(url, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(setAwardSpinner(false));
                await dispatch(getAwardList(response));
            } else {
                // alert(response.errors)
                await dispatch(setAwardSpinner(false));
                console.log(response.errors);
            }
        } catch (e) {
            await dispatch(setAwardSpinner(false));
            console.log(e);
        }
    };
}

export function profileFormField() {
    const token = auth.getAccessToken();
    return async (dispatch, getState) => {
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/user/submited/fields`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(getUserProfileFormField(response));
            } else {
                // alert(response.errors)
                console.log(response.errors);
            }
        } catch (e) {
            console.log(e);
        }
    };
}

export function memberProfileData(history) {
    const token = auth.getAccessToken();
    return async (dispatch, getState) => {
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/profile`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(getProfileData(response));
            } else {
                // history.push('/register');
                if (response?.error) {
                    history.push('/register') 
                }
            }
        } catch (e) {
            //console.log(e);
            history.push('/register');
        }
    };
}
export function visitorProfileData(visitorId) {
    const token = auth.getAccessToken();
    return async (dispatch, getState) => {
        await dispatch(setProfilePercentageSpinner(true));
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/visitor/profile?visitor_profile_id=${visitorId}`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(getVisitorProfile(response));
                await dispatch(setProfilePercentageSpinner(false));
            } else {
                await dispatch(setProfilePercentageSpinner(false));
                console.log(response.errors);
            }
        } catch (e) {
            await dispatch(setProfilePercentageSpinner(false));
            console.log(e);
        }
    };
}

export function updateProfileFeatureProductData(featureProductId) {
    const token = auth.getAccessToken();
    return async (dispatch, getState) => {
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/featured/listing/${featureProductId}`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(setClearFeatureProductValue({}));
                let objectValue = {}
                response && response.data && response.data.fields && response.data.fields.map(async (item) => {
                    if (item.featured_listing_field_id) {
                        objectValue["8"] = item.value;
                    }
                    (
                        objectValue[item.featured_listing_field_id] = item.value
                    )
                });
                delete objectValue["4"];
                objectValue["featured_listing_id"] = featureProductId;
                await dispatch(setClearFeatureProductValue(objectValue));
                await dispatch(updateFeatureProductData(response.data));
            } else {
                // alert(response.errors)
                console.log(response.errors);
            }
        } catch (e) {
            console.log(e);
        }
    };
}

export function profileProgress() {
    const token = auth.getAccessToken();
    return async (dispatch, getState) => {
        await dispatch(setProfilePercentageSpinner(true));
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/profile/progress`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(setProfilePercentageSpinner(false));
                if (response?.data) {
                    await localStorage.setItem("profile_percentage", response?.data?.profile_percentage)
                }
                await dispatch(setProfileProgress(response));
            } else {
                // alert(response.errors)
                console.log(response.errors);
            }
        } catch (e) {
            console.log(e);
        }
    };
}



export const changeAvatarCover = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        if (model.image_type === 1) {
            await dispatch(setAvatarSpinner(true));
        } else if (model.image_type === 2) {
            await dispatch(setCoverSpinner(true));
        }
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/avatar/cover/image`, {
                    method: "POST",
                    headers: {
                        // 'Content-Type': 'application/vnd.api+json; charset=utf-8',
                        // Accept: 'application/json',
                        Authorization: `Bearer ` + token,
                    },
                    body: form_data,
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(setCoverSpinner(false));
                    await dispatch(setAvatarSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(setAvatarSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            // alert('You need to login first')
            console.log('You need to login first');
        }
    };
};


export const updateProfile = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    //console.log(model,"model");
    return async (dispatch, getState) => {
        await dispatch(setUpdateProfileSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/user/profile`, {
                    method: "POST",
                    headers: {
                        // 'Content-Type': 'application/vnd.api+json; charset=utf-8',
                        // Accept: 'application/json',
                        Authorization: `Bearer ` + token,
                    },
                    body: form_data,
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(memberProfileData());
                    await dispatch(setUpdateProfileSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(setUpdateProfileSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            // alert('You need to login first')
            await dispatch(setUpdateProfileSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const userFieldUpdate = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(setUpdateUserFieldSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/user/field`, {
                    method: "POST",
                    headers: { Authorization: `Bearer ` + token },
                    body: form_data,
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(setUpdateUserFieldSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(setUpdateUserFieldSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            // alert('You need to login first')
            await dispatch(setUpdateUserFieldSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const featureProductList = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(setFeaturedProductSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/post/featured/listing`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ` + token,
                    },
                    body: form_data,
                    redirect: 'follow'
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(setFeaturedProductSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(setFeaturedProductSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(setFeaturedProductSpinner(false));
            // alert('You need to login first')
            console.log('You need to login first');
        }
    };
};

export const deleteFeatureProductList = (_id) => {
    var formdata = new FormData();
    formdata.append("featured_listing_id", _id);
    return async (dispatch, getState) => {
        await dispatch(setFeaturedProductSpinner(true));
        const token = auth.getAccessToken()
        try {
            let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/featured/listing`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ` + token,
                },
                body: formdata,
                redirect: 'follow'
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(setFeaturedProductSpinner(false));
                return response
            }
        } catch (e) {
            await dispatch(setFeaturedProductSpinner(false));
            console.log(e, 'error.');
        }
    };
};

export const updateContactDetail = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(setUpdateContactSpinner(true));
        const token = auth.getAccessToken()
        try {
            let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/contact/details`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ` + token,
                },
                body: form_data,
                redirect: 'follow'
            });
            let response = await result.json();
            if (response.success) {
                await dispatch(setUpdateContactSpinner(false));
                return response
            }
        } catch (e) {
            console.log(e, 'error.');
        }
    };
};

// initialState
export const initialState = {
    profileData: [],
    awardListData: [],
    visitorProfile: [],
    editFeatureProductData: [],
    profileUpdateValue: {},
    profileProgressData: [],
    featureProduct: {},
    addFeatureProduct: [],
    formField: [],
    formValue: {},
    updateContact: {},
    errMessage: { msg: "", field: "", },
    errImageMessage: { msg: "", field: "" },
    avatarSpinner: false,
    coverSpinner: false,
    awardSpinner: false,
    updateProfileSpinner: false,
    updateContactSpinner: false,
    profilePercentageSpinner: false,
    featuredProductSpinner: false,
    updateUserFieldSpinner: false,
    profiletabIndex: 0,
};

const ACTION_HANDLERS = {
    [PROFILE_DATA]: (state, action) => {
        return {
            ...state,
            profileData: action.payload,
        };
    },
    [VISITOR_PROFILE_DATA]: (state, action) => {
        return {
            ...state,
            visitorProfile: action.payload,
        };
    },
    [SET_PROFILE_UPDATE_VALUES]: (state, action) => {
        return {
            ...state,
            profileUpdateValue: action.payload,
        };
    },
    [SET_PROFILE_PROGRESS]: (state, action) => {
        return {
            ...state,
            profileProgressData: action.payload,
        };
    },
    [SET_FEATURE_PRODUCT]: (state, action) => {
        return {
            ...state,
            featureProduct: action.payload,
        };
    },

    [ADD_FEATURE_PRODUCT_LIST]: (state, action) => {
        return {
            ...state,
            featureProduct: action.payload,
        };
    },
    [SET_USER_PROFILE_FORM_FIELD]: (state, action) => {

        return {
            ...state,
            formField: action.payload,
        };
    },
    [SET_AWARD_LIST_DATA]: (state, action) => { return { ...state, awardListData: action.payload } },

    [SET_REGISTER_FORM_FIELD_VALUES]: (state, action) => {
        return {
            ...state,
            formValue: action.payload,
        };
    },
    [SET_UPDATE_CONTACT_US]: (state, action) => {
        return {
            ...state,
            updateContact: action.payload,
        };
    },
    [SET_REGISTER_ERR_MESSAGE]: (state, action) => {
        return {
            ...state,
            errMessage: action.payload,
        };
    },
    [SET_UPLOAD_IMAGE_ERR_MESSAGE]: (state, action) => {
        return {
            ...state,
            errImageMessage: action.payload,
        };
    },
    [SET_AVATAR_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            avatarSpinner: action.payload,
        };
    },
    [SET_COVER_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            coverSpinner: action.payload,
        };
    },
    [SET_UPDATE_PROFILE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            updateProfileSpinner: action.payload,
        };
    },
    [SET_UPDATE_USER_FIELD_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            updateUserFieldSpinner: action.payload,
        };
    },
    [SET_FEATURED_PRODUCT_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            featuredProductSpinner: action.payload,
        };
    },
    [SET_UPDATE_CONTACT_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            updateContactSpinner: action.payload,
        };
    },
    [SET_PROFILE_PERCENTAGE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            profilePercentageSpinner: action.payload,
        };
    },
    [SET_AWARD_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            awardSpinner: action.payload,
        };
    },
    [SET_UPDATE_FEATURE_PRODUCT]: (state, action) => {
        return {
            ...state,
            editFeatureProductData: action.payload,
        };
    },
    [CLEAR_FEATURE_PRODUCT_DATA]: (state, action) => {
        return {
            ...state,
            featureProduct: action.payload,
        };
    },
    [SET_PROFILE_TAB_INDEX]: (state, action) => {
        return {
            ...state,
            profiletabIndex: action.payload,
        };
    },
};

// memberProfileData key memberProfileReducer
export default function memberProfileReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
