import React, { useState } from 'react'
import moment from 'moment'
import action_img from '../../assets/images/action.svg'
import check_img from '../../assets/images/check_img.svg'
import check_img_default from '../../assets/images/check_img_yellow.svg'
import cross_img from '../../assets/images/close-img.svg'
import file_img from '../../assets/images/print_img.svg'
import PaginatedComponentView from '../PaginatedComponent/PaginatedComponentView'
import { Link } from 'react-router-dom'
import Modal from "react-modal";
import language from '../../helpers/language'


export default function ProductOrderTable({ orderData }) {
    const [status, setStatus] = useState(false);
    const [lang] = useState(language?.getLang());
    const { file, data, hanldeOption, handleFileChange, error, changeOrderStatus, handlePageClick, setFileUpload, fileUpload, uploadOrderInovice, loading, setFile } = orderData;

 console.log(data?.last_page,"last_page")
    const selectOpt = (status) => {
        if (status === "completed") return <option value={'completed'}>Completed</option>
        if (status === "cancelled") return <option value={'cancelled'}>Cancelled</option>
        if (status === "pending") return (<> <option value={'pending'} disabled>Pending</option>  <option value={'processing'}>Processing</option>  <option value={'cancelled'}>Cancelled</option> </>)
        if (status === "processing") return (<> <option value={'processing'} disabled>Processing</option>  <option value={'on hold'}>On hold</option> <option value={'in transit'}>In transit</option> </>)
        if (status === "in transit") return (<><option value={'completed'}>Completed</option><option value={'in transit'} disabled>In transit</option></>)
        if (status === "on hold") return (<> <option value={'on hold'} disabled>On hold</option> <option value={'cancelled'}>Cancelled</option> <option value={'in transit'}>In transit</option></>)
    }

  
    return (
        <div className='itemTable itemTableScroll customer__table'>
            <table>
                <thead>
                    <tr>
                        <th className='id'><div className='CustomId'>Order ID</div></th>
                        <th className='customer__name'><div className='CustomName'>Customer Name</div></th>
                        <th className='customer__name'><div className='custPoduct'>Product</div></th>
                        <th className='customer__name'><div className='custCategory'>Category</div></th>
                        <th className='date'><div className='dateField'>Date</div></th>
                        <th className='qty'>Quantity</th>
                        <th className='total'><div className='custTotal'>Total</div></th>
                        <th>Status <span onClick={() => (setStatus(!status))}><i className="fa fa-info-circle infoIcon" aria-hidden="true" title=""></i></span></th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.data?.length > 0 ? data?.data?.map(({ order_id, num_items_sold, baseUrl, buyer_info, invoice_name, status, total_seles, created_at, currency, product_item_info }) => {
                        let productInfo = product_item_info?.map(({ product_info }) => ({ name: product_info?.title, category: product_info?.product_category?.option }))
                            ?.filter((item) => item.name && item.category);
                        const isDisable = (status === "completed" || status === "cancelled");
                        const isCancelled = (status === "on hold" || status === "pending");
                        const isAccept = (status === "pending");
                        return (
                            <tr key={order_id}>
                                <td className='id'>{order_id}</td>
                                <td className="customer__name">{buyer_info?.company_name}</td>
                                <td className="customer__name product">{productInfo && productInfo?.map(({ name }, i, ar) => ar?.length > i + 1 ? <span>{`${name} ,`} </span> : <span>{name}</span>)}</td>
                                <td className="customer__name category-width">{productInfo && productInfo?.map(({ category }, i, ar) => ar?.length > i + 1 ? `${category} ,` : category)}</td>
                                <td className='date'>{moment(created_at).format("DD/MM/YYYY hh:mm A")}</td>
                                <td className='qty'>{num_items_sold}</td>
                                <td className='total'>{currency}{total_seles}</td>
                                <td className='newOrder'>
                                    <select disabled={isDisable} value={status} onChange={(e) => hanldeOption(order_id, e.target.value)}>
                                        {selectOpt(status)}
                                    </select>
                                </td>
                                <td>
                                    <div className='iconGroup'>
                                        <Link to={`/marketplace/producer-my-order-view/${order_id}`} >
                                            <span className='' title="View">
                                                <img src={action_img} alt="Action" />

                                            </span>
                                        </Link>
                                        <span className="" disabled={!isAccept} title={`${isAccept? "Accept" : "Accepted"}`} onClick={() => isAccept && changeOrderStatus(order_id, "processing", true)}>
                                            <img src={`${isAccept?check_img_default:check_img}`} alt="Action" />

                                        </span>
                                        {/* show this after click on accept */}
                                        {/* <span className='' disabled={!isAccept} title="Accept" onClick={() => isAccept && changeOrderStatus(order_id, "processing", true)}>
                                            <img src={check_img} alt="Action" />

                                        </span> */}
                                        <span className='' disabled={!isCancelled} title="Cancel" onClick={() => isCancelled && changeOrderStatus(order_id, "cancelled", true)}>
                                            <img src={cross_img} alt="Action" />
                                        </span>
                                        <span className='' title="Upload Invoice">
                                            {invoice_name ? <a href={`${baseUrl}${invoice_name}`} target={'_blank'}>
                                                <img src={file_img} alt="Action" />
                                            </a> :
                                                <>
                                                    <div className='fileUpload' onClick={() => setFileUpload({ isOpen: true, id: order_id })}>
                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                    </div>
                                                    {fileUpload?.isOpen && <Modal
                                                        className=""
                                                        ariaHideApp={false}
                                                        isOpen={fileUpload?.isOpen}
                                                        style={{
                                                            overlay: {
                                                                position: 'fixed',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                                                            },
                                                            content: {
                                                                position: 'absolute',
                                                                top: '0',
                                                                left: '0%',
                                                                right: '0%',
                                                                bottom: '200px',
                                                                border: '1px solid #ccc',
                                                                background: '#fff',
                                                                overflow: 'auto',
                                                                WebkitOverflowScrolling: 'touch',
                                                                borderRadius: '4px',
                                                                padding: '0px',
                                                                // outline: 'none',
                                                            },
                                                        }}
                                                    >
                                                        <div className="popup_header flex justify-between items-center">
                                                            <h4 className="page__title">Upload File</h4>
                                                            <button className="close_btn--modal" onClick={() => { setFile(null); setFileUpload({ isOpen: false, id: '' }) }}>
                                                                {' '}
                                                                {language?.close}
                                                            </button>

                                                        </div>

                                                        <div className="card card--block modal_box">
                                                            <div className="crop_img_block text-center">
                                                                <label className='fileUpload'>
                                                                    <input
                                                                        type="file"
                                                                        accept="application/pdf"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                    <span>Choose file to upload
                                                                        <i className="fa fa-upload" aria-hidden="true"></i>
                                                                    </span>
                                                                </label>
                                                                {file && (
                                                                    <div className='mt-4 text-center'>
                                                                        <p>Selected File: {file.name}</p>
                                                                        {/* You can perform further actions with the selected file here */}
                                                                    </div>
                                                                )}
                                                                {file && <button type='button' disabled={loading} onClick={() => uploadOrderInovice(fileUpload?.id)} className='btn btnBlue addtocart mt-4'>{loading ? "Uploading.." : "Submit"}</button>}
                                                            </div>
                                                        </div>

                                                    </Modal>}
                                                </>}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : <p>No result found.</p>
                    }
                </tbody>
            </table>
            <PaginatedComponentView lastPage={data?.last_page} handlePageClick={handlePageClick} />
          {/* Status Popup */}
            <Modal
                className="incoTermModal Status"
                ariaHideApp={false}
                isOpen={status}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    },
                    content: {
                        position: 'absolute',
                        top: '0',
                        left: '0%',
                        right: '0%',
                        bottom: '200px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        padding: '0',
                        // outline: 'none',
                    },
                }}
            >
                <div className="popup_header flex justify-between items-center">
                    <h4 className="page__title"></h4>
                    <button
                        className="close_btn--modal"
                        onClick={() => setStatus()}
                    >
                        {lang?.close}
                    </button>
                </div>
                <div className='modal-body contentModal'>
                    <div className="card card--block modal_box modalLessGap">
                        <strong>Pending: </strong>
                        <p>
                          The order has not been yet accepted by the producer
                        </p>
                        <strong>Processing: </strong>
                        <p>
                          The order has been accepted by the producer
                        </p>
                        <strong>Hold: </strong>
                        <p>
                          The order has been put on hold by the producer
                        </p>
                        <strong>Cancel: </strong>
                        <p>
                          The producer cancelled the order
                        </p>
                        <strong>In Transit: </strong>
                        <p>
                          The order is on the way to the final destination
                        </p>
                        <strong>Completed: </strong>
                        <p>
                          The order has been received by the importer
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
