import React from 'react'
import { Link, useLocation, useHistory } from "react-router-dom";
import auth from '../../../../../helpers/auth';

export default function InnerSideBar() {
    const location = useLocation();
    const history = useHistory();

    const logout = async (action) => {
        auth.removeAccessToken();
        const BannerStatus = localStorage.getItem("BannerStatus");
        localStorage.clear();
        localStorage.setItem('lang', 'en');
        if(BannerStatus!==null){
            localStorage.setItem("BannerStatus", BannerStatus);
        }
        
        history.push('/register');
      };
  return (
    <>
        <div className='settingSideBar'>
            <ul>
                <li>
                    <Link to="/change-password" className={location.pathname === "/change-password" || location.pathname ==="/buyer-settings" ? "Active" : ""}>Change Password</Link>
                </li>
                <li>
                    <Link to="/change-contact-info" className={location.pathname === "/change-contact-info" ? "Active" : ""}>Change Contact Information</Link>
                </li>
                <li>
                    <Link to="/delete-account" className={location.pathname === "/delete-account" ? "Active" : ""}>Delete My Account</Link>
                </li>
                <li>
                    <Link to="#" onClick={() => logout()}>Sign out</Link>
                </li>
            </ul>
        </div>
    </>
  )
}
