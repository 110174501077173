import React, { useEffect } from 'react';
import Input from './Input';
import { saveBillingAndDeliveryAddress, updateBillingAndDeliveryAddress } from '../../routes/marketplace/modules/marketplace';
import { country } from '../Dashboard/AddressFormFields/AddressData';
import { useSelector } from 'react-redux';


function BillingForm({lang, hadleSubmitData, id, formType, handleCancel, setResponseErrors, sameAsShipping, submitBtn, loading, setLoading, setFormData, formData, newAddressFields, errors, setErrors, isSameAsShipping }) {
  let { regions } = useSelector((state) => state.register);

  useEffect(() => {
    if (!submitBtn) {
      if (!isSameAsShipping) {
        newAddressFields?.map(({ name, value, addressId }) => {
          setErrors((prev) => ({ ...prev, addressId: addressId, [name]: '' }))
          setFormData((prev) => ({ ...prev, [name]: '' }))
        });

      } else {
        newAddressFields?.map(({ name, value, addressId }) => {
          setErrors((prev) => ({ ...prev, addressId: addressId, [name]: '' }))
          setFormData((prev) => ({ ...prev, [name]: value }))
        });
      }
    }

    return (() => setFormData({}));
  }, [isSameAsShipping]);


  useEffect(() => {
    if (id && submitBtn) {
      newAddressFields?.map(({ name, value, addressId }) => {
        setErrors((prev) => ({ ...prev, addressId: addressId, [name]: '' }))
        setFormData((prev) => ({ ...prev, [name]: value }))
      });
    }

  }, [id]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseErrors({ message: "", type: false });
    setLoading(true);
    if (handleErrors()) {
      formData.address_type = formType;
      if (formType === "billing") formData["same_billing_address"] = sameAsShipping;
      const response = id ? await updateBillingAndDeliveryAddress(formData, formType, id) : await saveBillingAndDeliveryAddress(formData);
      if (response?.success === 200) {
        setResponseErrors({ message: response?.message ?? `Address has been ${id ? "update" : "save"} successfully.`, type: true });
        hadleSubmitData();
        setLoading(false);
      } else {
        setResponseErrors({ message: response?.message ?? "Something  went wrong.", type: false });
      }
    } else {
      setLoading(false)
    }
  };

  const handleErrors = () => {
    let formValidation = true;
    for (let key in formData) {
      if (key !== "same_billing_address" && (formData[key]?.trim()?.length === 0 && !formData[key]) && key !== "street_address_2") {
        formValidation = false
        setErrors(prev => ({ ...prev, [key]: lang?.Option_require }));
      }
    }
    return formValidation;
  }


  return (
    <div className="billing-form mt-4 mb-4">
      <h2 className=''>{formType === "shipping" ? lang?.New_address_added : lang?.Add_billing_details}</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='shipClassForm'>
          {newAddressFields?.map(({ name, type, placeHolder, id }) => {
            return (
              <Input key={id} type={type} inputTitle={placeHolder} name={name} onChange={handleChange} errors={errors} options={regions} country={country} formData={formData} />
            )
          })}
        </div>
        <div className='btnRight'>
          {submitBtn && <button disable={loading ? loading : false} type="submit" className='btn btnBgBlue'>{loading ? lang?.Loading : lang?.only_Submit}</button>}
          <button type="button" onClick={() => handleCancel(formType)} className='btn btnborderBlue'>{lang?.Cancel}</button>
        </div>
      </form>
    </div>
  );
}

export default BillingForm;
