import React, { Fragment, useEffect } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import importedComponent from "react-imported-component";
import Loading from "../components/Loading";
import { createRoutes } from "../routes/index";
import store, { history } from "../store/store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "../assets/style.css";
import "../assets/custom.css"
import "../assets/reactCrop.css"
import "../assets/alyseiNews.css"
// import 'react-accessible-accordion/dist/fancy-example.css';
// import firebase from "../config/firebase"

const App = () => {
  const routes = createRoutes();

  // useEffect(() => {
  //   let token;
  //   const msg = firebase.messaging();
  //   msg.requestPermission().then((data) => {
  //     return msg.getToken({ vapidKey: "BHLo7BWxSDBdtsXwALVmYgQ22AA33xPrm4Dyvbj3ruUwtcc9anXYdXTPVD_odB3vH7K4OuAOcYe7qBnNd8HmITw" });
  //   }).then((data) => {
  //     console.log("token", data)
  //     token = data
  //   })
  // }, [])

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Fragment>{routes}</Fragment>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
