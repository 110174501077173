export default {
  getLang() {
    let lang = localStorage.getItem("lang");

    const EN = {
      lan: "en",
      Name: "Please enter first name.",
      LastName: "Please enter last name.",
      MobNo: "Please enter mobile number.",
      Please_Enter_Email: "Please enter email address. ",
      ValidEmail: "Please enter valid email address.",
      Password: "Please enter password.",
      ValidPass: "Please enter confirm password. ",
      NewPass: "Please enter new password.",
      Address: "Please enter address.",
      Please_Select_Country: "Please select country first",
      State: "Please select state first.",
      RecoveryEmail:
        "A recovery email has been sent to your registered email address",
      NewAndConfirmPassNotMatch:
        "New password and confirm password doesn't match.",
      ValidMobNo: "Please enter valid number.",
      Otp: "Please enter OTP.",
      LocationEnable: "Location enabled.",
      LocationNotEnable: "Location not enabled.",
      SomeThingsWrong: "Something went wrong. Please try again.",
      NetworkError: "Unable to connect to the Internet. Please try again. ",
      SessionExpire: "Your session has expired. Please login again.",
      LoctionNotEnable: "Location not enabled. Please turn on the location.",
      FillRequireInfo: "Please fill all the required Information. ",
      TermAndCondition: "Please agree to Terms&Conditions. ",
      NumberAddedSuccess: "Number Added successfully.",
      EmailAddedSuccess: "Email added successfully. ",
      OtpSendSuccessfull: "OTP sent successfully.",
      OptSendOnEmail: "OTP sent on your mail.",
      PasswordChangeSuccessfull: "Password changed successfully.",
      SourceType: "Please choose a source type",
      TakePhoto: "Take Photo",
      FromLibrary: "Choose From Library",
      DeletePhoto: "Delete photo",
      RemovePhoto: "Remove photo",
      SixDigitOtp: "Enter 6-digit OTP.",
      FeatureNotAvailable: "This feature is not available.",
      ProfileUpdateSuccess: "Profile updated successfully.",
      UpLoadImage: "Please upload image.",
      ChooseRoleId: "Please choose the role.",
      ReasonToDecline: "Please enter reason to decline ",
      SelectCookingSkill: "Please Select Cooking Skill.",
      SelectCuisine: "Please select Cuisine. ",
      SelectMeal: "Please select Meal.",
      SelectCourse: "Please select Course.",
      SelectDiet: "Please select Diet.",
      SelectPrepationTime: "Please select Preparation Time.",
      CookManyPeople: "Please select for how much people you are cooking.",
      SelectFood: "Please select Food Intolerance.",
      SelectRegion: "Please select Region.",
      EnterIngredientName: "Please enter Ingredient Name.",
      SelectCategory: "Please select a Category.",
      Edit_desc: "Please enter Description.",
      Title: "Please enter Title.",
      AddIngrdient: "Please add Ingredient.",
      AddTool: "Please add at least one Utensils/Tools.",
      SelectUnit: "Please select Unit",
      EnterQuantity: "Please enter Quantity",
      CharacterNumber: "Please enter more than 3 character.",
      DescriptionOrUrl: "Please enter Description or Url. ",
      LocationServiceFeature:
        "We don't have access to location services on your device. Please go to settings and enable location services to use this feature.",
      PassWordValidation:
        "Password must be at least 8 characters and contain at least one numeric digit and a special character.",
      MarketPlacePowerfullEng:
        "The most powerful marketplace engine for the Made in Italy.",
      FdaIconText:
        "The owner, operator, or agent in charge of either a domestic or foreign facility that is engaged in manufacturing/processing, packing, or holding of food for human or animal consumption in the United States, must register with FDA",
      AlertMsg: "Alert Message.",
      WantToLogout: "Are you sure you want to logout?",
      Speciality: "Please select at least one speciality",
      EnterCity: "Please enter your city",
      SelectCity: "Please select the City",
      Zip_Postal: "Please enter the ZIP/POSTAL CODE",
      InvailidZip_Postal: "Invalid Zip/Postal code",
      Expertise: "Please select at least one expertise",
      SelectTitle: "Please select at least one title",
      DeliveryOption: "Please select pick and delivery option.",
      PickUpDiscount: "Please select pick up discount for alysei users.",
      DeliveryDiscount: "Please select delivery discount for alysei users",
      RestaurantType: "Please select Restaurant Type.",
      Horeca: "Please select the Horeca",
      PrivateLebel: "Please select the Private Label.",
      AlyseiBrandLebel: "Please select the Alysei Brand Label.",
      Term_Condition: "Please accept the terms and conditions.",
      // marketPlace hint -
      ImporterInquiry: "Inquiries",
      Alysei_Marketplace: "Alysei Marketplace",
      Recently_Added_Product: "Recently Added Product",
      Newly_Added_Stores: "Newly Added Stores",
      Quick_search_by_Italian_Regions: "Quick search by Italian Regions",
      Top_Rated_Products: "Top Rated Products",
      Top_Favourite_Products: "Top Favourite Products",
      Product_Title: "Title*",
      Product_Type: "Product Type",
      Conservation_Method: "Conservation Method",
      Product_Properties: "Product Properties",
      Italian_Region: "Italian Region",
      Reset_Filter: "Clear Filters",
      FDA_Certification: "FDA Certification",
      Update_Review: "Update Review",
      Edit_review: "Edit review",
      Edit_your_review_and_ratings: "Edit your review and ratings",
      Related_Products: "Related Products",
      Sample_Available: "Sample Available",
      Dispatch_Instructions: "Dispatch Instructions",
      Handling_Instructions: "Handling Instructions",
      Minimum_Order_Quantity: "Minimum Order Quantity",
      Brand_Label: "Brand Label",
      Quantity_Available: "Quantity Available",
      About_Product: "About Product",
      Imagetype: "The Image type must be JPEG, JPG, PNG",
      ImageSize: "Image size should not be more than 2 MB",
      Go_back: "Back to Marketplace",
      Go_back_Dashboard: "Back to dashboard",
      ImageMaxLen:
        "Choose your stores main photo first. Add more photos of your store max 8 allowed*",
      ImageMaxLen5:
        "Choose your stores main photo first. Add more photos of your store max 5 allowed",
      Min_Order_Quantity: "Minimum Order Quantity",
      Dispatch_Instruction: "Dispatch Instruction",
      Available_For_Sample: "Available For Sample",
      Product_Price: "Product Price",
      Min_Char: "should be minimum char",
      is_required: "is required",
      LessThan: "should be less than",
      MinValue: "should be minimum value",
      LessThanOrEq: "should be less than or equal to",
      LogoImage: "Please select logo image",
      GalleryImage: "Please select gallery images",
      Crop_image: "Crop Image",
      Config_store: "Configure Your Store",
      NotAllowed: "You are not allowed to access this page",
      GoHome: "GO TO HOME PAGE",
      MyDashboard: "My Dashboard",
      AddReviewAndRatings: "Add reviews and ratings",
      EditReviewAndRatings: "Edit your review and ratings",
      Select_package: "Alysei Marketplace MemberShip",
      Alysei_B2B_Marketplace: "Alysei B2B Marketplace",
      Select_best_package: "Choose a plan that is right for you",
      Thank_you: "Thank you for submitting your information for admin review",
      will_resp: "We will respond you at earliest",
      View_profile: "View Profile",
      Our_gallery: "Our gallery",
      About_store: "About Store",
      Store_products: "Store Products",
      // Select_fda_Certificate: 'Select FDA Certificate',
      Select_fda_Certificate: "Select",
      Ratings_and_reviews: "Ratings & Reviews",
      Add_review: "Add Rating",
      Products: "Product Management",
      Producer_Store: "Producer Store",
      View_all: "View all",
      Search: "Search",
      Select: "Select",
      Categories: "Categories",
      Location: "Location",
      call: "call",
      Website: "Website",
      Direction: "Direction",
      Favourite: "Favourite",
      Send_Inquiry: "Send Inquiry",
      See_All: "See All",
      Accept: "Accept",
      Decline: "Decline",
      Decline_Request: "Decline Request",
      Reason_to_Decline: "Reason to Decline",
      Confirm: "Confirm",
      Store_Name: "Store Name",
      Phone_number: "Phone Number",
      PhoneNumber: "Phone Number",
      Email: "Email",
      Store_region: "Store Region",
      Photos: "Photos",
      Dashboard: "Dashboard",
      Inquiries: "Inquiries",
      Total_products: "Total Products",
      Total_category: "Total Categories",
      Total_reviews: "Total Ratings",
      Analytics: "Analytics",
      Yearly: "Yearly",
      Monthly: "Monthly",
      Weekly: "Weekly",
      Yesterday: "Yesterday",
      Today: "Today",
      Add_new_product: "Add New Product",
      No_product_found: "No Product found",
      Update: "Update",
      Add_gallery: "Add Gallery",
      Total_inquiries: "Total Inquiries",
      Add_photos: "Add Photos",
      only_title: "Title",
      Keywords: "Keywords",
      Product_category: "Product Category",
      Select_product_category: "Select Product Category",
      yes: "Yes",
      no: "No",
      Description: "Description",
      Edit_prod_desc: "Product Description",
      Select_brandLebel: "Select Brand Labels",
      New: "New",
      Open: "Open",
      opened: "Opened",
      Closed: "Closed",
      close: "close",
      No_new_inquery: "No New Inquery",
      No_closed_inquery: "No Closed Inquery",
      only_product: "Product",
      Product_listing: "Product Listing",
      Sort_by: "Sort By",
      AtoZ: "A to Z",
      ZtoA: "Z to A",
      Most_Rated_Stores: "Most Rated Stores",
      Star_Stores: "Star Stores",
      Most_Searched: "Most Searched",
      No_review_found: "No reviews till now",
      No_data_found: "No Data found.",
      Fast_response:
        " Fill the form just once to get faster responses. Store will contact you shortly",
      Full_name: "Full Name",
      Message: "Message",
      GO_TO_MARKETPLACE: "GO TO MARKETPLACE",
      Showing: "Showing",
      results: "results",
      Next: "Next",
      View: "View",
      Connect: "Connect",
      Connected: "Connected",
      Cancel: "Cancel",
      Requested: "Requested",
      Italian_F_and_B_producers: "Italian F&B Producer",
      Importer_And_Distributor: "Importer & Distributor",
      Importer_And_Distributor_Singular: "Importer & Distributor",
      Voice_Of_Experts: "Voice Of Expert",
      Voice_Of_Expert: "Voice Of Expert",
      B2b_Voice_Of_Experts: "Voice Of Experts",
      Travel_Agencies: "Travel Agency",
      Italian_Restaurants_in_US: "Italian Restaurant in US",
      Italian_F_and_B_producersB2B: "Italian F&B Producers",
      Importer_And_DistributorB2B: "Importers & Distributors",
      Travel_AgenciesB2B: "Travel Agencies",
      Italian_Restaurants_in_USB2B: "Italian Restaurants in US",
      Voyagers: "Voyagers",
      Hubs: "Hub",
      Private_Label: "Private Label",
      Label: "Label",
      Hotel_Restaurant_Café: "Hotel/Restaurant/Café",
      HORECA: "HORECA",
      Products_sold_under_another_companys_brand:
        "Products sold under another company's brand",
      Alysei_Brand_Label: "Alysei Brand Label",
      Provide_pick_up_and_or_Delivery: "Provide pick up and/or Delivery?",
      Pick_up: "Pick up",
      Delivery: "Delivery",
      United_State: "United States",
      Italy: "Italy",
      Country: "Country",
      Unsubscribe: "Unsubscribe",
      Subscribe: "Subscribe",
      Edit: "Edit",
      HouseAndFlatNo: "Enter your Address",
      Enter_email: "Enter a email",
      NoPlace_holder: "999-999-9999",
      Award: "Award",
      Save: "Save",
      Add: "Add",
      Image: "Image",
      Name_of_Competition_Event: "Name of Competition / Event",
      Name_of_winning_Product: "Prize",
      Name_of_Modal_place_recived: "Medal / place received",
      Select_Medal_Type: "Select-Medal-Type",
      Select_Medal: "Select Medal",
      Bronze: "Bronze",
      Silver: "Silver",
      Gold: "Gold",
      Please_select_BlogTitle: "Please Enter Blog Title",
      Please_block_Description: "Please Enter Blog Description.",
      Please_select_blog_status: "Please Select Blog Status.",
      Please_select_BlogImage: "Please Select Blog ImageId.",
      Blog: "Blog",
      Create: "Create",
      Status: "Status",
      Event: "Event",
      Event_View: "Event View",
      name: "Name",
      Date: "Date",
      Host: "Host",
      Time: "Time",
      Type: "Type",
      Free: "Free",
      Paid: "Paid",
      buy_invitation: "By Invitation",
      price: "Price",
      Event_Description: "Event Description",
      PriceType: "select-price-type",
      currencyType: "Select Currency",
      Duration: "Duration",
      intensity: "Intensity",
      only_country: "country",
      Registration: "Registration",
      Select_Event: "Select Event Type",
      Currency_type: "Currency type",
      Adventure_Type: "Adventure Type",
      Adventure: "Adventure",
      Select_reg_type: "Select Registration Type",
      Event_Registration_Type: "Event Registration Type",
      Public: "Public",
      public: "Public",
      Private: "Private",
      Trip_View: "Trip View",
      Trip: "Trip",
      days: "days",
      day: "day",
      Euro: "Euro",
      USD: "USD",
      USA: "USA",
      Filter: "Filter",
      Restaurant: "Restaurant",
      Restaurants: "Restaurants",
      Booking_Url: "Booking Url",
      Host_Name: "Host Name",
      Please_Enter_Event: "Please Enter Event",
      PleaseSelectEvent: "Please Select Event",
      PleaseEnterHostName: "Please Enter Host Name",
      Please_Enter_BookingUrl: "Please Enter booking Url",
      Please_Enter: "Please Enter",
      Please_Select: "Please Select",
      Trip_Name_Package_Name: "Trip Name / Package Name",
      Select_city_where_You_from_exported:
        "Selected City name where you from exported.",
      Below_Selected_state_where_You_from_exported:
        "Below are the selected state where you from exported City.",
      Selected_Hubs: "Select the HUB in",
      Below_are_the_selected_hubs: "Below are the selected hubs.",
      Travel_Agency: "Travel Agency",
      Select_Country: "Select Country",
      Discovery: "Discovery",
      Alysei: "Alysei",
      Specialization: "Specialization",
      Region: "Region",
      RegionFrom: "Region From",
      RegionTo: "Region ",
      Read_More: "Read More",
      Remove_all: "Remove all",
      Do_not_recognize_hub: "Don't recognize your Hub?",
      Click_Here: "Click Here",
      Where_you_want_to_export:
        "Select the HUB in USA where you plan to develop your business",
      Choose_Your_Country:
        "The Hub identifies a geographical area developed around a metropolitan district, the nerve center of business, network and development activites",
      Login: "Login",
      Do_not_have_account: " Don't have an account with us?",
      Verify_Otp: "Verify Otp",
      only_password: "Password",
      ValidatePass:
        "Please enter at least one special symbol and number in your password. ",
      Facebook: "Facebook ",
      Alysei_Membership_Progress: "Alysei Membership Progress",
      Alysei_Membership_is_important_to_guarantee:
        "Alysei Membership is important to guarantee professionalism, quality and visibility",
      Your_membership_progress_is_pending_review:
        "Your membership progress is pending review. You will be notified once the review of your account has begun.",
      Alysei_Certified:
        "Welcome to Alysei! Your request has been approved. In order to become a Certified Alysei Member and access Alysei, please complete your profile",
      Profile_100_percent: "Congratulations! You are now an Alysei Certified ",
      Certified_Alysei: "",
      Latest_Notifications: "Latest Notifications",
      Your_Progress: "Your Progress",
      only_address: "Address",
      NO_message: "There is no members.",
      only_Remove: "Remove",
      No_new_chat: "There is no new chat.",
      Image_with_text: "Add Photo",
      upload: "upload",
      Enter_your_text: "Enter your text here ...",
      Connections: "Connections",
      Remove_Connection: "Remove",
      No_connection_found_right: "You have no connections right now!",
      No_Invitations_found: "You have no invitations right now!",
      No_pending_request: "You have no pending invites right now!",
      Following: "Following",
      Followers: "Followers",
      only_Importer: "Importer",
      only_Distributor: "Distributor",
      Unfollow: "Unfollow",
      Follow: "Follow",
      Invitations: "Invitations",
      View_Request: "View Request",
      Network: "Network",
      Pending: "Pending",
      Block: "Block",
      UnBlock: "UnBlock",
      pieces: "No. of pieces",
      bottles: "no. of bottles",
      liters: "liters",
      kilograms: "kilograms",
      grams: "grams",
      milligrams: "milligrams",
      add_product: "Add Product",
      add_package: "Add Package",
      Whats_new: "What's New",
      only_me: "Only Me",
      only_Post: "Post",
      only_delete: "Delete",
      only_share: "Share",
      Report: "Report",
      Reply: "Reply",
      when_you_post_see_here: "When you post something it will appear here.",
      my_connections: "My Connections",
      Connect: "Connect",
      sure_for_delete: "Are you sure you want to delete this post ?",
      discard_post:
        "If you discard now, you'll lose any changes that you've made to this post.",
      discard: "Discard",
      continue_editing: "Continue editing",
      ok: "Ok",
      Report_Post: "Report Post",
      Share_Post: "Share Post",
      Remove_comment: "Remove comment",
      Comment: "Comment",
      write_feedback: "Write your feedback...",
      write_reply: "Write a reply...",
      not_post_yet: "No Post yet!",
      Winning_Product: "Award",
      Awards: "Awards",
      Trips: "Trips",
      NoAward: "There are no awards to show at this moment.",
      are_you_sure: "Are you sure ?",
      Blogs: "Blogs",
      View_blog: "View Blog",
      There_is_no_blogs_at_this_moment: "There are no blogs at this moment.",
      There_is_no_events_at_this_moment: "There are events at this moment.",
      There_is_no_trips_at_this_moment: "There are no trips at this moment.",
      More_info: "More Info..",
      Events: "Events",
      welcome_to_Alysei_world_you_will_be_notified_for_membership_status_update:
        " Welcome to the Alysei World! You will be notified for any membership status update.",
      Change_Banner: "Change Banner",
      Change_Profile_Photo: "Change Profile Photo",
      Posts: "Posts",
      User_Name: "User Name",
      User_Type: "User Type",
      see_more: "...See More",
      see_less: "See Less",
      only_about: "About",
      About_us: "About Us",
      Contact: "Contact",
      COMPLETE_YOUR_PROFILE_IN_ORDER_TO_START_POSTING:
        "Complete your profile in order to navigate on Alysei",
      Go_to_profile: "Go to profile",
      there_is_no_photo_at_this_moment: "There are no photos at this moment.",
      Add_Award: "Add Award",
      Add_Blogs: "Create Blog",
      Settings: "Settings",
      Add_Feature_Product: "Add Featured Product",
      Add_Featured: "Add Featured ",
      Nothing_added_yet: "Nothing added yet.",
      Update_Profile: "Update Profile",
      Trip_Name: "Trip Name",
      Trip_Price: "Trip Price",
      Cancel_Request: "Cancel Request",
      Respond: "Respond",
      Accept_Request: "Accept Request",
      Delete_Request: "Delete Request",
      Delete: "Delete",
      Connection_Request: "Connection Request",
      sending_request_with: "Sending a request to connect with",
      Reason_to_connect: "Reason to connect",
      Send_Request: "Send Request",
      IMAGE: "IMAGE",
      TITLE: "TITLE",
      URL: "URL",
      Required_Information: "*Required Information",
      Sign_up: "Sign up",
      already_account_with_us: "Already have an account with us?",
      Join__Alysei_Today: "Join Alysei Today",
      Become_an_Alyesi_member_by_signing_up_for_the_Free_Trial_Beta_version:
        "Become an Alyesi member by signing up for the Free Trial Beta version. Your access request will be subject to approval.",
      Learn_More: "Learn More",
      Blocking: "Blocking",
      Click_to_Unblock: "Click to Unblock",
      Company: "Company",
      VAT_No: "VAT No.",
      FDA_No: "FDA No.",
      Photo_of_Label: "Photo of Label",
      Upload_an_image_of_your_product_label:
        "Upload an image of your product label.",
      Upload: "Upload",
      Uploaded: "Uploaded",
      Fce_Sid_Certification: "FCE-SID Certification",
      Upload_an_image_of_your_FCE_SID_Certification:
        "Upload an image of your FCE-SID Certification.",
      Phytosanitary_Certificate: "Phytosanitary Certificate",
      Upload_an_image_of_your_Phytosanitary_Certificate:
        "Upload an image of your Phytosanitary Certificate.",
      Packaging_For_Usa: "Packaging For USA",
      Upload_an_image_or_PDF_of_your_Packaging_For_Usa:
        "Upload an image or PDF of your Packaging For USA.",
      Animal_Helath_Asl_Certificate: "Animal Health or ASL Certificate",
      Food_Safety_Plan: "Food Safety Plan",
      Upload_image_or_PDF_of_your_Food_Safety_Plan:
        "Upload an image or PDF of your Food Safety Plan.",
      Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate:
        "Upload an image PDF of your Animal Health or ASL Certificate",
      Please_enter_the_Company_Name: "Please enter the Company Name",
      Please_enter_the_Restaurant_Name: "Please enter the Restaurant Name",
      Please_enter_the_VAT_no: "Please enter the VAT no.",
      It_consists_of_11_numerical_digit: "It consists of 11 numerical digits.",
      Please_select_FDA_certified_field: "Please Select FDA CERTIFIED Field.",
      Please_select_product_type: "Please select the product type.",
      Please_select_at_least_one_conservation_method:
        "Please select at least one conservation method.",
      Please_select_at_least_one_Properties:
        "Please select at least one Properties.",
      Please_select_at_least_one_speciality:
        "Please select at least one speciality",
      OTP_has_been_send_successfully: "OTP has been send successfully.",
      Alysei: "Alysei",
      We_have_sent_you_a_6_digit_verification_code_OTP:
        "We have sent you a 6 digit verification code(OTP) to",
      Verify_OTP: "Verify OTP",
      Didnot_receive_the_OTP: "Didn't receive the OTP?",
      Resend_OTP: "Resend OTP",
      Privacy: "Privacy",
      Membership: "Membership",
      Subscription: "Subscription",
      Billing: "Billing",
      Your_Data: "Your Data",
      FAQ: "FAQ",
      Privacy_Policy: "Privacy Policy",
      Terms_and_Condition: "Terms and Conditions",
      Hub_select: "Hub select",
      Changing_your_password_will_log_you_off_any_other_devices:
        "Changing your password will log you off of any other devices.",
      Current_Password: "Current Password",
      New_Password: "New Password",
      validation_of_password:
        " Password must be at least 8 characters and contain at least one numeric digit and a special character.",
      Save_Changes: "Save",
      WHO_CAN_SEND_YOU_A_PRIVATE_MESSAGE: "WHO CAN SEND YOU A PRIVATE MESSAGE?",
      WHO_CAN_SEND_YOUR_PROFILE: "WHO CAN VIEW YOUR PROFILE?",
      WHO_CAN_CONNECT_WITH_YOU: "WHO CAN CONNECT WITH YOU?",
      Anyone: "Anyone",
      Just_me: "Just me",
      Email_Preferences: "Email Preferences",
      Private_messages: "Private messages",
      When_someone_request_to_follow: "When someone request to follow",
      Weekly_updates: "Weekly updates",
      General_Account_Settings: "General Account Settings",
      Resaurant: "Resaurant",
      only_Name: "Name",
      Username: "User Name",
      Language: "Language",
      English: "English (US)",
      Italian: "Italian (IT)",
      Select_option: "Select option",
      Billing_Detail_comming_soon: "Billing Detail coming soon",
      Billing_Details: "Billing Details",
      Billing: "Billing",
      Orders: "Orders",
      Orders_comming_soon: "Orders coming soon",
      Account_Data_Download: "Account Data Download",
      Nothing_requested_yet: "Nothing requested yet.",
      Request_Data: "Request Data",
      All: "All",
      People: "Users",
      Nothing_to_show: "There is nothing to show as of now",
      NoPeople: "No People found",
      Enter_your_reason: "Reason Field is require, Please enter your reason.",
      Connect_Request: "Connect Request",
      Sending_a_request_to: "Sending a request to",
      connect_with: "connect with",
      Reason_to: "Reason to",
      Send_Reason: "Send Reason",
      Select_Product_Type: "Select Product Type",
      Continue: "Continue",
      Copyright: "© Copyright 2024 Alysei Corp |  All rights reserved  |",
      Contact_us: "Contact us",
      Terms_of_Use: "Terms of Use",
      Instagram: "Instagram",
      Youtube: "Youtube",
      Linkedin: "Linkedin",
      Forget_password: "Forget password?",
      Enter_your_email_recovery:
        "Enter your registered email to recover your password",
      only_home: "Home",
      Reset_Password: "Reset Password",
      Confirm_Password: "Confirm Password",
      only_Submit: "Submit",
      only_profile: "Profile",
      Logout: "Logout",
      B2B: "B2B",
      Recipes: "Recipes",
      Recipe: "Recipe",
      only_Marketplace: "Marketplace",
      Notifications: "Notifications",
      Clear_All: "Clear All",
      no_notifications: "There is no notification.",
      Minimun_8: "Minimun 8",
      digit_1: "1 digit",
      special_1: "1 special",
      search_components: "search components",
      Loading: "Loading...",
      // recipes...
      General_Information: "General Information",
      only_select: "Select",
      meal: "meal",
      Cooking_Skills: "Cooking Skills",
      Cuisine: "Cuisine",
      Diets: "Diets",
      Food_Introlerance: "Food Intolerance",
      Prepration_Time: "Preparation Time",
      Hours: "Hours",
      Minutes: "Minutes",
      Serving: "Serving",
      For_how_much_people_you_are_cooking:
        " For how much people you are cooking.",
      Select_Region: "Select Region",
      Change_Photo: "Change Photo",
      Upload_Recipe_Image: "Upload Recipe Image",
      click_here_to_upload_recipe_image: "click here to upload recipe image",
      IMAGES: "IMAGE",
      PNG_JPG: "PNG,JPG",
      Resolution: "Resolution",
      Upto_1600_1600: "Upto 1600x1600",
      Back: "Back",
      Add_Ingredients_used_in_Recipe: "Add Ingredients used in Recipe",
      Save_in_draft: "Save in draft",
      Page_Reload_message: "Changes you made may not be saved.",
      Quantity: "Quantity",
      Unit: "Unit",
      kg: "kg",
      litre: "litre",
      only_pieces: "pieces",
      dozen: "dozen",
      gm: "gm",
      ml: "ml",
      spoon: "Teaspoon",
      drops: "drops",
      Envelope: "Envelope",
      clove: "clove",
      tablespoon: "tablespoon",
      pinch: "pinch",
      shot: "shot",
      asNeeded: "as needed",
      glass: "glass",
      cup: "cup",
      Ingredients_List: "Ingredients List",
      Ingredients: "Ingredients",
      Used: "Used",
      Add_Ingredients_used_in_Recipe: "Add Ingredients used in Recipe",
      Step: "Step",
      Steps: "Steps",
      Tools_used_in_Step: "Tools used in Step",
      please_Write_Reviews: "please Write Reviews",
      please_Write_Rating: "Please add rating",
      only_Rating: "Ratings",
      Networking: "Networking",
      Reviews: "Ratings",
      Likes: "Likes",
      Like: "Like",
      There_are_no_ingredients: "There are no ingredients.",
      There_are_no_tools: "There are no selected tools.",
      Please_select_quantity_or_ingredients:
        "Please select quantity or ingredients.",
      Please_select_quantity_or_unit: "Please select unit and quantity",
      please_write: "Please write",
      title_required: "Title is required.",
      subject_required: "Subject is required.",
      message_required: "Message is required.",
      Please_Add_Recipe_Steps: "Please Add Recipe Steps",
      Add_Recipe_Steps: "Add Recipe Steps",
      Recipe_Steps: " Recipe Steps",
      RecipeSteps: " Recipe Steps",
      please_select_at_one_cooking_tool:
        "Please Select at least one cooking Tool",
      Add_Utencils_Tools_used_in_recipe: " Add Utensils/Tools used in recipe",
      Add_Utensil:"Utensils Used",
      Utencils: "Utensils",
      Save_in_draft: "Save in draft",
      Finish: "Finish",
      Tools_Utencils_Used: "Tools & Utensils Used",
      Mins: "Mins",
      Hr: "Hr",
      Likes: "Likes",
      Quick_search_by: "Quick search by",
      Trending_Now: "Trending Now",
      Quick_Easy: "Quick and Easy",
      Update_preferences: "Update preferences",
      Add_More: "Add More",
      Published: "Published",
      Draft: "Draft",
      Recipe: "Recipe",
      What_are_you_craving: "What are you craving?",
      Personalize_your_experience: "Personalize your experience",
      what_is_your_favorite_cuisines: "What is your favourite Cuisines?",
      Do_you_have_any_food_allergies: "Do you have any food allergies?",
      Do_you_follow_these_diets: "Which diet do you follow?",
      Do_not_want_to_see_ingredients: "Don't want to see ingredients?",
      The_Alysei_Recipe_Tool_is_a_world_of_good:
        "The Alysei Recipe Tool is a world of good, healthy Italian Recipes that you can explore, create, and share in an easy and fun way. Enjoy Italy and its flavors!",
      Explore: "Explore",
      MyRecipes: "My Recipes",
      MyPreferences: "My Preferences",
      You_may_also_like: "You may also like",
      Recipeby: "Recipe by",
      Start_Cooking: "Start Cooking",
      Suggested: "Suggested",
      MyFavouriteRecipe: "My Favourite Recipe",
      Meals: "Meals",
      Please_fill_Url_or_Description_field:
        "Please fill at least  Url or Description field",
      image_field_required: "Image is required",
      Please_fill_title: "Please fill title field",
      Create_Recipe: "Create Recipe",
      Edit_Recipe: "Edit Recipe",
      Leave_a_comment: "Write a comment...",
      ShareRecipe: "Share Recipe",
      Go_To_Store: "Go To My Store",
      Create_You_Store: "Create Your Store",
      Please_Enter_email_and_pass: "Please Enter Your username and password",
      Please_select_atleast_one_ingredients:
        "Please select at least one ingredients",
      Please_select_atleast_one_tool: "Please select at least one tool",
      Skip: "Skip",
      Suggested_Connections: "Suggested Connections",
      No_Connection_Found: "No Connection Found",
      Please_Select_At_Least_one_field: "Please select at least one field.",
      Create_Your_Own_modern:
        "Create your own modern, professional online Store",
      Create_Your_Product_List: "Create Your Product listing",
      Display_Your_Unique_Products_Listing:
        "Display your unique products listing",
      Respond_To_Buyers_Inquiry: "Respond to buyers inquiry",
      Interact_With_Your_Future_Buyers: "Interact with your future buyers",
      Favorite_and_Ratings: "Favorite and Ratings",
      Being_Able_To_Be_Reviewed_And_Rated_By_Buryers:
        "Being able to be reviewed and rated by buyers",
      Comming_Soon: "Coming Soon",
      Boost_your_listing: "Boost your listing",
      Boost_your_listing_to_expand_you_and_reach:
        "Boost your listing to expand you reach and increase buyers engagement",
      Sell_online: "Sell online",
      Sell_Your_Product_online_To_Your_Buyers:
        "Sell your product online to your buyers",
      B2C_Grand_Opening: "B2C Grand Opening",
      Alysei_Full_Opening_To_Market_Buyers_And_Consumers:
        "Alysei full opening to Market, Buyers and Consumers",
      Free_MemberShip: "Free MemberShip",
      Restaurant_SearchBar_Type: "Restaurant Type",
      Event_Description: "Event description",
      Give_Your_FeedBack: "Give us your feedback",
      Send_Your_FeedBack_Here: "Send your feedback here...",
      Rating_Is_Require: "Rating is required",
      Review_Is_Require: "Review is required",
      Review_Must_Be_Greater_Than_Three_Character:
        "Review must be greater than 3 Character",
      Thank_You_For_Your_Review: "Thank you for your review",
      What_Is_Your_View: "what is your view?",
      product_keywords: "Product Name",
      MarketPlaceMainHeading:
        "Explore the most powerful Italian Food and Beverage Marketplace to search for the carefully selected top high-quality products from our endless cuisine. Italian Producers can create their unique Store, upload their product portfolio, explore, search, and reply to inquiries from other Alysei Members. Voyagers can access to explore and learn more about the history, traditions, and quality of the Italian cuisine.",
      Search_for_recipes: "Search for recipes",
      write_comments: "write your comment...",
      Winner: "Winner",
      search_your_address: "Search Your Address",
      Apply: "Apply",
      write_message: "Write a message...",
      selectCities: "Select Cities",
      selectedHubs: "Selected Hubs",
      selectHubs: "Select Hubs",
      selectState: "Select the State where you plan to develop your business.",
      noHubsFound: "No Hubs Found",
      onlyOneCity: "You can select only one City",
      onlyOneState: "You can select only one State",
      IngredientsUsed: "Ingredients used in Step ",
      helps_user_to_find_product: "Helps user to find product",
      quanity_available:
        "How many products are you currently available to deliver?",
      Minimum_Order_Quantity_tooltip:
        "Which is the minimum order quantity you are able to accept?",

      deletedSharePostHeading: "This content isn't available right now",
      deletedSharePostMessage:
        "when this happens,it's usually because the owner only shared it with a small group of people, changed who can see it or it's been deleted.",
      chooseHubOrStates: "It is necessary to choose either hubs or states",
      enterPhoneNo: "Please enter phone no",
      numericalDigit: "It consists of min 8 or max 10 numerical digits",
      enterAddress: "Please enter address",
      enterWebsiteLink: "Please enter website link",
      enterEvent: "Please Enter Competition / Event",
      enterWinningProduct: "Please Enter winning Product",
      enterMedalName: "Please Select Name of Medal",
      enterImage: "Please Select image",
      imageType: "The Image type must be JPEG, JPG, PNG",
      selectImageSize: "Image size should not be more than 2 MB",
      disputeImageSize: "Image size should not be more than 1 MB",
      selectImageSizeLessThan10: "The Image size must be less than 5 MB",
      selectImageSizeLessThan5: "Images should be less than 5",
      selectBannerImage: "Please select banner image",
      selectLogoImage: "Please select logo image",
      selectGalleryImage: "Please select gallery images",
      selectLogoBannerGalleryToChange:
        "Please select logo, Banner or Gallery to change",
      addPhotoMax10:
        " Photos 0/10 Choose your stores main photo first. Add more photos of your store max 10 allowed.",
      enterMessageField: "Message Field is Require Please Enter Your Message",
      enterTextBeforePost: "Please Add Some Text Before Post",
      enterMaxCharacter: "Enter maximum character 300",
      enterRecipeName: "Please enter recipe name",
      selectRecipeMeal: "Please select recipe meal.",
      selectRecipeCourse: "Please select recipe course.",
      selectRecipeCooking: "Please select recipe cooking skills.",
      selectRecipeCuisine: "Please select recipe cuisine.",
      selectRecipeServing: "Please select recipe prepration serving.",
      selectRecipeRegion: "Please select recipe region.",
      selectRecipeImage: "Please select recipe image.",
      selectOneState: "You can select only one state",
      provideSampleOfProduct:
        "Are you able to provide samples of your products upon request",
      productSellingPrice:
        "Indicate your product selling price to possible buyers",
      detailAboutSpecificDispatch:
        "Provide details about your specific dispatch instructions",
      invalidEmail: "Invalid email ID",
      invalidOTP: "Invalid OTP Number",
      review: "Rating",
      reviews: "Ratings",
      remobeAllHubsMessageTop:
        "This action will remove all the hubs, you have selected.",
      remobeAllHubsMessageMiddle: "Are you sure want to remove all the hubs?",
      Expertise: "Expertise",
      Title: "Title",
      SelectState: "Select State",
      Speciality: "Speciality",
      KeywordSearch: "Keyword search",
      EditPost: "Edit Post",
      select: "Select",
      search_messages: "Search Messages",
      properties: "Properties",
      add_menu: "Add Menu",
      product_title: "My store product",
      product_price: "Product Price",
      my_store: "Store Settings",
      closed_inquery: "Close Inquiry",
      how_choose_plan: "Choose how you plan to sell your products",
      Provide_details_about_your_safe_handling_instructions:
        "Provide details about your safe handling instructions",
      Enter_Title_for_Step: "Enter Title for Step",
      Your_recipe_direction_text_here: "Your recipe direction text here...",
      Search_Ingredients: "Search Ingredients",
      Search_Tools: "Search Tools",
      Producer: "Producer",
      No_Recipe_Found: "No recipe found",
      recipe_tool: "Recipe Tool",
      Store_Image_Heading: "Add Store images",
      gallery_images: "Gallery Images",
      add_a: "is required",
      coverImageSuccessMessage: "Cover image uploaded successfully",
      profileImageSuccessMessage: "Profile image uploaded successfully",
      Report_Problem: "Please select a problem with the post",
      Nudity: "Nudity",
      Violence: "Violence",
      Harassment: "Harassment",
      Suicide: "Suicide or Self Injury",
      False_Info: "False Information",
      Others: "Others",
      Write_Reason: "write a reason",
      One_Reason_Choose: "Please choose atleast one reason",
      Recommend_Size_900_900: "Recommended size 900 X 900",
      Singular_Hub: "Hub",
      coverImageWidthInvalidHead: "Please choose a different cover photo",
      coverImageWidthInvalidMessage:
        "This cover photo is too small. Please choose a cover photo with larger width.",
      pallet: "Pallets",
      visibility: "Visibility",
      select_all: "Select All",
      unselect_all: "Unselect All",
      change_password_text: "Please enter your current password to continue.",
      change_email: "Change Email",
      enter_new_email_text: "Enter your new email",
      confirm_your_email: "Confirm your email",
      confirm_email_mismatched: "Confirm email is mismatched",
      change_email_text:
        "Please enter your new email to associate with this account.",
      EMAIL_VISIBILITY: "Email Visibility",
      PHONE_NUMBER_VISIBILITY: "Phone Number Visibility",
      ADDRESS_VISIBILITY: "Address Visibility",
      Save_Proceed: "Save & Proceed",
      Please_select_brand_label: "Please select brand label",
      BLOCK_USERS: "Would you also like to block this user?",
      YOU_WANT_TO_BLOCK_USERS: "Are you sure you want to block this user?",
      BLOCK_USERS_HINT: "By blocking this user, you won't be longer connected.",
      PLEASE_SELECT: "Please select",
      TO_BLOCK_USER: "to block and submit",
      BLOCK_USERS_HINT_AND_MESSAGES:
        "By blocking this user, you won't be longer connected with him and not be able to see any activity",
      SPAM_FOLDER: "OTP can be in the spam folder",
      REALY_WANT_TO_UNBLOCK_USER: " Are you sure want to unBlock this user",
      SUPPORT_ACTION: "Support Actions",
      // new dashboard for producer...
      Recent_orders: "Recent Orders",
      Order_id: "Order ID",
      Customer_Name: "Customer Name",
      Total: "Total",
      Date: "Starting Date",
      Action: "Action",
      View: "view",
      Search_product_by_name: "search product  by name or its ID",
      Select_category: "select category",
      Select_stock: "select stock",
      Active: "active",
      Inactive: "Inactive",
      Image: "Image",
      Order_management: "Order Management",
      On_hold: "On_hold",
      New_orders: "New Orders",
      Ongoing: "Ongoing",
      Past_orders: "Past Orders ",
      Invoice: "invoice ",
      Tax_management: "Tax Management ",
      Tax_classes: "Tax Classes ",
      Tax_class: "Tax class ",
      Tax_rate: "Tax Rate ",
      Tax_class_title: "Tax Class Title",
      Edit_tax_class: "Edit tax class",
      Confirm_Delete: "Confirm Delete ",
      Tax_class_notes:
        "Please note: This tax class has been used in 5 product.Are you sure you want to delete this tax class?",
      Taxes_type: "Taxes Type",
      Tax_name: "Tax Name",
      Select_tax_type: "Select Tax Type",
      Download_invoice: "Download invoice",
      Print: "Print",
      Order_details: "Order details",
      My_order_view: "My order view",
      Order_on: "Order on",
      Billing_address: "Billing Address",
      Shipping_address: "Shipping Address",
      payment_method: "Payment Method",
      Order_summary: "Order summary",
      Grand_total: "Grand total",
      Shipping_charge: "Shipping Charge",
      Tax: "Tax",
      Unit_price: "Unit Price",
      Items_subtotal: "items subtotal ",
      Product_category: "product category",
      Sr_no: "Sr.No.",
      Customer_name: "customer name ",
      Cancellation: "cancellation",
      In_transit: "in transit",
      Completed: "Completed",
      completed: "completed",
      Processing: "Processing",
      Pending: "pending",
      On_hold: "On hold",
      Upload_invoice: "upload invoice",
      Transaction_management: "Transaction Management",
      Date_to: "Date to",
      Generate_report: "Generate report",
      Transaction_id: "Transaction ID",
      Amount: "Total Amount",
      Payment_status: "Payment Status",
      Order_status: "Order Status",
      Date_from: "Date from",
      Succeeded: "Succeeded",
      Request_for_payment: "Request for payment",
      Requires_payment_method: "Requires payment method",
      Generate_report: "Generate report",
      Date_and_time: "date and time",
      Transaction_details: "transaction details",
      Order_completed: "Order Completed",
      last_order_date: "Last Order Date",
      Customer_management: "Customer Management",
      Offer_management: "Offer Management",
      Ending_date: "Ending Date",
      Importer_name: "Importer Name",
      Offer_title: "Offer Title",
      Offfer_id: "Offfer ID",
      Send_offer: "Send offer",
      Search_for_importer: "Search for importer",
      Add_more: "add more",
      Includes_shipping_charges: "Includes shipping charges",
      Shipping_price: "Shipping price",
      Important_notes: "Important Notes",
      Incoterms: "Incoterms",
      Payment: "Payment",
      Mode_of_Payment: "Mode of Payment",
      general: "General",
      Paypal: "Paypal",
      Bank_wire_transfer: "Bank wire transfer",
      Account_Holder_Name: "Account Holder Name",
      Swift_Code: "Swift Code",
      Bank_Name: "Bank Name",
      Account_number: "Account Number",
      Recommended_retail_price: "Recommended Retail Price",
      Other: "Other",
      Stock: "Stock ",
      Sort_by_A: "Sort by A-Z ",
      Alysei_marketplace: "Alysei marketplace",
      // new dashboard for importer...
      My_orders: "My orders",
      Invoice_not_ready_to_download: "invoice not ready to download",
      Shipping_address: "Shipping Address",
      Billing_address: "Billing Address",
      Add_billing_address: "add billing address",
      Delete_address_msg: "Are you sure you want to delete this address?",
      Notes_Address_delete:
        "Please note: Deleting this address will not delete any pending orders being shipped to this address. To ensure uninterrupted fullfillment of future orders, please update any wishlists, subscribe and save setting and periodical subscriptions using this Address.",
      Add_shipping_details: "Add shipping details",
      Select_an_address: "Select an address",
      Add_billing_details: "Add billing details",
      More_details: "More details",
      add_to_cart: "Add To Cart",
      go_to_cart: "Go To Cart",
      My_wishlist: "My wishlist ",
      View_offers: "View Offers",
      Note: "Note",
      My_offers: "My Offers",
      Empty_cart: "Your cart is empty!",
      Empty_cart_text:
        " Looks like you haven’t added anything to your cart yet",
      Go_to_shop: "Go to shop",
      Select_Payment_Method: "Select Payment Method",
      Checkout: "Checkout",
      Expiry: "expiry",
      Order_total: "Order total",
      View_cart: "view cart",
      Continue_to_pay: "Continue to pay",
      Payment_success: "Payment Successful!",
      Payment_msg: "Your order has been successfully processed.",
      Page_refresh: "Please do not refresh page",
      Payment_is_in_progress: "payment is in progress.",
      Cart_msg:
        "At a time you can add either a product including offer or a product without offer.Do you want to add this product. Are you sure?",
      Clear_cart: "Clear cart",
      Continue_shopping: "Continue shopping",
      Offer_exp: "Offer has been Expired",
      Offer_userd: "You have already used this offer.",
      Different_store_product:
        "Currently you have items in your cart from another store.Would you like to clear the cart and add product of",
      Fields_req: "Please fill this field",
      Select_imp: "Select Importer",
      Saving: "Saving",
      Send: "Send",
      Select_a_date: "Select a date",
      Select_incoterms: "Select Incoterms",
      Incoterm_text: "Place of Destination",
      Select_product: "Select Product",
      Per_unit_rice: "Per Unit Price",
      Go_back: "Go back",
      N_A: "N/A",
      Offer_name: "Offer Name",
      Online: "Online",
      Online: "Online",
      Item: "Item",
      Vat: "Vat",
      Emails: "Email",
      Subtotal: "Subtotal",
      Customer: "Customer",
      Only_address: "Address",
      Shipment_info: "Shipment Info",
      Shipping_name: "Shipping Name",
      Shipment_id: "Shipment ID",
      Shipment_url: "Shipment URL",
      Taxes_rate: "Taxes Rate",
      Taxes_rate: "Taxes Rate",
      Please_note: "Please note",
      Confirm_msg: "Are you sure you want to delete this",
      onlyName: "Name",
      Reset_filter: "Reset Filter",
      Requesting: "Requesting",
      Requested: "Requested",
      Not_requested: "Not Requested",
      Pennding: "Pending",
      Category: "Category",
      Search_Product_By_Id: "Search Product by name or its ID",
      RRP: "RRP",
      Date_time: "Date & Time",
      In_stock: "In Stock",
      out_stock: "Out of Stock",
      Cancelled: "Cancelled",
      Select_hubs_for_restrict_connection:
        "WHO CAN NOT SEND YOU CONNECTION REQUEST BY HUB'S.",
      Enter_location: "Enter location",
      Tel: "Tel",
      My_Address: "My Address",
      Company_name: "Company Name",
      Total_price: "Total Price",
      Set_payment_option: "Set this as default payment option to get payment",
      Paypal_id: "Paypal Id",
      Option_require: "This field is require.",
      Shopping_cart: "Shopping Cart",
      minimum_product_qty: "Minimum order quantity of this product is",
      less_or_equal_product_qty: "Product should be less than or equal",
      num_of_qty: "no of quantity",
      Add_reflected_in_the_bill: "The details that you want to be reflected on the invoice",
      Clear: "Clear",
      Confirm_remove: "Confirm Remove",
      Confirm_clear: "Confirm Clear",
      Are_you_sure_to_do: "Are you sure you want to remove this",
      Are_you_sure_to_clear_cart: "Are you sure you want to clear cart",
      Address_delete_msg:
        "Deleting this address will not delete any pending orders being shipped to this address.",

      Set_shipping_details: "Set shipping details same as billing details.",
      New_address_added: "Add New Address",
      Payment_warning: "Please do not refresh page payment is in progress.",
      Billing_address_warning: "Please add billing details.",
      Shipping_address_warning: "Please add shipping details.",
      Order_errors: "Something went wrong with this order. Please try later.",
      Tax_class_create_successfully: "Tax class has been deleted successfully.",
      Tax_create_successfully: "Tax has been deleted successfully.",
      Offer_id: "Offer ID",
      Starting_date: "Starting Date",
      No_offer: "Offer not found",
      Offer: "Offer",
      Fixed: "Fixed",
      Percentage: "Percentage",
      Customer_details: "Customer Details",
      Customer_contact: "Customer Contact",
      Customer_email: "Customer Email",
      Customer_address: "Customer Address",
      Joining_date: "Joining Date",
      Total_number_of_order_completed: "Total Number of Orders Completed",
      Total_number_of_order_canceled: "Total Number of Orders Cancelled",
      Sort_By_Z_A: "Sort By Z-A",
      Email_id: "Email ID",
      Support_24: "On-Line Support",
      Continuous_support: "Technical support through Alysei Live Chat",
      Manage_your_customers: "Manage your Customers",
      Customize_and_manage_your_customers:
        "Customize and manage your customers.",
      Online_payment_integration:
        "Online payment integration for smooth checkouts.",
      Alysei_will_charge:
        "Alysei will retain 10% commission for each transaction.",
      Alysei_commission_info:
        "Please note that Alysei 10% commission does not include any third party fees",
      Active_user: "Active",
      Inactive_user: "Inactive",
      charge_10_percentage:
        "Alysei will retain a 10% commission on your listed price",
      //new added key....
      You_earned: "Amount You Get",
      You_earned_info:
        "This amount will include Alysei platform commission and Stripe platform fee.",
      RRP_info:
        "The recommended retail price (RRP) is the price suggested by the manufacturer that the product should be sold at.",
      Tax_Class_info:
        "A tax class is a collection of tax rates. Tax classes determine the tax rates that apply.",
      Tax_Rate_info:
        "A tax rate is the percentage at which taxes are paid to a government.",
      Order_management_info: "",
      badge_Text_For_Stripe:
        "To sell your products in Alysei and get payments, please connect your Stripe account. For more info,",
      Tax_Class_Product:
        "Select the tax class to apply. For more info go to the Tax Management section on your dashboard.",
    };

    const IT = {
      lan: "it",
      Name: " Nome",
      Producer: "Produttore",
      LastName: "Inserisci il cognome",
      MobNo: "Inserisci il numero di cellulare",
      Please_Enter_Email: "Inserisci l'indirizzo email.",
      ValidEmail: "Inserisci un indirizzo email valido",
      Password: "Inserisci la password.",
      ValidPass: "Conferma la password.",
      NewPass: "Inserisci una nuova password.",
      Address: "Inserisci l'indirizzo",
      Please_Select_Country: "Seleziona prima il Paese",
      State: "Seleziona prima lo Stato.",
      RecoveryEmail:
        "Un'email di recupero è stata inviata al tuo indirizzo email registrato",
      NewAndConfirmPassNotMatch:
        "La nuova password e la conferma password non corrispondono.",
      ValidMobNo: "Inserisci un numero valido.",
      Otp: "Inserisci l'OTP.",
      LocationEnable: "Posizione abilitata.",
      LocationNotEnable: "Posizione non abilitata.",
      SomeThingsWrong: "Qualcosa è andato storto. Si prega di riprovare.",
      NetworkError:
        "Impossibile connettersi ad Internet. Si prega di riprovare.",
      SessionExpire:
        "La tua sessione è scaduta. Per favore esegui nuovamente l'accesso.",
      LoctionNotEnable:
        "Posizione non abilitata. Si prega di attivare la posizione.",
      FillRequireInfo:
        "Si prega di compilare tutte le informazioni richieste. ",
      TermAndCondition: "Si prega di accettare Termini e Condizioni d'uso.",
      NumberAddedSuccess: "Numero aggiunto con successo.",
      EmailAddedSuccess: "Email aggiunta con successo. ",
      OtpSendSuccessfull: "OTP inviato con successo.",
      OptSendOnEmail: "OTP inviato alla tua email.",
      PasswordChangeSuccessfull: "Password cambiata con successo.",
      SourceType: "Scegli la fonte",
      TakePhoto: "Scatta una foto",
      FromLibrary: "Scegli dalla galleria",
      DeletePhoto: "Elimina foto",
      RemovePhoto: "Rimuovi foto",
      SixDigitOtp: "Inserisci l'OTP a 6 cifre.",
      FeatureNotAvailable: "Questa funzione non è disponibile.",
      ProfileUpdateSuccess: "Profilo aggiornato con successo.",
      UpLoadImage: "Carica l'immagine.",
      ChooseRoleId: "Scegli il ruolo.",
      ReasonToDecline: "Inserisci il motivo del rifiuto",
      SelectCookingSkill: "Seleziona l'abilità in cucina.",
      SelectCuisine: "Seleziona la Cucina. ",
      SelectMeal: "Seleziona il Pasto.",
      SelectCourse: "Seleziona la Portata.",
      SelectDiet: "Seleziona la Dieta.",
      SelectPrepationTime: "Seleziona il tempo di preparazione.",
      CookManyPeople: "Seleziona per quante persone stai cucinando.",
      SelectFood: "Seleziona intolleranza alimentare.",
      SelectRegion: "Seleziona la Regione.",
      EnterIngredientName: "Inserisci l'ingrediente.",
      SelectCategory: "Seleziona una categoria",
      Edit_desc: "Inserisci la descrizione.",
      Title: "Inserisci il titolo.",
      AddIngrdient: "Aggiungi gli ingredienti.",
      AddTool: "Aggiungi gli utensili.",
      SelectUnit: "Seleziona l'Unità",
      EnterQuantity: "Inserisci la Quantità",
      CharacterNumber: "Inserisci più di 3 caratteri.",
      DescriptionOrUrl: "Inserisci la descrizione o il sito web. ",
      LocationServiceFeature:
        "Non abbiamo accesso ai servizi di localizzazione sul tuo dispositivo. Vai alle impostazioni e abilita i servizi di localizzazione per utilizzare questa funzione",
      PassWordValidation:
        "La tua password deve contenere almeno 8 caratteri, 1 carattere speciale e 1 numero",
      FdaCirtificate: "Certificazione FDA",
      ConfigureStore: "Configura il tuo negozio online",
      StoreConnect:
        "Compila il modulo per ottenere una risposta più rapida. Il negozio ti contatterà a breve",
      NoProducer: "Nessun produttore trovato",
      PickUpOrDelivery: "Offri da Asporto e/o Consegna",
      MarketPlacePowerfullEng:
        "Il più potente motore Marketplace per il Made in Italy",
      FdaIconText:
        "Il proprietario, l'operatore o l'agente responsabile di una struttura nazionale o estera impegnata nella produzione/trasformazione, confezionamento o stoccaggio di alimenti per il consumo umano o animale negli Stati Uniti, deve registrarsi presso la FDA",
      AlertMsg: "Messaggio di avviso.",
      WantToLogout: "Sei sicuro di voler uscire?",
      Horeca: "Seleziona Horeca",
      PrivateLebel: "Seleziona Private Label.",
      AlyseiBrandLebel: "Seleziona Alysei Brand Label.",
      Term_Condition: "Accetta Termini e Condizioni D'Uso.",
      Delete: "Elimina",
      image_field_required: "L'immagine è richiesta",
      // marketPlace hint -
      ImporterInquiry: "Richiesta",
      Alysei_Marketplace: "Alysei Marketplace",
      Recently_Added_Product: "Prodotti più recenti",
      Newly_Added_Stores: "Negozi online più recenti",
      Quick_search_by_Italian_Regions: "Ricerca Rapida  per Regioni Italiane",
      Top_Rated_Products: "Prodotti più votati",
      Top_Favourite_Products: "I migliori prodotti",
      Product_Title: "Prodotto",
      Product_Type: "Tipo di Prodotto",
      Conservation_Method: "Metodo di Conservazione",
      Product_Properties: "Proprietà del Prodotto",
      Italian_Region: "Regione Italiana",
      Reset_Filter: "Rimuovi i Filtri",
      FDA_Certification: "Certificato FDA",
      Update_Review: "Aggiorna Recensione",
      Edit_review: "Modifica la recensione",
      Edit_your_review_and_ratings: "Modifica la tua recensione",
      Related_Products: "Prodotti correlati",
      Sample_Available: "Campioni disponibili",
      Dispatch_Instructions: "Informazioni sulla spedizione",
      Handling_Instructions: "Informazioni sulla gestione",
      Minimum_Order_Quantity: "Quantità di ordine minimo",
      Brand_Label: "Etichetta del prodotto",
      Quantity_Available: "Quantità disponibile",
      About_Product: "Informazioni sul prodotto",
      Imagetype: "Il formato dell'immagine deve essere JPEG, JPG, PNG",
      ImageSize: "L'immagine non può essere maggiore di 2MB",
      Go_back: "Torna al Marketplace",
      Go_back_Dashboard: "Torna alla dashboard",
      ImageMaxLen:
        "Scegli prima la foto principale del tuo negozio. Aggiungi poi più foto del negozio fino ad un massimo di 8.",
      Min_Order_Quantity: "Quantità minima d'ordine",
      Dispatch_Instruction: "Istruzioni per la spedizione.",
      Available_For_Sample: "Disponibile per Campioni",
      Product_Price: "Prezzo del prodotto",
      Min_Char: "dovrebbe essere un carattere minimo",
      is_required: "è richiesto",
      LessThan: "dovrebbe essere inferiore a",
      MinValue: "dovrebbe essere un valore minimo",
      LessThanOrEq: "dovrebbe essere minore o uguale a",
      LogoImage: "Seleziona il tuo logo",
      GalleryImage: "Seleziona le immagini dalla galleria",
      Crop_image: "Ritaglia l'immagine",
      Config_store: "Configura il tuo negozio online",
      NotAllowed: "Non sei autorizzato ad accedere a questa pagina",
      GoHome: "VAI ALLA HOMEPAGE",
      MyDashboard: "la mia scrivania",
      AddReviewAndRatings: "Aggiungi recensioni",
      EditReviewAndRatings: "Modifica la tua recensione",
      Select_package: "Iscrizione al Marketplace di Alysei",
      Alysei_B2B_Marketplace: "Mercato B2B di Alysei",
      Select_best_package: "Scegli il piano adatto a te",
      Thank_you:
        "Grazie per aver fornito le tue informazioni per la revisione da parte di Alysei",
      will_resp: "Ti risponderemo al più prestot",
      View_profile: "Visualizza profilo",
      Our_gallery: "La nostra galleria",
      About_store: "Il negozio",
      Store_products: "I prodotti del negozio",
      Products: "Prodotti",
      Select_fda_Certificate: "Seleziona",
      Ratings_and_reviews: "Ratings & Reviews",
      Add_review: "Aggiungi una Rating",
      Producer_Store: "Negozio del Produttore",
      View_all: "Vedi tutto",
      Search: "Cerca",
      Select: "Seleziona",
      Reviews: "Ratings",
      Edit_review: "Modifica recensione",
      close: "Chiudi",
      Categories: "Categorie",
      Location: "Posizione",
      call: "Chiama",
      Website: "Sito Web",
      Direction: "Direction",
      Favourite: "Favoriti",
      Send_Inquiry: "Invia richiesta",
      See_All: "Vedi tutto",
      Accept: "Accetta",
      Decline: "Rifiuta",
      Decline_Request: "Rifiuta la Richiesta",
      Reason_to_Decline: "Motivi del rifiuto",
      Confirm: "Conferma",
      Invitations: "Inviti",
      Store_Name: "Nome del Negozio",
      Phone_number: "Telefono",
      PhoneNumber: "Numero di Telefono",
      Email: "Email",
      Store_region: "Regione del negozio",
      Photos: "Foto",
      Dashboard: "Pannello di controllo",
      Inquiries: "Richieste",
      Total_products: "Prodotti totali",
      Total_category: "Categorie totali",
      Total_reviews: "Recensioni totali",
      Analytics: "Statistica",
      Yearly: "Annuale",
      Monthly: "Mensile",
      Weekly: "Settimanale",
      Yesterday: "Ieri",
      Today: "Oggi",
      Add_new_product: "Aggiungi un nuovo prodotto",
      No_product_found: "Nessun prodotto trovato",
      Update: "Aggiorna",
      Add_gallery: "Aggiungi galleria",
      Total_inquiries: "Richieste totali",
      Add_photos: "Aggiungi foto",
      only_title: "Titolo",
      Keywords: "Parola chiave",
      Product_category: "Categoria di prodotto",
      Select_product_category: "Seleziona la categoria di prodotto",
      yes: "Sì",
      no: "No",
      Edit_prod_desc: "Descrizione del prodotto",
      Select_brandLebel: "Seleziona l'etichetta",
      New: "Nuove",
      Open: "Aperte",
      Closed: "Chiuse",
      opened: "Aperte",
      Description: " Descrizione",
      No_new_inquery: "Nessuna nuova richiesta ",
      No_closed_inquery: "Nessuna richiesta chiusa",
      only_product: "Prodotto",
      Product_listing: "Lista di prodotti",
      Sort_by: "Ordina per",
      AtoZ: "Dalla A alla Z",
      ZtoA: "Dalla Z alla A",
      Most_Rated_Stores: "I negozi più votati",
      Star_Stores: "Star Stores",
      Most_Searched: "Più Cercati",
      No_data_found: "Nessun dato trovato",
      No_review_found: "Nessuna recensione fino ad ora",
      Fast_response:
        "Compila il modulo per ottenere una risposta più rapida. Il Produttore Italiano ti contatterà a breve.",
      Full_name: "Nome completo",
      Message: "Messaggio",
      GO_TO_MARKETPLACE: "VAI AL MARKETPLACE",
      Showing: "Mostra",
      results: "risultati",
      Next: "Avanti",
      View: "Vedi",
      Connect: "Connettiti",
      Connected: "Connesso",
      Cancel: "Cancella",
      Requested: "Richiesto",
      Italian_F_and_B_producers: "Produttore enogastronomico Italiano",
      Importer_And_Distributor: "Importatori e Distributori",
      Importer_And_Distributor_Singular: "Importatore e Distributore",
      Voice_Of_Experts: "Esperti di Settore",
      Voice_Of_Expert: "Esperto di Settore",
      B2b_Voice_Of_Experts: "Esperti di Settori",
      Travel_Agencies: "Agenzie di Viaggio",
      Italian_Restaurants_in_US: "Ristoranti Italiani negli USA",
      Italian_F_and_B_producersB2B: "Produttori enogastronomici Italiani",
      Importer_And_DistributorB2B: "Importatori e Distributori",
      Travel_AgenciesB2B: "Agenzie di Viaggio",
      Italian_Restaurants_in_USB2B: "Ristoranti Italiani negli USA",
      Voyager: "Voyager",
      Hubs: "Hubs",
      Private_Label: "Marchio del distributore",
      Label: "Label",
      Hotel_Restaurant_Café: "Hotel/Ristorante/Café",
      HORECA: "HORECA",
      Products_sold_under_another_companys_brand:
        "Prodotti venduti con il marchio di un'altra azienda",
      Alysei_Brand_Label: "Marchio Alysei",
      Provide_pick_up_and_or_Delivery: "Offri da Asporto e/o Consegna",
      Pick_up: "Asporto",
      Delivery: "Consegna",
      United_State: "Stati Uniti",
      Italy: "Italia",
      Country: "Paese",
      Unsubscribe: "Annulla Iscrizione",
      Subscribe: "Iscriviti",
      Edit: "Modifica",
      HouseAndFlatNo: "No. civico/Piano/Palazzo",
      Enter_email: "Inserisci un'email",
      NoPlace_holder: "999-999-9999",
      Award: "Premio",
      Save: "Salva",
      Add: "Aggiungi",
      Image: "Immagine",
      Name_of_Competition_Event: "Nome della Competizione/Evento",
      Name_of_winning_Product: "PREMIO",
      Name_of_Modal_place_recived: "Medaglia/Podio",
      Select_Medal_Type: "Seleziona la medaglia",
      Select_Medal: "Seleziona la medaglia",
      Bronze: "Bronzo",
      Silver: "Argento",
      Gold: "Oro",
      Please_select_BlogTitle: "Inserisci il titolo del Blog",
      Please_block_Description: "Inserisci la descrizione del Blog.",
      Please_select_blog_status: "Seleziona lo stato del Blog.",
      Please_select_BlogImage: "Seleziona l'immagine del Blog.",
      Blog: "Blog",
      Create: "Crea",
      Status: "Stato",
      Event: "Evento",
      Event_View: "Visualizzazione eventi",
      View_Event: "Visualizza evento",
      name: "Nome",
      Date: "Data",
      Host: "Organizzatore",
      Time: "Ora",
      Type: "Tipo",
      Free: "Gratuito",
      Paid: "A Pagamento",
      buy_invitation: "Su Invito",
      price: "Prezzo",
      PriceType: "Seleziona il tipo di prezzo",
      currencyType: "Seleziona Moneta",
      Duration: "Durata",
      intensity: "Intensità",
      only_country: "Paese",
      Registration: "Registrazione",
      Select_Event: "Seleziona il tipo di evento",
      Currency_type: "Tipo di valuta",
      Adventure_Type: "Tipo di avventura",
      Adventure: "Avventura",
      Select_reg_type: "Seleziona il tipo di registrazione",
      Event_Registration_Type: "Registrazione",
      Public: "Pubblico",
      public: "Pubblico",
      Private: "Privato",
      Trip: "Viaggio",
      Trip_View: "Visualizza Viaggio",
      days: "Giorni",
      day: "Giorno",
      Euro: "Euro",
      USD: "Dollaro Statunitense",
      USA: "USA",
      Filter: "Filtra",
      Restaurant: "Ristorante",
      Restaurants: "Ristoranti",
      Booking_Url: "Sito Web per Prenotare",
      Host_Name: "Nome Organizzatore",
      Please_Enter_Event: "Inserisci un evento",
      PleaseSelectEvent: "Seleziona un evento",
      PleaseEnterHostName: "Inserisci il nome dell'organizzatore",
      Please_Enter_BookingUrl: "Inserisci il sito web per la prenotazione",
      Please_Enter: "Inserisci",
      Please_Select: "Seleziona",
      Trip_Name_Package_Name: "Nome del Viaggio/Pacchetto",
      Select_city_where_You_from_exported:
        "Selected City name where you from exported.",
      Below_Selected_state_where_You_from_exported:
        "Below are the selected state where you from exported City.",
      Selected_Hubs: "Seleziona l'HUB negli",
      Below_are_the_selected_hubs:
        "Di seguito sono riportati gli hub selezionati.",
      Travel_Agency: "Agenzia di Viaggio",
      Select_Country: "Seleziona Paese",
      Discovery: "Discovery",
      Alysei: "Alysei",
      Specialization: "Specializzazione",
      Region: "Regione",
      RegionFrom: "Regione Da",
      RegionTo: "Regione ",
      Read_More: "Leggi di più",
      Remove_all: "Elimina tutto",
      Do_not_recognize_hub: "Non trovi il tuo Hub?",
      Click_Here: "Clicca qui",
      Where_you_want_to_export:
        "Seleziona l'HUB negli USA dove vuoi sviluppare il tuo business",
      Choose_Your_Country: "L'Hub identifica una zona geografica sviluppata intorno ad un'area metropolitana, centro nevralgico di attività di business, network e sviluppo.",
      Login: "Accedi",
      Do_not_have_account: "Non hai ancora un account?",
      Verify_Otp: "Verifica OTP",
      only_password: "Password",
      ValidatePass:
        "Inserisci almeno un simbolo e un numero speciale nella tua password.",
      Facebook: "Facebook ",
      Alysei_Membership_Progress: "Status della Membership Alysei",
      Alysei_Membership_is_important_to_guarantee:
        "L'iscrizione ad Alysei è importante per garantire professionalità, qualità e visibilità",
      Your_membership_progress_is_pending_review:
        "La tua iscrizione è in attesa di revisione. Sarai notificato una volta iniziato il processo di revisione.",
      Alysei_Certified:
        "Benvenuto in Alysei! La tua richiesta è stata approvata. Per diventare un Membro Certificato e accedere alla piattaforma Alysei, completa il tuo profilo.",
      Profile_100_percent: "Congratulazioni! Ora sei un ",
      Certified_Alysei: "certificato Alysei",
      Latest_Notifications: "Ultime Notifiche",
      Your_Progress: "La mia iscrizione",
      only_address: "Indirizzo",
      NO_message: "Non ci sono messaggi.",
      only_Remove: "Rimuovi",
      No_new_chat: "Non ci sono nuove chat.",
      Image_with_text: "Aggiungi foto",
      upload: "Carica",
      Enter_your_text: "Inserisci il tuo testo qui...",
      Connections: "Connessioni",
      Remove_Connection: "rimuovere",
      No_connection_found_right: "Non hai connessioni al momento!",
      No_Invitations_found: "Non hai inviti al momento!",
      No_pending_request: 'Non hai inviti in attesa al momento!"',
      No_pending_request: 'Non hai inviti in attesa al momento!"',
      Following: "Seguiti",
      Followers: "Followers",
      only_Importer: "Importatore",
      only_Distributor: "Distributore",
      Unfollow: "Non seguire",
      Follow: "Seguire",
      Invitations: "Inviti",
      View_Request: "Vedi Richiesta",
      Network: "Network",
      Pending: "In Attesa",
      Block: "Blocca",
      UnBlock: "Sblocca",
      pieces: "no. di pezzi",
      bottles: "no. di bottiglie",
      liters: "Litri",
      kilograms: "kilogrammi",
      grams: "grammi",
      milligrams: "milligrammi",
      add_product: "Aggiungi Prodotto",
      Whats_new: "Cosa c’è di nuovo?",
      only_me: "Solo io",
      only_Post: "Post",
      only_delete: "Elimina",
      only_share: "Condividi",
      Report: "Segnala",
      Reply: "Rispondi",
      when_you_post_see_here: "Quando pubblichi qualcosa, apparirà qui.",
      my_connections: "Connessioni",
      sure_for_delete: "Sei sicuro di voler eliminare questo post?",
      ok: "Ok",
      // Connect: 'Collegare',
      Report_Post: "Segnala un'azione",
      Share_Post: "Condividi post",
      Remove_comment: "Rimuovi commento",
      Comment: "commento",
      write_feedback: "Write your feedback...",
      write_comments: "Scrivi un commento...",
      write_reply: "Rispondi...",
      not_post_yet: "Nessun post al momento!",
      Winning_Product: "Premio",
      Awards: "Premi",
      Trips: "Viaggi",
      NoAward: "Non ci sono premi al momento.",
      are_you_sure: "Sei sicuro?",
      Blogs: "Blogs",
      There_is_no_blogs_at_this_moment: "Non ci sono blogs al momento.",
      There_is_no_events_at_this_moment: "Non ci sono eventi al momento.",
      There_is_no_trips_at_this_moment: "Non ci sono viaggi al momento.",
      More_info: "Più Informazioni",
      Events: "Eventi",
      welcome_to_Alysei_world_you_will_be_notified_for_membership_status_update:
        " Benvenuto nel mondo Alysei! Riceverai una notifica per qualsiasi aggiornamento sullo status della tua Membership..",
      Change_Banner: "CAMBIA FOTO COPERTINA",
      Change_Profile_Photo: "Cambia la foto del profilo",
      Posts: "Post",
      User_Name: "Nome utente",
      User_Type: "Tipologia di utente",
      see_more: "...vedi di più",
      see_less: "Vedi di meno",
      only_about: "Chi sono",
      About_us: "Chi siamo",
      Contact: "Contatti",
      COMPLETE_YOUR_PROFILE_IN_ORDER_TO_START_POSTING:
        "Completa il tuo profilo per iniziare a navigare in Alysei",
      Go_to_profile: "Vai al tuo Profilo",
      there_is_no_photo_at_this_moment: "Non ci sono foto al momento.",
      Add_Award: "Aggiungi Premio",
      Add_Blogs: "Crea Blog",
      Settings: "Impostazioni",
      Add_Feature_Product: "Aggiungi Prodotti in Primo Piano",
      Nothing_added_yet: "Nulla di aggiunto ancora.",
      Update_Profile: "Aggiorna il profilo",
      Trip_Name: "Nome del viaggio",
      Trip_Price: "Prezzo del Viaggio",
      Cancel_Request: "Cancella la richiesta",
      Respond: "Respond",
      Accept_Request: "Accetta la richiesta",
      Delete_Request: "Elimina la richiesta",
      Connection_Request: "Richiesta di connessione",
      sending_request_with: "Invio richiesta di connessione con",
      Reason_to_connect: "Motivi della connessione",
      Send_Request: "Invia richiesta",
      IMAGE: "Immagine",
      TITLE: "Titolo",
      URL: "Sito web o URL",
      Required_Information: "*Informazioni Richieste",
      Sign_up: "Crea un nuovo account",
      already_account_with_us: "Hai già un account con noi?",
      Join__Alysei_Today: "Unisciti ad Alysei",
      Become_an_Alyesi_member_by_signing_up_for_the_Free_Trial_Beta_version:
        "Diventa un membro Alyesi iscrivendoti alla versione Beta in prova gratuita. La tua richiesta di accesso sarà soggetta ad approvazione.",
      Learn_More: "Per saperne di più",
      Blocking: "Blocca",
      Click_to_Unblock: "Clicca per sbloccare",
      Company: "Azienda",
      VAT_No: "Part.IVA",
      FDA_No: "Numero FDA",
      Photo_of_Label: "Foto dell'etichetta",
      Upload_an_image_of_your_product_label:
        "Carica una foto dell'etichetta del tuo prodotto.",
      Upload: "Carica",
      Uploaded: "Caricato",
      Fce_Sid_Certification: "Certificazione FCE-SID",
      Upload_an_image_of_your_FCE_SID_Certification:
        "Carica una foto del tuo certificato FCE-SID.",
      Phytosanitary_Certificate: "Certificato fitosanitario",
      Upload_an_image_of_your_Phytosanitary_Certificate:
        "Carica una foto del tuo certificato fitosanitario.",
      Packaging_For_Usa: "Packaging per gli USA",
      Upload_an_image_or_PDF_of_your_Packaging_For_Usa:
        "Carica una foto della tua confezione per gli USA.",
      Animal_Helath_Asl_Certificate:
        "Certificato di Salute Animale o Certificato ASL",
      Food_Safety_Plan: "Piano di Sicurezza Alimentare",
      Upload_image_or_PDF_of_your_Food_Safety_Plan:
        "Carica una foto del tuo piano di sicurezza alimentare.",
      Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate:
        "Carica una foto del tuo certificato di salute animale o certificato ASL",
      Please_enter_the_Company_Name: "Inserisci il nome aziendale",
      Please_enter_the_Restaurant_Name: "Inserisci il nome del ristorante",
      Please_enter_the_VAT_no: "Please enter the VAT no.",
      It_consists_of_11_numerical_digit: "It consists of 11 numerical digits.",
      Please_select_FDA_certified_field: "Seleziona la tua certificazione FDA.",
      Please_select_product_type: "Seleziona il tipo di prodotto.",
      Please_select_at_least_one_conservation_method:
        "Seleziona almeno un metodo di conservazione.",
      Please_select_at_least_one_Properties: "Seleziona almeno una proprietà.",
      Please_select_at_least_one_speciality:
        "Please select at least one speciality",
      OTP_has_been_send_successfully: "L'OTP è stato inviato con successo.",
      Alysei: "Alysei",
      We_have_sent_you_a_6_digit_verification_code_OTP:
        "Ti abbiamo inviato un codice di verifica a 6 cifre (OTP) a",
      Verify_OTP: "Verifica OTP",
      Didnot_receive_the_OTP: "Non hai ricevuto l'OTP?",
      Resend_OTP: "Invia di nuovo l'OTP",
      Privacy: "Privacy",
      Membership: "Iscrizione",
      Subscription: "Sottoscrizione",
      Billing: "Fatturazioni",
      Your_Data: "I tuoi dati",
      FAQ: "Domande frequenti",
      Privacy_Policy: "Informativa sulla Privacy",
      Terms_and_Condition: "Termini e Condizioni d'Uso",
      Hub_select: "Seleziona l'Hub",
      Changing_your_password_will_log_you_off_any_other_devices:
        "La modifica della password ti disconnetterà da qualsiasi altro dispositivo.",
      Current_Password: "Password attuale",
      New_Password: "Nuova Password",
      validation_of_password:
        "La password deve contenere almeno 8 caratteri, una cifra numerica e un carattere speciale",
      Save_Changes: "Salva",
      WHO_CAN_SEND_YOU_A_PRIVATE_MESSAGE:
        "CHI PUÒ INVIARTI UN MESSAGGIO PRIVATO?",
      WHO_CAN_SEND_YOUR_PROFILE: "Chi può vedere il tuo profilo?",
      WHO_CAN_CONNECT_WITH_YOU: "CHI PUÒ CONNETTERSI CON TE?",
      Anyone: "Tutti",
      Just_me: "Solo io",
      Email_Preferences: "Preferenze Email",
      Private_messages: "Messaggi privati",
      When_someone_request_to_follow: "Quando qualcuno chiede di seguirti",
      Weekly_updates: "Aggiornamenti settimanali",
      General_Account_Settings: "Impostazioni generali dell'account",
      Resaurant: "Ristorante",
      only_Name: "Nome",
      Username: "Nome Utente",
      Language: "Lingua",
      English: "Inglese (US)",
      Italian: "Italiano (IT)",
      Select_option: "Seleziona l'opzione",
      Billing_Detail_comming_soon: "Dettagli di fatturazione in arrivo a breve",
      Billing_Details: "Dettagli di fatturazione",
      Billing: "Fatturazione",
      Orders: "Ordini",
      Orders_comming_soon: "Ordini in arrivo a breve",
      Account_Data_Download: "Scarica i dati del tuo account",
      Nothing_requested_yet: "Nessuna richiesta al momento .",
      Request_Data: "Richiedi i dati",
      All: "Tutti",
      People: "Users",
      Nothing_to_show: "Nulla da mostrare al momento",
      NoPeople: "Nessuna persona trovata",
      Enter_your_reason:
        "Il campo del motivo è obbligatorio, inserisci il motivo.",
      Connect_Request: "Richiesta di connessione",
      Sending_a_request_to: "Invio di una richiesta a",
      connect_with: "Connettiti con",
      Reason_to: "Motivo per",
      Send_Reason: "Invia il motivo",
      Select_Product_Type: "Seleziona il tipo di prodotto",
      Continue: "Continua",
      Copyright: "© Copyright 2024 Alysei Corp | Tutti i diritti riservati |",
      Contact_us: "Contattaci",
      Terms_of_Use: "Condizioni d'uso",
      Instagram: "Instagram",
      Youtube: "Youtube",
      Linkedin: "Linkedin",
      Forget_password: "Hai dimenticato la password?",
      Enter_your_email_recovery:
        "Inserisci la tua email per recuperare la password",
      only_home: "Home",
      Reset_Password: "Reimposta la password",
      Confirm_Password: "Conferma Password",
      only_Submit: "Sottoscrivi",
      only_profile: "Profilo",
      Logout: "Esci",
      B2B: "B2B",
      Recipes: "Ricette",
      Recipe: "Ricetta",
      only_Marketplace: "Marketplace",
      Notifications: "Notifiche",
      Clear_All: "Elimina Tutto",
      no_notifications: "Non ci sono notifiche.",
      Minimun_8: "Minimo 8",
      digit_1: "1 numero",
      special_1: "1 carattere speciale",
      search_components: "componenti di ricerca",
      Loading: "Caricamento...",
      General_Information: "Informazioni Generali",
      only_select: "Seleziona",
      meal: "Pasto",
      Cooking_Skills: "Abilità in Cucina",
      Cuisine: "Cucina",
      Diets: "Dieta",
      Food_Introlerance: "Intolleranza alimentare",
      Prepration_Time: "Tempo di preparazione",
      Hours: "Ore",
      Minutes: "Minuti",
      Serving: "Dosi",
      For_how_much_people_you_are_cooking: "(Dosi) Per quante persone.",
      Select_Region: "Seleziona la Regione",
      Change_Photo: "Cambia Foto",
      Upload_Recipe_Image: "Carica la foto della ricetta",
      click_here_to_upload_recipe_image:
        "Clicca qui per caricare l'immagine della ricetta",
      IMAGES: "Immagine",
      PNG_JPG: "PNG,JPG",
      Resolution: "Risoluzione",
      Upto_1600_1600: "Max 1600x1600",
      Back: "Indietro",
      Quantity: "Quantità",
      Unit: "Unità",
      kg: "kg",
      litre: "Litro",
      only_pieces: "Pezzi",
      dozen: "Dozzina",
      gm: "gr",
      ml: "ml",
      spoon: "Cucchiaino",
      drops: "Gocce",
      Envelope: "Bustina",
      Ingredients_List: "Lista degli ingredienti",
      Ingredients: "Ingredienti",
      Used: "Utilizzati",
      Add_Ingredients_used_in_Recipe:
        "Aggiungi gli ingredienti utilizzati nella ricetta",
      Step: "Step",
      Steps: "Steps",
      Tools_used_in_Step: "Utensili utilizzati in questo step",
      please_Write_Reviews: "Aggiungi Recensione",
      please_Write_Rating: "Aggiungi Recensione",
      only_Rating: "Valutazione",
      Networking: "Networking",
      There_are_no_ingredients: "Non ci sono ingredienti.",
      There_are_no_tools: "There are no selected tools.",
      Please_select_quantity_or_ingredients:
        "Seleziona la quantità o gli ingredienti.",
      Please_select_quantity_or_unit:
        "Seleziona l'unità di misura e la quantità",
      please_write: "Scrivi",
      title_required: "Il titolo è obbligatorio.",
      subject_required: "L'argomento è obbligatorio.",
      message_required: "Il messaggio è obbligatorio.",
      Please_Add_Recipe_Steps: "Aggiungi una Fase",
      Add_Recipe_Steps: "Aggiungi Steps",
      Recipe_Steps: "Passaggi della Ricetta",
      RecipeSteps: "Steps",
      please_select_at_one_cooking_tool:
        "Seleziona almeno un utensile da cucina",
      Add_Utencils_Tools_used_in_recipe:
        "Aggiungi Utensili ed Elettrodomestici",
        Add_Utensil:"Utensils Used",
      Utensils: "Utensili",
      Save_in_draft: "Salvata in Bozze",
      Page_Reload_message: "Changes you made may not be saved.",
      Finish: "Fine",
      Tools_Utencils_Used: "Strumenti e utensili usati",
      Mins: "Minuti",
      Hr: "Ore",
      Likes: "Mi Piace",
      Quick_search_by: "Ricerca Rapida per",
      Trending_Now: "In Voga al momento",
      Quick_Easy: "Facile e Veloce",
      Update_preferences: "Aggiorna le preferenze",
      Add_More: "Aggiungi altro",
      Published: "Pubblicata",
      Draft: "Bozza",
      What_are_you_craving: "Cosa desideri?",
      Personalize_your_experience: "Personalizza la tua esperienza",
      what_is_your_favorite_cuisines: "Qual è la tua cucina preferita?",
      Do_you_have_any_food_allergies: "Hai qualche allergia alimentare?",
      Do_you_follow_these_diets: "Segui una di queste \n diete?",
      Do_not_want_to_see_ingredients: "Quali ingredienti non vuoi vedere?",
      The_Alysei_Recipe_Tool_is_a_world_of_good:
        "L' Alysei Recipe Tool è un mondo di ricette italiane buone e salutari che puoi esplorare, creare e condividere in modo facile e divertente. Goditi l'Italia e i suoi sapori!",
      Explore: "Esplora",
      MyRecipes: "Le Mie Ricette",
      MyPreferences: "Le Mie Preferenze",
      You_may_also_like: "Potrebbe piacerti anche",
      Recipeby: "Ricetta di",
      Start_Cooking: "Inizia la preparazione",
      Suggested: "Suggerito",
      MyFavouriteRecipe: "I Miei Favoriti Ricette",
      Meals: "Pietanza",
      Please_fill_Url_or_Description_field:
        "Si prega di compilare il campo URL o Descrizione.",
      Please_fill_title: "Si prega di compilare il campo del titolo",
      Italian: "Italian",
      Create_Recipe: "Crea la tua Ricetta",
      Edit_Recipe: "Modifica la Ricetta",
      Leave_a_comment: "Lascia un Commento",
      ShareRecipe: "Condividi la Ricetta",
      Go_To_Store: "Vai al mio negozio online",
      Create_You_Store: "Crea il tuo negozio online",
      Please_Enter_email_and_pass: "Inserisci il tuo nome utente e la password",
      Please_select_atleast_one_ingredients: "Seleziona almeno un ingrediente",
      Please_select_atleast_one_tool: "Please select at least one tool",
      Skip: "Salta",
      Suggested_Connections: "Collegamenti suggeriti",
      No_Connection_Found: "Nessuna connessione trovata",
      Please_Select_At_Least_one_field: "Seleziona almeno un campo.",
      Create_Your_Own_modern:
        "Crea il tuo negozio online moderno e professionale",
      Create_Your_Product_List: "Crea il tuo portfolio prodotti",
      Display_Your_Unique_Products_Listing: "Mostra i tuoi prodotti in vetrina",
      Respond_To_Buyers_Inquiry: "Rispondi alle richieste degli acquirenti",
      Interact_With_Your_Future_Buyers:
        "Interagisci con i tuoi possibili acquirenti",
      Favorite_and_Ratings: "Valutazioni",
      Being_Able_To_Be_Reviewed_And_Rated_By_Buryers:
        "Poter essere recensito e valutato dagli acquirenti",
      Comming_Soon: "Prossimamente…",
      Boost_your_listing: "Potenzia le tue iserzioni",
      Boost_your_listing_to_expand_you_and_reach:
        "Potenzia la tua offerta per raggiungere i tuoi acquirenti",
      Sell_online: "Vendi online",
      Sell_Your_Product_online_To_Your_Buyers:
        "Vendi i tuoi prodotti online ai tuoi acquirenti",
      B2C_Grand_Opening: "Apertura del Marketplace ai Consumatori",
      Alysei_Full_Opening_To_Market_Buyers_And_Consumers:
        "Apertura completa al Mercato, agli Acquirenti ed ai Consumatori",
      Free_MemberShip: "Iscrizione gratuita",
      RestaurantType: "Seleziona il tipo di ristorante",
      Restaurant_SearchBar_Type: "Tipologia di ristorante",
      Event_Description: "descrizione dell'eventon",
      View_blog: "Visualizza blog",
      Give_Your_FeedBack: "Dacci il tuo feedback",
      Send_Your_FeedBack_Here: "Invia il tuo feedback qui...",
      Rating_Is_Require: "La valutazione è richiesta",
      Review_Is_Require: "La revisione è obbligatoria",
      Review_Must_Be_Greater_Than_Three_Character:
        "La recensione deve essere maggiore di 3 caratteri",
      Thank_You_For_Your_Review: "Grazie per la tua opinione",
      What_Is_Your_View: "qual è il tuo punto di vista?",
      product_keywords: "Nome del Prodotto",
      MarketPlaceMainHeading:
        "Esplora il più potente mercato online italiano di enogastronomia alla ricerca dei migliori prodotti di alta qualità accuratamente selezionati.I produttori italiani possono creare la propria vetrina online, caricare il proprio portfolio di prodotti, esplorare, ricercare, creare connessioni e rispondere alle richieste di altri membri Alysei. I Voyagers Alysei possono accedere per ricercare prodotti e produttori alla scoperta della storia, cultura e tradizioni della nostra immensa cucina italiana.",
      Search_for_recipes: "Cerca ricette",
      Winner: "Vincitore",
      search_your_address: "Cerca il tuo indirizzo",
      Apply: "Applica",
      write_message: "Scrivi un messaggio...",
      selectCities: "Seleziona le città",
      selectHubs: "Seleziona gli Hubs",
      selectedHubs: "Hubs selezionati",
      selectState: "Seleziona gli stati degli Stati Uniti in cui ti trovi",
      noHubsFound: "Nessun hub trovato",
      onlyOneCity: "Puoi selezionare una sola città",
      onlyOneState: "È possibile selezionare solo uno Stato",
      IngredientsUsed: "Ingredienti usati in questo step ",
      helps_user_to_find_product: "Aiuta l'utente a trovare il tuo prodotto",
      quanity_available:
        "Quanti prodotti sei attualmente disponibile a consegnare?",
      Minimum_Order_Quantity_tooltip:
        "Qual è la quantità minima d'ordine che puoi accettare?",

      deletedSharePostHeading: "Questo contenuto non è disponibile al momento",
      deletedSharePostMessage:
        "quando ciò accade, di solito è perché il proprietario lo ha condiviso solo con un piccolo gruppo di persone, ha cambiato chi può vederlo o è stato eliminato.",
      chooseHubOrStates: "È necessario scegliere hub o stati",
      enterPhoneNo: "Si prega di inserire il numero di telefono",
      numericalDigit:
        "È composto da un minimo di 8 o un massimo di 10 cifre numeriche",
      enterAddress: "Si prega di inserire l'indirizzo",
      enterWebsiteLink: "Si prega di inserire il collegamento al sito web",
      enterEvent: "Si prega di inserire la competizione/evento",
      enterWinningProduct: "Inserisci il prodotto vincente",
      enterMedalName: "Seleziona il nome della medaglia",
      enterImage: "Si prega di selezionare l'immagine",
      imageType: "Il tipo di immagine deve essere JPEG, JPG, PNG",
      selectImageSize:
        "La dimensione dell'immagine non deve essere superiore a 2 MB",
      disputeImageSize: "Image size should not be more than 1 MB",
      selectImageSizeLessThan10:
        "La dimensione dell'immagine deve essere inferiore a 5 MB",
      selectImageSizeLessThan5: "Le immagini devono essere inferiori a 5",
      selectBannerImage: "Seleziona l'immagine del banner",
      selectLogoImage: "Si prega di selezionare l'immagine del logo",
      selectGalleryImage: "Si prega di selezionare le immagini della galleria",
      selectLogoBannerGalleryToChange:
        "Seleziona il logo, il banner o la galleria da modificare",
      addPhotoMax10:
        "Foto 0/10 Scegli prima la foto principale del tuo negozio. Aggiungi più foto del tuo negozio max 10 consentite.",
      enterMessageField:
        "Il campo del messaggio è obbligatorio. Inserisci il tuo messaggio",
      enterTextBeforePost: "Si prega di aggiungere del testo prima del post",
      enterMaxCharacter: "Immettere massimo 300 caratteri",
      enterRecipeName: "Si prega di inserire il nome della ricetta",
      selectRecipeMeal: "Si prega di selezionare la ricetta del pasto.",
      selectRecipeCourse: "Si prega di selezionare il corso ricetta.",
      selectRecipeCooking:
        "Si prega di selezionare le abilità di cucina della ricetta.",
      selectRecipeCuisine: "Si prega di selezionare la cucina della ricetta.",
      selectRecipeServing:
        "Si prega di selezionare la preparazione della ricetta che serve.",
      selectRecipeRegion: "Si prega di selezionare la regione della ricetta.",
      selectRecipeImage: "Si prega di selezionare l'immagine della ricetta.",
      selectOneState: "È possibile selezionare un solo stato",
      provideSampleOfProduct:
        "Siete in grado di fornire campioni dei vostri prodotti su richiesta",
      productSellingPrice:
        "Indica il prezzo di vendita del tuo prodotto ai possibili acquirenti",
      detailAboutSpecificDispatch:
        "Fornisci dettagli sulle tue istruzioni di spedizione specifiche",
      invalidEmail: "ID e-mail non valido",
      invalidOTP: "Numero OTP non valido",
      discard: "Elimina",
      Add_Featured: "Aggiungi In Primo Piano",
      clove: "spicchio",
      tablespoon: "cucchiaio",
      pinch: "pizzico",
      shot: "Bicchierino",
      asNeeded: "q.b.",
      glass: "Bicchiere",
      cup: "Tazza",
      review: "Rating",
      reviews: "Ratings",
      Likes: "Mi Piace",
      Like: "Mi Piace",
      remobeAllHubsMessageTop:
        "Questa azione eliminerà tutti gli Hubs che hai selezionato",
      remobeAllHubsMessageMiddle:
        "Sei sicuro di voler eliminare tutti gli Hubs?",
      Expertise: "Competenza",
      Title: "Titolo",
      SelectState: "Seleziona lo Stato",
      Speciality: "Specialità",
      KeywordSearch: "Ricerca Keyword",
      EditPost: "Modifica Post",
      select: "Seleziona",
      search_messages: "Cerca Messaggi",
      properties: "Proprietà",
      add_package: "Aggiungi Pacchetto",
      Add_Menu: "Aggiungi Menù",
      product_title: "Titolo del prodotto*",
      product_price: "Prezzo del prodotto",
      my_store: "Il Mio Negozio",
      closed_inquery: "Chiudi richiesta",
      how_choose_plan: "Scegli come intendi vendere i tuoi prodotti",
      Provide_details_about_your_safe_handling_instructions:
        "Fornisci istruzioni per la gestione",
      Enter_Title_for_Step: "Inserisci il Titolo del step",
      Your_recipe_direction_text_here:
        "Scrivi qui le indicazioni della Ricetta...",
      Search_Ingredients: "Ricerca gli Ingredienti",
      Search_Tools: "Strumenti di ricerca",
      No_Recipe_Found: "Nessuna Ricetta trovata",
      recipe_tool: "Mondo Ricette",
      Store_Image_Heading: "Aggiungi foto Negozio",
      gallery_images: "foto",
      coverImageSuccessMessage: "Immagine di copertina caricata correttamente",
      profileImageSuccessMessage: "Immagine del profilo caricata correttamente",
      add_a: "Aggiungi una",
      Report_Problem: "Seleziona il problema con il post",
      Nudity: "Nudo",
      Violence: "Violenza",
      Harassment: "Molestie",
      Suicide: "Suicidio o Autolesionismo",
      False_Info: "False informazioni",
      Others: "Altro",
      Write_Reason: "Scrivi un motivo",
      One_Reason_Choose: "Scegli almeno un motivo",
      Utencils: "Utensili",
      Recommend_Size_900_900: "Taglia consigliata 900 X 900",
      Singular_Hub: "Hub",
      coverImageWidthInvalidHead: "Please choose a different cover photo",
      coverImageWidthInvalidMessage:
        "This cover photo is too small. Please choose a cover photo with larger width.",
      pallet: "Pallet",
      visibility: "Visibilità",
      select_all: "Select All",
      unselect_all: "Unselect All",
      change_password_text: "Inserisci la tua password attuale per continuare.",
      change_email: "Cambia Email",
      enter_new_email_text: "Inserisci la tua nuova email",
      confirm_your_email: "conferma la tua email",
      confirm_email_mismatched: "Conferma che l'email non corrisponde",
      change_email_text:
        "Inserisci la tua nuova email da associare a questo account.",
      EMAIL_VISIBILITY: "Email Visibile",
      PHONE_NUMBER_VISIBILITY: "Numero Visibile",
      ADDRESS_VISIBILITY: "Indirizzo Visibile",
      Save_Proceed: "Salva e Procedi",
      Please_select_brand_label: "Seleziona l'etichetta del marchio",
      BLOCK_USERS: "Vuoi bloccare questo utente?",
      YOU_WANT_TO_BLOCK_USERS: "Sei sicuro di voler bloccare questo utente?",
      BLOCK_USERS_HINT: " Bloccando questo utente, non sarete più connessi.",
      PLEASE_SELECT: "Selezionare",
      TO_BLOCK_USER: "per bloccare e inviare",
      BLOCK_USERS_HINT_AND_MESSAGES:
        "Bloccando questo utente, non sarai più connesso con lui e non sarai in grado di vedere alcuna attività",
      SPAM_FOLDER: "L' OTP può essere nella cartella spam",
      REALY_WANT_TO_UNBLOCK_USER: "Sei sicuro di voler sbloccare questo utente",
      SUPPORT_ACTION: "Azioni di supporto",

      // new dashboard for producer...
      Recent_orders: "Recent Orders",
      Order_id: "Order ID",
      Customer_Name: "Customer Name",
      Total: "Total",
      Date: "Date",
      Action: "Action",
      View: "View",
      Search_product_by_name: "Search product  by name or its ID",
      Select_category: "Select category",
      Select_stock: "Select stock",
      Active: "Active",
      Inactive: "Inactive",
      Image: "Image",
      Order_management: "Order management",
      On_hold: "On_hold",
      New_orders: "New orders",
      Ongoing: "Ongoing",
      Past_orders: "Past Orders ",
      Invoice: "Invoice ",
      Tax_management: "Tax management ",
      Tax_classes: "Tax Classes ",
      Tax_class: "Tax class ",
      Tax_rate: "Tax Rate ",
      Tax_class_title: "Tax Class Title",
      Edit_tax_class: "Edit tax class",
      Confirm_Delete: "Confirm Delete ",
      Tax_class_notes:
        "Please note: This tax class has been used in 5 product.Are you sure you want to delete this tax class?",
      Taxes_type: "Taxes Type",
      Tax_name: "Tax name",
      Select_tax_type: "Select Tax Type",
      Download_invoice: "Download invoice",
      Print: "Print",
      Order_details: "Order details",
      My_order_view: "My order view",
      Order_on: "Order on",
      Billing_address: "Billing Address",
      Shipping_address: "Shipping Address",
      payment_method: "Payment Method",
      Order_summary: "Order summary",
      Grand_total: "Grand total",
      Shipping_charge: "Shipping Charge",
      Tax: "Tax",
      Unit_price: "Unit Price",
      Items_subtotal: "Items subtotal ",
      Product_category: "Product category",
      Sr_no: "Sr.No.",
      Customer_name: "Customer name ",
      Cancellation: "Cancellation",
      In_transit: "In transit",
      Completed: "Completed",
      Processing: "Processing",
      Pending: "Pending",
      On_hold: "On hold",
      Upload_invoice: "Upload invoice  ",
      Transaction_management: "Transaction Management",
      Date_to: "Date to",
      Generate_report: "Generate report",
      Transaction_id: "Transaction ID",
      Amount: "Total Amount",
      Payment_status: "Payment Status",
      Order_status: "Order status",
      Date_from: "Date from",
      Succeeded: "Succeeded",
      Request_for_payment: "Request for payment",
      Requires_payment_method: "Requires payment method",
      Generate_report: "Generate report",
      Date_and_time: "Date and time",
      Transaction_details: "Transaction details",
      Order_completed: "Order completed",
      last_order_date: "Last Order Date",
      Customer_management: "Customer Management",
      Offer_management: "Offer Management",
      Ending_date: "Ending Date",
      Importer_name: "Importer Name",
      Offer_title: "Offer Title",
      Offfer_id: "Offer ID",
      Send_offer: "Send offer",
      Search_for_importer: "Search for importer",
      Add_more: "Add more",
      Includes_shipping_charges: "Includes shipping charges",
      Shipping_price: "Shipping price",
      Important_notes: "Important Notes",
      Incoterms: "Incoterms",
      Payment: "Payment",
      Mode_of_Payment: "Mode of Payment",
      general: "General",
      Paypal: "Paypal",
      Bank_wire_transfer: "Bank wire transfer",
      Account_Holder_Name: "Account Holder Name",
      Swift_Code: "Swift Code",
      Bank_Name: "Bank Name",
      Account_number: "Account number",
      Recommended_retail_price: "Recommended Retail Price",
      Other: "Other",
      Stock: "Stock ",
      Sort_by_A: "Sort by A-Z ",
      Alysei_marketplace: "Alysei marketplace",
      // new dashboard for importer...
      My_orders: "My Orders",
      Invoice_not_ready_to_download: "Invoice not ready to download",
      Shipping_address: "Shipping Address",
      Billing_address: "Billing Address",
      Add_billing_address: "Add billing address",
      Delete_address_msg: "Are you sure you want to delete this address?",
      Notes_Address_delete:
        "Please note: Deleting this address will not delete any pending orders being shipped to this address. To ensure uninterrupted fullfillment of future orders, please update any wishlists, subscribe and save setting and periodical subscriptions using this Address.",
      Add_shipping_details: "Add shipping details",
      Select_an_address: "Select an address",
      Add_billing_details: "Add billing details",
      More_details: "More details",
      add_to_cart: "Add to cart",
      go_to_cart: "Go To Cart",
      My_wishlist: "My wishlist ",
      View_offers: "View Offers",
      Note: "Note",
      My_offers: "My Offers",
      Empty_cart: "Your cart is empty!",
      Empty_cart_text:
        " Looks like you haven’t added anything to your cart yet",
      Go_to_shop: "Go to shop",
      Select_Payment_Method: "Select Payment Method",
      Checkout: "Checkout",
      Expiry: "expiry",
      Order_total: "Order total",
      View_cart: "View Cart",
      Continue_to_pay: "Continue to pay",
      Payment_success: "Payment Successful!",
      Payment_msg: "Your order has been successfully processed.",
      Page_refresh: "Please do not refresh page",
      Payment_is_in_progress: "payment is in progress.",
      Cart_msg:
        "At a time you can add either a product including offer or a product without offer.Do you want to add this product. Are you sure?",
      Clear_cart: "Clear cart",
      Continue_shopping: "Continue shopping",
      Offer_exp: "Offer has been Expired.",
      Offer_userd: "You have already used this offer.",
      Different_store_product:
        "Currently you have items in your cart from another store.Would you like to clear the cart and add product of",
      Fields_req: "Please fill this field",
      Select_imp: "Select Importer",
      Saving: "Saving",
      Send: "Send",
      Select_a_date: "Select a date",
      Select_incoterms: "Select Incoterms",
      Incoterm_text: "Place of Destination",
      Select_product: "Select Product",
      Per_unit_rice: "Per Unit Price",
      Go_back: "Go back",
      N_A: "N/A",
      Offer_name: "Offer Name",
      Online: "Online",
      Online: "Online",
      Item: "Item",
      Vat: "Vat",
      Emails: "Email",
      Subtotal: "Subtotal",
      Customer: "Customer",
      Only_address: "Address",
      Shipment_info: "Shipment Info",
      Shipping_name: "Shipping Name",
      Shipment_id: "Shipment ID",
      Shipment_url: "Shipment URL",
      My_Address: "My Address",
      completed: "completed",
      Taxes_rate: "Taxes Rate",
      Taxes_rate: "Taxes Rate",
      Please_note: "Please note",
      Confirm_msg: "Are you sure you want to delete this",
      onlyName: "Name",
      Reset_filter: "Reset Filter",
      Requesting: "Requesting",
      Requested: "Requested",
      Not_requested: "Not Requested",
      Pennding: "Pending",
      Category: "Category",
      Search_Product_By_Id: "Search Product by name or its ID",
      RRP: "RRP",
      Date_time: "Date & Time",
      In_stock: "In Stock",
      out_stock: "Out of Stock",
      Cancelled: "Cancelled",
      Select_hubs_for_restrict_connection:
        "WHO CAN NOT SEND YOU CONNECTION REQUEST BY HUB'S.",
      Enter_location: "Enter location",
      Tel: "Tel",
      My_Address: "My Address",
      Company_name: "Company Name",
      Total_price: "Total Price",
      Set_payment_option: "Set this as default payment option to get payment",
      Paypal_id: "Paypal Id",
      Option_require: "This field is required.",
      Shopping_cart: "Shopping Cart",
      minimum_product_qty: "Minimum order quantity of this product is",
      less_or_equal_product_qty: "Product should be less than or equal",
      num_of_qty: "no of quantity",
      Add_reflected_in_the_bill: "Add you want to be reflected in the bill.",
      Clear: "Clear",
      Confirm_remove: "Confirm Remove",
      Confirm_clear: "Confirm Clear",
      Are_you_sure_to_do: "Are you sure you want to remove this",
      Are_you_sure_to_clear_cart: "Are you sure you want to clear cart",
      Address_delete_msg:
        "Deleting this address will not delete any pending orders being shipped to this address",
      Set_shipping_details: "Set shipping details same as billing details.",
      New_address_added: "Add New Address",
      Payment_warning: "Please do not refresh page payment is in progress.",
      Billing_address_warning: "Please add billing details.",
      Shipping_address_warning: "Please add shipping details.",
      Order_errors: "Something went wrong with this order. Please try later.",
      Tax_class_create_successfully: "Tax class has been deleted successfully.",
      Tax_create_successfully: "Tax has been deleted successfully.",
      Offer_id: "Offer ID",
      Starting_date: "Starting Date",
      No_offer: "Offer not found",
      Offer: "Offer",
      Fixed: "Fixed",
      Percentage: "Percentage",
      Customer_details: "Customer Details",
      Customer_contact: "Customer Contact",
      Customer_email: "Customer Email",
      Customer_address: "Customer Address",
      Joining_date: "Joining Date",
      Total_number_of_order_completed: "Total Number of Orders Completed",
      Total_number_of_order_canceled: "Total Number of Orders Cancelled",
      Sort_By_Z_A: "Sort By Z-A",
      Email_id: "Email ID",
      Support_24: "Supporto online",
      Continuous_support: "Supporto tecnico tramite Alysei Live Chat",
      Manage_your_customers: "Manage your Customers",
      Customize_and_manage_your_customers:
        "Customize and manage your customers.",
      Online_payment_integration:
        "Online payment integration for smooth checkouts.",
      Alysei_will_charge:
        "La commissione Alysei per ogni transazione è pari al 10%.",
      Alysei_commission_info:
        "La commissione del 10% di Alysei non comprende fees di terze parti",
      Active_user: "Active",
      Inactive_user: "Inactive",
      charge_10_percentage:
        "Alysei will retain a 10% commission on your listed price",
      You_earned: "Amount You Get",
      You_earned_info:
        "L’importo include la commissione Alysei e le fees di Stripe.",
      RRP_info:
        "Il prezzo al dettaglio consigliato (RRP) è il prezzo suggerito dal produttore al quale il prodotto dovrebbe essere venduto",
      Tax_Class_info:
        "Una classe fiscale è una raccolta di aliquote fiscali. Le classi fiscali determinano le aliquote fiscali applicabili.",
      Tax_Rate_info:
        "Un’aliquota fiscale è la percentuale con cui le tasse vengono pagate a un governo.",
      Order_management_info: "",
      badge_Text_For_Stripe:
        "Per vendere i tuoi prodotti su Alysei e ricevere pagamenti, connetti il tuo account Stripe. Per maggiori informazioni,",
      Tax_Class_Product:
        "Scegli il tipo di tassa da applicare.Per maggiori info vai alla sezione Tax Management sul tuo dashboard.",
    };

    if (lang && lang.length && lang === "it") {
      const ENV = IT;
      return ENV;
    } else {
      const ENV = EN;
      return ENV;
    }
  },
};
