import React from "react";
import "../../../assets/marketplacecss/mrktplace-style.css";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useParams } from "react-router-dom";
import Cropper from "react-cropper";
import Modal from "react-modal";
import CurrencyInput from "react-currency-input-field";
import FormError from "../../../components/Errors/FormError";
import constant from "../../../helpers/constant";
import auth from "../../../helpers/auth";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import ReactTooltip from "react-tooltip";
import { setFormErrors } from "../../register/modules/register";

import {
  fetchStoreData,
  fetchProductCategories,
  fetchBrandLabels,
  getMarketplaceProductDetails,
  updateProductData,
  removeMarketplaceStoreProductImage,
  setMarketplaceProduct,
  getMyAllTaxesClassesList,
  getMarketplaceMyProducts,
  fetchHomeScreenData,
} from "../modules/marketplace";
import language from "../../../helpers/language";
import MyEditor from "../../../components/TextEditor/MyEditor";
import DashboardSideBar from "./DashboardSideBar";
import Carousel from "react-grid-carousel";

export default function EditProduct() {
  const [activeTab, setActiveTab] = useState("products");
  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const myRef = useRef(null);
  const [lang] = useState(language.getLang());
  const [isSuccess, setIsSuccess] = useState(false);
  const [galleryImageObject, setGalleryImageObject] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [addProductDataArr, setAddProductDataArr] = useState({});
  const [isSpinner, setIsSpinner] = useState(false);
  const [isflag, setIsflag] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [OrderQuantityUnit, setOrderQuantityUnit] = useState("No. of pieces");
  const [allTaxClasses, setAllTaxClasses] = useState([]);
  const [isCropped, setIsCropped] = useState(false);
  const [targetImageId, setTargetImageId] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [cropData, setCropData] = useState("");
  const cropper = React.createRef(null);
  const [productCat, setProductCat] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState([]);
  const [selectedBrandLabel, setSelectedBrandLabel] = useState([]);
  const [productPrice, setProductPrice] = useState("0.00");
  const [rrPrice, setRRPrice] = useState("0.00");

  useEffect(async () => {
    const user = await auth.getUserType();
    JSON.parse(user).role_id !== constant.rolesId.producer &&
      history.push("/marketplace/error");
    await dispatch(getMarketplaceProductDetails(id));
    await dispatch(fetchStoreData());
    await dispatch(fetchProductCategories());
    await dispatch(fetchBrandLabels());
  }, [id]);

  console.log(OrderQuantityUnit, "orderQuan")

  let {
    marketplaceProduct,
    marketPlaceStore,
    productCategies,
    brandLabels,
    marketPlaceDashboardSpinner,
    errMessage,
  } = useSelector((state) => state.marketPlaceReducerData);

  //console.log(marketplaceProduct, "marketplace");

  // Get my product classes
  useEffect(async () => {
    const response = await getMyAllTaxesClassesList();
    setAllTaxClasses(response.data ?? []);
  }, []);

  useEffect(async () => {
    if (productCategies && productCategies.length > 0) {
      const prodCatArray = [];
      productCategies.map((cat, index) => {
        const array = {
          key: cat.name,
          value: cat.marketplace_product_category_id,
        };

        if (
          cat.marketplace_product_category_id ===
          parseInt(marketplaceProduct.product_category_id)
        ) {
          setSelectedProductCategory([array]);
        }
        prodCatArray.push(array);
      });
      await setProductCat(prodCatArray);
    }

    await setProductPrice(marketplaceProduct?.product_price);
    await setRRPrice(marketplaceProduct?.rrp_price ?? 0);
  }, [productCategies, marketplaceProduct]);

  useEffect(async () => {
    if (brandLabels && brandLabels.length > 0) {
      const brandLabelArray = [];
      brandLabels.map((brand, index) => {
        const array = {
          key: brand.name,
          value: brand.marketplace_brand_label_id,
        };
        if (
          brand.marketplace_brand_label_id ===
          parseInt(marketplaceProduct.brand_label_id)
        ) {
          setSelectedBrandLabel([array]);
        }
        brandLabelArray.push(array);
      });
      await setBrandLabel(brandLabelArray);
    }
  }, [brandLabels, marketplaceProduct]);

  //console.log(marketplaceProduct, "marketplace");

  // Get my product classes
  useEffect(async () => {
    const response = await getMyAllTaxesClassesList();
    setAllTaxClasses(response.data ?? []);
  }, []);

  useEffect(async () => {
    if (productCategies && productCategies.length > 0) {
      const prodCatArray = [];
      productCategies.map((cat, index) => {
        const array = {
          key: cat.name,
          value: cat.marketplace_product_category_id,
        };

        if (
          cat.marketplace_product_category_id ===
          parseInt(marketplaceProduct.product_category_id)
        ) {
          setSelectedProductCategory([array]);
        }
        prodCatArray.push(array);
      });
      await setProductCat(prodCatArray);
    }

    await setProductPrice(marketplaceProduct?.product_price);
    await setRRPrice(marketplaceProduct?.rrp_price ?? 0);
  }, [productCategies, marketplaceProduct]);

  useEffect(async () => {
    if (brandLabels && brandLabels.length > 0) {
      const brandLabelArray = [];
      brandLabels.map((brand, index) => {
        const array = {
          key: brand.name,
          value: brand.marketplace_brand_label_id,
        };
        if (
          brand.marketplace_brand_label_id ===
          parseInt(marketplaceProduct.brand_label_id)
        ) {
          setSelectedBrandLabel([array]);
        }
        brandLabelArray.push(array);
      });
      await setBrandLabel(brandLabelArray);
    }
  }, [brandLabels, marketplaceProduct]);

  useEffect(() => {
    setAddProductDataArr({
      ...marketplaceProduct,
    });
  }, [marketplaceProduct]);

  useEffect(async () => {
    await setOrderQuantityUnit(
      marketplaceProduct?.unit !== null
        ? marketplaceProduct?.unit
        : OrderQuantityUnit
    );
  }, [marketplaceProduct]);

  const scrollToRef = (myref) => {
    window.scrollTo(0, myref.current && myref.current.offsetTop - 200);
  };

  const handleGalleryImages = async (event) => {
    Object.keys(event.target.files).map((item, i) => {
      if (
        !event.target.files[item].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)
      ) {
        dispatch(setFormErrors(lang?.Imagetype, event.target.id));
        scrollToRef(myRef);
      } else if (event.target.files[item].size > 2000000) {
        dispatch(setFormErrors(lang?.ImageSize, event.target.id));
        scrollToRef(myRef);
      } else {
        setOpenModal(true);

        setFileName(event.target.files[0].name);
        setTargetImageId(event.target.id);
        setImage(URL.createObjectURL(event.target.files[0]));
      }
    });

    setTimeout(() => {
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 3000);
    return (event.target.value = null);
  };

  const removeGallery = async (index) => {
    if (index > -1) {
      galleryImages.splice(index, 1);
      galleryImageObject.splice(index, 1);
      await setGalleryImages(galleryImages);
      await setGalleryImageObject(galleryImageObject);
      setAddProductDataArr({
        ...addProductDataArr,
        gallery_images: galleryImageObject,
      });
    }
  };

  const handleChange = async (event) => {
    setIsUpdate(true);
    let key = event.target.name;
    let value = event.target.value;
    await setAddProductDataArr({ ...addProductDataArr, [key]: value });
  };

  const handleChangePrice = async (value, name) => {
    name === "rrp_price" ? setRRPrice(value) : await setProductPrice(value);
    await setAddProductDataArr({ ...addProductDataArr, [name]: value ?? 0 });
    setIsUpdate(true);
  };

  const updateProduct = async () => {
    await setIsSpinner(true);
    const {
      title,
      description,
      product_category_id,
      handling_instruction,
      min_order_quantity,
      dispatch_instruction,
      available_for_sample,
      quantity_available,
      product_price,
      keywords,
      brand_label_id,
      gallery_images,
      class_tax_id,
    } = addProductDataArr;
    const obj = {
      title: title || title === "" ? title : marketplaceProduct.title,
      description:
        description || description === ""
          ? description
          : marketplaceProduct.description,
      product_category_id:
        product_category_id || product_category_id >= 0
          ? product_category_id.toString()
          : marketplaceProduct.product_category_id.toString(),
      handling_instruction:
        handling_instruction || handling_instruction === ""
          ? handling_instruction
          : marketplaceProduct.handling_instruction,
      min_order_quantity:
        min_order_quantity ?? marketplaceProduct?.min_order_quantity,
      dispatch_instruction:
        dispatch_instruction || dispatch_instruction === ""
          ? dispatch_instruction
          : marketplaceProduct.dispatch_instruction,
      available_for_sample:
        available_for_sample || available_for_sample === ""
          ? available_for_sample
          : marketplaceProduct.available_for_sample,
      quantity_available:
        quantity_available ?? marketplaceProduct?.quantity_available,
      product_price:
        product_price || product_price === ""
          ? product_price
          : marketplaceProduct.product_price,
      keywords:
        keywords || keywords === "" ? keywords : marketplaceProduct?.keywords,
      brand_label_id: brand_label_id
        ? brand_label_id
        : marketplaceProduct.brand_label_id,
      gallery_images: gallery_images,
      class_tax_id: class_tax_id ?? marketplaceProduct?.class_tax_id,
      rrp_price: rrPrice,
    };
    var data = validateData(obj);
    if (data.length > 0) {
      await setIsSpinner(false);
      dispatch(setFormErrors(data[0][1], data[0][0]));
      await (myRef && myRef.content);
      scrollToRef(myRef);
    } else if (
      (gallery_images === undefined || gallery_images.length <= 0) &&
      marketplaceProduct.product_gallery.length <= 0
    ) {
      await setIsSpinner(false);
      dispatch(setFormErrors(lang?.enterImage, "gallery_images"));
      scrollToRef(myRef);
    } else {
      obj.unit = await OrderQuantityUnit;
      await dispatch(
        updateProductData(obj, marketplaceProduct.marketplace_product_id)
      ).then(async (res) => {
        if (res?.success === 200) {
          setIsSpinner(false);
          setIsSuccess(true);
          setResponseMsg("Successfully updated.");
          await dispatch(getMarketplaceMyProducts(1, {}));
          await dispatch(fetchHomeScreenData());
          setGalleryImages([]);
          history.push("/marketplace/dashboard");
          localStorage.setItem("tab", activeTab);
        } else {
          setIsSpinner(false);
          console.log(res?.errors?.exception);
        }
      });
    }

    setTimeout(() => {
      setIsSuccess(false);
      setResponseMsg("");
      dispatch(setFormErrors({ msg: "", field: "" }));
    }, 5000);
  };

  const validateData = (data) => {
    let keyValidate = [
      // {
      //   name: 'title',
      //   min_char: 3,
      //   max_char: 60,
      //   display_name: `${lang?.only_title}`,
      // },

      {
        name: "product_category_id",
        min_number: 1,
        display_name: `${lang?.Product_category}`,
      },

      // {
      //   name: 'handling_instruction',
      //   min_char: 10,
      //   max_char: 250,
      //   display_name: `${lang?.Handling_Instructions}`,
      // },
      // {
      //   name: 'dispatch_instruction',
      //   min_char: 10,
      //   max_char: 250,
      //   display_name: `${lang?.Dispatch_Instruction}`,
      // },
      {
        name: "available_for_sample",
        min_value: 0,
        display_name: `${lang?.Available_For_Sample}`,
      },
    ];
    let errors = [];
    for (let key = 0; key < keyValidate.length; key++) {
      let keyName = keyValidate[key].name;
      if (!data.hasOwnProperty(keyValidate[key].name) || !data[keyName]) {
        errors.push([
          keyValidate[key].name,
          keyValidate[key].display_name + ` ${lang?.is_required}`,
        ]);
      }

      if (keyValidate[key].hasOwnProperty("min_char")) {
        let keyName = keyValidate[key].name;
        if (data[keyName].length < keyValidate[key].min_char) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.Min_Char} ` +
            keyValidate[key].min_char,
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("max_char")) {
        let keyName = keyValidate[key].name;
        if (data[keyName].length > keyValidate[key].max_char) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.LessThan} ` +
            keyValidate[key].max_char +
            " characters",
          ]);
        }
      }

      if (keyValidate[key].hasOwnProperty("min_value")) {
        let keyName = keyValidate[key].name;
        if (
          data[keyName] &&
          data[keyName].length < keyValidate[key].min_value
        ) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.MinValue} ` +
            keyValidate[key].min_value,
          ]);
        }
      }
      if (keyValidate[key].hasOwnProperty("max_value")) {
        let keyName = keyValidate[key].name;
        if (data[keyName] && data[keyName] > keyValidate[key].max_value) {
          errors.push([
            keyValidate[key].name,
            keyValidate[key].display_name +
            ` ${lang?.LessThanOrEq} ` +
            keyValidate[key].max_value,
          ]);
        }
      }
    }
    return errors;
  };

  const deleteProductGalleryImage = async (marketplace_product_gallery_id) => {
    setIsUpdate(true);
    let type = 2;
    const item =
      marketplaceProduct &&
      marketplaceProduct.product_gallery.filter(
        (item) =>
          item.marketplace_product_gallery_id !== marketplace_product_gallery_id
      );
    const delete_product_image = {
      ...marketplaceProduct,
      product_gallery: (marketplaceProduct["product_gallery"] = item),
    };
    const response = await dispatch(
      removeMarketplaceStoreProductImage(marketplace_product_gallery_id, type)
    );
    if (response.success === 200) {
      await dispatch(setMarketplaceProduct(delete_product_image));
      // alert('product remove successfully.');
    }
  };

  // Start Cropping your Image
  const getCropData = async () => {
    setIsUpdate(true);
    setIsCropped(true);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
      targetImageId === "gallery_images" &&
        (await galleryImages.push(
          cropper.current.cropper.getCroppedCanvas().toDataURL()
        ));
      await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL()).then(
        (res) =>
          res.blob().then(async (blob) => {
            const file = await new File([blob], `${fileName}`, {
              type: "image/png",
            });
            targetImageId === "gallery_images" &&
              (await galleryImageObject.push(file));
          })
      );
      await setIsCropped(false);
      await setOpenModal(false);
      await setGalleryImages(galleryImages);
      await setAddProductDataArr({
        ...addProductDataArr,
        gallery_images: galleryImageObject,
      });
    }
  };

  const closeModal = async () => {
    await setCropData("");
    await setImage("");
    await setTargetImageId("");
    await setCropData("");
    await setFileName("");
    await setOpenModal(false);
  };
  // End of Cropping

  const selectTab = async (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className='marketplace dashboard header'>
        <div className='flex justify-between items-center'>
          <h6 className='back-btn-small'>
            <Link to='/marketplace/dashboard?tab=products'>
              <i className='fa fa-arrow-left' aria-hidden='true' /> &nbsp;{" "}
              {lang?.Go_back_Dashboard}
            </Link>
          </h6>
        </div>
      </div>
      <div className='servics_blocks dashboardView'>
        <div className='site-align top-align'>
          <div className='left__section-block sm:mb-2 md:mb-2'>
            <div className='tabLeftBlock'>
              <DashboardSideBar
                lang={lang}
                selectTab={selectTab}
                activeTab={activeTab}
                id={id}
              />
            </div>
          </div>
          <div className='servics_blocks middle-long__section-block middleBlockBg'>
            {/* <div className="flex justify-between items-center"> */}
            <div className='home-section no-padding sm:mt-2'>
              <div className='product-title'>
                <div className='flex flex-wrap justify-between items-center'>
                  <h4 className='mb-0'>{lang?.EditProduct}</h4>
                  <div className='inner-btn-box'>
                    <Link
                      to='#'
                      className='btn btn-blue SaveBtn'
                      onClick={() => {
                        isUpdate && updateProduct();
                      }}
                    >
                      {isSpinner ? (
                        "Saving..."
                      ) : (
                        <>
                          {" "}
                          <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                          >
                            <path
                              d='M1.66667 0.5C0.754788 0.5 0 1.25479 0 2.16667V13.8333C0 14.7452 0.754788 15.5 1.66667 15.5H13.3333C14.2452 15.5 15 14.7452 15 13.8333V3.83333L11.6667 0.5H1.66667ZM1.66667 2.16667H10.9766L13.3333 4.52344V13.8333H1.66667V2.16667ZM3.33333 3.83333V6.33333H10V3.83333H3.33333ZM7.08333 8C6.5308 8 6.00089 8.21949 5.61019 8.61019C5.21949 9.00089 5 9.5308 5 10.0833C5 10.6359 5.21949 11.1658 5.61019 11.5565C6.00089 11.9472 6.5308 12.1667 7.08333 12.1667C7.63587 12.1667 8.16577 11.9472 8.55647 11.5565C8.94717 11.1658 9.16667 10.6359 9.16667 10.0833C9.16667 9.5308 8.94717 9.00089 8.55647 8.61019C8.16577 8.21949 7.63587 8 7.08333 8Z'
                              fill='white'
                            />
                          </svg>
                          Save
                        </>
                      )}
                    </Link>
                    <Link to='/marketplace/dashboard' className='btn btn--gray'>
                      <svg width='18' height='9' viewBox='0 0 18 9' fill='none'>
                        <path
                          d='M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z'
                          fill='black'
                        />
                      </svg>
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className='product-list'>
                <div className='recentOrder secBlockGap'>
                  <h4 className='secBlockTitle'></h4>
                  <form className='configure-store-form add-product-page'>
                    <div className='marketplace add-product'>
                      {marketPlaceDashboardSpinner && <FormSpinner />}
                      <div className='mt-0'>
                        {responseMsg && (
                          <h4
                            className='font-20 text-center w-full'
                            style={{
                              color: isSuccess ? "green" : "red",
                              textAlign: "center",
                            }}
                          >
                            {responseMsg}.
                          </h4>
                        )}
                        <div className='flex items-center store-profile pb-3'>
                          <div className='user__pic'>
                            <img
                              src={`${marketPlaceStore.logo_base_url}${marketPlaceStore.logo_id}`}
                              alt={marketPlaceStore.name}
                              title={marketPlaceStore.name}
                            />
                          </div>
                          <h4 className='font-16 ml-5'>
                            {marketPlaceStore.name}
                          </h4>
                        </div>
                        <div className='flex flex-wrap w-full'>
                          {/* <div className="xl:w-1/4 lg:w-1/4 md:w-full sm:w-full col-padding add--cover--photo">
                      <div className>
                        <label>
                          {isflag ? <FormSpinner /> : <span className>
                            <i className="fa fa-picture-o" aria-hidden="true" />
                          </span>}
                          <input type="file" id="gallery_images" name="gallery_images[]" style={{}} accept="image/png,image/jpeg" onChange={(e) => handleGalleryImages(e)} multiple value={null} /> {lang?.Add_photos}
                        </label>
                      </div>
                    </div> */}
                          <div className='w-full'>
                            <ul className='gallery__images flex'>
                              <div className='xl:w-1/4 lg:w-1/4 md:w-full sm:w-full col-padding add--cover--photo mt-0'>
                                <div className>
                                  <label>
                                    {isflag ? (
                                      <FormSpinner />
                                    ) : (
                                      <span className>
                                        <i
                                          className='fa fa-picture-o'
                                          aria-hidden='true'
                                        />
                                      </span>
                                    )}
                                    <input
                                      type='file'
                                      id='gallery_images'
                                      name='gallery_images[]'
                                      style={{}}
                                      accept='image/png,image/jpeg'
                                      onChange={(e) => handleGalleryImages(e)}
                                      multiple
                                      value={null}
                                    />{" "}
                                    {lang?.Add_photos}
                                  </label>
                                </div>
                              </div>
                              {galleryImages?.length > 0 &&
                                galleryImages?.map((src, i) => {
                                  return (
                                    <li key={i} className='uploadedImg'>
                                      <img src={src} key={i} />
                                      <div className='editDeleteBtn flex justify-end items-center p-2'>
                                        <p>
                                          <Link
                                            to='#'
                                            className='remove-image'
                                            style={{ cursor: "pointer" }}
                                            onClick={() => removeGallery(i)}
                                          >
                                            <i
                                              className='fa fa-trash'
                                              aria-hidden='true'
                                            ></i>
                                          </Link>
                                        </p>
                                      </div>
                                    </li>
                                  );
                                })}
                              <div
                                className="carousel-container"
                                style={{ width: '420px', overflowX: 'auto', whiteSpace: 'nowrap' }}
                              >
                                <Carousel
                                  cols={2} // Display two columns

                                  gap={10}
                                  loop
                                  hideArrow={
                                    marketplaceProduct?.product_gallery?.length <= 3
                                      ? true
                                      : false
                                  }
                                >
                                  {marketplaceProduct?.product_gallery?.length > 0 &&
                                    marketplaceProduct?.product_gallery.map((item, i) => {
                                      return (
                                        <Carousel.Item>
                                          <li
                                            className='uploadedImg formLoadImg editImage'
                                            key={
                                              item.marketplace_product_gallery_id
                                            }
                                          >
                                            <img
                                              src={`${item.base_url}${item.attachment_medium_url}`}
                                            />
                                            <div className='editDeleteBtn flex justify-end items-center p-2'>
                                              <p>
                                                <Link
                                                  to='#'
                                                  className='remove-image'
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    deleteProductGalleryImage(
                                                      item.marketplace_product_gallery_id
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className='fa fa-trash'
                                                    aria-hidden='true'
                                                  ></i>
                                                </Link>
                                              </p>
                                            </div>
                                          </li>
                                        </Carousel.Item>
                                      );
                                    })}
                                </Carousel>
                              </div>
                            </ul>
                          </div>


                          <div className='w-full col-padding imgBottomLine'>
                            <p className='mt-3'>
                              {lang?.Photos}{" "}
                              {galleryImages.length +
                                (marketplaceProduct &&
                                  marketplaceProduct.product_gallery &&
                                  marketplaceProduct.product_gallery.length)}
                              /8 {lang?.ImageMaxLen}.
                            </p>
                            {errMessage.field == "gallery_images" ? (
                              <FormError msg={errMessage.msg} />
                            ) : null}
                          </div>
                        </div>
                        <div className='mrkt-place form'>
                          <div className='card-form'>
                            <div className='flex flex-wrap main-row'>
                              <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                <div className='w-100'>
                                  <div className='form__item'>
                                    <label>{lang?.only_title}*</label>
                                    <input
                                      type='text'
                                      name='title'
                                      onChange={(e) => handleChange(e)}
                                      placeholder={lang?.Product_Title}
                                      id='product-title'
                                      defaultValue={marketplaceProduct?.title}
                                      maxLength={100}
                                    />
                                    {errMessage.field == "title" ? (
                                      <div ref={myRef}>
                                        <FormError msg={errMessage.msg} />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className='w-100'>
                                  <div className='form__item'>
                                    <label>{lang?.Keywords}</label>
                                    <div className='withinfoIcon relative'>
                                      <input
                                        type='text'
                                        name='keywords'
                                        onChange={(e) => handleChange(e)}
                                        placeholder={lang?.Keywords}
                                        id='product-title'
                                        defaultValue={
                                          marketplaceProduct.keywords
                                        }
                                      />
                                      <p
                                        className='aadWarning pr-4'
                                        data-tip={
                                          lang?.helps_user_to_find_product
                                        }
                                      >
                                        <i
                                          className='fa fa-exclamation-circle'
                                          aria-hidden='true'
                                        ></i>
                                      </p>
                                      <ReactTooltip />
                                    </div>
                                  </div>
                                </div>
                                <div className='w-100'>
                                  <div className='form__item'>
                                    <label>{lang?.Product_category}*</label>
                                    <select
                                      name={"product_category_id"}
                                      value={
                                        addProductDataArr?.product_category_id ??
                                        ""
                                      }
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value={""}>{lang?.select}</option>
                                      {productCat?.map(({ key, value }) => {
                                        return (
                                          <option key={key} value={value}>
                                            {key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    {errMessage.field ==
                                      "product_category_id" ? (
                                      <div ref={myRef}>
                                        <FormError msg={errMessage.msg} />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className='w-100'>
                                  {/* <div className="form__item">
                                <label>{lang?.Quantity_Available}*</label>
                                <input type="text" name="quantity_available" onChange={(e) => handleChange(e)} placeholder={`${lang?.Quantity_Available}`} id="Quantity-Available" defaultValue={marketplaceProduct.quantity_available} />
                                {errMessage.field == "quantity_available" ? (
                                  <div ref={myRef}>
                                    <FormError msg={errMessage.msg} />
                                  </div>
                                ) : null}
                              </div> */}
                                </div>
                                <div className='w-100'>
                                  {/* <div className="form__item noBoder minQty"> */}
                                </div>
                              </div>
                              <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                <div className='col-100'>
                                  <div className='form__item'>
                                    <label>{lang?.Description}*</label>
                                    <textarea
                                      className='cmnt-write'
                                      id='description'
                                      onChange={(e) => handleChange(e)}
                                      placeholder={`${lang?.Edit_prod_desc}`}
                                      name='description'
                                      defaultValue={
                                        marketplaceProduct.description
                                      }
                                      maxLength='250'
                                    />
                                    {/* {addProductDataArr?.description?.length > 0 && `${addProductDataArr?.description?.length}/250 Characters`} */}
                                    {errMessage.field == "description" ? (
                                      <div ref={myRef}>
                                        <FormError msg={errMessage.msg} />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className='w-100'>
                                  <div className='form__item'>
                                    <label>{lang?.Brand_Label}</label>
                                    <select
                                      name={"brand_label_id"}
                                      value={
                                        addProductDataArr?.brand_label_id ?? ""
                                      }
                                      onChange={(e) => (e) => handleChange(e)}
                                    >
                                      <option value={""}>{lang?.select}</option>
                                      {brandLabel?.map(({ key, value }) => {
                                        return (
                                          <option key={key} value={value}>
                                            {key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Stock data  */}
                    <h4 className='secBlockTitle'>Stock</h4>
                    <div className='marketplace add-product'>
                      <div className='mrkt-place form'>
                        <div className='card-form'>
                          <div className='flex flex-wrap main-row'>
                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                              <div className='w-100'>
                                <div className='form__item noBoder'>
                                  <div className='pdctQty'>
                                    <div className=''>
                                      <label>{lang?.Quantity_Available}</label>
                                      <div className='withinfoIcon relative'>
                                        <input
                                          type='text'
                                          name='quantity_available'
                                          onChange={(e) => handleChange(e)}
                                          placeholder='Quantity Available*'
                                          id='Quantity-Available'
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          defaultValue={
                                            marketplaceProduct.quantity_available !==
                                              null
                                              ? marketplaceProduct?.quantity_available
                                              : 0
                                          }
                                        />
                                        <p
                                          className='aadWarning p-4'
                                          data-tip={lang?.quanity_available}
                                        >
                                          <i
                                            className='fa fa-exclamation-circle'
                                            aria-hidden='true'
                                          ></i>
                                        </p>
                                        <ReactTooltip />
                                      </div>
                                    </div>
                                  </div>
                                  {errMessage.field == "quantity_available" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='w-100'>
                                <div className='form__item noBoder'>
                                  <label>{lang?.Minimum_Order_Quantity}</label>
                                  <div className='withinfoIcon relative flex items-center'>
                                    <input
                                      type='text'
                                      name='min_order_quantity'
                                      onChange={(e) => handleChange(e)}
                                      placeholder={`${lang?.Minimum_Order_Quantity}`}
                                      id='Minimum-Order'
                                      defaultValue={
                                        marketplaceProduct.min_order_quantity !==
                                          "null"
                                          ? marketplaceProduct.min_order_quantity
                                          : 0
                                      }
                                    />
                                  </div>
                                  {errMessage.field == "min_order_quantity" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                              <div className='w-100'>
                                <div className='form__item noBoder'>
                                  <div className='pieceNo'>
                                    <label>{lang?.pieces}</label>
                                    <select
                                      onChange={(e) => { setOrderQuantityUnit(e.target.value); setIsUpdate(true); }
                                      }
                                      value={OrderQuantityUnit}
                                    >
                                      <option value={"No. of pieces"}>
                                        {lang?.pieces}
                                      </option>
                                      <option value={"No. of bottles"}>
                                        {lang?.bottles}
                                      </option>
                                      <option value={"liters"}>
                                        {lang?.liters}
                                      </option>
                                      <option value={"kilograms"}>
                                        {lang?.kilograms}
                                      </option>
                                      <option value={"grams"}>
                                        {lang?.grams}
                                      </option>
                                      <option value={"milligrams"}>
                                        {lang?.milligrams}
                                      </option>
                                      <option value={"pallet"}>
                                        {lang?.pallet}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className='w-100'>
                                <div className='form__item'>
                                  <label>{lang?.Sample_Available}*</label>
                                  <select
                                    id='available_for_sample'
                                    name='available_for_sample'
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value='-1'>
                                      {lang?.Sample_Available}
                                    </option>
                                    <option
                                      value='Yes'
                                      selected={
                                        marketplaceProduct.available_for_sample ===
                                          "Yes"
                                          ? true
                                          : false
                                      }
                                    >
                                      {lang?.yes}
                                    </option>
                                    <option
                                      value='No'
                                      selected={
                                        marketplaceProduct.available_for_sample ===
                                          "No"
                                          ? true
                                          : false
                                      }
                                    >
                                      {lang?.no}
                                    </option>
                                  </select>
                                  {errMessage.field ==
                                    "available_for_sample" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Price data  */}
                    <h4 className='secBlockTitle'>Price</h4>
                    <div className='marketplace add-product'>
                      <div className='mrkt-place form'>
                        <div className='card-form'>
                          <div className='flex flex-wrap main-row'>
                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                              <div className='w-100'>
                                <div className='form__item'>
                                  <label>{lang?.Product_Price}*</label>
                                  <div className='withinfoIcon relative'>
                                    <CurrencyInput
                                      id='product_price'
                                      name='product_price'
                                      placeholder={`${lang?.product_price}*`}
                                      allowDecimals={true}
                                      value={productPrice}
                                      decimalsLimit={2}
                                      onValueChange={(value, name) =>
                                        handleChangePrice(value, name)
                                      }
                                    />
                                    <span className='usd'>
                                      <i
                                        className='fa fa-usd'
                                        aria-hidden='true'
                                      ></i>
                                    </span>
                                    <p
                                      className='aadWarning pr-4'
                                      data-tip={lang?.charge_10_percentage}
                                      currentitem='false'
                                    >
                                      <i
                                        className='fa fa-exclamation-circle'
                                        aria-hidden='true'
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  {productPrice > 0 && (
                                    <p className='textGreen'>
                                      You will get $
                                      {productPrice - (productPrice * 10) / 100} (Click info icon for more information)
                                    </p>
                                  )}
                                  {errMessage.field == "product_price" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='w-100'>
                                <div className='form__item'>
                                  <label>Recommended Retail Price</label>
                                  <div className='withinfoIcon relative'>
                                    <CurrencyInput
                                      id='rrp_price'
                                      name='rrp_price'
                                      placeholder={`${lang?.product_price}`}
                                      allowDecimals={true}
                                      value={rrPrice}
                                      decimalsLimit={2}
                                      onValueChange={(value, name) =>
                                        handleChangePrice(value, name)
                                      }
                                    />
                                    <span className='usd'>
                                      <i
                                        className='fa fa-usd'
                                        aria-hidden='true'
                                      ></i>
                                    </span>
                                    <p
                                      className='aadWarning pr-4'

                                      data-tip={lang?.RRP_info}
                                      currentitem='false'
                                    >
                                      <i
                                        className='fa fa-exclamation-circle'
                                        aria-hidden='true'
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                              <div className='w-100'>
                                <div className='form__item'>
                                  <label>Tax classes</label>
                                  <div className="withinfoIcon relative selectOption">
                                    <select
                                      className=""
                                      id='class_tax_id'
                                      name='class_tax_id'
                                      onChange={(e) => {
                                        setIsUpdate(true);
                                        setAddProductDataArr({
                                          ...addProductDataArr,
                                          ["class_tax_id"]: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value='-1'>
                                        {"Select tax classes"}
                                      </option>
                                      {allTaxClasses?.length > 0 ? (
                                        allTaxClasses.map(
                                          ({ tax_class_id, name }) => {
                                            return (
                                              <option
                                                value={tax_class_id}
                                                selected={
                                                  marketplaceProduct.class_tax_id ===
                                                    tax_class_id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {name}
                                              </option>
                                            );
                                          }
                                        )
                                      ) : (
                                        <option value=''>Not create yet!</option>
                                      )}
                                    </select>
                                    <p
                                      className="aadWarning pr-6"
                                      data-tip=""
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        title='Select the tax class to apply. For more info go to the Tax Management section on your dashboard.'
                                      ></i>
                                    </p>
                                  </div>
                                  {errMessage.field == "class_tax_id" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Other data  */}
                    <h4 className='secBlockTitle'>Other</h4>
                    <div className='marketplace add-product'>
                      <div className='mrkt-place form'>
                        <div className='card-form'>
                          <div className='flex flex-wrap main-row'>
                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                              <div className='w-100'>
                                <div
                                  className='form__item'
                                  onClick={() => setIsUpdate(true)}
                                >
                                  <div className='withinfoIcon relative flexBox labelInfo'>
                                    <label>{lang?.Handling_Instructions}*</label>
                                    <p
                                      className=""
                                      data-tip={
                                        lang?.Provide_details_about_your_safe_handling_instructions
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  <div className="withinfoIcon relative textArea">
                                    <MyEditor
                                      setAddProductDataArr={setAddProductDataArr}
                                      keys='handling_instruction'
                                      data={
                                        marketplaceProduct.handling_instruction
                                      }
                                    />
                                  </div>
                                  {addProductDataArr?.handling_instruction
                                    ?.length > 0 &&
                                    `${addProductDataArr?.handling_instruction?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    )?.length
                                    }/250 Characters`}
                                  {errMessage.field ==
                                    "handling_instruction" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                              <div className='col-100'>
                                <div
                                  className='form__item'
                                  onClick={() => setIsUpdate(true)}
                                >
                                  <div className='withinfoIcon relative flexBox labelInfo'>
                                    <label>{lang?.Dispatch_Instruction}*</label>
                                    <p
                                      className=""
                                      data-tip={
                                        lang?.Provide_details_about_your_safe_handling_instructions
                                      }
                                      currentitem="false"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                  <div className="withinfoIcon relative textArea">
                                    <MyEditor
                                      setAddProductDataArr={setAddProductDataArr}
                                      keys='dispatch_instruction'
                                      data={
                                        marketplaceProduct.dispatch_instruction
                                      }
                                    />
                                  </div>
                                  {addProductDataArr?.dispatch_instruction
                                    ?.length > 0 &&
                                    `${addProductDataArr?.dispatch_instruction?.replace(
                                      /<[^>]+>/g,
                                      ""
                                    )?.length
                                    }/250 Characters`}
                                  {errMessage.field ==
                                    "dispatch_instruction" ? (
                                    <div ref={myRef}>
                                      <FormError msg={errMessage.msg} />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="w-full col-padding mt-3">
                    <p className="">
                      Photos {galleryImages.length}/5 Choose your stores main photo first. Add more photos of your store max 5 allowed.
                    </p>
                    {errMessage.field == "gallery_images" ? (
                            <FormError msg={errMessage.msg} />
                          ) : null}
                    
                    </div> */}
                <div className='mrkt-place form editProduct'>
                  <div className='card-form'>
                    <form className='configure-store-form add-product-page'></form>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* </div>
          </div>
        </div> */}
      {/* crop image nodal */}
      <div>
        {openModal && (
          <Modal
            className=''
            ariaHideApp={false}
            isOpen={openModal}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0px",
                // outline: 'none',
              },
            }}
          >
            <div className='popup_header flex justify-between items-center'>
              <h4 className='page__title'>{lang?.Crop_image}</h4>
              <button className='close_btn--modal' onClick={() => closeModal()}>
                {" "}
                {lang?.close}
              </button>
            </div>
            <div className='card card--block modal_box'>
              <div className='crop_img_block'>
                <Cropper
                  zoomTo={0}
                  style={{ height: 400, width: "100%" }}
                  preview='.img-preview'
                  src={image}
                  viewMode={1}
                  dragMode={"move"}
                  cropBoxMovable={false}
                  responsive={true}
                  cropBoxResizable={false}
                  ref={cropper}
                  initialAspectRatio={2 / 2}
                  // ready={() => {
                  //   cropper.current.cropper.setCropBoxData({
                  //     left: 70,
                  //     top: 70,
                  //     width: 200,
                  //     height: 200,
                  //   });
                  // }}
                  center={true}
                  guides={false}
                />
              </div>
              <div className='form__action form__item mt-4'>
                <button onClick={() => getCropData()}>
                  {" "}
                  {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`}{" "}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
