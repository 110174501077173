import React, { Fragment } from "react";
import { renderRoutes } from "react-router-config";
import Footer from "../components/Footer/Footer";
import { Header } from "../components/Headers/Header";
import auth from "../helpers/auth";
import { Redirect } from "react-router";

export const CoreLayout = ({ children, route }) => (
  <Fragment>
    {auth.isAuthenticated ?
      <Fragment>
        <Header />
        <div className="main__content">
          <div className="site__width">
            {children}
            {renderRoutes(route.routes)}
          </div>
        </div>
        {/* <Footer/> */}
      </Fragment> : <Redirect to='/' />
    }
  </Fragment>
);

export default CoreLayout;
