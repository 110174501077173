import React from 'react'

export default function Connect() {
   
    return (
        <div>
         
         
          {/* Site Header Title */}
          <div className="full-width-bg-white">
            <div className="site__width">
              <div className="card card--no-radius mb-2">
                <div className="text-left">
                  <i className="fa fa-user-plus" aria-hidden="true" />
                  <span>Connect</span>
                </div>
              </div>	
            </div>
          </div>
          {/* Layout Content */}
          <div className="main__content sugges" style={{minHeight: '53.75rem'}}>
            <div className="site__width">
              <div className="site-align top-align">
                {/* Left Block */}
                <div className="left__section-block">
                </div>
                {/* Center Block */}
                <div className="middle__section-block">
                  <div className="card card--block p-0">
                    <div className="card-form p-2">
                      <form className>
                        <div className="form__item form-mb-0">
                          <input type="email" placeholder="Search for connections" id="email" />
                        </div>
                      </form>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>ibyte infomatics</span>
                            <span className="user-link-username">@ibyteinfomatics320</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-card-box">
                      <div className="user-suggestion">
                        <div className="row align-items-center">
                          <div className="user-bg-gray">
                            <i className="fa fa-user" aria-hidden="true" />
                          </div>
                          <span className="text-name">
                            <span>sfsd</span>
                            <span className="user-link-username">@sfsd321</span>
                          </span>
                          <div className="follow-icon">
                            <i className="fa fa-plus-circle" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Right Block */}
                <div className="right__section-block sugges">
                </div>
              </div>
            </div>
          
          </div>
        </div>
      );
}
