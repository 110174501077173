import React from 'react'
import { Link } from 'react-router-dom'

function BadgeButton({ Image }) {
  return (
    <div className={`user-badge `} >
      <Link to="/subscription">
      <img src={Image} />
      </Link>
 
    </div>
  )
}

export default BadgeButton