import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  addProdctToCart,
  getSingleOfferImp,
} from "../../routes/marketplace/modules/marketplace";
import { useDispatch, useSelector } from "react-redux";
import ImporterSideBar from "./ImporterSideBar";
import language from "../../helpers/language";
import moment from "moment";
import constant from "../../helpers/constant";
import DashboardSideBar from "../../routes/marketplace/components/DashboardSideBar";
import { getSingleProductTax } from "../../routes/marketplace/modules/marketplaceHelper";
import IncoTermModal from "../../routes/marketplace/components/IncoTermModal";

export default function SingleViewOfferDetails() {
  const [incoTerm, setIncoTerm] = useState(false);
  const currentDate = moment();
  const { offerId } = useParams();
  const dispatch = useDispatch();
  const [offerData, setOfferData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lang] = useState(language.getLang());
  let { myDashBoardStatus, cartProducts } = useSelector(
    (state) => state.marketPlaceReducerData
  );
  const {
    profileData: { data },
  } = useSelector((state) => state.memberProfileData);
  const [activeTab, setActiveTab] = useState(
    myDashBoardStatus !== "" ? myDashBoardStatus : "myOffers"
  );
  const isProducerORNot =
    data?.user_data?.role_id === constant.rolesId.importer ||
    data?.user_data?.role_id === constant.rolesId["importer&distributer"] ||
    data?.user_data?.role_id === constant.rolesId.distributer;

  useEffect(() => {
    getSingleOfferData(offerId);
  }, [offerId]);

  const getSingleOfferData = async (offerId) => {
    setLoader(true);
    const offerResponse = await getSingleOfferImp(offerId);
    if (offerResponse) {
      setLoader(false);
      setOfferData(offerResponse?.data ?? []);
    } else {
      setLoader(false);
     
    }
  };
//console.log(offerData,"offerDta")
//console.log(offerData.length==0&&"yoooooo")

  const handleTabChanges = (tab) => {
    setActiveTab(tab);
  };

  const targetDate = moment(offerData?.end_date, "YYYY-MM-DD hh:ss");

  const addToCartYourOffer = () => {
    if (
      cartProducts?.length === 0 ||
      (cartProducts?.length > 0 &&
        cartProducts?.[0]?.map_offer_id?.[0] == offerId)
    ) {
      addProductFun();
    } else {
      showAlert();
    }
  };

  const addProductFun = () => {
    dispatch(addProdctToCart([]));
    localStorage.setItem("totalProductInCart", null);
    localStorage.setItem('stripe_id', "");
    const offerItem = offerData?.get_map_offer?.map((item, i) => ({
      count: item.quantity,
      product_price: item.unit_price,
      marketplace_product_id: item.product_id,
      get_product_tax: null,
      title: item?.product_info?.title ?? "",
      product_gallery: [],
      shipping_price: offerData?.shipping_price,
      map_offer_id: [item?.offer_id],
      type: "offer",
    }));
    dispatch(addProdctToCart(offerItem));
    localStorage.setItem("totalProductInCart", JSON.stringify(offerItem));
    localStorage.setItem('stripe_id', offerData?.stripeConnectedId);
  };

  // if user add product from other store
  const showAlert = () => {
    if (
      window.confirm(
        `At a time you can add either a product including offer or a product without offer.Do you want to add this product. Are you sure?`
      )
    ) {
      addProductFun();
    } else {
      console.log("This is just doing.");
    }
  };

  const getTotalPriceOfAllProduct = () => {
    const offerItem = offerData?.get_map_offer?.map((item, i) => ({
      count: item.quantity,
      product_price: item.unit_price,
      marketplace_product_id: item.product_id,
      get_product_tax: item?.product_info?.get_product_tax,
      title: item?.product_info?.title ?? "",
      product_gallery: [],
      shipping_price: offerData?.shipping_price,
      map_offer_id: [item?.offer_id],
      type: "offer",
    }));
    if (offerItem?.length > 0) {
      return offerItem?.reduce(
        (cur, item) =>
          cur +
          getSingleProductTax(
            item.product_price,
            item.count,
            item?.get_product_tax,
            false
          ),
        0
      );
    } else return 0;
  };

  const textLimit = (text, limit) => {
    if (text?.length > limit) {
      const part = text.slice(0, limit);
      return part + "...";
    }
    return text;
  };

  return (
    <>
      <div className='marketplace dashboard header'>
        {/* Banner Section */}
        {/* <div> */}
          <div className='section__wrapper'>
            <div className='flex justify-between items-center'>
              <h6 className='back-btn-small'>
                <Link
                  to={`${
                    isProducerORNot ? "/my-dashboard" : "/marketplace/dashboard"
                  }`}
                >
                  <i className='fa fa-arrow-left' aria-hidden='true' /> &nbsp;{" "}
                  {lang?.Go_back}
                </Link>
              </h6>
            </div>
            </div>
          </div>
            <div className='servics_blocks dashboardView'>
              <div className='site-align top-align'>
                <div className='left__section-block sm:mb-2 md:mb-2'>
                  <div className='tabLeftBlock'>
                    {isProducerORNot ? (
                      <ImporterSideBar
                        handleTabChanges={handleTabChanges}
                        activeTab={activeTab}
                        lang={lang}
                        id={offerId}
                      />
                    ) : (
                      <DashboardSideBar
                        lang={lang}
                        selectTab={setActiveTab}
                        activeTab={"offer-management"}
                        id={offerId}
                      />
                    )}
                  </div>
                </div>
                <div className='servics_blocks middle-long__section-block middleBlockBg'>
                  {loader ? (
                    <p>{lang?.Loading}</p>
                  ) : (
                    <div className='home-section no-padding sm:mt-2'>
                      <div className='product-title flexBox'>
                        <h4>{lang?.View_offers}</h4>
                        {offerData?.get_incoterm?.incoterms &&
                          isProducerORNot && (
                            <h4 className='transactionPrice textGreen w-90 text-center'>
                              {lang?.Incoterms} -{" "}
                              {offerData?.get_incoterm?.incoterms}
                              <span className='infoIcon cursor pl-2'>
                                <i
                                  class='fa fa-info-circle'
                                  aria-hidden='true'
                                  onClick={() => setIncoTerm(!incoTerm)}
                                ></i>
                              </span>
                            </h4>
                          )}
                      </div>

                     {
                      offerData.length!=0?(
                        <div>
                      <div className='product-list transactionMgmt'>
                        <div className='recentOrder secBlockGap'>
                          <div className='itemTable itemTableScroll customer__table offer__view'>
                            <table>
                              <thead>
                                <tr>
                                  <th>{lang?.Sr_no}</th>
                                  <th>{lang?.Image}</th>
                                  <th className='transactionPrice'>
                                    {lang?.product_keywords}
                                  </th>
                                  <th>{lang?.Unit_price}</th>
                                  <th>{lang?.Quantity}</th>
                                  <th>{lang?.Tax}</th>
                                  <th>{lang?.Total}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {offerData?.get_map_offer?.length > 0 &&
                                  offerData?.get_map_offer?.map(
                                    (
                                      {
                                        imp_notes,
                                        quantity,
                                        product_info,
                                        unit_price,
                                        product_id,
                                      },
                                      i
                                    ) => {
                                      const totalTax = getSingleProductTax(
                                        unit_price,
                                        quantity,
                                        product_info?.get_product_tax,
                                        true
                                      );
                                      const totalPriceWithTAx =
                                        getSingleProductTax(
                                          unit_price,
                                          quantity,
                                          product_info?.get_product_tax,
                                          false
                                        );
                                      return (
                                        <tr key={product_id}>
                                          <td>{i + 1}</td>
                                          <td className=''>
                                            <span>
                                              {product_info?.galleries?.length >
                                              0
                                                ? product_info?.galleries?.map(
                                                    (
                                                      {
                                                        base_url,
                                                        attachment_medium_url,
                                                      },
                                                      i
                                                    ) => {
                                                      if (i < 1)
                                                        return (
                                                          <img
                                                            src={`${base_url}${attachment_medium_url}`}
                                                            alt='Product-Image'
                                                          />
                                                        );
                                                    }
                                                  )
                                                : lang?.N_A}
                                            </span>
                                          </td>
                                          <td className='transactionPrice'>
                                            {product_info?.title ?? lang?.N_A}
                                          </td>
                                          <td>${unit_price}</td>
                                          <td>{quantity}</td>
                                          <td>${totalTax ?? 0}</td>
                                          <td>${totalPriceWithTAx.toFixed(2)}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                              <tbody>
                                <tr className='rating-field'>
                                  <td className='transactionPrice' colSpan={3}>
                                    {lang?.Offer_name} :{" "}
                                    {offerData?.offer_title ?? "N/A"}
                                  </td>
                                  <td colSpan={2}>
                                    {lang?.Shipping_charge} : $
                                    {offerData?.shipping_price}
                                  </td>
                                  <td colSpan={2}>
                                    <p className='text-right finalTotal'>
                                      {lang?.Grand_total}: $
                                      {getTotalPriceOfAllProduct() +
                                        offerData?.shipping_price}
                                    </p>
                                  </td>
                                </tr>
                                {/* {(offerData?.get_incoterm?.incoterms && isProducerORNot) && 
                                                    <tr> 
                                                        <td className='transactionPrice textGreen' colSpan={8}>{lang?.Incoterms} - {offerData?.get_incoterm?.incoterms}</td>
                                                    </tr>
                                                    } */}
                                {isProducerORNot &&
                                  offerData?.order_id !== null &&
                                  offerData?.get_map_offer?.length > 0 && (
                                    <tr>
                                      {" "}
                                      <td colSpan={8}>
                                        {
                                          <h4
                                            style={{
                                              paddingLeft: "410px",
                                              color: "red",
                                            }}
                                          >
                                            {lang?.Offer_userd}
                                          </h4>
                                        }
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                            {offerData?.imp_notes?.length > 0 && (
                              <div className='important__Notes'>
                                <p className='noteHead'>
                                  {lang?.Important_notes}
                                </p>
                                {/* <p>
                                  {textLimit(offerData?.imp_notes, 40) ??
                                    lang?.N_A}
                                </p> */}
                                <p>{offerData?.imp_notes ?? lang?.N_A}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <br />
                      {isProducerORNot &&
                        offerData?.order_id === null &&
                        offerData?.get_map_offer?.length > 0 && (
                          <div>
                            {" "}
                            {!targetDate.isAfter(currentDate) &&
                            offerData?.get_map_offer?.length > 0 ? (
                              <p className='textRed'>{lang?.Offer_exp}</p>
                            ) : (
                              <>
                                {offerData?.get_map_offer?.length > 0 &&
                                cartProducts?.[0]?.map_offer_id?.[0] ==
                                  offerId ? (
                                  <div className='flexBox'>
                                    <Link
                                      to={`/marketplace/my-cart`}
                                      className='btn accept_Btn'
                                    >
                                      {lang?.go_to_cart}{" "}
                                    </Link>
                                  </div>
                                ) : (
                                  offerData?.get_map_offer?.length > 0 && offerData?.stripeConnected &&(
                                    <button
                                      className='btn btnTeal addtocart withIcon mt-4'
                                      disabled={
                                        offerData?.order_id !== null
                                          ? true
                                          : false
                                      }
                                      onClick={addToCartYourOffer}
                                    >
                                      <div className='pretext'>
                                        <svg
                                          width='25'
                                          height='25'
                                          viewBox='0 0 30 30'
                                          fill='none'
                                        >
                                          <path
                                            d='M6.76514 2.49805L2.50244 2.51758L2.51465 5.01758L5.10254 5.00537L6.14258 7.50049H6.25H7.79297V7.50293H14.0479L11.6382 11.3262L10.1123 13.7505H10.1147L7.90039 17.2832C7.40039 18.0832 7.37453 19.0937 7.83203 19.9175C8.28953 20.7412 9.1576 21.2529 10.1001 21.2529H24.9976V18.7529H10.1001L10.0195 18.6064L11.4917 16.2529H20.6494C21.5582 16.2529 22.3945 15.7588 22.8345 14.9663L27.3389 6.8584C27.5551 6.4709 27.5492 6.00066 27.3242 5.61816C27.0992 5.23691 26.6876 5.00293 26.2451 5.00293H7.81006L6.76514 2.49805ZM0 10.0005V12.5005H7.94922L8.00049 12.4248L9.52393 10.0005H0ZM0 15.0005V17.5005H4.7998L6.38672 15.0005H0ZM9.99756 22.5029C9.33452 22.5029 8.69863 22.7663 8.22979 23.2352C7.76095 23.704 7.49756 24.3399 7.49756 25.0029C7.49756 25.666 7.76095 26.3019 8.22979 26.7707C8.69863 27.2395 9.33452 27.5029 9.99756 27.5029C10.6606 27.5029 11.2965 27.2395 11.7653 26.7707C12.2342 26.3019 12.4976 25.666 12.4976 25.0029C12.4976 24.3399 12.2342 23.704 11.7653 23.2352C11.2965 22.7663 10.6606 22.5029 9.99756 22.5029ZM22.4976 22.5029C21.8345 22.5029 21.1986 22.7663 20.7298 23.2352C20.261 23.704 19.9976 24.3399 19.9976 25.0029C19.9976 25.666 20.261 26.3019 20.7298 26.7707C21.1986 27.2395 21.8345 27.5029 22.4976 27.5029C23.1606 27.5029 23.7965 27.2395 24.2653 26.7707C24.7342 26.3019 24.9976 25.666 24.9976 25.0029C24.9976 24.3399 24.7342 23.704 24.2653 23.2352C23.7965 22.7663 23.1606 22.5029 22.4976 22.5029Z'
                                            fill='white'
                                          />
                                        </svg>
                                        {lang?.add_to_cart}
                                      </div>
                                    </button>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      ):(
                        <div class="p-4 mb-4 text-center text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <div class="font-medium ">
                        <p class="font-medium">We are sorry,</p>
                        </div>
                         This offer is no longer exist.
                        </div>
                      )
                     } 
                    </div>
                  )}
                  <br />
                </div>

                {/* Incoterm Poppup */}
                {incoTerm && (
                  <IncoTermModal
                    lang={lang}
                    incoTerm={incoTerm}
                    setIncoTerm={setIncoTerm}
                  />
                )}
              </div>            
            </div>

            <br />
        {/* </div> */}
    </>
  );
}
