import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SettingLeftNavigation from '../../setting/components/SettingLeftNavigation';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import language from '../../../helpers/language';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ChangeEmail from './ChangeEmail';
import REACT_APP_API from '../../../config/environment';
import auth from '../../../helpers/auth';

export default function ResetEmail() {
  const [otp, setOtp] = useState();
  const history = useHistory();
  const [isEmailAndOtpTrue, setIsEmailAndOtpTrue] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [lang] = useState(language.getLang());
  const [confirmEmail, setConfirmEmail] = useState('');
  const [error, setError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(async () => {
    //setVerifyOtp(true);
  }, []);

  const changeEmail = async (event) => {
    event.preventDefault();
    if (email != confirmEmail) {
      setError(lang?.confirm_email_mismatched);
      return true;
    }

    let token = await auth.getAccessToken();
    let obj = { email: email, device_type: 'web' };
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/change/email/request`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          locale: language.getLang()?.lan,
        },
        body: JSON.stringify(obj),
      }
    );
    let response = await result.json();
    if (response.success === 200 && response.status == true) {
      setVerifyOtp(true);
      setError('');
    } else if (response.success == 422) {
      setError(response.errors);
    } else {
      setError(response.message);
    }
  };

  const verifyOtpMethod = async (event) => {
    event.preventDefault();
    await setIsEmailAndOtpTrue(true);
    let token = await auth.getAccessToken();
    let obj = { otp: otp };
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/verify/email/request/otp`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          locale: language.getLang()?.lan,
        },
        body: JSON.stringify(obj),
      }
    );
    let response = await result.json();
    if (response.success === 200 && response.status == true) {
      setVerifyOtp(false);
      setOtpError('');
      setSuccessMessage(response.message);
      await setEmail('');
      await setConfirmEmail('');
      await setIsEmailAndOtpTrue(false);
      setTimeout(() => {
        history.push('/setting');
      }, 1500)
    } else if (response.success == 422) {
      setOtpError(response.errors);
      await setIsEmailAndOtpTrue(false);
    } else {
      setOtpError(response.message);
      await setIsEmailAndOtpTrue(false);
    }
  };

  const resendOtp = async () => {
    let token = await auth.getAccessToken();
    const result = await fetch(
      `${REACT_APP_API.ENV.REACT_APP_API}/resend/email/request/otp`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          locale: language.getLang()?.lan,
        },
      }
    );
    let response = await result.json();
    if (response.success === 200 && response.status == true) {
      setOtpError('');
      setSuccessMessage(lang?.OtpSendSuccessfull);
    } else if (response.success == 422) {
      setOtpError(response.errors);
    } else {
      setOtpError(response.message);
    }
  };
  return (
    <>
      <div className="home__page b2b settings">
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title">
                <h1 className="font-18 text-black">{lang?.change_email}</h1>
              </div>
              <div className="md:w-3/4 mt-6">
                <form onSubmit={(e) => changeEmail(e)}>
                  <h4 className="font-16 text-normal mb-4">
                    {lang?.change_email_text}
                  </h4>
                  <div className="md:w-2/4">
                    <div className="form__item">
                      <label className="font-16 text-normal">
                        {lang?.New} {lang?.Email}
                      </label>
                      <input
                        type="email"
                        value={email}
                        placeholder={lang?.enter_new_email_text}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form__item">
                      <label className="font-16 text-normal">
                        {lang?.Confirm} Email
                      </label>
                      <input
                        type="email"
                        value={confirmEmail}
                        placeholder={lang?.confirm_your_email}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        required
                      />
                      <div className="error">
                        <p style={{ color: 'red' }}>{error}</p>
                      </div>
                    </div>
                  </div>
                  <div className="btnBox">
                    <button
                      className="btn btnPrimary"
                      //   onClick={() => {
                      //     setVerifyOtp(!verifyOtp);
                      //     setValidEmail('');
                      //   }}
                      type="submit"
                    >
                      {lang?.only_Submit}
                    </button>
                    <div className="reset text-center ">
                      <h5
                        className="heading--w text-Red"
                        style={{ color: 'green' }}
                      >
                        {successMessage}
                      </h5>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Verify Email Popup Start */}
      {verifyOtp && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={verifyOtp}
            // isOpen={verifyOtp}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
              },
              content: {
                position: 'absolute',
                top: '0',
                left: '0%',
                right: '0%',
                bottom: '200px',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                padding: '0',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Alysei}</h4>
              <button
                className="close_btn--modal"
                onClick={() => {
                  setVerifyOtp(!verifyOtp);
                  setValidEmail('');
                }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-blue text-center mb-8">
                {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{' '}
                <strong>{email}</strong>
                <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
              </p>
              <p className="text-red-500">{validEmail}</p>
              <form
                className="mb-2"
                onSubmit={(event) => verifyOtpMethod(event)}
              >
                <div className="form__action otp">
                  <OtpInput
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    value={otp}
                  />
                  <div className="error">
                    <p style={{ color: 'red' }}>{otpError}</p>
                  </div>
                  <button type="submit" className="btn w-full mt-6 mb-6">
                    {isEmailAndOtpTrue ? <FormSpinner /> : 'Verify OTP'}
                  </button>
                  <p>
                    {lang?.Didnot_receive_the_OTP}{' '}
                    <Link
                      to="#"
                      className="title-color font-semibold"
                      onClick={() => resendOtp()}
                    >
                      {lang?.Resend_OTP}
                    </Link>
                  </p>
                  <div className="reset text-center ">
                    <h5
                      className="heading--w text-Red"
                      style={{ color: 'green' }}
                    >
                      {successMessage}
                    </h5>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      )}
      {/* Verify Email Popup End */}
    </>
  );
}
