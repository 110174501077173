import React, { useState, useEffect, useRef } from 'react';
import default_Image from '../../../assets/images/default_image.jpg';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ReactPaginate from 'react-paginate';
import ReactStars from 'react-rating-stars-component';
import { Multiselect } from 'multiselect-react-dropdown';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import {
  getProductCategories,
  getProductProperties,
  getConservationMethod,
  searchProducts,
  getItalianRegion,
  setSearchProductData,
  setTopBanners,
  getProductPropertiesAndConservation,
} from '../modules/marketplace';
import auth from '../../../helpers/auth';
import constant from '../../../helpers/constant';
import language from '../../../helpers/language';
import { set } from 'lodash';

export default function SearchProduct(props) {
  const [searchProductCategories, setSearchProductCategories] = useState();
  const [searchProductProperties, setSearchProductProperties] = useState();
  const [searchConservationMethods, setSearchConservationMethods] = useState();
  const [searchItalianRegion, setSearchItalianRegion] = useState();
  const [searchFdaData, setSearchFdaData] = useState([]);
  const [lang] = useState(language.getLang());
  const [productTabButton, setProductTabButton] = useState('active-button');
  const [storeTabButton, setStoreTabButton] = useState('');
  const [productTab, setProductTab] = useState('active-tab');
  const [storeTab, setStoreTab] = useState('');
  const [activeTab, setActiveTab] = useState('product');
  const dispatch = useDispatch();
  const myProductTypeRef = useRef(null);
  const myConservationRef = useRef(null);
  const myItalianRegionRef = useRef(null);
  const myProductPropertyRef = useRef(null);
  const myFDACertificateRef = useRef(null);
  const history = useHistory();
  const [productKeyword, setProductKeyword] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const [user, setUser] = useState([]);
  const [selectedRegionsArray, setSelectedRegionsArray] = useState([]);
  const [selectedcategoryArray, setSelectedCategoryArray] = useState([]);
  const [selectedMethodArray, setSelectedMethodArray] = useState([]);
  const [selectedpropertyArray, setSelectedPropertyArray] = useState([]);
  const [fdaCertifiedStatus, setFdaCertified] = useState([
    { key: lang?.yes, value: '1' },
    { key: lang?.no, value: '0' },
  ]);
  const [selectedFDAValues, setSelectedFDAValues] = useState([]);
  const [conservationPropertyDisabled, setConservationPropertyDisabled] =
    useState(true);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const category = getParameterByName('category');
    const method = getParameterByName('method');
    const property = getParameterByName('property');
    const region = getParameterByName('region');
    const selectedTab = getParameterByName('tab');
    const keyword = getParameterByName('keyword');
    const fda_certified = getParameterByName('fda_certified');
    const user = await auth.getUserType();
    await setUser(JSON.parse(user));
    await setProductKeyword(keyword);
    const box_id = 2;
    let type = 1;
    if (selectedTab === 'store') {
      await setProductTabButton('');
      await setStoreTabButton('active-button');
      await setProductTab('');
      await setStoreTab('active-tab');
      await setActiveTab('store');
      type = 1;
    } else {
      await setProductTabButton('active-button');
      await setStoreTabButton('');
      await setProductTab('active-tab');
      await setStoreTab('');
      await setActiveTab('product');
      type = 2;
    }

    const searchData = {
      category,
      method,
      property,
      region,
      keyword,
      box_id,
      type,
      fda_certified,
    };
    // await dispatch(searchProducts(searchData));
    productCategories &&
      productCategories.length === 0 &&
      (await dispatch(getProductCategories()));
    productProperties &&
      productProperties.length === 0 &&
      (await dispatch(getProductProperties(category)));
    conservationMethods &&
      conservationMethods.length === 0 &&
      (await dispatch(getConservationMethod(category)));
    italianRegions &&
      italianRegions.length === 0 &&
      (await dispatch(getItalianRegion()));

    changeTabs(selectedTab);
  }, []);

  const changeTabs = async (selectedTab) => {
    const category = getParameterByName('category');
    const method = getParameterByName('method');
    const property = getParameterByName('property');
    const region = getParameterByName('region');
    const keyword = getParameterByName('keyword');
    const fda_certified = getParameterByName('fda_certified');
    if (selectedTab === 'store') {
      const searchStoreData = {
        category,
        method,
        property,
        region,
        box_id: 2,
        type: 1,
        fda_certified,
      };
      await dispatch(searchProducts(searchStoreData));
    } else {
      const searchProductData = {
        category,
        method,
        property,
        region,
        keyword,
        box_id: 2,
        type: 2,
      };
      await dispatch(searchProducts(searchProductData));
    }
  };

  let {
    productCategories,
    productProperties,
    conservationMethods,
    conservationMethodLabel,
    productPropertiesLabel,
    searchProductData,
    italianRegions,
    marketPlaceSpinner,
  } = useSelector((state) => state.marketPlaceReducerData);

  useEffect(async () => {
    const fda_certified = getParameterByName('fda_certified');
    const category = getParameterByName('category');
    const method = getParameterByName('method');
    const property = getParameterByName('property');
    const region = getParameterByName('region');
    await setSearchFdaData(fda_certified);
    if (fda_certified != undefined && fda_certified != null) {
      await setSelectedFDAValues([
        {
          key: fda_certified === '1' ? lang?.yes : lang?.no,
          value: fda_certified === 1 ? '1' : '0',
        },
      ]);
    } else {
      document.getElementById('fda__certificate_input').placeholder =
        lang?.Select;
    }

    if (category !== undefined && category !== null) {
      setSearchProductCategories(category);
      var categoryData = productCategories.find(function (element) {
        return element.marketplace_product_category_id === parseInt(category);
      });
      if (categoryData) {
        await setSelectedCategoryArray([
          {
            name: categoryData?.name,
            marketplace_product_category_id:
              categoryData?.marketplace_product_category_id,
          },
        ]);
      }
      if (productCategories.length === 0) {
        await dispatch(getProductPropertiesAndConservation(category));
      }
      await setConservationPropertyDisabled(false);
    } else {
      document.getElementById('product_categories_input').placeholder =
        lang?.Select;
    }
    if (property !== undefined && property !== null) {
      const property = getParameterByName('property');
      const propertyArr = property && property.split(',');
      let selectedpropertyArr = [];
      let selectedpropertyText = [];
      propertyArr &&
        productProperties.map(function (propertyName) {
          propertyArr.map(function (option) {
            if (propertyName.user_field_option_id == option) {
              selectedpropertyText.push(propertyName.option);
              selectedpropertyArr.push(propertyName);
            }
          });
        });
      setSelectedPropertyArray(selectedpropertyArr);
      selectedpropertyText && setSearchProductProperties(propertyArr.join());
      if (selectedpropertyText.length > 1) {
        document.getElementById('product_properties_input').placeholder =
          selectedpropertyText[0] +
          ' +' +
          (selectedpropertyText.length - 1) +
          ' more';
      } else if (selectedpropertyText.length == 1) {
        document.getElementById('product_properties_input').placeholder =
          selectedpropertyText[0];
      } else {
        document.getElementById('product_properties_input').placeholder =
          lang?.Select;
      }
    }
    if (method !== undefined && method !== null) {
      const method = getParameterByName('method');
      const methodArr = method && method.split(',');
      let selectedMethodArr = [];
      methodArr &&
        conservationMethods.map(function (method) {
          methodArr.map(function (methodName) {
            if (method.user_field_option_id == methodName) {
              selectedMethodArr.push(method);
            }
          });
        });
      setSelectedMethodArray(selectedMethodArr);

      methodArr && setSearchConservationMethods(methodArr.join());
      if (selectedMethodArr.length > 1) {
        document.getElementById('conservation_method_input').placeholder =
          selectedMethodArr[0].option +
          ' +' +
          (selectedMethodArr.length - 1) +
          ' more';
      } else if (selectedMethodArr.length == 1) {
        document.getElementById('conservation_method_input').placeholder =
          selectedMethodArr[0].option;
      } else {
        document.getElementById('conservation_method_input').placeholder =
          lang?.Select;
      }
    }

    if (region !== undefined && region !== null) {
      const regionString = getParameterByName('region');
      if (regionString && regionString.length > 0) {
        const regionsArr = regionString && regionString.split(',');
        let selectedArr = [];
        regionsArr &&
          italianRegions.map(function (region) {
            regionsArr.map(function (id) {
              if (id == region.id) {
                selectedArr.push(region);
              }
            });
          });
        await setSelectedRegionsArray(selectedArr);
        regionsArr.length && setSearchItalianRegion(regionsArr.join());
        if (selectedArr.length > 1) {
          document.getElementById('region_input').placeholder =
            selectedArr[0].name + ' +' + (selectedArr.length - 1) + ' more';
        } else if (selectedArr.length == 1) {
          document.getElementById('region_input').placeholder =
            selectedArr[0].name;
        } else {
          document.getElementById('region_input').placeholder = lang?.Select;
        }
      }
    }
  }, [italianRegions]);

  const changeProductCategoris = async (e) => {
    await setSearchProductCategories(e[0].marketplace_product_category_id);
    await setSelectedCategoryArray(e);
    await dispatch(
      getProductPropertiesAndConservation(e[0].marketplace_product_category_id)
    );
    await setConservationPropertyDisabled(false);
    setSearchProductProperties('');
    setSearchConservationMethods('');
    setSelectedMethodArray([]);
    setSelectedPropertyArray([]);
    document.getElementById('conservation_method_input').placeholder =
      lang?.Select;
    document.getElementById('product_properties_input').placeholder =
      lang?.Select;
  };

  const changeConservationMethods = (
    conservationMethodSelectedList,
    selectedItem
  ) => {
    if (conservationMethodSelectedList.length > 1) {
      document.getElementById('conservation_method_input').placeholder =
        conservationMethodSelectedList[0].option +
        ' +' +
        (conservationMethodSelectedList.length - 1) +
        ' more';
    } else if (conservationMethodSelectedList.length == 1) {
      document.getElementById('conservation_method_input').placeholder =
        conservationMethodSelectedList[0].option;
    } else {
      document.getElementById('conservation_method_input').placeholder =
        lang?.Select;
    }

    let data = conservationMethodSelectedList.map((item, i) => {
      return item.user_field_option_id;
    });

    if (data.length > 0) {
      setSearchConservationMethods(data.join());
    } else {
      setSearchConservationMethods('');
    }
  };
  const changeProductProperties = (
    productCategorySelectedList,
    selectedItem
  ) => {
    if (productCategorySelectedList.length > 1) {
      document.getElementById('product_properties_input').placeholder =
        productCategorySelectedList[0].option +
        ' +' +
        (productCategorySelectedList.length - 1) +
        ' more';
    } else if (productCategorySelectedList.length == 1) {
      document.getElementById('product_properties_input').placeholder =
        productCategorySelectedList[0].option;
    } else {
      document.getElementById('product_properties_input').placeholder =
        lang?.Select;
    }

    let data = productCategorySelectedList.map((item, i) => {
      return item.user_field_option_id;
    });

    if (data.length > 0) {
      setSearchProductProperties(data.join());
    } else {
      setSearchProductProperties('');
    }
  };

  const changeItalianRegions = (italianRegionSelectedList) => {
    if (italianRegionSelectedList.length > 1) {
      document.getElementById('region_input').placeholder =
        italianRegionSelectedList[0].name +
        ' +' +
        (italianRegionSelectedList.length - 1) +
        ' more';
    } else if (italianRegionSelectedList.length == 1) {
      document.getElementById('region_input').placeholder =
        italianRegionSelectedList[0].name;
    } else {
      document.getElementById('region_input').placeholder = lang?.Select;
    }

    let data = italianRegionSelectedList.map((item, i) => {
      return item.id;
    });

    if (data.length > 0) {
      setSearchItalianRegion(data.join());
    } else {
      setSearchItalianRegion('');
    }
  };

  // const changeFda = (e) => {
  //   setSearchFdaData(e.target.value);
  // };

  const handleSearch = async () => {
    let type = 1;
    if (activeTab === 'store') {
      type = 1;
    } else {
      type = 2;
    }

    if (searchProductCategories === undefined) {
      setSearchProductCategories('');
    }

    if (searchProductProperties === undefined) {
      setSearchProductProperties('');
    }

    if (searchConservationMethods === undefined) {
      setSearchConservationMethods('');
    }

    if (searchFdaData == -1) {
      setSearchFdaData('');
    }

    if (searchItalianRegion === undefined) {
      setSearchItalianRegion('');
    }
    // const searchStoreData = {
    //     property: searchProductProperties,
    //     category: searchProductCategories,
    //     method: searchConservationMethods,
    //     region: searchItalianRegion,
    //     box_id: 2,
    //     type
    // };
    const searchData = {
      property: searchProductProperties,
      category: searchProductCategories,
      method: searchConservationMethods,
      fda_certified: searchFdaData,
      region: searchItalianRegion,
      keyword: productKeyword,
      box_id: 2,
      type,
    };
    (searchFdaData?.length > 0 ||
      searchProductCategories ||
      searchConservationMethods ||
      searchProductProperties ||
      (searchItalianRegion && searchItalianRegion.length > 0) ||
      productKeyword.length > 0) &&
      (await dispatch(searchProducts(searchData)));
  };

  const handleClearCheckBox = async () => {
    if (activeTab == 'store') {
      myConservationRef.current.resetSelectedValues();
      myProductPropertyRef.current.resetSelectedValues();
      myFDACertificateRef.current.resetSelectedValues();
      myProductTypeRef.current.resetSelectedValues();
      myItalianRegionRef.current.resetSelectedValues();
    } else {
      myConservationRef.current.resetSelectedValues();
      myProductPropertyRef.current.resetSelectedValues();
      myProductTypeRef.current.resetSelectedValues();
      myItalianRegionRef.current.resetSelectedValues();
    }
    document.getElementById('conservation_method_input').placeholder =
        lang?.select;
    document.getElementById('product_properties_input').placeholder =
        lang?.select;
    document.getElementById('region_input').placeholder =
        lang?.select;
    setProductKeyword('');
    setSearchProductCategories('');
    setSearchConservationMethods('');
    setSearchProductProperties('');
    setSearchFdaData([]);
    setSearchItalianRegion('');
    setConservationPropertyDisabled(true);
  };
  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const selectTab = (tab) => {
    if (tab === 'product') {
      handleClearCheckBox();
      setProductTabButton('active-button');
      setStoreTabButton('');
      setProductTab('active-tab');
      setStoreTab('');
      setActiveTab('product');
    } else {
      handleClearCheckBox();
      setProductTabButton('');
      setStoreTabButton('active-button');
      setProductTab('');
      setStoreTab('active-tab');
      setActiveTab('store');
    }

    changeTabs(tab);
  };
  const handlePageClick = async (e) => {
    let type = 1;
    if (activeTab === 'store') {
      type = 1;
    } else {
      type = 2;
    }
    const selectedPage = e.selected + 1;
    setPageNo(selectedPage);
    const pagechangeData = {
      category: searchProductCategories,
      property: searchProductProperties,
      method: searchConservationMethods,
      fda_certified: searchFdaData,
      region: searchItalianRegion,
      keyword: productKeyword,
      box_id: 2,
      type,
      pageNo: selectedPage,
    };
    await dispatch(searchProducts(pagechangeData));
  };

  const handleSortFilter = async (event) => {
    let sortValue = event.target.value;
    if (activeTab === 'store') {
      const filterStoreData = {
        category: searchProductCategories,
        property: searchProductProperties,
        method: searchConservationMethods,
        region: searchItalianRegion,
        box_id: 2,
        type: 1,
        pageNo,
        sort_by_producer: sortValue,
      };
      sortValue > 0 && (await dispatch(searchProducts(filterStoreData)));
    } else {
      const filterProductData = {
        category: searchProductCategories,
        property: searchProductProperties,
        method: searchConservationMethods,
        fda_certified: searchFdaData,
        region: searchItalianRegion,
        keyword: productKeyword,
        box_id: 2,
        type: 2,
        pageNo,
        sort_by_product: sortValue,
      };
      sortValue > 0 && (await dispatch(searchProducts(filterProductData)));
    }
  };

  const changeFDACertificate = (e) => {
    if (searchFdaData != '') {
    }
    setSearchFdaData(e[0].value);
  };
  return (
    <div className="marketplace producerScore mrkt-place-three product--seacrh">
      {/* Banner Section */}
      <div className="main__content">
        <div className="section__wrapper">
          <div className>
            <div className>
              <div className="site__banner banner-wrapper">
                <div className>
                  <div className="">
                    <div
                      className="flex justify-between items-center mb-3"
                      onClick={() => history.goBack()}
                    >
                      <a href="javascript:void(0)" className="back__btn">
                        <i className="fa fa-arrow-left" aria-hidden="true" />
                        &nbsp;
                        {lang?.Go_back}
                      </a>
                    </div>
                    <div className="banner__form">
                      <div className="tabs middle-long__section-block">
                        <div className="tabs__wrapper">
                          <span
                            tab={1}
                            className={`btn ${productTabButton}`}
                            onClick={() => selectTab('product')}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24.506"
                              height="26.527"
                              viewBox="0 0 24.506 26.527"
                            >
                              <defs>
                                <style
                                  dangerouslySetInnerHTML={{
                                    __html: '.a{fill:#37a282;}',
                                  }}
                                />
                              </defs>
                              <path
                                className="a"
                                d="M10.8,6A1.529,1.529,0,0,0,9.34,7.066L8.473,9.78c-.031.1-.066.209-.1.311a1.531,1.531,0,0,0-.725,2.781,30.929,30.929,0,0,0,.006,12.783,1.528,1.528,0,0,0,.719,2.781c.031.1.061.209.1.311l.867,2.714a1.529,1.529,0,0,0,1.46,1.066H27.674a1.529,1.529,0,0,0,1.46-1.066L30,28.747c.036-.1.066-.209.1-.311a1.528,1.528,0,0,0,.719-2.781,30.929,30.929,0,0,0,.006-12.783,1.531,1.531,0,0,0-.725-2.781c-.031-.1-.066-.209-.1-.311l-.867-2.714A1.529,1.529,0,0,0,27.674,6Zm0,1.02h2.994L13.34,9.857c-.01.077-.025.148-.03.224H9.447l.867-2.7A.5.5,0,0,1,10.8,7.02Zm4.024,0h3.9v3.061h-4.39a.32.32,0,0,1,.01-.067Zm4.923,0h3.9l.479,2.994a.32.32,0,0,0,.01.067H19.747Zm4.933,0h2.994a.5.5,0,0,1,.485.357l.867,2.7H25.164c0-.077-.02-.148-.03-.224ZM8.524,11.1H29.95a.51.51,0,1,1,0,1.02H8.524a.51.51,0,1,1,0-1.02Zm.113,2.041h4.279a61.126,61.126,0,0,0-.133,10.8.511.511,0,0,0,.51.469h.036a.505.505,0,0,0,.469-.546,60.747,60.747,0,0,1,.142-10.728h4.786V25.385H8.636a30,30,0,0,1,0-12.243Zm11.111,0h10.09a30,30,0,0,1,0,12.243H25.563a60.347,60.347,0,0,0,.28-7.825.507.507,0,0,0-.5-.5h-.016a.51.51,0,0,0-.5.526,58.982,58.982,0,0,1-.285,7.8h-4.8Zm5.389.9a.51.51,0,0,0-.469.548c.029.369.053.738.076,1.108a.51.51,0,0,0,.508.479h.032a.509.509,0,0,0,.478-.539q-.034-.564-.077-1.126a.513.513,0,0,0-.549-.473ZM9.8,14.672a.51.51,0,1,0,0,1.02h1.02a.51.51,0,1,0,0-1.02Zm-.255,2.551a.51.51,0,0,0,0,1.02h1.02a.51.51,0,1,0,0-1.02Zm0,2.551a.51.51,0,0,0,0,1.02h1.02a.51.51,0,1,0,0-1.02ZM9.8,22.325a.51.51,0,0,0,0,1.02h1.02a.51.51,0,1,0,0-1.02ZM8.524,26.406H29.95a.51.51,0,1,1,0,1.02H8.524a.51.51,0,1,1,0-1.02Zm.924,2.041H13.3c.01.077.026.148.036.224l.454,2.837H10.8a.5.5,0,0,1-.485-.357Zm4.887,0h4.392v3.061h-3.9l-.479-2.994a.547.547,0,0,1-.014-.068Zm5.412,0h4.392a.32.32,0,0,1-.01.067l-.479,2.994h-3.9Zm5.423,0h3.856l-.867,2.7a.5.5,0,0,1-.485.357H24.68l.454-2.837c.01-.073.026-.144.036-.221Z"
                                transform="translate(-6.983 -6)"
                              />
                            </svg>
                            <em>{lang?.Products}</em>
                          </span>
                          <span
                            tab={2}
                            className={`btn ${storeTabButton}`}
                            onClick={() => selectTab('store')}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="27.907"
                              height="26.641"
                              viewBox="0 0 27.907 26.641"
                            >
                              <defs>
                                <style
                                  dangerouslySetInnerHTML={{
                                    __html: '.a{fill:#37a282;}',
                                  }}
                                />
                              </defs>
                              <path
                                className="a"
                                d="M5.537,4a1.912,1.912,0,0,0-1.9,1.9V8.383A31.848,31.848,0,0,0,3,13.2a4.129,4.129,0,0,0,.632,2.379h0V28.732a1.912,1.912,0,0,0,1.9,1.9h1.8a.634.634,0,0,0,.207,0h5.5a.634.634,0,0,0,.207,0h15.12a1.912,1.912,0,0,0,1.9-1.9V15.578h0A4.129,4.129,0,0,0,30.9,13.2a31.854,31.854,0,0,0-.634-4.812V5.9a1.912,1.912,0,0,0-1.9-1.9Zm0,1.268H28.366A.624.624,0,0,1,29,5.9V7.8H21.488a.574.574,0,0,0-.2,0h-4.24a.574.574,0,0,0-.2,0H12.61a.574.574,0,0,0-.2,0H4.9V5.9a.624.624,0,0,1,.637-.632Zm.634.634a.631.631,0,1,0,.449.184A.634.634,0,0,0,6.171,5.9Zm1.9,0a.634.634,0,1,0,.634.634A.634.634,0,0,0,8.071,5.9Zm1.9,0a.487.487,0,1,0,.005,0ZM4.822,9.073H7.439v5.635a2.164,2.164,0,0,1-1.585.706,1.253,1.253,0,0,1-1.179-.564,3,3,0,0,1-.407-1.65,28.884,28.884,0,0,1,.554-4.127Zm3.885,0h3.171v5.635a2.137,2.137,0,0,1-3.171,0Zm4.439,0h3.171v5.635a2.137,2.137,0,0,1-3.171,0Zm4.439,0h3.171v5.635a2.137,2.137,0,0,1-3.171,0Zm4.439,0H25.2v5.639a2.141,2.141,0,0,1-3.171,0Zm4.439,0H29.08a28.892,28.892,0,0,1,.554,4.122,3,3,0,0,1-.406,1.656,1.253,1.253,0,0,1-1.179.564,2.169,2.169,0,0,1-1.585-.7ZM8.073,15.867a3.426,3.426,0,0,0,4.439,0,3.426,3.426,0,0,0,4.439,0,3.428,3.428,0,0,0,4.439,0,3.43,3.43,0,0,0,4.439,0,3.45,3.45,0,0,0,2.22.815,2.889,2.889,0,0,0,.951-.16v12.21a.624.624,0,0,1-.634.634H13.781V18.585H6.8V29.366H5.537a.624.624,0,0,1-.634-.634V16.523a2.889,2.889,0,0,0,.951.16,3.448,3.448,0,0,0,2.219-.816Zm7.61,2.719v8.878H27.1V18.585Zm-7.61,1.268h4.439v9.512H8.073Zm8.878,0h8.878V26.2H16.951Z"
                                transform="translate(-2.996 -4)"
                              />
                            </svg>
                            <em>{lang?.Producer_Store}</em>
                          </span>
                        </div>
                        <div className={`tab home-section active-tab`}>
                          {/* <!-- Tab Content Left Section --> */}
                          <div className="tab-content-left-section">
                            <div className="banner__form">
                              <form>
                                <div className="flex items-center banner-form-bg">
                                  {activeTab === 'product' && (
                                    <div className="col-w-8">
                                      <div className="form__item">
                                        <label for="">
                                          {lang?.Product_Title}
                                        </label>
                                        <input
                                          type="text"
                                          placeholder={lang?.product_keywords}
                                          value={productKeyword}
                                          id=""
                                          onChange={(e) =>
                                            setProductKeyword(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-w-8">
                                    <div className="form__item relative">
                                      <label>{lang?.Product_Type}</label>
                                      {/* <Multiselect
                                        options={productCategories}
                                        onSelect={changeProductCategoris}
                                        onRemove={changeProductCategoris}
                                        selectedValues={selectedcategoryArray}
                                        displayValue="name"
                                        placeholder={lang?.Select}
                                        showCheckbox="true"
                                        id="product_categories"
                                        showArrow={true}
                                        closeOnSelect={false}
                                        ref={myProductTypeRef}
                                      /> */}
                                      <Multiselect
                                        options={productCategories}
                                        displayValue="name"
                                        onSelect={changeProductCategoris}
                                        selectedValues={selectedcategoryArray}
                                        placeholder={lang?.select}
                                        id={'fda__certificate'}
                                        singleSelect
                                        ref={myProductTypeRef}
                                      />
                                      {/* <span className='selectDownArrow'><i className="fa fa-angle-down" aria-hidden="true"></i></span> */}
                                    </div>
                                  </div>
                                  <div className="col-w-8">
                                    <div className="form__item relative">
                                      <label>
                                        {conservationMethodLabel != ''
                                          ? conservationMethodLabel
                                          : lang?.Conservation_Method}
                                      </label>
                                      <Multiselect
                                        options={conservationMethods}
                                        onSelect={changeConservationMethods}
                                        onRemove={changeConservationMethods}
                                        selectedValues={selectedMethodArray}
                                        displayValue="option"
                                        showCheckbox="true"
                                        placeholder={lang?.Select}
                                        id="conservation_method"
                                        showArrow={true}
                                        closeOnSelect={false}
                                        ref={myConservationRef}
                                        disable={conservationPropertyDisabled}
                                      />
                                      {/* <span className='selectDownArrow'><i className="fa fa-angle-down" aria-hidden="true"></i></span> */}
                                    </div>
                                  </div>
                                  <div className="col-w-8">
                                    <div className="form__item relative">
                                      <label>
                                        {productPropertiesLabel != ''
                                          ? productPropertiesLabel
                                          : lang?.Product_Properties}
                                      </label>
                                      <Multiselect
                                        options={productProperties}
                                        onSelect={changeProductProperties}
                                        onRemove={changeProductProperties}
                                        selectedValues={selectedpropertyArray}
                                        displayValue="option"
                                        placeholder={lang?.Select}
                                        showCheckbox="true"
                                        id="product_properties"
                                        showArrow={true}
                                        closeOnSelect={false}
                                        ref={myProductPropertyRef}
                                        disable={conservationPropertyDisabled}
                                      />
                                      {/* <span className='selectDownArrow'><i className="fa fa-angle-down" aria-hidden="true"></i></span> */}
                                    </div>
                                  </div>
                                  {activeTab !== 'product' && (
                                    <div className="col-w-8">
                                      <div className="form__item">
                                        <label>{lang?.FDA_Certification}</label>
                                        <Multiselect
                                          options={fdaCertifiedStatus}
                                          displayValue="key"
                                          onSelect={changeFDACertificate}
                                          id={'fda__certificate'}
                                          selectedValues={selectedFDAValues}
                                          placeholder={lang?.Select}
                                          singleSelect
                                          ref={myFDACertificateRef}
                                        />
                                        {/* <select
                                          id="hor"
                                          onChange={changeFda}
                                          value={searchFdaData}
                                          className="searchBox"
                                        >
                                          <option value="-1">
                                            {lang?.Select}
                                          </option>
                                          <option value="1">{lang?.yes}</option>
                                          <option value="0">{lang?.no}</option>
                                        </select> */}
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-w-8">
                                    <div className="form__item">
                                      <label>{lang?.Italian_Region}</label>
                                      <Multiselect
                                        options={italianRegions}
                                        onSelect={changeItalianRegions}
                                        onRemove={changeItalianRegions}
                                        selectedValues={selectedRegionsArray}
                                        displayValue="name"
                                        showCheckbox="true"
                                        placeholder={lang?.Select}
                                        id="region"
                                        closeOnSelect={false}
                                        ref={myItalianRegionRef}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-w-8">
                                    <div className="btn-blue-bg">
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={() => {
                                          handleSearch();
                                        }}
                                      >
                                        {lang?.Search}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="reset text-left pl-2 pb-4">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => handleClearCheckBox()}
                                  >
                                    {lang?.Reset_Filter}
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <div className="product-main">
                <div className="product-type pt-5">
                  <div className="flex justify-between">
                    <h4 className="product-title black mt-0">
                      <span className="">
                        {lang?.Showing}{' '}
                        {searchProductData &&
                          searchProductData.total &&
                          searchProductData.total}{' '}
                        {lang?.results}
                      </span>
                    </h4>
                    {/* <div className="flex form__item items-center">
                                            <span className="ml-5">{lang?.Sort_by}</span>
                                            <div className="select--field ml-5">
                                                <select id="meal" onChange={(event) => handleSortFilter(event)}>
                                                    <option value="1"> {lang?.Most_Rated_Stores}</option>
                                                    <option value="2">{5} {lang?.Star_Stores}</option>
                                                    <option value="3">{lang?.Most_Searched}</option>
                                                </select>
                                            </div>
                                        </div> */}
                  </div>

                  {/*Put the scroll bar always on the bottom*/}
                  <div id="scrollableDiv">
                    {marketPlaceSpinner ? (
                      <FormSpinner />
                    ) : searchProductData?.data?.length > 0 ? (
                      searchProductData?.data?.length > 0 &&
                      searchProductData?.data?.map((item) => {
                        let region_name = italianRegions
                          .filter((items) => items.id == item.store_region)
                          .map((item) => item.name);
                        return (
                          <>
                            {activeTab == 'store' ? (
                              <div
                                className="flex mt-2 mb-8"
                                key={item.marketplace_store_id}
                              >
                                <div className="md:w-1/3 sm:w-full">
                                  <div className="col-w-left xl:pr-3 lg:pr-3 md:pr-0 sm:pr-0">
                                    <div className="product-img">
                                      <Link
                                        to={`/marketplace/added-store/${item.marketplace_store_id}`}
                                      >
                                        <img
                                          src={`${item.logo_base_url}${item.logo_id}`}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="md:w-2/3 sm:w-full">
                                  <div className="col-w-right pl-3 xl:pr-3 lg:pr-3 md:pr-0 sm:pr-0">
                                    <div className="flex flex-wrap justify-between">
                                      <h4 className="font-18 black mt-0">
                                        {item.name}
                                      </h4>
                                    </div>
                                    <p className="mt-2 mb-2 parahideMob">
                                      {item.description}{' '}
                                    </p>
                                    <h4 className="font-16">{region_name}</h4>
                                    <div className="flex justify-between mt-2">
                                      <p className="rating">
                                        <span className>
                                          {item.avg_rating}{' '}
                                          <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                          />
                                        </span>
                                        &nbsp;{item.total_reviews}{' '}
                                        {item.total_reviews <= 1
                                          ? lang?.review
                                          : lang?.reviews}
                                      </p>
                                    </div>
                                    <h4 className="count_no font-16 text-teal mt-2">
                                      {item.product_category_name}
                                      {item?.count_product > 1 && (
                                        <span className="more_count">
                                          {' '}
                                          +{item.count_product - 1} more
                                        </span>
                                      )}
                                    </h4>
                                    {/*<div className="store-name flex justify-between">
                                                                        <p>
                                                                            <strong>{item.store_name}</strong>
                                                                        </p>
                                                                       
                                                                    </div>*/}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="flex mt-2 mb-8">
                                {' '}
                                <div
                                  className="md:w-1/3 sm:w-full"
                                  key={item.marketplace_product_id}
                                >
                                  <div className="col-w-left xl:pr-3 lg:pr-3 md:pr-0 sm:pr-0">
                                    <div className="product-img">
                                      <Link
                                        to={`/marketplace/product/${item.slug}`}
                                      >
                                        {item.product_gallery &&
                                        item.product_gallery.length > 0 ? (
                                          <img
                                            src={`${
                                              item.product_gallery &&
                                              item.product_gallery[0].base_url
                                            }${
                                              item.product_gallery &&
                                              item.product_gallery[0]
                                                .attachment_url
                                            }`}
                                            alt="Image"
                                          />
                                        ) : (
                                          <img
                                            src={default_Image}
                                            alt="default-Image"
                                          />
                                        )}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="md:w-2/3 sm:w-full">
                                  <div className="col-w-right pl-3 xl:pr-3 lg:pr-3 md:pr-0 sm:pr-0">
                                    <div className="flex flex-wrap justify-between">
                                      <h4 className="font-18 black sm-w-full">
                                        {item.title}
                                      </h4>
                                      {(user &&
                                        user.role_id ==
                                          constant.rolesId[
                                            'importer&distributer'
                                          ]) ||
                                      user.role_id ==
                                        constant.rolesId.importer ||
                                      user.role_id ==
                                        constant.rolesId.distributer ? (
                                        <h4 className="font-18 black sm-w-full sm--mt-1">{`$${item.product_price}`}</h4>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div className="review flex-wrap justify-between mt-2 sm--mt-1">
                                      <div className="flex flex-wrap item-center sm-w-full">
                                        <div className='rating__bg flex items-center'>
                                          <span className='flex items-center gap-1'>
                                          {/* <h4 className="font-15"> */}
                                            {item.avg_rating}
                                          {/* </h4> */}
                                          {' '}
                                          &nbsp;
                                            <i
                                              className="fa fa-star"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </div>
                                        <h4 className="font-12 pl-2">
                                          {item.total_reviews} &nbsp;
                                          {item.total_reviews.length <= 1
                                            ? lang?.reviews
                                            : lang?.review}
                                        </h4>
                                      </div>
                                      <div className="prdct_nm_bg sm-w-full sm--mt-1">
                                        <h4 className="font-18">
                                          {item.product_category_name}
                                        </h4>
                                      </div>
                                    </div>
                                    <p className="mt-2 parahideMob">
                                      {item.description}{' '}
                                    </p>
                                    {/* <div className='mt-2'>
                                                                            <h4 className='font-16'>{item.total_reviews}{(item.total_reviews.length <= 1) ? "Reviews" : "Review"}</h4>
                                                                            </div> */}
                                    <div className="flex flex-wrap justify-between">
                                      <div className="store-name flex justify-between mt-1">
                                        <h4 className="font-16">
                                          {item.store_name}
                                        </h4>
                                      </div>
                                      {user.role_id ==
                                        constant.rolesId.voyagers ||
                                      user.role_id ==
                                        constant.rolesId.travelAgencies ||
                                      user.role_id ==
                                        constant.rolesId.restaurant ||
                                      user.role_id == constant.rolesId.producer
                                        ? null
                                        : item.available_for_sample ==
                                            'Yes' && (
                                            <div>
                                              <span className="font-16 text-black">
                                                {lang?.Available_For_Sample}
                                              </span>
                                              <i
                                                className="fa fa-check-circle pl-4"
                                                aria-hidden="true"
                                              />
                                            </div>
                                          )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <div className="notFound text-center mt-4 mb-4">
                        <h1>{lang?.No_data_found}</h1>
                      </div>
                    )}
                  </div>
                  {searchProductData?.total > 5 && (
                    <ReactPaginate
                      previousLabel={'prev'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={Math.ceil(
                        searchProductData.total / searchProductData.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
