import React from 'react'
import { Fragment, useEffect } from "react";
// C:\Users\pc\Desktop\alysei\alysei-web\public\images\logo1.png
import headerimg from '../../assets/images/logo1.png'
import { Link, useHistory } from "react-router-dom";
// import '../../../assets/marketplacecss/mrktplace-style.css';
// import '../../assets/mrktplace-style.css'

export default function MarketPlaceHeader() {
    let history = useHistory();
    return (
        <Fragment>
        {/* header */}
        <div className="site__header mrkt-place-header">
          <div className="site__width">
            <div className="site-align w-full">
              <div className="top_left-header site-align w-full">
                <div className="left-arrow w-6/12">
                  <a href="javascript:void(0)" onClick={() => history.goBack()}>
                    <i className="fa fa-arrow-left" aria-hidden="true" />
                  </a>
                </div>
                <div className="site__logo">
                  <Link to="/marketplace"><img src={headerimg} alt="Logo" title="Logo" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      
    )
}
