import auth from '../../../helpers/auth'
import REACT_APP_API from "../../../config/environment";
import headerType from '../../../helpers/headerType';
export const SET_SEND_REQUEST_SPINNER_STATUS = "SET_SEND_REQUEST_SPINNER_STATUS";
export const SET_SEND_ACCEPT_REJECT_REQUEST_SPINNER_STATUS = "SET_SEND_ACCEPT_REJECT_REQUEST_SPINNER_STATUS";
export const SET_BLOCK_REQUEST_SPINNER_STATUS = "SET_BLOCK_REQUEST_SPINNER_STATUS";
export const SET_CONNECTION_SPINNER_STATUS = "SET_CONNECTION_SPINNER_STATUS";
export const GET_USER_CERTIFICATE_SPINNER_STATUS = "GET_USER_CERTIFICATE_SPINNER_STATUS";
export const UPLOAD_USER_CERTIFICATE_SPINNER_STATUS = "UPLOAD_USER_CERTIFICATE_SPINNER_STATUS";
export const SET_CONNECTION_PROFILE_SPINNER_STATUS = "SET_CONNECTION_PROFILE_SPINNER_STATUS";
export const SET_CONNECTION_CANCEL_SPINNER_STATUS = "SET_CONNECTION_CANCEL_SPINNER_STATUS";
export const GET_CONNECTION_TABS_DATA = "GET_CONNECTION_TABS_DATA";
export const GET_USER_CERTIFICATE_DATA = "GET_USER_CERTIFICATE_DATA";
export const GET_CONNECTION_PRODUCT_TYPE_DATA = "GET_CONNECTION_PRODUCT_TYPE_DATA";
export const GET_CONNECTION_PROFILE_DATA = "GET_CONNECTION_PROFILE_DATA";

export function setSendRequestSpinner(flag) {
    return {
        type: SET_SEND_REQUEST_SPINNER_STATUS,
        payload: flag,
    };
}

export function acceptRejectRequestSpinner(flag) {
    return {
        type: SET_SEND_ACCEPT_REJECT_REQUEST_SPINNER_STATUS,
        payload: flag,
    };
}

export function setConnectionSpinner(flag) {
    return {
        type: SET_CONNECTION_SPINNER_STATUS,
        payload: flag,
    };
}

export function getUserCertificateSpinner(flag) {
    return {
        type: GET_USER_CERTIFICATE_SPINNER_STATUS,
        payload: flag,
    };
}

export function uploadUserCertificateSpinner(flag) {
    return {
        type: UPLOAD_USER_CERTIFICATE_SPINNER_STATUS,
        payload: flag,
    };
}

export function setConnectionProfileSpinner(flag) {
    return {
        type: SET_CONNECTION_PROFILE_SPINNER_STATUS,
        payload: flag,
    };
}

export function cancelConnectionSpinner(flag) {
    return {
        type: SET_CONNECTION_CANCEL_SPINNER_STATUS,
        payload: flag,
    };
}

export function blockReqSpinner(flag) {
    return {
        type: SET_BLOCK_REQUEST_SPINNER_STATUS,
        payload: flag,
    };
}

export function getConnectionTabsData(data) {
    return {
        type: GET_CONNECTION_TABS_DATA,
        payload: data,
    };
}

export function getUserCertificateData(data) {
    return {
        type: GET_USER_CERTIFICATE_DATA,
        payload: data,
    };
}

export function getConnectionProductData(data) {
    return {
        type: GET_CONNECTION_PRODUCT_TYPE_DATA,
        payload: data,
    };
}

export function getConnectionProfileData(data) {
    return {
        type: GET_CONNECTION_PROFILE_DATA,
        payload: data,
    };
}

export const sendConnectionRequest = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(setSendRequestSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/send/connection/request`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(setSendRequestSpinner(false));
                    return response
                } else {
                    await dispatch(setSendRequestSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(setSendRequestSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(setSendRequestSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const acceptRejectRequest = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(acceptRejectRequestSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/accept/reject/request`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(acceptRejectRequestSpinner(false));
                    return response
                } else {
                    return response;
                }
            } catch (e) {
                await dispatch(acceptRejectRequestSpinner(false));
                console.log(e, 'error.');

            }
        } else {
            await dispatch(acceptRejectRequestSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const acceptRejectRequestWithVisitorId = (model) => {
    return async (dispatch, getState) => {
        await dispatch(acceptRejectRequestSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/accept/reject/connection/request/from/profile?visitor_profile_id=${model.connection_id}&&accept_or_reject=${model.accept_or_reject}&reason=${model.reason}`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(acceptRejectRequestSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(acceptRejectRequestSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(acceptRejectRequestSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const followUnfollowUser = (model) => { 
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/follow/user`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    return response
                }
            } catch (e) {
                console.log(e, 'error.');
            }
        } else {
            await dispatch(acceptRejectRequestSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const blockRequest = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        await dispatch(blockReqSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/block/user`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(blockReqSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(blockReqSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(blockReqSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const updateUserCertificate = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    console.log(model,"form_data");
    return async (dispatch, getState) => {
        await dispatch(uploadUserCertificateSpinner(true));
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/user/certificates`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(getUserCertificate());
                    await dispatch(uploadUserCertificateSpinner(false));
                    return response
                }
            } catch (e) {
                await dispatch(uploadUserCertificateSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(uploadUserCertificateSpinner(false));
            console.log('You need to login first');
        }
    };
};


export const fetchConnectionTabs = (tab, pageNo) => {
    return async (dispatch, getState) => {
        await dispatch(setConnectionSpinner(true));
        const token = await auth.getAccessToken();
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/connection/tabs?tab=${tab}&page=${pageNo}`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success) {
                    await dispatch(getConnectionTabsData(response));
                    await dispatch(setConnectionSpinner(false));
                    // return response
                }
            } catch (e) {
                await dispatch(setConnectionSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            await dispatch(setConnectionSpinner(false));
            console.log('You need to login first');
        }
    };
};


export const getUserCertificate = () => {
    return async (dispatch, getState) => {
        // await dispatch(getUserCertificateSpinner(true));
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/user/certificates`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success) {
                    // await dispatch(getUserCertificateSpinner(false));
                    await dispatch(getUserCertificateData(response.data));
                    // return response
                }
            } catch (e) {
                // await dispatch(getUserCertificateSpinner(false));
                console.log(e, 'error.');
            }
        } else {
            // await dispatch(getUserCertificateSpinner(false));
            console.log('You need to login first');
        }
    };
};

export const getConnectionProductType = (tab) => {
    return async (dispatch, getState) => {
        const token = await auth.getAccessToken();
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/products/for/connection`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                // console.log("responce",response)
                if (response.success === 200) {
                    await dispatch(getConnectionProductData(response.data));
                    // return response
                }
            } catch (e) {
                console.log(e, 'error.');
            }
        } else {
            console.log('You need to login first');
        }
    };
};

export function connectionProfile(id) {
    return async (dispatch, getState) => {
        const token = await auth.getAccessToken();
        await dispatch(setConnectionProfileSpinner(true));
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/view/connection?connection_id=${id}`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response && response.success === 200) {
                
                await dispatch(getConnectionProfileData(response.data));
                await dispatch(setConnectionProfileSpinner(false));
            } else {
                // alert(response.errors)
                await dispatch(setConnectionProfileSpinner(false));
                console.log(response.errors);
            }
        } catch (e) {
            await dispatch(setConnectionProfileSpinner(false));
            console.log(e);
        }
    };
}

export function cencelConnectionReq(id) {
    return async (dispatch, getState) => {
        const token = await auth.getAccessToken()
        await dispatch(cancelConnectionSpinner(true));
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/cancel/connection/request?visitor_profile_id=${id}`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response && response.success === 200) {
                await dispatch(getConnectionProfileData(response.data));
                await dispatch(cancelConnectionSpinner(false));
            } else {
                // alert(response.errors)
                await dispatch(cancelConnectionSpinner(false));
                console.log(response.errors);
            }
        } catch (e) {
            await dispatch(cancelConnectionSpinner(false));
            console.log(e);
        }
    };
}

// initialState
export const initialState = {
    blockUserSpinner: false,
    connectionSpinner: false,
    sendRequestSpinner: false,
    acceptRejectSpinner: false,
    cancelConnectionSpinner: false,
    cuserCertificateSpinner: false,
    connectionProfileSpinner: false,
    updateuserCertificateSpinner: false,
    connectionTabs: [],
    userCertificateData: [],
    connectionProfileData: [],
    connectionProductTypeData: [],
};

const ACTION_HANDLERS = {
    [SET_SEND_REQUEST_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            sendRequestSpinner: action.payload,
        };
    },
    [SET_CONNECTION_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            connectionSpinner: action.payload,
        };
    },
    [GET_USER_CERTIFICATE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            cuserCertificateSpinner: action.payload,
        };
    },
    [UPLOAD_USER_CERTIFICATE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            updateuserCertificateSpinner: action.payload,
        };
    },
    [SET_CONNECTION_PROFILE_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            connectionProfileSpinner: action.payload,
        };
    },
    [SET_SEND_ACCEPT_REJECT_REQUEST_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            acceptRejectSpinner: action.payload,
        };
    },
    [SET_CONNECTION_CANCEL_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            cancelConnectionSpinner: action.payload,
        };
    },
    [SET_BLOCK_REQUEST_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            blockUserSpinner: action.payload,
        };
    },


    [GET_CONNECTION_TABS_DATA]: (state, action) => {
        return {
            ...state,
            connectionTabs: action.payload,
        };
    },
    [GET_USER_CERTIFICATE_DATA]: (state, action) => {
        return {
            ...state,
            userCertificateData: action.payload,
        };
    },
    [GET_CONNECTION_PROFILE_DATA]: (state, action) => {
        return {
            ...state,
            connectionProfileData: action.payload,
        };
    },
    [GET_CONNECTION_PRODUCT_TYPE_DATA]: (state, action) => {
      
        return {
            ...state,
            connectionProductTypeData: action.payload,
        };
    },
};

export default function networkConnectionReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
