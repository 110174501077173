import React, { Fragment, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logo from "../../../assets/images/logo.png";
import OtpInput from "react-otp-input";
import Helmet from "react-helmet";

export default function Access() {
    const history = useHistory();
    const [verifyAccess, setVerifyAccess] = useState('');
    const [isErrorMessage, setIsErrorMessage] = useState('');

    // Submit 
    // const submitAccessCode = () =>{
    //     if(verifyAccess !=''){
    //         const accessCode = '12202';
    //         if(accessCode === verifyAccess){
    //             localStorage.setItem('ACCESS_CODE', 'verified');
    //             history.push(`/register`);
    //         }
    //         else{
    //             setIsErrorMessage('Please enter valid access code!');    
    //         } 
    //     }
    //     else{
    //         setIsErrorMessage('Please enter the access code!');
    //     }
    // }

    const handleSubmit = async(e) =>{
        e.preventDefault();
        if(verifyAccess !=''){
            const accessCode = '12202';
            if(accessCode === verifyAccess){
                localStorage.setItem('ACCESS_CODE', 'verified');
                history.push(`/register`);
            }
            else{
                setIsErrorMessage('Please enter valid access code!');    
            } 
        }
        else{
            setIsErrorMessage('Please enter the access code!');
        }
    }
    //console.log(verifyAccess,"verifyAccess");
  return (
    <Fragment>
        <div className='access-code-bg'>
            <Helmet>
            <meta id="meta-description" name="description" content="Access" />
            <meta id="og-title" property="og:title" content="Alysei" />
            <meta id="og-image" property="og:image" content="https://alysei.s3.us-west-1.amazonaws.com/uploads/2023/01/1685396.png" />
                
              </Helmet>
            <div className='accessView'>
                <div className='pageLogo'>
                    <Link to="/home"><img src={logo} alt="Logo" /></Link>
                </div>
                <div className='titleWidth'>
                    <h4 className='font-22'>Thank you for choosing Alysei, Please enter the access code</h4>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='inputBlock'>
                        <div className="form__action otp access-verify">
                            <OtpInput
                                value={verifyAccess}
                                onChange={(accessCode) => {setVerifyAccess(accessCode); setIsErrorMessage("");}}
                                numInputs={5}
                            />
                        </div>
                    </div>
                    <div className={'error'} style={{color:"red",textAlign:"center"}}>{isErrorMessage}</div>
                    <div className='form__action form__item'>
                        <button type='submit' className='btn'>Submit</button>
                    </div>
                    <p className='textBlock text-center mt-8'>
                        In case you don't have access code, please write us at <a style={{color:"#4183c4"}} href="mailto:info@alysei.com">info@alysei.com</a>
                    </p>
                </form>
            </div>
        </div>
    </Fragment>
  )
}
