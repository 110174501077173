import React, { useState } from 'react'
import img1 from "../../../../../assets/images/faq_image.png"
import { useSelector } from 'react-redux';
import FormSpinner from '../../../../../components/Spinners/FormSpinner';
// import AccordionData from './AccordionData'

export default function RightContent() {
  let { dashboardBuyerRoles, loadDashboardDataSpinner } = useSelector((state) => state.buyerModuleFilterData);
  const [faq, setFaq] = useState(0);
  
  return (
    <>
    {loadDashboardDataSpinner ? <FormSpinner/> :
      dashboardBuyerRoles.length > 0 && dashboardBuyerRoles.map(({id, role_name, description }, index) => (
        <div className={`faqItem ${index === faq ? "Active" : ""}`}>
          <div className='accordionRow' onClick={() =>{index === faq?setFaq(-1):setFaq(index)} }>
            <div iv className='flex items-center justify-between'>
              <h4 className=''>{role_name}</h4> 
              <div className='pt-1'><i className="fa fa-angle-down ml-1 " aria-hidden="true"></i></div>
            </div>
            <p>{description}</p>
          </div>
        </div>
      ))}
      <div className='faqImage'>
        <img src={img1} alt="Faq Image" />
      </div>

    </>
  )
}
