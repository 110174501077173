import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment"
import headerType from "../../../helpers/headerType";
export const GET_ALL_ACTIVITY_POST = "GET_ALL_ACTIVITY_POST";
export const GET_ALL_DISCOVER_ALYSEI = "GET_ALL_DISCOVER_ALYSEI";
export const GET_EDIT_EVENT_DATA = "GET_EDIT_EVENT_DATA";
export const GET_EDIT_TRIP_DATA = "GET_EDIT_TRIP_DATA";
export const GET_BLOG_DATA = "GET_BLOG_DATA";
export const GET_ALL_REASTAURENT_TYPE_DATA = "GET_ALL_REASTAURENT_TYPE_DATA";
export const GET_ALL_SPECIALIZATION_DATA = "GET_ALL_SPECIALIZATION_DATA";
export const GET_ALL_INTENSITY_DATA = "GET_ALL_INTENSITY_DATA";
export const GET_TRIPS_COUNTRY_DATA = "GET_TRIPS_COUNTRY_DATA";
export const GET_ALL_NOTIFICATION_DATA = "GET_ALL_NOTIFICATION_DATA";
export const SET_SHARE_POST_SPINNER_STATUS = "SET_SHARE_POST_SPINNER_STATUS";
export const SET_LIKE_STATUS_SPINNER_STATUS = "SET_LIKE_STATUS_SPINNER_STATUS";
export const SET_CLEAR_NOTIFICATION_SPINNER_STATUS = "SET_CLEAR_NOTIFICATION_SPINNER_STATUS";
export const GET_ALL_ACTIVITY_SPINNER_STATUS = "GET_ALL_ACTIVITY_SPINNER_STATUS";
export const GET_ALL_DISCOVER_ALYSEI_SPINNER_STATUS = "GET_ALL_DISCOVER_ALYSEI_SPINNER_STATUS";
export const GET_EVENT_DATA_SPINNER_STATUS = "GET_EVENT_DATA_SPINNER_STATUS";
export const GET_EDIT_TRIPS_SPINNER_STATUS = "GET_EDIT_TRIPS_SPINNER_STATUS";
export const GET_LIST_TRIPS_SPINNER_STATUS = "GET_LIST_TRIPS_SPINNER_STATUS";
export const GET_BLOG_SPINNER_STATUS = "GET_BLOG_SPINNER_STATUS";
export const GET_ALL_DISCOVER_FILTER_SPINNER_STATUS = "GET_ALL_DISCOVER_FILTER_SPINNER_STATUS";
export const GET_ALL_RESTAURANT_SPINNER_STATUS = "GET_ALL_RESTAURANT_SPINNER_STATUS";
export const GET_ALL_SPECIALIZATION_SPINNER_STATUS = "GET_ALL_SPECIALIZATION_SPINNER_STATUS";
export const GET_ALL_SINTENSITY_SPINNER_STATUS = "GET_ALL_SINTENSITY_SPINNER_STATUS";
export const GET_TRIPS_COUNTRY_SPINNER_STATUS = "GET_TRIPS_COUNTRY_SPINNER_STATUS";
export const GET_ALL_NOTIFICATION_SPINNER_STATUS = "GET_ALL_NOTIFICATION_SPINNER_STATUS";
export const SET_COMMENT_STATUS_SPINNER_STATUS = "SET_COMMENT_STATUS_SPINNER_STATUS";
export const SET_DELETE_POST_STATUS_SPINNER_STATUS = "SET_DELETE_POST_STATUS_SPINNER_STATUS";
export const DISCOVER_ALYSEI_DATA = "DISCOVER_ALYSEI_DATA";
export const DISCOVER_FILTERS_DATA = "DISCOVER_FILTERS_DATA";
export const USER_SUBSCRITION_DATA = "USER_SUBSCRITION_DATA";

export function setLikePostSpinner(flag) {
  return {
    type: SET_LIKE_STATUS_SPINNER_STATUS,
    payload: flag,
  };
}
export function setDiscoverAlyseiData(data) {
  return {
    type: DISCOVER_ALYSEI_DATA,
    payload: data,
  };
}

export function setDiscoveryFilterData(data) {
  return {
    type: DISCOVER_FILTERS_DATA,
    payload: data,
  };
}

export function setClearNotificationSpinner(flag) {
  return {
    type: SET_CLEAR_NOTIFICATION_SPINNER_STATUS,
    payload: flag,
  };
}

export function setSharePostSpinner(flag) {
  return {
    type: SET_SHARE_POST_SPINNER_STATUS,
    payload: flag,
  };
}

export function commentPostSpinner(flag) {
  return {
    type: SET_COMMENT_STATUS_SPINNER_STATUS,
    payload: flag,
  };
}

export function removePostSpinner(flag) {
  return {
    type: SET_DELETE_POST_STATUS_SPINNER_STATUS,
    payload: flag,
  };
}

export function getAllActivitySpinner(flag) {
  return {
    type: GET_ALL_ACTIVITY_SPINNER_STATUS,
    payload: flag,
  };
}

export function getAllDiscoverSpinner(flag) {
  return {
    type: GET_ALL_DISCOVER_ALYSEI_SPINNER_STATUS,
    payload: flag,
  };
}
export function getEditEventSpinner(flag) {
  return {
    type: GET_EVENT_DATA_SPINNER_STATUS,
    payload: flag,
  };
}
export function getEditTripSpinner(flag) {
  return {
    type: GET_EDIT_TRIPS_SPINNER_STATUS,
    payload: flag,
  };
}
export function getTripListSpinner(flag) {
  return {
    type: GET_LIST_TRIPS_SPINNER_STATUS,
    payload: flag,
  };
}

export function getEditBlogSpinner(flag) {
  return {
    type: GET_BLOG_SPINNER_STATUS,
    payload: flag,
  };
}

export function getFilterDiscoverSpinner(flag) {
  return {
    type: GET_ALL_DISCOVER_FILTER_SPINNER_STATUS,
    payload: flag,
  };
}

export function getAllRestaurantSpinner(flag) {
  return {
    type: GET_ALL_RESTAURANT_SPINNER_STATUS,
    payload: flag,
  };
}

export function getAllSpecializationSpinner(flag) {
  return {
    type: GET_ALL_SPECIALIZATION_SPINNER_STATUS,
    payload: flag,
  };
}

export function getAllIntensitySpinner(flag) {
  return {
    type: GET_ALL_SINTENSITY_SPINNER_STATUS,
    payload: flag,
  };
}

export function getTripsCountrySpinner(flag) {
  return {
    type: GET_TRIPS_COUNTRY_SPINNER_STATUS,
    payload: flag,
  };
}

export function getAllNotificationSpinner(flag) {
  return {
    type: GET_ALL_NOTIFICATION_SPINNER_STATUS,
    payload: flag,
  };
}

export function getActivityPost(data) {
  return {
    type: GET_ALL_ACTIVITY_POST,
    payload: data,
  };
}

export function getAllDiscoverAlysei(data) {
  return {
    type: GET_ALL_DISCOVER_ALYSEI,
    payload: data,
  };
}

export function getEditEvent(data) {
  return {
    type: GET_EDIT_EVENT_DATA,
    payload: data,
  };
}

export function getEditTrip(data) {
  return {
    type: GET_EDIT_TRIP_DATA,
    payload: data,
  };
}

export function getEditBlog(data) {
  return {
    type: GET_BLOG_DATA,
    payload: data,
  };
}

export function getAllReataurentData(data) {
  return {
    type: GET_ALL_REASTAURENT_TYPE_DATA,
    payload: data,
  };
}

export function getAllSpecializationData(data) {
  return {
    type: GET_ALL_SPECIALIZATION_DATA,
    payload: data,
  };
}

export function getAllIntensityData(data) {
  return {
    type: GET_ALL_INTENSITY_DATA,
    payload: data,
  };
}

export function getTripsCountryData(data) {
  return {
    type: GET_TRIPS_COUNTRY_DATA,
    payload: data,
  };
}

export function getNotificationData(data) {
  return {
    type: GET_ALL_NOTIFICATION_DATA,
    payload: data,
  };
}

export function userSubscriptionHandler(data) {
  console.log(data,"producer")
  return {
    type: USER_SUBSCRITION_DATA,
    payload: data,
  };
}

export const editBlogs = (id) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getEditBlogSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/blog/listing?visitor_profile_id=${id}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getEditBlog(response));
          await dispatch(getEditBlogSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getEditBlogSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getEditBlogSpinner(false));
    }
  };
};

export const editTrips = (id) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getEditTripSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/edit/trip/${id}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getEditTrip(response));
          await dispatch(getEditTripSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getEditTripSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getEditTripSpinner(false));
    }
  };
};



export const editEvent = (id) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getEditEventSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/edit/event/${id}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getEditEvent(response));
          await dispatch(getEditEventSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getEditEventSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getEditEventSpinner(false));
    }
  };
};

export const fetchAllDiscoverAlysei = (type, pageNo) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getAllDiscoverSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/circle/detail?type=${type}&page=${pageNo}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getAllDiscoverAlysei(response));
          await dispatch(getAllDiscoverSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getAllDiscoverSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getAllDiscoverSpinner(false));
    }
  };
};


export const filterDiscoverEvents = (model) => {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getFilterDiscoverSpinner(true));
    await dispatch(setDiscoveryFilterData(model));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/stories/byfilter?type=${model.type}&date=${model.date}&event_type=${model.event_type}&registration_type=${model.registration_type}&restaurant_type=${model.restaurant_type}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token)
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getFilterDiscoverSpinner(false));
          await dispatch(getAllDiscoverAlysei(response));
          return response;
        }
      } catch (e) {
        await dispatch(getFilterDiscoverSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(getFilterDiscoverSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const filterDiscoverBlogs = (model) => {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getFilterDiscoverSpinner(true));
    await dispatch(setDiscoveryFilterData(model));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/stories/byfilter?type=${model.type}&title=${model.title}&specialization=${model.specialization}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token)
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getFilterDiscoverSpinner(false));
          await dispatch(getAllDiscoverAlysei(response));
          return response;
        }
      } catch (e) {
        await dispatch(getFilterDiscoverSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(getFilterDiscoverSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const filterDiscoverRestaurent = (model) => {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getFilterDiscoverSpinner(true));
    await dispatch(setDiscoveryFilterData(model));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/stories/byfilter?type=${model.type}&hubs=${model.hubs}&restaurant_type=${model.restaurant_type}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token)
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getFilterDiscoverSpinner(false));
          await dispatch(getAllDiscoverAlysei(response));
          return response;
        }
      } catch (e) {
        await dispatch(getFilterDiscoverSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(getFilterDiscoverSpinner(false));
      console.log("You need to login first");
    }
  };
};


export const resetDiscoveryFilters = (model) => {
  return async (dispatch) => {
    await dispatch(setDiscoveryFilterData(model));
  }
}

export const filterDiscoverTrips = (model) => {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getFilterDiscoverSpinner(true));
    await dispatch(setDiscoveryFilterData(model));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/stories/byfilter?type=${model.type}&region=${model.region}&adventure_type=${model.adventure_type}&duration=${model.duration}&intensity=${model.intensity}&price=${model.price}&country=${model.country}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token)
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getFilterDiscoverSpinner(false));
          await dispatch(getAllDiscoverAlysei(response));
          return response;
        } else {
          await dispatch(getFilterDiscoverSpinner(false));
        }
      } catch (e) {
        await dispatch(getFilterDiscoverSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(getFilterDiscoverSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const setUpdateViewBubbles = async(slug) => {
    const token = await auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/view/latest-news`,
          {
            method: "POST",
            headers:headerType.HeadersWithTokenAndJson(token),
            body:JSON.stringify({view_name:slug})
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          return response;
        }
      } catch (e) {
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
};

export const getBrokerAndShipping = async(slug) => {
    const token = await auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/discovery/post/${slug}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          return response;
        }
      } catch (e) {
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
};


export const fetchAllRestaurantType = () => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getAllRestaurantSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/restaurant/types`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getAllReataurentData(response.data));
          await dispatch(getAllRestaurantSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getAllRestaurantSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getAllRestaurantSpinner(false));
    }
  };
};

export const fetchAllSpecialization = () => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getAllSpecializationSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/specialization`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getAllSpecializationData(response.data.sort(dynamicSort("option"))));
          await dispatch(getAllSpecializationSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getAllSpecializationSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getAllSpecializationSpinner(false));
    }
  };
};

const dynamicSort = (property) => {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
} 
export const fetchAllIntensity = () => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getAllIntensitySpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/intensity/list`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getAllIntensityData(response.data));
          await dispatch(getAllIntensitySpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getAllIntensitySpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getAllIntensitySpinner(false));
    }
  };
};

export const fetchTripsCountry = (type) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getTripsCountrySpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/countries?param=${type}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getTripsCountryData(response.data));
          await dispatch(getTripsCountrySpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getTripsCountrySpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(getTripsCountrySpinner(false));
    }
  };
};

//  single post activity..
export const singlelActivityFeed = (postId) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(getAllActivitySpinner(true));
    if (!token||token) {
      try { 
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/post/detail?post_id=${postId}`, //http://127.0.0.1:8000/api/get/post/detail?post_id=1085
          {
            method: "GET",
            headers: await headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getActivityPost(response?.data ??[]));
          // await dispatch(setDiscoverAlyseiData(response.discover_alysei));
          await dispatch(getAllActivitySpinner(false));
          // await localStorage.setItem("having_preferences", response && response.having_preferences);
          return response.data;
        }
      } catch (e) {
        await dispatch(getAllActivitySpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
      await dispatch(getAllActivitySpinner(false));
    }
  };
};

export const fetchAllActivityFeed = (model) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(getAllActivitySpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/activity/feed?page=${model.page}`,
          {
            method: "GET",
            headers: await headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getActivityPost(response.data));
          await dispatch(setDiscoverAlyseiData(response.discover_alysei));
          await dispatch(getAllActivitySpinner(false));
          await localStorage.setItem("having_preferences", response && response.having_preferences);
          return response.data;
        }
      } catch (e) {
        await dispatch(getAllActivitySpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
      await dispatch(getAllActivitySpinner(false));
    }
  };
};

export const fetchAllNotification = (page) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(getAllNotificationSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/all/notifications?page=${page}`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getNotificationData(response.data));
          await dispatch(getAllNotificationSpinner(false));
          return response?.data;
        }
      } catch (e) {
        await dispatch(getAllNotificationSpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
      await dispatch(getAllNotificationSpinner(false));
    }
  };
};

export const clearNotification = () => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setClearNotificationSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/delete/notifications`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(setClearNotificationSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(setClearNotificationSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setClearNotificationSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const likePost = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setLikePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/post/like`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(setLikePostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setLikePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

// Comment like & dislike
export const likeUnlikeComment = (model) => {

  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setLikePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/comment/like`,
          {
            method: "POST",
            headers: headerType.HeadersWithTokenAndJson(token),
            body: JSON.stringify(model),
          }
        );
        let response = await result.json();
        if (response.success) {
          // await dispatch(setLikePostSpinner(false));
          return response;
        }
      } catch (e) {
        // await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setLikePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const sharedPost = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setSharePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/share/post`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(setSharePostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(setSharePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setSharePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const postComment = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch) => {
    const token = auth.getAccessToken();
    await dispatch(commentPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/post/comment`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(commentPostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(commentPostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(commentPostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const replyComment = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch) => {
    const token = auth.getAccessToken();
    await dispatch(commentPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/reply/post/comment`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(commentPostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(commentPostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(commentPostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const deletePost = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(removePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/delete/post`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 }));
          await dispatch(removePostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(removePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(removePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const removePostComment = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    // await dispatch(removePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/delete/post/comment`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          // await dispatch(removePostSpinner(false));
          return response;
        }
      } catch (e) {
        // await dispatch(removePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // await dispatch(removePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const updateComment = (model) => {
  var form_data = new FormData();
  form_data.append('comment', model['comment']);
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/post/comment/update/${model.comment_id}`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
        if (response.success) {
          return response;
        }
      } catch (e) {
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};

// report post
export const reportUserPost = (reportData) => {
  return async (dispatch) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/report/post`,
          {
            method: "POST",
            headers: headerType.HeadersWithTokenAndJson(token),
            body: JSON.stringify(reportData),
          }
        );
        let response = await result.json();
          return response;
      } catch (e) {
        // await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};

// block user
export const blockUser = (userId) => {
  return async (dispatch) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/block/user`,
          {
            method: "POST",
            headers: headerType.HeadersWithTokenAndJson(token),
            body: JSON.stringify({block_user_id:userId}),
          }
        );
        let response = await result.json();
          return response;
      } catch (e) {
        // await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};

export const reportUser = (reportData) => {
  return async (dispatch) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/report-user`,
          {
            method: "POST",
            headers: headerType.HeadersWithTokenAndJson(token),
            body: JSON.stringify(reportData),
          }
        );
        let response = await result.json();
          return response;
      } catch (e) {
        // await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};

export const checkSubscription = (id) => {
  
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    // await dispatch(getEditBlogSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/membership/get/user/subscription`,
          {
            method: "GET",
            headers: headerType.HeadersWithToken(token),
          }
        );
        let response = await result.json();
        console.log(response,"response producer inside check")
        if (response.success === 200) {
          await dispatch(userSubscriptionHandler(response))
          // await dispatch(getEditBlog(response));
          // await dispatch(getEditBlogSpinner(false));
          // return response;
        }
      } catch (e) {
        // await dispatch(getEditBlogSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      // await dispatch(getEditBlogSpinner(false));
    }
  };
};


export const initialState = {
  editBlogData: [],
  editTripData: [],
  tripListData: [],
  activityPost: [],
  editEventData: [],
  intensityData: [],
  tripsCountryData: [],
  notificationData: [],
  discoverAlyseiData: [],
  reataurentTypeData: [],
  specializationData: [],
  discover_alysei: [],
  get_Subscription_Status_Data:[],
  discover_filters : '',
  blogSpinner: false,
  tripListSpinner: false,
  editTripSpinner: false,
  commentSpinner: false,
  intensitySpinner: false,
  clearPostSpinner: false,
  likePostSpinner: false,
  sharePostSpinner: false,
  removePostSpinner: false,
  getActivitySpinner: false,
  getNotificationSpinner: false,
  discoverSpinner: false,
  restaurantTypeSpinner: false,
  discoverFilterSpinner: false,
  specializationSpinner: false,
  tripsCountrySpinner: false,
  editEventSpinner: false,
};

const ACTION_HANDLERS = {
  [GET_ALL_ACTIVITY_POST]: (state, action) => {
    return {
      ...state,
      activityPost: action.payload,
    };
  },
  [GET_ALL_DISCOVER_ALYSEI]: (state, action) => {
    return {
      ...state,
      discoverAlyseiData: action.payload,
    };
  },
  [GET_EDIT_EVENT_DATA]: (state, action) => {
    return {
      ...state,
      editEventData: action.payload,
    };
  },
  [GET_EDIT_TRIP_DATA]: (state, action) => {
    return {
      ...state,
      editTripData: action.payload,
    };
  },
  [GET_BLOG_DATA]: (state, action) => {
    return {
      ...state,
      editBlogData: action.payload,
    };
  },
  [GET_ALL_REASTAURENT_TYPE_DATA]: (state, action) => {
    return {
      ...state,
      reataurentTypeData: action.payload,
    };
  },
  [GET_ALL_SPECIALIZATION_DATA]: (state, action) => {
    return {
      ...state,
      specializationData: action.payload,
    };
  },
  [GET_ALL_INTENSITY_DATA]: (state, action) => {
    return {
      ...state,
      intensityData: action.payload,
    };
  },
  [GET_TRIPS_COUNTRY_DATA]: (state, action) => {
    return {
      ...state,
      tripsCountryData: action.payload,
    };
  },
  [GET_ALL_NOTIFICATION_DATA]: (state, action) => {
    return {
      ...state,
      notificationData: action.payload,
    };
  },

  [SET_LIKE_STATUS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      likePostSpinner: action.payload,
    };
  },

  [SET_CLEAR_NOTIFICATION_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      clearPostSpinner: action.payload,
    };
  },

  [SET_SHARE_POST_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      sharePostSpinner: action.payload,
    };
  },

  [SET_COMMENT_STATUS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      commentSpinner: action.payload,
    };
  },

  [SET_DELETE_POST_STATUS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      removePostSpinner: action.payload,
    };
  },

  [GET_ALL_ACTIVITY_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      getActivitySpinner: action.payload,
    };
  },

  [GET_ALL_DISCOVER_ALYSEI_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      discoverSpinner: action.payload,
    };
  },

  [GET_EVENT_DATA_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      editEventSpinner: action.payload,
    };
  },

  [GET_EDIT_TRIPS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      editTripSpinner: action.payload,
    };
  },

  [GET_LIST_TRIPS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      tripListSpinner: action.payload,
    };
  },

  [GET_BLOG_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      blogSpinner: action.payload,
    };
  },

  [GET_ALL_DISCOVER_FILTER_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      discoverFilterSpinner: action.payload,
    };
  },

  [GET_ALL_RESTAURANT_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      restaurantTypeSpinner: action.payload,
    };
  },

  [GET_ALL_SPECIALIZATION_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      specializationSpinner: action.payload,
    };
  },

  [GET_ALL_SINTENSITY_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      intensitySpinner: action.payload,
    };
  },

  [GET_TRIPS_COUNTRY_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      tripsCountrySpinner: action.payload,
    };
  },

  [GET_ALL_NOTIFICATION_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      getNotificationSpinner: action.payload,
    };
  },
  [DISCOVER_ALYSEI_DATA]: (state, action) => {
    return {
      ...state,
      discover_alysei: action.payload,
    };
  },
  [DISCOVER_FILTERS_DATA]: (state, action) => {
    return {
      ...state,
      discover_filters: action.payload,
    };
  },
  [USER_SUBSCRITION_DATA]: (state, action) => {
    
    return {
      ...state,
      get_Subscription_Status_Data: action.payload,
    };
  },
};

export default function fetchActivityFeed(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
