import auth from '../../../helpers/auth'
import REACT_APP_API from "../../../config/environment"
import { getTripList, setCreateBlog, setCreateEvent } from '../../discover/modules/DiscoverModule';
import { getAwardList } from '../../profile/modules/profile';
import headerType from '../../../helpers/headerType';
export const GET_UNIVERSAL_SEARCH_SPINNER_STATUS = "GET_UNIVERSAL_SEARCH_SPINNER_STATUS";
export const GET_UNIVERSAL_SEARCH_PEOPLE_DATA = "GET_UNIVERSAL_SEARCH_PEOPLE_DATA";
export const GET_UNIVERSAL_SEARCH_POSTS_DATA = "GET_UNIVERSAL_SEARCH_POSTS_DATA";
export const GET_UNIVERSAL_SEARCH_EVENTS_DATA = "GET_UNIVERSAL_SEARCH_EVENTS_DATA";
export const GET_UNIVERSAL_SEARCH_BLOGS_DATA = "GET_UNIVERSAL_SEARCH_BLOGS_DATA";
export const GET_UNIVERSAL_SEARCH_TRIPS_DATA = "GET_UNIVERSAL_SEARCH_TRIPS_DATA";
export const GET_UNIVERSAL_SEARCH_AWARDS_DATA = "GET_UNIVERSAL_SEARCH_AWARDS_DATA";


export function getUniversalSearchSpinner(flag) {
    return {
        type: GET_UNIVERSAL_SEARCH_SPINNER_STATUS,
        payload: flag,
    };
}
export function getUniversalSearchPeopleData(data) {
    return {
        type: GET_UNIVERSAL_SEARCH_PEOPLE_DATA,
        payload: data,
    };
}
export function getUniversalSearchPostsData(data) {
    return {
        type: GET_UNIVERSAL_SEARCH_POSTS_DATA,
        payload: data,
    };
}
export function getUniversalSearchEventsData(data) {
    return {
        type: GET_UNIVERSAL_SEARCH_EVENTS_DATA,
        payload: data,
    };
}
export function getUniversalSearchBlogsData(data) {
    return {
        type: GET_UNIVERSAL_SEARCH_BLOGS_DATA,
        payload: data,
    };
}
export function getUniversalSearchTripsData(data) {
    return {
        type: GET_UNIVERSAL_SEARCH_TRIPS_DATA,
        payload: data,
    };
}
export function getUniversalSearchAwardsData(data) {
    return {
        type: GET_UNIVERSAL_SEARCH_AWARDS_DATA,
        payload: data,
    };
}


export const getUniversalSearchData = (data) => {
    return async (dispatch, getState) => {
        await dispatch(getUniversalSearchSpinner(true));
        const token = await auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/search?search_type=${data.type ?? ""}&keyword=${data.keywords ?? ""}&page=${data.pageNo ?? 1}`, {
                    method: "GET",
                    headers: headerType.HeadersWithToken(token),
                });
                let response = await result.json();
                if (response.success === 200) {
                    await dispatch(getUniversalSearchSpinner(false));
                    const data = response.data;
                    data.peoples && await dispatch(getUniversalSearchPeopleData(data.peoples));
                    data.posts && await dispatch(getUniversalSearchPostsData(data.posts));
                    data.events && await dispatch(getUniversalSearchEventsData(data.events));
                    data.events && await dispatch(setCreateEvent(data.events?.data));
                    data.blogs && await dispatch(getUniversalSearchBlogsData(data.blogs));
                    data.blogs && await dispatch(setCreateBlog(data.blogs?.data));
                    data.trips && await dispatch(getUniversalSearchTripsData(data.trips));
                    data.trips && await dispatch(getTripList(data.trips?.data));
                    data.awards && await dispatch(getUniversalSearchAwardsData(data.awards));
                    data.awards && await dispatch(getAwardList(data.awards));
                    return response
                } else {
                    await dispatch(setCreateEvent([]));
                    await dispatch(getAwardList([]));
                    await dispatch(getTripList([]));
                    await dispatch(setCreateBlog([]));
                    
                    await dispatch(getUniversalSearchPostsData([]));
                    await dispatch(getUniversalSearchEventsData([]));
                    await dispatch(getUniversalSearchBlogsData([]));
                    await dispatch(getUniversalSearchTripsData([]));
                    await dispatch(getUniversalSearchAwardsData([]));
                    await dispatch(getUniversalSearchPeopleData([]));

                    await dispatch(getUniversalSearchSpinner(false));
                }
            } catch (e) {
                await dispatch(getUniversalSearchSpinner(false));
            }
        } else {
            await dispatch(getUniversalSearchSpinner(false));
            console.log('You need to login first');
        }
    };
};


// initialState
export const initialState = {
    universalSpinner: false,
    universalSearchPeopleData: [],
    universalSearchPostsData: [],
    universalSearchEventsData: [],
    universalSearchBlogsData: [],
    universalSearchTripsData: [],
    universalSearchAwardsData: [],

};

const ACTION_HANDLERS = {

    [GET_UNIVERSAL_SEARCH_SPINNER_STATUS]: (state, action) => {
        return {
            ...state,
            universalSearchData: action.payload,
        };
    },

    [GET_UNIVERSAL_SEARCH_PEOPLE_DATA]: (state, action) => {
        return {
            ...state,
            universalSearchPeopleData: action.payload,
        };
    },
    [GET_UNIVERSAL_SEARCH_POSTS_DATA]: (state, action) => {
        return {
            ...state,
            universalSearchPostsData: action.payload,
        };
    },
    [GET_UNIVERSAL_SEARCH_EVENTS_DATA]: (state, action) => {
        return {
            ...state,
            universalSearchEventsData: action.payload,
        };
    },
    [GET_UNIVERSAL_SEARCH_BLOGS_DATA]: (state, action) => {
        return {
            ...state,
            universalSearchBlogsData: action.payload,
        };
    },
    [GET_UNIVERSAL_SEARCH_TRIPS_DATA]: (state, action) => {
        return {
            ...state,
            universalSearchTripsData: action.payload,
        };
    },
    [GET_UNIVERSAL_SEARCH_AWARDS_DATA]: (state, action) => {
        return {
            ...state,
            universalSearchAwardsData: action.payload,
        };
    },

};

export default function universalSearchReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
