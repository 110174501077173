import React, { useState, useEffect, Fragment } from 'react';

import Modal from 'react-modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectionProfile,
  acceptRejectRequest,
  acceptRejectRequestWithVisitorId,
} from '../modules/networkConnection';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ConnectionModal from '../../../components/ConnectionModals/ConnectionModal';
import language from '../../../helpers/language';

export default function ViewRequest(props) {
  const [lang] = useState(language.getLang());
  const history = useHistory();
  const { connection_id, resource_id, user_id } = useParams();
  const dispatch = useDispatch();
  const [declinePopUp, setDelinePopUp] = useState(false);
  const [declineText, setDeclineText] = useState('');
  const [RequireText, setRequireText] = useState('');
  const [responseError, setResponseErrors] = useState('');
  const [userName, setUserName] = useState('');
  const [openConnectionModel, setOpenConnectionModel] = useState(false);

  useEffect(async () => {
    if (connection_id) await dispatch(connectionProfile(connection_id));
  }, []);
  const { connectionProfileSpinner, connectionProfileData } = useSelector(
    (state) => state.networkConnectionState
  );

  const handleAccept = async () => {
    const response = await dispatch(
      acceptRejectRequest({ connection_id: connection_id, accept_or_reject: 1 })
    );
    if (response?.success === 200) {
      history.push('/connection');
    } else {
      setResponseErrors(response?.errors?.exception);
    }
    setTimeout(() => {
      setResponseErrors('');
    }, 3000);
  };

  const handleDecline = async () => {
    await setUserName(
      connectionProfileData?.user_data?.restaurant_name ??
        connectionProfileData?.user_data?.company_name ??
        `${connectionProfileData?.user_data?.first_name} ${connectionProfileData?.user_data?.last_name}`
    );
    await setOpenConnectionModel(!openConnectionModel);
  };

  const handleModal = async (newValue) => {
    await setOpenConnectionModel(newValue);
  };

  return (
    <div className="viewRequest">
      {/* {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: user_id, name: userName, network: true, handleOpenModel: handleModal, from: `${lang?.Decline}`, visitor: false, view: true }} />}   */}
      <div className="discover_page mb-4">
        <div className="page_top_form">
          <div className="step">
            <div className="cancel">
              <Link to="/networking">
                <span>
                  <i className="fa fa-arrow-left pr-3" aria-hidden="true">
                    {' '}
                    &nbsp;
                  </i>
                  {lang?.View_Request}
                </span>
              </Link>
            </div>
            <div className="right__align_btn btn-white"></div>
          </div>
        </div>
      </div>
      <div className="tab__list">
        {openConnectionModel && (
          <ConnectionModal
            openModelData={{
              openModel: openConnectionModel,
              user_id: user_id,
              visitor_id: resource_id,
              name: userName,
              network: true,
              handleOpenModel: handleModal,
              from: `${lang?.Decline}`,
              visitor: false,
              view: true,
            }}
          />
        )}
        {connectionProfileSpinner ? (
          <FormSpinner />
        ) : (
          <div className="pageWCenter">
            <h4 style={{ color: 'red', textAlign: 'center' }}>
              {responseError}
            </h4>
            <div
              className=""
              onClick={() =>
                history.push(`${'/visitor-profile/'}${resource_id}`)
              }
            >
              <div className="user__pic m-auto">
                {connectionProfileData?.user_data?.avatar_id ? (
                  <img
                    src={`${connectionProfileData?.user_data?.avatar_id?.base_url}${connectionProfileData?.user_data?.avatar_id?.attachment_url}`}
                    alt="User Image"
                  />
                ) : (
                  <img
                    src="https://alysei.s3.us-west-1.amazonaws.com/uploads/2021/12/6971696IMG_1640327050627.jpg"
                    alt="User Image"
                  />
                )}
                <form className="cover__upload">
                  <label>
                    <input type="file" name="avatar" id="avatar_id" />
                  </label>
                </form>
              </div>
              <h4 className="user__nm font-18 text-black text-center mt-2">
                {connectionProfileData?.user_data?.company_name ??
                  connectionProfileData?.user_data?.restaurant_name ??
                  `${connectionProfileData?.user_data?.first_name} ${connectionProfileData?.user_data?.last_name}`}
              </h4>
            </div>
            <p className="text-center mt-2">
              {connectionProfileData?.user_data?.reason_to_connect !==
                'undefined' &&
                connectionProfileData?.user_data?.reason_to_connect}{' '}
            </p>
            <div className="ViewReqDetail">
              <div className="flex items-center justify-between mt-4 mb-4">
                <div className="view__hub__btn md:w-1/2 pr-1">
                  {' '}
                  <Link
                    className="btn BgTeal"
                    to="#"
                    onClick={() => handleAccept()}
                  >
                    {lang?.Accept}
                  </Link>{' '}
                </div>
                <div className="view__hub__btn md:w-1/2 pl-1">
                  <Link
                    className="btn BgDanger"
                    to="#"
                    onClick={() => handleDecline()}
                  >
                    {lang?.Decline}
                  </Link>
                </div>
              </div>
              {connectionProfileData?.certificates?.length > 0 &&
              connectionProfileData?.user_data ? (
                <Fragment>
                  <div className="about__product mt-2 mb-2">
                    <div className="mt-1 mb-1">
                      <h4 className="font-16">{lang?.VAT_No}</h4>
                      <p className="forINput textArea noHeight">
                        {connectionProfileData?.user_data?.vat_no == 'undefined'
                          ? ''
                          : connectionProfileData?.user_data?.vat_no}{' '}
                      </p>
                    </div>
                  </div>
                  {connectionProfileData?.user_data?.fda_no !== 'null' &&
                    connectionProfileData?.user_data?.fda_no && (
                      <div className="about__product mt-2 mb-2">
                        <div className="mt-1 mb-1">
                          <h4 className="font-16">{lang?.FDA_No}</h4>
                          <p className="forINput textArea noHeight">
                            {connectionProfileData?.user_data?.fda_no}{' '}
                          </p>
                        </div>
                      </div>
                    )}
                </Fragment>
              ) : (
                connectionProfileData?.about_member?.map((item, index) => {
                  return (
                    <div className="about__product mt-2 mb-2" key={index}>
                      <div className="mt-1 mb-1">
                        <h4 className="font-16">{item.title}</h4>
                        <p className="forINput textArea noHeight">
                          {item.value}{' '}
                        </p>
                      </div>
                    </div>
                  );
                })
              )}
              {connectionProfileData?.certificates?.map((item, index) => {
                let conservation_tag=null
                let proerties_tag=null
            
                if(item?.conservation_methods!==null){
                   conservation_tag=item?.conservation_methods[0]?.conservation_name
                }
                if(item?.product_properties!==null){
                   proerties_tag=item?.product_properties[0]?.properties_name
                }
                
                console.log(conservation_tag,proerties_tag,"tagg")
                console.log(item,"iteememem ")
                return (
                  <>
                    <div className="about__product mt-2 mb-2" key={index}>
                      <div className="mt-4 mb-4">
                        <h4 className="font-16">{item.option}</h4>
                      </div>
                      {/* ...................Conservation Methods............. */}
                      {item.conservation_methods && (
                        <div className="mt-4 mb-4">
                          <h4 className="font-16">{conservation_tag&&conservation_tag?conservation_tag:"Conservation Methods"}</h4>
                          {item?.conservation_methods?.map((option, index) => {
                            return (
                              <div className="textBlock" key={index}>
                                <p>{option?.option}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {/* ...................properties Methods............. */}
                      {item.product_properties && (
                        <div className="mt-4 mb-4">
                          <h4 className="font-16">{proerties_tag&&proerties_tag?proerties_tag:"Properties"}</h4>
                          {item?.product_properties?.map((option, index) => {
                            return (
                              <div className="textBlock" key={index}>
                                <p>{option?.option}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {/* -----------Photo of Label----------- */}
                      {item.photo_of_label && (
                        <div className="photoLevel">
                          <div className="flex flx-wrap items-center certificateLavel">
                            <div className="w-3/5 md:w-full sm:w-full md:w-full sm:w-full">
                              <div className="flex items-center">
                                <div className="icon mr-4">
                                  <span>
                                    <i
                                      className={
                                        item && item.photo_of_label
                                          ? 'fa fa-check-circle-o selected'
                                          : 'fa fa-circle-o notSelected'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                  </span>
                                </div>
                                <div className="textBlock">
                                  <h4 className="font-15">
                                    {lang?.Photo_of_Label}
                                  </h4>
                                  <p>
                                    {
                                      lang?.Upload_an_image_of_your_product_label
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-2/5 md:w-full sm:w-full gridBlockTwo">
                              <div className="view__hub__btn">
                                <div className="btnBox text-right">
                                  {item?.photo_of_label && (
                                    <a
                                      href={`${item?.base_url}${item?.photo_of_label}`}
                                      target={'_blank'}
                                      className="btn bgLight Primary"
                                    >
                                      {lang?.View}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* -----------fce sid_certification----------- */}
                      {item?.fce_sid_certification && (
                        <div className="photoLevel">
                          <div className="flex items-center certificateLavel">
                            <div className="w-3/5 md:w-full sm:w-full">
                              <div className="flex items-center">
                                <div className="icon mr-4">
                                  <span>
                                    <i
                                      className={
                                        item && item.fce_sid_certification
                                          ? 'fa fa-check-circle-o selected'
                                          : 'fa fa-circle-o notSelected'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                  </span>
                                </div>
                                <div className="textBlock">
                                  <h4 className="font-15">
                                    {lang?.Fce_Sid_Certification}
                                  </h4>
                                  <p>
                                    {
                                      lang?.Upload_an_image_of_your_FCE_SID_Certification
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-2/5 md:w-full sm:w-full gridBlockTwo">
                              <div className="view__hub__btn">
                                <div className="btnBox text-right mt-1">
                                  {item?.fce_sid_certification && (
                                    <a
                                      href={`${item?.base_url}${item?.fce_sid_certification}`}
                                      target={'_blank'}
                                      className="btn bgLight Primary"
                                    >
                                      {lang?.View}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* -----------phytosanitary_certificate----------- */}
                      {item?.phytosanitary_certificate && (
                        <div className="photoLevel">
                          <div className="flex items-center certificateLavel">
                            <div className="w-3/5 md:w-full sm:w-full">
                              <div className="flex items-center">
                                <div className="icon mr-4">
                                  <span>
                                    <i
                                      className={
                                        item && item.phytosanitary_certificate
                                          ? 'fa fa-check-circle-o selected'
                                          : 'fa fa-circle-o notSelected'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                  </span>
                                </div>
                                <div className="textBlock">
                                  <h4 className="font-15">
                                    {lang?.Phytosanitary_Certificate}
                                  </h4>
                                  <p>
                                    {
                                      lang?.Upload_an_image_of_your_Phytosanitary_Certificate
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-2/5 md:w-full sm:w-full gridBlockTwo">
                              <div className="view__hub__btn">
                                <div className="btnBox text-right mt-1">
                                  {item?.phytosanitary_certificate && (
                                    <a
                                      href={`${item?.base_url}${item?.phytosanitary_certificate}`}
                                      target={'_blank'}
                                      className="btn bgLight Primary"
                                    >
                                      {lang?.View}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* -----------packaging_for_usa----------- */}
                      {item?.packaging_for_usa && (
                        <div className="photoLevel">
                          <div className="flex items-center certificateLavel">
                            <div className="w-3/5 md:w-full sm:w-full">
                              <div className="flex items-center">
                                <div className="icon mr-4">
                                  <span>
                                    <i
                                      className={
                                        item && item.packaging_for_usa
                                          ? 'fa fa-check-circle-o selected'
                                          : 'fa fa-circle-o notSelected'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                  </span>
                                </div>
                                <div className="textBlock">
                                  <h4 className="font-15">
                                    {lang?.Packaging_For_Usa}
                                  </h4>
                                  <p>
                                    {
                                      lang?.Upload_an_image_or_PDF_of_your_Packaging_For_Usa
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="w-2/5 md:w-full sm:w-full gridBlockTwo">
                              <div className="view__hub__btn">
                                <div className="btnBox text-right mt-1">
                                  {item?.packaging_for_usa && (
                                    <a
                                      href={`${item?.base_url}${item?.packaging_for_usa}`}
                                      target={'_blank'}
                                      className="btn bgLight Primary"
                                    >
                                      {lang?.View}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* -----------food_safety_plan----------- */}
                      {item?.food_safety_plan && (
                        <div className="photoLevel">
                          <div className="flex items-center certificateLavel">
                            <div className="w-3/5 md:w-full sm:w-full">
                              <div className="flex items-center">
                                <div className="icon mr-4">
                                  <span>
                                    <i
                                      className={
                                        item && item.food_safety_plan
                                          ? 'fa fa-check-circle-o selected'
                                          : 'fa fa-circle-o notSelected'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                  </span>
                                </div>
                                <div className="textBlock">
                                  <h4 className="font-15">
                                    {lang?.Food_Safety_Plan}
                                  </h4>
                                  <p>
                                    {
                                      lang?.Upload_image_or_PDF_of_your_Food_Safety_Plan
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-2/5 md:w-full sm:w-full gridBlockTwo">
                              <div className="view__hub__btn">
                                <div className="btnBox text-right mt-1">
                                  {item?.food_safety_plan && (
                                    <a
                                      href={`${item?.base_url}${item?.food_safety_plan}`}
                                      target={'_blank'}
                                      className="btn bgLight Primary"
                                    >
                                      {lang?.View}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* -----------animal_helath_asl_certificate----------- */}
                      {item?.animal_helath_asl_certificate && (
                        <div className="photoLevel">
                          <div className="flex items-center certificateLavel">
                            <div className="w-3/5 md:w-full sm:w-full">
                              <div className="flex items-center">
                                <div className="icon mr-4">
                                  <span>
                                    <i
                                      className={
                                        item &&
                                        item.animal_helath_asl_certificate
                                          ? 'fa fa-check-circle-o selected'
                                          : 'fa fa-circle-o notSelected'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                  </span>
                                </div>
                                <div className="textBlock">
                                  <h4 className="font-15">
                                    {lang?.Animal_Helath_Asl_Certificate}
                                  </h4>
                                  <p>
                                    {
                                      lang?.Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="w-2/5 md:w-full sm:w-full gridBlockTwo">
                              <div className="view__hub__btn">
                                <div className="btnBox text-right mt-1">
                                  {item?.animal_helath_asl_certificate && (
                                    <a
                                      href={`${item?.base_url}${item?.animal_helath_asl_certificate}`}
                                      target={'_blank'}
                                      className="btn bgLight Primary"
                                    >
                                      {lang?.View}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>

            {/* connect Popup Start */}
            {/* <div className="view__hub__btn">
                        <a className="btn BgTeal" onClick={() => setConnectPopup(!connectPopup)}>Connect</a>
                    </div>*/}
            {declinePopUp && (
              <Modal
                ariaHideApp={false}
                isOpen={declinePopUp}
                style={{
                  overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                  },
                  content: {
                    position: 'absolute',
                    top: '0',
                    left: '0%',
                    right: '0%',
                    bottom: '200px',
                    border: '1px solid #ccc',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    padding: '0',
                    // outline: 'none',
                  },
                }}
              >
                <div className="popup_header flex justify-between items-center">
                  <h4 className="page__title">{lang?.Decline_Request}</h4>
                  <button
                    className="close_btn--modal"
                    onClick={() => setDelinePopUp(!setDelinePopUp)}
                  >
                    {' '}
                    {lang?.close}{' '}
                  </button>
                </div>
                <div className="card card--block modal_box">
                  <p className="font-16 mb-4">{lang?.Decline_Request}</p>
                  <p style={{ color: 'red' }}>{RequireText}</p>

                  <form>
                    <textarea
                      className="write_post-area w-full"
                      placeholder={lang?.Enter_your_text}
                      rows="2"
                      value={declineText}
                      onChange={(e) => setDeclineText(e.target.value)}
                    />
                  </form>
                  <div className="view__hub__btn">
                    <button
                      type="button"
                      className="btn btnTeal"
                      onClick={() => handleDecline()}
                    >
                      {' '}
                      {lang?.Confirm}{' '}
                    </button>
                  </div>
                </div>
                <div></div>
              </Modal>
            )}
            {/* connect Popup End */}
          </div>
        )}
      </div>
    </div>
  );
}
