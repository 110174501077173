import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormError from "../../../components/Errors/FormError";
import userProfile from "../../../assets/images/dummy-profile-pic.jpg";
import banner from "../../../assets/images/dummy-image-landscape.jpg";
import constant from "../../../helpers/constant";
import language from "../../../helpers/language";
import diamond from '../../../assets/images/badges/Diamond.svg'
import Free from '../../../assets/images/badges/Free.svg'
import Gold from '../../../assets/images/badges/Gold.svg'
import Platinum from '../../../assets/images/badges/Platinum.svg'
import BadgeButton from "./BadgeButton";
import { checkSubscription } from "../modules/HomeModule";




export default function HomeLeftBlock() {
  const dispatch = useDispatch()
  const [lang] = useState(language.getLang());
  const { profileData, avatarSpinner, errImageMessage } = useSelector(
    (state) => state.memberProfileData
  );
  const { get_Subscription_Status_Data } = useSelector(
    (state) => state.activityFeedStatus
  );
  const [badge,setBadge]=useState(false)

  let subscriptionData = get_Subscription_Status_Data?.subscription?.subscription_plan

  useEffect(() => {
    dispatch(checkSubscription());
  }, []);

  useEffect(() => {
    let role = JSON.parse(localStorage.getItem('USER_TYPE'));
   
    if(role.role_id === 3){
      setBadge(true)
    }
  },[]);

  console.log(get_Subscription_Status_Data, "get_Subscription_Status_Data")

  return (
    <div>
      <div className="discoverPage">
        <div className="card card--block">
          <div className="user__profile__banner">
            <img src={profileData?.data?.user_data?.cover_id !== undefined && profileData?.data?.user_data?.cover_id !== null
              ? `${profileData?.data?.user_data?.cover_id.base_url}${profileData?.data?.user_data?.cover_id?.attachment_url}` : banner} alt="Profile Back Banner" />
            <div className="user__pic"
              style={{ border: `${"0.313rem solid"}#${profileData?.data?.user_data?.profile_color}`, }} >
              <img src={profileData?.data?.user_data?.avatar_id !== undefined && profileData?.data?.user_data?.avatar_id !== null
                ? `${profileData.data?.user_data?.avatar_id?.base_url}${profileData?.data?.user_data?.avatar_id?.attachment_medium_url}`
                : userProfile
              } alt="Profile Image" className="homeLeftBanner" />
            </div>
            {badge&&<BadgeButton Image={subscriptionData?.name == "Free" && Free || subscriptionData?.name == "Gold" && Gold || subscriptionData?.name == "Diamond" && diamond || subscriptionData?.name == "Platinum" && Platinum} />}

            {errImageMessage.field == "avatar_id" ? (
              <FormError msg={errImageMessage.msg} />
            ) : null}
          </div>
          <div className="profile__bg__white left-block">
            <div className="profile_title">
              {profileData?.data?.user_data ? (
                <h4> {profileData.data?.user_data?.restaurant_name ?? profileData.data?.user_data?.company_name ?? `${profileData?.data?.user_data?.first_name} ${profileData?.data?.user_data?.last_name}` ?? profileData?.data?.user_data?.username}</h4>
              ) : (<h4>{lang?.User_Name}</h4>)}
            </div>
            <div className="flex mt-3 mb-3 profile__Post">
              <div className="w-1/3">
                <div className="outer__div">
                  <h6> {lang?.Posts}{" "}<span className="flex">{profileData?.data?.post_count}</span></h6>
                </div>
              </div>
              <div className="w-1/3">
                <div className="outer__div">
                  <h6>{lang?.Connections}{" "} <span className="flex">{profileData?.data?.connection_count} </span></h6>
                </div>
              </div>
              {profileData?.data?.user_data?.role_id !== constant.rolesId.voyagers && <div className="w-1/3">
                <div className="outer__div">
                  <h6> {lang?.Followers}{" "} <span className="flex"> {profileData?.data?.follower_count} </span> </h6>
                </div>
              </div>}
              {profileData?.data?.user_data?.role_id === constant.rolesId.voyagers && <div className="w-1/3">
                <div className="outer__div">
                  <h6> {lang?.Following}{" "} <span className="flex"> {profileData?.data?.following_count} </span> </h6>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
