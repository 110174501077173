import React from 'react'
import BuyerHeader from '../DashboardView/BuyerHeader'
import SideBar from '../SideBar'
import InnerSideBar from './SettingSideBar/InnerSideBar'
import { Link } from "react-router-dom";
import ChangePasswordView from './ChangePasswordView/ChangePasswordView';

export default function ChangePassword() {
    return (
        <>
            <section className='buyerDashboard'>
                <div className='dashboardLayout'>
                    <div className='headerBlock'>
                        <BuyerHeader
                            pageTitle="Settings"
                        />
                    </div>
                    <div className='sideBarBlock'>
                        <SideBar />
                    </div>
                    <div className='centerContent'>
                        <div className='pageContent'>
                            <div className=''>
                                <div className='newDashboard bgWhite'>
                                    <div className='card card--block p-0 noRadius'>
                                        <div className='flexBox'>
                                            <InnerSideBar />
                                            <ChangePasswordView />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
