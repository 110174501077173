import React, { Fragment, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import constant from "../../../helpers/constant";
import NetworkLeftNavigation from "./NetworkLeftNavigation";
import { acceptRejectRequest, fetchConnectionTabs, getConnectionTabsData } from "../modules/networkConnection";
import NotFound from "../../../components/NetworkConnection/NotFound";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import ReactPaginate from "react-paginate";
import ConnectionModal from "../../../components/ConnectionModals/ConnectionModal";
import language from "../../../helpers/language";
import { setProfileTabIndex } from "../../profile/modules/profile";
import MemberType from "../../../components/MemeberType";
import auth from "../../../helpers/auth";
import firebase from "../../../config/firebase";

export default function Invitation() {
    const [lang] = useState(language.getLang());
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [isTrue, setIsTrue] = useState(false);
    const [userId, setUserId] = useState('');
    const [visitorId, setvisitorId] = useState('');
    const [connectionID, setConnectionId] = useState();
    const [userName, setUserName] = useState("");
    const [openConnectionModel, setOpenConnectionModel] = useState(false);
    const [alyseiReview, setAlyseiReview] = useState(false);
    const [userType, setuserType] = useState();

    const { connectionTabs, connectionSpinner } = useSelector((state) => state.networkConnectionState);
    const { profileData } = useSelector((state) => state.memberProfileData);


    useEffect(async () => {
        document.body.classList.remove("hidePageScroll");
        const userType = await auth.getUserType();
        await setuserType(JSON.parse(userType));
        await dispatch(fetchConnectionTabs(constant.tabs.invitation, page));
        await dispatch(setProfileTabIndex(0));
        setIsTrue(true);
    }, [])


    useEffect(async () => {
        const userId = await profileData?.data?.user_data?.user_id
        if (userId) {
            const fetchUserNode = firebase.database().ref("users").child(userId)
            fetchUserNode.on("value", async (snapshot) => {
                const response = snapshot.val();
                await setAlyseiReview(response?.alysei_approval);
            });
        }
    }, [profileData?.data?.user_data?.user_id, alyseiReview, localStorage.getItem("alysei_review")]);

    const handleAccept = async (connectionId) => {
        const response = await dispatch(acceptRejectRequest({ connection_id: connectionId, accept_or_reject: 1 }));
        if (response.success === 200) {
            history.push('/connection');
        }

    }

    const handleDecline = async (item) => {
        await setUserId(item?.user_id)
        await setvisitorId(item?.resource_id)
        await setUserName(item?.user?.restaurant_name ?? item?.user?.company_name ?? `${item?.user?.first_name} ${item?.user?.last_name}`);
        await setOpenConnectionModel(!openConnectionModel);

    }

    const handlePageClick = async (e) => {
        let pageNo = e.selected + 1
        await setPage(page);
        await dispatch(fetchConnectionTabs(constant.tabs.invitation, pageNo));
    };

    const handleModal = async (newValue, res) => {
        if (res.success === 200) {
            const newdata = connectionTabs.data?.data?.filter(item => item.connection_id !== parseInt(connectionID));
            const finalValue = {
                ...connectionTabs,
                data: { ...connectionTabs.data, data: newdata }
            }
            await dispatch(getConnectionTabsData(finalValue));
        }
        await setOpenConnectionModel(newValue);
    }

    return (
        <Fragment>
            {(JSON.parse(auth.getUserType())?.role_id !== constant.rolesId.voyagers) && localStorage.getItem("alysei_review") != true && (alyseiReview === 'false' || alyseiReview === false)
                ? <Redirect to='/membership' />
                :
                <div className="home__page b2b hubMember">
                    {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, visitor_id: visitorId, name: userName, network: true, handleOpenModel: handleModal, from: `${lang?.Decline}`, visitor: false, view: false }} />}
                    <div className="servics_blocks">
                        <div className="site-align top-align">
                            {/* Left Block */}
                            <NetworkLeftNavigation />
                            {/* Middle Block */}
                            <div className="servics_blocks middle-long__section-block middleBlockBg">
                                <div className="">

                                    {connectionTabs?.errors || connectionTabs?.data?.data?.length === 0 ?
                                        <Fragment><div className="medium_title"><h1 className="font-18 text-black">{lang?.Invitations}</h1></div><NotFound errors={connectionTabs?.data?.data?.length === 0 ? `${lang?.No_Invitations_found}` : connectionTabs?.errors?.exception} /></Fragment>
                                        : <div className="medium_title"><h1 className="font-18 text-black">{lang?.Invitations}</h1></div>}
                                    {connectionSpinner ? <FormSpinner /> :
                                        <div className="card--section site-align top-align flex-wrap">
                                            {connectionTabs?.data?.data?.length > 0 &&connectionTabs?.data?.data?.map((item, index) => {
                                                console.log('ok not done!');
                                                return (
                                                    <div className="card card--block" key={index}>
                                                        <div className="hubUser" onClick={() => history.push(`${"/visitor-profile/"}${item.user && item.user.user_id}`)} >
                                                            <div className="feed-user-info mb-2">
                                                                <div className="user__pic">
                                                                    {
                                                                        item?.user?.avatar_id ?
                                                                            <img src={`${item.user.avatar_id.base_url}${item.user.avatar_id.attachment_url}`} alt="User Image" /> :
                                                                            {/* <ContentLoader viewBox="0 0 380 70">
                                                                            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                            </ContentLoader> */}
                                                                    }
                                                                </div>
                                                                <div className="user__detail-data"> <span className="user__name">
                                                                {item && item.user ? 
                                                                    <Link to="#">
                                                                        {/* {(item?.user?.first_name !== null && item?.user?.last_name !== null &&
                                                                    `${item?.user?.first_name}${item?.user?.last_name}`) ||
                                                                    (item?.user?.company_name) || (item?.user?.restaurant_name)} */}
                                                                        {item?.user?.restaurant_name ?? item?.user?.company_name ?? `${item?.user?.first_name} ${item?.user?.last_name}`}
                                                                    </Link>
                                                                    :
                                                                    {/* <ContentLoader viewBox="0 0 380 70">
                                                                    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                    </ContentLoader> */}
                                                                }
                                                                {item && item.user ? 
                                                                    <MemberType item={item?.user} />
                                                                    :
                                                                    {/* <ContentLoader viewBox="0 0 380 70">
                                                                    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                    </ContentLoader> */}
                                                                }
                                                                    {/* <span>@username</span> */}
                                                                </span>
                                                                </div>
                                                            </div>
                                                            {item && item.user ? 
                                                            <p>{item.reason_to_connect?.length > 40 ? item?.reason_to_connect?.slice(0, 30) + "..." : (item.reason_to_connect != "undefined") && item?.reason_to_connect} </p>
                                                            :
                                                            {/* <p><ContentLoader viewBox="0 0 380 70">
                                                                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader></p> */}
                                                            }
                                                        </div>
                                                        <div className="viewReq mt-4">
                                                            {item && item.user ? 
                                                                <Link to={`${"/view-request"}/${item.connection_id}/${item && item.resource_id}/${item.user_id}`} className="btn bg-Primary w-full">{lang?.View_Request}</Link>
                                                                :
                                                                {/* <ContentLoader viewBox="0 0 380 70">
                                                                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader> */}
                                                            }
                                                        </div>
                                                        <div className="card-form">
                                                            <div className="flex items-center justify-between">
                                                                <div className="view__hub__btn md:w-1/2 pr-1">
                                                                {item && item.user ? 
                                                                <Link to="#" className="btn BgTeal" onClick={() => handleAccept(item.connection_id)}>{lang?.Accept}</Link> 
                                                                :
                                                                {/* <ContentLoader viewBox="0 0 380 70">
                                                                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader> */}
                                                                }
                                                                </div>
                                                                <div className="view__hub__btn md:w-1/2 pl-1"> 
                                                                {item && item.user ? 
                                                                <Link to="#" className="btn BgDanger" onClick={() => { setConnectionId(item.connection_id); handleDecline(item) }}>{lang?.Decline}</Link>
                                                                :
                                                                {/* <ContentLoader viewBox="0 0 380 70">
                                                                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader> */}
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </div>
                                {isTrue && connectionTabs?.data?.total > 10 &&
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={Math.ceil((connectionTabs?.data?.total) / (connectionTabs?.data?.per_page))}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />}
                            </div>
                            {/* Right Block */}
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}
