import React, { useEffect, useState,useRef } from 'react'
import { getSingleTransactionDetails } from '../modules/marketplace';
import { useHistory, useParams } from 'react-router-dom';
import language from '../../../helpers/language';
import DashboardSideBar from './DashboardSideBar';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

export default function SingleTransactionView() {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const { id } = useParams();
    const history = useHistory();
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [lang] = useState(language.getLang());
    const [activeTab, setActiveTab] = useState("transaction-management");

    useEffect(() => {
        getSingleTransaction()
    }, []);

    const selectTab = async (tab) => {
        setActiveTab(tab);
    }

    const onTabChange = (tab) => {
        localStorage.setItem("tab", tab);
        history.push('/marketplace/dashboard');
    }

    const getSingleTransaction = async () => {
        const transactionResponse = await getSingleTransactionDetails(id);
        if (transactionResponse?.success === 200) {
            setTransactionDetails(transactionResponse?.data)
        } else {
            setTransactionDetails([]);
        }
    }


    const { buyer_info, order_info, order_item_info, paid_amount, currency, order_id, created_at, producer_payment_request, admin_payment_made } = transactionDetails ?? {};
    return (
        <>
            <div className='servics_blocks dashboardView order_mgmt_detail'>
                <div className='site-align top-align'>
                    <div className='left__section-block sm:mb-2 md:mb-2'>
                        <div className='tabLeftBlock'>
                            <DashboardSideBar lang={lang} selectTab={selectTab} activeTab={activeTab} id={id} />
                        </div>
                    </div>
                    
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>
                        <div className="product-title">
                            <div className="flex flex-wrap justify-between items-center">
                                <h4 className="mb-0">
                                    {lang?.Transaction_details}
                                </h4>
                                <div className="inner-btn-box">                            
                                    <a className="btn btn-blue" onClick={() => handlePrint()}>
                                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
                                            <path d="M6.875 0C5.51787 0 4.4 1.06974 4.4 2.36842V3.15789H3.575C1.61043 3.15789 0 4.69898 0 6.57895V13.9474C0 15.2461 1.11787 16.3158 2.475 16.3158H4.4V17.6316C4.4 18.9303 5.51787 20 6.875 20H15.125C16.4821 20 17.6 18.9303 17.6 17.6316V16.3158H19.525C20.8821 16.3158 22 15.2461 22 13.9474V6.57895C22 4.69898 20.3896 3.15789 18.425 3.15789H17.6V2.36842C17.6 1.06974 16.4821 0 15.125 0H6.875ZM6.875 1.57895H15.125C15.5906 1.57895 15.95 1.9229 15.95 2.36842V3.15789H6.05V2.36842C6.05 1.9229 6.40943 1.57895 6.875 1.57895ZM3.575 4.73684H18.425C19.4975 4.73684 20.35 5.5526 20.35 6.57895V13.9474C20.35 14.3929 19.9906 14.7368 19.525 14.7368H17.6V12.8947C17.6 11.5961 16.4821 10.5263 15.125 10.5263H6.875C5.51787 10.5263 4.4 11.5961 4.4 12.8947V14.7368H2.475C2.00943 14.7368 1.65 14.3929 1.65 13.9474V6.57895C1.65 5.5526 2.50247 4.73684 3.575 4.73684ZM6.875 12.1053H15.125C15.5906 12.1053 15.95 12.4492 15.95 12.8947V17.6316C15.95 18.0771 15.5906 18.4211 15.125 18.4211H6.875C6.40943 18.4211 6.05 18.0771 6.05 17.6316V12.8947C6.05 12.4492 6.40943 12.1053 6.875 12.1053Z" fill="white" />
                                        </svg>
                                        {lang?.Print}
                                    </a>

                                    <a className="btn btn--gray" onClick={() => onTabChange("transaction-management")}>
                                        <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                                            <path d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z" fill="black" />
                                        </svg>
                                        {lang?.Back}
                                    </a>
                                </div>
                            </div>
                        </div>
                       
                        <div  ref={componentRef} className='orderDetailHeader TransactionDetail '>
                            <div className="product-list">
                                <div className='recentOrder'>
                                    <h1>{lang?.Transaction_id} : #{id} </h1>
                                    <div className='innderHeader mb--30 justify--between'>
                                        <div className='itemTable TransTable'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{lang?.price}</th>
                                                        <th className='transStatus'>{lang?.Payment_status}</th>
                                                        <th className='transactionDate'>{lang?.Date_time}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{currency}{paid_amount}</td>
                                                        {order_info?.status === "cancelled" ? <td>{order_info?.status}</td> : <td>{(admin_payment_made && producer_payment_request) ? lang?.Paid : producer_payment_request ? lang?.Pennding : lang?.Not_requested}</td>}
                                                        <td>{moment(created_at).format("DD/MM/YYYY h:m A")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <br />
                                        <div className='itemTable'>
                                            <table className='CustomDept'>
                                                <thead>
                                                    <tr>
                                                        <th>{lang?.Customer_Name}</th>
                                                        <th>{lang?.Contact}</th>
                                                        <th>{lang?.Emails}</th>
                                                        <th className='CustAddress'>{lang?.Only_address}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{buyer_info?.company_name}</td>
                                                        <td>{`+${buyer_info?.country_code} ${buyer_info?.phone}`}</td>
                                                        <td>{buyer_info?.email ?? "N/A"}</td>
                                                        <td>{buyer_info?.address}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <br />
                                        <div className='itemTable CustDateTable'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className='OrderId'>{lang?.Order_id}</th>
                                                        <th className='CustDate'>{lang?.Date_time}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{order_id}</td>
                                                        <td>{moment(order_info?.created_at).format("DD/MM/YYYY h:m A")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <br />
                                        <div className='itemTable transProduct'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{lang?.Image}</th>
                                                        <th className='transactionPrice'>{lang?.only_product}</th>
                                                        <th>{lang?.Category}</th>
                                                        <th>{lang?.Unit_price}</th>
                                                        <th>{lang?.Quantity}</th>
                                                        <th>{lang?.Total}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {order_item_info && order_item_info?.map(({ product_price, quantity, product_info }, i) => {
                                                        return (
                                                            <tr>
                                                                <td className='cart-product-list'><span>{product_info?.product_gallery?.length > 0 ? product_info?.product_gallery?.map(({ base_url, attachment_medium_url }, i) => {
                                                                    if (i < 1)
                                                                        return (
                                                                            <img src={`${base_url}${attachment_medium_url}`} alt="Product-Image" />
                                                                        )
                                                                }) : lang?.N_A}</span></td>
                                                                <td className='transactionPrice'>{product_info?.title ?? lang?.N_A}</td>
                                                                <td>{product_info?.product_category?.option ?? lang?.N_A}</td>
                                                                <td>{currency}{product_price}</td>
                                                                <td>{quantity}</td>
                                                                <td>{currency}{(product_price * quantity)}</td>
                                                            </tr>
                                                        )
                                                    })}


                                                </tbody>
                                            </table>
                                        </div>

                                        <br />
                                        <div className='itemTable transBilling'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>{lang?.Billing_address}</th>
                                                        <th>{lang?.Shipping_address}</th>
                                                        <th>{lang?.Payment}</th>
                                                        <th>{lang?.Order_status}</th>
                                                        <th className='TransOrderSum'>{lang?.Order_summary}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{order_info?.billing_address?.first_name} {order_info?.billing_address?.last_name} {order_info?.billing_address?.city}<br />
                                                            {order_info?.billing_address?.state} {order_info?.billing_address?.zipcode}{order_info?.billing_address?.country}</td>
                                                        <td>{order_info?.shipping_address?.first_name} {order_info?.shipping_address?.last_name} {order_info?.shipping_address?.city}<br />
                                                            {order_info?.shipping_address?.state} {order_info?.shipping_address?.zipcode}{order_info?.shipping_address?.country}</td>
                                                        <td>{"Card"}</td>
                                                        <td>{order_info?.status}</td>
                                                        <td>
                                                            <div className='order_Span'>
                                                                <p>{lang?.Item}({`${order_info?.num_items_sold}`}) {lang?.Subtotal} :<span>{`${order_info?.currency}${order_info?.total_seles - order_info?.tax_total}`}</span></p>
                                                                <p>{lang?.Total} :  <span>{`${order_info?.currency}${order_info?.net_total}`}</span></p>
                                                                <p>{lang?.Vat} :  <span>{order_info?.currency}{order_info?.tax_total}</span></p>
                                                                {order_info?.shipping_total > 0 && <p>{lang?.Shipping_charge} :  <span>{`${order_info?.currency}${order_info?.shipping_total}`}</span></p>}
                                                                <p>{lang?.Grand_total} :   <span>{`${order_info?.currency}${Number(order_info?.total_seles) + Number(order_info?.shipping_total)}`}</span></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
