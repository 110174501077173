import React from 'react'
import logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import BuyerHeader from './DashboardView/BuyerHeader';
import RecentRequest from './DashboardView/RecentRequest';
import GraphView from './DashboardView/GraphView';
import WhereToStartView from './DashboardView/WhereToStartView';
import ProcessView from './DashboardView/ProcessView';
import WhyChoose from './DashboardView/WhyChoose';
import PartnerView from './DashboardView/PartnerView';
import FooterView from './FooterView';
import AboutUsView from './DashboardView/AboutUs/AboutUsView';

export default function Dashboard() {
    return (
        <>
            <section className='buyerDashboard'>
                <div className='dashboardLayout'>
                    <div className='headerBlock'>
                        <BuyerHeader 
                            pageTitle="Dashboard"
                        />
                    </div>
                    <div className='sideBarBlock'>
                        <SideBar />
                    </div>
                    <div className='centerContent'>
                        <div className='pageContent'>
                            <h4 className='secTitle'>Overview</h4>

                            {/* Graph Section View Start */}

                            <div className='topLayout sectionGap'>
                                <div className='graphView'>
                                    <GraphView />
                                </div>
                                <div className='recentReq'>
                                    <RecentRequest />
                                </div>
                            </div>

                            {/* Graph Section View End */}

                            {/* Where To Section Start */}
                            {/* <div className='whereToStart sectionGap'>
                                <WhereToStartView />
                            </div> */}
                            {/* Where To Section End */}

                            {/* About Section Start */}
                            <div className='aboutUs sectionGap'>
                                <AboutUsView />
                            </div>
                            {/* About Section End */}

                            {/* Why Choose Section Start */}
                            <div className='whyChoose sectionGap'>
                                <WhyChoose />
                            </div>
                            {/* Why Choose Section End */}
                            
                            {/* Process Section Start */}
                            <div className='ourProcess sectionGap'>
                                <ProcessView />
                            </div>
                            {/* Process Section End */}


                            <div className='Partners sectionGap'>
                                <PartnerView />
                            </div>

                            {/* Footer Section Start */}
                            <div className='footerView mt-4 mb-4'>
                                <FooterView />
                            </div>
                            {/* Footer Section End */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
