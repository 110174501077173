import REACT_APP_API from "../../../config/environment";
import headerType from "../../../helpers/headerType";
export const LOGIN_P_SET_LOGIN_SPINNER_STATUS = "LOGIN_P_SET_LOGIN_SPINNER_STATUS";
export const LOGIN_P_SET_LOGIN_FORM_DATA = "LOGIN_P_SET_LOGIN_FORM_DATA";
export const PLAN_DATE = "PLAN_DATE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export function setloginSpinner(flag) {
  return {
    type: LOGIN_P_SET_LOGIN_SPINNER_STATUS,
    payload: flag,
  };
}

export function setLoginData(data) {
  return {
    type: LOGIN_P_SET_LOGIN_FORM_DATA,
    payload: data,
  };
}

export function setPlanDate(data) {
  return {
    type: PLAN_DATE,
    payload: data,
  };
}

export function setLang(data) {
  return {
    type: SET_LANGUAGE,
    payload: data,
  };
}

export function logIn(data) {
  return async (dispatch, getState) => {
    await dispatch(setloginSpinner(true));
    const token = `Basic ${btoa(`${data.email}:${data.password}`)}`;
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/user/login`, {
        method: "POST",
        cache: "no-cache",
        headers: headerType.HeadersWithTokenAndJson(token,true),
      });
      let response = await result.json();
      if (!response.success) {
        // alert(JSON.stringify(response.error))
        await dispatch(setloginSpinner(false)); 
        return response
      }else{
        await dispatch(setLoginData(response.data))
        await dispatch(setPlanDate(response.default_subscription_expiry_date))
        await dispatch(setloginSpinner(false));
        return response
      }
    } catch (e) {
      await dispatch(setloginSpinner(false));
      console.log(e);
    }
  };
}

export const initialState = {
  loginSpinner: false,
  userInfo:{},
  planExpireDate:'',
  lang:''
};

export const loginActions = {
  setloginSpinner,
  setLoginData
};

const ACTION_HANDLERS = {
  [LOGIN_P_SET_LOGIN_FORM_DATA]:(state, action)=>{
    return {
      ...state,
      userInfo: action.payload,
    }
  },
  [PLAN_DATE]:(state, action)=>{
    return {
      ...state,
      planExpireDate: action.payload,
    }
  },
  [LOGIN_P_SET_LOGIN_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      loginSpinner: action.payload,
    };
  },
  [SET_LANGUAGE]: (state, action) => {
    return {
      ...state,
      lang: action.payload,
    };
  },
};

export default function loginReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
