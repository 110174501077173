import React from "react";
import SectionTitleCenter from "../SectionTitleCenter";
import CardLayout from "./CardLayout";

export default function WhyChoose() {
  return (
    <>
      <div className="chooseAlysei card card--block">
        <div className="newSiteWidth">
          <div className="titleBlock">
            <SectionTitleCenter
              titleSmall="Alysei for Buyers"
              Title="High-Quality Products on Your Shelves Faster and at Lower Costs"
              Description="The demand for Italian products on the Global market is continually growing. Made in Italy Food and Beverage enjoys excellent appeal among customers, with supermarkets representing the preferred purchasing channel for food specialties (69% of the total)."
            />
            <div className="bgBlue">
              <p className="bg-blue-content">
                Alysei is specifically designed to enable the connection between
                Global Buyers and the right companies depending on their
                specific needs and requirements. This will dramatically shorten
                the expensive and time consuming process to search and connect
                with the right qualified Italian Producers and at the right
                time. Not only saving time and money but more importantly
                finding the right path for your business expansion in your own
                territory.
              </p>
            </div>
            <div className="cardGroup">
              <CardLayout
                cardTitle="Cut Sourcing Times"
                cardDescription="Sourcing times reduced by 60%. Get new products on shelves in record times."
              />
              <CardLayout
                cardTitle="Find Regional Products"
                cardDescription="Search, select, send product request and engage  with Certified Producers."
              />
              <CardLayout
                cardTitle="Improve your Margin"
                cardDescription="Shortlist and engage with active, verified Alysei Producers for targeted cost-saving."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
