import React, { useState, useEffect } from 'react'
import FormSpinner from '../../../../../components/Spinners/FormSpinner'; 
import language from '../../../../../helpers/language'
import { setChangePassword } from '../../../modules/Buyermodule';
import { useHistory } from "react-router-dom";
export default function ChangePasswordView() { 
    const [lang] = useState(language.getLang());
    const history = useHistory();
    const intialValues = {
        oldPassword : '',
        newPassword : '',
        confirmPassword: '',
    }
    const [changePasswordState, setChangePasswordState] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disabledTrue, setDisabledTrue] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmitting){
            changePasswordForm();
        }
    },[formErrors]);

     // Options set in states
    const handleChange = (e) => {
        const { name, value } = e.target;
        setChangePasswordState({ ...changePasswordState, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
    };
    // Form validation
    const validate = (values) => {
        let errors = {};
        if (!values.oldPassword) {
        errors.oldPassword = `${lang?.Option_require}`;
        }
        if (!values.newPassword) {
        errors.newPassword = `${lang?.Option_require}`;
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = `${lang?.Option_require}`;
        }
        if (values.confirmPassword != values.newPassword) {
            errors.confirmPassword = `${lang?.NewAndConfirmPassNotMatch}`;
        }
        return errors;
    };

     // Check request validation
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormErrors(validate(changePasswordState));
        setIsSubmitting(true);
    };

    const changePasswordForm = async() => {
        await setIsLoader(true);
        await setDisabledTrue(true);
        const changePassword = {
            old_password : changePasswordState?.oldPassword,
            new_password : changePasswordState?.newPassword 
        }

        let response = await setChangePassword(changePassword);
        if(response?.success === 200){
            await setSuccessMessage(response?.message);
            await setChangePasswordState(intialValues);
            // setTimeout(() => {
            //     history.push("/register");
            //   }, 2600);

        }
        else{
            await setErrorMessage(response?.errors);
        }
        await setIsLoader(false);
        setTimeout(async() => {
            await setDisabledTrue(false);
            await setSuccessMessage("");
            await setErrorMessage("");
          }, 2500);
    }
  return (
    <>
        <div className='settingView'>
            <h4 className='pageTitle'>Change Password</h4>
            <p>Enter old password and new password to change password</p>
            <div className='formView'>
                <div className='error_message'>{errorMessage}</div>
                <div className='success_message'>{successMessage}</div>
                <form onSubmit={(event) => handleSubmit(event)}>
                    <div className='form__item whiteBg'>
                        <input type='password' placeholder='Old Password' name="oldPassword" value={changePasswordState.oldPassword} onChange={(e) => handleChange(e)}/>
                        <div className='error_message'>{formErrors?.oldPassword}</div>
                    </div>
                    <div className='form__item whiteBg'>
                        <input type='password' placeholder='New Password' name="newPassword" value={changePasswordState.newPassword} onChange={(e) => handleChange(e)}/>
                        <div className='error_message'>{formErrors?.newPassword}</div>
                    </div>
                    <div className='form__item whiteBg'>
                        <input type='password' placeholder='Confirm Password' name="confirmPassword" value={changePasswordState.confirmPassword} onChange={(e) => handleChange(e)}/>
                        <div className='error_message'>{formErrors?.confirmPassword}</div>
                    </div>
                    <div className='actionButoon mt-4'>
                        <button type='sumit' className='btn btn-blue style2' disabled={disabledTrue} >{isLoader ? <FormSpinner/> : 'Change Password' }</button>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
