/** @format */

import React, { useEffect, useState } from "react";
import ProducerPaymentSettingView from "../../../components/Dashboard/ProducerPaymentSetting/ProducerPaymentSettingView";
export default function StripeConnect({ lang, isStripeConnected }) {
    return (
      <ProducerPaymentSettingView
        lang={lang}
        isStripeConnected={isStripeConnected}
      />
    );
}