import React, { useState } from 'react';
import language from '../helpers/language';

const DeleteConfirmation = ({ onDelete, onCancel, title, notes, heading, subHeading, btnText, loader, message }) => {
  const [lang] = useState(language?.getLang());
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="custom_modal_width bg-white p-4 rounded-md shadow-md">
        <h2 className="text-lg  font-semibold mb-4">{heading}</h2>
        {message && <h5 className="text-lg textRed font-semibold mb-4">{message}</h5>}
        <p className="mb-4">{subHeading} {title}?</p>
        {notes && <p className="mb-4"><span className='noteBlock'>{lang?.Please_note}: </span> {notes}</p>}
        <div className="flex justify-end">
          <button
            onClick={onCancel}
            className="mr-2 bg-gray-300 hover:bg-gray-400 px-3 py-1 rounded-md"
          >
            {lang?.Cancel}
          </button>
          <button
            onClick={onDelete}
            className={`bg-${btnText === "Confirm" ? "green" : "red"}-500 px-3 py-1 rounded-md text-white`}
            disabled={loader}
          >
            {loader ? lang?.Loading : btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
