import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import img1 from "../../../assets/images/pizza-recipe.jpg";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import DashboardSideBar from './DashboardSideBar';
import language from '../../../helpers/language';
import { useSelector, useDispatch } from "react-redux";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import {
    setMyDashboardStatus,
    getSingleLead,
    uploadBuyerChatImageUpdatedLastActivity,
    blockBuyerUser,
    unBlockBuyerUser,
  } from "../modules/marketplace";
  import moment from "moment";
  import firebase from "../../../config//firebase";
  import Modal from "react-modal";
  import nodataimage from '../../../assets/images/no-data.svg'
  import uploadimg from '../../../assets/images/img-1.png'
import { getFilterResults } from "../../Buyer/modules/Buyermodule";
export default function LeadView() {
        const { uuid } = useParams();
        const dispatch = useDispatch();
        const [lang] = useState(language.getLang());
        const [requestData, setRequestData] = useState('');
        const [fetchChatList, setFetchChatList] = useState([]);
        const [message, setMessage] = useState("");
        const [image, setImage] = useState([]);
        const [imageError, setImageError] = useState("");
        const [disabledTrue, setDisabledTrue] = useState(false);
        const [isLoader, setIsLoader] = useState(false);
        const [imageCount, setImageCount] = useState(0);
        const [unreadMessageCount, setUnreadMessageCount] = useState(0);
        const [openBlockModal, setOpenBlockModal] = useState(false);
        const [openUnBlockModal, setOpenUnBlockModal] = useState(false);
        const [blockLoader, setBlockLoader] = useState(false);
        const [unBlockLoader, setUnBlockLoader] = useState(false);
        const [successMessage, setSuccessMessage] = useState("");
        const [isOpenImage, setIsOpenImage] = useState(false);
        const [userPhotosData, setUserPhotosData] = useState([]);
        const [imageIndex, setImageIndex] = useState(0);
        const history = useHistory();
        let { defaultProductTypes, defaultItalianRegionOPtions, defaultFdaOptions, createRequestSpinner, conservationLabelName, conservationMethods, propertyLabelName, productProperties, producers } = useSelector((state) => state.buyerModuleFilterData);
        localStorage.setItem("tab", 'newlead');
        let { myDashBoardStatus } = useSelector(
            (state) => state.marketPlaceReducerData
          );
        const [activeTab, setActiveTab] = useState("newlead");
        
        useEffect(async() => {
            await getSingleRequestData();
        },[uuid]);




        const getSingleRequestData = async() => {
            let leadResponse = await getSingleLead(uuid);
            console.log(leadResponse,"leadResponce")
            // if(leadResponse?.single_request
            //     ?.show!=1&&leadResponse?.single_request
            //     ?.views!=1){
            //         localStorage.setItem('tab', 'newlead');
            //          history.push('/marketplace/dashboard');
            // }
            await setRequestData(leadResponse);
        }
        const selectTab = async (tab) => {
            setActiveTab(tab);
            dispatch(setMyDashboardStatus(tab));
        };

        useEffect(async () => {
            if(requestData?.request){
                const fetchUserNode = firebase.database().ref("request").child(requestData?.request?.id).child(requestData?.request?.producer_id).child('message');
                fetchUserNode.on("value", async (snapshot) => {
                const response = snapshot.val();
                const result = [];
                for (let id in response) {
                    result.push({ id, ...response[id] });
                }
            
                await setFetchChatList(result);
                });

                await unreadChatCount();
            }
          }, [requestData]);

          //message save in state
          const handleChange = async(e) => {
            const { value } = e.target;
            await setMessage(value);
            await unreadChatCount();
          }

          const handleFile = async (event) => {
            let imageArr = [];
            await unreadChatCount();
            if (event.target.files.length > 5) {
              await setImageError("Images should be less than 6");
              document.getElementById("attachment_img").value = "";
            } else {
              Object.keys(event.target.files).map(async (item, i) => {
                if (event.target.files[item].size > 1000000) {
                  await setImageError(lang?.disputeImageSize);
                  document.getElementById("attachment_img").value = "";
                } else {
                  let reader = new FileReader();
                  reader.readAsDataURL(event.target.files[item]);
                  reader.onload = (e) => {
                    imageArr.push(event.target.files[item]);
                  };
                  await setImageCount(i+1);
                }
              });
              await setImage(imageArr);
              
            }
            setTimeout(() => {
              setImageError("");
            }, 3000);
          };

          const resetChatBox = async () => {
            await setMessage("");
            await setImage("");
            await setImageCount(0);
            document.getElementById("attachment_img").value = "";
          };

            // unreadcount Chat Node
            const unreadChatCount = () => {
                const fetchUserNode = firebase.database().ref("request").child(requestData?.request?.id).child(requestData?.request?.producer_id);
                fetchUserNode.on("value", async (snapshot) => {
                const response = snapshot.val();
                if (response?.senderId === requestData?.request?.producer_id) {
                  await setUnreadMessageCount(response?.totalmessage);
                } else {
                  await setUnreadMessageCount(0);
                }
                //return response;
                });
            };

          // Save Chat into the firebase
          const saveChatFirebase = async () => {
            if (image != "" || message != "") {
                setDisabledTrue(true);
                setIsLoader(true);
                const sendData = {
                    request_id: requestData?.request?.id,
                    chat_receiver_id : requestData?.request?.buyer_id,
                    image: image,
                  };
                const updateChat = await uploadBuyerChatImageUpdatedLastActivity(sendData);
                if (updateChat?.success) {
                var currentTimeStamp = Date.now();
                firebase
                  .database()
                  .ref("request")
                  .child(requestData?.request?.id)
                  .child(requestData?.request?.producer_id)
                  .update({
                    lastmessage: message,
                    lasttimestamp: currentTimeStamp,
                    read_status: false,
                    senderId: requestData?.request?.producer_id,
                    messageType: 0,
                    totalmessage: parseInt(unreadMessageCount) + 1,
                    producerresponse: 1,
                  });
                firebase.database().ref("request").child(requestData?.request?.id).child(requestData?.request?.producer_id).child('message').child(currentTimeStamp).set({
                id: requestData?.request?.buyer_id,
                timestamp: currentTimeStamp,
                message: message,
                senderId: requestData?.request?.producer_id,
                messageType: 0,
                attachments: updateChat?.attachments,
                });

                await setMessage("");
                await setImage("");
                await setImageCount(0);
                document.getElementById("attachment_img").value = "";

                setTimeout(() => {
                    setDisabledTrue(false);
                    setIsLoader(false);
                  }, 1000);
                }
            }
          };
        
        const isImgUrl = (url) => {
        return /\.(jpg|jpeg|png)$/.test(url);
        }

        const blockBuyer = async() => {
            await setBlockLoader(true);
            let apiResponse = await blockBuyerUser(requestData?.request?.buyer_id);
            if(apiResponse.success){
                await getSingleRequestData();
                await setBlockLoader(false);
                await setOpenBlockModal(false);
                await setSuccessMessage(apiResponse?.message);
            }
            setTimeout(() => {
                setSuccessMessage("");
              }, 2500);
            //console.log(apiResponse,"apiResponse");
        }

        const unBlockBuyer = async() => {
            await setUnBlockLoader(true);
            let apiResponse = await unBlockBuyerUser(requestData?.request?.buyer_id);
            if(apiResponse.success){
                await getSingleRequestData();
                await setUnBlockLoader(false);
                await setOpenUnBlockModal(false);
                await setSuccessMessage(apiResponse?.message);
            }
            setTimeout(() => {
                setSuccessMessage("");
              }, 2500);
        }
        
      
        const imageViewerHandler = (imgData) => {
            const transformedData = imgData
                .filter(url => {
                    const extension = url.split('.').pop().toLowerCase();
                    return extension !== 'pdf';
                })
                .map((url) => {
                    const extension = url.split('.').pop().toLowerCase(); // Extract file extension
                    const type = 'photo'; // Since PDFs are filtered out, all remaining files are considered photos
                    const altTag = 'image'; // Set alt tag for photos
            
                    return {
                        url: url,
                        type: type,
                        altTag: altTag
                    };
                });
            setUserPhotosData(transformedData);
            setIsOpenImage(true);
        };

        console.log(requestData,"request")
    
        return (
            <>
                <div className="message mt-4 mb-4 text-center text-green">
                    <h4 className="font-bold">{successMessage}</h4>
                </div>
                <div className="marketplace dashboard header">
                    {/* Banner Section */}
                    <div>
                        <div className="section__wrapper">
                            <div className="flex justify-between items-center">
                                <h6 className="back-btn-small">
                                    <Link to="/marketplace">
                                        <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                                        Back to Marketplace
                                    </Link>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="servics_blocks dashboardView dashboardInquiry viewDisputes">
                    <div className="site-align top-align">
                        <div className="left__section-block sm:mb-2 md:mb-2">
                            {/* Data coming soon */}
                            {/* Show sidebar here and remove Data coming soon text */}
                            <DashboardSideBar selectTab={selectTab}
                            activeTab={activeTab}
                            lang={lang}
                            id={uuid} />
                                
                        </div>
                            <div className="servics_blocks middle-long__section-block middleBlockBg disputes">
                                <div className="product-title">
                                    <div className="updateStatus text-center"></div>
                                    <div className="flex flex-wrap justify-between items-center">
                                        <h4>{requestData?.request?.title}</h4>
                                        <div className="productFilter disputesFilter">
                                            <div className="inner-btn-box">
                                                {requestData?.request?.blocked ? 
                                                <button className='btn btn--gray' type='button' onClick={() => setOpenUnBlockModal(true)}>Unblock Buyer</button>
                                                :
                                                <button className='btn btn--gray' type='button' onClick={() => setOpenBlockModal(true)}>Block Buyer</button>
                                                }
                                                <Link
                                                    to="/marketplace/dashboard"
                                                    className="btn btn--gray"
                                                >
                                                    <svg
                                                        width="18"
                                                        height="9"
                                                        viewBox="0 0 18 9"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z"
                                                            fill="black"
                                                        ></path>
                                                    </svg>
                                                    Back
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-list newLead">
                                    <div className="recentOrder secBlockGap">
                                        {requestData?.success && (
                                        <div className="itemTable itemTableScroll customer__table">
                                            <div className="message mt-4 mb-4 text-center text-green">
                                                <h4 className="font-bold"></h4>
                                            </div>
                                            <div className="showData">
                                                <div className="showDataRow grid4">
                                                    <div className="dataTitle">
                                                        <strong>ID</strong> <span>#{requestData?.request?.id}</span>
                                                    </div>
                                                    <div className="dataTitle">
                                                        <strong>Company Name</strong>{" "}
                                                        <span>{requestData?.request?.company_name ? requestData?.request?.company_name : '--'}</span>
                                                    </div>
                                                    {/* <div className="dataTitle">
                                                        <strong>Contact</strong>{" "}
                                                        {requestData?.request?.phone ? 
                                                        <span>{requestData?.request?.country_code ? `+${requestData?.request?.country_code}` : ''} {requestData?.request?.phone}</span>
                                                        : <span>--</span> }
                                                    </div> */}
                                                    <div className="dataTitle">
                                                        <strong>Location</strong>{" "}
                                                        <span>{`${requestData?.request?.country}` }</span>
                                                        {/* <span>{requestData?.request?.address ? requestData?.request?.address : '--'}</span> */}
                                                    </div>
                                                    <div className="dataTitle">
                                                        <strong>Product Type</strong>{" "}
                                                        <span>{requestData?.request?.product_type_id ? requestData?.request?.product_type_id : '--'}</span>
                                                    </div>
                                                    <div className="dataTitle">
                                                        <strong>{requestData?.request?.conservation_name?requestData?.request?.conservation_name:"Conservation Method"}</strong>{" "}
                                                        <span>{requestData?.request?.conservation_id ? requestData?.request?.conservation_id :"--"}</span>
                                                    </div>
                                                    {/* {
                                                        requestData?.request?.conservation_id!=null?(
                                                            <div className="dataTitle">
                                                        <strong>Conservation Method</strong>{" "}
                                                        <span>{requestData?.request?.conservation_id ? requestData?.request?.conservation_id :"--"}</span>
                                                    </div>
                                                        ):(
                                                    <div className="dataTitle">
                                                        <strong>{conservationLabelName}</strong>{" "}
                                                        <span>{requestData?.request?.product_type_id}</span>
                                                    </div>

                                                        )

                                                    } */}

                                                    <div className="dataTitle">
                                                        <strong>{requestData?.request?.property_name?requestData?.request?.property_name:"Properties"}</strong>{" "}
                                                        <span>{requestData?.request?.property_id ? requestData?.request?.property_id : '--'}</span>
                                                    </div>
                                                    <div className="dataTitle">
                                                        <strong>FDA Certificate</strong>{" "}
                                                        <span>{requestData?.request?.fda_option_id ? requestData?.request?.fda_option_id : '--'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="messageBlock mt-6">
                                                {requestData?.request?.blocked === false ?
                                                <div className="user_chat">
                                                {fetchChatList && fetchChatList.length > 0 && (
                                                    <div className="allChat">
                                                    {fetchChatList && fetchChatList.length > 0 && fetchChatList.map(({senderId, timestamp, message, attachments, messageType}) => {
                                                        console.log(message,timestamp,senderId,requestData,"Id")
                                                        const senderData = requestData?.request?.chat_members.find(({ user_id }) => user_id === senderId);
                                                        console.log(senderData,"senderData",fetchChatList);
                                                            return(
                                                                <div className="">
                                                                    {
                                                                        requestData.request.buyer_id==senderId?(
                                                                            <div className="flex justify-content-start">
                                                                    <div className="messageQuery right-0">
                                                                        {/* <div className="prdctHead">
                                                                            <div className="prdtImg">
                                                                                {senderData?.profile_img ?
                                                                                <img src={`${senderData?.profile_img?.base_url}${senderData?.profile_img?.attachment_medium_url}`} alt="blank" />
                                                                                :
                                                                                <img src={`${senderData?.profile_imgage?.base_url}${senderData?.profile_imgage?.attachment_medium_url}`} alt="Profile" />
                                                                                }
                                                                            </div>
                                                                            <div className="prdtDes pl-2">
                                                                                <p>{senderData?.name}</p>
                                                                                <span>{moment(parseInt(timestamp)).format(
                                                                                    "DD MMM YYYY hh:mm A"
                                                                                )}</span>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="messageDesc left-msg mt-2">
                                                                            <p className="msg-box">{message}</p>
                                                                            <div className="attachedFile">
                                                                                <div className="attachedFileGroupflex">
                                                                                {attachments && attachments.length > 0 && attachments.map((attachment,index) =>{
                                                                                        const img =  isImgUrl(attachment);
                                                                                        return(
                                                                                            <>
                                                                                            {
                                                                                                img?(
                                                                                                    <>
                                                                                                    {
                                                                                                    index==0&&
                                                                                                     <div onClick={()=>imageViewerHandler(attachments)}  className="uploadImg">
                                                                                                        <img src={attachment} alt='uploaded-img'/>
                                                                                                      </div>
                                                                                                    }

                                                                                                    {
                                                                                                        index==1&&attachments.length==2&&
                                                                                                        <div onClick={()=>imageViewerHandler(attachments)}  className="uploadImg">
                                                                                                           <img src={attachment} alt='uploaded-img'/>
                                                                                                         </div>

                                                                                                    }
                                                                                         
                                                                                                {index==2&&attachments.length>2&&
                                                                                                    <div onClick={()=>imageViewerHandler(attachments)} className="uploadImg extra">
                                                                                                    <img src={attachments[1]} alt='uploaded-img'/>
                                                                                                    <div class="show_all_icon"><i class="fa fa-plus" aria-hidden="true"></i><span class="img_count_no">{attachments.length-2}</span></div>
                                                                                                </div>
                                                                                                }
                                                                                               
                                                                                                </>
                                                                                                ):(
                                                                                                    <>
                                                                                              <a
                                                                                                href={attachment}
                                                                                                alt="PDF"
                                                                                                target="_blank"
                                                                                                >
                                                                                                <svg width="155" height="131" viewBox="0 0 155 131" fill="none">
                                                                                                    <path d="M0 0H111L133.5 25.5L155 50V131H0V0Z" fill="#EF4036"/>
                                                                                                    <path d="M54.5985 75V56.6078H61.8548C63.2498 56.6078 64.4382 56.8742 65.4201 57.4071C66.402 57.9339 67.1504 58.6674 67.6653 59.6073C68.1861 60.5413 68.4466 61.619 68.4466 62.8403C68.4466 64.0617 68.1831 65.1393 67.6563 66.0733C67.1294 67.0073 66.3661 67.7347 65.3662 68.2556C64.3724 68.7765 63.169 69.0369 61.756 69.0369H57.1311V65.9207H61.1274C61.8758 65.9207 62.4925 65.7919 62.9774 65.5345C63.4683 65.2711 63.8335 64.9088 64.073 64.4478C64.3185 63.9809 64.4412 63.445 64.4412 62.8403C64.4412 62.2296 64.3185 61.6968 64.073 61.2418C63.8335 60.7808 63.4683 60.4246 62.9774 60.1731C62.4865 59.9157 61.8638 59.7869 61.1094 59.7869H58.4871V75H54.5985ZM77.4922 75H70.9723V56.6078H77.5461C79.3961 56.6078 80.9887 56.976 82.3238 57.7124C83.6589 58.4428 84.6857 59.4936 85.4041 60.8646C86.1285 62.2356 86.4908 63.8761 86.4908 65.7859C86.4908 67.7018 86.1285 69.3482 85.4041 70.7253C84.6857 72.1023 83.6529 73.159 82.3058 73.8954C80.9647 74.6318 79.3602 75 77.4922 75ZM74.8609 71.6682H77.3306C78.4801 71.6682 79.447 71.4647 80.2313 71.0575C81.0216 70.6444 81.6143 70.0068 82.0095 69.1447C82.4106 68.2766 82.6111 67.157 82.6111 65.7859C82.6111 64.4269 82.4106 63.3163 82.0095 62.4542C81.6143 61.592 81.0246 60.9574 80.2403 60.5503C79.456 60.1432 78.4891 59.9396 77.3396 59.9396H74.8609V71.6682ZM89.3713 75V56.6078H101.549V59.8139H93.2598V64.1964H100.741V67.4024H93.2598V75H89.3713Z" fill="white"/>
                                                                                                    <path d="M111 45V0.000198364L133 25L155 50H116C113.239 50 111 47.7614 111 45Z" fill="#D6D6D7"/>
                                                                                                </svg>

                                                                                                </a>
                                                                                                    
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                           
                                                                                        
                                                                                            </>

                                                                                        )
                                                                                    })}
                                                                                    
                                                                                    {/* {attachments && attachments.length > 0 && attachments.map((attachment) =>{
                                                                                        const img =  isImgUrl(attachment);
                                                                                        return (<div className="uploadImg">
                                                                                            {img ? (
                                                                                                <img
                                                                                                src={attachment}
                                                                                                alt="Added Image"
                                                                                                />
                                                                                            ) : (
                                                                                                <a
                                                                                                href={attachment}
                                                                                                alt="PDF"
                                                                                                target="_blank"
                                                                                                >
                                                                                                <svg width="155" height="131" viewBox="0 0 155 131" fill="none">
                                                                                                    <path d="M0 0H111L133.5 25.5L155 50V131H0V0Z" fill="#EF4036"/>
                                                                                                    <path d="M54.5985 75V56.6078H61.8548C63.2498 56.6078 64.4382 56.8742 65.4201 57.4071C66.402 57.9339 67.1504 58.6674 67.6653 59.6073C68.1861 60.5413 68.4466 61.619 68.4466 62.8403C68.4466 64.0617 68.1831 65.1393 67.6563 66.0733C67.1294 67.0073 66.3661 67.7347 65.3662 68.2556C64.3724 68.7765 63.169 69.0369 61.756 69.0369H57.1311V65.9207H61.1274C61.8758 65.9207 62.4925 65.7919 62.9774 65.5345C63.4683 65.2711 63.8335 64.9088 64.073 64.4478C64.3185 63.9809 64.4412 63.445 64.4412 62.8403C64.4412 62.2296 64.3185 61.6968 64.073 61.2418C63.8335 60.7808 63.4683 60.4246 62.9774 60.1731C62.4865 59.9157 61.8638 59.7869 61.1094 59.7869H58.4871V75H54.5985ZM77.4922 75H70.9723V56.6078H77.5461C79.3961 56.6078 80.9887 56.976 82.3238 57.7124C83.6589 58.4428 84.6857 59.4936 85.4041 60.8646C86.1285 62.2356 86.4908 63.8761 86.4908 65.7859C86.4908 67.7018 86.1285 69.3482 85.4041 70.7253C84.6857 72.1023 83.6529 73.159 82.3058 73.8954C80.9647 74.6318 79.3602 75 77.4922 75ZM74.8609 71.6682H77.3306C78.4801 71.6682 79.447 71.4647 80.2313 71.0575C81.0216 70.6444 81.6143 70.0068 82.0095 69.1447C82.4106 68.2766 82.6111 67.157 82.6111 65.7859C82.6111 64.4269 82.4106 63.3163 82.0095 62.4542C81.6143 61.592 81.0246 60.9574 80.2403 60.5503C79.456 60.1432 78.4891 59.9396 77.3396 59.9396H74.8609V71.6682ZM89.3713 75V56.6078H101.549V59.8139H93.2598V64.1964H100.741V67.4024H93.2598V75H89.3713Z" fill="white"/>
                                                                                                    <path d="M111 45V0.000198364L133 25L155 50H116C113.239 50 111 47.7614 111 45Z" fill="#D6D6D7"/>
                                                                                                </svg>

                                                                                                </a>
                                                                                            )}
                                                                                        </div>
                                                                                        )
                                                                                        return(
                                                                                            <>
                                                                                            {
                                                                                                img?(
                                                                                                    <>

                                                                                                 <div className="uploadImg">
                                                                                                    <img src={attachment} alt='uploaded-img'/>
                                                                                                </div>
                                                                                                {
                                                                                                    attachments.length<=2?(
                                                                                                        <div className="uploadImg">
                                                                                                        <img src={attachment} alt='uploaded-img'/>
                                                                                                    </div>
                                                                                                    ):(
                                                                                                  <div className="uploadImg extra">
                                                                                                <img src={attachments[1]} alt='uploaded-img'/>
                                                                                                <div class="show_all_icon"><i class="fa fa-plus" aria-hidden="true"></i><span class="img_count_no">2</span></div>
                                                                                            </div>
                                                                                                )
                                                                                                }
                                                                                                </>
                                                                                                ):(
                                                                                                    <>
                                                                                              <a
                                                                                                href={attachment}
                                                                                                alt="PDF"
                                                                                                target="_blank"
                                                                                                >
                                                                                                <svg width="155" height="131" viewBox="0 0 155 131" fill="none">
                                                                                                    <path d="M0 0H111L133.5 25.5L155 50V131H0V0Z" fill="#EF4036"/>
                                                                                                    <path d="M54.5985 75V56.6078H61.8548C63.2498 56.6078 64.4382 56.8742 65.4201 57.4071C66.402 57.9339 67.1504 58.6674 67.6653 59.6073C68.1861 60.5413 68.4466 61.619 68.4466 62.8403C68.4466 64.0617 68.1831 65.1393 67.6563 66.0733C67.1294 67.0073 66.3661 67.7347 65.3662 68.2556C64.3724 68.7765 63.169 69.0369 61.756 69.0369H57.1311V65.9207H61.1274C61.8758 65.9207 62.4925 65.7919 62.9774 65.5345C63.4683 65.2711 63.8335 64.9088 64.073 64.4478C64.3185 63.9809 64.4412 63.445 64.4412 62.8403C64.4412 62.2296 64.3185 61.6968 64.073 61.2418C63.8335 60.7808 63.4683 60.4246 62.9774 60.1731C62.4865 59.9157 61.8638 59.7869 61.1094 59.7869H58.4871V75H54.5985ZM77.4922 75H70.9723V56.6078H77.5461C79.3961 56.6078 80.9887 56.976 82.3238 57.7124C83.6589 58.4428 84.6857 59.4936 85.4041 60.8646C86.1285 62.2356 86.4908 63.8761 86.4908 65.7859C86.4908 67.7018 86.1285 69.3482 85.4041 70.7253C84.6857 72.1023 83.6529 73.159 82.3058 73.8954C80.9647 74.6318 79.3602 75 77.4922 75ZM74.8609 71.6682H77.3306C78.4801 71.6682 79.447 71.4647 80.2313 71.0575C81.0216 70.6444 81.6143 70.0068 82.0095 69.1447C82.4106 68.2766 82.6111 67.157 82.6111 65.7859C82.6111 64.4269 82.4106 63.3163 82.0095 62.4542C81.6143 61.592 81.0246 60.9574 80.2403 60.5503C79.456 60.1432 78.4891 59.9396 77.3396 59.9396H74.8609V71.6682ZM89.3713 75V56.6078H101.549V59.8139H93.2598V64.1964H100.741V67.4024H93.2598V75H89.3713Z" fill="white"/>
                                                                                                    <path d="M111 45V0.000198364L133 25L155 50H116C113.239 50 111 47.7614 111 45Z" fill="#D6D6D7"/>
                                                                                                </svg>

                                                                                                </a>
                                                                                                    
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                           
                                                                                        
                                                                                            </>

                                                                                        )
                                                                                    })} */}
                                                                                     {/* <div className="uploadImg">
                                                                                        <img src={uploadimg} alt='uploaded-img'/>
                                                                                    </div>
                                                                                    <div className="uploadImg extra">
                                                                                        <img src={uploadimg} alt='uploaded-img'/>
                                                                                        <div class="show_all_icon"><i class="fa fa-plus" aria-hidden="true"></i><span class="img_count_no">2</span></div>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                            <p className="chatDate">{moment(parseInt(timestamp)).format(
                                                                                    "DD MMM YYYY hh:mm A"
                                                                                )}</p>
                                                                        </div>
                                                                        </div>
                                                                            </div>
                                                                           
                                                                        ):(
                                                                            <div className="flex justify-end mr-2">
                                                                            <div className="messageQuery right-0">
                                                                        {/* <div className="prdctHead">
                                                                            <div className="prdtImg">
                                                                                {senderData?.profile_img ?
                                                                                <img src={`${senderData?.profile_img?.base_url}${senderData?.profile_img?.attachment_medium_url}`} alt="blank" />
                                                                                :
                                                                                <img src={`${senderData?.profile_imgage?.base_url}${senderData?.profile_imgage?.attachment_medium_url}`} alt="Profile" />
                                                                                }
                                                                            </div>
                                                                            <div className="prdtDes pl-2">
                                                                                <p>{senderData?.name}</p>
                                                                                <span>{moment(parseInt(timestamp)).format(
                                                                                    "DD MMM YYYY hh:mm A"
                                                                                )}</span>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="messageDesc right-msg mt-2">
                                                                            <p className="msg-box">{message}</p>
                                                                            <div className="attachedFile">
                                                                                <div className="attachedFileGroupflex">
                                                                            

                                                                                    {attachments && attachments.length > 0 && attachments.map((attachment,index) =>{
                                                                                        const img =  isImgUrl(attachment);
                                                                                        let length=0
                                                                                       
                                                                                       
                                                                                        return(
                                                                                            <>
                                                                                            {
                                                                                                img?(
                                                                                                    <>
                                                                                                    {
                                                                                                    index==0&&
                                                                                                     <div style={{cursor:"pointer"}} onClick={()=>imageViewerHandler(attachments)}  className="uploadImg">
                                                                                                        <img src={attachment} alt='uploaded-img'/>
                                                                                                      </div>
                                                                                                    }

                                                                                                    {
                                                                                                        index==1&&attachments.length==2&&
                                                                                                        <div  style={{cursor:"pointer"}} onClick={()=>imageViewerHandler(attachments)}  className="uploadImg">
                                                                                                           <img src={attachment} alt='uploaded-img'/>
                                                                                                         </div>

                                                                                                    }
                                                                                         
                                                                                                {index==2&&attachments.length>2&&
                                                                                                    <div onClick={()=>imageViewerHandler(attachments)} className="uploadImg extra">
                                                                                                    <img src={attachments[1]} alt='uploaded-img'/>
                                                                                                    <div class="show_all_icon"><i class="fa fa-plus" aria-hidden="true"></i><span class="img_count_no">{attachments.length-2}</span></div>
                                                                                                </div>
                                                                                                }
                                                                                               
                                                                                                </>
                                                                                                ):(
                                                                                                    <>
                                                                                              <a
                                                                                                href={attachment}
                                                                                                alt="PDF"
                                                                                                target="_blank"
                                                                                                >
                                                                                                <svg width="155" height="131" viewBox="0 0 155 131" fill="none">
                                                                                                    <path d="M0 0H111L133.5 25.5L155 50V131H0V0Z" fill="#EF4036"/>
                                                                                                    <path d="M54.5985 75V56.6078H61.8548C63.2498 56.6078 64.4382 56.8742 65.4201 57.4071C66.402 57.9339 67.1504 58.6674 67.6653 59.6073C68.1861 60.5413 68.4466 61.619 68.4466 62.8403C68.4466 64.0617 68.1831 65.1393 67.6563 66.0733C67.1294 67.0073 66.3661 67.7347 65.3662 68.2556C64.3724 68.7765 63.169 69.0369 61.756 69.0369H57.1311V65.9207H61.1274C61.8758 65.9207 62.4925 65.7919 62.9774 65.5345C63.4683 65.2711 63.8335 64.9088 64.073 64.4478C64.3185 63.9809 64.4412 63.445 64.4412 62.8403C64.4412 62.2296 64.3185 61.6968 64.073 61.2418C63.8335 60.7808 63.4683 60.4246 62.9774 60.1731C62.4865 59.9157 61.8638 59.7869 61.1094 59.7869H58.4871V75H54.5985ZM77.4922 75H70.9723V56.6078H77.5461C79.3961 56.6078 80.9887 56.976 82.3238 57.7124C83.6589 58.4428 84.6857 59.4936 85.4041 60.8646C86.1285 62.2356 86.4908 63.8761 86.4908 65.7859C86.4908 67.7018 86.1285 69.3482 85.4041 70.7253C84.6857 72.1023 83.6529 73.159 82.3058 73.8954C80.9647 74.6318 79.3602 75 77.4922 75ZM74.8609 71.6682H77.3306C78.4801 71.6682 79.447 71.4647 80.2313 71.0575C81.0216 70.6444 81.6143 70.0068 82.0095 69.1447C82.4106 68.2766 82.6111 67.157 82.6111 65.7859C82.6111 64.4269 82.4106 63.3163 82.0095 62.4542C81.6143 61.592 81.0246 60.9574 80.2403 60.5503C79.456 60.1432 78.4891 59.9396 77.3396 59.9396H74.8609V71.6682ZM89.3713 75V56.6078H101.549V59.8139H93.2598V64.1964H100.741V67.4024H93.2598V75H89.3713Z" fill="white"/>
                                                                                                    <path d="M111 45V0.000198364L133 25L155 50H116C113.239 50 111 47.7614 111 45Z" fill="#D6D6D7"/>
                                                                                                </svg>

                                                                                                </a>
                                                                                                    
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                           
                                                                                        
                                                                                            </>

                                                                                        )
                                                                                    })}
                                                                                    



                                                                                </div>
                                                                            </div>
                                                                            <p className="chatDate">{moment(parseInt(timestamp)).format(
                                                                                    "DD MMM YYYY hh:mm A"
                                                                                )}</p>
                                                                        </div>
                                                                       </div>
                                                                </div>
                                                                        )
                                                                    }
                                                                    
                                                                </div>
                                                                
                                                            )
                                                        })

                                                    }


                                                    </div>
                                                )}
                                                <div className="replyMessage">
                                                    <div className="messageText">
                                                        <textarea
                                                            rows="1"
                                                            cols="4"
                                                            placeholder="Enter Message Here..."
                                                            value={message}
                                                            onChange={(event) => handleChange(event)}
                                                        ></textarea>
                                                    </div>
                                                    <div className="chatButtons">
                                                        <div className="attachFile">
                                                            <label>
                                                                <div className="icon">
                                                                    <i
                                                                        className="fa fa-paperclip"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    Attach File or Images
                                                                </div>

                                                                <input
                                                                    type="file"
                                                                    id="attachment_img"
                                                                    className="form--item"
                                                                    placeholder="Select Image or File to attach"
                                                                    accept="image/*, application/pdf"
                                                                    onChange={(event) => handleFile(event)}
                                                                    multiple
                                                                ></input>
                                                                <span className="slectedFiles">{ imageCount > 0 ? imageCount == 1 ? ` ${imageCount} File Selected` : ` ${imageCount} Files Selected` : ''}</span>
                                                            </label>
                                                            
                                                        </div>
                                                        <div className="rightButtons">
                                                            <button
                                                                type="button"
                                                                className="btn btn-white"
                                                                disabled={disabledTrue}
                                                                onClick={() => {
                                                                resetChatBox();
                                                                }}
                                                            >
                                                                Discard
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-blue ml-4"
                                                                disabled={disabledTrue}
                                                                onClick={() => {
                                                                    saveChatFirebase();
                                                                  }}
                                                            > {isLoader ? <FormSpinner /> : "Send"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='error'>{imageError}</div>
                                                </div>
                                                </div>
                                                :
                                                <div className='noChatData'>
                                                    <div className='noDataImage'>
                                                        <img src={nodataimage} alt="Dummy Image" />
                                                    </div>
                                                    <h2 className='fs-20 font-bold'>You have blocked this buyer</h2>
                                                </div>
                                                }
                                            </div>
                                            
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* <FormSpinner /> */}
                    </div>
                    <Modal
                    isOpen={openBlockModal}
                    style={{
                        overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                        },
                        content: {
                        position: "absolute",
                        top: "0",
                        left: "0%",
                        right: "0%",
                        bottom: "200px",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        padding: "0",
                        border: "0",
                        borderRadius: "8px",
                        // outline: 'none',
                        },
                    }}
                    >
                    <div className="popup_header flex justify-between items-center popupheaderBg">
                        <h4 className="page__title">Block Buyer</h4>
                        <button
                        className="close_btn--modal"
                        onClick={() => setOpenBlockModal(!openBlockModal)}
                        >
                        Close
                        </button>
                    </div>
                    <div className="card card--block modal_box">
                        <p className="warning">
                        Are you sure you want to block buyer?
                        </p>
                        <div className="right__btn text-right mt-4">
                        {blockLoader ? (
                            <FormSpinner />
                        ) : (
                            <>
                            <Link
                                className="btn btn1"
                                to="#"
                                onClick={() => setOpenBlockModal(!openBlockModal)}
                            >
                                Cancel
                            </Link>
                            <Link
                                className="btn btn1 btn2 ml-3"
                                to="#"
                                onClick={() => blockBuyer()}
                            >
                                Block
                            </Link>
                            </>
                        )}
                        </div>
                    </div>
                    </Modal>

                    <Modal
                    isOpen={openUnBlockModal}
                    style={{
                        overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                        },
                        content: {
                        position: "absolute",
                        top: "0",
                        left: "0%",
                        right: "0%",
                        bottom: "200px",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        padding: "0",
                        border: "0",
                        borderRadius: "8px",
                        // outline: 'none',
                        },
                    }}
                    >
                    <div className="popup_header flex justify-between items-center popupheaderBg">
                        <h4 className="page__title">Unblock Buyer</h4>
                        <button
                        className="close_btn--modal"
                        onClick={() => setOpenUnBlockModal(!openUnBlockModal)}
                        >
                        Close
                        </button>
                    </div>
                    <div className="card card--block modal_box">
                        <p className="warning">
                        Are you sure you want to unblock buyer?
                        </p>
                        <div className="right__btn text-right mt-4">
                        {unBlockLoader ? (
                            <FormSpinner />
                        ) : (
                            <>
                            <Link
                                className="btn btn1"
                                to="#"
                                onClick={() => setOpenUnBlockModal(!openUnBlockModal)}
                            >
                                Cancel
                            </Link>
                            <Link
                                className="btn btn1 btn2 ml-3"
                                to="#"
                                onClick={() => unBlockBuyer()}
                            >
                                Unblock
                            </Link>
                            </>
                        )}
                        </div>
                    </div>
                    </Modal>
                </div>
                {isOpenImage && (
                <div  className="image--light--pop"  >
                <ReactImageVideoLightbox
                    data={userPhotosData}
                    startIndex={imageIndex}
                    showResourceCount={true}
                    onCloseCallback={() => {
                    setIsOpenImage(false);
                    setUserPhotosData([]);
                    setImageIndex(0);
                    }}
                // onNavigationCallback={(currentIndex) =>
                //     console.log(`Current index: ${currentIndex}`)
                // }
                />
                </div>
            )}
            </>
        )
    }
