import React from 'react'
import SectionTitle from '../../SectionTitle'

export default function LeftContent() {
  return (
    <>
      <div className='flexBox'>
        <div className='secBlockLeft'>
          <div className='blockWrapper'>
            <h4 className='boxTitle text-blue mb-2'>About Alysei</h4>
            <SectionTitle
              secTitleLarge={'"' + "Set your course by the stars, not by the lights of every passing ship" + '"'}
            />
            <div className='mr-3'>
              <p>
                Alysei was born in a little coffee shop in Lake Geneva, Wisconsin. A great passion and willingness to create market opportunities is what brought us together and turned our idea into reality. Alysei is the first B2B and B2C portal for high quality Italian products in the food and beverages industry. It is designed and developed on a collaborative social portal platform that is intended to leverage public enthusiasm for the Made in Italy enogastronomy movement.</p>
              <br />
              <p> Alysei certified Producers will be able to find and connect with certified US-based Importers and Distributors that are appropriate for their business.
              </p>
              <br />
              <p>
                This will enable Producers to build up and consolidate their brand, promote and sell their products, and reach their target consumers faster.
              </p>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
