export default {
  ENV: {
    REACT_APP: 'https://api.alysei.com',
    REACT_APP_API: 'https://api.alysei.com/api',
    REACT_APP_IMAGE_URL: 'https://api.alysei.com',
    REACT_APP_STRIPE_KEY: 'pk_live_51Nd6mSHuYOUUdkFcej56qBWblD69ntqH3XhzJWWw5F4856oLBr7M3lHFONzbSfQAiOlZZGiOLiTzU7wosjXStzwi00C038mn7Q',
    REACT_APP_API_GOOGLE_API_KEY :"AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68",
    CLIENT_ID :"ca_PNVRITwKcMGLrIlxcjJrm54weospxW3x"
  },
};
