import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import loginReducer from "../routes/login/modules/login";
import RegisterReducer from "../routes/register/modules/register"
import authWrapperReducer from "../routes/AuthWrapper/modules/auth"
import memberProfileReducer from "../routes/profile/modules/profile"
import marketPlaceReducer from "../routes/marketplace/modules/marketplace"
import recipeReducer from "../routes/recipe/modules/RecipeModule"
import discoverReducer from "../routes/discover/modules/DiscoverModule"
 
import hubs_State from "../routes/hubs/modules/hubs";
import addPostReducer from "../routes/Post/modules/post";
import fetchActivityFeed from "../routes/home/modules/HomeModule"
import membershipState from "../routes/membership/modules/membership";
import b2bState from "../routes/B2B/modules/B2B";
import networkConnectionReducer from "../routes/NetworkConnection/modules/networkConnection";
import userSettingReducer from "../routes/setting/module/UserSetting";
import messageReducer from "../routes/message/module/messageModule";
import universalSearchReducer from "../routes/universal-search/module/universal_search";
import buyerModuleReducer from "../routes/Buyer/modules/Buyermodule";

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: loginReducer,
  register: RegisterReducer,
  authWrapper: authWrapperReducer,
  memberProfileData: memberProfileReducer,
  hubs_State: hubs_State,
  postState: addPostReducer,
  marketPlaceReducerData: marketPlaceReducer,
  recipedata: recipeReducer,
  discoverReducerState: discoverReducer,
  activityFeedStatus: fetchActivityFeed,
  memberProgressState: membershipState,
  b2bState: b2bState,
  networkConnectionState: networkConnectionReducer,
  userSettingState: userSettingReducer,
  messageState: messageReducer,
  universalSearch : universalSearchReducer,
  buyerModuleFilterData : buyerModuleReducer,
});

export default rootReducer;