import React, { useState } from "react";
import { Fragment } from "react";
import language from "../../helpers/language";
import './FormSpinner.css'

export function FormSpinner(){
    const [lang] = useState(language.getLang());
    return (
        <Fragment>
        <div className="loader">{lang?.Loading}</div>
        </Fragment>
    )
}

export default FormSpinner;