/* @format */

import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment";
import headerType from "../../../helpers/headerType";
export const GET_HUBS_SPINNER_STATUS = "GET_HUBS_SPINNER_STATUS";
export const SELECTED_CITY_NAME_ARRAY = "SELECTED_CITY_NAME_ARRAY";
export const GET_STATE_WISE_HUBS = "GET_STATE_WISE_HUBS";
export const GET_REVIEW_HUBS = "GET_REVIEW_HUBS";
export const SELECTED_STATE_ARRAY = "SELECTED_STATE_ARRAY";
export const SELECTED_HUBS_ARRAY = "SELETED_HUBS_ARRAY";
export const SELECTED_CITY_ARRAY = "SELECTED_CITY_ARRAY";
export const SELECT_HUBS_STATE = "SELECT_HUBS_STATE";
export const STATE_HUB_SELECT = "STATE_HUB_SELECT";
export const HUBS = "HUBS";
export const SELECTED_HUBS_STATES = "SELECTED_HUBS_STATES";
export const ACTIVE_UPCOMING_COUNTRIES = "ACTIVE_UPCOMING_COUNTRIES";
export const UPCOMING_COUNTRIES_SPINNER_STATUS =
  "UPCOMING_COUNTRIES_SPINNER_STATUS";

export function upcomingCountries(upcomingCountries) {
  return {
    type: ACTIVE_UPCOMING_COUNTRIES,
    payload: upcomingCountries,
  };
}

export function getUpcomingCountriesSpinner(flag) {
  return {
    type: UPCOMING_COUNTRIES_SPINNER_STATUS,
    payload: flag,
  };
}
export function getHubsSpinner(flag) {
  return {
    type: GET_HUBS_SPINNER_STATUS,
    payload: flag,
  };
}
export function newHubStateSelected(selectedStates) {
  return {
    type: SELECTED_HUBS_STATES,
    payload: selectedStates,
  };
}

export function getStateWiseHubs(data) {
  return {
    type: GET_STATE_WISE_HUBS,
    payload: data,
  };
}

export function getReviewHubs(data) {
  return {
    type: GET_REVIEW_HUBS,
    payload: data,
  };
}

export function selectedHubsState(data) {
  return {
    type: SELECT_HUBS_STATE,
    payload: data,
  };
}
export function setHubsFun(data) {
  return {
    type: HUBS,
    payload: data,
  };
}

export function stateHubSelect(data) {
  return {
    type: STATE_HUB_SELECT,
    payload: data,
  };
}

export function seletedHubsArray(data) {
  return {
    type: SELECTED_HUBS_ARRAY,
    payload: data,
  };
}

export function seletedStatedArray(data) {
  return {
    type: SELECTED_STATE_ARRAY,
    payload: data,
  };
}
export function seletedCityArray(data) {
  return {
    type: SELECTED_CITY_ARRAY,
    payload: data,
  };
}
export function seletedCityNameArray(data) {
  return {
    type: SELECTED_CITY_NAME_ARRAY,
    payload: data,
  };
}

export function getHubState(countryId) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    //await dispatch(getHubsSpinner(true))
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/state/wise/hubs?country_id=${countryId}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getStateWiseHubs(response));
        await dispatch(selectedHubsState(response));
        //await dispatch(getHubsSpinner(false))
      } else {
        //await dispatch(getHubsSpinner(false))
      }
    } catch (e) {
      console.log(e, "error");
      //await dispatch(getHubsSpinner(true))
    }
  };
}


export function getHubStateBySearch(data) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    //await dispatch(getHubsSpinner(true))
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/search/hubs/my-regions`,
        {
          method: "POST",
          headers: headerType.HeadersWithTokenAndJson(token),
          body: JSON.stringify(data),
        },

      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getStateWiseHubs(response));
        await dispatch(selectedHubsState(response));
        //await dispatch(getHubsSpinner(false))
      } else {
        //await dispatch(getHubsSpinner(false))
      }
    } catch (e) {
      console.log(e, "error");
      //await dispatch(getHubsSpinner(true))
    }
  };
}

export function reviewHubs() {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/review/hubs`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getReviewHubs(response));
        await dispatch(getHubsSpinner(false));
        return response?.data;
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getHubsSpinner(false));
    }
  };
}

export function updateReviewHubs() {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    //await dispatch(getHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/review/hubs`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getReviewHubs(response));
        //await dispatch(getHubsSpinner(false));
        return response?.data;
      }
    } catch (e) {
      console.log(e, "error");
      //await dispatch(getHubsSpinner(false));
    }
  };
}

export function getStateOfCity(stateId) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getHubsSpinner(true));
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/cities?state_id=${stateId}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(seletedCityArray(response));
        await dispatch(getHubsSpinner(false));
        return response;
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getHubsSpinner(false));
    }
  };
}

export function getHubsState(countryId) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    await dispatch(getHubsSpinner(true))
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/selected/hub/states?country_id=${countryId}`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(seletedStatedArray(response));
        await dispatch(getHubsSpinner(false))
      }
    } catch (e) {
      console.log(e, "error");
      await dispatch(getHubsSpinner(false))
    }
  };
}

export function postSlectedHubs(params) {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/post/hubs`,
        {
          method: "POST",
          headers: headerType.HeadersWithTokenAndJson(token),
          body: JSON.stringify({ params: params }),
        }
      );
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(selectedHubsState([]));
        return response;
        // await dispatch(seletedStatedArray(response))
      } else return response;
    } catch (e) {
      console.log(e, "error");
    }
  };
}

export function deleteAllHubs() {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/user/hubs/deleted`,
        {
          method: "DELETE",
          headers: headerType.HeadersWithTokenAndJson(token)
        }
      );
      const response = await result.json();
      if (response.success === 200) {
        return true;
      }
      else {
        return false;
      }
      return response;
    } catch (e) {
      console.log(e, "error");
    }
  };
}

export function getUpcomingCountries() {
  return async (dispatch) => {
    const token = await auth.getAccessToken();
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/get/active/upcoming/countries`,
        {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      const response = await result.json();
      if (response.success === 200) {
        await dispatch(upcomingCountries(response));
        //await dispatch(getUpcomingCountriesSpinner(false))
      }
    } catch (e) {
      console.log(e, "error");
      //await dispatch(getUpcomingCountriesSpinner(false))
    }
  };
}


export const initialState = {
  Hubs: [],
  getHubs: [],
  seletedHubs: [],
  selectedCityArray: [],
  selectedState: [],
  reviewHubsData: [],
  statehubselect: [],
  hubsSpinner: false,
  selectedCityName: [],
  selectedHubsStateData: [],
  newHubSelected: [],
  upcomingHubCountries: [],
};

const ACTION_HANDLERS = {
  [ACTIVE_UPCOMING_COUNTRIES]: (state, action) => {
    return {
      ...state,
      upcomingHubCountries: action.payload,
    };
  },
  [SELECT_HUBS_STATE]: (state, action) => {
    return {
      ...state,
      selectedHubsStateData: action.payload,
    };
  },
  [GET_STATE_WISE_HUBS]: (state, action) => {
    return {
      ...state,
      getHubs: action.payload,
    };
  },
  [GET_REVIEW_HUBS]: (state, action) => {
    return {
      ...state,
      reviewHubsData: action.payload,
    };
  },
  [HUBS]: (state, action) => {
    return {
      ...state,
      Hubs: action.payload,
    };
  },
  [SELECTED_HUBS_ARRAY]: (state, action) => {
    return {
      ...state,
      seletedHubs: action.payload,
    };
  },
  [SELECTED_STATE_ARRAY]: (state, action) => {
    return {
      ...state,
      selectedState: action.payload,
    };
  },
  [SELECTED_CITY_ARRAY]: (state, action) => {
    return {
      ...state,
      selectedCityArray: action.payload,
    };
  },
  [SELECTED_CITY_NAME_ARRAY]: (state, action) => {
    return {
      ...state,
      selectedCityName: action.payload,
    };
  },
  [STATE_HUB_SELECT]: (state, action) => {
    return {
      ...state,
      statehubselect: action.payload,
    };
  },
  [GET_HUBS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      hubsSpinner: action.payload,
    };
  },
  [SELECTED_HUBS_STATES]: (state, action) => {
    return {
      ...state,
      newHubSelected: action.payload,
    };
  },
};

export default function hubs_State(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
