import React, { Fragment, useState } from 'react';
import img1 from "../../../src/assets/images/notfound.png"
export default function NotFound(props) {
    return (
        <Fragment>
            <div className='notFounf'>
                <div className='notFounfImg'><img src={img1} alt="Not_Found" /></div>
                <h4 className='font-18 black mt-4'>{props.errors} </h4>
            </div>
        </Fragment>
    )
}
