import auth from '../../../helpers/auth'
import REACT_APP_API from "../../../config/environment";
import headerType from '../../../helpers/headerType';


export const uploadMedia = (model) => {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    return async (dispatch, getState) => {
        const token = auth.getAccessToken()
        if (token) {
            try {
                let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/upload/media`, {
                    method: "POST",
                    headers: headerType.HeadersWithToken(token),
                    body: form_data
                });
                let response = await result.json();
                if (response.success === 200) {
                    return response
                }
            } catch (e) {
                console.log(e, 'error.');
            }
        } else {
            console.log('You need to login first');
        }
    };
};


// initialState
export const initialState = {};

const ACTION_HANDLERS = {};

export default function messageReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
