import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Circle,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import language from "../../helpers/language";
// import { GoogleMapsAPI } from '../client-config';
// Geocode.setApiKey("AIzaSyDX4HE7708TQYkE0WoOlzTDlq7_9nneUHY");
Geocode.setApiKey("AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68");
Geocode.enableDebug();
class Map extends Component {
  constructor(props) {
    super(props);
    console.log(props,"props");
    this.state = {
      selectLang: language.getLang(),
      address: this.props.value,
     
    };
  }
  


  /*
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    this.props.onPlaceSelected(latValue, lngValue);
    // Set these values in the state.
    this.setState({
        address: address ? address : "",
      });
  };

  render() {
    const { address, area, city, state, country } = this.state
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          
        >
          
          {/* For Auto complete Search Box */}
          
            <Autocomplete
              placeholder={props?.placeHolder}
              onPlaceSelected={this.onPlaceSelected}
              defaultValue={address}
              // value={address}
              // value={`${city} ${state} ${country}`}
              types={['(regions)']}
              options= {{
              types: [],
              fields: [
                "address_components",
                "geometry.location",
                "place_id",
                "formatted_address",
              ]
            }}
            />
        </GoogleMap>
      ))
    );
    let map;
      map = (
        <div>
          {/* {this.props.name === "address" && 
              <div className="form-group">
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  onChange={this.onChange}
                  readOnly="readOnly"
                  value={this.state.address}
                  // placeholder="Search Location"
                // defaultValue={this.props.value}
                />
              </div>
              } */}

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68"}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />} containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />} />
        </div>
      );
    return map;
  }
}
export default Map;
