import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProdctToCart } from '../modules/marketplace';
import user_img from '../../../assets/images/user_profile-img.png'
import { getSingleProductTax, increaseQtyOfProducts } from '../modules/marketplaceHelper';
import { Link, useHistory } from 'react-router-dom';
import DeleteConfirmation from '../../../components/DeleteConfirmation';
import language from '../../../helpers/language';
import IncoTermModal from './IncoTermModal';

export default function TotalAddedProductListing() {
	const [incoTerm, setIncoTerm] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [lang] = useState(language?.getLang())
    const [productModal, setProductModal] = useState({ id: '', isDelete: false });
    let { cartProducts } = useSelector((state) => state.marketPlaceReducerData);
    const isProductWithoutOffer = cartProducts?.[0]?.map_offer_id?.length === 0;

    // increate or decrease quantity of products
    const increaseQtyOfProduct = (id, isIncrease) => {
        const productItems = increaseQtyOfProducts(cartProducts, id, isIncrease)
        dispatch(addProdctToCart(productItems));
        localStorage.setItem('totalProductInCart', JSON.stringify(productItems))
    }

    // remove item from cart
    const removeItem = (id) => {
        const removeItems = cartProducts.filter((item) => item.marketplace_product_id !== id);
        dispatch(addProdctToCart(removeItems));
        localStorage.setItem('totalProductInCart', JSON.stringify(removeItems));
        if(removeItems.length == 0){
            localStorage.setItem('stripe_id', '');
        }
        handleCancelDelete();
    }

    const handleDelete = (id) => {
        id && setProductModal({ id, isDelete: true });
    }

    const handleCancelDelete = () => {
        setProductModal({ id: '', isDelete: false });
    }

    const handleDetleteOffer = async () => {
        isProductWithoutOffer ? removeItem(productModal?.id) : clearCartProduct();
    }

    const clearCartProduct = () => {
        dispatch(addProdctToCart([]));
        localStorage.setItem('totalProductInCart', []);
        localStorage.setItem('stripe_id', '');
        handleCancelDelete();
    }

    const getTotalPriceOfAllProduct = () => {
        if (cartProducts?.length > 0) {
            return cartProducts?.reduce((cur, item) => (cur + getSingleProductTax(item.product_price, item.count, item?.get_product_tax, false)), 0)
        } else return 0;
    }


    return (
        <div className="marketplace add-product editProduct shopping-Cart dashboardView">
            <div className="product-list">
                <div className='recentOrder secBlockGap add_Cart'>
                    <div className='itemTable'>
                        {cartProducts?.length > 0 ?
                            <div>
                                <div className="product-title mb-4">
                                    <h4>{lang?.Shopping_cart}</h4>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{lang?.Image}</th>
                                            <th>{lang?.product_keywords}</th>
                                            <th className='Qty_List_data'>{lang?.Quantity}</th>
                                            <th>{lang?.Per_unit_rice}</th>
                                            <th>{lang?.Tax}</th>
                                            <th>{lang?.price}</th>
                                            {isProductWithoutOffer && <th>{lang?.Action}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartProducts.map((product, i) => (
                                            <>
                                                <tr key={product.marketplace_product_id} className={`${product?.available_status === 2 ? "notAvailable" : "available"}`}>
                                                    <td className='flexBox items-center'>{product?.product_gallery?.length > 0 ? <Link to={`/marketplace/product/${product.slug}`} > <img src={`${product?.product_gallery[0]?.base_url}${product?.product_gallery[0]?.attachment_url}`} alt="product-image" /> </Link> : <Link to={`/marketplace/product/${product.slug}`} >  <img src={user_img} /></Link>}
                                                        <span className='textRed outOfStock'> {product?.available_status === 2 && "Currently unavailable"} </span>
                                                    </td>
                                                    <td>
                                                        {product.title}
                                                    </td>
                                                    <td className='Qty_List_data'>
                                                        {product?.map_offer_id?.length > 0 ? <div className="Qty_List">{product.count}</div> : <div className="Qty_List">
                                                            {<span className="increase-decrease-btn" onClick={() => product.count > Number(product?.min_order_quantity=="null"?1:product?.min_order_quantity) ? increaseQtyOfProduct(product.marketplace_product_id, false) : product?.min_order_quantity=="null"?alert(`${lang?.minimum_product_qty} `+"1"+  ``):alert(`${lang?.minimum_product_qty} `+ product?.min_order_quantity +  ``)}>-</span>}
                                                            {product.count}
                                                            <span className="increase-decrease-btn" onClick={() => product.count < product.quantity_available ? increaseQtyOfProduct(product.marketplace_product_id, true) : alert(`${lang?.less_or_equal_product_qty} ${product.quantity_available}.`)}>+</span>
                                                        </div>}
                                                    </td>
                                                    <td className='item_price'>${product.product_price}</td>
                                                    <td className='item_price'>${getSingleProductTax(product.product_price, product.count, product?.get_product_tax, true)}</td>
                                                    <td className='item_price'>
                                                        ${getSingleProductTax(product.product_price, product.count, product?.get_product_tax, false)}
                                                    </td>
                                                    {product?.map_offer_id?.length === 0 && <td>
                                                        <div className='flexBox'>
                                                            <span className='deleteBlock' onClick={() => handleDelete(product.marketplace_product_id)}>
                                                                <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </td>}

                                                </tr>
                                            </>
                                        ))}

                                    </tbody>
                                </table>
                                <div className='flexBox justify-between'>
                                    {cartProducts?.[0]?.map_offer_id?.length > 0 && <>
                                        <div className='w-50'>
                                        {cartProducts?.[0]?.incoterm?.incoterms && <p className="textGreen itemTotal text-left relative incoTerms"><span style={{ color: "#000" }}>*{lang?.Incoterms} -</span><span className='aadWarning'>
                                        <i className="fa fa-exclamation-circle infoIcon" aria-hidden="true" onClick={() => setIncoTerm(!incoTerm)}></i></span>{cartProducts?.[0]?.incoterm?.incoterms}</p>}
                                        </div>
                                        <div className='w-50 text-right'>
                                            <p className='itemTotal text-right'>{lang?.Total} : ${(getTotalPriceOfAllProduct())}</p>
                                        </div>
                                        <hr /></>
                                    }
                                </div>
                                {(cartProducts?.[0]?.map_offer_id?.length === 0 && cartProducts?.[0]?.incoterm?.incoterms) && <> <p className='textGreen itemTotal text-left relative incoTerms 2'><span style={{ color: "#000" }}>*{lang?.Incoterms} -</span> <span className='aadWarning' title={lang?.Add_reflected_in_the_bill}>
                                    <i className="fa fa-exclamation-circle infoIcon" aria-hidden="true" onClick={() => setIncoTerm(!incoTerm)}></i>
                                </span> {cartProducts?.[0]?.incoterm?.incoterms}</p>
                                    <hr /></>}
                                <div className='last_btn'>
                                    <div className='btnGroupLeft flexBox gap-4'>
                                        <button className="remove-btn continue_Btn" onClick={() => history.push('/marketplace')}>{lang?.Continue_shopping}</button>
                                        {cartProducts?.[0]?.map_offer_id?.length > 0 && <button className="continue_Btn clearBtn" onClick={() => handleDelete("232")}>{lang?.Clear_cart}</button>}
                                    </div>
                                    <button className="check_Btn" disabled={cartProducts?.filter((item) => item?.available_status !== 2)?.length === 0} onClick={() => history.push('/marketplace/checkout-form')}>{lang?.Checkout}</button>
                                </div>
                            </div>
                            :
                            <div className="empty-cart shopEmpty">
                                <div className='cartIcon'>
                                    <svg width="150" height="150" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.70959 4.68566C4.55334 4.68346 4.39821 4.71232 4.25322 4.77059C4.10822 4.82885 3.97625 4.91535 3.86498 5.02506C3.75371 5.13477 3.66535 5.26551 3.60504 5.40966C3.54473 5.55382 3.51367 5.70853 3.51367 5.86479C3.51367 6.02105 3.54473 6.17576 3.60504 6.31991C3.66535 6.46407 3.75371 6.5948 3.86498 6.70451C3.97625 6.81423 4.10822 6.90073 4.25322 6.95899C4.39821 7.01726 4.55334 7.04612 4.70959 7.04391H6.87592C7.44814 7.04391 7.92513 7.43925 8.03355 8.00195L8.26846 9.23021L10.8033 22.5429C11.2616 24.9448 13.3778 26.696 15.8238 26.696H30.5413C32.9872 26.696 35.1043 24.9455 35.5618 22.5429L38.0966 9.23021C38.1291 9.05975 38.1236 8.88423 38.0805 8.71615C38.0374 8.54807 37.9577 8.39158 37.8471 8.25784C37.7365 8.1241 37.5978 8.01642 37.4408 7.94246C37.2839 7.8685 37.1125 7.8301 36.939 7.83H10.401L10.3503 7.56132C10.0335 5.90058 8.56687 4.68566 6.87592 4.68566H4.70959ZM10.8509 10.1882H35.5142L33.245 22.1023C32.9966 23.4069 31.8702 24.3377 30.5413 24.3377H15.8238C14.4954 24.3377 13.3697 23.4069 13.1201 22.1023V22.1008L10.8509 10.1882ZM17.68 28.2682C17.0545 28.2682 16.4547 28.5166 16.0124 28.9589C15.5702 29.4011 15.3217 30.0009 15.3217 30.6264C15.3217 31.2518 15.5702 31.8517 16.0124 32.2939C16.4547 32.7362 17.0545 32.9846 17.68 32.9846C18.3054 32.9846 18.9052 32.7362 19.3475 32.2939C19.7897 31.8517 20.0382 31.2518 20.0382 30.6264C20.0382 30.0009 19.7897 29.4011 19.3475 28.9589C18.9052 28.5166 18.3054 28.2682 17.68 28.2682ZM28.6851 28.2682C28.0597 28.2682 27.4598 28.5166 27.0176 28.9589C26.5753 29.4011 26.3269 30.0009 26.3269 30.6264C26.3269 31.2518 26.5753 31.8517 27.0176 32.2939C27.4598 32.7362 28.0597 32.9846 28.6851 32.9846C29.3106 32.9846 29.9104 32.7362 30.3526 32.2939C30.7949 31.8517 31.0434 31.2518 31.0434 30.6264C31.0434 30.0009 30.7949 29.4011 30.3526 28.9589C29.9104 28.5166 29.3106 28.2682 28.6851 28.2682Z" fill="url(#paint0_linear_194_122)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_194_122" x1="20.8156" y1="4.68555" x2="20.8156" y2="32.9846" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#33A386" />
                                                <stop offset="1" stop-color="#2F97C1" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h2 className='mb-3 fs-40 font-bold'>{lang?.Empty_cart}</h2>
                                <p className='font-16 mb-3 font-normal'>{lang?.Empty_cart_text}</p>
                                <Link to="/marketplace" className="btn addtocart">{lang?.Go_to_shop}</Link>
                            </div>
                        }
                    </div>
                </div>
                {/* Incoterm Poppup */}
                {incoTerm &&
                    <IncoTermModal lang={lang} incoTerm={incoTerm} setIncoTerm={setIncoTerm}/>
                }
            </div>
            {(productModal?.isDelete && productModal?.id) && <DeleteConfirmation message={''} loader={false} onDelete={handleDetleteOffer} btnText={isProductWithoutOffer ? lang?.only_Remove : lang?.Clear} heading={isProductWithoutOffer ? lang?.Confirm_remove : lang?.Confirm_clear} subHeading={isProductWithoutOffer ? lang?.Are_you_sure_to_do : lang?.Are_you_sure_to_clear_cart} notes={''} onCancel={handleCancelDelete} title={isProductWithoutOffer ? lang?.only_product : ''} />}
        </div>
    )
}
