import React from 'react'

export default function InfoiconBlockView(props) {
  return (
    <>
        <div className='infoIconItem'>
            {props.Icon &&<div className='iconBlock'>
                {props.Icon}
            </div>}
            {props.Title &&<h4 className='fs-20 mt-4'>{props.Title}</h4>}
            {props.Description && <p className='mt-2'>{props.Description}</p>}
        </div>
    </>
  )
}
