import React from 'react'

export default function SectionTitleCenter(props) {
  return (
    <>
        <div className='secTitleCenter'>
            {props.titleSmall && <p className='text-blue fs-24'>{props.titleSmall}</p>}
            {props.Title && <h5>{props.Title}</h5>}
            {props.Description && <p className='mt-4 subTitle'>{props.Description}</p>}
        </div>
    </>
  )
}
