import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import FormSpinner from "../../../components/Spinners/FormSpinner";
import { fetchRestarantType, fetchItalianRestHubs, getRestaurentUsers, getConnectionData } from "../modules/B2B";
import constant from "../../../helpers/constant"
import RestaurantSearchBar from "./RestaurantSearchBar";
import ReactPaginate from "react-paginate";
import ConnectionModal from "../../../components/ConnectionModals/ConnectionModal";
import { acceptRejectRequestWithVisitorId, followUnfollowUser } from "../../NetworkConnection/modules/networkConnection";
import auth from "../../../helpers/auth";
import language from "../../../helpers/language";
export default function RestaurantSearch() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hubsId, setHubsId] = useState("");
  const [roleSearchType, setroleSearchType] = useState(2);

  const [restaurantType, setRestaurantType] = useState("");
  const [pickup, setPickup] = useState("");
  const [delivery, setDelivery] = useState("");
  const [page, setPage] = useState(1);
  const [isTrue, setIsTrue] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [openConnectionModel, setOpenConnectionModel] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [typeOfUser, setTypeOfUser] = useState("");
  const [userPercenatge, setProfilePercentage] = useState('');
  const [lang] = useState(language.getLang());
  const [hubShow, setHubShow] = useState(true);

  const { restaurantSpinner, getRestaurantsData, } = useSelector((state) => state.b2bState);
  const { profileData: { data } } = useSelector((state) => state.memberProfileData);
  useEffect(async () => {
    await setProfilePercentage(parseInt(localStorage.getItem("profile_percentage")));
    await getRestaurantSearch(page);
    await dispatch(fetchRestarantType(10));
    const userType = await auth.getUserType()
    await setTypeOfUser(JSON.parse(userType))
    await setIsTrue(true);
  }, []);

  const getRestaurantSearch = async (pageNo) => {
    const model = {
      searchType: roleSearchType,
      roleId: constant.rolesId.restaurant,
      hubsId: hubsId,
      restaurantType: restaurantType,
      pickup: pickup,
      delivery: delivery,
      page: !pageNo ? page : pageNo
    };
    await dispatch(fetchItalianRestHubs(model));
    
  };

  const handleModal = (newValue) => {
    setOpenConnectionModel(newValue);

  }
  const handlePageClick = async (e) => {
    let pageNo = e.selected + 1
    await setPage(page);
    await setIsChange(true);
    await getRestaurantSearch(pageNo);
  };
  const handleCancelReq = async (userId, roleId) => {
    const newdata = getRestaurantsData?.data?.data?.map((item) => item.user_id === userId ? { ...item, connection_flag: 0 } : item);
    const finalValue = {
      ...getRestaurantsData,
      data: { ...getRestaurantsData.data, data: newdata }
    }
    const response = await dispatch(acceptRejectRequestWithVisitorId({ connection_id: parseInt(userId), accept_or_reject: 3 }));
    if (response?.success === 200) {
      await dispatch(getRestaurentUsers(finalValue));
      typeOfUser?.role_id === roleId && await dispatch(getConnectionData(finalValue));
    }
  }

  const followUser = async (user) => {
    const respone = await dispatch(followUnfollowUser({ follow_user_id: user?.user_id, follow_or_unfollow: user.follow_flag === 0 ? 1 : 0 }));
    if (respone?.success === 200) {
      const newdata = getRestaurantsData?.data?.data?.map((item) => item.user_id === user?.user_id ? { ...item, follow_flag: user.follow_flag === 0 ? 1 : 0 } : item);
      const finalValue = {
        ...getRestaurantsData,
        data: { ...getRestaurantsData.data, data: newdata }
      }
      await dispatch(getRestaurentUsers(finalValue));

    } else {
      console.log(respone);
    }
  }

  const getStatesOfSearchBar = async(filters) => {
      //console.log(filters,"filterData");
      await setroleSearchType(filters.searchType);
      await setHubsId(filters.hubsId);
      await setRestaurantType(filters.restaurantType);
      await setPickup(filters.pickup);
      await setDelivery(filters.delivery);
  }

  return (
    <div className="servics_blocks">
      {/* <div className="medium_title">
              <h1 className="text-muted">Italian Restaurants in US</h1>
            </div> */}
      <div className="site-align top-align">
        {/* Left Block */}
        <RestaurantSearchBar hubShow={hubShow} getStatesOfSearchBar={getStatesOfSearchBar}/>
        {/* Middle long Block */}
        <div className="servics_blocks middle-long__section-block middleBlockBg">
          {openConnectionModel && <ConnectionModal openModelData={{ openModel: openConnectionModel, user_id: userId, name: userName, handleOpenModel: handleModal, type: constant.rolesId.restaurant, userData: getRestaurantsData }} />}
          <div className="">
            <div className="medium_title">
              <h1 className="font-18 text-black">{lang?.Italian_Restaurants_in_USB2B}</h1>
            </div>
            {restaurantSpinner ? (
              <FormSpinner />
            ) : (
              <div className="card--section site-align top-align flex-wrap">
                {getRestaurantsData?.errors && (
                  <p>{getRestaurantsData.errors?.exception}</p>
                )}
                {getRestaurantsData?.data?.data?.map((item, index) => {
                  return (
                    <div className="card card--block" key={index} >
                      <div className="hubUser">
                        <div className="feed-user-info">
                          <div className="user__pic" onClick={() => history.push(data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`)}>
                            <img src={item.avatar_id && `${item.avatar_id.base_url}${item.avatar_id.attachment_medium_url}`} alt="User Image" />
                          </div>
                          <div className="user__detail-data">
                            <span className="user__name">
                              <Link to={data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`} className="font-16 text-Blue">
                                {(item && item.first_name !== null && item && item.last_name !== null && `${item && item.first_name}${item && item.last_name}`) ||
                                  (item && item.company_name) || (item && item.restaurant_name)}
                              </Link>
                              {/* <div className="flex"> */}

                              {/* </div> */}
                            </span>
                          </div>
                        </div>
                        {parseInt(typeOfUser?.role_id) !== constant.rolesId.voyagers && <div className="hub_btn flex items-center">
                          <div className={(data?.user_data?.user_id !== item.user_id && item?.hubConnectionRequest)? "md:w-1/2 viewReq mt-4" : "md:w-1/2 md:w-full viewReq mt-4"}><Link to={data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                          {((userPercenatge === 100 && data?.user_data?.user_id !== item.user_id&& item?.hubConnectionRequest) && (item.available_to_connect === 1 && item.connection_flag === 0)) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className="btn bg-Primary btn BgTeal w-full" onClick={() => { setUserName(item?.company_name ?? item.restaurant_name); setOpenConnectionModel(true); setUserId(item.user_id) }}>{lang?.Connect}</Link></div>}
                          {userPercenatge !== 100 && (<div className="md:w-1/2 view__hub__btn"><Link to="/home" className='btn bg-Primary btn BgTeal w-full'>{lang?.Connect}</Link></div>)}
                          {(item.available_to_connect === 1 && item.connection_flag === 1) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' >{lang?.Connected}</Link></div>}
                          {(item.available_to_connect === 1 && item.connection_flag === 2) && <div className="md:w-1/2 view__hub__btn"><Link to="#" onClick={() => { handleCancelReq(item.user_id, item.role_id) }} className='btn bg-Primary btn BgTeal w-full' >{lang?.Cancel}</Link></div>}
                        </div>}
                        {parseInt(typeOfUser?.role_id) === constant.rolesId.voyagers && <div className="hub_btn flex items-center">
                          <div className={data?.user_data?.user_id !== item.user_id ? "md:w-1/2 viewReq mt-4" : "md:w-1/2 md:w-full viewReq mt-4"}><Link to={data?.user_data?.user_id === item.user_id ? "/profile" : `${"/visitor-profile"}/${item.user_id}`} className="btn bg-Primary bg-transparent w-full">{lang?.View}</Link></div>
                          {(item?.available_to_follow === 1 && item?.follow_flag === 0) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' onClick={() => followUser(item)}>{lang?.Follow}</Link></div>}
                          {(item?.follow_flag === 1 && item?.available_to_follow === 1) && <div className="md:w-1/2 view__hub__btn"><Link to="#" className='btn bg-Primary btn BgTeal w-full' onClick={() => followUser(item)}>{lang?.Unfollow}</Link></div>}
                        </div>}
                      </div>
                      {/* <div className="card-form">
                              <div className="view__hub__btn">
                                <Link to="#">Pending</Link>
                              </div>
                            </div> */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {isTrue && getRestaurantsData?.data?.total > 10 &&
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil((getRestaurantsData?.data?.total) / (getRestaurantsData?.data?.per_page))}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />}
        </div>
        {/* Right Block */}
      </div>
    </div>
  );
}
