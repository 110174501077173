import React, { useState, useEffect } from 'react'
import FormSpinner from '../../../../../components/Spinners/FormSpinner'; 
import language from '../../../../../helpers/language'
import { getBuyerContactInfo, updateContactNumber } from '../../../modules/Buyermodule';

export default function ChangeContactInformation() {
    const [lang] = useState(language.getLang());
    const intialValues = {
        contact_number : '',
    }
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disabledTrue, setDisabledTrue] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(async() => {
        let response = await getBuyerContactInfo();
        if(response?.success){
            await setFormValues({ ...formValues, ['contact_number']: response?.buyer_info?.phone });
        }
        //console.log(response,"response");
    },[]);

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmitting){
            updatePhoneNumber();
        }
    },[formErrors]);

     // Options set in states
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
    };
    // Form validation
    const validate = (values) => {
        let errors = {};
        if (!values.contact_number) {
        errors.contact_number = `${lang?.Option_require}`;
        }
        return errors;
    };

     // Check request validation
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const updatePhoneNumber = async() => {
        await setIsLoader(true);
        await setDisabledTrue(true);
        const updatePhone = {
            phone : formValues?.contact_number
        }

        let response = await updateContactNumber(updatePhone);
        if(response?.success){
            await setSuccessMessage(response?.message);
        }
        else{
            await setErrorMessage(response?.errors);
        }
        await setIsLoader(false);
        setTimeout(async() => {
            await setDisabledTrue(false);
            await setSuccessMessage("");
            await setErrorMessage("");
          }, 2500);
    }

  return (
    <>
        <div className='settingView'>
            <h4 className='pageTitle'>Change Contact Information</h4>
            <p>Enter your new number to change contact information</p>
            <div className='formView'>
            <div className='error_message'>{errorMessage}</div>
                <div className='success_message'>{successMessage}</div>
                <form onSubmit={(event) => handleSubmit(event)}>
                    <div className='form__item whiteBg relative flexBox phoneNumber'>
                        {/* <span className="countryCode">+91 </span> */}
                        <input type='number' name='contact_number' value={formValues.contact_number} onChange={(e) => handleChange(e)}placeholder='XXXXXXXXXX' />
                        
                    </div>
                    <div className='error_message'>{formErrors?.contact_number}</div>
                    <div className='actionButoon mt-4'>
                        <button type='submit' className='btn btn-blue style2' disabled={disabledTrue}>{isLoader ? <FormSpinner/> : 'Update' }</button>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
