import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import language from "../../helpers/language";

export function Select({ label, onChange, Options, id, itemkey, apiCall, type }) {
  const [lang] = useState(language.getLang());
  let {
    formValue,
  } = useSelector((state) => state.memberProfileData);

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <Fragment key={itemkey}>
      <div className="form__item">
        <label>{label}</label>
        <div className="">
          {apiCall === "true" ? (
            <select id={id} onChange={handleChange}>
              <option>{lang?.Select}</option>
              {Options.map((i) => {
                return (
                  <Fragment> <option value={i.id === "" ? i.name : i.id} key={i.name}> {i.name} </option></Fragment>
                );
              })}
            </select>
          ) : (
            <select id={id} onChange={handleChange}>
              <option>{lang?.Select}</option>
              {Options.map((i) => {
            
                if (i.is_selected) { formValue[id] = i.user_field_option_id }
                return (
                  <Fragment>
                    {
                      i.name!=="Buyer"&& <option
                      value={i.role_id ? i.role_id : i.user_field_option_id}
                      key={i.role_id ? i.role_id : i.user_field_option_id}
                      selected={i.is_selected}
                    >
                      {
                        i.role_id ? i.name : i.option
                      
                      }
                    </option> 
                    }
                    
                    {/* <option
                      value={i.role_id ? i.role_id : i.user_field_option_id}
                      key={i.role_id ? i.role_id : i.user_field_option_id}
                      selected={i.is_selected}
                    >
                      {
                        i.role_id ? i.name : i.option
                      
                      }
                    </option> */}
                  </Fragment>
                );
              })}
            </select>
            // role_id: 4, name: "Importer", slug: "importer", display_name: "Importer", image: "/images/roles/importer.png"}
          )}
          {/* <div className="select--field-arrow">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div> */}
        </div>
        {/* <div className="form-desc">Hotel/Restaurant/Café</div> */}
      </div>
      <hr />
    </Fragment>
  );
}

export default Select;

