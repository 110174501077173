
export const newAddressFields = [
    {
        name: "first_name",
        type: "text",
        value: '',
        placeHolder: "First name*",
        id: 1
    },
    {
        name: "last_name",
        type: "text",
        value: '',
        placeHolder: "Last name*",
        id: 2
    },
    {
        name: "email",
        type: "text",
        value: '',
        placeHolder: "email*",
        id: 3
    },
    {
        name: "street_address",
        type: "textArea",
        value: '',
        placeHolder: "Address 1*",
        id: 4
    },
    {
        name: "street_address_2",
        type: "textArea",
        value: '',
        placeHolder: "Address 2",
        id: 5
    },
    {
        name: "city",
        type: "text",
        value: '',
        placeHolder: "City*",
        id: 6
    },
    {
        name: "zipcode",
        type: "text",
        value: '',
        placeHolder: "Zip code*",
        id: 7
    },
    {
        name: "country",
        type: "select",
        value: '',
        placeHolder: "Country*",
        id: 8
    },
    {
        name: "state",
        type: "select",
        value: '',
        placeHolder: "State*",
        id: 9
    },
];

export const region = [
    {
        name: "Northwest",
        value: "Northwest",
        id: 1
    },
    {
        name: "Southwest",
        value: "South west",
        id: 2
    },
    {
        name: "Southeast",
        value: "SouthEast",
        id: 3
    },
    {
        name: "West",
        value: "West",
        id: 4
    },
    {
        name: "Midwest",
        value: "Midwest",
        id: 5
    },
    {
        name: "Northeast",
        value: "Northeast",
        id: 6
    },
    
];

export const country = [
    {
        name: "United States",
        value: "United States",
        id: 1
    },
];