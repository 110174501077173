import React from 'react'

export default function CenterContent() {
  return (
    <>
      <div className='bgBlue'>
        <div className='smallBadge'>New</div>
        <h4>Alysei B2B Platform for Buyers</h4>
        <p>
        Alysei, the first B2B Marketplace that brings the excellence of Italian food and wine to the Global Market.
        </p>
        <br/>
        <p>
          Alysei bridge the gap between tradition and modernity, offering the opportunity to Buyers to discover real Italian regional high-quality products to address their increasing customer demand.
        </p>
        <div className="card card--block card-gap mt-3">
          <div className='flexBox cardBadge'>
            <div className='Circle'>
              <svg width="29" height="28" viewBox="0 0 29 28" fill="none">
                <path d="M0.935059 0.304138V24.5777C0.935059 26.236 2.31097 27.6119 3.96926 27.6119H28.2428V24.5777H3.96926V0.304138H0.935059ZM25.6946 5.33249L19.1225 11.8424L14.5801 7.49851L5.94563 15.9048L8.06127 18.0797L14.5978 11.715L19.158 16.0737L27.831 7.48665L25.6946 5.33249Z" fill="white"/>
              </svg>
            </div>
            <div className='textBox'>
              <p className='font-weight-bold text-black'>A game changer for Food and Beverage Buyers around the world
</p>
            </div>
          </div>
        </div>
      </div>
      <div className='card card--block card-gap bgTeal mt-3'>
        <div className='flexBox'>
          <div className='leftBox'>
            <h4>40% <span>Growth</span></h4>
          </div>
          <div className='rightBox'>
            <p>of the demand for Italian Food and Beverage around the world
</p>
          </div>
        </div>
      </div>
    </>
  )
}
