import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchEventListingData, deleteEvent, setCreateEvent, editEventDataById } from '../../discover/modules/DiscoverModule';
import moment from 'moment';
import Modal from "react-modal";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';

export default function EventView({ isUniversalSearchTrue, visitorId }) {
    const history = useHistory();
    const params = useParams()
    const [lang] = useState(language.getLang());
    const [awardId, setAwardId] = useState('');
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isEvent, setIsEvent] = useState(true);
    const dispatch = useDispatch();

    useEffect(async () => {
        await setIsEvent(isUniversalSearchTrue ? isUniversalSearchTrue.hide : isEvent);
        if (window.location.pathname === "/profile" || window.location.pathname === `${"/visitor-profile/"}${params?.id}`) {
            if (visitorId) {
                await dispatch(fetchEventListingData(visitorId));
            } else {
                await dispatch(fetchEventListingData())
            }
        }
        await dispatch(editEventDataById([]));
        return () => { isEvent = false };
    }, []);
    const { createdEventData, awardSpinner } = useSelector((state) => state.discoverReducerState);

    const deleteEventitem = async (id) => {
        await setIsDelete(true);
        const response = await dispatch(deleteEvent(id));
        if (response) {
            await setIsDelete(false);
            let eventData = createdEventData?.filter((item) => item.event_id !== id);
            await setDeleteModel(false);
            await dispatch(setCreateEvent(eventData));
        }

    }
    return (
        <div>
            <div className="discover_page">
                <div className={isEvent ? "blogView gridColOne" : "blogView"}>
                    {awardSpinner ? <FormSpinner /> : createdEventData?.length > 0 ? createdEventData?.map((item) => {
                        return (
                            <div className='imgBlog'>
                                <div className='awardColn'>
                                    <div className='awardImg' onClick={() => history.push(`/view-events/${item.event_id}`)}>
                                        <img src={`${item?.attachment?.base_url}${item?.attachment?.attachment_medium_url}`} alt="Blog Image" />
                                    </div>
                                    {visitorId !== undefined || isEvent && <div className="flex justify-between items-center editOption mt-2">
                                        <Link to={`/edit-event/${item.event_id}`}><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                        <Link to="#" ><div onClick={() => { setDeleteModel(true); setAwardId(item.event_id); }}><i className="fa fa-trash" aria-hidden="true"></i></div></Link>
                                    </div>}
                                    <div className='blogProfile'>
                                        <div className="feed-user-info">
                                            <div className="user__pic">
                                                <img src={`${item?.user?.avatar_id?.base_url}${item?.user?.avatar_id?.attachment_medium_url}`} alt='Blog Profile' />
                                            </div>
                                            {/* <div className='eventLike'>
                                        <Link to="#" className='btn'>Are you interested?</Link>
                                    </div> */}
                                            <div className="user__detail-data">
                                                <span className="user__name">
                                                    <Link to="#">{item?.event_name}</Link>
                                                </span>
                                                <span className="">
                                                    {item?.host_name}
                                                </span>
                                            </div>
                                            {/* <div className='likeThumb'>
                                        <i className="fa fa-thumbs-up" aria-hidden="true"></i> &nbsp;
                                        <span>2</span>
                                    </div> */}
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <span>{item?.description?.length > 140 ? item?.description?.slice(0, 140) + '...' : item?.description}</span>
                                        <p className='readMore flex justify-between items-center'>
                                            <span className='text-black'>{moment(item.event_date_time).format("MMM D, YYYY")}</span>
                                            {item?.description?.length > 140 && <Link to={`/view-events/${item.event_id}`}>{lang?.More_info}</Link>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                        <div className="card card--block">
                            <div className="post--form emptyData">
                                <div className="empty_notification">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64.556" height="67.363" viewBox="0 0 64.556 67.363">
                                        <path id="icons8_event" d="M16.034,0a2.853,2.853,0,0,0-2.009.8,2.853,2.853,0,0,0-.8,2.009V5.614H4.807a2.854,2.854,0,0,0-2.009.8A2.854,2.854,0,0,0,2,8.42V64.556a2.853,2.853,0,0,0,.8,2.009,2.853,2.853,0,0,0,2.009.8H63.75a2.728,2.728,0,0,0,2.807-2.807V8.42A2.727,2.727,0,0,0,63.75,5.614h-8.42V2.807A2.853,2.853,0,0,0,54.532.8,2.853,2.853,0,0,0,52.522,0H49.716a2.853,2.853,0,0,0-2.009.8,2.853,2.853,0,0,0-.8,2.009V5.614H21.648V2.807A2.853,2.853,0,0,0,20.85.8,2.853,2.853,0,0,0,18.841,0Zm0,2.807h2.807v8.42H16.034Zm33.682,0h2.807v8.42H49.716ZM4.807,8.42h8.42v2.807a2.727,2.727,0,0,0,2.807,2.807h2.807a2.727,2.727,0,0,0,2.807-2.807V8.42H46.909v2.807a2.727,2.727,0,0,0,2.807,2.807h2.807a2.727,2.727,0,0,0,2.807-2.807V8.42h8.42v9.824H4.807Zm0,12.631H63.75V64.556H4.807Zm8.42,5.614V58.943h42.1V26.665Zm2.807,2.807h7.017v7.017H16.034Zm9.824,0h7.017v7.017H25.858Zm9.824,0H42.7v7.017H35.682Zm9.824,0h7.017v7.017H45.505ZM16.034,39.3h7.017v7.017H16.034Zm9.824,0h7.017v7.017H25.858Zm19.648,0h7.017v7.017H45.505ZM16.034,49.119h7.017v7.017H16.034Zm9.824,0h7.017v7.017H25.858Zm9.824,0H42.7v7.017H35.682Zm9.824,0h7.017v7.017H45.505Z" transform="translate(-2)" />
                                    </svg>
                                    <h3 className="font-20 font-bold mt-5 mb-3">{lang?.Events}</h3>
                                    <h4 className="font-18 text-normal text-black">{lang?.There_is_no_events_at_this_moment}</h4>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
            {/* Delete Popup */}
            {deleteModel && (
                <div>
                    <Modal
                        ariaHideApp={false}
                        isOpen={deleteModel}
                        style={{
                            overlay: {
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(255, 255, 255, 0.75)",
                            },
                            content: {
                                position: "absolute", top: "0", left: "0%", right: "0%", bottom: "200px", border: "1px solid #ccc", background: "#fff", overflow: "auto", WebkitOverflowScrolling: "touch", borderRadius: "10px", padding: "0",
                                // outline: 'none',
                            },
                        }}
                    >
                        <div className="popup_header flex justify-between items-center popupheaderBg">
                            <h4 className="page__title">{lang?.Delete}</h4>
                            <button
                                className="close_btn--modal"
                                onClick={() => setDeleteModel(!deleteModel)}
                            >
                                {lang?.close}
                            </button>
                        </div>
                        <div className="card card--block modal_box ">
                            <p className="warning">{lang?.are_you_sure}</p>
                            <div className="right__btn text-right">
                                <Link
                                    className="btn btn1"
                                    to="#"
                                    onClick={() => setDeleteModel(false)}
                                >
                                    {lang?.Cancel}
                                </Link>
                                <Link className="btn btn2 ml-3" to="#" onClick={() => deleteEventitem(awardId)}> {isDelete ? <FormSpinner /> : `${lang?.ok}`} </Link>
                            </div>
                        </div>
                        <div></div>
                    </Modal>
                </div>
            )}
        </div>
    );
}
