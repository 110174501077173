import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAlyseiNewsStatus } from '../modules/DiscoverModule';
import Stories from 'stories-react';
// import 'stories-react/dist/index.css';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setUpdateViewBubbles } from '../../home/modules/HomeModule';

export default function NewsStatus() {
    const [alyseiNews, setAlyseiNews] = useState([]);
    const [isAvailable, setIsAvailable] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { discover_alysei } = useSelector((state) => state.activityFeedStatus);


    useEffect(async () => {
        if (discover_alysei?.some((item) => item?.new_update && !item?.category && item.name === 'whats-new')) {
            await setUpdateViewBubbles('whats-new');
        }
    }, []);

    useEffect(async () => {
        const response = await dispatch(getAlyseiNewsStatus());
        await setAlyseiNews(response);
        await setIsAvailable(true);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    console.log(alyseiNews,"alesei news")

    return (

        <div className='story_content_wrapper'>
            <div className='site__width'>
                <div className='storyView'>
                    {/* <p onClick={()=> history.push('/')}>Close</p> */}
                    <div className='storyHeader'>
                        <div className="closeStory">
                            <Link onClick={() => history.push('/')}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </Link>
                        </div>
                    </div>
                    {isAvailable !== false &&
                        <Stories width='100%' height="600px" stories={alyseiNews} defaultDuration={2000} />
                    }
                </div>
            </div>
        </div>
    )
}
