
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Cropper from "react-cropper";
import Modal from "react-modal";

import language from '../../../helpers/language';
import FormError from '../../../components/Errors/FormError';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import user_img from '../../../assets/images/user_profile-img.png'

import { createAward, fetchEditAwardData, updateAward, setFormError } from '../modules/DiscoverModule';

export default function CreateAward() {
    const [awardName, setAwardName] = useState('');
    const [winningProduct, setWinningProduct] = useState('');
    const [medalId, setMedalId] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [imageId, setImageId] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [awardStatus, setAwardStatus] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [lang] = useState(language.getLang());
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();


    const [isCropped, setIsCropped] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [image, setImage] = useState('');
    const [fileName, setFileName] = useState('');
    const [cropData, setCropData] = useState("");
    const cropper = React.createRef(null);

    useEffect(async () => {
        id !== undefined && await dispatch(fetchEditAwardData(id));
    }, [id]);
    const { editAwardData, awardSpinner, errMessage } = useSelector((state) => state.discoverReducerState);

    useEffect(async () => {
        if (Object.keys(editAwardData)?.length > 0) {
            // await setImageId(`${editAwardData.attachment?.base_url}${editAwardData.attachment?.attachment_url}`);
            await setImageUrl(editAwardData.attachment !== null ? `${editAwardData.attachment?.base_url}${editAwardData.attachment?.attachment_medium_url}` : '');
            await setAwardName(editAwardData?.award_name);
            await setWinningProduct(editAwardData?.winning_product);
            await setMedalId(editAwardData?.medal_id);
            await setWebsiteUrl(editAwardData?.competition_url);
        }
    }, [editAwardData])


    const selectMedal = (e) => {
        setMedalId(e.target.value);
    }
    const addAwardImage = async (e) => {
        console.log("edit");
        // let file = e.target.files[0];
        // setImageId(file)
        // const img = URL.createObjectURL(file);
        // setImageUrl(img);
        await setOpenModal(true)
        await setFileName(e.target.files[0].name);
        await setImage(URL.createObjectURL(e.target.files[0]));
        return e.target.value = null
    }

    const createAwardfunction = async () => {
        const awardData = {
            award_name: awardName,
            winning_product: winningProduct,
            medal_id: medalId,
            competition_url: websiteUrl,
            image_id: imageId
        };
        // let website = websiteUrl.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if (awardName === '') {
            dispatch(setFormError(lang?.enterEvent, "awardName"));
        } else if (winningProduct === '') {
            dispatch(setFormError(lang?.enterWinningProduct, "winningProduct"));
        }
        else if (medalId === '' || medalId == "-1") {
            dispatch(setFormError(lang?.enterMedalName, "medalName"));
        }
        else if (imageUrl === '') {
            dispatch(setFormError(lang?.enterImage, "imageId"));
        }
        // else if (!website) {
        //     dispatch(setFormError("Please Enter valid website .", "websiteUrl"));
        // }
        else {

            await setIsAdd(true);
            if (id !== undefined) {
                awardData.award_id = id;
                const response = await dispatch(updateAward(awardData));
                if (response) {
                    await setIsAdd(false);
                    await setAwardStatus(response.message);

                } else {
                    await setIsAdd(false);
                    setAwardStatus('');
                }
            } else {
                const response = await dispatch(createAward(awardData));
                if (response) {
                    await setIsAdd(false);
                    history.push("/profile");
                } else {
                    await setIsAdd(false);
                    setAwardStatus('');
                }
            }

        }

        await setTimeout(() => {
            dispatch(setFormError(''));
            setAwardStatus('');
        }, 3000);
    }

    const WebsiteHandleChange = async (e) => {
        setWebsiteUrl(e.target.value)
    }

    // Start Cropping your Image
    const getCropData = async () => {
        await setIsCropped(true);
        if (typeof cropper !== "undefined") {
            setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
            await setImageUrl(cropper.current.cropper.getCroppedCanvas().toDataURL());
            await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL())
                .then(res => res.blob()
                    .then(async (blob) => {
                        const file = await new File([blob], `${fileName}`, { type: "image/png" });
                        setImageId(file)
                    })
                );
            await setIsCropped(false);
            await setOpenModal(false);
        }
    };
    const closeModal = async () => {
        await setCropData('');
        await setImage('');
        await setCropData('');
        await setFileName('');
        await setOpenModal(false);
    }
    // End of Cropping

    return (
        <Fragment>
            <div className="discover_page forCreate">
                <div className="page_top_form">
                    <div className="flex justify-between items-center">
                        <div className='leftArrowBlock'>
                            <Link to="#" onClick={() => history.goBack()}>
                                <i className="fa fa-arrow-left pr-2" aria-hidden="true"></i>
                            </Link>
                            {id === undefined ? `${lang?.Add}` : `${lang?.Edit}`} {lang?.Award}
                        </div>

                        <div className="editProfileBtn">
                            <button className='btn btnPrimary' onClick={() => { createAwardfunction(); }}>{isAdd ? <FormSpinner /> : id !== undefined ? `${lang?.Update}` : `${lang?.Save}`}</button>
                        </div>
                    </div>
                </div>

                <h4 className='font-18 text-green text-center mt-4 mb-4'>{awardStatus}</h4>
                <div className="discover__events create">
                    {awardSpinner ? <FormSpinner /> : <form>
                        <div className='createForm mt-4'>
                            <div className='md:w-2/5 sm:w-full xl:mb-0 lg:mb-0 md:mb-0 sm:mb-4'>
                                <div className='colLeftBlock'>
                                    <div className="fileUpload">
                                        <label className="hide-input">
                                            <span>
                                                <i className="fa fa-camera" aria-hidden="true"></i> &nbsp; {lang?.Award} {lang?.Image}
                                            </span>
                                            <input type="file" id="award_images" accept="image/png,image/jpeg" onChange={(e) => addAwardImage(e)} />
                                            {(imageUrl !== '') && <img src={imageUrl} alt="award-image" />}
                                            {(imageUrl === '' && id !== undefined) && <img src={user_img} alt="award-default-image" />}

                                        </label>
                                    </div>
                                </div>
                                {errMessage.field == "imageId" && <div> <FormError msg={errMessage.msg} /></div>}
                            </div>
                            <div className='md:w-3/5 sm:w-full'>
                                <div className='colRightBlock'>
                                    <div className="form__item">
                                        <label>{lang?.Name_of_Competition_Event} *</label>
                                        <input type="text"  value={awardName} placeholder='' onChange={(e) => { setAwardName(e.target.value); }} />
                                    </div>
                                    {errMessage.field == "awardName" && <div> <FormError msg={errMessage.msg} /></div>}
                                    {/* maxLength={25} */}
                                    <div className="form__item">
                                        <label>{lang?.Name_of_winning_Product} *</label>
                                        <input type="text" value={winningProduct} placeholder='' onChange={(e) => { setWinningProduct(e.target.value); }} />
                                    </div>
                                    {errMessage.field == "winningProduct" && <div> <FormError msg={errMessage.msg} /></div>}

                                    <div className="form__item">
                                        <label>{lang?.Name_of_Modal_place_recived} *</label>
                                        <select onChange={(e) => { selectMedal(e) }} value={medalId}>
                                            <option value="-1">{lang?.Select_Medal}</option>
                                            <option value={1}>{lang?.Bronze}</option>
                                            <option value={2}>{lang?.Silver}</option>
                                            <option value={3}>{lang?.Gold}</option>
                                            <option value={4}>{lang?.Winner}</option>
                                        </select>
                                    </div>
                                    {errMessage.field == "medalName" && <div> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Website}</label>
                                        <a><input type="text" maxLength={255} value={websiteUrl} placeholder='' onChange={(e) => WebsiteHandleChange(e)} /></a>
                                    </div>
                                    {errMessage.field == "websiteUrl" && <div> <FormError msg={errMessage.msg} /></div>}
                                </div>
                            </div>
                        </div>
                    </form>}
                </div>
            </div>
            {/* crop modal  */}
            {openModal && <Modal className=""
                ariaHideApp={false}
                isOpen={openModal}
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                    },
                    content: {
                        position: "absolute",
                        top: "0",
                        left: "0%",
                        right: "0%",
                        bottom: "200px",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        padding: "0px",
                        // outline: 'none',
                    },
                }}
            >
                <div className="popup_header flex justify-between items-center">
                    <h4 className="page__title">{lang?.Crop_image}</h4>
                    <button className="close_btn--modal" onClick={() => closeModal()} > {lang?.close}</button>
                </div>
                <div className="card card--block modal_box">
                    <div className="crop_img_block">
                        <Cropper
                            zoomTo={0}
                            style={{ width: '100%' }}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            dragMode={'move'}
                            cropBoxMovable={false}
                            responsive={true}
                            cropBoxResizable={false}
                            ref={cropper}
                            initialAspectRatio={2/2}
                            center={true}
                            guides={false}
                            />
                    </div>
                    <div className="form__action form__item mt-4">
                        <button onClick={() => getCropData()}> {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`} </button>
                    </div>
                </div>
                {/* {cropData !== '' && <img style={{ width: "100%" }} src={cropData} alt="cropped" />} */}
            </Modal>}
            {/* crop modal end */}
        </Fragment>
    )
}
