import constant from '../helpers/constant';
import React, { useState } from 'react';
import language from '../helpers/language';

export default function MediaMemberType({ item }) {
  const [lang] = useState(language.getLang());
  return (
    <div className="profile__detail">
      {item?.role_id === constant.rolesId.producer && (
        <span>{lang?.Producer}</span>
      )}
      {item?.role_id === constant.rolesId.importer && (
        <span>{lang?.only_Importer}</span>
      )}
      {item?.role_id === constant.rolesId.distributer && (
        <span>{lang?.only_Distributor}</span>
      )}
      {item?.role_id == constant.rolesId['importer&distributer'] && (
        <span>{lang?.Importer_And_Distributor_Singular}</span>
      )}
      {item?.role_id === constant.rolesId.voiceOfExpert && (
        <span>{lang?.Voice_Of_Expert}</span>
      )}
      {item?.role_id === constant.rolesId.travelAgencies && (
        <span>{lang?.Travel_Agencies}</span>
      )}
      {item?.role_id === constant.rolesId.restaurant && (
        <span>{lang?.Restaurant}</span>
      )}
      {item?.role_id === constant.rolesId.voyagers && (
        <span>{lang?.Voyager}</span>
      )}
      {item?.role_id !== constant.rolesId.voyagers}
    </div>
  );
}
