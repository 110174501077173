import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import DashboardSideBar from './DashboardSideBar';
import language from '../../../helpers/language';
import { useLocation } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { createTax, createTaxClass, getMyAllTaxesList, updateTax, updateTaxClass, getSingeTaxClassDetails, getSingeTaxDetails } from '../modules/marketplace';

export default function GenerateTransactionReport() {
    const { pathname } = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState("tax-management");
    const [lang] = useState(language.getLang());
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        tax_id: ''
    });
    const [errors, setErrors] = useState({});
    const [messageText, setMessageText] = useState('');
    const [selectedTaxId, setSelectedTaxId] = useState([]);
    const [allTaxes, setAllTaxes] = useState([]);
    const [loading, setLoading] = useState(false);
    let path = id ? (pathname?.split("/")[2] === "edit-tax" ? "add-tax" : "add-tax-classes") : pathname?.split("/")[2]

    const selectTab = async (tab) => {
        setActiveTab(tab);
    }

    useEffect(() => {
        id && getSingleTaxAndTaxClassDetails()
    }, [id]);

    const getSingleTaxAndTaxClassDetails = async () => {
        const response = path !== "add-tax" ? await getSingeTaxClassDetails(id) : await getSingeTaxDetails(id);
        if (response?.success === 200) {
            const taxData = response?.data;
            if (path !== "add-tax") {
                setFormData({ name: taxData?.name, description: taxData?.description, tax_class_id: taxData?.tax_class_id, tax_id: taxData?.get_tax_classes?.map((item) => item?.tax_id) ?? [] })
                setSelectedTaxId(taxData?.get_tax_classes?.map((item) => item));
            } else {
                setFormData({ ...taxData });
            }


        }
    }

    // Get my product taxt
    useEffect(async () => {
        getAllTaxList()
    }, []);

    const getAllTaxList = async () => {
        const response = await getMyAllTaxesList();
        setAllTaxes(response.data ?? []);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        setLoading(true);
        if (Object.keys(validationErrors).length === 0) {
            if (path === "add-tax") {
                delete formData.tax_id
                delete formData.description
                delete formData.name
            } else {
                delete formData.tax_type
                delete formData.tax_rate
                delete formData.type_name
            }
            const response = path === "add-tax" ? await createTax(formData) : await createTaxClass(formData);
            if (response?.success === 200) {
                setLoading(false);
                setFormData({
                    name: '',
                    description: '',
                    tax_id: ''
                });
                setSelectedTaxId([]);
                setMessageText(response.message);
                setTimeout(() => {
                    setMessageText('');
                    history.push('/marketplace/dashboard');
                }, 1000);
            } else {
                setErrors({ errorMsg: response?.message });
            }
        } else {
            setErrors(validationErrors);
            setLoading(false);
        }
    };

    const onSelect = (selectedList, selectedItem) => {
        const taxId = selectedList?.map(({ tax_id }) => tax_id);
        setFormData((prev) => ({ ...prev, ["tax_id"]: taxId }));
        setErrors({ ...errors, ["tax_id"]: "" });
    }

    const onRemove = (selectedList, removedItem) => {
        const taxId = selectedList?.map(({ tax_id }) => tax_id);
        if (taxId?.length > 0) {
            setFormData((prev) => ({ ...prev, ["tax_id"]: taxId }));
        } else {
            setFormData((prev) => ({ ...prev, ["tax_id"]: taxId }));
            setErrors({ ...errors, ["tax_id"]: "This field is required" });
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({});
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data?.name?.trim() && path === "add-tax-classes") {
            errors.name = lang?.Option_require;
        }

        if (data?.tax_id?.length === 0 && path === "add-tax-classes") {
            errors.tax_id = lang?.Option_require;
        }

        if (!data?.tax_name?.trim() && path === "add-tax") {
            errors.name = lang?.Option_require;
        }
        if (!data?.tax_rate && path === "add-tax") {
            errors.description = lang?.Option_require;
        }
        if (!data?.tax_type && path === "add-tax") {
            errors.tax_id = lang?.Option_require;
        }

        return errors;
    };


    const updateTaxClasses = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        setLoading(true);
        if (Object.keys(validationErrors).length === 0) {
            if (path === "add-tax") {
                delete formData.description
                delete formData.name
            } else {
                delete formData.tax_type
                delete formData.tax_rate
                delete formData.type_name
            }
            const response = path === "add-tax" ? await updateTax(formData, formData?.tax_id) : await updateTaxClass(formData, formData?.tax_class_id);
            if (response?.success) {
                setLoading(false);
                setFormData({
                    name: '',
                    description: '',
                    tax_id: ''
                });
                setMessageText(response.message);
                setTimeout(() => {
                    setMessageText('');
                    history.push('/marketplace/dashboard');
                }, 1000);
            } else {
                setErrors({ errorMsg: response?.message })
            }
        } else {
            setErrors(validationErrors);
            setLoading(false);
        }

    }


    return (
        <>
            <div className='servics_blocks dashboardView generate_transaction addTax'>
                <div className='site-align top-align'>
                    <div className='left__section-block sm:mb-2 md:mb-2'>
                        <div className='tabLeftBlock'>
                            <DashboardSideBar lang={lang} selectTab={selectTab} activeTab={activeTab} id={id} />
                        </div>
                    </div>
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>
                        <div className="product-title">
                            <div className="flex flex-wrap justify-between items-center">
                                <h4 className="mb-0">
                                    {id ? lang?.Edit : lang?.Add} {path !== "add-tax" ? lang?.Tax_class : lang?.Tax_rate}
                                </h4>
                                {messageText && <p>{messageText}.</p>}
                                <div className="inner-btn-box">
                                    <button
                                        className="btn btn-blue"
                                        onClick={(e) => id ? updateTaxClasses(e) : handleSubmit(e)}
                                        disabled={loading}
                                    >
                                        {loading ? `${lang?.Saving}...` : lang?.Save}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="product-list">
                            <div className='recentOrder secBlockGap'>
                                <div className='productFilter innderHeader mb--30 justify--between'>
                                </div>
                                <div className='mrkt-place form'>
                                    <div className='card-form'>
                                        <div className='flex flex-wrap main-row'>
                                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                                <div className='col-100'>
                                                    <div className='form__item'>
                                                        <label>{path !== "add-tax" ? lang?.Tax_class_title : lang?.Tax_name}</label>
                                                        {path !== "add-tax" ?
                                                            <textarea className='cmnt-write' id='description' maxLength={250} name={"name"}
                                                                placeholder={"Class Name"}
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                            >
                                                            </textarea> :
                                                            <input type="text" name="tax_name" value={formData?.tax_name} onChange={handleChange} placeholder={lang?.Tax_name} />
                                                        }
                                                    </div>
                                                </div>
                                                {errors.name && <label style={{ color: 'red' }} className="error">{errors.name}</label>}
                                            </div>
                                            {path === "add-tax" ?
                                                <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                                    <div className='productFilter'>
                                                        <div className='form__item w-full'>
                                                            <label>{lang?.Type}</label>
                                                            <>
                                                                <select name={"tax_type"} value={formData?.tax_type} onChange={handleChange}>
                                                                    <option value={''}> {lang?.Select_tax_type} </option>
                                                                    <option key={1} value={"percentage"}>{lang?.Percentage}</option>
                                                                    <option key={2} value={"fixed"}>{lang?.Fixed}</option>
                                                                </select>
                                                            </>

                                                        </div>

                                                        {/*<div className='iconGroup'>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                                        <rect width="47.5" height="47.4999" fill="#FF3131" />
                                                        <path d="M21.3733 10.7822L20.1217 12.0338H13.8638V14.537H15.1154V33.3109C15.1154 33.9645 15.3548 34.6308 15.8267 35.1027C16.2986 35.5746 16.9649 35.8141 17.6185 35.8141H30.1345C30.7881 35.8141 31.4544 35.5746 31.9263 35.1027C32.3982 34.6308 32.6377 33.9645 32.6377 33.3109V14.537H33.8893V12.0338H27.6313L26.3797 10.7822H21.3733ZM17.6185 14.537H30.1345V33.3109H17.6185V14.537ZM20.1217 17.0402V30.8077H22.6249V17.0402H20.1217ZM25.1281 17.0402V30.8077H27.6313V17.0402H25.1281Z" fill="white" />
                                                    </svg>
                                            </span>
                                        </div>*/}
                                                    </div>
                                                    {errors?.tax_id && <label className="error" style={{ color: 'red' }}>{errors?.tax_id}</label>}
                                                </div> :
                                                <>


                                                </>
                                            }
                                            <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                                <div className='col-100'>
                                                    {<div className='form__item'>
                                                        <label>{path !== "add-tax" ? lang?.Description : lang?.Tax_rate}</label>
                                                        {path !== "add-tax" ?
                                                            <textarea className='cmnt-write' id='description'
                                                                name={"description"}
                                                                placeholder={"Description"}
                                                                value={formData.description}
                                                                onChange={handleChange}
                                                                maxLength={250}
                                                            >
                                                            </textarea> :
                                                            <input type="number" name="tax_rate" placeholder={lang?.Tax_rate} value={formData?.tax_rate} onChange={handleChange} />
                                                        }
                                                    </div>}
                                                </div>

                                                {errors.description && <label className="error" style={{ color: 'red' }}>{errors.description}</label>}
                                            </div>
                                            {path !== "add-tax" ?
                                                <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                                    <div className='productFilter mt-2'>
                                                        <div className='form__item w-full'>
                                                            <label>{lang?.Type}</label>
                                                            <>
                                                                {allTaxes?.length > 0 &&
                                                                    <Multiselect
                                                                        options={allTaxes}
                                                                        selectedValues={selectedTaxId}
                                                                        onSelect={onSelect}
                                                                        onRemove={onRemove}
                                                                        displayValue="tax_with_price"
                                                                    />}
                                                            </>

                                                        </div>
                                                    </div>
                                                </div> : <p></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
