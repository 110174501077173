import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate';

export default function PaginatedComponentView({lastPage,handlePageClick,index}) {
 
console.log(index,"in dex")
    return (
        <>
            {lastPage > 1 && <div className="productListing" style={{ marginTop: "2rem" }}>
                <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={lastPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={index}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div>}
        </>
    )
}
