import React, { useState } from 'react'
import '../../../assets/marketplacecss/mrktplace-style.css'
import { Link } from 'react-router-dom'
import language from '../../../helpers/language';

export function ErrorPage() {
  const [lang] = useState(language.getLang());
  return (
    <>
      <div className="marketplace submitation">

        {/* Banner Section */}
        <div className="main__content">
          <div className="section__wrapper">
            <div className="page-padding">
              <div className="width--submitation">
                <div className="icon">
                  <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
                <h3>"Sorry</h3>
                <p className="mt-5 mb-5">
                  {lang?.NotAllowed}"
                </p>
                <div className="inner-btn-box justify-center btn-style-2">
                  <Link to="/home" className="btn btn-blue">{lang?.GoHome}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}