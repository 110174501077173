import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setMyDashboardStatus } from '../../routes/marketplace/modules/marketplace'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfettiExplosion from 'react-confetti-explosion';

export default function PaymentSuccessfully() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRedirect = () => {
    dispatch(setMyDashboardStatus('myOrder'))
    history.push("/my-dashboard");
  }
  return (
    <div className="marketplace add-product editProduct shopping-Cart dashboardView">
      <div className="product-list">
        <div className='recentOrder secBlockGap add_Cart'>
          <div className='itemTable'>
            <div className='empty-cart shopEmpty'>
              <div class="cartIcon mb-6">
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </div>
              <div className='centerBlock'>
                <ConfettiExplosion
                  particleSize={4}
                  force={0.8}
                  duration={4000}
                  particleCount={300}
                  floorHeight={800}
                  floorWidth={""}
                />
              </div>
              <h2 class="mb-4 fs-40 font-bold">Payment Successful!</h2>
              <p className='font-16 mb-4 font-normal'>Your order has been successfully processed.</p>
              <Link to='#' onClick={handleRedirect} className="btn btn-primary">Back to Dashboard</Link>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
