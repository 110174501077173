import React, { Fragment, useEffect, useState } from "react";
import { fetchFormFields } from "../modules/register";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { connect, useSelector, useDispatch } from "react-redux";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from "../../../helpers/language";
import auth from "../../../helpers/auth";
import Access from "./access";
import demoicon from "../../../assets/images/demo.svg";

export function RegisterView(params) {
  const accessCode = auth.getAccessCode();
  const [lang] = useState(language.getLang());
  let { role_data, userRoleSpinner } = useSelector(
    (state) => state.authWrapper
  );

  useEffect(() => {
    const str = window.location.href;
    const getUrl = str.split("/");
    const bodyUrl = getUrl[getUrl.length - 1];
    document.body.classList.add(bodyUrl);
  }, []);

  return (
    <>
      {/* {accessCode === "verified" ? ( */}

      <Fragment>
        <div className=''>
          <div className='scheduleDemo ml-auto'>
            <a
              href='https://calendly.com/alysei2024'
              target='_blank'
              rel='noreferrer'
            >
              <div className='flexBox'>
                <span>
                  <img src={demoicon} alt='Demo Icon' />
                </span>{" "}
                Schedule a demo
              </div>
            </a>
          </div>
          <div className='main-center-heading'>
            <h1>{lang?.Join__Alysei_Today} </h1>
            <p>
              {
                lang?.Become_an_Alyesi_member_by_signing_up_for_the_Free_Trial_Beta_version
              }
            </p>
          </div>
          {userRoleSpinner ? (
            <FormSpinner />
          ) : (
            <div className='register__options sm:flex sm:flex-wrap'>
              {role_data?.roles?.map((item) => (
                <div className='w-full md:w-1/3' key={item.role_id}>
                  <Link to={`/register/${item.role_id}`}>
                    {/* <div className="bg-white hover:bg-blue-900 h-full p-4 hover:text-white transition delay-150 duration-300 ease-in-out flex flex-wrap items-center rounded-lg producer__main"> */}
                    <div className='register_block bg-white h-full p-4 rounded-lg hover:text-white'>
                      <div className='option__text hover:text-white'>
                        <h1>{item.name}</h1>
                      </div>
                      <div className='flex items-center producer__main'>
                        <div className='w-48 option__text'>
                          <p>{item.description}</p>
                        </div>
                        <div className='rounded-full w-32 h-32 option__item'>
                          <img
                            src={
                              item && item.attachment
                                ? `${item.attachment.base_url}${item.attachment.attachment_url}`
                                : "https://images.unsplash.com/profile-fb-1447837867-8e7ce9b9594c.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
                            }
                            className='rounded-full h-full'
                            alt='Profile'
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
          <div className='et_pb_button_wrapper text-center mt-4 mb-3'>
            <a
              className='et_pb_button et_pb_more_button'
              href='https://info.alysei.com'
              target='_blank'
            >
              {lang?.Learn_More}
            </a>
          </div>
          <a
            href='https://wa.me/+13122864961'
            class='whatsapp_float'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i class='fa fa-whatsapp whatsapp-icon'></i>
          </a>
        </div>
      </Fragment>
      {/* ) : (
        <Redirect to="/access" />
      )} */}
    </>
  );
}

export default RegisterView;
