import React, { Fragment, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import HomeRightBlock from "./HomeRightBlock";
import HomeLeftBlock from "./HomeLeftBlock";
import { SocialPost } from "./SocialPost";
import auth from "../../../helpers/auth";
import { memberProfileData, profileProgress } from "../../profile/modules/profile";
import RightFooter from "../../../components/Footer/RightFooter";
import firebase from "../../../config/firebase";

export function PostActivity(props) {
    const dispatch = useDispatch();
    const [profilePercentage, setProfilePercentage] = useState(localStorage.getItem("profile_percentage"));
    const [alyseiReview, setAlyseiReview] = useState(false);

    const { profileData } = useSelector((state) => state.memberProfileData);
  
    useEffect(async () => {
        document.body.classList.remove("hubs");
        document.body.classList.remove("profile");
        document.body.classList.remove("selected_hubs");
        document.body.classList.remove("home");
        const token = auth.getAccessToken();
        await dispatch(memberProfileData(token));
    }, []);

    useEffect(async () => {
        localStorage.removeItem('post-url');
        await dispatch(profileProgress());
        await setProfilePercentage(parseInt(localStorage.getItem("profile_percentage")));
    }, []);

    useEffect(() => {
        const str = window.location.href;
        const getUrl = str.split("/");
        const bodyUrl = getUrl[getUrl.length - 1];
        document.body.classList.add(bodyUrl);

        // For Scroll
        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(document).scrollTop() > 20) {
                    $("#addScrollClass").addClass("addScroll");
                } else {
                    $("#addScrollClass").removeClass("addScroll");
                }
            });
        });
    }, []);

    useEffect(async () => {
        await getUserNode()
        // await fetchPostData()
    },[alyseiReview])

    const getUserNode = () => {
        const userId = profileData?.data?.user_data?.user_id
        const fetchUserNode = firebase.database().ref("users").child(userId)
        fetchUserNode.on("value", (snapshot) => {
            const response = snapshot.val();
            setAlyseiReview(response?.alysei_approval)
        });
    }

    return (
        <Fragment>
            {/* <div className="whatsappWeb">
                <a href="https://api.whatsapp.com/send/?phone=919015148283&text&app_absent=0" target="_blank">
                    <svg viewBox="0 0 48 48" width="70px" height="70px" fill-rule="evenodd" clip-rule="evenodd"><path fill="#fff" d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z" /><path fill="#fff" d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z" /><path fill="#cfd8dc" d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z" /><path fill="#40c351" d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z" /><path fill="#fff" fill-rule="evenodd" d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z" clip-rule="evenodd" /></svg>
                </a>
            </div> */}
            <div className="home__page" id="addScrollClass">
                {/* Layout Content */}
                <div className="site-align top-align">
                    {/* Left Block */}
                    <div className="left__section-block md:mb-2 sm:mb-2">
                        <HomeLeftBlock />
                        {/* <SuggestedFriend /> */}
                    </div>
                    {/* Middle Block */}
                    {parseInt(profilePercentage) === 100 &&
                        <div className="middle__section-block home_page">
                            {/* <AddPost /> */}
                            <SocialPost redirect_to={"post_screen"} />
                        </div>}
                    {/* Right Block */}
                    {<div className="tab-content-right-section lg:order-last md:order-first sm:order-first md:mb-2 sm:mb-2">{parseInt(profilePercentage) === 100 && <HomeRightBlock />}<RightFooter /></div>}
                    {/* End Right Block */}
                </div>
            </div>
        </Fragment>
    );
}
