import React, { Fragment, useState } from "react";
import language from "../../helpers/language";

export function PasswordIndicator({ specialChar, digits, strLength }) {
  const [lang] = useState(language.getLang());
  return (
    <Fragment>
      <div className="pwd-hints">
        <div className="hint-list"> <span style={strLength ? { color: 'green' } : null}> <i className="far fa-check-circle" /> </span> {lang?.Minimun_8}</div>
        <div className="hint-list"> <span style={digits ? { color: 'green' } : null}>  <i className="far fa-check-circle" /></span> {lang?.digit_1} </div>
        <div className="hint-list"> <span style={specialChar ? { color: 'green' } : null}> <i className="far fa-check-circle" /> </span> {lang?.special_1}
          <a href="#" title="Valid Special Characters: [!@#$%^&*+-=[/]()`~|\<>?]" > <i className="far fa-question-circle" /></a>
        </div>
      </div>
      <hr />
      <div className="form-desc"> {lang?.PassWordValidation} </div>
      <hr />
    </Fragment>
  )
}

export default PasswordIndicator;