import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from "react-redux";
import FormSpinner from '../Spinners/FormSpinner';
import { getUserCertificate, updateUserCertificate } from '../../routes/NetworkConnection/modules/networkConnection';
import { Link } from 'react-router-dom';
import language from '../../helpers/language';


export default function Certificate(props) {
    const [lang] = useState(language.getLang());

    const dispatch = useDispatch();
    const [vatNo, setvatNo] = useState();
    const [fdaNo, setfdaNo] = useState('');
    const [isFdaEmpty, setIsFdaEmpty] = useState(true);
    const [userCertificates, setUserCertificates] = useState(null);
    const [productType, setProductType] = useState(JSON.parse(localStorage.getItem("productTypeArr")));
    const [filterProductType, setFilterProductType] = useState(JSON.parse(localStorage.getItem("productTypeArr")));

    useEffect(async () => { await dispatch(getUserCertificate()) }, []);
    const { cuserCertificateSpinner, userCertificateData, updateuserCertificateSpinner } = useSelector((state) => state.networkConnectionState);
//console.log(userCertificateData,'userCertificateData');
    useEffect(async () => {
        await setvatNo(userCertificateData && userCertificateData.user_data && userCertificateData.user_data.vat_no)
        await setfdaNo(userCertificateData?.user_data?.fda_no === null || userCertificateData?.user_data?.fda_no === '' ? '' : userCertificateData?.user_data?.fda_no)
        await setIsFdaEmpty(userCertificateData?.user_data?.fda_no === null || userCertificateData?.user_data?.fda_no === '' ? true : false)
    }, [userCertificateData])

    useEffect(() => {
        const filteredArray = userCertificateData?.data_certificates?.filter(value => productType?.includes(value.user_field_option_id))
        setFilterProductType(filteredArray)
    }, [productType?.length, userCertificateData])

    const uploadCertification = async (e, fieldId) => {
        setUserCertificates(e.target.name)
        const model = {
            vat_no: vatNo,
            fda_no: fdaNo,
            user_field_option_id: fieldId
        }
        model[e.target.name] = await e.target.files[0]
        await dispatch(updateUserCertificate(model))
    }
    const handleSave = async () => {
        const model = {
            vat_no: vatNo,
            fda_no: fdaNo,
        }
        await dispatch(updateUserCertificate(model))
        await dispatch(getUserCertificate())
    }

    const handleChange = (e) => {
        setfdaNo(e.target.value.length <= 11 ? e.target.value : fdaNo)
        props?.handleFda(fdaNo, vatNo)
        console.log(fdaNo,"dgffdgsf");
    }

    const handleChangeVAT = async(e) => {
        await setvatNo(e.target.value.length <= 11 ? e.target.value : vatNo);
    }

    const enableFDANumber = async() => {
        await setIsFdaEmpty(false);
    }
    return (
        <Fragment>
            <div className='ViewReqDetail'>
                <div className="about__product mt-2 mb-2">
                    <div className="mt-1 mb-1">
                        <h4 className="font-16">{lang?.VAT_No}</h4>
                        <div className="form__item"> <input type="text" value={vatNo} onChange={(e) => { handleChangeVAT(e) }} onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}/></div>
                    </div>
                </div>

                <div className="about__product mt-2 mb-2">
                    <div className="mt-1 mb-1">
                        <h4 className="font-16">{lang?.FDA_No}</h4>
                        <div className="form__item">
                        {isFdaEmpty ? 
                            <input type="text" value="No" onClick={enableFDANumber}/> 
                        :
                        <input id="myFDANumber" type="number" value={fdaNo} onChange={(e) => { handleChange(e) }} onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} />
                        }
                        </div>
                    </div>
                </div>

                {filterProductType?.length ? filterProductType?.map((item, index) => {
                    return (
                        <div className='about__product mt-2 mb-2' key={index}>
                            <div className='mt-4 mb-4'>
                                <h4 className='font-16'>{item.option}</h4>
                            </div>
                            {/* -----------Photo of Label----------- */}
                            <div className='photoLevel'>
                                <div className='flex flx-wrap items-center certificateLavel'>
                                    <div className='w-3/5 md:w-full sm:w-full md:w-full sm:w-full'>
                                        <div className='flex items-center'>
                                            <div className='icon mr-4'>
                                                <span>
                                                    <i className={item && item.photo_of_label ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                </span>
                                            </div>
                                            <div className='textBlock'>
                                                <h4 className='font-15'>{lang?.Photo_of_Label}</h4>
                                                <p>{lang?.Upload_an_image_of_your_product_label}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                        <div className="view__hub__btn">
                                            <label for="file1" className="btnBox text-right mt-1">
                                                {<Link to="#" className="btn bgLight BgTeal">{userCertificates === "photo_of_label" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.photo_of_label ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>}
                                                <input id="file1" type="file" name='photo_of_label' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                            </label>
                                        </div>
                                        <div className="view__hub__btn">
                                            <div className="btnBox text-right">
                                                {item?.photo_of_label && <a href={`${item?.base_url}${item?.photo_of_label}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* -----------fce sid_certification----------- */}
                            <div className='photoLevel'>
                                <div className='flex items-center certificateLavel'>
                                    <div className='w-3/5 md:w-full sm:w-full'>
                                        <div className='flex items-center'>
                                            <div className='icon mr-4'>
                                                <span>
                                                    <i className={item && item.fce_sid_certification ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                </span>
                                            </div>
                                            <div className='textBlock'>
                                                <h4 className='font-15'>{lang?.Fce_Sid_Certification}</h4>
                                                <p>{lang?.Upload_an_image_of_your_FCE_SID_Certification}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                        <div className="view__hub__btn">
                                            <label for="file1" className="btnBox text-right mt-1">
                                                <Link to="#" className="btn bgLight BgTeal">{userCertificates === "fce_sid_certification" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.fce_sid_certification ? "Uploaded" : 'Upload'}`}</Link>
                                                <input type="file" name='fce_sid_certification' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                            </label>
                                        </div>
                                        <div className="view__hub__btn">
                                            <div className="btnBox text-right mt-1">
                                                {item?.fce_sid_certification && <a href={`${item?.base_url}${item?.fce_sid_certification}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* -----------phytosanitary_certificate----------- */}
                            <div className='photoLevel'>
                                <div className='flex items-center certificateLavel'>
                                    <div className='w-3/5 md:w-full sm:w-full'>
                                        <div className='flex items-center'>
                                            <div className='icon mr-4'>
                                                <span>
                                                    <i className={item && item.phytosanitary_certificate ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                </span>
                                            </div>
                                            <div className='textBlock'>
                                                <h4 className='font-15'>{lang?.Phytosanitary_Certificate}</h4>
                                                <p>{lang?.Upload_an_image_of_your_Phytosanitary_Certificate}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                        <div className="view__hub__btn">
                                            <label for="file1" className="btnBox text-right mt-1">
                                                <Link to="#" className="btn bgLight BgTeal">{userCertificates === "phytosanitary_certificate" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.phytosanitary_certificate ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                <input type="file" name='phytosanitary_certificate' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                            </label>
                                        </div>
                                        <div className="view__hub__btn">
                                            <div className="btnBox text-right mt-1">
                                                {item?.phytosanitary_certificate && <a href={`${item?.base_url}${item?.phytosanitary_certificate}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* -----------packaging_for_usa----------- */}
                            <div className='photoLevel'>
                                <div className='flex items-center certificateLavel'>
                                    <div className='w-3/5 md:w-full sm:w-full'>
                                        <div className='flex items-center'>
                                            <div className='icon mr-4'>
                                                <span>
                                                    <i className={item && item.packaging_for_usa ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                </span>
                                            </div>
                                            <div className='textBlock'>
                                                <h4 className='font-15'>{lang?.Packaging_For_Usa}</h4>
                                                <p>{lang?.Upload_an_image_or_PDF_of_your_Packaging_For_Usa}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                        <div className="view__hub__btn">
                                            <label for="file1" className="btnBox text-right mt-1">
                                                <Link to="#" className="btn bgLight BgTeal">{userCertificates === "packaging_for_usa" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.packaging_for_usa ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                <input type="file" name='packaging_for_usa' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                            </label>
                                        </div>
                                        <div className="view__hub__btn">
                                            <div className="btnBox text-right mt-1">
                                                {item?.packaging_for_usa && <a href={`${item?.base_url}${item?.packaging_for_usa}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* -----------food_safety_plan----------- */}
                            <div className='photoLevel'>
                                <div className='flex items-center certificateLavel'>
                                    <div className='w-3/5 md:w-full sm:w-full'>
                                        <div className='flex items-center'>
                                            <div className='icon mr-4'>
                                                <span>
                                                    <i className={item && item.food_safety_plan ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                </span>
                                            </div>
                                            <div className='textBlock'>
                                                <h4 className='font-15'>{lang?.Food_Safety_Plan}</h4>
                                                <p>{lang?.Upload_image_or_PDF_of_your_Food_Safety_Plan}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                        <div className="view__hub__btn">
                                            <label for="file1" className="btnBox text-right mt-1">
                                                <Link to="#" className="btn bgLight BgTeal">{userCertificates === "food_safety_plan" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.food_safety_plan ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                <input type="file" name='food_safety_plan' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                            </label>
                                        </div>
                                        <div className="view__hub__btn">
                                            <div className="btnBox text-right mt-1">
                                                {item?.food_safety_plan && <a href={`${item?.base_url}${item?.food_safety_plan}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* -----------animal_helath_asl_certificate----------- */}
                            <div className='photoLevel'>
                                <div className='flex items-center certificateLavel'>
                                    <div className='w-3/5 md:w-full sm:w-full'>
                                        <div className='flex items-center'>
                                            <div className='icon mr-4'>
                                                <span>
                                                    <i className={item && item.animal_helath_asl_certificate ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                    {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                </span>
                                            </div>
                                            <div className='textBlock'>
                                                <h4 className='font-15'>{lang?.Animal_Helath_Asl_Certificate}</h4>
                                                <p>{lang?.Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                        <div className="view__hub__btn">
                                            <label for="file1" className="btnBox text-right mt-1">
                                                <Link to="#" className="btn bgLight BgTeal">{userCertificates === "animal_helath_asl_certificate" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.animal_helath_asl_certificate ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                <input type="file" name='animal_helath_asl_certificate' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                            </label>
                                        </div>
                                        <div className="view__hub__btn">
                                            <div className="btnBox text-right mt-1">
                                                {item?.animal_helath_asl_certificate && <a href={`${item?.base_url}${item?.animal_helath_asl_certificate}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                })
                    :
                    userCertificateData?.data_certificates?.map((item, index) => {
                        return (
                            <div className='about__product mt-2 mb-2' key={index}>
                                <div className='mt-4 mb-4'>
                                    <h4 className='font-16'>{item.option}</h4>
                                </div>
                                {/* -----------Photo of Label----------- */}
                                <div className='photoLevel'>
                                    <div className='flex flx-wrap items-center certificateLavel'>
                                        <div className='w-3/5 md:w-full sm:w-full md:w-full sm:w-full'>
                                            <div className='flex items-center'>
                                                <div className='icon mr-4'>
                                                    <span>
                                                        <i className={item && item.photo_of_label ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                        {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                </div>
                                                <div className='textBlock'>
                                                    <h4 className='font-15'>{lang?.Photo_of_Label}</h4>
                                                    <p>{lang?.Upload_an_image_of_your_product_label}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                            <div className="view__hub__btn">
                                                <label for="file1" className="btnBox text-right mt-1">
                                                    {<Link to="#" className="btn bgLight BgTeal">{userCertificates === "photo_of_label" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.photo_of_label ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>}
                                                    <input id="file1" type="file" name='photo_of_label' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                                </label>
                                            </div>
                                            <div className="view__hub__btn">
                                                <div className="btnBox text-right">
                                                    {item?.photo_of_label && <a href={`${item?.base_url}${item?.photo_of_label}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* -----------fce sid_certification----------- */}
                                <div className='photoLevel'>
                                    <div className='flex items-center certificateLavel'>
                                        <div className='w-3/5 md:w-full sm:w-full'>
                                            <div className='flex items-center'>
                                                <div className='icon mr-4'>
                                                    <span>
                                                        <i className={item && item.fce_sid_certification ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                        {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                </div>
                                                <div className='textBlock'>
                                                    <h4 className='font-15'>{lang?.Fce_Sid_Certification}</h4>
                                                    <p>{lang?.Upload_an_image_of_your_FCE_SID_Certification}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                            <div className="view__hub__btn">
                                                <label for="file1" className="btnBox text-right mt-1">
                                                    <Link to="#" className="btn bgLight BgTeal">{userCertificates === "fce_sid_certification" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.fce_sid_certification ? "Uploaded" : 'Upload'}`}</Link>
                                                    <input type="file" name='fce_sid_certification' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                                </label>
                                            </div>
                                            <div className="view__hub__btn">
                                                <div className="btnBox text-right mt-1">
                                                    {item?.fce_sid_certification && <a href={`${item?.base_url}${item?.fce_sid_certification}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* -----------phytosanitary_certificate----------- */}
                                <div className='photoLevel'>
                                    <div className='flex items-center certificateLavel'>
                                        <div className='w-3/5 md:w-full sm:w-full'>
                                            <div className='flex items-center'>
                                                <div className='icon mr-4'>
                                                    <span>
                                                        <i className={item && item.phytosanitary_certificate ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                        {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                </div>
                                                <div className='textBlock'>
                                                    <h4 className='font-15'>{lang?.Phytosanitary_Certificate}</h4>
                                                    <p>{lang?.Upload_an_image_of_your_Phytosanitary_Certificate}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                            <div className="view__hub__btn">
                                                <label for="file1" className="btnBox text-right mt-1">
                                                    <Link to="#" className="btn bgLight BgTeal">{userCertificates === "phytosanitary_certificate" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.phytosanitary_certificate ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                    <input type="file" name='phytosanitary_certificate' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                                </label>
                                            </div>
                                            <div className="view__hub__btn">
                                                <div className="btnBox text-right mt-1">
                                                    {item?.phytosanitary_certificate && <a href={`${item?.base_url}${item?.phytosanitary_certificate}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* -----------packaging_for_usa----------- */}
                                <div className='photoLevel'>
                                    <div className='flex items-center certificateLavel'>
                                        <div className='w-3/5 md:w-full sm:w-full'>
                                            <div className='flex items-center'>
                                                <div className='icon mr-4'>
                                                    <span>
                                                        <i className={item && item.packaging_for_usa ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                        {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                </div>
                                                <div className='textBlock'>
                                                    <h4 className='font-15'>{lang?.Packaging_For_Usa}</h4>
                                                    <p>{lang?.Upload_an_image_or_PDF_of_your_Packaging_For_Usa}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                            <div className="view__hub__btn">
                                                <label for="file1" className="btnBox text-right mt-1">
                                                    <Link to="#" className="btn bgLight BgTeal">{userCertificates === "packaging_for_usa" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.packaging_for_usa ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                    <input type="file" name='packaging_for_usa' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                                </label>
                                            </div>
                                            <div className="view__hub__btn">
                                                <div className="btnBox text-right mt-1">
                                                    {item?.packaging_for_usa && <a href={`${item?.base_url}${item?.packaging_for_usa}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* -----------food_safety_plan----------- */}
                                <div className='photoLevel'>
                                    <div className='flex items-center certificateLavel'>
                                        <div className='w-3/5 md:w-full sm:w-full'>
                                            <div className='flex items-center'>
                                                <div className='icon mr-4'>
                                                    <span>
                                                        <i className={item && item.food_safety_plan ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                        {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                </div>
                                                <div className='textBlock'>
                                                    <h4 className='font-15'>{lang?.Food_Safety_Plan}</h4>
                                                    <p>{lang?.Upload_image_or_PDF_of_your_Food_Safety_Plan}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                            <div className="view__hub__btn">
                                                <label for="file1" className="btnBox text-right mt-1">
                                                    <Link to="#" className="btn bgLight BgTeal">{userCertificates === "food_safety_plan" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.food_safety_plan ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                    <input type="file" name='food_safety_plan' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                                </label>
                                            </div>
                                            <div className="view__hub__btn">
                                                <div className="btnBox text-right mt-1">
                                                    {item?.food_safety_plan && <a href={`${item?.base_url}${item?.food_safety_plan}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* -----------animal_helath_asl_certificate----------- */}
                                <div className='photoLevel'>
                                    <div className='flex items-center certificateLavel'>
                                        <div className='w-3/5 md:w-full sm:w-full'>
                                            <div className='flex items-center'>
                                                <div className='icon mr-4'>
                                                    <span>
                                                        <i className={item && item.animal_helath_asl_certificate ? "fa fa-check-circle-o selected" : "fa fa-circle-o notSelected"} aria-hidden="true"></i>
                                                        {/* <i className="far fa-circle" aria-hidden="true"></i> */}
                                                    </span>
                                                </div>
                                                <div className='textBlock'>
                                                    <h4 className='font-15'>{lang?.Animal_Helath_Asl_Certificate}</h4>
                                                    <p>{lang?.Upload_image_or_PDF_of_your_your_Animal_Helath_or_ASL_Certificate}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-2/5 md:w-full sm:w-full gridBlockTwo'>
                                            <div className="view__hub__btn">
                                                <label for="file1" className="btnBox text-right mt-1">
                                                    <Link to="#" className="btn bgLight BgTeal">{userCertificates === "animal_helath_asl_certificate" && updateuserCertificateSpinner ? <FormSpinner /> : `${item?.animal_helath_asl_certificate ? `${lang?.Uploaded}` : `${lang?.Upload}`}`}</Link>
                                                    <input type="file" name='animal_helath_asl_certificate' onChange={(e) => uploadCertification(e, item.user_field_option_id)} />
                                                </label>
                                            </div>
                                            <div className="view__hub__btn">
                                                <div className="btnBox text-right mt-1">
                                                    {item?.animal_helath_asl_certificate && <a href={`${item?.base_url}${item?.animal_helath_asl_certificate}`} target={"_blank"} className="btn bgLight Primary">{lang?.View}</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
                {props?.redirect_from === "company" &&
                    < div className="view__hub__btn md:w-1/3 m-auto">
                        {updateuserCertificateSpinner ? <FormSpinner /> : <a className="btn BgTeal" onClick={() => handleSave()}>{lang?.Save}</a>}

                    </div>
                }
            </div>


        </Fragment >
    )
}
