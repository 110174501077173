import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import MyOrderView from './MyOrderView'
import ImporterSideBar from './ImporterSideBar'
import { useSelector } from 'react-redux';
import language from '../../helpers/language';
import { Link } from 'react-router-dom'

export default function SingleOrderViewPage() {
    const { id } = useParams();
    const [lang] = useState(language.getLang());
    let { myDashBoardStatus } = useSelector((state) => state.marketPlaceReducerData);
    const [activeTab, setActiveTab] = useState(myDashBoardStatus !== "" ? myDashBoardStatus : "myOrder");

    const handleTabChanges = (tab) => {
        setActiveTab(tab)
    }
    return (
        <>
            <div className="marketplace dashboard header">
                {/* Banner Section */}
                <div>
                    <div className="section__wrapper">
                        <div className="flex justify-between items-center">
                            <h6 className="back-btn-small">
                                <Link to="/marketplace">
                                    <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;{" "}
                                    {lang?.Go_back}
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="servics_blocks dashboardView order_mgmt_detail">
                <div className="site-align top-align">
                    <div className="left__section-block sm:mb-2 md:mb-2" >
                        <ImporterSideBar handleTabChanges={handleTabChanges} activeTab={activeTab} lang={lang} id={id} />
                    </div>
                    <MyOrderView id={id} linkUrl={'/my-dashboard'} />
                </div>
            </div>
        </>
    )
}
