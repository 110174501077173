
// get single product price with tax or without tax 
// if onlyTax is true it return only total of tax , else return total+tax
const getSingleProductTax = (price, count, product, onlyTax) => {
    let totalPrice = count * price;
    const totalTax = (filterTaxByName(product?.get_tax_classes, "percentage") * totalPrice / 100) + filterTaxByName(product?.get_tax_classes, "fixed");
    return onlyTax ? totalTax : (totalTax + totalPrice) ?? 0
}


// get tax by type
const filterTaxByName = (data, type) => {
    if (!data) return 0;
    return data.filter((tax) => tax?.get_tax_detail?.tax_type === type)?.reduce((cur, val) => cur + val?.get_tax_detail?.tax_rate, 0)
}

// get total product price
const getTotalPriceOfAllProduct = (cartProducts, isTaxOrPrice) => {
    const data = cartProducts?.filter((item) => item?.available_status !== 2);
    if (data?.length > 0) {
        return data?.reduce((cur, item) => (cur + getSingleProductTax(item.product_price, item.count, item?.get_product_tax, isTaxOrPrice)), 0)
    }
}

// increate or decrease quantity of products
const increaseQtyOfProducts = (cartProducts, id, isIncrease) => {
    const data = cartProducts?.filter((item) => item?.available_status !== 2);
    const productItems = data?.map((item) => {
        return item.marketplace_product_id === id ? { ...item, count: isIncrease ? item.count + 1 : item.count > 1 ? item.count - 1 : 1 } : item
    });
    return productItems;
}

// get details or count  of product on their key type
const getProductDetailsOnType = cartProducts => type => cartProducts?.map((item) => Number(item[type]));

export {
    getSingleProductTax,
    filterTaxByName,
    getTotalPriceOfAllProduct,
    increaseQtyOfProducts,
    getProductDetailsOnType
}