/** @format */

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getSingleDispute,
  uploadChatImageUpdatedLastActivity,
  setMyDashboardStatus,
  changeDisputeStatus,
} from "../../routes/marketplace/modules/marketplace";
import moment from "moment";
import img1 from "../../assets/images/pizza-recipe.jpg";
import ImporterSideBar from "./ImporterSideBar";
import DistibutorSideBar from "../../routes/marketplace/components/DashboardSideBar";
import firebase from "../../config//firebase";
import language from "../../helpers/language";
import FormSpinner from "../Spinners/FormSpinner";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function SingleDisputeView() {
  const [lang] = useState(language.getLang());
  const [dispute, setDispute] = useState("");
  const [members, setMembers] = useState([]);
  const [fetchChatList, setFetchChatList] = useState([]);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  const [imageError, setImageError] = useState("");
  const [disabledTrue, setDisabledTrue] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [unReadCount, setUnReadCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [isDataLoad, setIsDataLoader] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [changeStatusMessage, setChangeStatusMessage] = useState("");
  const { disputeId } = useParams();
  const dispatch = useDispatch();
  const history=useHistory()
  let { myDashBoardStatus } = useSelector(
    (state) => state.marketPlaceReducerData
  );
  const [activeTab, setActiveTab] = useState(
    myDashBoardStatus !== "" ? myDashBoardStatus : "Disputes"
  );
  const currentUser = JSON.parse(localStorage.getItem("USER_TYPE")) ?? null;
  useEffect(async () => {
    getDispute(disputeId);
    //deleteChatCountNode(disputeId, currentUser?.user_id);
    const fetchUserNode = firebase.database().ref("dispute").child(disputeId);
    fetchUserNode.on("value", async (snapshot) => {
      const response = snapshot.val();
      const result = [];
      for (let id in response) {
        result.push({ id, ...response[id] });
      }

      await setFetchChatList(result);
    });
  }, [disputeId]);

  // get all my dispute listing
  const getDispute = async (id) => {
    const myDisputeRes = await getSingleDispute(id);
    //console.log(myDisputeRes, "myDisputeRes");
    if (myDisputeRes?.success === 200) {
      console.log(myDisputeRes?.dispute?.producer_view,"myDisputeRes?.producer_view")
      if(myDisputeRes?.dispute?.producer_view==0){
        localStorage.setItem('tab', 'dispute');
        history.push('/marketplace/dashboard');
      }
      
      await setDispute(myDisputeRes?.dispute);
      await setMembers(myDisputeRes?.dispute?.members);
      await setIsDataLoader(true);
    } else {
      await setDispute([]);
    }
  };

  // Options set in states
  const handleChange = async (e) => {
    const { name, value } = e.target;
    await setMessage(value);
  };

  const handleFile = async (event) => {
    let imageArr = [];
    if (event.target.files.length > 5) {
      await setImageError("Images should be less than 6");
      document.getElementById("attachment_img").value = "";
    } else {
      Object.keys(event.target.files).map(async (item, i) => {
        if (event.target.files[item].size > 1000000) {
          await setImageError(lang?.disputeImageSize);
          document.getElementById("attachment_img").value = "";
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[item]);
          reader.onload = (e) => {
            imageArr.push(event.target.files[item]);
          };
          await setImageCount(i+1);
        }
      });
      await setImage(imageArr);
      
    }
    setTimeout(() => {
      setImageError("");
    }, 3000);
  };

  const resetChatBox = async () => {
    await setMessage("");
    await setImage("");
    await setImageCount(0);
    document.getElementById("attachment_img").value = "";
  };

  const saveChatFirebase = async () => {
    if (image != "" || message != "") {
      setDisabledTrue(true);
      setIsLoader(true);
      const sendData = {
        dispute_id: disputeId,
        image: image,
      };
      const updateDispute = await uploadChatImageUpdatedLastActivity(sendData);
      if (updateDispute?.success === 200) {
        var currentDateTime = new Date();
        var randNum = Math.floor(currentDateTime.getTime() / 1000);
        var date_time = new Date().toISOString();
        firebase.database().ref("dispute").child(disputeId).child(randNum).set({
          attachments: updateDispute?.attachments,
          create_at: date_time,
          message: message,
          sender_id: updateDispute?.user_id,
          timestamp: currentDateTime,
        });
        
        await setMessage("");
        await setImage("");
        await setImageCount(0);
        document.getElementById("attachment_img").value = "";
      }
      setTimeout(() => {
        setDisabledTrue(false);
        setIsLoader(false);
      }, 1000);
    }
  };

  const updateDisputeStatus = async () => {
    if (changeStatus != '') {
      setDisabledTrue(true);
      const updateData = {
        request_id: disputeId,
        status: changeStatus,
      };
      const updateDisputeStatus = await changeDisputeStatus(updateData);
      if (updateDisputeStatus?.success === 200) {
        setChangeStatusMessage(updateDisputeStatus?.message);
        setChangeStatus("");
        getDispute(disputeId);
        setDisabledTrue(false);
      }
      setTimeout(() => {
        setChangeStatusMessage("");
        setChangeStatus("");
      }, 1500);
    }
  };
  const handleTabChanges = (tab) => {
    setActiveTab(tab);
    dispatch(setMyDashboardStatus(tab));
  };
  const selectTab = async (tab) => {
    setActiveTab(tab);
    dispatch(setMyDashboardStatus(tab));
  };

  const isImgUrl = (url) => {
    return /\.(jpg|jpeg|png)$/.test(url);
  }

  console.log(dispute,"disputetet")
  return (
    <>
      <div className="marketplace dashboard header">
        {/* Banner Section */}
        <div>
          <div className="section__wrapper">
            <div className="flex justify-between items-center">
              <h6 className="back-btn-small">
                <Link to="/marketplace">
                  <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp;
                  Back to Marketplace
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="servics_blocks dashboardView dashboardInquiry viewDisputes">
        <div className="site-align top-align">
          <div className="left__section-block sm:mb-2 md:mb-2">
            {/* Data coming soon */}
            {/* Show importer sidebar here and remove Data coming soon text */}
            {currentUser?.role_id == 3 ? (
              <DistibutorSideBar
                selectTab={selectTab}
                activeTab={activeTab}
                lang={lang}
                id={disputeId}
              />
            ) : (
              <ImporterSideBar
                handleTabChanges={handleTabChanges}
                activeTab={activeTab}
                lang={lang}
                id={disputeId}
              />
            )}
          </div>
          {isDataLoad ? (
            <div className="servics_blocks middle-long__section-block middleBlockBg disputes">
              <div className="product-title">
                <div className="updateStatus text-center"></div>
                <div className="flex flex-wrap justify-between items-center">
                  <h4>Need Support</h4>
                  <div className="productFilter disputesFilter">
                    <div className="inner-btn-box">
                      {dispute?.ticket_closed && (
                        <div className="Status form__item flexBox items-center gap-2">
                          <label className="mb-0">Status</label>
                          <div className="select-item skyBlue">
                            <select
                              onChange={(event) => {
                                setChangeStatus(event.target.value);
                              }}
                              disabled={
                                dispute?.status == "resolved" ? true : false
                              }
                            >
                              <option value="pending" selected={dispute?.status == 'pending' ? 'selected' : ''}>Pending</option>
                              <option value="resolved" selected={dispute?.status == 'resolved' ? 'selected' : ''}>Resolved</option>
                            </select>
                          </div>
                          <div className="rightButtons">
                            <button
                              className="btn btn-blue"
                              onClick={() => {
                                updateDisputeStatus();
                              }}
                              disabled={disabledTrue}
                            >
                              {disabledTrue ? <FormSpinner/> : 'Save' }
                            </button>
                          </div>
                        </div>
                      )}
                      <Link
                        to={
                          currentUser?.role_id == 3
                            ? "/marketplace/dashboard"
                            : "/my-dashboard"
                        }
                        className="btn btn--gray"
                      >
                        <svg
                          width="18"
                          height="9"
                          viewBox="0 0 18 9"
                          fill="none"
                        >
                          <path
                            d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z"
                            fill="black"
                          ></path>
                        </svg>
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-list">
                <div className="recentOrder secBlockGap">
                  <div className="itemTable itemTableScroll customer__table">
                    <div className="message mt-4 mb-4 text-center text-green">
                      <h4 className="font-bold">{changeStatusMessage}</h4>
                    </div>
                    <div className="showData">
                      <div className="showDataRow">
                        <div className="dataTitle">
                          <strong>ID</strong> <span>#{dispute?.id}</span>
                        </div>
                        <div className="dataTitle">
                          <strong>Order ID</strong>{" "}
                          <span>{dispute?.order_id}</span>
                        </div>
                        <div className="dataTitle">
                          <strong>Order Purchase Date</strong>{" "}
                          <span>
                            {moment(
                              new Date(
                                dispute?.order?.created_at
                              ).toLocaleString()
                            ).format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="dataTitle">
                          <strong>Created</strong>{" "}
                          <span>
                            {moment(
                              new Date(dispute?.created_at).toLocaleString()
                            ).format("DD MMMM YYYY hh:mm A")}
                          </span>
                        </div>
                        <div className="dataTitle">
                          <strong>Participants</strong>{" "}
                          <span>
                            {members[dispute?.chatMember?.prod_id]?.name},{" "}
                            {members[dispute?.chatMember?.imp_dis_id]?.name},{" "}
                            {members[dispute?.chatMember?.admin_id]?.name}
                          </span>
                        </div>
                        <div className="dataTitle">
                          <strong>Status</strong>{" "}
                          <span className={`${dispute?.status == "pending" ? "pendingStatus" : "pendingStatus resolved"}`} >
                            {dispute?.status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="messageBlock mt-6">
                      <div className="allChat">
                        {fetchChatList?.length > 0 &&
                          fetchChatList?.map((item, index) => {
                            let myDate = new Date(
                              item?.create_at
                            ).toLocaleString();
                            return (
                              <div className="messageQuery py-6">
                                <div className="prdctHead">
                                  <div className="prdtImg">
                                    <img
                                      src={
                                        item?.sender_id == 1
                                          ? members?.[item?.sender_id]?.image
                                          : members?.[item?.sender_id]
                                              ?.base_url +
                                            members?.[item?.sender_id]
                                              ?.attachment_medium_url
                                      }
                                      alt="Profile"
                                    />
                                  </div>
                                  <div className="prdtDes pl-2">
                                    <p>{members?.[item?.sender_id]?.name}</p>
                                    <span>
                                      {moment(myDate).format(
                                        "DD MMMM YYYY hh:mm A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="messageDesc mt-4">
                                  <p>{item?.message}</p>
                                  <div className="attachedFile">
                                    <div className="attachedFileGroup">
                                      {item?.attachments?.length > 0 &&
                                        item?.attachments?.map((attach, i) => {
                                          const img =  isImgUrl(attach);
                                          return (
                                            <div className="uploadImg">
                                              {img ? (
                                                <img
                                                  src={attach}
                                                  alt="Added Image"
                                                />
                                              ) : (
                                                <a
                                                  href={attach}
                                                  alt="PDF"
                                                  target="_blank"
                                                >
                                                  <svg width="155" height="131" viewBox="0 0 155 131" fill="none">
                                                    <path d="M0 0H111L133.5 25.5L155 50V131H0V0Z" fill="#EF4036"/>
                                                    <path d="M54.5985 75V56.6078H61.8548C63.2498 56.6078 64.4382 56.8742 65.4201 57.4071C66.402 57.9339 67.1504 58.6674 67.6653 59.6073C68.1861 60.5413 68.4466 61.619 68.4466 62.8403C68.4466 64.0617 68.1831 65.1393 67.6563 66.0733C67.1294 67.0073 66.3661 67.7347 65.3662 68.2556C64.3724 68.7765 63.169 69.0369 61.756 69.0369H57.1311V65.9207H61.1274C61.8758 65.9207 62.4925 65.7919 62.9774 65.5345C63.4683 65.2711 63.8335 64.9088 64.073 64.4478C64.3185 63.9809 64.4412 63.445 64.4412 62.8403C64.4412 62.2296 64.3185 61.6968 64.073 61.2418C63.8335 60.7808 63.4683 60.4246 62.9774 60.1731C62.4865 59.9157 61.8638 59.7869 61.1094 59.7869H58.4871V75H54.5985ZM77.4922 75H70.9723V56.6078H77.5461C79.3961 56.6078 80.9887 56.976 82.3238 57.7124C83.6589 58.4428 84.6857 59.4936 85.4041 60.8646C86.1285 62.2356 86.4908 63.8761 86.4908 65.7859C86.4908 67.7018 86.1285 69.3482 85.4041 70.7253C84.6857 72.1023 83.6529 73.159 82.3058 73.8954C80.9647 74.6318 79.3602 75 77.4922 75ZM74.8609 71.6682H77.3306C78.4801 71.6682 79.447 71.4647 80.2313 71.0575C81.0216 70.6444 81.6143 70.0068 82.0095 69.1447C82.4106 68.2766 82.6111 67.157 82.6111 65.7859C82.6111 64.4269 82.4106 63.3163 82.0095 62.4542C81.6143 61.592 81.0246 60.9574 80.2403 60.5503C79.456 60.1432 78.4891 59.9396 77.3396 59.9396H74.8609V71.6682ZM89.3713 75V56.6078H101.549V59.8139H93.2598V64.1964H100.741V67.4024H93.2598V75H89.3713Z" fill="white"/>
                                                    <path d="M111 45V0.000198364L133 25L155 50H116C113.239 50 111 47.7614 111 45Z" fill="#D6D6D7"/>
                                                  </svg>

                                                </a>
                                              )}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        
                      </div>
                      {dispute?.status == "pending" && (
                        <div className="replyMessage">
                          <div className="messageText">
                            <textarea
                              rows="2"
                              cols="4"
                              placeholder="Type reply here..."
                              value={message}
                              onChange={(event) => handleChange(event)}
                            ></textarea>
                          </div>
                          <div className="chatButtons">
                            <div className="attachFile">
                              <label>
                                <div className="icon">
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  Attach File or Images
                                </div>

                                <input
                                  type="file"
                                  id="attachment_img"
                                  className="form--item"
                                  placeholder="Select Image or File to attach"
                                  accept="image/*, application/pdf"
                                  onChange={(event) => handleFile(event)}
                                  multiple
                                ></input>
                                <span className="slectedFiles">{ imageCount > 0 ? imageCount == 1 ? ` ${imageCount} File Selected` : ` ${imageCount} Files Selected` : ''}</span>
                              </label>
                            </div>
                            <div className="rightButtons">
                              <button
                                type="button"
                                className="btn btn-white"
                                disabled={disabledTrue}
                                onClick={() => {
                                  resetChatBox();
                                }}
                              >
                                Discard
                              </button>
                              <button
                                type="button"
                                className="btn btn-blue ml-4"
                                disabled={disabledTrue}
                                onClick={() => {
                                  saveChatFirebase();
                                }}
                              >
                                {isLoader ? <FormSpinner /> : "Send"}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <FormSpinner />
          )}
        </div>
      </div>
    </>
  );
}
