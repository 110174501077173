/** @format */

import React, { useEffect, useState } from "react";
import {
  getSingleMyOffers,
  createOrderDispute,
  setMyDashboardStatus,
} from "../../routes/marketplace/modules/marketplace";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import constant from "../../helpers/constant";
import language from "../../helpers/language";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
import FormErrors from "../Errors/FormError";
import FormSpinner from "../Spinners/FormSpinner";
import { useDispatch } from "react-redux";

export default function MyOrderView({ id, linkUrl }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState({});
  const [lang] = useState(language.getLang());
  const [loading, setLoading] = useState(false);
  const [raiseReq, setRaiseReq] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [activeTab, setActiveTab] = useState("Disputes");
  const [imageCount, setImageCount] = useState(0);
  const currentUser = JSON.parse(localStorage.getItem("USER_TYPE")) ?? null;
  const intialValues = {
    order_id: id,
    title: "",
    subject: "",
    image: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabledTrue, setDisabledTrue] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isImageName, setIsImageName] = useState("");
  useEffect(() => {
    getMySingleOrder();
  }, [id]);

  const getMySingleOrder = async () => {
    setLoading(true);
    const orderResponse = await getSingleMyOffers(id);
    if (orderResponse?.success === 200) {
      if (Object.keys(orderResponse?.data)?.length > 0) {
        setLoading(false);
        setOrderDetails(orderResponse?.data ?? {});
      }
    } else {
      setOrderDetails({});
      setLoading(false);
      //console.log(orderResponse);
    }
  };

  const orderSummary = (data) => {
    const {
      net_total,
      num_items_sold,
      shipping_total,
      total_seles,
      tax_total,
      currency,
    } = data ?? {};
    return (
      <div className="order_Span">
        <p>
          {lang?.Item}({`${num_items_sold}`}) {lang?.Subtotal} :
          <span>{`${currency}${net_total}`}</span>
        </p>
        <p>
          {lang?.Total} : <span>{`${currency}${net_total}`}</span>
        </p>
        <p>
          {lang?.Vat} : <span>{`${currency}${tax_total}`}</span>
        </p>
        {shipping_total > 0 && (
          <p>
            {lang?.Shipping_charge} :{" "}
            <span>{`${currency}${shipping_total}`}</span>
          </p>
        )}
        <p>
          {lang?.Grand_total} :{" "}
          <span>{`${currency}${
            Number(total_seles) + Number(shipping_total)
          }`}</span>
        </p>
      </div>
    );
  };

  const handlePrint = () => {
    window.print();
  };
  const closeModal = async () => {
    await setRaiseReq("");
  };

  const {
    billing_address,
    product_item_info,
    shipping_address,
    seller_info,
    buyer_info,
    baseUrl,
    invoice_name,
    ...data
  } = orderDetails ?? {};

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      saveDisputeTicket();
    }
  }, [formErrors]);
  // Options set in states
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };
  // Check dispute validation
  const saveTicket = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
  };

  const saveDisputeTicket = async () => {
    setDisabledTrue(true);
    setIsLoader(true);
    const createDispute = await createOrderDispute(formValues);
    if (createDispute?.success === 200) {
      await setResponseMessage(createDispute?.message);
      const resetValues = {
        order_id: id,
        title: "",
        subject: "",
        image: "",
        message: "",
      };
      await setFormValues(resetValues);
      await setRaiseReq("");
      setTimeout(() => {
      
      if(currentUser?.role_id == 3){
        //dispatch(setMyDashboardStatus("dispute"));
        localStorage.setItem("tab", 'dispute');
        history.push("/marketplace/dashboard");
      }
      else{
        dispatch(setMyDashboardStatus("Disputes"));
        history.push("/my-dashboard");
      }
      
      }, 3000);
    } else {
      await setResponseMessage(lang?.SomeThingsWrong);
    }
    setTimeout(() => {
      setDisabledTrue(false);
      setIsLoader(false);
      setResponseMessage("");
      setImageCount(0);
    }, 2500);
    
  };

  // Form validation
  const validate = (values) => {
    let errors = {};
    if (!values.title) {
      errors.title = `${lang?.title_required}`;
    }
    if (!values.subject) {
      errors.subject = `${lang?.subject_required}`;
    }
    if (!values.message) {
      errors.message = `${lang?.message_required}`;
    }
    return errors;
  };

  const handleFile = async (event) => {
    let imageArr = [];
    let imageName = [];
    if (event.target.files.length > 5) {
      setFormErrors({ image: "Images should be less than 6" });
      document.getElementById("attachment_img").value = "";
    } else {
      Object.keys(event.target.files).map(async(item, i) => {
        imageName.push(event.target.files[item].name);
        if (event.target.files[item].size > 1000000) {
          setFormErrors({ image: lang?.disputeImageSize });
          document.getElementById("attachment_img").value = "";
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[item]);
          reader.onload = (e) => {
            imageArr.push(event.target.files[item]);
            
          };
          await setImageCount(i+1);
        }
      });
      setIsImageName(imageName.toString());
      setFormValues({ ...formValues, ["image"]: imageArr });
    }
    setTimeout(() => {
      setFormErrors({ image: "" });
    }, 3000);
  };
  return (
    <>
      <div className="servics_blocks middle-long__section-block middleBlockBg myOrder">
        <div className="message"><h4 className="font-bold text-center text-green">{responseMessage}</h4></div>
        <div className="product-title">
          <div className="flex flex-wrap justify-between items-center">
            <h4 className="mb-0">{lang?.Order_details}</h4>
            <div className="inner-btn-box">
              {/* <button type="button" className="btn btnNoBg">
                Need Help?
              </button> */}
              <button
                type="button"
                className="btn btn-blue light gap-2"
                onClick={() => setRaiseReq(!raiseReq)}
                disabled={orderDetails?.disputeRaised}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M10 1.6665C6.32797 1.6665 3.33333 4.66114 3.33333 8.33317V9.1665V9.91846C2.85524 10.3743 2.5 10.9608 2.5 11.6665C2.5 12.7015 3.21463 13.4689 4.11947 13.8442C5.20366 16.4357 7.3752 18.3332 10 18.3332H12.5V16.6665H10C8.11796 16.6665 6.40913 15.2494 5.55013 13.0109L5.37272 12.5519L4.88444 12.4836C4.47403 12.4271 4.16667 12.0958 4.16667 11.6665C4.16667 11.3457 4.32867 11.094 4.58171 10.9487L5 10.7095V9.99984V9.1665C5 8.69565 5.36247 8.33317 5.83333 8.33317H14.1667C14.6375 8.33317 15 8.69565 15 9.1665V9.99984V13.3332H11.5934C11.5074 13.0897 11.348 12.8789 11.1373 12.7297C10.9266 12.5805 10.6749 12.5002 10.4167 12.4998C10.0851 12.4998 9.7672 12.6315 9.53278 12.866C9.29836 13.1004 9.16667 13.4183 9.16667 13.7498C9.16709 14.0656 9.28698 14.3695 9.50225 14.6004C9.71752 14.8314 10.0122 14.9723 10.3271 14.995C10.3569 14.9982 10.3868 14.9998 10.4167 14.9998H15.8333H16.6667C17.5833 14.9998 18.3333 14.2498 18.3333 13.3332V11.6665C18.3333 10.7498 17.5833 9.99984 16.6667 9.99984V9.1665V8.33317C16.6667 4.66114 13.672 1.6665 10 1.6665ZM10 3.33317C12.213 3.33317 14.0755 4.75599 14.738 6.73975C14.5535 6.6957 14.3638 6.6665 14.1667 6.6665H5.83333C5.63625 6.6665 5.4465 6.6957 5.26204 6.73975C5.92448 4.75599 7.78701 3.33317 10 3.33317Z"
                    fill="#004577"
                  />
                </svg>
                {orderDetails?.disputeRaised
                  ? "Raised a Ticket"
                  : "Raise a Ticket"}
              </button>
              {baseUrl && invoice_name ? (
                <a
                  href={`${baseUrl}${invoice_name}`}
                  target="_blank"
                  className="btn btn-blue royal"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                      d="M7.98792 9.44164e-05C7.78305 0.003295 7.58784 0.08768 7.44514 0.234714C7.30245 0.381748 7.22395 0.579408 7.22689 0.784278V15.9264L1.33092 10.0304C1.25968 9.9562 1.17435 9.89697 1.07993 9.85616C0.985514 9.81535 0.883902 9.79379 0.781048 9.79275C0.678194 9.7917 0.576165 9.81119 0.480936 9.85007C0.385708 9.88895 0.299194 9.94644 0.226461 10.0192C0.153729 10.0919 0.0962393 10.1784 0.0573603 10.2736C0.0184812 10.3689 -0.00100573 10.4709 3.99601e-05 10.5738C0.00108565 10.6766 0.0226432 10.7782 0.0634503 10.8726C0.104257 10.9671 0.163494 11.0524 0.23769 11.1236L7.45339 18.3393C7.59838 18.4843 7.79499 18.5657 8 18.5657C8.20501 18.5657 8.40162 18.4843 8.54661 18.3393L15.7623 11.1236C15.8365 11.0524 15.8957 10.9671 15.9366 10.8726C15.9774 10.7782 15.9989 10.6766 16 10.5738C16.001 10.4709 15.9815 10.3689 15.9426 10.2736C15.9038 10.1784 15.8463 10.0919 15.7735 10.0192C15.7008 9.94644 15.6143 9.88895 15.5191 9.85007C15.4238 9.81119 15.3218 9.7917 15.219 9.79275C15.1161 9.79379 15.0145 9.81535 14.9201 9.85616C14.8257 9.89696 14.7403 9.9562 14.6691 10.0304L8.77311 15.9264V0.784278C8.7746 0.680802 8.75529 0.578081 8.71635 0.482203C8.6774 0.386324 8.6196 0.29924 8.54638 0.226111C8.47316 0.152982 8.386 0.0952958 8.29007 0.0564722C8.19414 0.0176486 8.09139 -0.00152273 7.98792 9.44164e-05Z"
                      fill="white"
                    />
                  </svg>
                  {lang?.Download_invoice}
                </a>
              ) : (
                <div title="Invoice not ready to download">
                  <Link
                    to="#"
                    className="btn btn-blue royal h-full"
                    style={!invoice_name ? { pointerEvents: "none" } : null}
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M7.98792 9.44164e-05C7.78305 0.003295 7.58784 0.08768 7.44514 0.234714C7.30245 0.381748 7.22395 0.579408 7.22689 0.784278V15.9264L1.33092 10.0304C1.25968 9.9562 1.17435 9.89697 1.07993 9.85616C0.985514 9.81535 0.883902 9.79379 0.781048 9.79275C0.678194 9.7917 0.576165 9.81119 0.480936 9.85007C0.385708 9.88895 0.299194 9.94644 0.226461 10.0192C0.153729 10.0919 0.0962393 10.1784 0.0573603 10.2736C0.0184812 10.3689 -0.00100573 10.4709 3.99601e-05 10.5738C0.00108565 10.6766 0.0226432 10.7782 0.0634503 10.8726C0.104257 10.9671 0.163494 11.0524 0.23769 11.1236L7.45339 18.3393C7.59838 18.4843 7.79499 18.5657 8 18.5657C8.20501 18.5657 8.40162 18.4843 8.54661 18.3393L15.7623 11.1236C15.8365 11.0524 15.8957 10.9671 15.9366 10.8726C15.9774 10.7782 15.9989 10.6766 16 10.5738C16.001 10.4709 15.9815 10.3689 15.9426 10.2736C15.9038 10.1784 15.8463 10.0919 15.7735 10.0192C15.7008 9.94644 15.6143 9.88895 15.5191 9.85007C15.4238 9.81119 15.3218 9.7917 15.219 9.79275C15.1161 9.79379 15.0145 9.81535 14.9201 9.85616C14.8257 9.89696 14.7403 9.9562 14.6691 10.0304L8.77311 15.9264V0.784278C8.7746 0.680802 8.75529 0.578081 8.71635 0.482203C8.6774 0.386324 8.6196 0.29924 8.54638 0.226111C8.47316 0.152982 8.386 0.0952958 8.29007 0.0564722C8.19414 0.0176486 8.09139 -0.00152273 7.98792 9.44164e-05Z"
                        fill="white"
                      />
                    </svg>
                    {lang?.Download_invoice}
                  </Link>
                </div>
              )}

              <Link to="#" className="btn btn-blue royal" onClick={handlePrint}>
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
                  <path
                    d="M6.875 0C5.51787 0 4.4 1.06974 4.4 2.36842V3.15789H3.575C1.61043 3.15789 0 4.69898 0 6.57895V13.9474C0 15.2461 1.11787 16.3158 2.475 16.3158H4.4V17.6316C4.4 18.9303 5.51787 20 6.875 20H15.125C16.4821 20 17.6 18.9303 17.6 17.6316V16.3158H19.525C20.8821 16.3158 22 15.2461 22 13.9474V6.57895C22 4.69898 20.3896 3.15789 18.425 3.15789H17.6V2.36842C17.6 1.06974 16.4821 0 15.125 0H6.875ZM6.875 1.57895H15.125C15.5906 1.57895 15.95 1.9229 15.95 2.36842V3.15789H6.05V2.36842C6.05 1.9229 6.40943 1.57895 6.875 1.57895ZM3.575 4.73684H18.425C19.4975 4.73684 20.35 5.5526 20.35 6.57895V13.9474C20.35 14.3929 19.9906 14.7368 19.525 14.7368H17.6V12.8947C17.6 11.5961 16.4821 10.5263 15.125 10.5263H6.875C5.51787 10.5263 4.4 11.5961 4.4 12.8947V14.7368H2.475C2.00943 14.7368 1.65 14.3929 1.65 13.9474V6.57895C1.65 5.5526 2.50247 4.73684 3.575 4.73684ZM6.875 12.1053H15.125C15.5906 12.1053 15.95 12.4492 15.95 12.8947V17.6316C15.95 18.0771 15.5906 18.4211 15.125 18.4211H6.875C6.40943 18.4211 6.05 18.0771 6.05 17.6316V12.8947C6.05 12.4492 6.40943 12.1053 6.875 12.1053Z"
                    fill="white"
                  />
                </svg>
                {lang?.Print}
              </Link>

              <Link to={linkUrl ?? "#"} className="btn btn--gray">
                <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                  <path
                    d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z"
                    fill="black"
                  />
                </svg>
                {lang?.Back}
              </Link>
            </div>
          </div>
        </div>

        <div className="product-list">
          <div className="recentOrder secBlockGap">
            <div className="productFilter innderHeader mb--30 justify--between">
              <div className="orderDetailHeader dashboardView">
                <h1> {lang?.My_order_view} </h1>
                <div className="mt-4">
                  <p className="OrderDetailP">
                    {`Order #${id}`}{" "}
                    <span>
                      {lang?.Order_on}{" "}
                      {moment(data?.created_at).format("DD/MM/YYYY")}
                    </span>
                  </p>
                  {loading ? (
                    <Loader content="Loading" />
                  ) : (
                    <div className="custom__tab__data">
                      <div className="itemTable">
                        <br />
                        <table className="order_Table">
                          <thead>
                            <tr>
                              <th>{lang?.Billing_address}</th>
                              <th>{lang?.Shipping_address}</th>
                              <th>{lang?.payment_method}</th>
                              <th className="Order__Summary">
                                {lang?.Order_summary}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <p className="addressData">
                                  {billing_address?.first_name}{" "}
                                  {billing_address?.last_name}
                                  <span>
                                    {" "}
                                    {billing_address?.street_address}{" "}
                                    {billing_address?.street_address_2}{" "}
                                    {billing_address?.city}{" "}
                                    {billing_address?.state} <br />
                                    {billing_address?.country}{" "}
                                    {billing_address?.zipcode}
                                  </span>
                                </p>
                              </td>
                              <td>
                                <p className="addressData">
                                  {shipping_address?.first_name}{" "}
                                  {shipping_address?.last_name}
                                  <span>
                                    {" "}
                                    {shipping_address?.street_address}{" "}
                                    {shipping_address?.street_address_2}{" "}
                                    {shipping_address?.city}{" "}
                                    {shipping_address?.state}
                                    <br />
                                    {shipping_address?.country}{" "}
                                    {shipping_address?.zipcode}
                                  </span>
                                </p>
                              </td>
                              <td>{lang?.Online}</td>
                              <td>{data && orderSummary(data)}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="itemTable">
                          <table className="order_Table">
                            <thead>
                              <tr>
                                <th>
                                  {constant.rolesId.producer ===
                                  currentUser?.role_id
                                    ? lang?.Customer
                                    : lang?.Company}{" "}
                                  {lang?.name}
                                </th>
                                <th>{lang?.Contact}</th>
                                <th>{lang?.product_keywords}</th>
                                <th>{lang?.Emails}</th>
                                <th className="orderDetailAddress">
                                  {lang?.Only_address}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {constant.rolesId.producer ===
                                currentUser?.role_id ? (
                                  <>
                                    <td>{buyer_info?.company_name}</td>
                                    <td>
                                      {`+${buyer_info?.country_code}`}{" "}
                                      {buyer_info?.phone}
                                    </td>
                                    <td>
                                      {product_item_info &&
                                        product_item_info?.map(
                                          ({ product_info }, i) =>
                                            product_item_info?.length > i + 1
                                              ? `${product_info?.title} ,`
                                              : `${product_info?.title}`
                                        )}
                                    </td>
                                    <td>{buyer_info?.email}</td>
                                    <td>
                                      {buyer_info?.address} <br />{" "}
                                      {buyer_info?.address1}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>{seller_info?.company_name}</td>
                                    <td>
                                      {`+${seller_info?.country_code}`}{" "}
                                      {seller_info?.phone}
                                    </td>
                                    <td>
                                      {product_item_info &&
                                        product_item_info?.map(
                                          ({ product_info }, i) =>
                                            product_item_info?.length > i + 1
                                              ? `${product_info?.title} ,`
                                              : `${product_info?.title}`
                                        )}
                                    </td>
                                    <td>{seller_info?.email}</td>
                                    <td>
                                      {seller_info?.address} <br />{" "}
                                      {seller_info?.address1}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="itemTable transProduct">
                          <table>
                            <thead>
                              <tr>
                                <th>{lang?.Sr_no}</th>
                                <th>{lang?.Image}</th>
                                <th className="transactionPrice">
                                  {lang?.product_keywords}
                                </th>
                                <th>{lang?.Product_category}</th>
                                <th>{lang?.Per_unit_rice}</th>
                                <th>{lang?.Quantity}</th>
                                <th>{lang?.Total}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {product_item_info &&
                                product_item_info?.map(
                                  (
                                    { product_price, quantity, product_info },
                                    i
                                  ) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td className="cart-product-list">
                                          <span>
                                            {product_info?.product_gallery
                                              ?.length > 0
                                              ? product_info?.product_gallery?.map(
                                                  (
                                                    {
                                                      base_url,
                                                      attachment_medium_url,
                                                    },
                                                    i
                                                  ) => {
                                                    if (i < 1)
                                                      return (
                                                        <img
                                                          src={`${base_url}${attachment_medium_url}`}
                                                          alt="Product-Image"
                                                        />
                                                      );
                                                  }
                                                )
                                              : lang?.N_A}
                                          </span>
                                        </td>
                                        <td className="transactionPrice">
                                          {product_info?.title ?? lang?.N_A}
                                        </td>
                                        <td>
                                          {product_info?.product_category
                                            ?.option ?? lang?.N_A}
                                        </td>
                                        <td>${product_price}</td>
                                        <td>{quantity}</td>
                                        <td>${product_price * quantity}</td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>

                        {/* <div className='ship_table'>
                                                    <h1> {lang?.Shipment_info}</h1>
                                                    <div className='itemTable'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>{lang?.Shipping_name}</th>
                                                                    <th>{lang?.Shipment_id}</th>
                                                                    <th>{lang?.Shipment_url}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{"Coming Soon"}</td>
                                                                    <td>{"Coming Soon"}</td>
                                                                    <td>{"Coming Soon"}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Raise Request Popup */}
      <Modal
        className=""
        ariaHideApp={false}
        isOpen={raiseReq}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "0",
            left: "0%",
            right: "0%",
            bottom: "200px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            padding: "0px",
            // outline: 'none',
          },
        }}
      >
        <div className="popup_header flex justify-between items-center">
          <h4 className="page__title">Raise a Ticket</h4>
          <button className="close_btn--modal" onClick={() => closeModal()}>
            {" "}
            {language?.close}
          </button>
        </div>

        <div className="card card--block modal_box customForm">
          <form onSubmit={(event) => saveTicket(event)}>
            <div className="grid-2 gap-2">
              <div className="form__item">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  className="form--item"
                  placeholder="Product is damaged"
                  value={formValues.title}
                  onChange={(event) => handleChange(event)}
                ></input>
                {formErrors.title ? <FormErrors msg={formErrors.title} /> : ""}
              </div>
              <div className="form__item">
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  className="form--item"
                  placeholder="Need Support"
                  value={formValues.subject}
                  onChange={(event) => handleChange(event)}
                ></input>
                {formErrors.subject ? (
                  <FormErrors msg={formErrors.subject} />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form__item">
              <label>Attach Images</label>
              <div className="relative attachItem">
                <label>
                  <span className="">
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
                      <path
                        d="M18.2406 2.271C16.6487 2.271 15.0569 2.87513 13.8472 4.08484L2.4972 15.4348C2.41623 15.5126 2.35158 15.6057 2.30704 15.7087C2.26251 15.8118 2.23898 15.9227 2.23784 16.0349C2.2367 16.1472 2.25796 16.2585 2.30039 16.3625C2.34283 16.4664 2.40557 16.5608 2.48495 16.6402C2.56432 16.7196 2.65874 16.7823 2.76267 16.8247C2.8666 16.8672 2.97795 16.8884 3.09021 16.8873C3.20246 16.8862 3.31335 16.8626 3.4164 16.8181C3.51945 16.7736 3.61257 16.7089 3.69032 16.6279L15.0403 5.27795C16.8149 3.50338 19.6663 3.50338 21.4409 5.27795C23.2155 7.05253 23.2155 9.90401 21.4409 11.6786L10.7293 22.3903C9.80918 23.3103 8.34245 23.3103 7.42237 22.3903C6.50229 21.4702 6.50229 20.0035 7.42237 19.0834L16.0653 10.4404C16.1463 10.3627 16.2109 10.2696 16.2555 10.1665C16.3 10.0635 16.3235 9.95257 16.3247 9.84032C16.3258 9.72807 16.3046 9.61672 16.2621 9.51279C16.2197 9.40886 16.1569 9.31444 16.0776 9.23506C15.9982 9.15568 15.9038 9.09294 15.7998 9.05051C15.6959 9.00808 15.5846 8.98681 15.4723 8.98795C15.3601 8.98909 15.2492 9.01262 15.1461 9.05715C15.0431 9.10169 14.95 9.16634 14.8722 9.24731L6.22926 17.8903C4.66421 19.4553 4.66421 22.0183 6.22926 23.5834C7.7943 25.1484 10.3573 25.1484 11.9224 23.5834L22.634 12.8717C25.0535 10.4523 25.0535 6.50426 22.634 4.08484C21.4243 2.87513 19.8325 2.271 18.2406 2.271Z"
                        fill="#5B5B5B"
                      />
                    </svg>
                  </span>
                  <input
                    type="file"
                    id="attachment_img"
                    className="form--item"
                    placeholder="Select Image or File to attach"
                    accept="image/*, application/pdf"
                    onChange={(event) => handleFile(event)}
                    multiple
                  ></input>
                  <span className="slectedFiles">{ imageCount > 0 ? imageCount == 1 ? ` ${imageCount} File Selected` : ` ${imageCount} Files Selected` : ''}</span>
                </label>
              </div>
              {/* <div className="imageName">{isImageName}</div> */}
              {formErrors.image ? <FormErrors msg={formErrors.image} /> : ""}
            </div>
            <div className="form__item">
              <textarea
                className="form--item"
                name="message"
                rows={4}
                value={formValues.message}
                onChange={(event) => handleChange(event)}
                placeholder="Hi, Yesterday i bought a wine Bottle & i got my product but it was damaged. I need return for this product."
              ></textarea>
              {formErrors.message ? (
                <FormErrors msg={formErrors.message} />
              ) : (
                ""
              )}
            </div>
            <div className="btnRight">
              <button
                type="submit"
                disabled={disabledTrue}
                className="btn btn-blue royal noRadius"
              >
                {isLoader ? <FormSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
