import React, { useEffect, useState } from 'react'
import { changeOfferStatusImp, getMyOffers } from '../../routes/marketplace/modules/marketplace'
import PaginatedComponentView from '../PaginatedComponent/PaginatedComponentView';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function MyOffers({lang}) {
    const [myOffers, setMyOffers] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)
    const [statusMsg, setStatusMsg] = useState('')

    useEffect(() => {
        myOffersListing(1);
    }, [isUpdated])
    console.log(myOffers,"myoffer")
    // get all my offers listing
    const myOffersListing = async (pageNum) => {
        setStatusMsg('');
        const myOffersRes = await getMyOffers(pageNum);
        if (myOffersRes?.success === 200) {
            setMyOffers(myOffersRes?.data)
        } else {
            setMyOffers([]);
        }
    };

    // paginated  order page
    const handleRecentOrderPageClick = async (e) => {
        const selectedPage = e.selected + 1;
        await myOffersListing(selectedPage)
    };

    const action = async (data) => {
        const offerStatusResponse = await changeOfferStatusImp(data);
        if (offerStatusResponse.success === 200) {
            setIsUpdated(!isUpdated);
            setStatusMsg(offerStatusResponse?.message);
        } else {
            setStatusMsg(offerStatusResponse?.message);
        }
    }

    return (
        <>
        <div className='servics_blocks middle-long__section-block middleBlockBg'>
                <div className="product-title">
                    <h4>{lang?.My_offers}</h4>
                </div>
                {/* <h1>My Offers</h1> */}
               { <div className='product-list'>
                    <div className="recentOrder secBlockGap">
                        {statusMsg && <p style={{ color: 'green' }}>{statusMsg}</p>}
                        <div className='itemTable itemTableScroll customer__table'>
                            <table>
                                <thead>
                                    <tr>
                                     
                                        <th>{lang?.Offer_name}</th>
                                        <th>{lang?.Company_name}</th>
                                        <th>{lang?.Date}</th>
                                        <th>{lang?.Ending_date}</th>
                                        <th>{lang?.Shipping_charge}</th>
                                        <th>{lang?.Action}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myOffers?.data?.length > 0 && myOffers?.data?.map(({get_seller_info,created_at, end_date,offer_title, offer_id, shipping_price, get_map_offer, status }, i) => {
                                        const sellerInfo = get_seller_info && get_seller_info[0];
                                        const targetDate = moment(end_date, 'YYYY-MM-DD hh:ss'); // Parse the target date
                                        const currentDate = moment();
                                        return (
                                            <tr key={offer_id}>
                                                <td>{offer_title ?? "N/A"}</td>
                                                <td>{sellerInfo?.company_name ?? "N/A"}</td>
                                                <td>{moment(created_at).format("DD/MM/YYYY, hh:ss A")}</td>
                                                <td>{moment(end_date).format("DD/MM/YYYY, hh:ss A")}</td>
                                                <td>${shipping_price}</td>
                                                <td>{
                                                    <div className='flexBox'>
                                                {/*!targetDate.isAfter(currentDate) && <p className='textRed'>Offer Expired</p>*/}
                                                        <Link to={`/marketplace/offer/${offer_id}`} className='btn accept_Btn'>{lang?.View_offers} </Link>
                                                    </div>
                                                }</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            <PaginatedComponentView lastPage={myOffers?.last_page} handlePageClick={handleRecentOrderPageClick} />
                        </div>
                    </div>
                </div>}
                
              
            </div>
        </>
        
            
       
    )
}

 