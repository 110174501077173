import React from 'react';

const AddressCard = ({ first_name,last_name, street, city, state, postalCode, country, id, email, type, deleteAddress, street_address_2, handleEdit }) => {
    return (
        <div className="discover__events" key={id}>
            <ul className="">
                <li className="w-full" >
                    <div className="event__card" >
                        <div className="">
                            <h5 className="event-title">
                            </h5>
                            <div className="flexBox">
                                <div className='address'>
                                    <div className="font-normal">
                                        {first_name} {last_name}
                                    </div>
                                    <p className="font-normal">{email}</p>
                                    <p className="font-normal">
                                        <span >{street} {street_address_2} {city} {state}</span>
                                    </p>

                                    <p className="font-normal">
                                        <span>{country} {postalCode} </span>
                                    </p>
                                </div>
                                <div className='flexBox gap-2'>
                                    <span className='editBlock' title='Edit' onClick={() => handleEdit(type, {
                                        first_name, last_name,
                                        email, country, city, street_address: street,
                                        street_address_2, zipcode: postalCode, state
                                    },id)}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>

                                    </span>
                                    <span className='deleteBlock' title='Delete' onClick={() => deleteAddress(type, id)}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default AddressCard;
