import React from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../../assets/images/pizza-recipe.jpg'

export default function DisputeManagementView() {
  return (
    <>
        <div className="marketplace dashboard header">
            {/* Banner Section */}
            <div>
                <div className="section__wrapper">
                    <div className="flex justify-between items-center">
                        <h6 className="back-btn-small">
                            <Link to="/marketplace">
                                <i className="fa fa-arrow-left" aria-hidden="true" /> &nbsp; Back to Marketplace
                            </Link>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div className='servics_blocks dashboardView dashboardInquiry viewDisputes'>
            <div className='site-align top-align'>
                <div className='left__section-block sm:mb-2 md:mb-2'>Data coming soon
                    {/* Show sidebar here and remove Data coming soon text */}
                    {/* <DashboardSideBar /> */}
                </div>
                <div className='servics_blocks middle-long__section-block middleBlockBg disputes'>
                    <div className="product-title">
                        <div className='flex flex-wrap justify-between items-center'>
                            <h4>Need Support</h4>
                            <div className='productFilter disputesFilter'>
                                <div className='inner-btn-box'>
                                    <div className='Status form__item flexBox items-center gap-2'>
                                        <label className='mb-0'>Status</label>
                                        <div className='select-item skyBlue'>
                                            <select>
                                                <option>Resolved</option>
                                                <option>Resolved</option>
                                                <option>Resolved</option>
                                            </select>
                                        </div>
                                    </div>
                                    <Link to="/marketplace" className="btn btn--gray">
                                        <svg width="18" height="9" viewBox="0 0 18 9" fill="none"><path d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z" fill="black"></path></svg>
                                        Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product-list'>
                        <div className='recentOrder secBlockGap'>
                            <div className='itemTable itemTableScroll customer__table'>
                                <div className='showData'>
                                    <div className='showDataRow'>
                                        <div className='dataTitle'><strong>ID</strong> <span>#1</span></div>
                                        <div className='dataTitle'><strong>Order ID</strong> <span>122</span></div>
                                        <div className='dataTitle'><strong>Order Purchase Date</strong> <span>22-12-2023</span></div>
                                        <div className='dataTitle'><strong>Created</strong> <span>26 December 2023, 04:14 PM</span></div>
                                        <div className='dataTitle'><strong>Participants</strong> <span>Producer Name, Importer Name, Admin</span></div>
                                        <div className='dataTitle'><strong>Status</strong> <span className='resolvedStatus'>Resolved</span></div>
                                        <div className='dataTitle'><strong>Last Activity</strong> <span>26 December 2023, 04:14 PM</span></div>
                                    </div>
                                </div>
                                <div className='messageBlock mt-6'>
                                    <div className='allChat'>
                                        <div className='messageQuery py-6'>
                                            <div className='prdctHead'>
                                                <div className='prdtImg'>
                                                    <img src="https://alysei.s3.us-west-1.amazonaws.com/uploads/2023/01/0e0fec9e7ae86632f565a8daddfc3dba.png" alt="Profile" />
                                                </div>
                                                <div className='prdtDes pl-2'>
                                                    <p>Prem Product</p>
                                                    <span>22 Dec 2023, 12:38</span>
                                                </div>
                                            </div>
                                            <div className='messageDesc mt-4'>
                                                <p>
                                                    Hi, Yesterday i bought a wine Bottle & i got my product but it was damaged. I need return for this product.
                                                </p>
                                                <div className='attachedFile'>
                                                    <p className='mb-2'><span><i className="fa fa-paperclip" aria-hidden="true"></i></span> image name.jpg</p>
                                                    <img src={img1} alt="Added Image" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='messageQuery py-6'>
                                            <div className='prdctHead'>
                                                <div className='prdtImg'>
                                                    <img src="https://alysei.s3.us-west-1.amazonaws.com/uploads/2023/01/0e0fec9e7ae86632f565a8daddfc3dba.png" alt="Profile" />
                                                </div>
                                                <div className='prdtDes pl-2'>
                                                    <p>Store Name</p>
                                                    <span>23 Dec 2023, 10:01</span>
                                                </div>
                                            </div>
                                            <div className='messageDesc mt-4'>
                                                <p>
                                                    Hi, Yesterday i bought a wine Bottle & i got my product but it was damaged. I need return for this product.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='replyMessage'>
                                        <div className='messageText'>
                                            <textarea rows="4" cols="4" placeholder='Type reply here...'></textarea>
                                        </div>
                                        <div className='chatButtons'>
                                            <div className='attachFile'>
                                                <label>
                                                    <div className='icon'>
                                                        <i className="fa fa-paperclip" aria-hidden="true"></i>
                                                        Attach File or Images
                                                    </div>
                                                    
                                                    <input type='file' />
                                                </label>
                                            </div>
                                            <div className='rightButtons'>
                                                <button type='button' className='btn btn-white'>Discard</button>
                                                <button type='button' className='btn btn-blue ml-4'>Send</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
