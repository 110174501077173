import headerType from "../../../helpers/headerType";
import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment"
export const CREATE_AWARD = "CREATE_AWARD";
export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_BLOG = "CREATE_BLOG";
export const SET_MEDAL_TYPE = "SET_MEDAL_TYPE";
export const EDIT_AWARD = "EDIT_AWARD";
export const EDIT_EVENT = "EDIT_EVENT";
export const EDIT_BLOG = "EDIT_BLOG";
export const GET_ERROR_MSG = "GET_ERROR_MSG";
export const SET_AWARD_SPINNER = "SET_AWARD_SPINNER";
export const SET_FORM_ERRORS_MESSAGE = "SET_FORM_ERRORS_MESSAGE";
export const GET__TRIP_LIST_DATA = "GET__TRIP_LIST_DATA";
export const GET_ADVENTURE_TYPE_DATA = "GET_ADVENTURE_TYPE_DATA";
export const GET__TRIP_LIST_DATA_BY_ID = "GET__TRIP_LIST_DATA_BY_ID";

// console.log(headerType.HeadersWithToken(),'headerType');
export function createAwardFun1(data) {
  return {
    type: CREATE_AWARD,
    payload: data,
  };
}
export function getAdventureType(data) {
  return {
    type: GET_ADVENTURE_TYPE_DATA,
    payload: data,
  };
}
export function setCreateEvent(data) {
  return {
    type: CREATE_EVENT,
    payload: data,
  };
}
export function setCreateBlog(data) {
  return {
    type: CREATE_BLOG,
    payload: data,
  };
}
export function setMedalType(data) {
  return {
    type: SET_MEDAL_TYPE,
    payload: data,
  };
}
export function editEventDataById(data) {
  return {
    type: EDIT_EVENT,
    payload: data,
  };
}
export function editBlogDataById(data) {
  return {
    type: EDIT_BLOG,
    payload: data,
  };
}
export function editAward(data) {
  return {
    type: EDIT_AWARD,
    payload: data,
  };
}

export function setFormError(message, fld) {
  return {
    type: SET_FORM_ERRORS_MESSAGE,
    payload: { msg: message, field: fld },
  };
}

export function setAwardSpinner(data) {
  return {
    type: SET_AWARD_SPINNER,
    payload: data,
  };
}

export function getTripdataById(data) {
  return {
    type: GET__TRIP_LIST_DATA_BY_ID,
    payload: data,
  };
}

export function getTripList(data) {
  return {
    type: GET__TRIP_LIST_DATA,
    payload: data,
  };
}

export const postEvent = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/create/event`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};

export const postBlog = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/create/blog`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {

        console.log(e, 'error.');
      }
    }

  };
};
export async function blogFun(data) {
  var form_data = new FormData();
  for (var key in data) {

    form_data.append(key, data[key]);
  }

  const token = auth.getAccessToken()
  const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/create/blog`, {
    method: "POST",
    headers: headerType.HeadersWithToken(token),
    body: form_data,
  });
  let response = await result.json();
  if (response.success === 200) {
    return response;

  } else {
    // alert(response.errors)
    console.log(response.errors);
  }

}
export const createAward = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/create/award`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};

export const getAlyseiNewsStatus = (model) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/news`, {
          method: "GET",
          headers: headerType.HeadersWithToken(token),
        });
        let response = await result.json();
        if (response.success) {
          let data = []
          response?.data?.map((img) => {
            data.push({ url: `${img?.attachment?.base_url}${img?.attachment?.attachment_url}`, type: 'image', })
          });
          return data
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};
export const updateBlog = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/blog`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};

//updateBlog



export const updateEvent = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/event`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};
export const updateTrips = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/trip`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};
export const updateAward = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/update/award`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};
export const deleteEvent = (id) => {
  var form_data = new FormData();
  form_data.append("event_id", id);
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/event`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }
  };
};

export const deleteBlog = (id) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/blog?blog_id=${id}`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),

        });
        let response = await result.json();
        if (response.success) {

          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }
  };
};

export const deleteTripsById = (id) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/trip?trip_id=${id}`, {
          method: "POST",
          headers: headerType.HeadersWithTokenAndJson(token),
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }
  };
};

export const deleteAward = (id) => {
  var form_data = new FormData();
  form_data.append("award_id", id);
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/award`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }
    //  else {
    //     await dispatch(setFeaturedProductSpinner(false));
    //     // alert('You need to login first')
    //     console.log('You need to login first');
    // }
  };
};

export function fetchAdventurType(type = '') {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/adventure/types?type=${type}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getAdventureType(response.data));
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function fetchTripsById(id) {
  return async (dispatch, getState) => {
    await dispatch(setAwardSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/edit/trip/${id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(getTripdataById(response.data));
        await dispatch(setAwardSpinner(false));
      } else {
        console.log(response.errors);
        await dispatch(setAwardSpinner(false));
      }
    } catch (e) {
      await dispatch(setAwardSpinner(false));
      console.log(e);
    }
  };
}
export function fetchEditEventDataById(id) {
  return async (dispatch, getState) => {
    await dispatch(setAwardSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/edit/event/${id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(editEventDataById(response.data));
        await dispatch(setAwardSpinner(false));
      } else {
        console.log(response.errors);
        await dispatch(setAwardSpinner(false));
      }
    } catch (e) {
      await dispatch(setAwardSpinner(false));
      console.log(e);
    }
  };
}

export function fetchEditBlogDataById(id) {
  return async (dispatch, getState) => {
    await dispatch(setAwardSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/edit/blogs/${id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(editBlogDataById(response.data));
        await dispatch(setAwardSpinner(false));
      } else {
        console.log(response.errors);
        await dispatch(setAwardSpinner(false));
      }
    } catch (e) {
      await dispatch(setAwardSpinner(false));
      console.log(e);
    }
  };
}

export function fetchEditAwardData(id) {
  return async (dispatch, getState) => {
    await dispatch(setAwardSpinner(true));
    try {
      const token = auth.getAccessToken();
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/edit/award/${id}`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(editAward(response.data));
        await dispatch(setAwardSpinner(false));
      } else {
        console.log(response.errors);
        await dispatch(setAwardSpinner(false));
      }
    } catch (e) {
      await dispatch(setAwardSpinner(false));
      console.log(e);
    }
  };
}
export function fetchEventListingData(visitorId) {
  return async (dispatch, getState) => {
    await dispatch(setAwardSpinner(true));
    try {
      const token = auth.getAccessToken();
      let url = visitorId
        ? `${REACT_APP_API.ENV.REACT_APP_API}/get/event/listing?visitor_profile_id=${visitorId}`
        : `${REACT_APP_API.ENV.REACT_APP_API}/get/event/listing`
      const result = await fetch(url, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setCreateEvent(response.data));
        await dispatch(setAwardSpinner(false));
      } else {
        console.log(response.errors);
        await dispatch(setAwardSpinner(false));
      }
    } catch (e) {
      await dispatch(setAwardSpinner(false));
      console.log(e);
    }
  };
}


export function fetchBlogListingData(visitorId) {
  return async (dispatch, getState) => {
    await dispatch(setAwardSpinner(true));
    try {
      const token = auth.getAccessToken();
      console.log(visitorId, '')
      let url = visitorId
        ? `${REACT_APP_API.ENV.REACT_APP_API}/get/blog/listing?visitor_profile_id=${visitorId}`
        : `${REACT_APP_API.ENV.REACT_APP_API}/get/blog/listing`
      const result = await fetch(url, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setCreateBlog(response.data));
        await dispatch(setAwardSpinner(false));
        return response
      } else {
        console.log(response.errors);
        await dispatch(setAwardSpinner(false));
      }
    } catch (e) {
      await dispatch(setAwardSpinner(false));
      console.log(e);
    }
  };
}

export function fetchAwardMedalType() {
  return async (dispatch, getState) => {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/medal/types`, {
        method: "GET",
        headers: headerType.HeadersWithTokenAndJson(token),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setMedalType(response.data));
      } else {
        console.log(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export const fetchTripsList = (visitorId) => {
  return async (dispatch, getState) => {
    const token = await auth.getAccessToken();
    await dispatch(setAwardSpinner(true));
    if (token) {
      try {
        let url = visitorId ? `${REACT_APP_API.ENV.REACT_APP_API}/get/trip/listing?visitor_profile_id=${visitorId}`
          : `${REACT_APP_API.ENV.REACT_APP_API}/get/trip/listing`
        let result = await fetch(url, { method: "GET", headers: headerType.HeadersWithToken(token) });
        let response = await result.json();
        if (response.success === 200) {
          await dispatch(getTripList(response.data));
          await dispatch(setAwardSpinner(false));
          return response;
        } else {
          await dispatch(setAwardSpinner(false));
        }
      } catch (e) {
        await dispatch(setAwardSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(setAwardSpinner(false));
    }
  };
};

export const createTrips = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/create/trip`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success === 200) {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};

export const postYourFeedBack = (model) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/blog/feedback`, {
          method: "POST",
          headers: headerType.HeadersWithTokenAndJson(token),
          body: JSON.stringify(model)
        });
        let response = await result.json();
        if (response.success === 200) {
          return response
        } else {
          return response
        }
      } catch (e) {
        console.log(e, 'error.');
      }
    }

  };
};
export const initialState = {
  createAwardData: [],
  createdEventData: [],
  createdBlogtData: [],
  editAwardData: [],
  eventDataById: [],
  blogDataById: [],
  tripListData: [],
  adventureTypes: [],
  tripDataById: [],
  awardMedalType: [],
  awardSpinner: false,
  errMessage: {
    msg: "",
    field: "",
  },
}



const ACTION_HANDLERS = {
  [CREATE_AWARD]: (state, action) => {
    return {
      ...state,
      createAwardData: action.payload,
    };
  },
  [CREATE_EVENT]: (state, action) => {
    return {
      ...state,
      createdEventData: action.payload,
    };
  },
  [CREATE_BLOG]: (state, action) => {
    return {
      ...state,
      createdBlogtData: action.payload,
    };
  },
  [EDIT_AWARD]: (state, action) => {
    return {
      ...state,
      editAwardData: action.payload,
    };
  },
  [EDIT_EVENT]: (state, action) => {
    return {
      ...state,
      eventDataById: action.payload,
    };
  },
  [EDIT_BLOG]: (state, action) => {
    return {
      ...state,
      blogDataById: action.payload,
    };
  },
  [SET_AWARD_SPINNER]: (state, action) => {
    return {
      ...state,
      awardSpinner: action.payload,
    };
  },
  [SET_FORM_ERRORS_MESSAGE]: (state, action) => {
    return {
      ...state,
      errMessage: action.payload,
    };
  },
  [SET_MEDAL_TYPE]: (state, action) => {
    return {
      ...state,
      awardMedalType: action.payload,
    };
  },
  [GET__TRIP_LIST_DATA]: (state, action) => {
    return {
      ...state,
      tripListData: action.payload,
    };
  },
  [GET__TRIP_LIST_DATA_BY_ID]: (state, action) => {
    return {
      ...state,
      tripDataById: action.payload,
    };
  },
  [GET_ADVENTURE_TYPE_DATA]: (state, action) => {
    return {
      ...state,
      adventureTypes: action.payload,
    };
  },
}

export default function DiscoverModule(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}