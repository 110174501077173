import React, { Fragment, useEffect, useState } from 'react'
import constant from "../../../helpers/constant";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import auth from "../../../helpers/auth";
import { stateHubSelect, getHubsState, } from '../modules/hubs';
import FormError from "../../../components/Errors/FormError";
import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from '../../../helpers/language';

export function SelectedState(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [newUser, setUser] = useState([]);
    const [findname, setFindName] = useState([]);
    const [search, setSearch] = useState('');
    const [msg, setMsg] = useState(false);
    const [textMsg, setTextMsg] = useState('');
    const { selectedState, hubsSpinner, statehubselect } = useSelector(state => state.hubs_State)
    const { data } = selectedState
    const [lang] = useState(language.getLang());

    useEffect(async () => {
        await dispatch(getHubsState())
        document.body.classList.add(`hubs`);
    }, [])

    useEffect(async () => {
        setSearch(data)
        data && data.map((res) => {
            res?.is_selected && handleChange({ target: { checked: res?.is_selected } }, res)
        })
        await setFindName([...new Set(statehubselect)]);
        const user = await auth.getUserType();
        await setUser(JSON.parse(user))
    }, [data])

    const handlesearch = (e) => {
        const findName = e.target.value;
        setName(findName);
        if (findName !== '') {
            const results = data.filter((user) => {
                return user.name.toLowerCase().includes(findName.toLowerCase());
            });
            setSearch(results);
        } else {
            setSearch(data);
        }
    }


    const handleChange = async (e, items) => {
        const data = {
            id: parseInt(items.id),
            name: items.name,
            isSelected: e.target.checked
        }
        await setMsg(false);
        await setTextMsg('');
        if (e.target.checked) {
            await setFindName((old) => [...old, data])
        } else {
            findname && findname.length > 0 ? findname.map((item, index) => {
                if (item.id === items.id) {
                    if (index > -1) {
                        findname.splice(index, 1);
                        setFindName(() => [...findname]);
                    } return findname;
                }
            }) : setFindName((old) => [...old, data]);

            
        }
        

        //await dispatch(getStateOfCity(id))
    }

    const handleErrorMsg = async () => {
        await findname.length ? setMsg(true) : setMsg(false)
        newUser.role_id === constant.rolesId.restaurant && findname.length && await setTextMsg(lang?.selectOneState)

        setTimeout(() => {
            setMsg(false)
            setTextMsg('');
        }, 3000);
    }

    const handleclick = async () => {
        var result = findname.reduce((unique, o) => {
            if (!unique.some(obj => obj.id === o.id && obj.name === o.name)) {
                unique.push(o);
            }
            return unique;
        }, []);

        await dispatch(stateHubSelect([...new Set(result)]))
        history.push("/city")
    }
    return (
        <Fragment>
            <div className="hub__selection--form bg-white selectedState city">
                <form className="hub__form padding--5">
                    <div className="form--inner--div">
                        <div className="flex">
                            <div className="w-full">
                                <div className="coln-left">
                                    <h4 className="title-blue-font-30">
                                        {lang?.USA}
                                    </h4>
                                    <p className="title-para">{lang?.selectState}</p>
                                    <div className="form__item searchIcon mt-3">
                                        <span className="inputSearch"><i className="fa fa-search" aria-hidden="true"></i></span>
                                        <input type="text" className="" placeholder="Search states" onChange={(e) => handlesearch(e)} />
                                    </div>
                                    <div className="flex justify-end mt-5   md:mb-4 sm:mb-4">
                                        <div className="bg-btn-gray sky-blue">
                                            <button type="button" className="btn" onClick={handleclick}>{lang?.Next}</button>
                                            {/* {findname.length  || statehubselect.length  ? <button type="button" className="btn" onClick={handleclick}>{lang?.Next}</button> : null} */}
                                        </div>
                                    </div>
                                    {textMsg !== '' && msg && <FormError msg={textMsg} />}
                                    {hubsSpinner ? <FormSpinner /> :
                                        <div className="hub__tab padding--5">
                                            <div className="choose__hub" onClick={() => handleErrorMsg()} >
                                                {search && search.map((item, index) => {
                                                    const filterStateName = (statehubselect && statehubselect.length > 0 && statehubselect.filter((data) => item.id === data.id) || findname && findname.length > 0 && findname.filter((data) => item.id === data.id));

                                                    return (
                                                        <div className="form__item form__checkbox country-check" key={index}>
                                                            {filterStateName && filterStateName.length > 0 && filterStateName[0].id === item.id ?
                                                                <input type="checkbox" id={item.id}
                                                                    name={item.name} defaultChecked
                                                                    onChange={(event) => handleChange(event, item)} /> :
                                                                <input
                                                                    type="checkbox"
                                                                    id={item.id}
                                                                    name={item.name}
                                                                    defaultChecked={item?.is_selected}
                                                                    //  checked={findname.length > 0 && findname[0].id === parseInt(item.id) && findname[0].isSelected ? true : false}
                                                                    onChange={(event) => handleChange(event, item)}
                                                                    disabled={findname.length > 0 && findname[0].id !== item.id && newUser.role_id === constant.rolesId.restaurant ? true : false}
                                                                />
                                                            }
                                                            <label htmlFor={item.id} className="flex p-4 rounded-lg">
                                                                <span>{item.name}</span>
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}