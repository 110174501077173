import React from 'react'
import LeftContent from './LeftContent'
import CenterContent from './CenterContent'
import RightContent from './RightContent'

export default function AboutUsView() {
  return (
    <>
        <div className='aboutView card card--block'>
            <div className='flexBox'>
                <div className='leftData'>
                  <LeftContent />
                </div>
                <div className='centerData'>
                  <CenterContent />
                </div>
                <div className='rightData'>
                  <RightContent />
                </div>
            </div>
        </div>
    </>
  )
}
