import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



export default function MyEditor({ setAddProductDataArr, keys, data }) {

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setAddProductDataArr((prev) => ({ ...prev, [keys]: data }));
    };

    return (
        <div>
            <CKEditor
                editor={ClassicEditor}
                data={data}
                onChange={handleEditorChange}
                id={keys}
            />
        </div>
    );
}


