import React from 'react'
import { Fragment, useEffect } from "react";
import headerimg from '../../assets/images/logo1.png'
import { Link, useHistory } from "react-router-dom";

export default function LoginHeader() {
  return (
    <Fragment>
      {/* header */}
      <div className="site__header mrkt-place-header">
        <div className="site__width">
          <div className="site-align w-full">
            <div className="top_left-header site-align w-full mobileHeaderView">
              {/* <div className="left-arrow w-6/12"></div> */}
              <div className="site__logo">
                <Link to="/"><img src={headerimg} alt="Logo" title="Logo" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>

  )
}
