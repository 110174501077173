import React, { useState, useEffect } from 'react'
import SettingLeftNavigation from './SettingLeftNavigation'
import MembershipBar from '../../../components/membership/membershipBar';
import language from '../../../helpers/language';

export default function MemberShip() {
  const [lang] = useState(language.getLang());

  useEffect(() => {
    //window.fcWidget.show() 
  },[]);
  return (
    <div>
      {/* Layout Content */}
      <div className="home__page b2b hubMember">
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title"><h1 className="font-18 text-black">{lang?.Membership}</h1></div>
              {/* <div className='md:w-3/4'> */}
              <MembershipBar />
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
