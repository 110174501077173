import React, {useState, useRef, useEffect} from 'react'
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
import FormSpinner from '../../../../../components/Spinners/FormSpinner';
import { deleteMyAccount } from '../../../modules/Buyermodule';
import auth from '../../../../../helpers/auth';

export default function DeleteAccountView() {
const history = useHistory();
const [openDeleteModal, setOpenDeleteModal] = useState(false);
const [deleteLoader, setDeleteLoader] = useState(false);
const [deleteMessage, setDeleteMessage] = useState("");

const deleteAccount = async() => {
    await setDeleteLoader(true);
    let apiResponse = await deleteMyAccount();
    if(apiResponse?.success){
        await setDeleteMessage(apiResponse?.message);
        await setDeleteLoader(false);
        await setOpenDeleteModal(false);
        setTimeout(() => {
            setDeleteMessage("");
            auth.removeAccessToken();
            localStorage.clear();
            history.push("/register");
        }, 2500);
    }
}
  return (
    <div className='settingView deleteAccount'>
        <div className="message mt-4 mb-4 text-center text-green">
            <h4 className="font-bold">{deleteMessage}</h4>
        </div>
        <h4 className='pageTitle'>Delete My Account</h4>
        <p>If you delete your account all data related to your account will be deleted.</p>
        <div className='formView'>
            <form>
                {/* <div className='form__item whiteBg'>
                    <textarea rows={5} placeholder='Please specify a reason to delete your account' />
                </div> */}
                <div className='actionButoon mt-4'>
                    <button type='button' className='btn btn-blue style2' onClick={() => setOpenDeleteModal(true)}>Delete</button>
                </div>
            </form>
        </div>
        <Modal
        isOpen={openDeleteModal}
        style={{
            overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            },
            content: {
            position: "absolute",
            top: "0",
            left: "0%",
            right: "0%",
            bottom: "200px",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            padding: "0",
            border: "0",
            borderRadius: "8px",
            // outline: 'none',
            },
        }}
        >
        <div className="popup_header flex justify-between items-center popupheaderBg">
            <h4 className="page__title">Delete Account</h4>
            <button
            className="close_btn--modal"
            onClick={() => setOpenDeleteModal(!openDeleteModal)}
            >
            Close
            </button>
        </div>
        <div className="card card--block modal_box">
            <p className="warning">
            Are you sure you want to delete account?
            </p>
            <div className="right__btn text-right mt-4">
            {deleteLoader ? (
                <FormSpinner />
            ) : (
                <>
                <Link
                    className="btn btn1"
                    to="#"
                    onClick={() => setOpenDeleteModal(!openDeleteModal)}
                >
                    Cancel
                </Link>
                <Link
                    className="btn btn1 btn2 ml-3"
                    to="#"
                    onClick={() => deleteAccount()}
                >
                    Delete
                </Link>
                </>
            )}
            </div>
        </div>
        </Modal>
    </div>
  )
}
