import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import REACT_APP_API from "../../config/environment"
import language from '../../helpers/language';

export default function RightFooter() {
  const [lang] = useState(language.getLang());
  return (
    <div className='rightFooter'>
      <div className='forDesktop'>
        <div className="site-align">
          <div className="footer-nav">
            <ul>
              {/* <li>
                <a href="#">{lang?.Home}</a>
              </li> */}
              <li>
                <a href="https://alysei.com/contacts/" target="_blank">{lang?.Contact_us}</a>
              </li>
              <li>
                <a href={`${REACT_APP_API.ENV.REACT_APP}/privacy-policy`} target="_blank">{lang?.Privacy_Policy}</a>
              </li>
              <li>
                <a href={`${REACT_APP_API.ENV.REACT_APP}/terms`} target="_blank">{lang?.Terms_and_Condition}</a>
              </li>
            </ul>
          </div>
          <div className="footer-social--nav">
            <ul>
            <li>
                  <a href="https://www.facebook.com/AlyseiB2B/" target="_blank">
                    <i className="fab fa-facebook-square" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/alyseilaunch2020/" target="_blank">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCLS2XGoIFJcqhBCxm9K7OEg" target="_blank">
                    <i className="fab fa-youtube-square" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/alysei/" target="_blank">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className='forMob'>
        <div className="site-align">
          <div className="footer-nav">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Contact us</a>
              </li>
              <li>
                <a href={`${REACT_APP_API.ENV.REACT_APP}/privacy-policy`} target="_blank">Privacy Policy</a>
              </li>
              <li>
                <a href={`${REACT_APP_API.ENV.REACT_APP}/terms`} target="_blank">Terms & Conditions</a>
              </li>
            </ul>
          </div>
          <div className="footer-social--nav">
            <ul>
              <li>
                <a href="#">
                  <i className="fab fa-facebook-square" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-youtube-square" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  )
}
