import React, { useState } from 'react'
import '../../../assets/marketplacecss/mrktplace-style.css'
import { Link } from 'react-router-dom'
import language from '../../../helpers/language';

export function ThankYou() {
  const [lang] = useState(language.getLang());
  return (
    <>
      <div className="marketplace submitation">
        {/* Banner Section */}
        <div className="main__content">
          <div className="section__wrapper">
            <div className="page-padding">
              <div className="width--submitation">
                <div className="icon">
                  <i className="fa fa-smile-o" aria-hidden="true" />
                </div>
                <h3>{lang?.Thank_you}.</h3>
                <p className="mt-5 mb-5">
                 {lang?.will_resp}
                </p>
                <div className="inner-btn-box justify-center btn-style-2">
                  <Link to="/marketplace" className="btn btn-blue">{lang?.GO_TO_MARKETPLACE}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
