import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function DiscoverRestaurants() {
    const [formToggle, setFormToggle] = useState(false);
    const [clickToggle, setClickToggle] = useState(false);
    return (
        <div>
            <div className="discover_page">
                <div className="page_top_form">
                    <div className="flex justify-between items-center">
                        <Link to="#"><i className="fa fa-arrow-left" aria-hidden="true"></i> Discover Events</Link>
                        <div className="click_from" onClick={() => setFormToggle(!formToggle)}>                           
                            {
                              formToggle?
                                <div>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>:
                                <div className="discover_map_list">
                                    <span className="">List
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.45172 0.625626L1.68797 2.8675L1.03984 2.29375L0.136719 3.31375L1.32672 4.37625L1.86859 4.85438L2.31484 4.28063L4.52484 1.45438L3.45172 0.625626ZM5.45984 2.06V3.42H14.6398V2.06H5.45984ZM3.45172 5.38563L1.68797 7.6275L1.03984 7.05375L0.136719 8.07375L1.32672 9.13625L1.86859 9.61438L2.31484 9.04063L4.52484 6.21438L3.45172 5.38563ZM5.45984 6.82V8.18H14.6398V6.82H5.45984ZM3.45172 10.1456L1.68797 12.3875L1.03984 11.8138L0.136719 12.8338L1.32672 13.8963L1.86859 14.3744L2.31484 13.8006L4.52484 10.9744L3.45172 10.1456ZM5.45984 11.58V12.94H14.6398V11.58H5.45984Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <svg width="16" height="16" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.139 25.3133H0V21.9383H2.1405C2.43612 21.4238 2.8622 20.9964 3.37575 20.6992C3.8893 20.402 4.47215 20.2455 5.0655 20.2455C5.65885 20.2455 6.2417 20.402 6.75525 20.6992C7.2688 20.9964 7.69488 21.4238 7.9905 21.9383H27V25.3133H7.986C7.68977 25.827 7.26347 26.2536 6.75 26.5502C6.23653 26.8468 5.65399 27.003 5.061 27.003C4.46801 27.003 3.88547 26.8468 3.372 26.5502C2.85853 26.2536 2.43223 25.827 2.136 25.3133H2.139ZM10.5765 15.1883H0V11.8133H10.575C10.8712 11.2996 11.2975 10.8729 11.811 10.5763C12.3245 10.2797 12.907 10.1235 13.5 10.1235C14.093 10.1235 14.6755 10.2797 15.189 10.5763C15.7025 10.8729 16.1288 11.2996 16.425 11.8133H27V15.1883H16.422C16.1261 15.7023 15.6999 16.1293 15.1864 16.4263C14.6729 16.7232 14.0902 16.8795 13.497 16.8795C12.9038 16.8795 12.3211 16.7232 11.8076 16.4263C11.2941 16.1293 10.8679 15.7023 10.572 15.1883H10.5765ZM19.014 5.06325H0V1.68825H19.0125C19.3087 1.17455 19.735 0.747931 20.2485 0.451303C20.762 0.154675 21.3445 -0.00149536 21.9375 -0.00149536C22.5305 -0.00149536 23.113 0.154675 23.6265 0.451303C24.14 0.747931 24.5663 1.17455 24.8625 1.68825H27V5.06325H24.8595C24.5636 5.57734 24.1374 6.00434 23.6239 6.30126C23.1104 6.59817 22.5277 6.75451 21.9345 6.75451C21.3413 6.75451 20.7586 6.59817 20.2451 6.30126C19.7316 6.00434 19.3054 5.57734 19.0095 5.06325H19.014Z" fill="black" />
                                    </svg>
                                </div>
                                
                            }
                        </div>
                    </div>
                    {
                        formToggle?<form className="mt-5">
                        <div className="flex justify-between items-center mb-5">
                            <h4 className="page__title">Filter</h4>
                            <a href="#">Clear Filters</a>
                        </div>
                        <div className="flex items-center banner-form-bg">
                            <div className="w-full md:w-1/5">
                                <div className="form__item">
                                    <label for="">Select a date</label>
                                    <select>
                                        <option>1st November, 2021</option>
                                        <option>2nd November, 2021</option>
                                        <option>3rd November, 2021</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full md:w-1/5 pl-2 pr-2">
                                <div className="form__item">
                                    <label>Event Type</label>
                                    <select>
                                        <option>Public</option>
                                        <option>Public</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full md:w-1/5 pl-2 pr-2">
                                <div className="form__item">
                                    <label>Registration type</label>
                                    <select>
                                        <option>Free</option>
                                        <option>Free</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full md:w-1/5 pl-2 pr-2">
                                <div className="form__item">
                                    <label>Restaurant type</label>
                                    <select>
                                        <option>Cafe</option>
                                        <option>Cafe</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full md:w-1/5">
                                <div className="btn-blue-bg">
                                    <button type="button" className="btn">Apply</button>
                                </div>
                            </div>
                        </div>
                    </form>:""
                    }
                    
                </div>
                <div className="locationMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224368.39371440222!2d77.25804182109499!3d28.516983404443188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a43173357b%3A0x37ffce30c87cc03f!2sNoida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1638174874131!5m2!1sen!2sin"></iframe>
                </div>
            </div>
        </div>
    )
}
