import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ImporterOrderTable from './ImporterOrderTable';
import ResetButtons from '../ResetButton/ResetButtons';

export default function MyOrderList({ data, handlePageClick, filterOrders ,lang,setFilterStatus}) {
    const [index, setIndex] = useState(0);
    const [order_id, setOrderId] = useState('');
    const [tabType] = useState({
        0: "",
        1: "ongoing",
        2: "completed",

    });

    const handleResetFilterBtn = () => {
        setOrderId('');
        filterOrders(null, data?.current_page);
    }

    return (
        <div className="servics_blocks middle-long__section-block middleBlockBg myOrder">
            <div className="product-title">
                <h4>{lang?.My_orders}</h4>
            </div>
            <div className='productFilter flexEnd'>
                <div className='categoryList searchBar'>
                    <input type='text' name="order_id"  value={order_id} onChange={(e) => setOrderId(e.target.value)} className='search form--group' placeholder='Order ID' />
                </div>
                <div className='btn btn--gray'>
                    <button type='button' className='btn filterBtn' onClick={() => order_id?.trim()?.length > 0 && filterOrders({ status: tabType[index], order_id }, data?.current_page)}>
                        {lang?.Filter}
                    </button>
                </div>
                {order_id?.length > 0 && <div className='btn btn--gray'>  <ResetButtons text={lang?.Reset_filter} onclick={handleResetFilterBtn} /> </div>}
            </div>

            <div className='product-list custom__tab'>
                <div className='tax__mgmt'>
                    <div className='TabFlex'>
                        <div className='Tax_Tab'>
                            <Tabs className="TaxTabFlex" onSelect={(i) => { setIndex(i); i !== index && filterOrders({ status: tabType[i] }, data?.current_page);setFilterStatus(tabType[i]) }}>
                                <TabList>
                                    <Tab>All</Tab>
                                    <Tab>{lang?.Ongoing}</Tab>
                                    <Tab>{lang?.Past_orders}</Tab>
                                </TabList>
                                {Array.from({ length: 3 })?.map((_, i) => {
                                    return (
                                        <TabPanel className="custom__tab__data" key={i}>
                                            <ImporterOrderTable orderData={{ data, handlePageClick }} lang={lang}/>
                                        </TabPanel>
                                    )
                                })}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
