import auth from '../../../helpers/auth'
import REACT_APP_API from "../../../config/environment";
import headerType from '../../../helpers/headerType';
export const GET_MEMBER_PROGRESS_SPINNER_STATUS = "GET_MEMBER_PROGRESS_SPINNER_STATUS";
export const GET_MEMBER_PROGRESS = "GET_MEMBER_PROGRESS";


export function getMemberSpinner(flag) {
    return {
      type: GET_MEMBER_PROGRESS_SPINNER_STATUS,
      payload: flag,
    };
}
  
export function getMemberProgress(data) {
    return {
        type: GET_MEMBER_PROGRESS,
        payload: data,
    };
    
}

export function fetchMemberProgress() {    
    return async (dispatch) => {
        const token = await auth.getAccessToken();
        await dispatch(getMemberSpinner(true))
        try {
            const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/alysei/progress`, {
                method: "GET",
                headers: headerType.HeadersWithTokenAndJson(token),
            });
            let response = await result.json();
            if (response.success === 200) {
                await dispatch(getMemberSpinner(false))
                await dispatch(getMemberProgress(response));
                await localStorage.setItem("alysei_review",response?.data.alysei_review);
            }
        } catch (e) {
            console.log(e, 'error');
           await dispatch(getMemberSpinner(false))
        }
    };
}


export const initialState = {
    getMemberProgressData: [],
    memberProgressSpinner:false
};

const ACTION_HANDLERS = {
    
    [GET_MEMBER_PROGRESS_SPINNER_STATUS]: (state, action) => {
      return {
        ...state,
        memberProgressSpinner: action.payload,
      };
    },   
    [GET_MEMBER_PROGRESS]: (state, action) => {
        return {
            ...state,
            getMemberProgressData: action.payload,
        };
    }, 
};


export default function membershipState(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
