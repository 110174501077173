import React, { Fragment } from "react";

export function SuccessMessage({ msg }) {
  return (
    <Fragment>
      <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-md text-green-700 bg-green-100 border border-green-300 profileError">   
        <div className="text-md font-normal  max-w-full flex-initial">
          {msg}
        </div>
      </div>
    </Fragment>
  );
}

export default SuccessMessage