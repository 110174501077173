import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import language from '../../../helpers/language'
import DashboardSideBar from './DashboardSideBar'
import { getCustomerDetailsById } from '../modules/marketplace';

export default function CustomerManagementDetail() {
    const { id } = useParams();
    const history = useHistory();
    const [lang] = useState(language.getLang());
    const [activeTab, setActiveTab] = useState("customer-management");
    const [loader, setLoader] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({});

    useEffect(() => {
        getCustomerDetails();
    }, [id])

    const selectTab = async (tab) => {
        setActiveTab(tab);
    }

    const getCustomerDetails = async () => {
        setLoader(true);
        try {
            const response = await getCustomerDetailsById(id);
            if (response?.success === 200) {
                setCustomerDetails(response?.customer ?? {});
                setLoader(false);
            } else {
                setCustomerDetails([]);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            console.log(error)
        }
    }


    const { address, company_name, country_code, created_at, orderCancelled, orderCompleted, phone, profile_percentage, email } = customerDetails ?? {};
    return (
        <>
            <div className='servics_blocks dashboardView customer_mgmt'>
                <div className='site-align top-align'>
                    <div className='left__section-block sm:mb-2 md:mb-2'>
                        <div className='tabLeftBlock'>
                            <DashboardSideBar lang={lang} selectTab={selectTab} activeTab={activeTab} id={id} />
                        </div>
                    </div>
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>
                        <div className="product-title">
                            <div className="flex flex-wrap justify-between items-center">
                                <h4 className="mb-0">
                                    {lang?.Customer_details}
                                </h4>
                                <div className="inner-btn-box">
                                    {/* <Link to="#" className="btn btn-red">
                                        <i className="fa fa-ban" aria-hidden="true"></i>
                                        Block User
                                  </Link>*/}
                                    <Link to="#" className="btn btn--gray" onClick={() => history.goBack()}>
                                        <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                                            <path d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z" fill="black" />
                                        </svg>
                                        {lang?.Back}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {(Object.keys(customerDetails)?.length > 0) ? <div className="product-list">
                            <div className='recentOrder secBlockGap'>
                                <div className='showData'>
                                    {/* <div className='flex flex-wrap main-row'>
                                        <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                            <div className='formData'>
                                                <p>Customer Name</p>
                                                <div className='dataControl'>
                                                    Customer Name
                                                </div>
                                            </div>
                                            <div className='formData'>
                                                <p>Customer Email</p>
                                                <div className='dataControl'>
                                                    customername@gmail.com
                                                </div>
                                            </div>
                                            <div className='formData'>
                                                <p>Joining Date</p>
                                                <div className='dataControl'>
                                                    22/06/2023 10:30 AM
                                                </div>
                                            </div>
                                        </div>
                                        <div className='xl:w-6/12 lg:w-6/12 md:w-full sm:w-full col-padding'>
                                            <div className='formData'>
                                                <p>Customer Contact</p>
                                                <div className='dataControl'>
                                                    +91 1234567890
                                                </div>
                                            </div>
                                            <div className='formData'>
                                                <p>Customer Address</p>
                                                <div className='dataControl address'>
                                                    Test Test 
                                                    south street
                                                    chicago
                                                    60564
                                                    Illinois
                                                    United States
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gridRow3'>
                                        <div className='formData'>
                                            <p>Status</p>
                                            <div className='dataControl'>
                                                Active
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>Total Number of Orders Completed</p>
                                            <div className='dataControl'>
                                                10
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>Total Number of Orders Canceled</p>
                                            <div className='dataControl'>
                                                5
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='gridRow2'>
                                        <div className='formData'>
                                            <p>{lang?.Customer_Name}</p>
                                            <div className='dataControl'>
                                                {company_name}
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>{lang?.Customer_contact}</p>
                                            <div className='dataControl'>
                                                {`+${country_code} ${phone}`}
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>{lang?.Customer_email}</p>
                                            <div className='dataControl'>
                                                {email}
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>{lang?.Customer_address}</p>
                                            <div className='dataControl'>
                                                {address}
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>{lang?.Joining_date}</p>
                                            <div className='dataControl'>
                                                {created_at}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gridRow3'>
                                        <div className='formData'>
                                            <p>{lang?.Status}</p>
                                            <div className='dataControl'>
                                                {profile_percentage === 100 ? lang?.Active_user : lang?.Inactive_user}
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>{lang?.Total_number_of_order_completed}</p>
                                            <div className='dataControl'>
                                                {orderCompleted}
                                            </div>
                                        </div>
                                        <div className='formData'>
                                            <p>{lang?.Total_number_of_order_canceled}</p>
                                            <div className='dataControl'>
                                                {orderCancelled}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Filter Data */}
                                {/* <div className='productFilter mb--30'>
                                    <div className='searchBar form--group'>
                                        <input type='text' className='search' placeholder='Order ID' />
                                    </div>
                                    <div className='searchBar form--group'>
                                        <input type='text' className='search' placeholder='Customer' />
                                    </div>
                                    <div className='categoryList form--group'>
                                        <select>
                                            <option>Order Status</option>
                                            <option>Order Status 1</option>
                                            <option>Order Status 2</option>
                                        </select>
                                    </div>
                                    <div className='categoryList form--group'>
                                        <input type='date' />
                                    </div>
                                    <div className='btn btn--gray'>
                                        <button type='button' className='btn'>
                                            <i className="fa fa-filter" aria-hidden="true"></i>
                                            Filter
                                        </button>
                                    </div>
                                </div> */}
                                {/* table Data */}
                                {/* <div className='custom__tab'>
                                    <Tabs>
                                        <TabList>
                                            <Tab>All</Tab>
                                            <Tab>New Orders</Tab>
                                            <Tab>Ongoing</Tab>
                                            <Tab>Past Orders</Tab>
                                        </TabList>

                                        <TabPanel className="custom__tab__data">
                                            <div className='itemTable'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <div className="form-checkbox-contents">
                                                                    <div className="hub--checkbox--area">
                                                                        <input className='form-check-input' type="checkbox" id="check_box1" name="select_item" />
                                                                        <label className="form-check-label" htmlFor="check_box1"></label>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th>Order ID</th>
                                                            <th>Customer Name</th>
                                                            <th>Product</th>
                                                            <th>Category</th>
                                                            <th>Date</th>
                                                            <th>Quantity</th>
                                                            <th>Total</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="form-checkbox-contents">
                                                                    <div className="hub--checkbox--area">
                                                                        <input className='form-check-input' type="checkbox" id="check_box2" name="select_item" />
                                                                        <label className="form-check-label" htmlFor="check_box2"></label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>111</td>
                                                            <td>Customer Name</td>
                                                            <td>Name</td>
                                                            <td>Category name</td>
                                                            <td>21/06/2023</td>
                                                            <td>10</td>
                                                            <td>$99.00</td>
                                                            <td className='newOrder'>
                                                                <select>
                                                                    <option>New order</option>
                                                                    <option>New order 2</option>
                                                                    <option>New order 3</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className='iconGroup'>
                                                                    <span className=''>
                                                                        <img src={action_img} alt="Action" />
                                                                    </span>
                                                                    <span className=''>
                                                                        <img src={check_img} alt="Action" />
                                                                    </span>
                                                                    <span className=''>
                                                                        <img src={cross_img} alt="Action" />
                                                                    </span>
                                                                    <span className=''>
                                                                        <img src={file_img} alt="Action" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className="custom__tab__data">
                                            New Orders Data
                                        </TabPanel>
                                        <TabPanel className="custom__tab__data">
                                            Ongoing
                                        </TabPanel>
                                        <TabPanel className="custom__tab__data">
                                            Past Orders
                                        </TabPanel>
                                    </Tabs>
                                </div> */}
                            </div>
                        </div> : <p className='text-center'>{loader ? lang?.Loading : lang?.No_data_found}</p>}
                    </div>
                </div>
            </div>
        </>
    )
}
