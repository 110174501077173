import { debounce } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import FormSpinner from "../../../components/Spinners/FormSpinner";
import language from '../../../helpers/language';
import { fetchIngredientsSearch, fetchPreferences, submitSelectPreferencesData, tabIndexFun } from '../modules/RecipeModule'

export default function MyPreferences(props) {
    const [lang] = useState(language.getLang());
    const dispatch = useDispatch()
    const [myPreference, setMyPreference] = useState([])
    const [foodId, setFoodId] = useState([]);
    const [dietsId, setDietsId] = useState([]);
    const [ingredientsId, setIngredientsId] = useState([])
    const [selectAllData, setSelectAlleData] = useState([]);
    const [cuisineId, setCuisineId] = useState([]);
    const [cooksId, setCooksId] = useState([])
    const [formErrors, setFormErrors] = useState('');
    const [searchData, setSearchData] = useState();
    const [selectedStatus, setSelectedStatus] = useState(false)
    const [isUpdatePre, setIsUpdatePre] = useState(false)
    const [updatePreferencesSpinner, setupdatePreferencesSpinner] = useState(false)
    const [isAddMore, setIsAddMore] = useState({ _id: 0, show: false });
    const { recipePreference, myPreferencesPercentageSpinner, recipePreferenceIngredient } = useSelector((state) => state.recipedata);

    useEffect(async () => {
        // await dispatch(tabIndexFun(3));
        await setSearchData(recipePreferenceIngredient?.maps);
    }, [])

    useEffect(async () => {
        setMyPreference(recipePreference)
        recipePreference && recipePreference.map(async (item) => {
            await item && item.maps.length > 0 && item.maps.map(async (val) => {
                if (item.preference_id === 1 && val.is_selected === true) cuisineId.push(val.cousin_id) && setCuisineId(cuisineId);
                else if (item.preference_id === 2 && val.is_selected === true) foodId.push(val.recipe_food_intolerance_id) && setFoodId(foodId);
                else if (item.preference_id === 3 && val.is_selected === true) dietsId.push(val.recipe_diet_id) && setDietsId(dietsId);
                else if (item.preference_id === 4 && val.is_selected === true) ingredientsId.push(val.recipe_ingredient_id) && setIngredientsId(ingredientsId);
                else if (item.preference_id === 5 && val.is_selected === true) cooksId.push(val.recipe_cooking_skill_id) && setCooksId(cooksId);
            })
            if (item.preference_id === 1) await selectAllData.push({ preference: item.preference_id, id: cuisineId })
            else if (item.preference_id === 2) await selectAllData.push({ preference: item.preference_id, id: foodId });
            else if (item.preference_id === 3) await selectAllData.push({ preference: item.preference_id, id: dietsId });
            else if (item.preference_id === 4) await selectAllData.push({ preference: item.preference_id, id: ingredientsId });
            else if (item.preference_id === 5) await selectAllData.push({ preference: item.preference_id, id: cooksId });
            await setSelectAlleData(selectAllData)
        })
    }, [recipePreference]);

    const handlePreferences = async (event, item, preferenceId) => {
        setIsUpdatePre(true)
        const isChecked = event.target.checked
        if (preferenceId === 2) preferenceFoodIntolerance(item, preferenceId, isChecked)
        else if (preferenceId === 3) preferenceDiet(item, preferenceId, isChecked)
        else if (preferenceId === 4) preferenceIngredient(item, preferenceId, isChecked)
        else if (preferenceId === 5) preferenceCooking(item, preferenceId, isChecked)
    }

    const preferenceFoodIntolerance = async (item, preferenceId, checkedStatus) => {
        await setSelectedStatus(checkedStatus)
        await myPreference && myPreference.length > 0 && myPreference.map((val) => { val.maps.map((v) => { if (v.recipe_food_intolerance_id === item.recipe_food_intolerance_id) return v.is_selected = checkedStatus }) })
        await setMyPreference(myPreference)
        if (checkedStatus) await foodId.push(item.recipe_food_intolerance_id);
        else {
            const index = foodId.indexOf(item.recipe_food_intolerance_id)
            if (index > -1) await foodId.splice(index, 1)
        }
        await selectAllData && selectAllData.map((res) => { if (res.preference === preferenceId) return res.id = foodId })
        await setSelectAlleData(selectAllData)
        await setSelectedStatus(!checkedStatus)
    }

    const preferenceDiet = async (item, preferenceId, checkedStatus) => {
        await setSelectedStatus(checkedStatus)
        await myPreference && myPreference.length > 0 && myPreference.map((val) => { val.maps.map((v) => { if (v.recipe_diet_id === item.recipe_diet_id) return v.is_selected = checkedStatus }) })
        setMyPreference(myPreference)
        if (checkedStatus) await dietsId.push(item.recipe_diet_id)
        else {
            const index = dietsId.indexOf(item.recipe_diet_id)
            if (index > -1) await dietsId.splice(index, 1)
        }
        await selectAllData && selectAllData.map((res) => { if (res.preference === preferenceId) return res.id = dietsId })
        await setSelectAlleData(selectAllData)
        await setSelectedStatus(!checkedStatus)
    }

    const preferenceIngredient = async (item, preferenceId, checkedStatus) => {
        await setSelectedStatus(checkedStatus)
        await myPreference && myPreference.length > 0 && myPreference.map((val) => { val.maps.map((v) => { if (v.recipe_ingredient_id === item.recipe_ingredient_id) return v.is_selected = checkedStatus }) })
        setMyPreference(myPreference)
        if (checkedStatus) await ingredientsId.push(item.recipe_ingredient_id)
        else {
            const index = ingredientsId.indexOf(item.recipe_ingredient_id)
            if (index > -1) await ingredientsId.splice(index, 1)
        }
        await selectAllData && selectAllData.map((res) => { if (res.preference === preferenceId) return res.id = ingredientsId })
        await setSelectAlleData(selectAllData)
        await setSelectedStatus(!checkedStatus)
    }

    const preferenceCooking = async (item, preferenceId, checkedStatus) => {
        await setSelectedStatus(checkedStatus)
        await myPreference && myPreference.length > 0 && myPreference.map((val) => { val.maps.map((v) => { if (v.recipe_cooking_skill_id === item.recipe_cooking_skill_id) return v.is_selected = checkedStatus }) })
        setMyPreference(myPreference)
        if (checkedStatus) await cooksId.push(item.recipe_cooking_skill_id)
        else {
            const index = cooksId.indexOf(item.recipe_cooking_skill_id)
            if (index > -1) await cooksId.splice(index, 1)
        }
        await selectAllData && selectAllData.map((res) => { if (res.preference === preferenceId) return res.id = cooksId })
        await setSelectAlleData(selectAllData)
        await setSelectedStatus(!checkedStatus)
    }


    const savePreferences = async () => {
        if (selectAllData[4].preference === 5 && selectAllData[4].id <= 0) {
            await setFormErrors(lang?.please_select_at_one_cooking_tool);
        } else {
            await submitSelectPreferencesData({ params: selectAllData }).then(async (res) => {
                setupdatePreferencesSpinner(false)
                if (res && res.success === 200) await dispatch(fetchPreferences()).then(async (res) => { if (res && res.success === 200) setMyPreference(res.data) })
                setIsAddMore({ show: !isAddMore.show })
                setIsUpdatePre(true)
            });
        }
        setTimeout(() => {
            setFormErrors('');
        }, 5000);

    }

    const searchIngredient = debounce(async (keywords) => {
        if (keywords.trim() !== '' || keywords !== undefined) {
            let SeachDataIng = recipePreferenceIngredient.maps.filter(item => item.title?.toLowerCase()?.includes(keywords.trim()?.toLowerCase()));
            setSearchData(SeachDataIng);
        } else {
            setSearchData(recipePreferenceIngredient.maps);
        }
    }, 1000);

    return (
        <Fragment>
            {<h4 style={{ color: "red", textAlign: "center" }}>{formErrors}</h4>}
            <div className="section__wrapper">
            {console.log(myPreference,"myPreference")}
                <div className='RightBtn text-right ' onClick={() => savePreferences()} > {isUpdatePre && <Link to="#" className='btn bgTealBtn'>{updatePreferencesSpinner ? <FormSpinner /> : `${lang?.Update_preferences}`}</Link>} </div>
                {myPreferencesPercentageSpinner ? <FormSpinner /> :
                    myPreference && myPreference.length > 0 && myPreference.map((data) => {
                        return (
                            <div className="site__width">
                                <div className="tab__row">
                                    <div className={(data.preference_id != 2) ? (data.preference_id == 3) || (data.preference_id === 5) ? "banner_slider tab__recipe foodIntolerance" : "banner_slider tab__recipe" : "banner_slider tab__recipe foodIntolerance"}>
                                        <div className='section_heading'> <h6 className="section_title">{data.name}</h6> </div>
                                        <ul className='Preferences flex-wrap'>
                                            {/* ---------selected ingredents--------------- */}
                                            {data.maps.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            selectedStatus ?
                                                                <Fragment>
                                                                    {item.is_selected &&
                                                                        <li>
                                                                            <input className="form-check-input" defaultChecked type="checkbox" id={item && item.image_id && item.image_id.id}
                                                                                onChange={(event) => handlePreferences(event, item, data.preference_id)} disabled={data.preference_id === 1}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={item && item.image_id && item.image_id.id}>
                                                                                <div className="slider_img"> <img src={`${item.image_id.base_url}${item.image_id.attachment_url}`} /> </div>
                                                                                <div className="checked_bg"> </div>
                                                                                <div className='banner__title'><span>{item.name ?? item.title}</span></div>
                                                                            </label>
                                                                        </li>
                                                                    }
                                                                </Fragment> :
                                                                <Fragment>
                                                                    {item.is_selected &&
                                                                        <li>
                                                                            <input className="form-check-input" defaultChecked type="checkbox" id={item && item.image_id && item.image_id.id}
                                                                                onChange={(event) => handlePreferences(event, item, data.preference_id)} disabled={data.preference_id === 1}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={item && item.image_id && item.image_id.id}>
                                                                                <div className="slider_img"> <img src={`${item.image_id.base_url}${item.image_id.attachment_url}`} /> </div>
                                                                                <div className="checked_bg"> </div>
                                                                                <div className='banner__title'><span>{item.name ?? item.title}</span></div>
                                                                            </label>
                                                                        </li>
                                                                    }
                                                                </Fragment>
                                                        }


                                                    </Fragment>
                                                )
                                            })
                                            }

                                            {/* .......Add more......... */}
                                            {data.preference_id !== 1 && data.preference_id !== 4 &&
                                                <li>
                                                    {<div className="" onClick={() => setIsAddMore({ _id: data.preference_id, show: !isAddMore.show })}>
                                                        <div className="add__more__icon">
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </div>
                                                        <h5>{lang?.Add_More}</h5>
                                                    </div>}
                                                </li>
                                            }
                                            {data.preference_id === 4 &&
                                                <li>
                                                    {<div className="" onClick={() => setIsAddMore({ _id: data.preference_id, show: !isAddMore.show })}>
                                                        <div className="add__more__icon">
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </div>
                                                        <h5>{lang?.Add_More}</h5>
                                                    </div>}
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {isAddMore && isAddMore.show && isAddMore._id === data.preference_id &&
                                    <>{parseInt(data.preference_id) === 4 ?
                                        <div className="recipe_subgroup banner_slider tab__recipe">
                                            <div className='banner__search__form'>
                                                {data.preference_id === 4 && <div className="banner__search-form">
                                                    {/* <h4>{lang?.What_are_you_craving}</h4> */}
                                                    <form onSubmit={(event) => event.preventDefault()}>
                                                        <div className="top-search">
                                                            <span className="search_icon">
                                                                <i className="fas fa-search" />
                                                            </span>
                                                            <input type="text" placeholder="Search for recipes" onChange={(event) => searchIngredient(event.target.value)} />
                                                            {/* <span className="filter_icon">
                                                            <Link to='#' >
                                                                <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 24 22"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M11 0C9.70703 0 8.60547 0.84375 8.1875 2H0V4H8.1875C8.60547 5.15625 9.70703 6 11 6C12.293 6 13.3945 5.15625 13.8125 4H24V2H13.8125C13.3945 0.84375 12.293 0 11 0ZM11 2C11.5625 2 12 2.4375 12 3C12 3.5625 11.5625 4 11 4C10.4375 4 10 3.5625 10 3C10 2.4375 10.4375 2 11 2ZM18 8C16.707 8 15.6055 8.84375 15.1875 10H0V12H15.1875C15.6055 13.1562 16.707 14 18 14C19.293 14 20.3945 13.1562 20.8125 12H24V10H20.8125C20.3945 8.84375 19.293 8 18 8ZM18 10C18.5625 10 19 10.4375 19 11C19 11.5625 18.5625 12 18 12C17.4375 12 17 11.5625 17 11C17 10.4375 17.4375 10 18 10ZM7 16C5.70703 16 4.60547 16.8438 4.1875 18H0V20H4.1875C4.60547 21.1562 5.70703 22 7 22C8.29297 22 9.39453 21.1562 9.8125 20H24V18H9.8125C9.39453 16.8438 8.29297 16 7 16ZM7 18C7.5625 18 8 18.4375 8 19C8 19.5625 7.5625 20 7 20C6.4375 20 6 19.5625 6 19C6 18.4375 6.4375 18 7 18Z"
                                                                        fill="#5B5B5B"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </span> */}
                                                        </div>
                                                    </form>
                                                </div>}
                                            </div>
                                            <div className="banner_slider tab__recipe">
                                                {/* <div className='seeAll mobileBtn'>
                                                    <Link href='#'>See All</Link>
                                                </div> */}
                                                <ul className='flex-wrap'>{
                                                    searchData?.map((item, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                {item.is_selected === false &&
                                                                    <li>
                                                                        <input className="form-check-input" type="checkbox" id={item && item.image_id && item.image_id.id}
                                                                            onChange={(event) => handlePreferences(event, item, data.preference_id)}
                                                                            disabled={data.preference_id === 5 && cooksId.length > 0}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={item && item.image_id && item.image_id.id}>
                                                                            <div className="slider_img"><img src={`${item.image_id.base_url}${item.image_id.attachment_url}`} /></div>
                                                                            <div className="checked_bg"> </div>
                                                                            <div className='banner__title'><span>{item.title ?? item.name}</span></div>
                                                                        </label>
                                                                    </li>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                </ul>
                                            </div>
                                        </div> :
                                        // <div className={(data.name !== "Food intolerance") ? (data.name === "Follow any diet") ? "recipe_subgroup banner_slider tab__recipe foodIntolerance" : "recipe_subgroup banner_slider tab__recipe" : "recipe_subgroup banner_slider tab__recipe foodIntolerance"}>
                                        <div className={(parseInt(data.preference_id) !== 1 && parseInt(data.preference_id) !== 4) ? "recipe_subgroup banner_slider tab__recipe foodIntolerance" : "recipe_subgroup banner_slider tab__recipe"}>
                                            <div className="banner_slider tab__recipe">
                                                <ul className='flex-wrap'>{
                                                    data && data.maps.map((item, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                {item.is_selected === false &&
                                                                    <li>
                                                                        <input className="form-check-input" type="checkbox" id={item && item.image_id && item.image_id.id}
                                                                            onChange={(event) => handlePreferences(event, item, data.preference_id)}
                                                                            disabled={data.preference_id === 5 && cooksId.length > 0}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={item && item.image_id && item.image_id.id}>
                                                                            <div className="slider_img"><img src={`${item.image_id.base_url}${item.image_id.attachment_url}`} /></div>
                                                                            <div className="checked_bg"> </div>
                                                                            <div className='banner__title'><span>{item.name ?? item.title}</span></div>
                                                                        </label>
                                                                    </li>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    </>

                                }
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    );
}

