import { Fragment, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import AuthHeader from "../../../components/Headers/AuthHeader";
import React from "react";
import Footer from "../../../components/Footer/Footer";
import { fetchUserRoles } from "../modules/auth";
import { useSelector, useDispatch } from "react-redux";
import LoginHeader from "../../../components/Headers/loginHeader";

export function AuthWrapperView({ children, route }) {
  const dispatch = useDispatch();
  useEffect(async () => {
    // await dispatch(fetchUserRoles());
    const str = window.location.href;
    const getUrl = str.split('/');
    const bodyUrl = getUrl[getUrl.length - 1]
    document.body.classList.add(bodyUrl)
  }, []);

  return (
    <Fragment>
      {route.path === "/login" ? <LoginHeader /> : <AuthHeader />}
      <div className="main__content footer">
        <div className="site__width">
          {children}
          {renderRoutes(route.routes)}
        </div>
        {/* <Footer /> */}
      </div>
      <Footer />
    </Fragment>
  );
}

export default AuthWrapperView;
