import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import auth from '../../../helpers/auth';
import constant from "../../../helpers/constant";
import NetworkLeftNavigation from "./NetworkLeftNavigation";
import { fetchConnectionTabs, followUnfollowUser } from "../modules/networkConnection";
import NotFound from "../../../components/NetworkConnection/NotFound";
import FormSpinner from '../../../components/Spinners/FormSpinner';
import ReactPaginate from 'react-paginate';
import language from '../../../helpers/language';

export default function Following() {
    const [lang] = useState(language.getLang());
    const history = useHistory();
    const dispatch = useDispatch();
    const [userType, setuserType] = useState();
    const [page, setPage] = useState(1);
    const [isTrue, setIsTrue] = useState(false);

    useEffect(async () => {
        const userType = await auth.getUserType()
        await setuserType(JSON.parse(userType))
        await dispatch(fetchConnectionTabs(constant.tabs.followers, page));
        await setIsTrue(true);
    }, [])

    const { connectionSpinner, connectionTabs } = useSelector((state) => state.networkConnectionState);
    const unfollowConnection = async(followId) => {
        //alert(followId);
        const model = {
            follow_user_id: parseInt(followId),
            follow_or_unfollow: 0
          }
        await dispatch(followUnfollowUser(model));
        await dispatch(fetchConnectionTabs(constant.tabs.followers, page));
        await setIsTrue(true);

    }

    const handlePageClick = async (e) => {
        let pageNo = e.selected + 1
        await setPage(page);
        await dispatch(fetchConnectionTabs(constant.tabs.followers, pageNo));
    };
    return (
        <div className="home__page b2b hubMember">
            <div className="servics_blocks">
                <div className="site-align top-align">
                    {/* Left Block */}
                    <NetworkLeftNavigation />
                    {/* Middle Block */}
                    <div className="servics_blocks middle-long__section-block middleBlockBg">
                        <div className="">
                            {!connectionSpinner && connectionTabs && connectionTabs.errors ?
                                <Fragment><div className="medium_title"><h1 className="font-18 text-black">{userType && userType.role_id === constant.rolesId.voyagers ? `${lang?.Following}` : `${lang?.Followers}`}</h1></div><NotFound errors={connectionTabs.errors.exception} /></Fragment>
                                : <div className="medium_title"><h1 className="font-18 text-black">{userType && userType.role_id === constant.rolesId.voyagers ? `${lang?.Following}` : `${lang?.Followers}`}</h1></div>
                            }
                            {connectionSpinner ? <FormSpinner /> :
                                <div className="card--section site-align top-align flex-wrap">
                                    {connectionTabs?.data?.data.length > 0 && connectionTabs?.data?.data?.map((item, index) => {
                                        //console.log(item.following_by)
                                        return (
                                            <div className="card card--block" key={index} >
                                                <div className="hubUser">
                                                    {item?.followed_by ? <div className="feed-user-info mb-2" onClick={() => history.push(`${"/visitor-profile/"}${item.followed_by.user_id}`)}>
                                                        <div className="user__pic text">
                                                            {item && item.followed_by && item.followed_by.avatar_id ?
                                                                <img src={`${item.followed_by.avatar_id.base_url}${item.followed_by.avatar_id.attachment_medium_url}`} alt="User Image" /> :
                                                                {/* <ContentLoader viewBox="0.2 0 78 78" speed={2} width={298} height={298} backgroundColor="#bababa" foregroundColor="#a29a9a">
                                                                <circle cx="20" cy="20" r="20" />
                                                                </ContentLoader> */}
                                                            }
                                                        </div>
                                                        <div className="user__detail-data">
                                                            <span className="user__name">
                                                            {item && item.followed_by && item.followed_by ? 
                                                                <Link to="#">
                                                                    {(item && item.followed_by && item.followed_by.first_name !== null && item && item.followed_by && item.followed_by.last_name !== null &&
                                                                        `${item && item.followed_by && item.followed_by.first_name} ${item && item.followed_by && item.followed_by.last_name}`) ||
                                                                        (item && item.followed_by && item.followed_by.company_name) || (item && item.followed_by && item.followed_by.restaurant_name)
                                                                    }
                                                                </Link>
                                                                :
                                                                {/* <ContentLoader viewBox="0 0 380 70">
                                                               <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                                </ContentLoader> */}
                                                            }
                                                            </span>
                                                            <div className="flex">
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.producer && <span>{lang?.Italian_F_and_B_producers}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.importer && <span>{lang?.only_Importer}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.distributer && <span>{lang?.only_Distributor}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId["importer&distributer"] && <span>{lang?.Importer_And_Distributor}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.voiceOfExpert && <span>{lang?.Voice_Of_Experts}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.travelAgencies && <span>{lang?.Travel_Agencies}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.restaurant && <span>{lang?.Italian_Restaurants_in_US}</span>}
                                                                {item.followed_by && item.followed_by.role_id === constant.rolesId.voyagers && <span>{lang?.Voyagers}</span>}
                                                                {/* <span className="text-blue"> , {!item.follower_count ? 0 : item.follower_count} Followers</span> */}
                                                            </div>
                                                        </div>
                                                    </div> :
                                                        <div className="feed-user-info mb-2" onClick={() => history.push(`${"/visitor-profile/"}${item.following_by.user_id}`)}>
                                                            <div className="user__pic best">
                                                                {item && item?.following_by && item?.following_by.avatar_id ?
                                                                    <img src={`${item.following_by.avatar_id.base_url}${item.following_by.avatar_id.attachment_medium_url}`} alt="User Image" /> :
                                                                    <img src="https://alysei.s3.us-west-1.amazonaws.com/uploads/2021/12/6971696IMG_1640327050627.jpg" alt="User Image" />
                                                                }
                                                            </div>
                                                            <div className="user__detail-data">
                                                                <span className="user__name">
                                                                    <Link to="#">
                                                                        {(item && item.following_by && item.following_by.first_name !== null && item && item.following_by && item.following_by.last_name !== null &&
                                                                            `${item && item.following_by && item.following_by.first_name} ${item && item.following_by && item.following_by.last_name}`) ||
                                                                            (item && item.following_by && item.following_by.company_name) || (item && item.following_by && item.following_by.restaurant_name)
                                                                        }
                                                                    </Link>
                                                                </span>
                                                                <div className="flex">
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.producer && <span>{lang?.Italian_F_and_B_producers}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.importer && <span>{lang?.only_Importer}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.distributer && <span>{lang?.only_Distributor}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId["importer&distributer"] && <span>{lang?.Importer_And_Distributor}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.voiceOfExpert && <span>{lang?.Voice_Of_Experts}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.travelAgencies && <span>{lang?.Travel_Agencies}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.restaurant && <span>{lang?.Italian_Restaurants_in_US}</span>}
                                                                    {item.following_by && item.following_by.role_id === constant.rolesId.voyagers && <span>{lang?.Voyagers}</span>}
                                                                    {/* <span className="text-blue"> , {!item.follower_count ? 0 : item.follower_count} Followers</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="viewReq mt-4">
                                                    {
                                                        userType && userType.role_id === constant.rolesId.voyagers && 
                                                        <Link to="#" className="btn bg-Primary w-full" onClick={() => unfollowConnection(item.follow_user_id)}>{lang?.Unfollow}</Link>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                            }
                        </div>
                        {isTrue && connectionTabs?.data?.total > 10 &&
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil((connectionTabs?.data?.total) / (connectionTabs?.data?.per_page))}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"} />}
                    </div>
                    {/* Right Block */}
                </div>
            </div>
        </div>
    )
}
