import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import SettingLeftNavigation from './SettingLeftNavigation'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import language from '../../../helpers/language';

export default function SettingBilling() {
  const [lang] = useState(language.getLang());

  useEffect(() => {
    //window.fcWidget.show() 
  },[]);
    return (
        <div>
          {/* Layout Content */}
          <div className="home__page b2b hubMember settings">
            <div className="servics_blocks">
                <div className="site-align top-align">
                  <SettingLeftNavigation />
                  <div className="servics_blocks middle-long__section-block middleBlockBg">
                    <div className="medium_title"><h1 className="font-18 text-black">{lang?.Billing}</h1></div>
                    {/* <div className='md:w-3/4'>
                        <div className='billingTab mb-4'>
                            <Tabs className="">
                                <TabList className="TabUpperList">
                                    <Tab className="TabInnerList">{lang?.Billing_Details}</Tab>
                                    <Tab className="TabInnerList">{lang?.Orders}</Tab>
                                </TabList>

                                <TabPanel>
                                    <h2>{lang?.Billing_Detail_comming_soon}</h2>
                                </TabPanel>
                                <TabPanel>
                                    <h2>{lang?.Orders_comming_soon}</h2>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div> */}
                    <p className='text-sm font-bold'>
                      Payment Method
                    </p>
                    <div className='grid grid-cols-2 gap-3 mt-3'>
                    <div className='card bg-gray-100 w-full border border-gray-200 p-4 rounded-md'>
                      <div className='flex gap-3 items-center'>
                      <div className='card-icon'>
                        
                        <svg width="80" height="80" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M69.3333 21.6665C65.3499 21.6665 61.4055 22.4511 57.7253 23.9755C54.0451 25.4999 50.7011 27.7342 47.8844 30.5509C45.0677 33.3676 42.8334 36.7116 41.309 40.3918C39.7846 44.072 39 48.0164 39 51.9998C39 55.9833 39.7846 59.9277 41.309 63.6079C42.8334 67.2881 45.0677 70.632 47.8844 73.4487C50.7011 76.2655 54.0451 78.4998 57.7253 80.0242C61.4055 81.5486 65.3499 82.3332 69.3333 82.3332C73.3168 82.3332 77.2612 81.5486 80.9414 80.0242C84.6216 78.4998 87.9655 76.2655 90.7822 73.4487C93.5989 70.632 95.8333 67.2881 97.3577 63.6079C98.8821 59.9277 99.6667 55.9833 99.6667 51.9998C99.6667 48.0164 98.8821 44.072 97.3577 40.3918C95.8333 36.7116 93.5989 33.3676 90.7822 30.5509C87.9655 27.7342 84.6216 25.4999 80.9414 23.9755C77.2612 22.4511 73.3168 21.6665 69.3333 21.6665Z" fill="#FF9800"/>
                        <path d="M34.6663 21.6665C30.6829 21.6665 26.7385 22.4511 23.0583 23.9755C19.3781 25.4999 16.0341 27.7342 13.2174 30.5509C10.4007 33.3676 8.16639 36.7116 6.642 40.3918C5.1176 44.072 4.33301 48.0164 4.33301 51.9998C4.33301 55.9833 5.1176 59.9277 6.642 63.6079C8.16639 67.2881 10.4007 70.632 13.2174 73.4487C16.0341 76.2655 19.3781 78.4998 23.0583 80.0242C26.7385 81.5486 30.6829 82.3332 34.6663 82.3332C38.6498 82.3332 42.5942 81.5486 46.2744 80.0242C49.9546 78.4998 53.2985 76.2655 56.1152 73.4487C58.932 70.632 61.1663 67.2881 62.6907 63.6079C64.2151 59.9277 64.9997 55.9833 64.9997 51.9998C64.9997 48.0164 64.2151 44.072 62.6907 40.3918C61.1663 36.7116 58.932 33.3676 56.1152 30.5509C53.2985 27.7342 49.9546 25.4999 46.2744 23.9755C42.5942 22.4511 38.6498 21.6665 34.6663 21.6665Z" fill="#D50000"/>
                        <path d="M39 51.9998C39 62.3023 44.148 71.3915 52 76.8731C59.852 71.3915 65 62.3023 65 51.9998C65 41.6973 59.852 32.6081 52 27.1265C44.148 32.6081 39 41.6973 39 51.9998Z" fill="#FF3D00"/>
                        </svg>
                        
                                              </div>
                                              <div className='card-info'>
                                              <div className='card-details'>
                                                <h4 className='font-bold text-base'>Master Card <span className='card-number'>****1234</span></h4>
                                                <p className='text-xs text-gray-400'>Expires 07/27</p>
                                              </div>
                                              
                                              </div>
                      </div>
                      <div className='card-edit-btns flex justify-end gap-10'>
                        <Link>Remove</Link>
                        <Link>Edit</Link>
                                              </div>
                      
                    </div>
                    <div className='card bg-gray-100 w-full border border-gray-200 p-4 rounded-md add-method-card flex justify-center items-center'>
                    
                      <Link className='text-base'><i className='fa fa-plus text-sm mr-1'></i>Add Payment Method</Link>
                     
                      
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      );
}
