import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import {  fetchAllDiscoverAlysei } from '../../home/modules/HomeModule';
import moment from 'moment';
import Modal from "react-modal";
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import {  postYourFeedBack } from '../modules/DiscoverModule';
import language from '../../../helpers/language';
import Parse from 'html-react-parser';


export default function ViewBlog() {
    const [sendfeedback, setSendFeedback] = useState(false);
    const dispatch = useDispatch();
    let { state } = useLocation();
    const history = useHistory();
    const { blogId, id } = useParams();
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [userPhotosData, setUserPhotosData] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [lang] = useState(language.getLang());
    const [blogData, setBlogData] = useState();
    const [isSuccess, setIsSuccess] = useState('');
    const [isTrue, setIsTrue] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setcomment] = useState('');

    const { editBlogData: { data }, blogSpinner, discoverAlyseiData } = useSelector((state) => state.activityFeedStatus);
    const { createdBlogtData } = useSelector((state) => state.discoverReducerState);
   
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        displayBlogData(state?.isState);
    }, [discoverAlyseiData, createdBlogtData, blogId]);

    const displayBlogData = async (state) => {
        if (state === 1) {
            return createdBlogtData?.filter(item => parseInt(item.blog_id) === parseInt(blogId))?.map(async (item) => {
                await setBlogData(item);
                //await setName(item?.user?.company_name ?? item?.user?.restaurant_name ?? `${item?.user?.first_name} ${item?.user?.last_name}`);
                //await setEmail(item?.user?.email);
            })
        } else if (state === 2) {
            if(discoverAlyseiData != ''){
                console.log('ot empty');
                return discoverAlyseiData?.data?.data?.map(async (item) => {
                    if (parseInt(item.blog_id) === parseInt(blogId)) {
                        await setBlogData(item);
                        //await setName(item?.user?.company_name ?? item?.user?.restaurant_name ?? `${item?.user?.first_name} ${item?.user?.last_name}`);
                        //await setEmail(item?.user?.email);
                    }
                })
            }
            else{
                return await dispatch(fetchAllDiscoverAlysei("blogs", state?.pageNo)).then(async (res) => {
                    if (res.success === 200) {
                        res?.data?.data?.map(async (item) => {
                            if (parseInt(item.blog_id) === parseInt(blogId)) {
                                await setBlogData(item);
                                //await setName(item?.user?.company_name ?? item?.user?.restaurant_name ?? `${item?.user?.first_name} ${item?.user?.last_name}`);
                                //await setEmail(item?.user?.email);
                            }
                        })
                    }
                })
            }
        }
        else if (state === 3) {
            return await dispatch(fetchAllDiscoverAlysei("blogs", state?.pageNo)).then(async (res) => {
                if (res.success === 200) {
                    res?.data?.data?.map(async (item) => {
                        if (parseInt(item.blog_id) === parseInt(blogId)) {
                            await setBlogData(item);
                            //await setName(item?.user?.company_name ?? item?.user?.restaurant_name ?? `${item?.user?.first_name} ${item?.user?.last_name}`);
                            //await setEmail(item?.user?.email);
                        }
                    })
                }
            })
        }
    }
    const submitYourFeedBack = async () => {
        const response = await dispatch(postYourFeedBack({ params: { name, email, comment, blog_id: blogId } }));
        if (response.success === 200) {
            await setIsSuccess(response.message);
            await setSendFeedback(false);
        } else {
            await setIsTrue(true);
            await setIsSuccess(response?.errors?.exception);
        }
        setTimeout(() => {
            setIsSuccess('');
            setIsTrue(false);
        }, 3000)
    }

     // Check post content has URL
  const Checkurl = (text) => {
    var url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
      url2 = /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;

    var html = text;
    if (html) {
      html = html
        .replace(url1, '$1<a target="_blank"  href="http://$2">$2</a>$3')
        .replace(url2, '$1<a target="_blank"  href="$2">$2</a>$5')
        .replace(/(\w+@\w+.\w+)+/g, "<a href='mailto:$1'>$1</a>");
    }
    return Parse(html?.replace(/\n/g, "<br />"));
  };
// photos gallery
  const handlePhotos = async (blogData) => {
    let userPhotos = [];
      await userPhotos.push({
        url: `${blogData.attachment.base_url}${blogData.attachment.attachment_url}`,
        type: 'photo',
        altTag: 'image',
      });

    await setUserPhotosData(userPhotos);
    await setIsOpenImage(true);
  };
    return (
        <div>
            <div className="discover_page">
                <div className="page_top_form">
                    <div className="blogView flex items-center">
                        <Link to="#" onClick={() => history.goBack()}><i className="fa fa-arrow-left pr-2" aria-hidden="true" ></i> {lang?.View_blog}</Link>
                        
                    </div>
                    <div className="editProfileBtn">
                            <h4 style={{ color: "green" }}>{isSuccess}</h4>
                        </div>
                </div>
                {blogSpinner ? <FormSpinner /> :
                    <div className="discover__events create">
                    {blogData?.user ?
                        <form>
                            <div className='createForm mt-4'>
                                <div className='xl:w-2/5 lg:w-2/5 md:w-full sm:w-full'>
                                    <div className='colLeftBlock'>
                                        <div className="ViewImg" onClick={() =>handlePhotos(blogData)}>
                                            <img src={blogData && blogData.attachment ? `${blogData.attachment.base_url}${blogData.attachment.attachment_url}` : 'https://Linklysei.s3.us-west-1.amazonaws.com/uploads/2022/02/844631643978317.jpeg'} alt='Blog Image' />
                                        </div>
                                        <div className='feed-user-detail mt-4'>
                                            <div className="feed-user-info">
                                                <Link to="#">
                                                    <div className="user__pic">
                                                        <img src={blogData?.user?.avatar_id ? `${blogData.user.avatar_id?.base_url}${blogData.user.avatar_id?.attachment_url}` : 'https://Linklysei.s3.us-west-1.amazonaws.com/uploads/2022/02/844631643978317.jpeg'} alt='Blog Image' />
                                                    </div>
                                                </Link>
                                                <div className="user__detail-data">
                                                    <span className="user__name">
                                                        <Link to={`${'/visitor-profile'}/${blogData?.user_id}`}>{blogData?.user?.first_name} {blogData?.user?.last_name}</Link>
                                                    </span>
                                                    <div className="flex">
                                                        <span>{moment(blogData && blogData.date).format("MMM D, YYYY")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sendfeedback mt-4'>
                                            <Link to="#" className='btn btnTeal' onClick={() => setSendFeedback(!sendfeedback)}>{lang?.Give_Your_FeedBack}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='xl:w-3/5 lg:w-3/5 md:w-full sm:w-full'>
                                    <div className='colRightBlock xl:pl-4 lg:pl-4 md:pl-0 sm:pl-0'>
                                        <div className="form__item"> <label>{lang?.only_title}</label><p className='forINput preField '>{blogData?.title}</p></div>
                                        <div className="form__item"><label>{lang?.Description}</label><p className='forINput preField textArea h-12'>{Checkurl(blogData?.description)}</p></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <FormSpinner />
                    }
                    </div>
                }
                <Modal
                    ariaHideApp={false}
                    isOpen={sendfeedback}
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.75)",
                        },
                        content: {
                            position: "absolute",
                            top: "0",
                            left: "0%",
                            right: "0%",
                            bottom: "200px",
                            border: "1px solid #ccc",
                            background: "#fff",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            padding: "0",
                            // outline: 'none',
                        },
                    }}
                >
                    <div className='upperHeader'>
                        <div className="popup_header flex justify-between items-center">
                            <h4 className="page__title">{lang?.Send_Your_FeedBack_Here}</h4>
                            <button className="close_btn--modal" onClick={() => { setSendFeedback(!sendfeedback); setIsSuccess('') }} > {lang?.close}</button>
                        </div>
                        {isTrue && <p className='font-12 textRed text-center'>{isSuccess}</p>}
                    </div>
                    <div className="card card--block modal_box">
                        <form className="">
                            <div className="form_info">
                                <div className="form__item">
                                    <label htmlFor="rec_name">{lang?.Full_name}</label>
                                    <input type="text" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className="form__item">
                                    <label htmlFor="rec_name">{lang?.Email}</label>
                                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div className="form__item">
                                    <label htmlFor="rec_name">{lang?.Comment}</label>
                                    <textarea placeholder={lang?.write_feedback} onChange={(e) => setcomment(e.target.value)} />
                                </div>
                            </div>
                        </form>
                        <div className="btn_blue_full_w"><button type="button" className="btn" onClick={() => submitYourFeedBack()}>{lang?.only_Submit}</button></div>
                    </div>
                    <div></div>
                </Modal>
            </div>
            {isOpenImage && (
                <div className="image--light--pop">
                <ReactImageVideoLightbox
                    data={userPhotosData}
                    startIndex={imageIndex}
                    showResourceCount={true}
                    onCloseCallback={() => {
                    setIsOpenImage(false);
                    setUserPhotosData([]);
                    setImageIndex(0);
                    }}
                />
                </div>
            )}
        </div>
    )
}
