import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment"
import headerType from "../../../helpers/headerType";
export const GET_ALL_ACTIVITY_POST = "GET_ALL_ACTIVITY_POST";
export const SET_SHARE_POST_SPINNER_STATUS = "SET_SHARE_POST_SPINNER_STATUS";
export const SET_LIKE_STATUS_SPINNER_STATUS = "SET_LIKE_STATUS_SPINNER_STATUS";
export const GET_ALL_ACTIVITY_SPINNER_STATUS = "GET_ALL_ACTIVITY_SPINNER_STATUS";
export const SET_COMMENT_STATUS_SPINNER_STATUS = "SET_COMMENT_STATUS_SPINNER_STATUS";
export const SET_DELETE_POST_STATUS_SPINNER_STATUS = "SET_DELETE_POST_STATUS_SPINNER_STATUS";
export const ADD_POST = "ADD_POST";
export const GET_EDIT_USER_POST_DETAILS = "GET_EDIT_USER_POST_DETAILS";
export const GET_ALL_USER_POST = "GET_ALL_USER_POST";
export const GET_POST_SPINNER_STATUS = "GET_POST_SPINNER_STATUS";
export const SET_POST_SPINNER_STATUS = "SET_POST_SPINNER_STATUS";


export function setPostSpinner(flag) {
  return {
    type: SET_POST_SPINNER_STATUS,
    payload: flag,
  };
}
export function setSharePostSpinner(flag) {
  return {
    type: SET_SHARE_POST_SPINNER_STATUS,
    payload: flag,
  };
}
export function commentPostSpinner(flag) {
  return {
    type: SET_COMMENT_STATUS_SPINNER_STATUS,
    payload: flag,
  };
}
export function removePostSpinner(flag) {
  return {
    type: SET_DELETE_POST_STATUS_SPINNER_STATUS,
    payload: flag,
  };
}
export function getActivityPost(data) {
  return {
    type: GET_ALL_ACTIVITY_POST,
    payload: data,
  };
}
export function getAllActivitySpinner(flag) {
  return {
    type: GET_ALL_ACTIVITY_SPINNER_STATUS,
    payload: flag,
  };
}
export function setLikePostSpinner(flag) {
  return {
    type: SET_LIKE_STATUS_SPINNER_STATUS,
    payload: flag,
  };
}

export function getPostSpinner(flag) {
  return {
    type: GET_POST_SPINNER_STATUS,
    payload: flag,
  };
}

export function addPostData(data) {
  return {
    type: ADD_POST,
    payload: data,
  };
}

export function getUserPostData(data) {
  return {
    type: GET_ALL_USER_POST,
    payload: data,
  };
}
export function getUserEditPostData(data) {
  return {
    type: GET_EDIT_USER_POST_DETAILS,
    payload: data,
  };
}

export function deletePostAttachment(attachmentId){
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
  let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/post/attachment/${attachmentId}`, {
    method: "POST",
    headers: headerType.HeadersWithToken(token),
  });
  return  await result.json();
}
}

export const deletePost = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(removePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/delete/post`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(removePostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(removePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(removePostSpinner(false));
      console.log("You need to login first");
    }
  };
};
export const addPostModel = (model) => {
  var formData = new FormData();
  for (var key in model) {
    if (key === "attachments") {
      for (let attachmentKey in model[key]) {
        attachmentKey !== "length" && attachmentKey !== "item" &&
          formData.append("attachments[]", model[key][attachmentKey]);
      }
    } else {
      formData.append(key, model[key]);
    }
  }
  return async (dispatch) => {
    const token = auth.getAccessToken();
    await dispatch(setPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/add/post`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: formData,
        });
        let response = await result.json();
        if (response.success) {
          // await dispatch(fetchAllActivityFeed({ postType: 0, page: 1 }));
          await dispatch(addPostData(response));
           await dispatch(fetchAllUserPost({ postType: 1, page: 1 }));
          await dispatch(setPostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(setPostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setPostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const sharedPost = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setSharePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/share/post`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(setSharePostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(setSharePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setSharePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const postComment = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch) => {
    const token = auth.getAccessToken();
    await dispatch(commentPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/post/comment`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(commentPostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(commentPostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(commentPostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const replyComment = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch) => {
    const token = auth.getAccessToken();
    await dispatch(commentPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/reply/post/comment`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(commentPostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(commentPostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(commentPostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export function fetchVisitorPost(visitorId, page) {
  const token = auth.getAccessToken();
  return async (dispatch, getState) => {
      try {
          const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/visitor/profile?visitor_profile_id=${visitorId}&page=${page}`, {
              method: "GET",
              headers: headerType.HeadersWithTokenAndJson(token),
          });
          let response = await result.json();
          if (response.success === 200) {
              return response?.data?.posts;
          } else {
              console.log(response.errors);
          }
      } catch (e) {
          console.log(e);
      }
  };
}
export const fetchAllUserPost = (model) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(getPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/all/user/post/${model.postType}?page=${model.page}`,
          {
            method: "GET",
            headers: headerType.HeadersWithTokenAndJson(token),
          }
        );
        let response = await result.json();
        if (response.success) {
          await dispatch(getUserPostData(response.data));
          await dispatch(getPostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(getPostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(setPostSpinner(false));
    }
  };
};

export const fetchAllUserPosts = (model) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/all/user/post/${model.postType}?page=${model.page}`,
          {
            method: "GET",
            headers: headerType.HeadersWithTokenAndJson(token),
          }
        );
        let response = await result.json();
        if (response.success) {
          return response;
        }
      } catch (e) {
        console.log(e, "error.");
      }
    } else {
      // alert('You need to login first')
      console.log("You need to login first");
      await dispatch(setPostSpinner(false));
    }
  };
};

export const reportUserPost = (reportData) => {
  //console.log(reportData,"reportData");
  return async (dispatch) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/report/post`,
          {
            method: "POST",
            headers: headerType.HeadersWithTokenAndJson(token),
            body: JSON.stringify(reportData),
          }
        );
        let response = await result.json();
          return response;
      } catch (e) {
        // await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};

export const likePost = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setLikePostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/post/like`, {
          method: "POST",
          headers: headerType.HeadersWithTokenAndJson(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(setLikePostSpinner(false));
          return response;
        }
      } catch (e) {
        await dispatch(setLikePostSpinner(false));
        console.log(e, "error.");
      }
    } else {
      await dispatch(setLikePostSpinner(false));
      console.log("You need to login first");
    }
  };
};

export const saveEditPostUserDetails = (model) => {
  var form_data = new FormData();
  for (var key in model) {
    if (key === "attachments") {
      for (let attachmentKey in model[key]) {
        attachmentKey !== "length" && attachmentKey !== "item" &&
        form_data.append("attachments[]", model[key][attachmentKey]);
      }
    } else {
      if (key === "deleteAttachments") {
        for (let deleteattachmentKey in model[key]) {
          deleteattachmentKey !== "length" &&
            deleteattachmentKey !== "item" &&
            form_data.append(
              "deleteAttachments[]",
              model[key][deleteattachmentKey]
            );
        }
      } else {
        form_data.append(key, model[key]);
      }
    }
  }
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    await dispatch(setPostSpinner(true));
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/edit/post`, {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(setPostSpinner(false));
          return response;
        }
      } catch (e) {
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};

export const getEditUserPostDetails = (post_id) => {
  return async (dispatch, getState) => {
    const token = auth.getAccessToken();
    if (token) {
      try {
        let result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/post/detail?post_id=${post_id}`, {
          method: "GET",
          headers: headerType.HeadersWithTokenAndJson(token),
        });
        let response = await result.json();
        if (response.success) {
          await dispatch(getUserEditPostData(response.data));
          return response;
        }
      } catch (e) {
        console.log(e, "error.");
      }
    } else {
      console.log("You need to login first");
    }
  };
};



export const initialState = {
  userEditPostDetail: [],
  addPost: [],
  getUserPost: [],
  postSpinner: false,
  getPostSpinner: false,
  activityPost: [],
  commentSpinner: false,
  likePostSpinner: false,
  sharePostSpinner: false,
  removePostSpinner: false,
  getActivitySpinner: false,
};

const ACTION_HANDLERS = {

  [GET_ALL_ACTIVITY_POST]: (state, action) => {
    return {
      ...state,
      activityPost: action.payload,
    };
  },

  [SET_SHARE_POST_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      sharePostSpinner: action.payload,
    };
  },

  [SET_COMMENT_STATUS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      commentSpinner: action.payload,
    };
  },

  [SET_DELETE_POST_STATUS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      removePostSpinner: action.payload,
    };
  },

  [GET_ALL_ACTIVITY_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      getActivitySpinner: action.payload,
    };
  },



  [ADD_POST]: (state, action) => {
    return {
      ...state,
      addPost: action.payload,
    };
  },
  [GET_ALL_USER_POST]: (state, action) => {
    return {
      ...state,
      getUserPost: action.payload,
    };
  },

  [SET_POST_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      postSpinner: action.payload,
    };
  },

  [SET_LIKE_STATUS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      likePostSpinner: action.payload,
    };
  },

  [GET_POST_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      getPostSpinner: action.payload,
    };
  },
  [GET_EDIT_USER_POST_DETAILS]: (state, action) => {
    return {
      ...state,
      userEditPostDetail: action.payload,
    };
  },
};

export default function addPostReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
