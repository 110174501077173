import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllHubs, fetchHubsMember,fetchHubsInfo, getProducer, fetchSelectedHubs, subscribeUnsubscribeHub } from "../modules/B2B";

import FormSpinner from "../../../components/Spinners/FormSpinner";
import cardbg1 from "../../../assets/images/img1.png";
import DataNotFound from "../../../components/DataNotFound";
import language from "../../../helpers/language";
import auth from '../../../helpers/auth';
import constant from '../../../helpers/constant';
import Modal from "react-modal";
import { checkSubscription } from "../../home/modules/HomeModule";

export default function ViewHub(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const [lang] = useState(language.getLang());
  const [issubscribeButton, setIssubscribeButton] = useState(true)
  const [isAlert, setIsAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { get_Subscription_Status_Data } = useSelector((state) => state.activityFeedStatus);
  let subscriptionData=get_Subscription_Status_Data?.subscription?.subscription_plan
  let subscriptionDataModified=subscriptionData?.hubs_subscription==-1?10000000000:subscriptionData?.hubs_subscription


  console.log(get_Subscription_Status_Data?.hub_count<subscriptionDataModified,get_Subscription_Status_Data?.hub_count,subscriptionDataModified,"subscriptionData")

  useEffect(()=>{
    dispatch(checkSubscription())
  },[])

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
      setIsOpen(true);
    };


  useEffect(async () => {
    const userType = await JSON.parse(auth.getUserType());
    if(userType?.role_id === constant?.rolesId?.restaurant){
        await setIssubscribeButton(false);
    }
    await dispatch(fetchAllHubs());
    // await dispatch(getProducer([])); 
  }, []);
  useEffect(async () => {
    await dispatch(fetchHubsInfo(parseInt(params?.id)));
    await dispatch(fetchHubsMember(parseInt(params?.id)))
  }, [params?.id]); 

  //console.log(userType, "userType");
  const subscribeUnsubscribe = async () => {
    const model = {
      hubId: parseInt(params.id),
      subscriptionType: getHubMemberData?.is_subscribed_with_hub ? 0 : 1
    }
    await dispatch(subscribeUnsubscribeHub(model))
    await dispatch(fetchHubsInfo(parseInt(params?.id)));
    await dispatch(fetchHubsMember(parseInt(params?.id)))
    await getSelectedHubs();
  }

  const getSelectedHubs = async () => {
    const searchHubs = {
      searchType: 3,
      keyword: '',
      state: [],
    };
    await dispatch(fetchSelectedHubs(searchHubs));
  };

  const alertMessage = async() => {
    await setIsAlert(true);
  }

  const { getHubMemberData, hubsMemberSpinner, getHubInfoData, getSelectedHubsData } = useSelector((state) => state.b2bState);
  //console.log(getHubInfoData,"getHubInfoData");
  return (
    <div className="b2b chicago">
      {/* Hubs section */}
      <div className="servics_blocks">
        <div className="site-align top-align">
          {/* Left Block */}

          <div className="left__section-block sm:mb-2 md:mb-2" >
            <div className="card card--block p-6">
              <div className={`hubImg ${getHubInfoData?.is_selected ? 'myHub' : ''}`}>
                <img src={getHubInfoData?.image ? `${getHubInfoData.image?.base_url}${getHubInfoData.image?.attachment_url}` : cardbg1} alt="Card Image" />
              </div>
              <div className="card-form p-3">
                <div className="address text-center p-0">
                  <a href="javascript:void(0)">{getHubInfoData?.title}</a>
                </div>
                <div className="address text-center p-0">
                  <span>
                    {/* <strong>{lang?.Location}:</strong> */}
                    <span className="inner_text pr-3yyyyyy">
                      {getHubInfoData?.state ? `${getHubInfoData?.state?.name}` : ''}
                    </span>
                    {/* <span className="inner_text">
                      {`${getHubInfoData?.country.name}`}
                    </span> */}
                  </span>
                </div>
                {issubscribeButton ? 
                <div className="view__hub__btn">
                  {getHubMemberData && getHubMemberData?.is_subscribed_with_hub === 1 && <Link to="#" onClick={() => subscribeUnsubscribe()}>{lang?.Unsubscribe}</Link>}
                  {getHubMemberData && getHubMemberData?.is_subscribed_with_hub === 0 && <Link to="#" onClick={() =>{get_Subscription_Status_Data?.hub_count<subscriptionDataModified?subscribeUnsubscribe():handleOpenModal()} }>{lang?.Subscribe}</Link>}

                </div>
                :
                <div className="view__hub__btn">
                  {getHubMemberData && getHubMemberData?.is_subscribed_with_hub === 1 && <Link to="#" className="buttonDisabled">{lang?.Unsubscribe}</Link>}
                  {getHubMemberData && getHubMemberData?.is_subscribed_with_hub === 0 && <Link to="#" className="buttonDisabled">{lang?.Subscribe}</Link>}

                </div>
                }
              </div>
            </div>
          </div>
          {/* Middle long Block */}
          <div className="servics_blocks middle-long__section-block middleBlockBg">
            <div className="">
              <div className="medium_title">
                <h1 className="font-18 text-black">{lang?.Hubs}</h1>
              </div>
              {hubsMemberSpinner ? (
                <FormSpinner />
              ) : (
                <div className="card--section site-align top-align flex-wrap b2b-hub-view">
                  {getHubMemberData?.data?.length > 0 ?
                    getHubMemberData?.data?.map((item, index) => {
                      return (
                        <div className="card card--block" key={index}>
                          <div className="travel-agencies">
                            <div className="inner-main-div">
                              <div className="selectedHubImg">
                                <img
                                  src={item && item.attachment ? `${item.attachment.base_url}${item.attachment.attachment_url}` : cardbg1} alt="Crad Image" />
                                <span className="hubNumber">
                                  {item.user_count}
                                </span>
                              </div>
                            </div>
                            <div className="card-form py-3">
                              <div className="address text-center p-0">
                                <a href="javascript:void(0)">{item.name}</a>
                              </div>
                              <div className="address text-center p-0">
                                {/* <span className="inner_text">
                                  Search for Travel Agencies who are a part of
                                  the Chicago Hub.
                                </span> */}
                              </div>
                              <div className="view__hub__btn" >
                                <Link to={`${"/hubmember"}/${parseInt(params.id)}/${item.role_id}`}>{lang?.View}</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }) : <DataNotFound post={{ title: "Hubs", post: "No hubs found." }} />
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
          ariaHideApp={false}
          isOpen={isAlert}
          style={{
              overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                  position: "absolute", top: "0", left: "0%", right: "0%", bottom: "200px", border: "1px solid #ccc", background: "#fff", overflow: "auto", WebkitOverflowScrolling: "touch", borderRadius: "10px", padding: "0",
                  // outline: 'none',
              },
          }}
      >
          <div className="popup_header flex justify-between items-center popupheaderBg">
            <h4 className="page__title"></h4>
              <button
                  className="close_btn--modal"
                  onClick={() => setIsAlert(!isAlert)}
              >
                  {lang?.close}
              </button>
          </div>
          <div className="card card--block modal_box">
              <p className="warning">Opps! we can't subscribe/unsubscribe here. if you want to change hub <Link to="/hubs" style={{color:'#2f97c1'}}>Click here!</Link></p>
          </div>
      </Modal>
      {/*subscription modal*/}
      <Modal
          isOpen={isOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Upgrade Modal"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg text-black text-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          >
          <div className="w-16 m-auto mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 47.5 47.5" viewBox="0 0 47.5 47.5" id="diamond"><path fill="#bdddf4" d="M0 0h-6l-7-9h10l3 9z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-7 9h-6l3-9H0z" transform="matrix(1.25 0 0 -1.25 46.25 16.25)"></path><path fill="#4289c1" d="M0 0h10L-8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 33.75 16.25)"></path><path fill="#8ccaf7" d="M0 0h-10L8-21 0 0Z" transform="matrix(1.25 0 0 -1.25 13.75 16.25)"></path><path fill="#8ccaf7" d="m0 0-3-9h16l-3 9H0Z" transform="matrix(1.25 0 0 -1.25 17.5 5)"></path><path fill="#5dadec" d="m0 0-8 21H8L0 0Z" transform="matrix(1.25 0 0 -1.25 23.75 42.5)"></path></svg></div>    
          <h2 className="text-2xl font-bold mb-4  ">Upgrade Your Plan</h2>
          {
            subscriptionData!==undefined?(
              <p>
              <span className=" text-black">As per your current Plan, you can add up to {subscriptionData?.hubs_subscription==-1?"Unlimited":subscriptionData?.hubs_subscription} Hubs.
           To add more, please upgrade your Membership Plan.</span>
          </p>
            ):(
              <p>
              <span className=" text-black">Sorry, You're unable to subscribe any hubs at the moment as you don't have an active subscription.</span>
          </p>  
            )
          }
         
          <Link to="/subscription">
          <button  className="btn btn-blue stripe text-white font-bold mt-6">Upgrade </button>
          </Link>
          
          </Modal>
    </div>
  );
}
