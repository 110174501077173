import React, { useEffect, useState } from 'react'
import { getMyRequestGraphData } from '../../modules/Buyermodule';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, LineElement,Tooltip, Legend, CategoryScale, LinearScale,PointElement,Filler } from "chart.js"
ChartJS.register(
    Title, LineElement,Tooltip, Legend, CategoryScale, LinearScale,PointElement,Filler
);

export default function GraphView() {
    const [monthData, setMonthData] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);
    const [data, setData] = useState({
        labels:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
        datasets:[
            {
                label:"Total Requests",
                data: monthData,
                backgroundColor: "white",
                tension:0.4,
                fill:false,
                borderColor:"#004577"
               
            },
            
        ]
    },)

    useEffect(async() => {
        await setData({
            labels:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            datasets:[
                {
                    label:"Total Requests",
                    data: monthData,
                    backgroundColor: "white",
                    tension:0.4,
                    fill:false,
                    borderColor:"#004577"
                   
                },
                
            ]
        });
    },[monthData])

    useEffect(async() => {
        let apiResponse = await getMyRequestGraphData();
        if(apiResponse?.success){
            await setMonthData(apiResponse?.graph);
        }
        console.log(apiResponse,'apiResponse');
    },[])
  return (
    <>
        <div className='graphViewCard'>
            <div className='card card--block card-gap'>
                <Line data ={data}></Line>
                {/* <Tabs className="customTab">
                    <TabList>
                        <Tab>Total requests</Tab>
                        <Tab>Purchased Requets</Tab>
                        <Tab>Shortlisted Requests</Tab>
                    </TabList>

                    <TabPanel className="customTabContent">
                        <div className='graphImage'>
                            <img src={graph} alt="Graph" />
                        </div>
                    </TabPanel>
                    <TabPanel className="customTabContent">
                        <div className='graphImage'>
                            <img src={graph} alt="Graph" />
                        </div>
                    </TabPanel>
                    <TabPanel className="customTabContent">
                        <div className='graphImage'>
                            <img src={graph} alt="Graph" />
                        </div>
                    </TabPanel>
                </Tabs> */}
            </div>
        </div>
    </>
  )
}
