import React from 'react'

export default function InfoIconBlock(props) {
  return (
    <>
        <div className='infoIconCard'>
            {props.Icon &&
                <div className='iconBlock'>
                    {props.Icon}
                </div>
            }
            {props.Title &&
                <h4 className='titleBlue mt-6'>{props.Title}</h4>
            }
            {props.Description &&
                <p className='mt-4 subTitle'>{props.Description}</p>
            }
        </div>
    </>
  )
}
