import React,{ Fragment } from "react";
import { renderRoutes } from "react-router-config";
import Footer from "../components/Footer/Footer";
import MarketPlaceHeader from "../components/Headers/MarketPlaceHeader";
import auth from "../helpers/auth";

export const MarketPlaceLayout = ({ children, route }) => (
  <Fragment>
    <MarketPlaceHeader/>
      <div className="main__content">
        {/* <div className="site__width"> */}
          {children}
          {renderRoutes(route.routes)}
        {/* </div> */}
      </div>      
      <Footer/>
  </Fragment>
);

export default MarketPlaceLayout;
