import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardSideBar from './DashboardSideBar';
import language from '../../../helpers/language';

export default function GenerateTransactionReport({ action_img }) {
    const [activeTab, setActiveTab] = useState("transaction-management");
    const [lang] = useState(language.getLang());

    const selectTab = async (tab) => {
        setActiveTab(tab);
    }
    return (
        <>
            <div className='servics_blocks dashboardView generate_transaction'>
                <div className='site-align top-align'>
                    <div className='left__section-block sm:mb-2 md:mb-2'>
                        <div className='tabLeftBlock'>
                            <DashboardSideBar lang={lang} selectTab={selectTab} activeTab={activeTab} />
                        </div>
                    </div>
                    <div className='servics_blocks middle-long__section-block middleBlockBg'>
                        <div className="product-title">
                            <div className="flex flex-wrap justify-between items-center">
                                <h4 className="mb-0">
                                    Transaction Details
                                </h4>
                                <div className="inner-btn-box">
                                    <Link
                                        to="#"
                                        className="btn btn-blue"
                                        onClick={() => window.print()}
                                    >
                                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
                                            <path d="M6.875 0C5.51787 0 4.4 1.06974 4.4 2.36842V3.15789H3.575C1.61043 3.15789 0 4.69898 0 6.57895V13.9474C0 15.2461 1.11787 16.3158 2.475 16.3158H4.4V17.6316C4.4 18.9303 5.51787 20 6.875 20H15.125C16.4821 20 17.6 18.9303 17.6 17.6316V16.3158H19.525C20.8821 16.3158 22 15.2461 22 13.9474V6.57895C22 4.69898 20.3896 3.15789 18.425 3.15789H17.6V2.36842C17.6 1.06974 16.4821 0 15.125 0H6.875ZM6.875 1.57895H15.125C15.5906 1.57895 15.95 1.9229 15.95 2.36842V3.15789H6.05V2.36842C6.05 1.9229 6.40943 1.57895 6.875 1.57895ZM3.575 4.73684H18.425C19.4975 4.73684 20.35 5.5526 20.35 6.57895V13.9474C20.35 14.3929 19.9906 14.7368 19.525 14.7368H17.6V12.8947C17.6 11.5961 16.4821 10.5263 15.125 10.5263H6.875C5.51787 10.5263 4.4 11.5961 4.4 12.8947V14.7368H2.475C2.00943 14.7368 1.65 14.3929 1.65 13.9474V6.57895C1.65 5.5526 2.50247 4.73684 3.575 4.73684ZM6.875 12.1053H15.125C15.5906 12.1053 15.95 12.4492 15.95 12.8947V17.6316C15.95 18.0771 15.5906 18.4211 15.125 18.4211H6.875C6.40943 18.4211 6.05 18.0771 6.05 17.6316V12.8947C6.05 12.4492 6.40943 12.1053 6.875 12.1053Z" fill="white" />
                                        </svg>
                                        Print
                                    </Link>
                                    <Link to="#" className="btn btn--gray">
                                        <svg width="18" height="9" viewBox="0 0 18 9" fill="none">
                                            <path d="M0 0.833252V8.33325H7.5L4.48242 5.31567C5.63777 4.34289 7.12075 3.74992 8.75 3.74992C11.695 3.74992 14.1875 5.66213 15.07 8.31047L17.0426 7.65291C15.8851 4.17708 12.615 1.66659 8.75 1.66659C6.54497 1.66659 4.53823 2.48852 3.00293 3.83618L0 0.833252Z" fill="black" />
                                        </svg>
                                        Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="product-list">
                            <div className='recentOrder secBlockGap'>
                                <div className='productFilter innderHeader mb--30 justify--between'>
                                    <h4 className='OrderDetailP'>Transaction ID 12345678</h4>
                                    {/* <h4>21/06/2023 12:13 PM</h4> */}
                                </div>
                                <div className='itemTable TransTable'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Price</th>
                                                <th className='transStatus'>Status</th>
                                                <th className='transactionDate'>Date & Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>$280.00</td>
                                                <td>Paid</td>
                                                <td>21/06/2023 12:13 PM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='itemTable'>
                                    <table className='CustomDept'>
                                        <thead>
                                            <tr>
                                                <th>Customer Name</th>
                                                <th>Contact</th>
                                                <th>Email</th>
                                                <th className='CustAddress'>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Customer Name</td>
                                                <td>+91 1234567890</td>
                                                <td>customername@gmail.com</td>
                                                <td className='CustomData'><span>Test Test </span>
                                                    <span>south street</span>
                                                    <span>chicago</span>
                                                    <span>60564</span>
                                                    <span>Illinois</span>
                                                    <span>United States</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='itemTable CustDateTable'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Order ID</th>
                                                <th className='CustDate'>Date & Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1502</td>
                                                <td className='CustDATE'>21/06/2023 12:13 PM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='itemTable transProduct'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th className='transactionPrice'>Product</th>
                                                <th>Unit Price</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td className='transactionPrice'>Name</td>
                                                <td>$99.00</td>
                                                <td>1</td>
                                                <td>$99.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='itemTable transBilling'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Billing Address</th>
                                                <th>Shipping Address</th>
                                                <th>Payment</th>
                                                <th>Payment Status</th>
                                                <th className='TransOrderSum'>Order Summary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>noida</td>
                                                <td>Delhi</td>
                                                <td>{"Cash"}</td>
                                                <td>{"success"}</td>
                                                <td>{"12/02/2023"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
