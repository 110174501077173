import auth from "../../../helpers/auth";
import REACT_APP_API from "../../../config/environment";
import headerType from "../../../helpers/headerType";
export const REGISTER_SET_SIGNUP_FORM_FIELDS = "REGISTER_SET_SIGNUP_FORM_FIELDS";
export const REGISTER_SET_SIGNUP_FORM_FIELDS_SPINNER_STATUS = "REGISTER_SET_SIGNUP_FORM_FIELDS_SPINNER_STATUS";
export const SET_REGISTER_FORM_FIELD_VALUES = "SET_REGISTER_FORM_FIELD_VALUES";
export const SET_REGISTER_ERR_MESSAGE = "SET_REGISTER_ERR_MESSAGE";
export const SET_REGISTER_RESPONSE_ERR_MESSAGE = "SET_REGISTER_RESPONSE_ERR_MESSAGE";
export const SET_REGISTER_ERROR_FIELD = "SET_REGISTER_ERROR_FIELD";
export const SET_REGISTER_COUNTRY_DATA = "SET_REGISTER_COUNTRY_DATA";
export const SET_REGISTER_REGION_DATA = "SET_REGISTER_REGION_DATA";
export const SET_REGISTER_CITY_DATA = "SET_REGISTER_CITY_DATA";
export const SET_REGISTER_FORM_FIELDS_NAME = "SET_REGISTER_FORM_FIELDS_NAME";
export const REGISTER_DATA = "REGISTER_DATA";
export const SET_VOYAGER_REGISTER_DATA = "SET_VOYAGER_REGISTER_DATA";
export const SET_REST_PWD_DATA = "SET_REST_PWD_DATA";
const Selectedlanguage = localStorage.getItem("lang");
export function setFormFields(data) {
  return {
    type: REGISTER_SET_SIGNUP_FORM_FIELDS,
    payload: data,
  };
}
export function setFormErrors(message, fld) {
  return {
    type: SET_REGISTER_ERR_MESSAGE,
    payload: { msg: message, field: fld },
  };
}

export function setResponseErrors(error) {
  return {
    type: SET_REGISTER_RESPONSE_ERR_MESSAGE,
    payload: error,
  };
}

export function setFormFieldSpinner(flag) {
  return {
    type: REGISTER_SET_SIGNUP_FORM_FIELDS_SPINNER_STATUS,
    payload: flag,
  };
}

export function setFieldValues(data) {
  return {
    type: SET_REGISTER_FORM_FIELD_VALUES,
    payload: data,
  };
}

export function setCountries(data) {
  return {
    type: SET_REGISTER_COUNTRY_DATA,
    payload: data,
  };
}

export function setRegions(data) {
  return {
    type: SET_REGISTER_REGION_DATA,
    payload: data,
  };
}

export function setCities(data) {
  return {
    type: SET_REGISTER_CITY_DATA,
    payload: data,
  };
}

export function setFieldNames(data) {
  return {
    type: SET_REGISTER_FORM_FIELDS_NAME,
    payload: data,
  };
}
export function registerData(data) {
  return {
    type: REGISTER_DATA,
    payload: data,
  };
}

export function voyagerRregisterData(data) {
  return {
    type: SET_VOYAGER_REGISTER_DATA,
    payload: data,
  };
}

export function resetPwdOtpData(data) {
  return {
    type: SET_REST_PWD_DATA,
    payload: data,
  };
}

export function fetchFormFields(id) {
  return async (dispatch, getState) => {
    await dispatch(setFormFieldSpinner(true));
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/registration/fields/${id}`, {
        method: "GET",
        headers: headerType.getHeaderWithJson(),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setFormFieldSpinner(false));
        await dispatch(setFormFields(response.data));
        await dispatch(setFieldNames(response.data));
      }
    } catch (e) {
      await dispatch(setFormFieldSpinner(false));
      console.log(e);
    }
  };
}

export function getCountries(id) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/countries?role_id=${id}`, {
        method: "GET",
        headers: headerType.getHeaderWithJson(),
      });
      let response = await result.json();
      if (response.success === 200) {
        // await dispatch(setFormFieldSpinner(false));
        await dispatch(setCountries(response.data));
        await dispatch(setRegions([]));
        await dispatch(setCities([]));
      }
    } catch (e) {
      console.log(e);
    }
  };
} 

export function getRegion(id) {
  return async (dispatch, getState) => {
    // await dispatch(setFormFieldSpinner(true));
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/states?country_id=${id}`, {
        method: "GET",
        headers: headerType.getHeaderWithJson(),
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(setRegions(response.data.sort(dynamicSort("name"))));
        //await dispatch(setFormFieldSpinner(false));
      }
    } catch (e) {
      console.log(e);
      //await dispatch(setFormFieldSpinner(false));
    }
  };
}

export function getCity(id) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/cities?state_id=${id}`, {
        method: "GET",
        headers: headerType.getHeaderWithJson(),
      });
      let response = await result.json();
      if (response.success) {
        await dispatch(setCities(response.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function register(model) {
  //console.log(model,"modal6121")
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }
  return async (dispatch, getState) => {
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/user/register`, {
        method: "POST",
        cache: "no-cache",
        body: form_data,
      });
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(registerData(response));
        return response;
      } else {
        await dispatch(setResponseErrors(response.errors));
        console.log(response.errors);
      }
    } catch (e) {
      // await dispatch(setloginSpinner(false));
      console.log(e);
    }
  };
}

export function voyagerRregister(model) {
  return async (dispatch, getState) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/verify/otp?email=${model.email}&otp=${model.otp}`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
            locale: Selectedlanguage
          },
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(voyagerRregisterData(response));
        localStorage.setItem("token", response.token);
        return response;
      } else {
        console.log(response.message);
        return response
      }
    } catch (e) {
      // await dispatch(setloginSpinner(false));
      console.log(e);
    }
  };
}

export function resetPwdOtp(model) {
  return async (dispatch) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/reset/password/verify/otp?email=${model.email}&otp=${model.otp}`,
        {
          method: "POST",
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        await dispatch(resetPwdOtpData(response));
        return response;
      } else {
        console.log(response.message);
        return response
      }
    } catch (e) {
      // await dispatch(setloginSpinner(false));
      console.log(e);
    }
  };
}

export function verifyUserEmaiId(email) {
  return async (dispatch) => {
    try {
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/resend/otp?email=${email}`,
        {
          method: "POST",
        }
      );
      let response = await result.json();
      if (response.success === 200) {
        return response;
      } else {
        console.log(response.message);
        return response
      }
    } catch (e) {
      // await dispatch(setloginSpinner(false));
      console.log(e);
    }
  };
}

export function resetPassword(model) {
  var form_data = new FormData();
  for (var key in model) {
    form_data.append(key, model[key]);
  }

  return async (dispatch) => {
    try {
      const result = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/reset/password`, {
        method: "POST",
        cache: "no-cache",
        body: form_data,
      });
      let response = await result.json();
      if (response.success === 200) {
        // await dispatch(registerData(response));
        return response;
      } else {
        await dispatch(setResponseErrors(response.errors));
        console.log(response.errors);
      }
    } catch (e) {
      // await dispatch(setloginSpinner(false));
      console.log(e);
    }
  };
}



const dynamicSort = (property) => {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

export const initialState = {
  formFields: {},
  formSpinner: false,
  formValue: {},
  countries: [],
  regions: [],
  cities: [],
  fieldNames: [],
  errMessage: {
    msg: "",
    field: "",
  },
  resError: "",
  registerData: [],
  voyageRregister: [],
  resetPwdOtp: [],
};

const ACTION_HANDLERS = {
  [REGISTER_SET_SIGNUP_FORM_FIELDS]: (state, action) => {
    return {
      ...state,
      formFields: action.payload,
    };
  },
  [REGISTER_SET_SIGNUP_FORM_FIELDS_SPINNER_STATUS]: (state, action) => {
    return {
      ...state,
      formSpinner: action.payload,
    };
  },
  [SET_REGISTER_FORM_FIELD_VALUES]: (state, action) => {
    return {
      ...state,
      formValue: action.payload,
    };
  },
  [SET_REGISTER_ERR_MESSAGE]: (state, action) => {
    return {
      ...state,
      errMessage: action.payload,
    };
  },
  [SET_REGISTER_RESPONSE_ERR_MESSAGE]: (state, action) => {
    return {
      ...state,
      resError: action.payload,
    };
  },
  [SET_REGISTER_COUNTRY_DATA]: (state, action) => {
    return {
      ...state,
      countries: action.payload,
    };
  },
  [SET_REGISTER_REGION_DATA]: (state, action) => {
    return {
      ...state,
      regions: action.payload,
    };
  },
  [SET_REGISTER_CITY_DATA]: (state, action) => {
    return {
      ...state,
      cities: action.payload,
    };
  },
  [SET_REGISTER_FORM_FIELDS_NAME]: (state, action) => {
    let arr = [];
    Object.keys(action.payload).map((item) => {
      action.payload[item].map((i) => {
        arr.push(i.name);
      });
    });
    return {
      ...state,
      fieldNames: arr,
    };
  },
  [REGISTER_DATA]: (state, action) => {
    return {
      ...state,
      registerData: action.payload,
    };
  },
  [SET_VOYAGER_REGISTER_DATA]: (state, action) => {
    return {
      ...state,
      voyageRregister: action.payload,
    };
  },
  [SET_REST_PWD_DATA]: (state, action) => {
    return {
      ...state,
      resetPwdOtp: action.payload,
    };
  },
};

export default function RegisterReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
