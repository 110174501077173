import React from 'react'

export default function CardLayout(props) {
  return (
    <>
        <div className='cardItem'>
            <div className='card card--block'>
                {props.cardTitle &&
                    <h4>{props.cardTitle}</h4>
                }
                {props.cardDescription &&
                    <p>{props.cardDescription}</p>
                }
            </div>
        </div>
    </>
  )
}
