import auth from '../../../helpers/auth';
import REACT_APP_API from '../../../config/environment';
import headerType from '../../../helpers/headerType';
export const SPINNER_LOADER_STATUS = 'SPINNER_LOADER_STATUS';
export const LOAD_DEFAULT_PRODUCT_TYPES = 'LOAD_DEFAULT_PRODUCT_TYPES';
export const LOAD_DEFAULT_FDA_OPTIONS = 'LOAD_DEFAULT_FDA_OPTIONS';
export const LOAD_DEFAULT_ITALIAN_REGION_OPTIONS = 'LOAD_DEFAULT_ITALIAN_REGION_OPTIONS';
export const LOAD_CONSERVATION_METHODS = 'LOAD_CONSERVATION_METHODS';
export const LOAD_CONSERVATION_LABEL_NAME = 'LOAD_CONSERVATION_LABEL_NAME';
export const LOAD_PRODUCT_PROPERTIES = 'LOAD_PRODUCT_PROPERTIES';
export const LOAD_PRODUCT_PROPERTY_LABEL_NAME = 'LOAD_PRODUCT_PROPERTY_LABEL_NAME';
export const LOAD_PRODUCERS = 'LOAD_PRODUCERS';
export const LOAD_MY_REQUESTS = 'LOAD_MY_REQUESTS';
export const LOAD_SINGLE_REQUEST = 'LOAD_SINGLE_REQUEST';
export const LOAD_CHAT_USER_PROFILES = 'LOAD_CHAT_USER_PROFILES';
export const SINGLE_REQUEST_LOADER = 'SINGLE_REQUEST_LOADER';
export const LOAD_MY_NOTIFICATIONS = 'LOAD_MY_NOTIFICATIONS';
export const LOAD_MY_RECENT_REQUEST = 'LOAD_MY_RECENT_REQUEST';
export const LOAD_DASHBOARD_DATA = 'LOAD_DASHBOARD_DATA';
export const LOAD_BUYER_ROLES = 'LOAD_BUYER_ROLES';
export const LOAD_PARTNER_LOGOS = 'LOAD_PARTNER_LOGOS';

export function setLoadDashboardDataSpinner(data) {
  return {
    type: LOAD_DASHBOARD_DATA,
    payload: data,
  };
}

export function setBuyerRoles(data) {
  return {
    type: LOAD_BUYER_ROLES,
    payload: data,
  };
}

export function setBusinessPartnerLogo(data) {
  return {
    type: LOAD_PARTNER_LOGOS,
    payload: data,
  };
}

export function setSingleRequest(data) {
  return {
    type: LOAD_SINGLE_REQUEST,
    payload: data,
  };
}

export function setChatUserProfiles(data) {
  return {
    type: LOAD_CHAT_USER_PROFILES,
    payload: data,
  };
}

export function setSingleRequestLoader(data) {
  return {
    type: SINGLE_REQUEST_LOADER,
    payload: data,
  };
}

export function setDefaltLoaderStatus(data) {
  return {
    type: SPINNER_LOADER_STATUS,
    payload: data,
  };
}

export function setDefaltProductTypesData(data) {
  return {
    type: LOAD_DEFAULT_PRODUCT_TYPES,
    payload: data,
  };
}

export function setDefaltFdaOptions(data) {
  return {
    type: LOAD_DEFAULT_FDA_OPTIONS,
    payload: data,
  };
}

export function setConservationMethods(data) {
  return {
    type: LOAD_CONSERVATION_METHODS,
    payload: data,
  };
}

export function setConversationLabelName(data){
  return {
    type : LOAD_CONSERVATION_LABEL_NAME,
    payload : data,
  }

}
export function setPropertyLabelName(data){
  return {
    type : LOAD_PRODUCT_PROPERTY_LABEL_NAME,
    payload : data,
  }

}

export function setProductProperties(data) {
  return {
    type: LOAD_PRODUCT_PROPERTIES,
    payload: data,
  };
}

export function setProducers(data) {
  return {
    type: LOAD_PRODUCERS,
    payload: data,
  };
}

export function setDefaltItalianRegionOptions(data) {
  return {
    type: LOAD_DEFAULT_ITALIAN_REGION_OPTIONS,
    payload: data,
  };
}

export function setMyAllRequests(data) {
  return {
    type: LOAD_MY_REQUESTS,
    payload: data,
  };
}

export function setMyRecentRequests(data) {
  return {
    type: LOAD_MY_RECENT_REQUEST,
    payload: data,
  };
}

export function setMyNotifications(data) {
  return {
    type: LOAD_MY_NOTIFICATIONS,
    payload: data,
  };
}

export function getBuyerFilters() {
  return async (dispatch) => {
    await dispatch(setDefaltLoaderStatus(true));
      try {
        const token = auth.getAccessToken();
        const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/product-type/filter`, {
          method: 'GET',
          headers: headerType.HeadersWithToken(token),
        });
        let data = await response.json();
        if (data?.success) {
          await dispatch(setDefaltProductTypesData(data?.product_types));
          await dispatch(setDefaltFdaOptions(data?.fda_options));
          await dispatch(setDefaltItalianRegionOptions(data?.italian_regions));
          await dispatch(setDefaltLoaderStatus(false));
        } else {
          await dispatch(setDefaltProductTypesData([]));
          await dispatch(setDefaltFdaOptions([]));
          await dispatch(setDefaltItalianRegionOptions([]));
          await dispatch(setDefaltLoaderStatus(false));
        }
      } catch (errors) {
        console.log(errors)
      }
    }
  }

  export function getFilterResults(filters){
    //console.log(filters,"filters");
    return async (dispatch) => {
      await dispatch(setDefaltLoaderStatus(true));
        try {
          const token = auth.getAccessToken();
          const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/producers/via-filters?product_type_id=${filters.product_type_id}&conservation_id=${filters.conservation_id}&property_id=${filters.property_id}&product_name=${filters.product_name}&fda_option_id=${filters.fda_option_id}&italian_region_id=${filters.italian_region_id}`, {
            method: 'GET',
            headers: headerType.HeadersWithToken(token),
          });
          let data = await response.json();
          if (data?.success) {
            console.log(data,"data");
            if(data?.options.length > 0){
              await dispatch(setConversationLabelName(data?.options[0]?.option));
              await dispatch(setPropertyLabelName(data?.options[1]?.option));
            }
            await dispatch(setConservationMethods(data?.options[0]?.options));
            await dispatch(setProductProperties(data?.options[1]?.options));
            await dispatch(setProducers(data?.producer));
            await dispatch(setDefaltLoaderStatus(false));
          } else {
            // await dispatch(setDefaltProductTypesData([]));
            // await dispatch(setDefaltFdaOptions([]));
            // await dispatch(setDefaltItalianRegionOptions([]));
            await dispatch(setDefaltLoaderStatus(false));
          }
        } catch (errors) {
          console.log(errors)
        }
      }
  }

  // Create Request API
  export async function createRequestAPI(data) {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/buyer/create/request`,
        {
          method: 'POST',
          headers: headerType.HeadersWithTokenAndJson(token),
          body: JSON.stringify(data),
        }
      );
      let response = await result.json();
      return response;
    } catch (e) {
      console.log(e);
    }
  
  }

  // Create Request API
  export async function checkProfileApprovedStatus() {
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/check/profile-review/status`,
        {
          method: 'GET',
          headers: headerType.HeadersWithTokenAndJson(token),
        }
      );
      let response = await result.json();
      return response;
    } catch (e) {
      console.log(e);
    }
  
  }

    // Get Contact info API
    export async function getBuyerContactInfo() {
      try {
        const token = auth.getAccessToken();
        const result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/contact-number`,
          {
            method: 'GET',
            headers: headerType.HeadersWithTokenAndJson(token),
          }
        );
        let response = await result.json();
        return response;
      } catch (e) {
        console.log(e);
      }
    
    }

    // Get request graph 
    export async function getMyRequestGraphData() {
      try {
        const token = auth.getAccessToken();
        const result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/graph-data`,
          {
            method: 'GET',
            headers: headerType.HeadersWithTokenAndJson(token),
          }
        );
        let response = await result.json();
        return response;
      } catch (e) {
        console.log(e);
      }
    
    }

    
    // Delete my account
    export async function deleteMyAccount() {
      try {
        const token = auth.getAccessToken();
        const result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/buyer/account/delete`,
          {
            method: 'DELETE',
            headers: headerType.HeadersWithTokenAndJson(token),
          }
        );
        let response = await result.json();
        return response;
      } catch (e) {
        console.log(e);
      }
    
    }

    // Delete my request
    export async function deleteMyRequest(uuid) {
      try {
        const token = auth.getAccessToken();
        const result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/delete/request/${uuid}`,
          {
            method: 'DELETE',
            headers: headerType.HeadersWithTokenAndJson(token),
          }
        );
        let response = await result.json();
        return response;
      } catch (e) {
        console.log(e);
      }
    
    }

  // Get My All Requests
  export function getMyAllRequests(page, product_type, requestId) {
    return async (dispatch) => {
      await dispatch(setDefaltLoaderStatus(true));
        try {
          const token = auth.getAccessToken();
          const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/requests?page=${page}&product_type=${product_type}&request_id=${requestId}`, {
            method: 'GET',
            headers: headerType.HeadersWithToken(token),
          });
          let data = await response.json();
          if (data?.success) {
            await dispatch(setMyAllRequests(data));
            await dispatch(setDefaltLoaderStatus(false));
          } else {
            await dispatch(setDefaltLoaderStatus(false));
          }
        } catch (errors) {
          console.log(errors)
        }
      }
    }

    // Get My Recent Requests
  export function getMyRecentRequest(page) {
    return async (dispatch) => {
      await dispatch(setDefaltLoaderStatus(true));
        try {
          const token = auth.getAccessToken();
          const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/buyer-recent/requests`, {
            method: 'GET',
            headers: headerType.HeadersWithToken(token),
          });
          let data = await response.json();
          if (data?.success) {
            await dispatch(setMyRecentRequests(data));
            await dispatch(setDefaltLoaderStatus(false));
          } else {
            await dispatch(setDefaltLoaderStatus(false));
          }
        } catch (errors) {
          console.log(errors)
        }
      }
    }

  // Get Dashboard Buyer Roles & Partner Logo
  export function getDashboardRolesLogo() {
    return async (dispatch) => {
      await dispatch(setLoadDashboardDataSpinner(true));
        try {
          const token = auth.getAccessToken();
          const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/dasboard-data`, {
            method: 'GET',
            headers: headerType.HeadersWithToken(token),
          });
          let data = await response.json();
          if (data?.success) {
            await dispatch(setBuyerRoles(data?.buyer_roles));
            await dispatch(setBusinessPartnerLogo(data?.partner_logo));
            await dispatch(setLoadDashboardDataSpinner(false));
          } else {
            await dispatch(setLoadDashboardDataSpinner(false));
          }
        } catch (errors) {
          console.log(errors)
        }
      }
    }

    // Get My All Notification
  export function getMyAllNotifications(page) {
    return async (dispatch) => {
      await dispatch(setDefaltLoaderStatus(true));
        try {
          const token = auth.getAccessToken();
          const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/notifications?page=${page}`, {
            method: 'GET',
            headers: headerType.HeadersWithToken(token),
          });
          let data = await response.json();
          if (data?.success) {
            await dispatch(setMyNotifications(data));
            await dispatch(setDefaltLoaderStatus(false));
          } else {
            await dispatch(setDefaltLoaderStatus(false));
          }
        } catch (errors) {
          console.log(errors)
        }
      }
    }

    export async function setChangePassword(model) {
      var form_data = new FormData();
      for (var key in model) {
          form_data.append(key, model[key]);
      }
      try {
        const token = auth.getAccessToken();
        const result = await fetch(
          `${REACT_APP_API.ENV.REACT_APP_API}/change/password`,
          {
            method: "POST",
            headers: headerType.HeadersWithToken(token),
            body: form_data,
          }
        );
        let response = await result.json();
          return response;
        
      } catch (e) {
        console.log(e);
      }
      
  };

  // Update Contact Number
  export async function updateContactNumber(model) {
    var form_data = new FormData();
    for (var key in model) {
        form_data.append(key, model[key]);
    }
    try {
      const token = auth.getAccessToken();
      const result = await fetch(
        `${REACT_APP_API.ENV.REACT_APP_API}/update/buyer/contact-number`,
        {
          method: "POST",
          headers: headerType.HeadersWithToken(token),
          body: form_data,
        }
      );
      let response = await result.json();
        return response;
      
    } catch (e) {
      console.log(e);
    }
    
};

  // Get Single Requests
  export function getSingleRequest(uuid) {
    return async (dispatch) => {
      await dispatch(setSingleRequestLoader(true));
        try {
          const token = auth.getAccessToken();
          const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/get/buyer/request/${uuid}`, {
            method: 'GET',
            headers: headerType.HeadersWithToken(token),
          });
          let data = await response.json();
          if (data?.success) {
            await dispatch(setSingleRequest(data?.request));
            await dispatch(setChatUserProfiles(data?.users_profiles));
            await dispatch(setSingleRequestLoader(false));
          } else {
            await dispatch(setSingleRequest([]));
            await dispatch(setChatUserProfiles([]));
            await dispatch(setSingleRequestLoader(true));
          }
        } catch (errors) {
          console.log(errors)
        }
      }
    }

  export const initialState = {
    defaultProductTypes: [],
    defaultFdaOptions : [],
    defaultItalianRegionOPtions : [],
    createRequestSpinner : false,
    conservationMethods : [],
    productProperties : [],
    producers : [],
    myAllRequests : [],
    singleDataLoader : true,
    singleRequestData : [],
    chtUserProfiles : [],
    myAllNotifications : [],
    myRecentRequests : [],
    dashboardBuyerRoles : [],
    dashboardPartnerLogo : [],
    loadDashboardDataSpinner : false,
    conservationLabelName : 'Conservation Method',
    propertyLabelName : 'Product Properties',
  };

  const ACTION_HANDLERS = {
    [LOAD_DEFAULT_PRODUCT_TYPES]: (state, action) => {
      return {
        ...state,
        defaultProductTypes: action.payload,
      };
    },
    [SINGLE_REQUEST_LOADER]: (state, action) => {
      return {
        ...state,
        singleDataLoader: action.payload,
      };
    },
    [LOAD_DEFAULT_FDA_OPTIONS]: (state, action) => {
      return {
        ...state,
        defaultFdaOptions: action.payload,
      };
    },
    [LOAD_DEFAULT_ITALIAN_REGION_OPTIONS]: (state, action) => {
      return {
        ...state,
        defaultItalianRegionOPtions: action.payload,
      };
    },
    [SPINNER_LOADER_STATUS]: (state, action) => {
      return {
        ...state,
        createRequestSpinner: action.payload,
      };
    },
    [LOAD_DASHBOARD_DATA] : (state, action) => {
      return {
        ...state,
        loadDashboardDataSpinner: action.payload,
      };
    },
    [LOAD_CONSERVATION_METHODS]: (state, action) => {
      return {
        ...state,
        conservationMethods: action.payload,
      };
    },
    [LOAD_CONSERVATION_LABEL_NAME]: (state, action) => {
      return {
        ...state,
        conservationLabelName: action.payload,
      };
    },
    [LOAD_PRODUCT_PROPERTY_LABEL_NAME]: (state, action) => {
      return {
        ...state,
        propertyLabelName: action.payload,
      };
    },
    [LOAD_PRODUCT_PROPERTIES]: (state, action) => {
      return {
        ...state,
        productProperties: action.payload,
      };
    },
    [LOAD_PRODUCERS]: (state, action) => {
      return {
        ...state,
        producers: action.payload,
      };
    },
    [LOAD_MY_REQUESTS]: (state, action) => {
      return {
        ...state,
        myAllRequests: action.payload,
      };
    },
    [LOAD_MY_RECENT_REQUEST] : (state, action) => {
      return {
        ...state,
        myRecentRequests: action.payload,
      };
    },
    [LOAD_MY_NOTIFICATIONS] : (state, action) => {
      return {
        ...state,
        myAllNotifications: action.payload,
      };
    },
    [LOAD_SINGLE_REQUEST] : (state, action) => {
      return {
        ...state,
        singleRequestData : action.payload,
      };
    },
    [LOAD_BUYER_ROLES] : (state, action) => {
      return {
        ...state,
        dashboardBuyerRoles : action.payload,
      };
    },
    [LOAD_PARTNER_LOGOS] : (state, action) => {
      return {
        ...state,
        dashboardPartnerLogo : action.payload,
      };
    },
    [LOAD_CHAT_USER_PROFILES] : (state,action) => {
      return {
        ...state,
        chtUserProfiles : action.payload,
      };
    },
  };

  export default function buyerModuleReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  }