import React, { Fragment } from 'react'
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cropper from "react-cropper";
import Modal from "react-modal";

import FormError from '../../../components/Errors/FormError'
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';
import { setFormError, postBlog, fetchEditBlogDataById, updateBlog, fetchBlogListingData, editBlogDataById, } from "../modules/DiscoverModule";

export default function CreateBlog() {
    const [blogTitle, setBlogTitle] = useState();
    const [blogDescription, setBlogDescription] = useState()
    const [blogStatus, setBlogStatus] = useState("Draft")
    const [blogStatusCode, setBlogStatusCode] = useState(0);
    const [blogImageId, setBlogImageId] = useState()
    const [imageId, setImageId] = useState('');
    const [isSuccessCreated, setIsSuccessCreated] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');
    const [editId, setEditId] = useState()
    const [editBlodData, setEditBlofDtata] = useState();
    const [lang] = useState(language.getLang());

    const [isCropped, setIsCropped] = useState(false);
    const [openModal, setOpenModal] = useState('');
    const [image, setImage] = useState('');
    const [fileName, setFileName] = useState('');
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState();


    const { errMessage, blogDataById, createdBlogtData, } = useSelector(state => state.discoverReducerState);

    const dispatch = useDispatch()
    const history = useHistory();
    const { id } = useParams();

    useEffect(async () => {
        id !== undefined && await setEditId(id);
        if (id !== undefined) {
            await dispatch(fetchBlogListingData()).then(async (res) => {
                if (res.success === 200) {
                    await editBlogFun(res.data)
                }
            })
        }
    }, [id]);

    const blogImageFun = async (e) => {
        let file = e.target.files[0];
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            dispatch(
                setFormError(
                    lang?.imageType,
                    "blogImageId"
                )
            );
            setTimeout(() => {
                dispatch(setFormError("", ""))
            }, 3000);
        } else {
            // await setImageId(file);
            // const img = URL.createObjectURL(file)
            // setBlogImageId(img)
            await setOpenModal(true)
            await setFileName(e.target.files[0].name);
            await setImage(URL.createObjectURL(e.target.files[0]));
            return e.target.value = null
        }



    }

    const createBlogFun = async (e) => {
        if (blogTitle === undefined) {
            await dispatch(setFormError(`${lang?.Please_select_BlogTitle}`, "blogTitle"))
        } else if (blogDescription === undefined) {
            await dispatch(setFormError(`${lang?.Please_block_Description}`, "blogDescription"))
        } else if (blogImageId === undefined) {
            await dispatch(setFormError(`${lang?.Please_select_BlogImage}`, "blogImageId"))
        } else {
            let curentData = new Date();
            const date = curentData.toDateString()
            const time = curentData.getTime();

            await setIsSuccessCreated(true);
            const blogDataObj = {
                title: blogTitle,
                date: date,
                time: time,
                description: blogDescription,
                status: blogStatusCode,
                image_id: imageId
            }
            const response = await dispatch(postBlog(blogDataObj));
            if (response) {
                await setIsSuccessCreated(false);
                history.push("/profile");
            }
        }
        setTimeout(() => {
            dispatch(setFormError("", ""))
        }, 3000);
    }

    const updateBlogData = async () => {
        await setIsSuccessCreated(true);
        let curentData = new Date();
        const date = curentData.toDateString();
        const time = curentData.getTime();
        const edit_data = {
            title: blogTitle !== undefined ? blogTitle : editBlodData?.title,
            date: date,
            time: time,
            description: blogDescription !== undefined ? blogDescription : editBlodData?.description,
            status: blogStatus === "Draft" ? 0 : 1,
            image_id: imageId !== undefined ? imageId : `${editBlodData?.attachment?.base_url}${editBlodData?.attachment?.attachment_url}`,
            blog_id: parseInt(id)
        }
        const response = await dispatch(updateBlog(edit_data));
        if (response) {
            await setUpdateMessage(response.message);
            await setIsSuccessCreated(false);
            history.push("/profile");
        } else {
            await setIsSuccessCreated(false);
        }
        await setTimeout(() => {
            setUpdateMessage('');
        }, 4000)
    }
    const editBlogFun = async (data) => {
        data?.length > 0 && data.map(async (item, index) => {
            if (item.blog_id == id) {
                await setEditBlofDtata(item);
                await setBlogStatus(item?.status === "0" ? "Draft" : "Publish");
                // await dispatch(editBlogDataById(item));
            }
        })
    }

    const blogStatusFun = async (e) => {
        if (e.target.checked) {
            await setBlogStatus("Publish");
            await setBlogStatusCode(1);
        } else {
            await setBlogStatus("Draft");
            await setBlogStatusCode(0);
        }
    }


    // Start Cropping your Image
    const getCropData = async () => {
        await setIsCropped(true);
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            await setBlogImageId(cropper.getCroppedCanvas().toDataURL());
            await fetch(cropper.getCroppedCanvas().toDataURL())
                .then(res => res.blob()
                    .then(async (blob) => {
                        const file = await new File([blob], `${fileName}`, { type: "image/png" });
                        await setImageId(file);
                    })
                );
            await setIsCropped(false);
            await setOpenModal(false);
        }
    };
    const closeModal = async () => {
        await setCropData('');
        await setImage('');
        await setCropData('');
        await setFileName('');
        await setOpenModal(false);
    }
    // End of Cropping

    return (
        <Fragment>
            {console.log(blogStatus, 'blogStatus')}
            <div className="discover_page forCreate">
                <div className="page_top_form">
                    <div className="flex justify-between items-center">
                        <div className='leftArrowBlock'>
                            <Link to="/profile"><i className="fa fa-arrow-left pr-2" aria-hidden="true"></i></Link>
                            {id !== undefined ? `${lang?.Edit}` : `${lang?.Create}`} {lang?.Blog}
                        </div>
                        <div className="editProfileBtn">
                            {id === undefined && <button className='btn btnPrimary' onClick={() => createBlogFun()}> {isSuccessCreated ? <FormSpinner /> : `${lang?.Save}`}</button>}
                            {id !== undefined && <button className='btn btnPrimary' onClick={() => updateBlogData()}>  {isSuccessCreated ? <FormSpinner /> : `${lang?.Update}`}</button>}
                        </div>
                    </div>
                </div>
                <div className="discover__events create">
                    <form>
                        <div className='createForm mt-4'>
                            <div className='md:w-2/5 sm:w-full xl:mb-0 lg:mb-0 md:mb-0 sm:mb-4'>
                                <div className='colLeftBlock'>
                                    <div className="fileUpload">
                                        <label className="hide-input">
                                            <span>
                                                <i className="fa fa-camera" aria-hidden="true"></i> &nbsp; {lang?.Add} {lang?.Image}
                                            </span>
                                            <input type="file" id="gallery_images" accept="image/png,image/jpeg" onChange={(e) => { blogImageFun(e) }} />
                                            {(id !== undefined || blogImageId !== undefined) && <img src={blogImageId !== undefined ? blogImageId : `${editBlodData?.attachment?.base_url}${editBlodData?.attachment?.attachment_url}`} />}
                                        </label>
                                    </div>
                                    {errMessage.field == "blogImageId" && <div> <FormError msg={errMessage.msg} /></div>}
                                </div>
                            </div>
                            <div className='md:w-3/5 sm:w-full'>
                                <div className='colRightBlock'>
                                    <div className="form__item">
                                        <label>{lang?.Blog} {lang?.only_title} *</label>
                                        <input type="text" placeholder='' defaultValue={editBlodData && editBlodData.title || blogTitle} onChange={(e) => { setBlogTitle(e.target.value) }} />
                                    </div>
                                    {errMessage.field == "blogTitle" && <div> <FormError msg={errMessage.msg} /></div>}
                                    <div className="form__item">
                                        <label>{lang?.Description} *</label>

                                        <textarea defaultValue={editBlodData && editBlodData.description || blogDescription} onChange={(e) => { setBlogDescription(e.target.value) }} cols={5} rows={5} />
                                        {blogDescription?.length > 0 && <p>{`${blogDescription.length}/Characters`}</p>}
                                    </div>
                                    {errMessage.field == "blogDescription" && <div> <FormError msg={errMessage.msg} /></div>}

                                    {/* <div className="form__item">
                                        <label>Blog Status</label>
                                        
                                        <input type="text" placeholder='' defaultValue={editBlodData && editBlodData.status ||blogStatus} onChange={(e)=>{setBlogStatus(e.target.value)}} />
                                    </div> */}
                                    <div className="form__item">
                                        <label>{lang?.Status}</label>
                                        <div className='taoggle_switch blog'>
                                            <div className='gridCol2'>
                                                <label>{blogStatus}</label>
                                                <div className='gridColRight text-right'>
                                                    <label className="switch">
                                                        <input type="checkbox" defaultChecked={editBlodData && parseInt(editBlodData.status)} onClick={(e) => { blogStatusFun(e) }} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <span className='ml-4 text-blue font-bold'>{lang?.Status}</span> */}
                                        </div>
                                    </div>
                                    {errMessage.field == "blogStatus" && <div> <FormError msg={errMessage.msg} /></div>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* crop modal  */}
            {openModal && <Modal className=""
                ariaHideApp={false}
                isOpen={openModal}
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                    },
                    content: {
                        position: "absolute",
                        top: "0",
                        left: "0%",
                        right: "0%",
                        bottom: "200px",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        padding: "0px",
                        // outline: 'none',
                    },
                }}
            >
                <div className="popup_header flex justify-between items-center">
                    <h4 className="page__title">{lang?.Crop_image}</h4>
                    <button className="close_btn--modal" onClick={() => closeModal()} > {lang?.close}</button>
                </div>
                <div className="card card--block modal_box">
                    <div className="crop_img_block">
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                    </div>
                    <div className="form__action form__item mt-4">
                        <button onClick={() => getCropData()}> {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`} </button>
                    </div>
                </div>
                {/* {cropData !== '' && <img style={{ width: "100%" }} src={cropData} alt="cropped" />} */}
            </Modal>}
            {/* crop modal end */}
        </Fragment>
    )
}
