import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addProdctToCart, paymentStatus } from '../../routes/marketplace/modules/marketplace';
import FormSpinner from '../Spinners/FormSpinner';



const StripePayment = ({lang, clientSecret, loader, setLoading, createProductOrder, totalAmount, setResponseErrors, forwardedRef, scrollToRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const stripe_id = localStorage.getItem('stripe_id');
  let { cartProducts } = useSelector((state) => state.marketPlaceReducerData);

  const [errorMessage, setErrorMessage] = useState(null);
  const [checkErrorMessage,setcheckErrorMessage]=useState(null)

useEffect(()=>{
  setTimeout(()=>{
    setcheckErrorMessage('')
  },4000)
},[checkErrorMessage])

  // pay your order amount 
  const payAmount = async (event) => {

    event.preventDefault();
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the product order 
    // const { orderStatus, orderId } = await createProductOrder();
    // console.log(orderStatus,)
    if (true) {
      setLoading(true)
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `http://localhost:3000/marketplace/checkout-form`,
        },
        redirect: 'if_required',
      });
      console.log(error,paymentIntent,"error")
      if(paymentIntent){
        
      }

      if (error) {
        setLoading(false)
        setcheckErrorMessage(error.message);
      
      } else {
         const { orderStatus, orderId } = await createProductOrder();
        const data = {
          order_id: orderId,
          intent_id: paymentIntent?.id,
          stripe_id: stripe_id
        }
        // sent payment status to the server
        const paymentsStatus = await paymentStatus(data);

        if (paymentsStatus?.success === 200) {
          localStorage.setItem("totalProductInCart", []);
          localStorage.setItem("stripe_id", '');
          dispatch(addProdctToCart([]));
          setLoading(false);
          history.push("/order-payment-successfully-complete");
        } else {
          setLoading(false);
          setResponseErrors({ message: paymentsStatus?.message ?? lang?.SomeThingsWrong, type: "common" });
          console.log(paymentsStatus?.message, 'errors')
        }
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`
      }
    } else {
      setLoading(false);
    }

  };

  return (
    <>
      <form onSubmit={payAmount}>
        {loader && <div className='fullPageLoader'>
          <div className='loaderInner'>
            <FormSpinner />
            <h4>{lang?.Payment_warning}</h4>
          </div>
        </div>}
        <PaymentElement />
        <h1 style={{color:"red"}}>{checkErrorMessage}</h1>
        <br />
        <div className='order-address order__Checkout'>
          <div className='last_btn'>
            <button className='continue_Btn' onClick={() => history.push('/marketplace/my-cart')}>{lang?.View_cart}</button>
            <button className='checkout-btn check_Btn' type='submit' disabled={(loader || cartProducts?.length === 0)} >{loader ? `${lang?.Processing}...` : totalAmount ? ` $${totalAmount} ${lang?.Continue_to_pay}` : lang?.Continue_to_pay}</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default StripePayment;
