import language from "../helpers/language";
const lang = language.getLang();


const getJsonHeader = {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    locale: lang.lan
}


export const getHeaderWithJson = () => {
    return {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        locale: lang.lan
    };
}
export const getHeaderWithJsonOnlyUserRole = (lan) => {
    return {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        locale: lan
    };
}

export const HeadersWithToken = (token) => {
    return {
        Authorization: `Bearer ${token}`,
        locale: lang.lan
    };
}
export const HeadersWithTokenAndJson = (token, log = false) => {
    return {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: log ? token : `Bearer ${token}`,
        locale: lang.lan
    };
}

export default {
    getHeaderWithJson,
    HeadersWithToken,
    HeadersWithTokenAndJson,
    getHeaderWithJsonOnlyUserRole
}

