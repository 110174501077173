import React, { Fragment, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import constant from '../../../helpers/constant';
import language from '../../../helpers/language';

export const VisitorContact = () => {
  const {
    visitorProfile: { data },
  } = useSelector((state) => state.memberProfileData);
  const [lang] = useState(language.getLang());
  return (
    <Fragment>
      {/* Tab Content left Section */}
      <div className="tab-content-section">
        <div className="">
          <div className="profile-options-lists">
            <div className="edit__profile contact" id="edit__profile">
              <div className="contact-detail_list">
                  <div className="options-list-item">
                    <h4 className="font-size-18 fontNormal pb-2">
                      <i className="fa fa-envelope mr-2" />
                      {lang?.Email}
                    </h4>
                    {/* <p className="list-title">
                    <i className="far fa-envelope mr-2" />
                    <strong></strong>
                  </p> */}
                    <div className="list-item-name ">
                      <p className="font-14">{data?.contact_tab?.email}</p>
                    </div>
                  </div>
                  <div className="options-list-item ">
                    <p className="font-size-18 fontNormal pb-2">
                      <i className="fas fa-phone-alt mr-2" />
                      {lang?.Phone_number}
                    </p>
                    <div className="list-item-name">
                      {data && data.contact_tab && (
                        <p className="font-14">
                          {!data.contact_tab.country_code
                            ? constant.rolesId.producer ===
                              data.contact_tab.role_id
                              ? '+39'
                              : '+1'
                            : `+${data.contact_tab.country_code}`}
                          &nbsp;
                          {data.contact_tab.phone}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="options-list-item ">
                    <p className="font-size-18 fontNormal pb-2">
                      <i className="fas fa-location-arrow mr-2" />
                      {lang?.only_address}
                    </p>
                    <div className="list-item-name">
                      <p className="font-14 visitor-add">
                        {data?.contact_tab?.address1 != '' &&
                        data?.contact_tab?.address1 != null
                          ? `${data?.contact_tab?.address1}, `
                          : ''}{' '}
                        {data?.contact_tab?.address}
                      </p>
                    </div>
                  </div>

                {(data?.contact_tab?.website != null && data?.contact_tab?.website != '' && data?.contact_tab?.website != 'null' && data?.contact_tab?.website != 'undefined' && data?.contact_tab?.website != undefined) && (
                <div className="options-list-item">
                  <p className="font-size-18 fontNormal pb-2">
                    <i className="fas fa-home mr-2" />
                    {lang?.Website}
                  </p>
                  <div className="list-item-name">
                    <p className="font-14">
                      {data?.contact_tab?.website?.includes('https') ? (
                        <a
                          href={`${data?.contact_tab?.website}`}
                          target="_blank"
                        >
                          {data?.contact_tab?.website}
                        </a>
                      ) : (
                        <a
                          href={`https://${data?.contact_tab?.website}`}
                          target="_blank"
                        >
                          {data?.contact_tab?.website}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                )}
                {(data?.contact_tab?.fb_link != null && data?.contact_tab?.fb_link != '' && data?.contact_tab?.fb_link != 'null' && data?.contact_tab?.fb_link != 'undefined' && data?.contact_tab?.fb_link != undefined) && (
                  <div className="options-list-item">
                    <p className="font-size-18 fontNormal pb-2">
                    <i className="fab fa-facebook mr-2" />
                      {lang?.Facebook}
                    </p>
                    <div className="list-item-name">
                      <p className="font-14">
                        {data?.contact_tab?.fb_link?.includes('https') ? (
                          <a
                            href={`${data?.contact_tab?.fb_link}`}
                            target="_blank"
                          >
                            {data?.contact_tab?.fb_link}
                          </a>
                        ) : (
                          <a
                            href={`https://${data?.contact_tab?.fb_link}`}
                            target="_blank"
                          >
                            {data?.contact_tab?.fb_link}
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                )}

                {(data?.contact_tab?.inst_link != null && data?.contact_tab?.inst_link != '' && data?.contact_tab?.inst_link != 'null' && data?.contact_tab?.inst_link != 'undefined' && data?.contact_tab?.inst_link != undefined) && (
                  <div className="options-list-item">
                    <p className="font-size-18 fontNormal pb-2">
                    <i className="fab fa-instagram mr-2" />
                    Instagram
                    </p>
                    <div className="list-item-name">
                      <p className="font-14">
                        {data?.contact_tab?.inst_link?.includes('https') ? (
                          <a
                            href={`${data?.contact_tab?.inst_link}`}
                            target="_blank"
                          >
                            {data?.contact_tab?.inst_link}
                          </a>
                        ) : (
                          <a
                            href={`https://${data?.contact_tab?.inst_link}`}
                            target="_blank"
                          >
                            {data?.contact_tab?.inst_link}
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
