import React, { useState, useEffect, useRef } from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { deleteBillingAndDeliveryAddress, getAddressList } from '../../routes/marketplace/modules/marketplace';
import AddressCard from './AddressCard';
import { newAddressFields } from './AddressFormFields/AddressData';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from '../DeleteConfirmation';
import BillingForm from '../Checkbox/MarketPlaceCheckOutForm';
import { getRegion } from '../../routes/register/modules/register';
import language from '../../helpers/language';

export default function MyAddress() {
    const myRef = useRef(null);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [lang] = useState(language?.getLang());
    const [responseErrors, setResponseErrors] = useState({ message: '', type: false });
    const [loading, setLoading] = useState(false);
    const [selectAddress, setSelectAddress] = useState(false);
    const [formFields, setFormFields] = useState(newAddressFields);
    const [selectAddressId, setSelectAddressId] = useState({ type: '', id: '' });
    const [formType, setFormType] = useState('');
    const [addressId, setAddressId] = useState('');
    const [billingAddressList, setBillingAddressList] = useState([]);
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [reRender, setRerender] = useState(false);
    const [isSameAsShipping, setIsSameAsShipping] = useState(false);
    const [billingFormData, setBillingFormData] = useState({});
    const [shippingFormData, setShippingFormData] = useState({});
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const notes = lang?.Address_delete_msg;


    let { regions } = useSelector((state) => state.register);

    useEffect(() => {
        getAllAdrress();
    }, [reRender])

    useEffect(() => {
        regions?.length === 0 && getCountryRegion(233);
    }, []);

    const getCountryRegion = async (id) => {
        await dispatch(getRegion(id));
    }
    // Get Billing and Delivery address
    const getAllAdrress = async () => {
        const response = await getAddressList();
        if (response?.success) {
            setBillingAddressList(response.billing_address ?? []);
            setShippingAddressList(response.shipping_address ?? []);

        }
    }

    // handle address type 
    const handleModal = (type) => {
        setFormType(type);
    }

    // close address form
    const handleOpenForm = (formType) => {
        setSelectAddress(false);
        setFormType('');
        setFormFields(newAddressFields);
        setAddressId('')
    }

    // submit address from value
    const hadleSubmitData = () => {
        setRerender(!reRender);
        setBillingAddressList([]);
        setBillingFormData([]);
        setIsSameAsShipping(false);
        setFormFields(newAddressFields);
        setFormType('');
        setSelectAddress(false);
        setTimeout(() => {
            setResponseErrors({ message: "", type: false });
        }, 2000);

    }

    // handle delete address  type
    const deleteBillingOrShippingAddress = (type, id) => {
        setSelectAddressId({ type, id })
        setIsDeleteConfirmationVisible(true);
    }

    // delete confimation modal close
    const handleCancel = () => {
        setSelectAddressId({ type: "", id: "" })
        setIsDeleteConfirmationVisible(false);
    };

    // submit address delete info
    const handleDelete = async () => {
        const { type, id } = selectAddressId;
        if (type && id) {
            let listData = type === "billing" ? billingAddressList : shippingAddressList
            const deletedList = listData?.filter((item) => item?.id !== id);
            const response = await deleteBillingAndDeliveryAddress(type, id);
            if (response?.success === 200) {
                deletedList?.length > 0 &&
                    type === "billing" ? setBillingAddressList(deletedList ?? []) : setShippingAddressList(deletedList ?? [])
                handleCancel()
                setResponseErrors({ message: response?.message, type: true });
                scrollToRef(myRef);
            } else {
                setResponseErrors({ message: response?.message, type: false });
            }
            setTimeout(() => {
                setResponseErrors({ message: '', type: false });
            }, 1500)
        }
    };

    // scroll page on message
    const scrollToRef = (ref) => {
        window.scroll(0, ref.current && ref.current.offsetTop - 200);
    };


    const handleEditAddress = (type, data, id) => {
        setAddressId(id);
        setFormFields(formFields?.map((item) => ({ ...item, value: data[item?.name] })));
        handleModal(type); setSelectAddress(true);
        scrollToRef(myRef);
    }

    return (
        <div className="middleBlockBg">
            <div className='product-list custom__tab'>
                <div className='tax__mgmt'>
                    <div className='TabFlex'>
                        <div className='Tax_Tab'>
                            <Tabs className="TaxTabFlex" onSelect={() => { setFormType(""); setSelectAddress(false); setAddressId(''); }}>
                                <TabList>
                                    <Tab>{lang?.Billing_address}</Tab>
                                    <Tab>{lang?.Shipping_address}</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className='product-title'>
                                        <div className='flex flex-wrap justify-between items-center'>
                                            <h4 className='mb-4 fs-22 font-bold'>{lang?.Billing_address}</h4>
                                            <h4 className={`mb-4 fs-22 font-bold ${responseErrors?.type ? "textGreen" : "textRed"}`} ref={myRef}>{responseErrors?.message}</h4>
                                            <div className='inner-btn-box' onClick={() => { handleModal("billing"); setSelectAddress(!selectAddress); setAddressId(''); }}>
                                                {/* <p className='add_billing text-blue font-bold'>+ {lang?.Add_billing_details}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {(selectAddress && formType === "billing") && <div className='mb-6' ref={myRef}>
                                            <BillingForm lang={lang} sameAsShipping={isSameAsShipping} id={addressId} loading={loading} setResponseErrors={setResponseErrors} submitBtn={true} newAddressFields={formFields} formData={billingFormData} setFormData={setBillingFormData} formType={formType} setErrors={setErrors} errors={errors} setLoading={setLoading} hadleSubmitData={hadleSubmitData} handleCancel={handleOpenForm} />
                                            {(billingFormData?.first_name?.trim()?.length > 0 && addressId === '') && <div className='hub--checkbox--area'>
                                                <input type='checkbox' className='form-check-input' id="check1" name="select_item" onChange={(e) => {
                                                    setIsSameAsShipping(e.target.checked);
                                                }} />
                                                <label className='fs-16 font-bold form-check-label' htmlFor='check1'>Set shipping details same as billing details.</label>
                                            </div>}
                                        </div>}
                                        {billingAddressList?.length > 0 && billingAddressList?.map(({ id, checked, first_name, last_name, email, country, city, state, street_address, street_address_2, zipcode }) => {
                                            return (
                                                <AddressCard
                                                    id={id}
                                                    checked={checked}
                                                    first_name={first_name}
                                                    last_name={`${last_name}`}
                                                    email={email}
                                                    street_address_2={street_address_2}
                                                    street={`${street_address}`}
                                                    city={city}
                                                    state={state}
                                                    postalCode={zipcode}
                                                    country={country}
                                                    type="billing"
                                                    handleEdit={handleEditAddress}
                                                    deleteAddress={deleteBillingOrShippingAddress}
                                                />
                                            )
                                        })}
                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div className='product-title'>
                                        <div className='flex flex-wrap justify-between items-center'>
                                            <h4 className='mb-4 fs-22 font-bold'>{lang?.Shipping_address}</h4>
                                            <h4 className={`mb-4 fs-22 font-bold ${responseErrors?.type ? "textGreen" : "textRed"}`} ref={myRef}>{responseErrors?.message}</h4>
                                            <div className='inner-btn-box' onClick={() => { handleModal("shipping"); setSelectAddress(!selectAddress); setAddressId(''); }}>
                                                {/* <p className='add_billing text-blue font-bold'>+  Add Shipping Details</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        {(selectAddress && formType === "shipping") && <div ref={myRef}> <BillingForm lang={lang} setResponseErrors={setResponseErrors} id={addressId} loading={loading} submitBtn={true} newAddressFields={formFields} sameAsShipping={isSameAsShipping} formData={shippingFormData} setFormData={setShippingFormData} formType={formType} setErrors={setErrors} errors={errors} setLoading={setLoading} hadleSubmitData={hadleSubmitData} handleCancel={handleOpenForm} /></div>}
                                        {shippingAddressList?.length > 0 && shippingAddressList?.map(({ id, checked, first_name, last_name, email, country, city, state, street_address, street_address_2, zipcode }) => {
                                            return (
                                                <AddressCard
                                                    id={id}
                                                    checked={checked}
                                                    first_name={first_name}
                                                    last_name={`${last_name}`}
                                                    email={email}
                                                    street={`${street_address}`}
                                                    street_address_2={street_address_2}
                                                    city={city}
                                                    state={state}
                                                    postalCode={zipcode}
                                                    country={country}
                                                    type="shipping"
                                                    handleEdit={handleEditAddress}
                                                    deleteAddress={deleteBillingOrShippingAddress}
                                                />
                                            )
                                        })}
                                    </div>

                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            {isDeleteConfirmationVisible && (
                <DeleteConfirmation message={''} loader={false} onDelete={handleDelete} btnText={"Delete"} heading={"Confirm Delete"} subHeading={"Are you sure you want to delete this"} notes={notes} onCancel={handleCancel} title={'address'} />
            )}
        </div>
    )
}