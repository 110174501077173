import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import Certificate from '../../../components/user/certificates';
import language from '../../../helpers/language';
import SettingLeftNavigation from './SettingLeftNavigation'
export default function Password() {
  const [lang] = useState(language.getLang());

  useEffect(() => {
    //window.fcWidget.show() 
  },[])
  return (
    <div>
      {/* Layout Content */}
      <div className="home__page b2b hubMember">
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title"><h1 className="font-18 text-black">{lang?.Company}</h1></div>
              <div className='w-full'>
                <Certificate redirect_from="company" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
