import React, { useState } from 'react'
import SettingLeftNavigation from './SettingLeftNavigation'
import { HubView } from '../../hubs/components/HubView';
import language from '../../../helpers/language';

export default function HubsSelect() {
    const [lang] = useState(language.getLang());
    return (
        <div>
            {/* Layout Content */}
            <div className="home__page b2b hubMember">
                <div className="servics_blocks">
                    <div className="site-align top-align">
                        <SettingLeftNavigation />
                        <div className="servics_blocks middle-long__section-block middleBlockBg">
                            <div className="medium_title"><h1 className="font-18 text-black">{lang?.Hub_select}</h1></div>
                            {/* <div className='md:w-3/4'> */}
                                <HubView selectedHubs={true}/>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
