import React, { Fragment } from "react";
import Parse from 'html-react-parser';

export function Terms({ label, id,onChange }) {
  const handleChange = (e) => {
      onChange(e)
  
  };
  return (
    <Fragment>
      <div className="form__item form-check">
        <input type="checkbox" id={id} value="yes" onChange={(e)=>handleChange(e)}/>
        <label htmlFor={id}>{Parse(label)}</label>
      </div>
      <hr />
    </Fragment>
  );
}

export default Terms;
