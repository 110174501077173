import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Circle,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import language from "../../helpers/language";
// import { GoogleMapsAPI } from '../client-config';
// Geocode.setApiKey("AIzaSyDX4HE7708TQYkE0WoOlzTDlq7_9nneUHY");
Geocode.setApiKey("AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68");
Geocode.enableDebug();
class Map extends Component {
  constructor(props) {
    super(props);
    console.log(props,"props");
    this.state = {
      selectLang: language.getLang(),
      address: this.props.value,
      subAddress: this.props.center.address1,
      city: "",
      area: "",
      state: "",
      country: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      mapCenter: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }
  /*
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    if (!this.props?.value) {
      Geocode.fromLatLng(this.props.center.lat, this.props.center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray),
            country = this.getCountry(addressArray)

          this.setState({
            address: address ? address : "",
            area: area ? area : "",
            city: city ? city : "",
            state: state ? state : "",
            country: country ? country : "",
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }

  }
  /*
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.center.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state ||
      this.state.country !== nextState.country
    ) {
      return true
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false
    }
  }
  /*
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /*
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /*
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "administrative_area_level_1" === addressArray[i].types[0]) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addrComponents) => {
    for (var i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] == "country") {
        return addrComponents[i].short_name;
      }
      if (addrComponents[i].types.length == 2) {
        if (addrComponents[i].types[0] == "political") {
          return addrComponents[i].short_name;
        }
      }
    }
    return false;
  }
  /*
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /*
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => { };

  /*
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */

  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.props.center.lat = newLat
    this.props.center.lng = newLng
    // this.getReverseGeocodingData(newLat, newLng)
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          country = this.getCountry(addressArray)
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          country: country ? country : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapCenter: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );


  };


  /*
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      country = this.getCountry(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    this.props.onChange(place.formatted_address, latValue, lngValue);

    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: (area) ? area : '',
      city: (city) ? city : '',
      state: (state) ? state : '',
      country: (country) ? country : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapCenter: {
        lat: latValue,
        lng: lngValue
      },
    });
  };

  render() {
    const { address, area, city, state, country } = this.state
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.props.center.lat,
            lng: this.props.center.lng,
          }}
        >
          {/* InfoWindow on top of marker */}

          {/*Marker*/}
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.props.center.lat,
              lng: this.props.center.lng,
            }}
          >
            {!this.props.radius && this.state.address && (
              <InfoWindow
                onClose={this.onInfoWindowClose}
                position={{
                  lat: this.props.center.lat,
                  lng: this.props.center.lng,
                }}
              >
                <div>
                  <span style={{ padding: 0, margin: 0 }}>
                  {this.state.subAddress !== null && this.state.subAddress != '' ? `${this.state.subAddress}, ${this.state.address}` : this.state.address}
                  </span>
                </div>
              </InfoWindow>
            )}
            <Circle
              defaultCenter={{
                lat: this.props.center.lat,
                lng: this.props.center.lng,
              }}
              radius={this.props.radius}
              options={{ strokeColor: "#808080" }}
            />
          </Marker>
          {/* For Auto complete Search Box */}
          {!this.props.radius && (
            <Autocomplete
              style={{
                width: "100%",
                height: "40px",
                paddingLeft: "16px",
                marginTop: "2px",
                marginBottom: "500px",
              }}
              placeholder={"Enter your zip code"}
              onPlaceSelected={this.onPlaceSelected}
              defaultValue={address}
              // value={address}
              // value={`${city} ${state} ${country}`}
              types={['(regions)']}
              options= {{
              types: [],
              fields: [
                "address_components",
                "geometry.location",
                "place_id",
                "formatted_address",
              ]
            }}
            />
          )}
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          {/* {this.props.name === "address" && 
              <div className="form-group">
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  onChange={this.onChange}
                  readOnly="readOnly"
                  value={this.state.address}
                  // placeholder="Search Location"
                // defaultValue={this.props.value}
                />
              </div>
              } */}

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68"}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />} containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />} />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
