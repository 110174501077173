import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../routes/login/modules/login";
import logo from "../../assets/images/logo.png";
import FormSpinner from "../../components/Spinners/FormSpinner";
import Modal from "react-modal";
import auth from "../../helpers/auth";
import {
  voyagerRregister,
  resetPwdOtp,
  resetPassword,
  verifyUserEmaiId,
  setResponseErrors,
} from "../../routes/register/modules/register";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import language from "../../helpers/language";
import { fetchUserRoles } from "../../routes/AuthWrapper/modules/auth";

export function AuthHeader(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [lang] = useState(language.getLang());
  const { loginSpinner } = useSelector((state) => state.auth);
  const { resError } = useSelector((state) => state.register);
  // const {
  //   role_data: { roles },
  // } = useSelector((state) => state.authWrapper);
  // const { role } = props.match.params;
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [selectLanguage, setSelectLanguage] = useState("en")
  const [otp, SetOtp] = useState();
  const [voyagersEmail, SetVoyagersEmail] = useState();
  const [account_enabled, SetAccount_enabled] = useState();
  const [isEmailAndOtpTrue, setIsEmailAndOtpTrue] = useState(false);
  const [voyagersOtp, SetVoyagersOtp] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [forgotPwd, setForgotPwd] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [otpVerifyError, setOtpVerifyError] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState('');

  useEffect(() => {
    const allowPages = ["/setting", "/privacy", "/company", "/password","/blocking","/setting-membership","/settingbilling","/your-data","/FAQ"];
    if(!allowPages.includes(location.pathname)){
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.showWidget();
        }
      };
    }
  }, []);

  useEffect(async () => {
    const selectedLang = await localStorage.getItem("lang");
    await dispatch(fetchUserRoles(selectedLang ?? selectLanguage));
    setSelectLanguage(selectedLang ?? selectLanguage);
  }, []);


  const lowercase = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handlechange = async (event) => {
    const name = event.target.id;
    const value = event.target.value;
    if (name === "email") {
      setEmail(value);
    }
    else if (name === "password") {
      setPassword(value);
    }
    else if (name === "confirmPwd") {
      setConfirmPwd(value);
    }
  };

  const varifyOtp = async (e) => {
    await e.preventDefault();
    const model = {
      account_enabled: account_enabled,
      email: voyagersEmail,
      otp: otp,
    };
    await dispatch(voyagerRregister(model)).then(async (res) => {
      if (res && res.success === 200) {
        // history.push(`/login/${role}`);
        //document.body.classList.remove(`register`);
        
        await auth.setTokens(
          res && res.token,
          JSON.stringify(res && res.data.roles)
        );
        //console.log(res?.data?.alysei_review,'res?.data?.alysei_review..');
        SetVoyagersOtp(false);
        await localStorage.setItem("alysei_review", res?.data?.alysei_review);
        if(res?.data?.roles?.role_id === 11){
              history.push(`/buyer-dashboard`);
        }
        else{
          if(res?.data?.alysei_review === 0){
          history.push(`/membership`);
          }
          else{
            history.push(`/home`);
          }
        }
      }
      else{
         await setOtpVerifyError(res.message);
      }
      setTimeout(() => {
        setOtpVerifyError('')
      }, 3000);
    });
  };

  const submitHandler = async (e) => {
    await e.preventDefault();
    const loginData = {
      email: email,
      password: password,
    };
    if (email === '' || password === '') {
      await setLoginErrorMsg(lang?.Please_Enter_email_and_pass);
    } else {
      await dispatch(logIn({ ...loginData, history: props.history })).then(
        async (res) => {
          if (res?.success === 200) {
            if (res.data.account_enabled === "incomplete") {
              await dispatch(verifyUserEmaiId(res.data?.email));
              SetVoyagersOtp(true);
              SetVoyagersEmail(res.data.email);
              SetAccount_enabled(res.data.account_enabled);
            } else {
              document.body.classList.remove(`register`);
              await auth.setTokens(
                res && res.token,
                JSON.stringify(res && res.data.roles)
              );
              await localStorage.setItem("alysei_review", res?.data?.alysei_review);
              if(res?.data?.role_id === 11){
                  history.push(`/buyer-dashboard`);
              }
              else{
                //console.log(res?.data?.alysei_review,'res?.data?.alysei_review..');
                const isGetingPublicPost = localStorage.getItem("post-url");
                isGetingPublicPost ? history.push(`${isGetingPublicPost}`) : history.push("/home");
              }
            }
          } else {
            setLoginErrorMsg(res?.error);
          }
        }
      );
    }
    setTimeout(() => {
      setLoginErrorMsg('')
    }, 3000);
  };

  const selectLang = (e) => {
    setSelectLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    window.location.reload();
  };

  const forgotPassword = async (event) => {
    event.preventDefault();
    if (email !== undefined) {
      const response = await dispatch(verifyUserEmaiId(email));
      if (response.success === 200) {
        setVerifyOtp(true);
        setForgotPwd(false);
      } else {
        setValidEmail('Invalid email ID')
      }

    }
    setTimeout(() => {
      setValidEmail('')
    }, 3000)

  };

  const verifyPwdOtp = async (event) => {
    event.preventDefault();
    await setIsEmailAndOtpTrue(true);
    const model = {
      email: email,
      otp: otp,
    };
    await dispatch(resetPwdOtp(model)).then((res) => {
      if (res && res.success === 200) {
        setResetPwd(true);
        setIsEmailAndOtpTrue(false);
      } else {
        setIsEmailAndOtpTrue(false);
        setValidEmail(res.message);
        SetOtp();
      }
    });
    setTimeout(() => {
      setValidEmail('')
    }, 3000)
  };

  const changePwd = async (event) => {
    event.preventDefault();
    await setIsResetPassword(true);
    var digit = /(\d+)/;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let result = format.test(password);
    let digitResult = digit.test(password);
    const model = {
      email: email,
      password: password,
      confirm_password: confirmPwd,
    };

    if (digitResult && result) {
      await dispatch(resetPassword(model)).then((res) => {
        if (res && res.success === 200) {
          setResetPasswordSuccess('Password has been reset successfully.');
          setTimeout(() => {
            SetOtp();
            setResetPwd(false);
            setForgotPwd(false);
            setVerifyOtp(false);
            setResetPasswordSuccess("");
          },3000);

        } else {
          SetOtp();
        }
      });
    } else {
      dispatch(setResponseErrors(lang?.ValidatePass))

    }
    await setIsResetPassword(false);
    setTimeout(() => {
      setValidEmail('')
      dispatch(setResponseErrors(''));
    }, 3000)
  };

  const resendOtp = async (email) => {
    const response = await dispatch(verifyUserEmaiId(email));
    if (response.success === 200) {
      dispatch(setResponseErrors(lang?.OTP_has_been_send_successfully));
      setValidEmail()
    } else {
      setValidEmail('somethings went wrong.')
    }
    setTimeout(() => {
      setValidEmail('');
      dispatch(setResponseErrors(''));
    }, 3000)
  }

  const togglePassword = (type) => {
    type === 1 && setPasswordShown(!passwordShown);
    type === 2 && setConfirmPassword(!confirmPassword);

  };
  return (
    <Fragment>
      <div className="site__header">
        <div className="site__width">
          <div className="site-align">
            <div className="site__logo auth">
              <Link to="/home">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="mobile--login--btn md:order-2">
              <Link to="/login">{lang?.Login}</Link>
            </div>
            <select className="md:order-1 enMob" id="hor" onChange={(e) => selectLang(e)} value={selectLanguage}>
              <option value="en">Eng</option>
              <option value="it">Italain</option>
            </select>
            <select className="md:order-1 enDesktop" id="hor" onChange={(e) => selectLang(e)} value={selectLanguage}>
              <option value="en">{lang?.English}</option>
              <option value="it">{lang?.Italian}</option>
            </select>
            {/* <div className="form__item hub select--field ml-5">
              <select id="hor" onClick={(e) => selectLang(e)}>
                <option>English</option>
                <option>Italain</option>
              </select>
            </div> */}
            <div className="login__form">
              <form onSubmit={(e) => submitHandler(e)}>
                <div className="form__item">
                  <input
                    id="email"
                    type="email"
                    placeholder={lang?.Email}
                    value={email}
                    onChange={(e) => {
                      handlechange(e);
                      lowercase(e);
                    }}
                  />
                </div>
                <div className="form__item relative">
                  <span className="eye_show">
                    <i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(1)} aria-hidden="false"></i>
                  </span>
                  <input
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => handlechange(e)}
                  />
                </div>
                <div className="form__action form__item">
                  <button type="submit">
                    {loginSpinner ? <FormSpinner /> : `${lang?.Login}`}
                  </button>
                  <span className="form__forgot-link">
                    <Link
                      to="#"
                      onClick={() => {
                        setForgotPwd(!forgotPwd);
                      }}
                    >
                      {lang?.Forget_password}
                    </Link>
                  </span>
                </div>
              </form>
              <p style={{ color: "red" }}>{loginErrorMsg}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Popup Start */}

      {forgotPwd && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={forgotPwd}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Forget_password}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setForgotPwd(!forgotPwd)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-center mb-6">{lang?.Enter_your_email_recovery}
              </p>
              <p style={{ color: 'red' }}>{validEmail}</p>
              <form className="" onSubmit={(event) => forgotPassword(event)}>
                <div className="form__action">
                  <div className="form__item">
                    <input
                      type="text"
                      placeholder="Email"
                      id="email"
                      name="email"
                      required
                      onChange={(e) => {
                        handlechange(e);
                        lowercase(e);
                      }}
                    />
                  </div>
                  <button type="submit" className="btn w-full mt-6 mb-2">
                    {lang?.Forget_password}
                  </button>
                </div>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

      {/* Forgot Password Popup End */}

      {/* Verify Otp Start */}
      {verifyOtp && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={verifyOtp}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Alysei}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setVerifyOtp(!verifyOtp); setValidEmail('') }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p className="text-blue text-center mb-8">
                {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{" "}
                <strong>{email}</strong>
                <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
              </p>
              <p className="text-red-500">{validEmail}</p>
              <p className="text-green">{resError}</p>
              <form className="mb-2" onSubmit={(event) => verifyPwdOtp(event)}>
                <div className="form__action otp">
                  <OtpInput
                    value={otp}
                    onChange={(otp) => SetOtp(otp)}
                    numInputs={6}
                 />
                  <button type="submit" className="btn w-full mt-6 mb-6">
                    {isEmailAndOtpTrue ? <FormSpinner /> : "Reset Password"}
                  </button>
                  <p>
                    {lang?.Didnot_receive_the_OTP}{" "}
                    <Link to="#" onClick={() => resendOtp(email)} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                  </p>
                </div>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}
      {/* Verify Otp End */}

      {/* Reset Password Popup Start */}
      {resetPwd && (
        <div>
          <Modal
            ariaHideApp={false}
            isOpen={resetPwd}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title text-center">{lang?.Reset_Password}</h4>
              <button
                className="close_btn--modal"
                onClick={() => { setResetPwd(false); setVerifyOtp(false); SetOtp() }}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box popUp">
              <p style={{ color: "red" }}>{resError}</p>
              <form className="mb-2" onSubmit={(event) => changePwd(event)}>
                <div className="form__action">
                  <div className="form__item relative">
                    <span className="eye_show">
                      <i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(1)} aria-hidden="false"></i>
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder={lang?.New_Password}
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                  <div className="form__item relative">
                    <span className="eye_show">
                      <i className={!confirmPassword ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => togglePassword(2)} aria-hidden="false"></i>
                    </span>
                    <input
                      type={confirmPassword ? "text" : "password"}
                      placeholder={lang?.Confirm_Password}
                      name="confirmPwd"
                      id="confirmPwd"
                      value={confirmPwd}
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                  <p className="text-center mb-6 mt-6">
                    {lang?.PassWordValidation}
                  </p>
                  <button type="submit" className="btn w-full" disabled={isResetPassword}>
                    {isResetPassword ? <FormSpinner/> : lang?.only_Submit}
                  </button>
                </div>
                <p style={{color:'green',padding:'10px 0px 0px 0px',textAlign:'center',fontSize:'16px'}}>{resetPasswordSuccess}</p>
              </form>
            </div>
            <div></div>
          </Modal>
        </div>
      )}

      {/* Reset Password Popup End */}

      {
        <div>
          {voyagersOtp && (
            <div>
              <Modal
                ariaHideApp={false}
                isOpen={voyagersOtp}
                style={{
                  overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.75)",
                  },
                  content: {
                    position: "absolute",
                    top: "0",
                    left: "0%",
                    right: "0%",
                    bottom: "200px",
                    border: "1px solid #ccc",
                    background: "#fff",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    padding: "0",
                  },
                }}
              >
                <div className="popup_header flex justify-between items-center">
                  <h4 className="page__title text-center">{lang?.Alysei}</h4>
                  <button
                    className="close_btn--modal"
                    onClick={() => SetVoyagersOtp(false)}
                  >
                    {lang?.close}
                  </button>
                </div>
                <div className="card card--block modal_box popUp">
                  <p className="text-blue text-center mb-8">
                    {lang?.We_have_sent_you_a_6_digit_verification_code_OTP}{" "}
                    <strong>{voyagersEmail}</strong>
                    <p className="text-blue text-center mb-8">{lang?.SPAM_FOLDER}.</p>
                  </p>
                  {/* <p className="text-red-500">{validEmail}</p> */}
                  <p className="text-green">{resError}</p>
                  <form className="mb-2" onSubmit={(event) => varifyOtp(event)}>
                    <label>{lang?.Verify_Otp}</label>
                    <div className="form__action otp">
                      <OtpInput
                        value={otp}
                        onChange={(otp) => SetOtp(otp)}
                        numInputs={6}
                      />
                      <p className="text-red-500">{otpVerifyError}</p>
                      <button type="submit" className="btn w-full mt-6 mb-6">
                        {lang?.Save}
                      </button>
                      <p>
                        {lang?.Didnot_receive_the_OTP}{" "}
                        <Link to="#" onClick={() => resendOtp(voyagersEmail)} className="title-color font-semibold">{lang?.Resend_OTP}</Link>
                      </p>
                    </div>
                  </form>
                </div>
                <div></div>
              </Modal>
            </div>
          )}</div>
      }
    </Fragment>
  );
}

export default AuthHeader;
