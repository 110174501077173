import React from 'react'

export default function PartnerLogoView(props) {
    return (
        <>
            <div className='partnerLogo'>
                {props.Logo &&
                    <img src={props.Logo} alt="Logo 1" />
                }
            </div>
            {/* <div className='partnerLogo'>
            <img src={logo2} alt="Logo 1" />
        </div>
        <div className='partnerLogo'>
            <img src={logo3} alt="Logo 1" />
        </div>
        <div className='partnerLogo'>
            <img src={logo4} alt="Logo 1" />
        </div>
        <div className='partnerLogo'>
            <img src={logo5} alt="Logo 1" />
        </div> */}
        </>
    )
}
