import React from 'react'
import BuyerHeader from '../DashboardView/BuyerHeader'
import SideBar from '../SideBar'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from "react-paginate";
import FormSpinner from '../../../../components/Spinners/FormSpinner';
import { fetchAllNotification } from '../../../home/modules/HomeModule';

export default function BuyerNotificationView() {
    const dispatch=useDispatch()
    const { notificationData, getNotificationSpinner } = useSelector((state) => state.activityFeedStatus);

    const handlePageClick = async (e) => {
        const pageNo = e.selected + 1;
        await dispatch(fetchAllNotification(pageNo));
      };
    return (
        <>
            <section className="buyerDashboard">
                <div className="dashboardLayout">
                    <div className="headerBlock">
                        <BuyerHeader pageTitle="View all notifications" />
                    </div>
                    <div className="sideBarBlock">
                        <SideBar />
                    </div>
                    <div className="centerContent">
                        <div className="pageContent">
                            <div className="card card--block modal_box">
                                {
                                   !getNotificationSpinner? notificationData?.data?.length > 0 && notificationData?.data?.map((item)=>{
                                        return(
                                            <>
                                            <div className="feed-user-info home_page noti mb-1">
                                                <div className="user__pic">
                                                <img src="https://devapi.alysei.com/images/admin-images.png" alt="Profile image" />
                                                </div>
                                                <div className="user__detail-data w-full">
                                                <div className="flex">
                                                    <span className="user__name w-75">
                                                    <strong>{item.sender_name}</strong>
                                                    </span>
                                                    <div className="just__Now text-right w-25">

                                                    <Link to="/home">{moment(item.created_at).fromNow()}</Link>
                                                    </div>
                                                </div>
                                                <span className="user__name">{item.title}</span>
                                                </div>
                                            </div>
                                            <hr />
                                            </>
                                        )
                                    }):<FormSpinner/>
                                }
                                  <hr />
                                {notificationData?.total > 10 &&
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil((notificationData?.total) / (notificationData?.per_page))}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}   
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />
                                    }
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
