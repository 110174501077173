import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Redirect, useHistory } from 'react-router-dom';

import Trending from './Trending';
import ExploreTab from './Explore';
import MyRecipeTab from './MyRecipe';
import RecipeSlider from './RecipeSlider';
import MyPreferences from "../components/MyPreferences";
import { removeSelectedToolAndIngredients, fetchPreferences, fetchExplore, fetchMyFavouriteRecipe, fetchMyAllRecipe, recipeGeneralInformation, tabIndexFun } from '../modules/RecipeModule'

// ................css............
import '../../../assets/recipe/recipe.css'
import '../../../assets/recipe/style.css'
import { Link } from 'react-router-dom';
import hat from "../../../assets/images/hat-icon.svg";
import language from '../../../helpers/language';
import { setProfileTabIndex } from '../../profile/modules/profile';
import constant from '../../../helpers/constant';
import auth from '../../../helpers/auth';
import firebase from "../../../config/firebase";

export default function RecipeView(props) {
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const [isLike, setIsLike] = useState(false)
  const [tempVal, setTempVal] = useState()
  const [isMyRecipe, setIsMyRecipe] = useState(0)
  const [isFavouriteRecipe, setIsFavouriteRecipe] = useState()
  const [havingPreferences, setHavingPreferences] = useState(parseInt(localStorage.getItem("having_preferences")) || 0);
  const { tabIndexData } = useSelector((state) => state.recipedata)
  const history = useHistory()
  const [alyseiReview, setAlyseiReview] = useState(false);
  const [typeOfUser, setTypeOfUser] = useState("");

  const { profileData } = useSelector((state) => state.memberProfileData);

  
  useEffect(() => {
    localStorage.removeItem('recipe_id');
    localStorage.removeItem('generalInformation');
    localStorage.removeItem('savedIngredients');
    localStorage.removeItem('savedTools');
    localStorage.removeItem('recipe_step');
    document.body.classList.remove("hidePageScroll");
  },[])
  useEffect(async () => {
    document.body.classList.remove('profile');
    await dispatch(recipeGeneralInformation({}));
    await dispatch(setProfileTabIndex(0));
  }, [tempVal]);

  useEffect(async() => { await localStorage.removeItem('recipe_step')},[]);
  useEffect(async () => { await dispatch(fetchExplore()) }, [])
  useEffect(async () => { await dispatch(fetchPreferences()) }, [])
  useEffect(async () => { await dispatch(removeSelectedToolAndIngredients()) }, [])
  useEffect(async () => { await dispatch(fetchMyFavouriteRecipe()).then((res) => { if (res && res.success === 409) setIsLike(true); setIsFavouriteRecipe(res?.errors?.exception) }) }, [])
  useEffect(async () => { await dispatch(fetchMyAllRecipe()).then((res) => { if (res && res.success === 409) { setIsMyRecipe(res?.errors?.exception) } }) }, [])
  
  useEffect(async () => {
    const userType = await auth.getUserType()
    await setTypeOfUser(JSON.parse(userType));
  }, []);
  
  useEffect(async () => {
    const userId = await profileData?.data?.user_data?.user_id
    if (userId) {
      const fetchUserNode = firebase.database().ref("users").child(userId)
      fetchUserNode.on("value", async (snapshot) => {
        const response = snapshot.val();
        await setAlyseiReview(response?.alysei_approval);
      });
    }
  }, [profileData?.data?.user_data?.user_id, alyseiReview, localStorage.getItem("alysei_review")]);

  const handleClick = async () => {
    await setTempVal(tempVal + 1)
    setHavingPreferences(1)
    history.push("/recipes")
  }

  return (
    <Fragment>
      {(JSON.parse(auth.getUserType())?.role_id !== constant.rolesId.voyagers) && localStorage.getItem("alysei_review") != true && (alyseiReview === 'false' || alyseiReview === false)
        ? <Redirect to='/membership' />
        :
        <div className="recipe__page bg_transparent no_padding">
          <div className="recipe_content">
            {/* ---------------------Banner Slider section ----------------*/}
            <RecipeSlider preferencesFun={handleClick} />
            <div className='mrkt_des noBorderBtm'>
              <div className='flex justify-between items-center'>
                <div className='font-32 w-full'>{"Recipe Tool"}</div>
                <div className="create_recipe-btn">  {<Link to="/recipes/create-recipe" className='btn'> <img src={hat} /> {lang?.Create_Recipe}  </Link>} </div>
                {/* <div className="create_recipe-btn">  {havingPreferences === 1 && <Link to="/recipes/create-recipe" className='btn'> <img src={hat} /> {lang?.Create_Recipe}  </Link>} </div> */}
              </div>
              <p className="mt-4"> {lang?.The_Alysei_Recipe_Tool_is_a_world_of_good} </p>
            </div>



            {/* -----------------Tabs--------------- */}
            <div className="tab__list">
              <Tabs selectedIndex={tabIndexData} onSelect={index => dispatch(tabIndexFun(index))}>
                <TabList className="TabUpperList">
                  <Tab className="TabInnerList">{lang?.Explore}</Tab>
                  <Tab className="TabInnerList">{lang?.Favourite}</Tab>
                  <Tab className="TabInnerList">{lang?.MyRecipes}</Tab>
                  <Tab className="TabInnerList">{lang?.MyPreferences}</Tab>
                </TabList>

                {/* --------------Explore tab----------------- */}
                <TabPanel className="Tab__data"><ExploreTab /></TabPanel>
                {/* ---------------Favourite tab----------------- */}
                <TabPanel className="Tab__data">
                  <div className="section__wrapper site__width recipe__section">{isLike ? <b>{isFavouriteRecipe}</b> : <Trending />}</div>
                </TabPanel>

                {/* ------------------My Recipes------------------ */}
                <TabPanel className="Tab__data"><MyRecipeTab error={isMyRecipe} /> </TabPanel>

                {/* ------------------My Preferences---------------- */}
                <TabPanel className="Tab__data"> <MyPreferences /> </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

