import React, { useRef } from 'react'
import Slider from "react-slick";
import PartnerLogoView from './PartnerLogoView'
import { useSelector } from 'react-redux';
import FormSpinner from '../../../../components/Spinners/FormSpinner';



export default function PartnerView() {
    let { dashboardPartnerLogo, loadDashboardDataSpinner } = useSelector((state) => state.buyerModuleFilterData);
    let sliderRef = useRef(null);

    console.log(dashboardPartnerLogo, "dashbdafs")

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };
    return (
        <>
            <div className='partnerView card card--block'>
                <h5 className='fs-24 font-bold text-blue'> Alysei Producers</h5>
                <div className='mt-8'>
                    <div className="slider-container">

                        {loadDashboardDataSpinner ? <FormSpinner /> :
                            <Slider ref={slider => (sliderRef = slider)} {...settings}>
                                {dashboardPartnerLogo.length > 0 && dashboardPartnerLogo.map(({ attachment }) => {
                                    return (<PartnerLogoView
                                        Logo={`${attachment?.base_url}${attachment?.attachment_url}`}
                                    />)
                                })}
                            </Slider>

                        }

                    </div>

                </div>
            </div>
        </>
    )
}
