import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  fetchAllHubs,
  fetchAllRole,
  fetchImpAndDistHubs,
  fetchItalianFBProducerLabels,
  productTypeField,
} from '../modules/B2B';
import constant from '../../../helpers/constant';
import MultiSelecter from '../../../components/MutiSelect/MultiSelect';
import Checkbox from '../../../components/Checkbox/Checkbox';
import language from '../../../helpers/language';
import { Multiselect } from 'multiselect-react-dropdown';

export default function ImporterSearchBar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const labelRef = useRef(null);
  const [heroca, setHeroca] = useState('');
  const [hubsId, setHubsId] = useState([]);
  const [userType, setUserType] = useState('');
  const [importerRoleId, setImporterRoleId] = useState([]);
  const [privateLabel, setPrivateLabel] = useState('');
  const [roleSearchType, setroleSearchType] = useState(2);
  const [alyseiBrandLabel, setAlyseiBrandLabel] = useState('');
  const [productTypeListImp, setProductTypeListImp] = useState([]);
  const [lang] = useState(language.getLang());
  const [searchLabels, setSearchLabels] = useState([{ key: lang?.select, value:''}]);
  const [productTypeImpArray, setProductTypeImpArray] = useState([]);
  const { getAllHubs, getAllRole, getAllItalianFbProducerLabels, productField } = useSelector(
    (state) => state.b2bState
  );

  // useEffect(async () => { await getImporterSearch(); }, []);

  useEffect(async () => {
    // await dispatch(fetchAllHubs());
    await dispatch(fetchAllRole());
    await dispatch(productTypeField(roleSearchType));
    await dispatch(fetchItalianFBProducerLabels(40));
    document.getElementById('importer_hubs_input').placeholder = lang?.select;
    document.getElementById('importer_roles_input').placeholder = lang?.select;
  }, []);

  useEffect(async() => {
    await setHubsId(props?.hubsId != undefined ? props?.hubsId : '');
  },[])

  useEffect(async() => {
    if (productField.type == 'checkbox') {
      productField.options &&
      productField.options.map((item) => {
          let opt = [];
          item.options.map((i) => {
            i.options.map((o) => {
              opt.push(o.user_field_option_id);
            });
          });
          let allCheckbox = {
            id: item.user_field_option_id,
            subopt: opt
          }
          productTypeImpArray.push(allCheckbox);
        });
    }
    await setProductTypeImpArray(productTypeImpArray);
  },[productField]);
  
  const getImporterSearch = async () => {
    const impAndDisHubs = {
      searchType: roleSearchType,
      roleId: constant.rolesId['importer&distributer'],
      hubsId: hubsId,
      heroca: heroca,
      userType: importerRoleId,
      privateLabel: privateLabel,
      alyseiBrandLabel: alyseiBrandLabel,
      productType: productTypeListImp,
      page: 1,
    };
    props.getStatesOfSearchBar(impAndDisHubs);
    await dispatch(fetchImpAndDistHubs(impAndDisHubs));
    //console.log(impAndDisHubs,"impAndDisHubs");
  };

  const handImporterSearch = async (event) => {
    event.preventDefault();
    await getImporterSearch();
  };

  const handleMultiSelect = async (id, selectedList) => {
    const arr = [];
    (await selectedList) && selectedList.map((item) => arr.push(item.id));
    setHubsId(arr);
  };

  const handleMultiSelectUserType = async (id, selectedList) => {
    const arr = [];
    (await selectedList) && selectedList.map((item) => arr.push(item.role_id));
    setImporterRoleId(arr);
  };

  const handleCheckBoxImp = async (e, isDefault) => {
    if (e.target.checked) {
      //console.log(e.target.value,"checked");
      productTypeListImp.push(parseInt(e.target.value));
      let uniqueArray = [...new Set(productTypeListImp)];
      await setProductTypeListImp(uniqueArray);
    } else {
      let uniqueArray = [...new Set(productTypeListImp)];
      const index = uniqueArray.indexOf(parseInt(e.target && e.target.value));
      if (index > -1) uniqueArray.splice(index, 1);
      let found = productTypeImpArray.some(el => el.id === parseInt(e.target.value));
      if(found){
        let resultObject = productTypeImpArray.find(o => o.id === parseInt(e.target.value));
        const iterator = resultObject.subopt.values();
        for (const value of iterator) {
          const index = uniqueArray.indexOf(parseInt(value));
          if (index > -1) uniqueArray.splice(index, 1);
        }
      }
      await setProductTypeListImp(uniqueArray);
    }
  };

  useEffect(async() => {
    if(searchLabels.length === 1){ 
      getAllItalianFbProducerLabels && getAllItalianFbProducerLabels?.options?.length > 0 &&  getAllItalianFbProducerLabels?.options?.map(async(option) => {
        const label = {
          key: option.option, 
          value: option.user_field_option_id 
        }
        await setSearchLabels((searchLabels) => [
          ...searchLabels,
          label,
        ]);
      })
    }
  },[getAllItalianFbProducerLabels])

  // Select Label
  const changeSearchLabel = async(e) => {
    setPrivateLabel(e[0].value);
  }

  //console.log(productTypeListImp,"importer");
  return (
    <div className="left__section-block sm:mb-2 md:mb-2">
      <div className="card card--block ">
        <div className="hubsTitle">
          <h4 className="font-18">{lang?.Importer_And_DistributorB2B}</h4>
        </div>
        <div className="card-form p-3">
          {/* <h6 className="font-14 pb-3">Search Hubs</h6> */}
          <form onSubmit={(event) => handImporterSearch(event)}>
            {props.hubShow && (
              <div className="form__item">
                <MultiSelecter
                  displayValue={'title'}
                  Options={getAllHubs}
                  label={'Hubs'}
                  id="importer_hubs"
                  placeholder={lang?.select}
                  required={'no'}
                  fireData={(id, selectedList) =>
                    handleMultiSelect(id, selectedList)
                  }
                />
              </div>
            )}
            <div className="form__item">
              <MultiSelecter
                displayValue={'name'}
                Options={getAllRole && getAllRole.importer_roles}
                label={lang?.User_Type}
                required={'no'}
                id="importer_roles"
                placeholder={lang?.select}
                fireData={(id, selectedList) =>
                  handleMultiSelectUserType(id, selectedList)
                }
              />
            </div>
            {productField !== undefined && (
              <Checkbox
                key={productField.user_field_id}
                label={productField.title}
                type={productField.type}
                placeholder={productField?.placeholder}
                Options={productField.options}
                id={productField.user_field_id}
                selectedCheckbox={productTypeListImp}
                Ref={productField.user_field_id}
                onChange={(e, isDefault) => handleCheckBoxImp(e, isDefault)}
              />
            )}
            <div className="form__item">
            <div className="form-desc">{lang?.Hotel_Restaurant_Café}</div>
              {/* <select
                id="hor"
                onChange={(event) => setHeroca(event.target.value)}
              >
                <option value="">{lang?.select}</option>
                <option value="yes">{lang?.yes}</option>
                <option value="no">{lang?.no}</option>
              </select> */}
              <Multiselect
                options={[{ key: lang?.select, value:''},{ key: lang?.yes, value:'yes'},{ key: lang?.no, value:'no'}]}
                displayValue="key"
                placeholder={lang?.select}
                onSelect={(event) => setHeroca(event[0].value)}
                id={'fda__certificate'}
                singleSelect
                ref={labelRef}
              />
              
            </div>
            <div className="form__item">
            <div className="form-desc">{lang?.Label}</div>
              {/* <select
                id="pl"
                onChange={(event) => setPrivateLabel(event.target.value)}
              >
              getAllTitle?.options
                <option value="">{lang?.select}</option>
                <option value="yes">{lang?.yes}</option>
                <option value="no">{lang?.no}</option>
              </select> */}
              
              <Multiselect
                options={searchLabels}
                displayValue="key"
                placeholder={lang?.select}
                onSelect={changeSearchLabel}
                id={'fda__certificate'}
                singleSelect
                ref={labelRef}
              />
              
            </div>
           <div className="form__item">
            <div className="form-desc">{lang?.Alysei_Brand_Label}</div>
              <Multiselect
                options={[{ key: lang?.select, value:''},{ key: lang?.yes, value:'yes'},{ key: lang?.no, value:'no'}]}
                displayValue="key"
                placeholder={lang?.select}
                onSelect={(event) => setAlyseiBrandLabel(event[0].value)}
                id={'fda__certificate'}
                singleSelect
                ref={labelRef}
              />
            </div>
            <div className="fomr-actions">
              <button className="btn btnBgPrimary" type="submit">
                {' '}
                {lang?.Search}{' '}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
