import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Circle,
} from "react-google-maps";
import Geocode from "react-geocode";
import language from "../../helpers/language";
Geocode.setApiKey("AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68");
Geocode.enableDebug();
class StoreMap extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.center.address,"address");
    this.state = {
      selectLang: language.getLang(),
      address: this.props.center.address,
      city: "",
      area: "",
      state: "",
      country: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      mapCenter: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }






  /*
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /*
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => { };

  render() {
    const { address, area, city, state, country } = this.state
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.props.center.lat,
            lng: this.props.center.lng,
          }}
        >
          {/* InfoWindow on top of marker */}

          {/*Marker*/}
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={false}
            position={{
              lat: this.props.center.lat,
              lng: this.props.center.lng,
            }}
          >
            {this.state.address && (
              <InfoWindow
                onClose={this.onInfoWindowClose}
                position={{
                  lat: this.props.center.lat,
                  lng: this.props.center.lng,
                }}
              >
                <div>
                  <span style={{ padding: 0, margin: 0 }}>
                  {this.state.address}
                  </span>
                </div>
              </InfoWindow>
            )}
          </Marker>
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBVNQEQqCG-NFtXnwT7g4BAwT6yWN67J68"}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />} containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />} />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default StoreMap;
