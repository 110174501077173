import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';
import { setChangePassword } from '../module/UserSetting';
import SettingLeftNavigation from './SettingLeftNavigation'
export default function Password() {

  const dispatch = useDispatch()
  const [oldPwd, setOldPwd] = useState('')
  const [newPwd, setNewPwd] = useState('')
  const [lang] = useState(language.getLang());
  const { changePwdSpinner } = useSelector((state) => state.userSettingState)

  useEffect(() => {
    //window.fcWidget.show() 
  },[]);
  const changePassword = async () => {
    await dispatch(setChangePassword({ old_password: oldPwd, new_password: newPwd }))
    await setNewPwd('');
    await setOldPwd('')
  }

  return (
    <div>
      {/* Layout Content */}
      <div className="home__page b2b settings">
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title"><h1 className="font-18 text-black">{lang?.Password}</h1></div>
              <div className='md:w-3/4 mt-6'>
                <form className="mt-4">
                  <h4 className='font-16 text-normal mb-4'>{lang?.Changing_your_password_will_log_you_off_any_other_devices}</h4>
                  <div className='form__item'>
                    <label className='font-16 text-normal'>{lang?.Current_Password}</label>
                    <input type="password" placeholder="Enter your old password" name="" value={oldPwd} onChange={(e) => setOldPwd(e.target.value)} />
                  </div>
                  <div className='form__item'>
                    <label className='font-16 text-normal'>{lang?.New_Password}</label>
                    <input type="password" placeholder="Enter your New password" name="" value={newPwd} onChange={(e) => setNewPwd(e.target.value)} />
                  </div>
                  <div className="alert alert-gray">
                    <p>
                      {lang?.validation_of_password}
                    </p>
                  </div>
                </form>
                <div className='btnBox'>
                  <Link to="#" onClick={() => changePassword()} className='btn btnPrimary'>{changePwdSpinner ? <FormSpinner /> : `${lang?.Save_Changes}`}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
