import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function Input({ type, label, placeholder, id, name, itemkey, onChange, value, }) {
  const [inputValue, setInputValue] = useState("");
  const [maxlength, setmaxlength] = useState("");
  const [tempval, setTempval] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(async () => { await setInputValue(value) }, []);

  const handlechange = (e) => {
    const regEx = /^[0-9\d]+$/;
    const format = /^[0-9]+$/;
    let strLength = e.target.value.length;
    if (e.target.name === "vat_no") {
      if (e.target.value === "" || regEx.test(e.target.value)) {
        onChange(e);
        setmaxlength(12);
        setInputValue(e.target.value);
      } else {
        if (strLength === 1) {
          setInputValue("");
        }
      }
    } else if (e.target.name === "zip_postal_code") {
      onChange(e);
      setmaxlength(10);
      let val = e.target.value;
      e.target.value = val.replace(/\D/g, "").replace(/(\d{1,5})(\d{1,4})?/g, function (txt, f, s) {
        if (s) { return `${f}-${s}` }
        else if (f) { return `${f}` }
      });
      setInputValue(e.target.value);

    } else {
      setInputValue(e.target.value);
      onChange(e);
    }
  };
  const togglePassword = (type) => {
    setPasswordShown(!passwordShown);
  };
  return (
    <Fragment>
      <div className="form__item relative" key={itemkey}>
        <label htmlFor={id}>{label}</label>
        {/* {name === 'contact_number' ?
          <span className="countryCode">+91 </span> : ""
        } */}
        {name === 'vat_no' || name === 'contact_number' || name === "zip_postal_code" ?
          <input
            maxLength={name === "zip_postal_code" || name === 'contact_number' ? 15 : 11}
            type={name === 'contact_number'?"number":type}
            placeholder={placeholder}
            id={id}
            name={name}
            value={inputValue}
            onChange={(e) => handlechange(e)}
          /> :
          <>
            {label === undefined && type === "password" && <span className="eye_show "><Link onClick={() => togglePassword()}><i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}></i></Link></span>}
            {label != null && type === "password" && <span className="eye_show withLabel"><Link onClick={() => togglePassword()}><i className={!passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}></i></Link></span>}
            <input type={type === "password" ? (type === "password" && passwordShown) ? "text" : "password" : type} placeholder={placeholder} id={id} name={name} value={inputValue} onChange={(e) => handlechange(e)} />
          </>

        }
      </div>
      <hr />
    </Fragment>
  );
}

export default Input;
