import React, { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Cropper from 'react-cropper';
import Modal from 'react-modal';
import constant from '../../../helpers/constant';

import FormError from '../../../components/Errors/FormError';
import {
  setFormError,
  postEvent,
  fetchEditEventDataById,
  updateEvent,
} from '../modules/DiscoverModule';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';
import '../../../assets/datepicker.css';

export default function CreateEvent() {
  const myRef = useRef(null);
  const [isBooking, setIsBookingUrl] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [bookingUrl, setBookingUrl] = useState('');
  const [imageId, setImageId] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventHostName, setEventHostName] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState(new Date());
  const [eventDescription, setEventDescription] = useState('');
  const [eventWebsite, setEventWebsite] = useState('');
  const [eventType, setEventType] = useState('');
  const [isType, setIsType] = useState(false);
  const [isSuccessCreated, setIsSuccessCreated] = useState(false);
  const [eventRegistrationType, setEventRegistrationType] = useState('');
  const [isRegistrationType, setIsRegistrationType] = useState(false);
  const [eventImageId, setEventImageId] = useState('');
  const [updateMessage, setUpdateMessage] = useState('');
  const [shortDate, setShortDate] = useState('');
  const [lang] = useState(language.getLang());

  const [isCropped, setIsCropped] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [image, setImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [cropData, setCropData] = useState('');
  const cropper = React.createRef(null);

  const { errMessage, eventDataById, awardSpinner } = useSelector(
    (state) => state.discoverReducerState
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const {
    profileData: { data },
  } = useSelector((state) => state.memberProfileData);

  useEffect(async () => {
    window.scrollTo(0, 0);
    let dateObj = new Date();
    await setShortDate(dateObj);
  }, []);

  useEffect(async () => {
    id !== undefined && (await dispatch(fetchEditEventDataById(id)));
  }, [id]);

  useEffect(async () => {
    const hostName = data?.user_data?.role_id === constant.rolesId.restaurant ? data?.user_data?.restaurant_name : data?.user_data?.role_id === constant.rolesId.voiceOfExpert ? data?.user_data?.first_name+' '+data?.user_data?.last_name : data?.user_data?.company_name;
    await setEventHostName(hostName);
    await setEventLocation(eventDataById?.location?eventDataById?.location:data?.contact_tab?.address);
    await setEventWebsite(eventDataById?.website?eventDataById?.website:data?.contact_tab?.website);
  }, [data]);
  console.log(eventDataById,"eventDataById")

  useEffect(async () => {
    if (Object.keys(eventDataById)?.length > 0) {
      await setBookingUrl(eventDataById?.url);
      await setEventName(eventDataById?.event_name);
      await setEventDescription(eventDataById?.description);
      await setEventRegistrationType(eventDataById?.registration_type);
      // await setImageId(`${eventDataById?.attachment?.base_url}${eventDataById?.attachment?.attachment_url}`);
      await setEventImageId(
        `${eventDataById?.attachment?.base_url}${eventDataById?.attachment?.attachment_medium_url}`
      );
      await setEventType(eventDataById?.event_type);
      await setEventDate(eventDataById?.date);
      await setEventTime(eventDataById?.time);
    }
  }, [eventDataById]);

  const scrollToRef = (ref) => {
    window.scroll(0, ref.current && ref.current.offsetTop - 200);
  };

  const eventImageFun = async (e) => {
    let file = e.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      await dispatch(setFormError(`${lang?.Imagetype}`, 'eventimage'));
      scrollToRef(myRef);
    } else {
      // await setImageId(file);
      // const img = URL.createObjectURL(file)
      // setEventImageId(img);
      await setOpenModal(true);
      await setFileName(e.target.files[0].name);
      await setImage(URL.createObjectURL(e.target.files[0]));
      return (e.target.value = null);
    }
  };
  const eventTypeFun = async (e) => {
    await setEventType(e.target.value);
    await setIsType(true);
  };

  const eventRegistrationFun = async (e) => {
    if (e.target.value === 'paid' || e.target.value === 'By Invitation') {
      setIsBookingUrl(true);
    } else {
      setIsBookingUrl(false);
      setIsFree(true);
      setBookingUrl('');
    }
    await setEventRegistrationType(e.target.value);
    await setIsRegistrationType(true);
  };

  const createEvent = async (e) => {
    if (eventName === '') {
      dispatch(
        setFormError(`${lang?.Please_Enter_Event} ${lang?.name}`, 'eventName')
      );
      await scrollToRef(myRef);
    } else if (eventHostName === '') {
      await dispatch(setFormError(`${lang?.PleaseEnterHostName}`, 'eventHost'));
      scrollToRef(myRef);
    } else if (eventLocation === '') {
      dispatch(
        setFormError(
          `${lang?.Please_Enter_Event} ${lang?.Location}`,
          'eventLocation'
        )
      );
      scrollToRef(myRef);
    } else if (eventDate === '') {
      dispatch(
        setFormError(`${lang?.PleaseSelectEvent} ${lang?.Date}`, 'eventDate')
      );
      scrollToRef(myRef);
    } else if (eventTime === '') {
      dispatch(
        setFormError(`${lang?.PleaseSelectEvent} ${lang?.Time}`, 'eventTime')
      );
      scrollToRef(myRef);
    } else if (eventDescription === '' || eventDescription.length > 300) {
      dispatch(
        setFormError(
          `${lang?.Please_Enter_Event} ${lang?.Description}`,
          'Description'
        )
      );
      scrollToRef(myRef);
    } else if (eventWebsite === '') {
      dispatch(
        setFormError(`${lang?.Please_Enter_Event} ${lang?.Website}`, 'eventWeb')
      );
      scrollToRef(myRef);
    } else if (eventType === '' || eventType === '') {
      scrollToRef(myRef);
      dispatch(
        setFormError(`${lang?.PleaseSelectEvent} ${lang?.Type}`, 'eventType')
      );
      scrollToRef(myRef);
    } else if (eventRegistrationType === '' || eventType === '') {
      dispatch(
        setFormError(
          `${lang?.PleaseSelectEvent} ${lang?.Registration}`,
          'eventregistration'
        )
      );
      await scrollToRef(myRef);
    } else if (eventImageId === '') {
      dispatch(
        setFormError(`${lang?.PleaseSelectEvent} ${lang?.Image}`, 'eventimage')
      );
      await scrollToRef(myRef);
    }
    // else if ((bookingUrl === '' || bookingUrl === '') && isBooking) {
    //     dispatch(setFormError(`${lang?.Please_Enter_BookingUrl}`, "booking-url"));
    //     scrollToRef(myRef);
    // }
    else {
      await setIsSuccessCreated(true);
      const eventDataObj = {
        event_name: eventName,
        host_name: eventHostName,
        location: eventLocation,
        date: moment(eventDate).format('YYYY/MM/DD'),
        time: moment(eventTime, 'HH:mm:ss').format('hh:mm:ss A'),
        description: eventDescription,
        website: eventWebsite,
        event_type: eventType,
        registration_type: eventRegistrationType,
        image_id: imageId,
      };
      if (id !== undefined && Object.keys(eventDataById)?.length > 0) {
        eventDataObj.event_id = id;
        if (isBooking || (bookingUrl !== '' && bookingUrl !== null)) {
          eventDataObj.url = bookingUrl;
        }
        const response = await dispatch(updateEvent(eventDataObj));
        if (response) {
          await setUpdateMessage(response.message);
          await setIsSuccessCreated(false);
        } else {
          await setIsSuccessCreated(false);
        }
      } else {
        if (isBooking) {
          eventDataObj.url = bookingUrl;
        }
        const response = await dispatch(postEvent(eventDataObj));
        if (response.success === 200) {
          await setIsSuccessCreated(false);
          history.push('/profile');
        } else {
          console.log(response);
        }
      }
    }
    setTimeout(() => {
      dispatch(setFormError('', ''));
    }, 3000);
  };

  const WebsiteHandleChange = async (e) => {
    setBookingUrl(e.target.value);
  };

  // Start Cropping your Image
  const getCropData = async () => {
    await setIsCropped(true);
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.current.cropper.getCroppedCanvas().toDataURL());
      await setEventImageId(cropper.current.cropper.getCroppedCanvas().toDataURL());
      await fetch(cropper.current.cropper.getCroppedCanvas().toDataURL()).then((res) =>
        res.blob().then(async (blob) => {
          const file = await new File([blob], `${fileName}`, {
            type: 'image/png',
          });
          await setImageId(file);
        })
      );
      await setIsCropped(false);
      await setOpenModal(false);
    }
  };
  const closeModal = async () => {
    await setCropData('');
    await setImage('');
    await setCropData('');
    await setFileName('');
    await setOpenModal(false);
  };
  // End of Cropping

  return (
    <Fragment>
      <div className="discover_page forCreate">
        <div className="page_top_form">
          <div className="flex justify-between items-center">
            <div className="leftArrowBlock">
              <Link to="/profile">
                <i className="fa fa-arrow-left pr-2" aria-hidden="true"></i>{' '}
              </Link>
              {id !== undefined ? `${lang?.Edit}` : `${lang?.Create}`}{' '}
              {lang?.Event}
            </div>
            <div className="editProfileBtn">
              <Link
                to="#"
                className="btn btnPrimary"
                onClick={() => createEvent()}
              >
                {' '}
                {isSuccessCreated ? (
                  <FormSpinner />
                ) : id === undefined ? (
                  `${lang?.Save}`
                ) : (
                  `${lang?.Update}`
                )}
              </Link>
            </div>
          </div>
        </div>
        <h4 className="font-18 text-green text-center mt-4 mb-4">
          {updateMessage}
        </h4>
        <div className="discover__events create">
          <form>
            {awardSpinner ? (
              <FormSpinner />
            ) : (
              <div className="createForm mt-4">
                <div className="md:w-2/5 sm:w-full xl:mb-0 lg:mb-0 md:mb-0 sm:mb-4">
                  {/* <div className='custom--width--left'> */}
                  <div className="colLeftBlock">
                    <div className="fileUpload">
                      <label className="hide-input">
                        <span>
                          <i className="fa fa-camera" aria-hidden="true"></i>{' '}
                          &nbsp; {lang?.Add} {lang?.Event} {lang?.Image}
                        </span>
                        <input
                          type="file"
                          id="gallery_images"
                          accept="image/png,image/jpeg"
                          onChange={(e) => eventImageFun(e)}
                        />
                        {eventImageId !== '' && <img src={eventImageId} />}
                      </label>
                    </div>
                    {errMessage.field == 'eventimage' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:w-3/5 sm:w-full">
                  <div className="colRightBlock">
                    <div className="form__item">
                      <label>
                        {lang?.Event} {lang?.name}
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        value={eventName}
                        onChange={(e) => {
                          setEventName(e.target.value);
                        }}
                      />
                    </div>
                    {errMessage.field == 'eventName' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}

                    <div className="form__item">
                      <label>
                        {lang?.Host} {lang?.name}
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        value={eventHostName}
                        readOnly
                      />
                    </div>
                    {errMessage.field == 'eventHost' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                    <div className="form__item">
                      <label>{lang?.Location}</label>
                      <input
                        type="text"
                        placeholder=""
                        value={eventLocation}
                        onChange={(e)=>setEventLocation(e.target.value)}
                        // readOnly
                      />
                    </div>
                    {errMessage.field == 'eventLocation' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                    <div className="form__item">
                      <label>{lang?.Date}</label>
                      <DatePicker
                        selected={new Date(moment(eventDate).format('LLL'))}
                        onChange={(date) => setEventDate(date)}
                        minDate={shortDate}
                        placeholderText="Select a date."
                        dateFormat="dd MMMM yyyy"
                        // showDisabledMonthNavigation
                      />
                      {/* <input type="date" placeholder='' value={eventDate} onChange={(e) => setEventDate(e.target.value)} min="02-03-2022" /> */}
                    </div>
                    {errMessage.field == 'eventDate' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}

                    <div className="form__item">
                      <label>{lang?.Time}</label>
                      <DatePicker
                        selected={new Date(moment(eventTime, 'h:mm aa'))}
                        onChange={(date) => setEventTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      {/* <input type="time" placeholder='' value={eventTime} onChange={(e) => { setEventTime(e.target.value) }} /> */}
                    </div>
                    {errMessage.field == 'eventTime' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}

                    <div className="form__item">
                      {/* <label>{lang?.Event} {lang?.Edit_desc}</label> */}
                      <label>{lang?.Event_Description}</label>
                      <textarea
                        placeholder=""
                        value={eventDescription}
                        onChange={(e) => {
                          setEventDescription(e.target.value);
                        }}
                        maxLength={300}
                      />
                      {eventDescription?.length > 0 && (
                        <p>{`${eventDescription.length}/300`}</p>
                      )}
                    </div>
                    {errMessage.field == 'Description' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                    <div className="form__item">
                      <label>{lang?.Website}</label>
                      <input
                        type="text"
                        placeholder=""
                        value={eventWebsite}
                        onChange={(e)=>setEventWebsite(e.target.value)}
                        // readOnly
                      />
                    </div>
                    {errMessage.field == 'eventWeb' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                    <div className="form__item">
                      <label>
                        {lang?.Event} {lang?.Type} *
                      </label>
                      <select
                        onChange={(e) => eventTypeFun(e)}
                        value={eventType}
                      >
                        <option value={''}>{lang?.Select_Event}</option>
                        <option value={'public'}>{lang?.Public}</option>
                        <option value={'private'}>{lang?.Private}</option>
                      </select>
                    </div>
                    {errMessage.field == 'eventType' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                    <div className="form__item">
                      <label>{lang?.Event_Registration_Type} *</label>
                      <select
                        onChange={(e) => {
                          eventRegistrationFun(e);
                        }}
                        value={eventRegistrationType}
                      >
                        <option value={''}>{lang?.Select_reg_type}</option>
                        <option value={'free'}>{lang?.Free}</option>
                        <option value={'paid'}>{lang?.Paid}</option>
                        <option value={'By Invitation'}>
                          {lang?.buy_invitation}
                        </option>
                      </select>
                    </div>
                    {errMessage.field == 'eventregistration' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                    {(isBooking ||
                      (!isFree &&
                        (eventDataById?.registration_type === 'paid' ||
                          eventDataById?.registration_type ===
                            'Buy Invitation'))) && (
                      <div className="form__item">
                        <label>{lang?.Booking_Url}</label>
                        <input
                          type="text"
                          placeholder=""
                          value={bookingUrl ? bookingUrl : ''}
                          onChange={(e) => WebsiteHandleChange(e)}
                        />
                      </div>
                    )}
                    {errMessage.field == 'booking-url' && (
                      <div ref={myRef}>
                        {' '}
                        <FormError msg={errMessage.msg} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>

      {/* crop modal  */}
      {openModal && (
        <Modal
          className=""
          ariaHideApp={false}
          isOpen={openModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
            },
            content: {
              position: 'absolute',
              top: '0',
              left: '0%',
              right: '0%',
              bottom: '200px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              padding: '0px',
              // outline: 'none',
            },
          }}
        >
          <div className="popup_header flex justify-between items-center">
            <h4 className="page__title">{lang?.Crop_image}</h4>
            <button className="close_btn--modal" onClick={() => closeModal()}>
              {' '}
              {lang?.close}
            </button>
          </div>
          <div className="card card--block modal_box">
            <div className="crop_img_block">
                <Cropper
                  zoomTo={0}
                  style={{ width: '100%' }}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  dragMode={'move'}
                  cropBoxMovable={false}
                  responsive={true}
                  cropBoxResizable={false}
                  ref={cropper}
                  initialAspectRatio={2/2}
                  center={true}
                  guides={false}
                  />
            </div>
            <div className="form__action form__item mt-4">
              <button onClick={() => getCropData()}>
                {' '}
                {isCropped ? <FormSpinner /> : `${lang?.Crop_image}`}{' '}
              </button>
            </div>
          </div>
          {/* {cropData !== '' && <img style={{ width: "100%" }} src={cropData} alt="cropped" />} */}
        </Modal>
      )}
      {/* crop modal end */}
    </Fragment>
  );
}
