import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { fetchAllNotification } from '../../home/modules/HomeModule';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import moment from 'moment';
import { setProfileTabIndex } from '../../profile/modules/profile';
import auth from '../../../helpers/auth';
import constant from '../../../helpers/constant';


export default function NotificationView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    dispatch(setProfileTabIndex(0));

    const user = await auth.getUserType();
    await setUser(JSON.parse(user));
  }, [])
  const { notificationData, getNotificationSpinner } = useSelector((state) => state.activityFeedStatus);

  const handlePageClick = async (e) => {
    const pageNo = e.selected + 1;
    await dispatch(fetchAllNotification(pageNo));
  };

  const redirectTo = (item) => {
    (item?.notification_type === "progress") && history.push("/profile");
    parseInt(item?.notification_type) === 1 && history.push("/message");
    parseInt(item?.notification_type) === 3 && history.push("/networking");
    parseInt(item?.notification_type) === 4 && history.push("/connection");
    parseInt(item?.notification_type) === 5 && history.push("/following");
    parseInt(item?.notification_type) === 9 && history.push("/membership");

    // parseInt(item?.notification_type) === 10 && history.push(`${"/home/post/activity/"}${item?.post_id}`);
    parseInt(item?.notification_type) === 2 && history.push(`${"/home/post/activity/"}${item?.post_id}`);
    parseInt(item?.notification_type) === 6 && history.push(`${"/home/post/activity/"}${item?.post_id}`);
    parseInt(item?.notification_type) === 7 && history.push(`${"/home/post/activity/"}${item?.post_id}`);
    parseInt(item?.notification_type) === 8 && history.push(`${"/view-events/"}${item?.post_id}`);
    item?.notification_type === "progress" && item?.redirect_to === "membership_progress" && history.push(`/membership`);

    if (parseInt(item?.notification_type) === 10) {
      if (user?.role_id === constant?.rolesId?.importer || user?.role_id === constant?.rolesId?.distributer || user?.role_id === constant?.rolesId?.["importer&distributer"]) {
        parseInt(item?.notification_type) === 10 && history.push({ pathname: '/marketplace/inquiry', state: item?.enquiry_type })
      }
      // else {
      //   parseInt(item?.notification_type) === 10 && history.push({ pathname: '/marketplace/dashboard', state: item?.enquiry_type })
      // }
    }
  };
  console.log(notificationData,"notificationData")
  return (
    <Fragment>
      <div className="card card--block modal_box">
        {notificationData?.data?.length > 0 ? notificationData?.data?.map((item) => {
          return (
            <>
              <div className="feed-user-info home_page noti mb-1" onClick={() => redirectTo(item)}>
                <div className="user__pic">
                  <img src={`${item.user.base_url}${item.user.avatar_image}`} alt="Profile image" />
                </div>
                <div className="user__detail-data w-full">
                  <div className="flex">
                    <span className="user__name w-75">
                      <strong>{item.sender_name}</strong>
                    </span>
                    <div className="just__Now text-right w-25">
                      {/* <Link to="/home">Just Now</Link> */}
                      <Link to="/home">{moment(item.created_at).fromNow()}</Link>
                    </div>
                  </div>
                  <span className="user__name">{item.title}</span>
                </div>
              </div>
              <hr />
            </>

          )
        }) :
          <FormSpinner />}
        <hr />
        {notificationData?.total > 10 &&
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil((notificationData?.total) / (notificationData?.per_page))}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />}
      </div>
    </Fragment>
  )
}
