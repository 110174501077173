import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

export default function IncoTermModal({lang,incoTerm,setIncoTerm}) {
    return (
        <>
            <Modal
                className="incoTermModal"
                ariaHideApp={false}
                isOpen={incoTerm}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    },
                    content: {
                        position: 'absolute',
                        top: '0',
                        left: '0%',
                        right: '0%',
                        bottom: '200px',
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        padding: '0',
                        // outline: 'none',
                    },
                }}
            >
                <div className="popup_header flex justify-between items-center">
                    <h4 className="page__title"></h4>
                    <button
                        className="close_btn--modal"
                        onClick={() => setIncoTerm()}
                    >
                        {lang?.close}
                    </button>
                </div>
                <div className='modal-body contentModal'>
                    <div className="card card--block modal_box modalLessGap">
                        <h4 className='textBlue'>TERMINI PER OGNI TIPO DI TRASPORTO</h4>
                        <strong>EXW – Ex Works</strong>
                        <p>
                            “Franco Fabbrica” : il venditore mette la merce a disposizione del compratore nei propri locali o in altro luogo convenuto (ES. fabbrica). Il venditore non è obbligato a effettuare la caricazione della merce sul veicolo preposto al pick-up, né a effettuare lo sdoganamento in export.
                        </p>
                        <strong>FCA – Free Carrier</strong>
                        <p>
                            “Franco Vettore”: il venditore consegna la merce al vettore compratore nei propri locali o in altro luogo convenuto.
                            Il venditore sdogana la merce in export e, se necessario, fornisce istruzioni al vettore per l’emissione della polizza di carico.
                        </p>
                        <strong>CPT – Carriage Paid To</strong>
                        <p>
                            “Trasporto Pagato fino a”: il venditore consegna la merce al vettore o terza parte incaricata dallo stesso venditore presso luogo concordato. Il venditore stipula il contratto di trasporto e sostiene le spese necessarie a spedire la merce verso il luogo di destinazione accordato.
                        </p>
                        <strong>CIP – Carriage And Insurance Paid To</strong>
                        <p>
                            “Trasporto e Assicurazione Pagati fino a”: il venditore consegna la merce al vettore o a terza parte incaricata dallo stesso venditore presso luogo concordato. In tale luogo avviene il passaggio del rischio al compratore.
                            II venditore provvede anche alla copertura assicurativa del compratore (contro la perdita o danni alla merce durante il trasporto) e allo sdoganamento della merce all’esportazione.
                        </p>
                        <strong>DPU – Delivered at Place Unloaded</strong>
                        <p>
                            “Reso al Luogo di destinazione Scaricato”: il venditore consegna la merce scaricata al compratore nel porto o luogo concordato. Il venditore si fa carico dei rischi connessi al trasporto e alla scaricazione della merce nel porto o luogo di destinazione convenuto.
                            Il venditore, se del caso, sdogana la merce all’esportazione.
                        </p>
                        <strong>DAP – Delivered At Place</strong>
                        <p>
                            “Reso al Luogo di Destinazione”: il venditore consegna la merce sul mezzo di trasporto di arrivo pronta per la scaricazione presso il luogo di destinazione convenuto. Il venditore si fa carico di tutti i rischi collegati al trasporto della merce al luogo convenuto.
                            Il venditore, se del caso, sdogana la merce all’esportazione.
                        </p>
                        <strong>DDP – Delivered Duty Paid</strong>
                        <p>
                            “Reso Sdoganato”: il venditore consegna la merce al compratore sdoganata all’importazione e ancora caricata sul mezzo di trasporto destinato al luogo di destinazione convenuto. Il venditore si fa carico di spese e rischi collegati al trasporto della merce verso il luogo di destinazione.
                        </p>
                        <p>
                            Il venditore ha inoltre l’obbligo di effettuare lo sdoganamento non solo all’esportazione ma anche all’importazione (pagando eventuali tributi sia di esportazione sia di importazione ed espletando tutte le formalità doganali).
                        </p>
                        <h4 className='textBlue'>TERMINI PER IL TRASPORTO VIA MARE</h4>
                        <strong>FAS – Free Alongside Ship</strong>
                        <p>
                            “Franco lungo Bordo”: il venditore consegna la merce sottobordo della nave. FAS prevede che il venditore, se del caso, curi lo sdoganamento della merce all’esportazione.
                        </p>
                        <strong>FOB – Free On Board</strong>
                        <p>
                            “Franco a Bordo”: il venditore consegna la merce a bordo della nave nel porto d’imbarco accordato. Il rischio di perdita o di danni alla merce si trasferisce quando la merce è a bordo della nave. Il compratore si accolla le spese da bordo nave in avanti.
                            Il venditore, se del caso, sdogana la merce all’esportazione.
                        </p>
                        <strong>CFR – Cost and Freight</strong>
                        <p>
                            “Costo e Nolo”: il venditore consegna la merce a bordo della nave. Il rischio di perdita o di danni alla merce si trasferisce quando la merce è a bordo della nave. Il venditore stipula il contratto di trasporto e sostiene le spese per l’invio della merce al porto di destinazione.
                        </p>
                        <strong>CIF – Cost, Insurance and Freight</strong>
                        <p>
                            “Costo, Assicurazione e Nolo”: il venditore consegna la merce a bordo della nave. Il rischio di perdita o di danni alla merce si trasferisce quando la merce è a bordo della nave. Il venditore stipula il contratto di trasporto e sostiene le spese per l’invio della merce al porto di destinazione.
                            II venditore provvede anche alla copertura assicurativa del compratore (contro la perdita o danni alla merce durante il trasporto).
                        </p>

                        <div className='englishData'>
                            <div className="borderDotted"></div>
                            <h4 className='textBlue'>ANY MODES OF TRANSPORT</h4>
                            <strong>EXW – Ex Works</strong>
                            <ul className='englishList'>
                                <li>Delivery when Seller places goods at Buyer’s disposal at named place</li>
                                <li><strong>Alert! </strong> Buyer loads goods onto collecting vehicle</li>
                                <li>Buyer pays transport</li>
                                <li>Buyer responsible for export where applicable</li>
                                <li>If no specific point named by Buyer, Seller may select point ‘that best suits its purpose’</li>
                                <li>Minimum Seller obligation</li>
                                <li>Best used for domestic/intra-trading bloc contracts</li>
                            </ul>

                            <strong>FCA – Free Carrier</strong>
                            <ul className='englishList'>
                                <li>Buyer arranges transport BUT...</li>
                                <li>...if commercial practice or at Buyer’s request, Seller may (but is not required to) arrange transport at Buyer’s cost and risk</li>
                                <li>Seller responsible for export + Buyer responsible for import, where applicable</li>
                                <li>If no specific point named by Buyer, Seller may select point ‘that best suits its purpose’</li>
                                <li>
                                    <strong>NEW! </strong> Where an on-board BL needed (eg under an LC), parties can agree under A6/B6 that
                                    <ul className='englishList innerSubList number'>
                                        <li>Buyer will request carrier to issue an on-board BL to the Seller, and</li>
                                        <li>Seller will then tender such on-board BL to the Buyer</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className='englishList noDesc'>
                                <li><span>FCA Option 1:</span> Delivery at Seller’s premises, Seller loads onto transport</li>
                                <li><span>FCA Option 2:</span> Delivery elsewhere, Seller places goods at Buyer’s disposal on arriving transport ready for unloading</li>
                            </ul>

                            <strong>CPT – Carriage Paid To</strong>
                            <ul className='englishList'>
                                <li>Note on C-Rules!
                                    <ul className='englishList innerSubList'>
                                        <li>Destination is named place – NOT place of delivery</li>
                                        <li>Risk and cost shift to Buyer at different places</li>
                                    </ul>
                                </li>
                                <li>Delivery/risk passage when Seller hands goods to carrier. Seller pays for transport BUT does not have transport risk</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                                <li>Where no specific point at the named place of destination is agreed or determined by practice, Seller may select the point at named place of destination that best suits its purpose</li>
                            </ul>
                            <strong>CIP – Carriage And Insurance Paid To</strong>
                            
                            <ul className='englishList'>
                                <li>Note on C-Rules!
                                    <ul className='englishList innerSubList'>
                                        <li>Destination is named place – NOT place of delivery</li>
                                        <li>Risk and cost shift to Buyer at different places</li>
                                    </ul>
                                </li>
                                <li>Delivery/risk passage when Seller hands goods to carrier. Seller pays for transport BUT does not have transport risk</li>
                                <li><strong>NEW!</strong> Seller contracts for ‘all risks’ cover insurance against Buyer’s transport risk</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                                <li>Where no specific point at the named place of destination is agreed or determined by practice, Seller may select the point at named place of destination that best suits its purpose</li>
                            </ul>
                            <strong>DPU – Delivered at Place Unloaded</strong>

                            <ul className='englishList'>
                                <li><strong>NEW!</strong> Name change from ‘Delivered at Terminal (DAT)’ {">"} ‘Delivered at Place Unloaded (DPU)’. Now comes after DAP</li>
                                <li>Delivery when Seller unloads the goods from arriving transportation and places them at Buyer’s disposal at named place of destination</li>
                                <li>Seller pays for transport</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                                <li>Where no specific point at the named place of destination is agreed or determined by practice, Seller may select the point at named place of destination that best suits its purpose</li>
                            </ul>
                            <strong>DAP – Delivered At Place</strong>

                            <ul className='englishList'>
                                <li>Delivery when Seller places the goods at Buyer’s disposal not unloaded on arriving means of transport at named place of destination</li>
                                <li>Seller pays for transport</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                                <li>Where no specific point at the named place of destination is agreed or determined by practice, Seller may select the point at named place of destination that best suits its purpose</li>
                            </ul>
                            <strong>DDP – Delivered Duty Paid</strong>

                            <ul className='englishList'>
                                <li>Delivery when Seller places the goods, <strong>cleared for import</strong>, at Buyer’s disposal not unloaded on arriving means of transport at named place</li>
                                <li>Seller pays for transport</li>
                                <li>Seller responsible for <strong>export AND import</strong>, where applicable </li>
                                <li>Where no specific point at the named place of destination is agreed or determined by practice, Seller may select the point at named place of destination that best suits its purpose</li>
                            
                            </ul>
                            <h4 className='textBlue'>SEA TRANSPORT</h4>
                            <strong>FAS – Free Alongside Ship</strong>

                            <ul className='englishList'>
                                <li>
                                    Delivery when goods are placed alongside vessel at port of shipment
                                </li>
                                <li>Buyer pays transport</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                                <li>If no specific loading point named by Buyer, Seller may select point ‘that best suits its purpose’</li>
                            </ul>
                            <strong>FOB – Free On Board</strong>

                            <ul className='englishList'>
                                <li>Delivery when goods are placed on board vessel at port of shipment</li>
                                <li>Buyer pays transport</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                                <li>If no specific loading point named by Buyer, Seller may select point ‘that best suits its purpose’</li>
                                <li>For goods in containers, consider using FCA instead</li>
                            </ul>
                            <strong>CFR – Cost and Freight</strong>
                            
                            <ul className='englishList'>
                                <li>Note on C-Rules!
                                    <ul className='englishList innerSubList'>
                                        <li>Destination is named place – NOT place of delivery</li>
                                        <li>Risk and cost shift to Buyer at different places</li>
                                    </ul>
                                </li>
                                <li>Delivery (and passage of risk) when Seller hands goods over to carrier BUT Seller pays for transport, without bearing transport risk</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                            </ul>
                            <strong>CIF – Cost, Insurance and Freight</strong>


                            <ul className='englishList'>
                                <li>Note on C-Rules!
                                    <ul className='englishList innerSubList'>
                                        <li>Destination is named place – NOT place of delivery</li>
                                        <li>Risk and cost shift to Buyer at different places</li>
                                    </ul>
                                </li>
                                <li>Delivery (and passage of risk) when Seller hands goods over to carrier BUT Seller pays for transport, without bearing transport risk</li>
                                <li>Seller contracts for minimum cover insurance against Buyer’s transport risk</li>
                                <li>Seller responsible for export; Buyer responsible for import where applicable</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
