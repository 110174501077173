import React from 'react';
import '../../../assets/marketplacecss/mrktplace-style.css';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from 'react-grid-carousel';
import { Redirect, useHistory } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import constant from '../../../helpers/constant';
import auth from '../../../helpers/auth';
import ReactStars from 'react-rating-stars-component';
import banner3 from '../../../assets/images/market-pace-banner3.png.png';

import {
  fetchHomeScreenData,
  fetchStoreData,
  getProductCategories,
  getProductProperties,
  getConservationMethod,
  getItalianRegion,
  setReviews,
  setProductReviewed,
  setStoreProductReviewed,
  setStoreReview,
  setMarketPlaceStoreDataById,
  checkStoreStatus,
  getProductPropertiesAndConservation,
} from '../modules/marketplace';
import language from '../../../helpers/language';
import firebase from '../../../config/firebase';

export function MarketplaceView() {
  const [searchProductCategories, setSearchProductCategories] = useState();
  const [searchProductProperties, setSearchProductProperties] = useState();
  const [searchConservationMethods, setSearchConservationMethods] = useState();
  const [searchItalianRegion, setSearchItalianRegion] = useState();
  const [searchFdaData, setSearchFdaData] = useState([]);
  const [productTabButton, setProductTabButton] = useState('active-button');
  const [storeTabButton, setStoreTabButton] = useState('');
  const [productTab, setProductTab] = useState('active-tab');
  const [storeTab, setStoreTab] = useState('');
  const [lang] = useState(language.getLang());
  const [validationText, setValidationText] = useState('');
  const [activeTab, setActiveTab] = useState('product');
  const [isSpinner, setSpinner] = useState(false);
  const [title, setTitle] = useState('');
  const [user, setUser] = useState([]);
  const myProductTypeRef = useRef(null);
  const myConservationRef = useRef(null);
  const myItalianRegionRef = useRef(null);
  const myProductPropertyRef = useRef(null);
  const myFDACertificateRef = useRef(null);
  const CUTTING_EXPRESSION = /\s+[^\s]*$/;
  const dispatch = useDispatch();
  const history = useHistory();
  const [alyseiReview, setAlyseiReview] = useState(false);
  const [conservationPropertyDisabled, setConservationPropertyDisabled] =
    useState(true);
  const [fdaCertifiedStatus, setFdaCertified] = useState([
    { key: lang?.yes, value: '1' },
    { key: lang?.no, value: '0' },
  ]); 

  useEffect(async () => {
    document.body.classList.remove("hidePageScroll");
    localStorage.setItem("tab", "dashboard");
    if (
      recentlyAddedProduct.length === 0 ||
      recentlyAddedProduct.length === 0 ||
      topBanners.length === 0 ||
      topFavourite.length === 0 ||
      topRatedProduct.length === 0
    )
      await dispatch(fetchHomeScreenData());
    window.scrollTo(0, 0);
    const user = await auth.getUserType();
    await setUser(JSON.parse(user));
    JSON.parse(user).role_id === constant.rolesId.producer &&
      (await dispatch(checkStoreStatus()));
    await dispatch(setReviews([]));
    await dispatch(setStoreReview([]));
    await dispatch(setMarketPlaceStoreDataById([]));
    await dispatch(setProductReviewed(true));
    await dispatch(setStoreProductReviewed(true));
    italianRegions &&
      italianRegions.length === 0 &&
      (await dispatch(getItalianRegion()));
    marketPlaceStore &&
      Object.keys(marketPlaceStore).length === 0 &&
      JSON.parse(user).role_id === constant.rolesId.producer &&
      (await dispatch(fetchStoreData()));
    productCategories &&
      productCategories.length === 0 &&
      (await dispatch(getProductCategories()));
    productProperties &&
      productProperties.length === 0 &&
      (await dispatch(getProductProperties()));
    conservationMethods &&
      conservationMethods.length === 0 &&
      (await dispatch(getConservationMethod()));
    setSpinner(true);
  }, []);

  let {
    recentlyAddedProduct,
    recentlyAddedStore,
    topBanners,
    topRatedProduct,
    marketPlaceStore,
    productCategories,
    productProperties,
    conservationMethods,
    conservationMethodLabel,
    productPropertiesLabel,
    marketPlaceSpinner,
    italianRegions,
    topFavourite,
    bottomBanners,
  } = useSelector((state) => state.marketPlaceReducerData);
  const { profileData } = useSelector((state) => state.memberProfileData);


  useEffect(async () => {
    const userId = await profileData?.data?.user_data?.user_id;
    if (userId) {
      const fetchUserNode = firebase.database().ref('users').child(userId);
      fetchUserNode.on('value', async (snapshot) => {
        const response = snapshot.val();
        await setAlyseiReview(response?.alysei_approval);
      });
    }
  }, [
    profileData?.data?.user_data?.user_id,
    alyseiReview,
    localStorage.getItem('alysei_review'),
  ]);

  const changeProductCategoris = async (e) => {
    setSearchProductCategories(e[0].marketplace_product_category_id);
    await dispatch(
      getProductPropertiesAndConservation(e[0].marketplace_product_category_id)
    );
    await setConservationPropertyDisabled(false);
  };

  const changeConservationMethods = (conservationMethodSelectedList) => {
    if (conservationMethodSelectedList.length > 1) {
      document.getElementById('conservation_method_input').placeholder =
        conservationMethodSelectedList[0].option +
        ' +' +
        (conservationMethodSelectedList.length - 1) +
        ' more';
    } else if (conservationMethodSelectedList.length == 1) {
      document.getElementById('conservation_method_input').placeholder =
        conservationMethodSelectedList[0].option;
    } else {
      document.getElementById('conservation_method_input').placeholder =
        lang?.select;
    }

    let data = conservationMethodSelectedList.map((item, i) => {
      return item.user_field_option_id;
    });

    if (data.length > 0) {
      setSearchConservationMethods(data.join());
    } else {
      setSearchConservationMethods('');
    }
  };

  const changeProductProperties = (productCategorySelectedList) => {
    if (productCategorySelectedList.length > 1) {
      document.getElementById('product_properties_input').placeholder =
        productCategorySelectedList[0].option +
        ' +' +
        (productCategorySelectedList.length - 1) +
        ' more';
    } else if (productCategorySelectedList.length == 1) {
      document.getElementById('product_properties_input').placeholder =
        productCategorySelectedList[0].option;
    } else {
      document.getElementById('product_properties_input').placeholder =
        lang?.select;
    }

    let data = productCategorySelectedList.map((item, i) => {
      return item.user_field_option_id;
    });

    if (data.length > 0) {
      setSearchProductProperties(data.join());
    } else {
      setSearchProductProperties('');
    }
  };

  const changeItalianRegions = (italianRegionSelectedList) => {
    if (italianRegionSelectedList.length > 1) {
      document.getElementById('region_input').placeholder =
        italianRegionSelectedList[0].name +
        ' +' +
        (italianRegionSelectedList.length - 1) +
        ' more';
    } else if (italianRegionSelectedList.length == 1) {
      document.getElementById('region_input').placeholder =
        italianRegionSelectedList[0].name;
    } else {
      document.getElementById('region_input').placeholder = lang?.select;
    }

    let data = italianRegionSelectedList.map((item, i) => {
      return item.id;
    });

    if (data.length > 0) {
      setSearchItalianRegion(data.join());
    } else {
      setSearchItalianRegion('');
    }
  };

  const changeFDACertificate = (e) => {
    setSearchFdaData(e[0].value);
  };

  const handleSearch = () => {
    let queryString = '?';
    if (searchProductCategories !== undefined) {
      queryString += 'category=' + searchProductCategories;
    }

    if (searchProductProperties !== undefined) {
      queryString += '&property=' + searchProductProperties;
    }

    if (searchConservationMethods !== undefined) {
      queryString += '&method=' + searchConservationMethods;
    }

    if (searchFdaData > -1) {
      queryString += '&fda_certified=' + searchFdaData;
    }

    if (searchItalianRegion !== undefined) {
      queryString += '&region=' + searchItalianRegion;
    }
    if (title.length >= 0) {
      queryString += '&keyword=' + title;
    }
    if (activeTab) {
      queryString += '&tab=' + activeTab;
    } else {
      queryString += '&tab=product';
    }
    activeTab === 'product' &&
      (title.length > 0 ||
        searchProductCategories ||
        searchConservationMethods ||
        searchProductProperties ||
        searchItalianRegion ||
        (searchFdaData != -1 && searchFdaData.length > 0))
      ? history.push('/marketplace/search/' + queryString)
      : setValidationText(lang?.Please_Select_At_Least_one_field);
    activeTab === 'store' &&
      (searchProductCategories ||
        searchFdaData?.length > 0 ||
        searchConservationMethods ||
        searchProductProperties ||
        searchItalianRegion.length > 0) &&
      history.push('/marketplace/search/' + queryString);
    setTimeout(() => {
      setValidationText('');
    }, 3000);
  };

  const selectTab = (tab) => {
    if (tab == 'store') {
      handleClearCheckBox();
      setProductTabButton('');
      setStoreTabButton('active-button');
      setProductTab('');
      setStoreTab('active-tab');
      setActiveTab('store');
    } else {
      handleClearCheckBox();
      setProductTabButton('active-button');
      setStoreTabButton('');
      setProductTab('active-tab');
      setStoreTab('');
      setActiveTab('product');
    }
  };

  const handleClearCheckBox = async () => {
    if (activeTab == 'store') {
      myConservationRef.current.resetSelectedValues();
      myProductPropertyRef.current.resetSelectedValues();
      myFDACertificateRef.current.resetSelectedValues();
      myProductTypeRef.current.resetSelectedValues();
      myItalianRegionRef.current.resetSelectedValues();
    } else {
      myConservationRef.current.resetSelectedValues();
      myProductPropertyRef.current.resetSelectedValues();
      myProductTypeRef.current.resetSelectedValues();
      myItalianRegionRef.current.resetSelectedValues();

    }
    document.getElementById('conservation_method_input').placeholder =
      lang?.select;
    document.getElementById('product_properties_input').placeholder =
      lang?.select;
    document.getElementById('region_input').placeholder =
      lang?.select;
    setTitle('');
    setSearchProductCategories('');
    setSearchConservationMethods('');
    setSearchProductProperties('');
    setSearchFdaData([]);
    setSearchItalianRegion('');
    setConservationPropertyDisabled(true);
  };

  const textLimit = (text, limit) => {
    if (text.length > limit) {
      const part = text.slice(0, limit - 3);
      if (part.match(CUTTING_EXPRESSION)) {
        return part.replace(CUTTING_EXPRESSION, ' ...');
      }
      return part + '...';
    }
    return text;
  };

  return (
    <>
      {JSON.parse(auth.getUserType())?.role_id !== constant.rolesId.voyagers &&
        localStorage.getItem('alysei_review') != true &&
        (alyseiReview === 'false' || alyseiReview === false) ? (
        <Redirect to="/membership" />
      ) : (
        <div className="marketplace mrkthome">
          <div className="site__banner banner-wrapper">
            <div className="banner__image withoutSlider recipeSlider">
              <img src={banner3} alt="Banner" />
            </div>
            <div className="banner__content">
              <div className="site__width">
                {/* <div className="banner-heading flex">
										<h4 className="heading--w text-white">Alysei Marketplace</h4>
										
									</div> */}
                {/* <h5 className="heading--w text" style={{ color: "red" }}>{validationText}</h5> */}
                <div
                  className={
                    validationText === ''
                      ? 'validationPopup'
                      : 'validationPopup open'
                  }
                >
                  <h5 className="heading--w text">{validationText}</h5>
                </div>
                <div className="banner__form">
                  <div className="tabs middle-long__section-block">
                    <div className="tabs__wrapper">
                      <span
                        tab="1"
                        className={`btn ${productTabButton}`}
                        onClick={() => selectTab('product')}
                      >
                        <em>{lang?.Products}</em>
                      </span>
                      <span
                        tab="2"
                        className={`btn ${storeTabButton}`}
                        onClick={() => selectTab('store')}
                      >
                        <em>{lang?.Producer_Store}</em>
                      </span>
                    </div>
                    <div className={`tab home-section active-tab`}>
                      {/* <!-- Tab Content Left Section --> */}
                      <div className="tab-content-left-section">
                        <div className="banner__form">
                          <form>
                            <div className="flex items-center banner-form-bg">
                              {activeTab === 'product' && (
                                <div className="lg:w-1/3 xl:w-1/3 md:full sm:w-full pl-2 pr-2">
                                  <div className="form__item relative">
                                    <label>{lang?.Product_Title}</label>
                                    <input
                                      type="text"
                                      value={title}
                                      placeholder={lang?.product_keywords}
                                      onChange={(e) => setTitle(e.target.value)}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="lg:w-1/3 xl:w-1/3 md:full sm:w-full pl-2 pr-2">
                                <div className="form__item relative">
                                  <label>{lang?.Product_Type}</label>
                                  <Multiselect
                                    options={productCategories}
                                    displayValue="name"
                                    onSelect={changeProductCategoris}
                                    placeholder={lang?.select}
                                    id={'fda__certificate'}
                                    singleSelect
                                    ref={myProductTypeRef}
                                  />
                                  {/* <span className='selectDownArrow'><i className="fa fa-angle-down" aria-hidden="true"></i></span> */}
                                </div>
                              </div>
                              <div className="lg:w-1/3 xl:w-1/3 md:full sm:w-full pl-2 pr-2">
                                <div className="form__item relative">
                                  <label>
                                    {conservationMethodLabel != ''
                                      ? conservationMethodLabel
                                      : lang?.Conservation_Method}
                                  </label>
                                  <Multiselect
                                    options={conservationMethods}
                                    onSelect={changeConservationMethods}
                                    onRemove={changeConservationMethods}
                                    displayValue="option"
                                    showCheckbox="true"
                                    id="conservation_method"
                                    showArrow={true}
                                    placeholder={lang?.select}
                                    closeOnSelect={false}
                                    ref={myConservationRef}
                                    disable={conservationPropertyDisabled}
                                  />
                                </div>
                              </div>
                              <div className="lg:w-1/3 xl:w-1/3 md:full sm:w-full pl-2 pr-2">
                                <div className="form__item relative">
                                  <label>
                                    {productPropertiesLabel != ''
                                      ? productPropertiesLabel
                                      : lang?.Product_Properties}
                                  </label>
                                  <Multiselect
                                    options={productProperties}
                                    onSelect={changeProductProperties}
                                    onRemove={changeProductProperties}
                                    displayValue="option"
                                    showCheckbox="true"
                                    id="product_properties"
                                    showArrow={true}
                                    closeOnSelect={false}
                                    placeholder={lang?.select}
                                    ref={myProductPropertyRef}
                                    disable={conservationPropertyDisabled}
                                  />
                                </div>
                              </div>

                              {activeTab !== 'product' && (
                                <div className="lg:w-1/3 xl:w-1/3 md:full sm:w-full pl-2 pr-2">
                                  <div className="form__item">
                                    <label>{lang?.FDA_Certification}</label>

                                    <Multiselect
                                      options={fdaCertifiedStatus}
                                      displayValue="key"
                                      onSelect={changeFDACertificate}
                                      placeholder={lang?.select}
                                      id={'fda__certificate'}
                                      singleSelect
                                      ref={myFDACertificateRef}
                                    />
                                    {/* <select id="hor" className='searchBox' onChange={changeFda}>
                                      <option value="-1">
                                        {lang?.Select_fda_Certificate}
                                      </option>
                                      <option value="1">{lang?.yes}</option>
                                      <option value="0">{lang?.no}</option>
                                    </select> */}
                                  </div>
                                </div>
                              )}
                              <div className="lg:w-1/3 xl:w-1/3 md:full sm:w-full pl-2 pr-2">
                                <div className="form__item">
                                  <label>{lang?.Italian_Region}</label>
                                  <Multiselect
                                    options={italianRegions}
                                    onSelect={changeItalianRegions}
                                    onRemove={changeItalianRegions}
                                    displayValue="name"
                                    showCheckbox="true"
                                    showArrow={true}
                                    placeholder={lang?.select}
                                    id="region"
                                    closeOnSelect={false}
                                    ref={myItalianRegionRef}
                                  />
                                </div>
                              </div>

                              <div className="xl:w-1/6 lg:w-1/6 md:w-full sm:w-full mt-3 pl-2 pr-2">
                                <div className="btn-blue-bg">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => {
                                      handleSearch();
                                    }}
                                  >
                                    {lang?.Search}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="reset text-left pl-2">
                              <Link
                                to="#"
                                onClick={() => handleClearCheckBox()}
                              >
                                {(title?.length > 0 ||
                                  searchProductCategories ||
                                  searchFdaData?.length > 0 ||
                                  searchConservationMethods ||
                                  searchProductProperties ||
                                  searchItalianRegion?.length > 0) &&
                                  lang?.Reset_Filter}
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="tab awards-section">
                      {/* <!-- Tab Content left Section --> */}
                      <div className="tab-content-left-section">
                        <div className="coming-soon">
                          <span className="card--block">Coming soon...</span>
                        </div>
                      </div>
                    </div>
                    <div className="tab job-section">
                      {/* <!-- Tab Content left Section --> */}
                      <div className="tab-content-left-section">
                        <div className="coming-soon">
                          <span className="card--block">Coming soon...</span>
                        </div>
                      </div>
                    </div>
                    <div className="tab contact-section">
                      {/* <!-- Tab Content left Section --> */}
                      <div className="tab-content-left-section">
                        <div className="card card--block">
                          <div className="profile-options-lists">
                            <div className="card-title">
                              <h5>{lang?.Contact_us}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- <div className="site__width"> --> */}
         
          {/* Marketplace Description Start*/}
          <div className="bg_white">
            <div className="mrkt_des">
              <div className="flex justify-between items-center">
                <div className="font-32">{lang?.Alysei_Marketplace}</div>
                {user.role_id === constant.rolesId.producer && (
                  <div className="btn--w btn-blue-bg ">
                    {marketPlaceStore?.store_products?.length === 0 &&
                      parseInt(localStorage.getItem('storeStatus')) === 0 && (
                        <Link to="/marketplace/add-product">
                          {lang?.Go_To_Store}
                        </Link>
                      )}
                    {marketPlaceStore?.store_products?.length > 0 &&
                      parseInt(localStorage.getItem('storeStatus')) === 0 && (
                        <Link to="/marketplace/thankyou">
                          {lang?.Go_To_Store}
                        </Link>
                      )}
                    {marketPlaceStore?.store_products?.length === 0 &&
                      parseInt(localStorage.getItem('storeStatus')) === 1 && (
                        <Link to="/marketplace/add-product">
                          {lang?.Go_To_Store}
                        </Link>
                      )}
                    {marketPlaceStore?.store_products?.length > 0 &&  parseInt(localStorage.getItem('storeStatus')) === 1 &&(
                      <Link to="/marketplace/dashboard">
                        {lang?.Go_To_Store}
                      </Link>
                    )}
                    {marketPlaceStore?.length === 0 && isSpinner && (
                      <Link to="/marketplace/selectpackage" onClick={()=>{ localStorage.setItem('storeStatus', '0');}}>
                        {lang?.Create_You_Store}
                      </Link>
                    )}
                  </div>
                )}
                {(user.role_id === constant.rolesId.restaurant ||
                  user.role_id === constant.rolesId.voiceOfExpert) && (
                    <div className="btn--w btn-blue-bg ">
                      <Link to="/marketplace/inquiry">
                        {lang?.ImporterInquiry}
                      </Link>
                    </div>
                  )}
                {(user.role_id === constant.rolesId.importer ||
                  user.role_id === constant.rolesId.distributer ||
                  user.role_id === constant.rolesId['importer&distributer']) && (
                    <div className="btn--w btn-blue-bg ">
                      <Link to="/my-dashboard">
                        {lang?.MyDashboard}
                      </Link>
                    </div>
                  )}
              </div>
              <p className="mt-4">{lang?.MarketPlaceMainHeading}</p>
            </div>
            {/* Marketplace Description End*/}

            {marketPlaceSpinner ? (
              <FormSpinner />
            ) : (
              <div className="">
                <div className="">
                  <div className="product-main rectlyAdded customSlider">
                    {recentlyAddedProduct?.length > 0 && (
                      <div className="product-type pt-4">
                        <div className="flex flex-wrap items-center justify-between">
                          <h4 className="product-title">
                            {lang?.Recently_Added_Product}
                          </h4>
                          <Link
                            to={
                              '/marketplace/view-all/recently-added-product/1'
                            }
                            className="product-title list"
                          >
                            {lang?.View_all}
                          </Link>
                        </div>
                        <Carousel
                          cols={4}
                          rows={1}
                          gap={10}
                          hideArrow={
                            recentlyAddedProduct?.length <= 4 ? true : false
                          }
                          loop
                          responsiveLayout={[
                            {
                              breakpoint: 991,
                              cols: 3,
                            },
                            {
                              breakpoint: 767,
                              cols: 2,
                            },
                            {
                              breakpoint: 500,
                              cols: 1,
                            },
                          ]}
                          mobileBreakpoint={670}
                        >
                          {recentlyAddedProduct.map((items) => {
                            return (
                              <Carousel.Item key={items.marketplace_product_id}>
                                <div
                                  className="productImgSlider recentProd"
                                  key={items.marketplace_product_id}
                                >
                                  <Link
                                    to={`/marketplace/product/${items.slug}`}
                                  >
                                    <img
                                      src={`${items.base_url}${items.logo_id}`}
                                      alt={items.title}
                                      title={items.title}
                                    />
                                  </Link>
                                </div>
                                <div className="product-desc">
                                  <h4 className="text-left prdcr-store-title">
                                    {textLimit(items.title, 25)}
                                  </h4>
                                  <div className="review">
                                    <h4 className="text-left prdcr-store-title">
                                      {items.avg_rating}
                                    </h4>
                                    <ReactStars
                                      edit={false}
                                      isHalf={true}
                                      value={parseFloat(items.avg_rating)}
                                      activeColor="#eb8a2f"
                                      color="rgb(74 72 72)"
                                    />
                                  </div>
                                  <p>
                                    {items.total_reviews}{' '}
                                    {items.total_reviews <= 1
                                      ? lang?.review
                                      : lang?.reviews}
                                  </p>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  <div className="product-main rectlyAdded customSlider">
                    {recentlyAddedStore?.length > 0 && (
                      <div className="product-type mt-4">
                        <div className="flex flex-wrap items-center justify-between">
                          <h4 className="product-title">
                            {lang?.Newly_Added_Stores}
                          </h4>
                          <Link
                            to={'/marketplace/view-all/newly-add-store/2'}
                            className="product-title list"
                          >
                            {lang?.View_all}
                          </Link>
                        </div>
                        <Carousel
                          cols={4}
                          rows={1}
                          gap={10}
                          hideArrow={
                            recentlyAddedStore?.length <= 4 ? true : false
                          }
                          loop
                          responsiveLayout={[
                            {
                              breakpoint: 991,
                              cols: 3,
                            },
                            {
                              breakpoint: 767,
                              cols: 2,
                            },
                            {
                              breakpoint: 500,
                              cols: 1,
                            },
                          ]}
                          mobileBreakpoint={670}
                        >
                          {recentlyAddedStore.map((items) => {
                         
                            let region_name = italianRegions
                           
                              .filter((item) => item.id == items.store_region)
                              .map((item) => item.name);
                            return (
                              <Carousel.Item key={items.marketplace_store_id}>
                                <div
                                  className="productImgSlider newlyAdded"
                                  key={items.marketplace_store_id}
                                >
                                  <Link
                                    to={`/marketplace/added-store/${items.marketplace_store_id}`}
                                  >
                                    <img
                                      src={`${items.logo_id.base_url}${items?.logo_id?.attachment_medium_url
                                        ? items.logo_id.attachment_medium_url
                                        : items.logo_id.attachment_url
                                        }`}
                                      alt={items.name}
                                      title={items.name}
                                    />
                                  </Link>
                                </div>
                                <div className="product-desc">
                                  <h4 className="text-left prdcr-store-title">
                                    {textLimit(items.name, 25)}
                                  </h4>
                                  {/* <h6 className="text-left prdcr-store-title">{items.location.slice(0, 70) + "..."}</h6> */}
                                  <h6 className="text-left prdcr-store-title">
                                    {region_name}
                                  </h6>
                                  <div className="review">
                                    <h4 className="text-left prdcr-store-title">
                                      {items.avg_rating}
                                    </h4>
                                    <ReactStars
                                      edit={false}
                                      isHalf={true}
                                      value={parseFloat(items.avg_rating)}
                                      activeColor="#eb8a2f"
                                      color="rgb(74 72 72)"
                                    />
                                  </div>
                                  <p>
                                    {items.total_reviews}{' '}
                                    {items.total_reviews <= 1
                                      ? lang?.review
                                      : lang?.reviews}
                                  </p>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>

                  <div className="product-main banner">
                    {topBanners?.length > 0 && (
                      <div className="product-type mt-4">
                        <Carousel
                          cols={4}
                          rows={1}
                          gap={10}
                          loop
                          hideArrow={topBanners?.length <= 4 ? true : false}
                        >
                          {topBanners.map((items) => {
                            return (
                              <Carousel.Item key={items.marketplace_banner_id}>
                                <div
                                  className="productImgSlider marketplaceBanner"
                                  key={items.marketplace_banner_id}
                                >
                                  <img
                                    src={`${items.attachment.base_url}${items.attachment.attachment_url}`}
                                    alt={items.name}
                                    title={items.name}
                                  />
                                </div>
                                <div className="product-desc">
                                  <h4 className="text-left prdcr-store-title">
                                    {items.name}
                                  </h4>
                                  <h6 className="text-left prdcr-store-title">
                                    {items.location}
                                  </h6>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>

                  <div className="product-main quickSearch">
                    {italianRegions?.length > 0 && (
                      <div className="product-type pt-4">
                        <h4 className="product-title">
                          {lang?.Quick_search_by_Italian_Regions}
                        </h4>

                        <Carousel
                          cols={6}
                          rows={1}
                          gap={10}
                          hideArrow={italianRegions?.length <= 4 ? true : false}
                          loop={true}
                          responsiveLayout={[
                            {
                              breakpoint: 991,
                              cols: 3,
                            },
                            {
                              breakpoint: 767,
                              cols: 2,
                            },
                          ]}
                          mobileBreakpoint={670}
                        >
                          {italianRegions.map((items, index) => {
                            return (
                              <Carousel.Item key={index}>
                                <div
                                  className="productImgSlider italianRegion"
                                  key={index}
                                >
                                  <Link
                                    to={`/marketplace/producer-store/${items.id}`}
                                  >
                                    <img
                                      src={`${items.flag_id.base_url}${items.flag_id.attachment_url}`}
                                      alt={items.title}
                                      title={items.title}
                                    />
                                  </Link>
                                </div>
                                <div className="product-desc text-center">
                                  <h4 className="prdcr-store-title text-center">
                                    {items.name}
                                  </h4>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  <div className="product-main rectlyAdded customSlider">
                    {topRatedProduct?.length > 0 && (
                      <div className="product-type pt-4">
                        <div className="flex flex-wrap items-center justify-between">
                          <h4 className="product-title">
                            {lang?.Top_Rated_Products}
                          </h4>
                          <Link
                            to={'/marketplace/view-all/top-rated-products/3'}
                            className="product-title list"
                          >
                            {' '}
                            {lang?.View_all}
                          </Link>
                        </div>
                        <Carousel
                          cols={4}
                          rows={1}
                          gap={10}
                          hideArrow={
                            topRatedProduct?.length <= 4 ? true : false
                          }
                          loop
                          responsiveLayout={[
                            {
                              breakpoint: 991,
                              cols: 3,
                            },
                            {
                              breakpoint: 767,
                              cols: 2,
                            },
                            {
                              breakpoint: 500,
                              cols: 1,
                            },
                          ]}
                          mobileBreakpoint={670}
                        >
                          {topRatedProduct.map((items) => {
                            return (
                              <Carousel.Item key={items.marketplace_product_id}>
                                <div
                                  className="productImgSlider"
                                  key={items.marketplace_product_id}
                                >
                                  <Link
                                    to={`/marketplace/product/${items.slug}`}
                                  >
                                    <img
                                      src={`${items.base_url}${items.logo_id}`}
                                      alt={items.title}
                                      title={items.title}
                                    />
                                  </Link>
                                </div>
                                <div className="product-desc">
                                  <h4 className="text-left prdcr-store-title">
                                    {textLimit(items.title, 25)}
                                  </h4>
                                  <h4 className="text-left prdcr-store-title">
                                    {textLimit(items.store_name, 25)}
                                  </h4>
                                  <div className="review">
                                    <h4 className="text-left prdcr-store-title">
                                      {items.avg_rating}
                                    </h4>
                                    <ReactStars
                                      edit={false}
                                      isHalf={true}
                                      value={parseFloat(items.avg_rating)}
                                      activeColor="#eb8a2f"
                                      color="rgb(74 72 72)"
                                    />
                                  </div>
                                  <p>
                                    {items.total_reviews}{' '}
                                    {items.total_reviews <= 1
                                      ? lang?.review
                                      : lang?.reviews}
                                  </p>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  <div className="product-main rectlyAdded customSlider">
                    {topFavourite?.length > 0 && (
                      <div className="product-type pt-4">
                        <h4 className="product-title">
                          {lang?.Top_Favourite_Products}
                        </h4>

                        <Carousel
                          cols={4}
                          rows={1}
                          gap={10}
                          hideArrow={topFavourite?.length <= 4 ? true : false}
                          loop
                          responsiveLayout={[
                            {
                              breakpoint: 991,
                              cols: 3,
                            },
                            {
                              breakpoint: 767,
                              cols: 2,
                            },
                            {
                              breakpoint: 500,
                              cols: 1,
                            },
                          ]}
                          mobileBreakpoint={670}
                        >
                          {topFavourite.map((items) => {
                            return (
                              <Carousel.Item key={items.marketplace_product_id}>
                                <div
                                  className="productImgSlider"
                                  key={items.marketplace_product_id}
                                >
                                  <Link
                                    to={`/marketplace/product/${items.slug}`}
                                  >
                                    <img
                                      src={`${items.base_url}${items.logo_id}`}
                                      alt={items.title}
                                      title={items.title}
                                    />
                                  </Link>
                                </div>
                                <div className="product-desc">
                                  <h4 className="text-left prdcr-store-title">
                                    {textLimit(items.title, 25)}
                                  </h4>
                                  <h4 className="text-left prdcr-store-title">
                                    {items.store_name}
                                  </h4>
                                  <div className="review">
                                    <h4 className="text-left prdcr-store-title">
                                      {items.avg_rating}
                                    </h4>
                                    <ReactStars
                                      edit={false}
                                      isHalf={true}
                                      value={parseFloat(items.avg_rating)}
                                      activeColor="#eb8a2f"
                                      color="rgb(74 72 72)"
                                    />
                                  </div>
                                  <p>
                                    {items.total_reviews}{' '}
                                    {items.total_reviews <= 1
                                      ? lang?.review
                                      : lang?.reviews}
                                  </p>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  <div className="product-main banner">
                    {bottomBanners?.length > 0 && (
                      <div className="product-type mt-4">
                        <Carousel
                          cols={4}
                          rows={1}
                          gap={10}
                          loop={true}
                          hideArrow={bottomBanners?.length <= 4 ? true : false}
                        >
                          {bottomBanners.map((items, i) => {
                            return (
                              <Carousel.Item key={i}>
                                <div
                                  className="productImgSlider marketplaceBanner"
                                  key={items.marketplace_banner_id}
                                >
                                  <img
                                    src={`${items.attachment.base_url}${items.attachment.attachment_url}`}
                                    alt={items.name}
                                    title={items.name}
                                  />
                                </div>
                                <div className="product-desc">
                                  <h4 className="text-left prdcr-store-title">
                                    {items.name}
                                  </h4>
                                  <h6 className="text-left prdcr-store-title">
                                    {items.location}
                                  </h6>
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  {/* <div className="product-main rectlyAdded mt-4">
                    <div className="productBlockImg">
                      {
                        bottomBanners?.length > 0 && bottomBanners?.map((item, indx) => {
                          return (
                            <div className="md:w-1/4">
                              <div className="imgBlock50" key={indx}>
                                <Link to="#">
                                  <img
                                    src={`${item?.attachment?.base_url}${item?.attachment?.attachment_url}`}
                                    alt="Image"
                                  />
                                </Link>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
