import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';
import { setProfileTabIndex } from '../../profile/modules/profile';
import { getUserSetting, updateUserSetting } from '../module/UserSetting';
import SettingLeftNavigation from './SettingLeftNavigation';
import { Link } from 'react-router-dom';
import auth from '../../../helpers/auth';

export default function Setting() {
  const dispatch = useDispatch();
  const [local_language, setLang] = useState('');
  const [webUrl, setWebUrl] = useState();
  const [username, setUsername] = useState();
  const [displayName, setDisplayName] = useState();
  const [user, setUser] = useState('');
  const [lang] = useState(language.getLang());
  const { userSettngData, userSettingSpinner, updateUserSettingSpinner } =
    useSelector((state) => state.userSettingState);
  useEffect(async () => {
    const user = await auth.getUserType();
    await setUser(JSON.parse(user));
    document.body.classList.remove("hidePageScroll");
    await dispatch(getUserSetting());
    await dispatch(setProfileTabIndex(0));
  }, []);

  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = false;
      s1.src = 'https://embed.tawk.to/62b20cce7b967b117995cd80/1g63o425l';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  /*useEffect(() => {

    const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/62b20cce7b967b117995cd80/1g63o425l';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s1.id="tawkId"
    s0.parentNode.insertBefore(s1,s0);
    // tawkto chat
    // var tawk = new TawkTo('62b20cce7b967b117995cd80', '1g63o425l')
    // tawk.onStatusChange((status) => 
    // {
    //     // console.log(status)
    // })


    // fresh chat 
  // window.fcWidget.show() 
  // window.fcWidget.init({
  //     token: "99198db8-ea46-4f69-a106-4fd0076835b4",
  //     // token: "lwDt1O7bgKqvjNmWbMGhMg",    
  //     // token: "140931b5-5f54-4022-96a8-2c017e818f57",
  //     host: "https://wchat.freshchat.com",
  //     // host: "https://ibyteinfomaticspvtltd-6719fe35138c50616571020.freshchat.com",
  //     // token: "4b33d617de872d8451ca6d3ea73ec2bbda1fac5dcb35bdab783368bae1931724",
  //     // host: "https://ibyteinfomaticspvtltd.myfreshworks.com/crm/sales&output=embed",
  //     siteId: "freshchat",
  //     firstName: "Sandy",
  //     lastName: "Singh",
  //     email: "sandy@ibyteinfomatics.com",
  //     tags: ['answerbotchannel']
  //   });


  }, [])*/

  //console.log(user,"userSettngData");

  useEffect(async () => {
    // await setLang(userSettngData?.user_settings?.locale);
    await setWebUrl(
      userSettngData?.user_settings?.website?.replace('null', '')
    );
    await setDisplayName(
      (userSettngData?.user_settings?.first_name !== null &&
        userSettngData?.user_settings?.last_name !== null &&
        `${
          userSettngData.user_settings &&
          userSettngData.user_settings.first_name
        } ${
          userSettngData.user_settings && userSettngData.user_settings.last_name
        }`) ||
        userSettngData?.user_settings?.company_name ||
        userSettngData?.user_settings?.restaurant_name
    );
    await setUsername(
      (userSettngData?.user_settings?.first_name !== null &&
        userSettngData?.user_settings?.last_name !== null &&
        `${userSettngData?.user_settings?.first_name} ${userSettngData?.user_settings?.last_name}`) ||
        userSettngData?.user_settings?.company_name ||
        userSettngData?.user_settings?.restaurant_name
    );
  }, [userSettngData]);

  useEffect(() => {
    setLang(localStorage.getItem('lang'));
  }, []);

  const submitHandle = async (e) => {
    e.preventDefault();
    const model = {
      display_name: displayName,
      locale: local_language,
      website: webUrl,
    };
    await dispatch(updateUserSetting(model));
  };

  const changeLanguage = (event) => {
    const selectLan = event.target.value;
    if (selectLan !== '-1') {
      localStorage.setItem('lang', selectLan);
      window.location.reload();
    }
    setLang(selectLan);
  };
  const getOrgName = (roleId) => {
    // const { user_settings: { company_name, first_name, restaurant_name, last_name } } = userSettngData;
    if (
      userSettngData?.user_settings?.first_name !== null ||
      userSettngData?.user_settings?.last_name !== null
    ) {
      return <div className="font-16 text-normal">{lang?.Full_name}</div>;
    } else if (userSettngData?.user_settings?.company_name !== null) {
      return (
        <div className="font-16 text-normal">
          {lang?.only_Name} {lang?.Company}
        </div>
      );
    } else if (userSettngData?.user_settings?.restaurant_name !== null) {
      return (
        <div className="font-16 text-normal">
          {lang?.Restaurant} {lang?.only_Name}
        </div>
      );
    }
  };
  
  return (
    <div>
      {/* Layout Content */}
      <div className="home__page b2b settings">
        <div className="servics_blocks">
          <div className="site-align top-align">
            <SettingLeftNavigation />
            <div className="servics_blocks middle-long__section-block middleBlockBg">
              <div className="medium_title">
                <h1 className="font-18 text-black">
                  {lang?.General_Account_Settings}
                </h1>
              </div>
              <div className="md:w-3/4 mt-6">
                {userSettingSpinner ? (
                  <FormSpinner />
                ) : (
                  <form onSubmit={(e) => submitHandle(e)}>
                    <div className="flex">
                      <div className="md:w-2/4">
                        <div className="form__item">
                          <label className="font-16">{lang?.URL}</label>
                          <input
                            type="text"
                            placeholder="https://social.alysei.com/u/"
                            value={webUrl}
                            onChange={(e) => setWebUrl(e.target.value)}
                            name=""
                          />
                        </div>
                      </div>
                      <div className="md:w-2/4 pl-4">
                        <div className="form__item">
                          <label className="font-16 text-normal">
                            {lang?.Username}
                          </label>
                          <input
                            type="text"
                            placeholder="https://social.alysei.com/u/"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            name=""
                            disabled={user?.role_id === 10 || user?.role_id === 7 ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form__item">
                      {getOrgName()}
                      {/* {userSettngData?.user_settings?.company_name !== null && <label className='font-16 text-normal'>{userSettngData?.user_settings?.company_name}</label>} */}
                      {/* {userSettngData?.user_settings?.restaurant_name !== null && <label className='font-16 text-normal'>{userSettngData?.user_settings?.restaurant_name}</label>}
                      {userSettngData?.user_settings?.first_name !== null && userSettngData?.user_settings?.last_name !== null && <label className='font-16 text-normal'>{lang?.only_Name}</label>} */}
                      <input
                        type="text"
                        placeholder="https://social.alysei.com/u/"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        name=""
                        disabled={user?.role_id === 10 || user?.role_id === 7 ? false : true}
                      />
                    </div>
                    <div className="form__item changeEmail">
                      <label className="font-16 text-normal">
                        {lang?.Email}
                      </label>
                      <div className="textBorder">
                        <p className="forINput">
                          {userSettngData?.user_settings?.email}
                        </p>
                        <span className="">
                          <Link to="/change-email">{lang?.change_email}</Link>
                        </span>
                      </div>
                    </div>
                    <div className="form__item">
                      <label className="font-16 text-normal">
                        {lang?.Language}
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => changeLanguage(e)}
                        value={local_language}
                      >
                        <option value={'-1'}>{lang?.Select_option}</option>
                        <option value={'en'}>{lang?.English}</option>
                        <option value={'it'}>{lang?.Italian}</option>
                      </select>
                    </div>
                    <div className="btnBox">
                      <button className="btn btnPrimary" type="submit">
                        {updateUserSettingSpinner ? (
                          <FormSpinner />
                        ) : (
                          `${lang?.Save_Changes}`
                        )}
                      </button>{' '}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
