import React, { Fragment } from 'react'
import '../../../assets/recipe/style.css'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import FormSpinner from "../../../components/Spinners/FormSpinner";
import Modal from "react-modal";
import { useState, useEffect } from 'react';
import auth from '../../../helpers/auth';
import FormErrors from '../../../components/Errors/FormError'
import language from '../../../helpers/language';
import { submitRecipeData, submitRecipePostData, recipeUpdateData, tabIndexFun } from '../modules/RecipeModule'
export default function AddRecipeStep() {

  const [lang] = useState(language.getLang());
  const history = useHistory()
  const dispatch = useDispatch()
  const intialValues = {
    title: "",
    description: ""
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [recipeCreatesteps, setRecipeCreatesteps] = useState(1);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedIngradients, setSelectedIngradients] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [isRecipeSteps, setIsRecipeSteps] = useState([]);
  const [recipeStepSelectedTools, setRecipeStepSelectedTools] = useState([])
  const [recipeStepSelectedIngredients, setRecipeStepSelectedIngredients] = useState([]);
  const [createAndUpdateLoader, setCreateAndUpdateLoader] = useState(false);
  const [disabledTrue, setDisabledTrue] = useState(false);
  const [stepEditIndex, setStepEditIndex] = useState(-1);
  const [recipeinformation, setRecipeinformation] = useState('');
  const [stepError, setStepError] = useState('');
  const [stepErrorStatus, setStepErrorStatus] = useState(false);
  const [isPostingStatus, setIsPostingStatus] = useState(false);
  const [postingButtonDisabled, setPostingButtonDisabled] = useState(false);
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [explorePercentageSpinner, setExplorePercentageSpinner] = useState(false);

  // Get Selected Ingredients
  useEffect(async() => {
    if(localStorage.getItem('savedIngredients') != null){
      await setSelectedIngradients(JSON.parse(localStorage.getItem('savedIngredients')));
    }
    if(localStorage.getItem('savedTools') != null){
      await setSelectedTools(JSON.parse(localStorage.getItem('savedTools')));
    }
    if(localStorage.getItem('recipe_step') != null){
      const addedSteps = JSON.parse(localStorage.getItem('recipe_step'));
      await setIsRecipeSteps(addedSteps);
      await setRecipeCreatesteps(addedSteps.length + 1);
    }
    if(localStorage.getItem('generalInformation') != null){
      await setRecipeinformation(JSON.parse(localStorage.getItem('generalInformation')));
    }
  },[])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      saveRecipeSteps();
    }

    if (Object.keys(formErrors).length === 0 && isUpdating) {
      updateRecipeSteps();
    }
  }, [formErrors]);


  // Save Recipe Step
  const saveRecipeSteps = async() => {
    setDisabledTrue(true);
    setCreateAndUpdateLoader(true);
    const addRecipeStepObject = await {
      title: formValues.title,
      description: formValues.description,
      ingredients: [...new Set(recipeStepSelectedIngredients)],
      tools: [...new Set(recipeStepSelectedTools)],
    }
    isRecipeSteps.push(addRecipeStepObject)
    localStorage.setItem('recipe_step', JSON.stringify(isRecipeSteps));
    await setIsRecipeSteps(isRecipeSteps)
    await setFormValues({ title: "", description: ""});
    await setRecipeStepSelectedTools([]);
    await setRecipeStepSelectedIngredients([]);
    await setIsSubmitting(false);
    setCreateAndUpdateLoader(false);
    setDisabledTrue(false);
    await setRecipeCreatesteps(recipeCreatesteps + 1)
  }

  const addMoreRecipe = () => {
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
    
  }


  // Form validation
  const validate = (values) => {
    let errors = {};
    if (!values.title) {
      errors.title = `${lang?.please_write} ${lang?.only_title}`;
    }
    if (!values.description) {
      errors.description = `${lang?.please_write} ${lang?.Description}`;
    }
    return errors;
  };

  // Options set in states
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  }

  // Select Step Ingredient 
  const selectRecipeIngredient = async (e, item) => {
    if (e.target.checked) {
      await setRecipeStepSelectedIngredients((old) => [...old, item.ingredient_id])
      document.getElementById(item.ingredient_id).checked = true;
    } else {
      const index = recipeStepSelectedIngredients.indexOf(item.ingredient_id)
      if (index > -1) {
        recipeStepSelectedIngredients.splice(index, 1)
        await setRecipeStepSelectedIngredients(recipeStepSelectedIngredients)
        document.getElementById(item.ingredient_id).checked = false;
      }
    }
    
  }

  // Select Step Tool 
  const selectRecipeTool = async (e, item) => {
    if (e.target.checked) {
      await setRecipeStepSelectedTools((old) => [...old, item.tool_id])
      document.getElementById(item.tool_id).checked = true;
    } else {
      const index = recipeStepSelectedTools.indexOf(item.tool_id)
      if (index > -1) {
        recipeStepSelectedTools.splice(index, 1)
        await setRecipeStepSelectedTools(recipeStepSelectedTools)
        document.getElementById(item.tool_id).checked = false;
      }
    }
    
  }

  // Goback Step
  const goToBackPage = async () => {
      history.push("/recipes/add-untencil");
  }


  const recipeCreatePost = async() => {
    await setPostingButtonDisabled(true);
    await setIsPostingStatus(true);
    const postdata = {
      params: {
        name: recipeinformation?.name,
        meal_id: recipeinformation?.meal_id,
        course_id: recipeinformation?.course_id,
        hours: recipeinformation?.hours || 0,
        minutes: recipeinformation?.minutes || 0,
        serving: recipeinformation?.serving,
        cousin_id: recipeinformation?.cousin_id,
        region_id: recipeinformation?.region_id,
        diet_id:recipeinformation?.diet_id,
        intolerance_id: recipeinformation?.intolerance_id,
        cooking_skill_id: recipeinformation?.cooking_skill_id,
        image_id: recipeinformation?.recipeImage,
        saved_ingredients: selectedIngradients,
        saved_tools: selectedTools,
        recipe_steps: isRecipeSteps,
        status: 1,
      },
    };
    if (localStorage.getItem('recipe_id') != null) {
      if(recipeinformation?.recipeImage === undefined){
         delete postdata?.params?.image_id;
      }
      postdata.params["recipeId"] = localStorage.getItem('recipe_id')
      await recipeUpdateData(postdata).then(async (response) => {
        if (response && response.success === 200) {
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("recipe_id")
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response && response.success === 409) {
          await setStepError(response.errors.exception)
          await setStepErrorStatus(true);

        }
      })
    } else if (isRecipeSteps && isRecipeSteps.length > 0) {
      await submitRecipeData(postdata).then(async (response) => {
        if (response.success === 200) {
          dispatch(submitRecipePostData(postdata))
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response.success === 409) {
          await setStepError(response.errors.exception)
          await setStepErrorStatus(true);
        }
      });

    } else {
      await setStepError(lang?.Please_Add_Recipe_Steps)
      await setStepErrorStatus(true);
    }

    await setPostingButtonDisabled(false);
    await setIsPostingStatus(false);
    setTimeout(() => {
      setStepError("");
      setStepErrorStatus(false);
    }, 3000);
  }

  // Edit Recipe Step
  const editRecipeStep = async(list, index) => {
    await setFormValues({ ...formValues, ['title']: list.title, ['description']: list.description });
    setRecipeStepSelectedIngredients(list.ingredients)
    setRecipeStepSelectedTools(list.tools)
    setIsUpdate(true);
    setStepEditIndex(index);
    setRecipeCreatesteps(index + 1);
  }

  // Update Recipe Step with check validation
  const updateRecipeStep = () => {
    setFormErrors(validate(formValues));
    setIsUpdating(true);
    
  }

  // Update Recipe Step
  const updateRecipeSteps = async() => {
    //console.log(stepEditIndex,"stepEditIndex");
    if(stepEditIndex > -1){
      //console.log(formValues.title,"title");
      isRecipeSteps[stepEditIndex].title = formValues.title;
      isRecipeSteps[stepEditIndex].description = formValues.description;
      isRecipeSteps[stepEditIndex].ingredients = recipeStepSelectedIngredients;
      isRecipeSteps[stepEditIndex].tools = recipeStepSelectedTools;
      await setIsRecipeSteps([...isRecipeSteps]);
      localStorage.setItem('recipe_step', JSON.stringify(isRecipeSteps));
      await setFormValues({ title: "", description: ""});
      await setRecipeStepSelectedTools([]);
      await setRecipeStepSelectedIngredients([]);
      await setIsUpdating(false);
      setDisabledTrue(false);
      setIsUpdate(false);
      setRecipeCreatesteps(isRecipeSteps.length + 1);
    }
  }

  // Delete Recipe Step
  const recipeDelete = async(e, index) => {
    isRecipeSteps.splice(index, 1);
    await setIsRecipeSteps([...isRecipeSteps]);
    localStorage.setItem('recipe_step', JSON.stringify(isRecipeSteps));
    setRecipeCreatesteps(recipeCreatesteps - 1)
    await setFormValues({ title: "", description: ""});
    await setRecipeStepSelectedTools([]);
    await setRecipeStepSelectedIngredients([]);
    await setIsSubmitting(false);
    setCreateAndUpdateLoader(false);
    setDisabledTrue(false);
    await setIsUpdating(false);
  }

   // Save recipe as a draft
   const saveInDraft = async() => {
    await setExplorePercentageSpinner(true)
    const postdata = {
      params: {
        name: recipeinformation?.name,
        meal_id: recipeinformation?.meal_id,
        course_id: recipeinformation?.course_id,
        hours: recipeinformation?.hours || 0,
        minutes: recipeinformation?.minutes || 0,
        serving: recipeinformation?.serving,
        cousin_id: recipeinformation?.cousin_id,
        region_id: recipeinformation?.region_id,
        diet_id:recipeinformation?.diet_id,
        intolerance_id: recipeinformation?.intolerance_id,
        cooking_skill_id: recipeinformation?.cooking_skill_id,
        image_id: recipeinformation?.recipeImage,
        saved_ingredients: selectedIngradients,
        saved_tools: selectedTools,
        recipe_steps: isRecipeSteps,
        status: 0,
      },
    };
    if (localStorage.getItem('recipe_id') != null) {
      if(recipeinformation?.recipeImage === undefined){
         delete postdata?.params?.image_id;
      }
      postdata.params["recipeId"] = localStorage.getItem('recipe_id')
      await recipeUpdateData(postdata).then(async (response) => {
        if (response && response.success === 200) {
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("recipe_id")
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response && response.success === 409) {
          await setStepError(response.errors.exception)
          await setStepErrorStatus(true);

        }
      })
    } else if (recipeinformation != '') {
      await submitRecipeData(postdata).then(async (response) => {
        if (response.success === 200) {
          dispatch(submitRecipePostData(postdata))
          await dispatch(tabIndexFun(2))
          await localStorage.removeItem("savedIngredients")
          await localStorage.removeItem("savedTools")
          await localStorage.removeItem("recipe_step")
          await localStorage.removeItem("generalInformation")
          history.push('/recipes')
        } else if (response.success === 409) {
          await setStepError(response.errors.exception)
          await setStepErrorStatus(true);
        }
      });

    }
  }
  return (
    <Fragment>
      {/* Layout Content */}
      <div className="recipe__page">
        <div className="step">
          <div className="cancel">
            <Link to="#" onClick={() => { setIsSaveDraft(!isSaveDraft)}}><span><i className="fa fa-arrow-left" aria-hidden="true"></i></span>{`${lang?.Create} ${lang?.Recipes}`}</Link>
          </div>
          <div className="right__align_btn btn-white noAlign flex items-center">
            <button disabled={postingButtonDisabled} onClick={() => goToBackPage()} className="btn ">{lang?.Back}</button>

            {
              localStorage.getItem('recipe_id') === null ?
                <button
                  onClick={() => recipeCreatePost()}
                  className="btn activeBtn" disabled={postingButtonDisabled}>{isPostingStatus ? <FormSpinner/> : `${lang?.only_Post}`}</button> :
                <button
                  onClick={() => recipeCreatePost()}
                  className="btn activeBtn" disabled={postingButtonDisabled}> {isPostingStatus ? <FormSpinner/> : `${lang?.Update}`}</button>
            }
          </div>
        </div>
        {stepErrorStatus ? <FormErrors msg={stepError} /> : '' }
        <div className="top__div">
          <div className="innerPageTitle">
            <h4 className="semi__bold__title">
              <span className="circle">4</span>
              {lang?.Add_Recipe_Steps}
            </h4>
          </div>
         
        </div>
        <div className="site__width">
          <div className="ingredient__main">
            <div className="ingredient__width">
              <div className="ingredients__search">
                <form className="recipe_form">
                  <div className="form__item">
                    <label>{lang?.Step} {recipeCreatesteps} {lang?.only_title}</label>
                    <input type="text"
                      name="title"
                      value={formValues.title}
                      placeholder={lang?.Enter_Title_for_Step} id="title"
                      onChange={(event) => handleChange(event)}
                       /> 
                      {formErrors.title ? <FormErrors msg={formErrors.title} /> : ''}
                  </div>

                  <div className="form__item">
                    <label>{lang?.Description}</label>
                    <textarea placeholder={lang?.Your_recipe_direction_text_here} id="store-message"
                      name="description"
                      value={formValues.description} 
                      onChange={(event) => handleChange(event)}
                      />
                      {formErrors.description ? <FormErrors msg={formErrors.description} /> : ''}
                  </div>
                  <div className="right__align_btn btn-white justify-end">
                  {
                    isUpdate ? <button type="button" className="btn activeBtn" onClick={(e) => updateRecipeStep(e)}>{lang?.Update} {lang?.Step}</button>
                      :  <button type="button" disabled={disabledTrue} className="btn activeBtn" onClick={(e) => {addMoreRecipe(e)}}> {createAndUpdateLoader ? <FormSpinner/>: lang?.Save_Proceed}</button>
                  }
                </div>
                </form>
              <hr/>
              </div>

              <h4 className="semi__bold__title">
                {
                   `${lang?.IngredientsUsed} ${recipeCreatesteps}`
                }
              </h4>
              <ul className="flex flex-wrap">
                {
                  selectedIngradients?.length > 0 && selectedIngradients.map((item, i) => {
                    return (
                      <Fragment>
                        <li className="recipe__detail">
                        {recipeStepSelectedIngredients.includes(item.ingredient_id) ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={item.title}
                              checked={true}
                              id={item.ingredient_id}
                              onClick={(e) => {
                                selectRecipeIngredient(e, item);
                              }}
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={item.title}
                              checked={false}
                              id={item.ingredient_id}
                              onClick={(e) => {
                                selectRecipeIngredient(e, item);
                              }}
                            />
                          )}
                          <label htmlFor={item.ingredient_id}>
                            <img
                              src={`${item.base_url}${item.src}`}
                              alt="Recipe"
                            />
                            <div className="check_mark" />
                            <h4 className="ingredient_name">{item.title}</h4>
                          </label>
                        </li>
                      </Fragment>
                    );
                  })
                }
              </ul>
              <h4 className="semi__bold__title">
                {
                   `${lang?.Tools_used_in_Step} ${recipeCreatesteps}`
                }

              </h4>
              <ul className="flex flex-wrap">
                {
                  selectedTools.length > 0 && selectedTools.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <li className="recipe__detail">
                          {recipeStepSelectedTools.includes(item.tool_id) ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={true}
                                id={item.tool_id}
                                onChange={(e) => {
                                  selectRecipeTool(e, item);
                                }}
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={item.tool_id}
                                checked={false}
                                onChange={(e) => {
                                  selectRecipeTool(e, item);
                                }}
                              />
                            )}
                          <label htmlFor={item.tool_id}>
                            <img
                              src={`${item.base_url}${item.src}`}
                              alt="Recipe"
                            />
                            <div className="check_mark" />
                            <h4 className="ingredient_name">{item.title}</h4>
                          </label>
                        </li>
                      </Fragment>
                    );
                  })
                }
              </ul>
            </div>
            <div className="ingredients-list">
              <h4 className="semi__bold__title add-recipe pb-2 borderBottom">{lang?.RecipeSteps}</h4>
              {
                isRecipeSteps && isRecipeSteps.length > 0 && isRecipeSteps.map((list, l) => {
                  return (
                    <Fragment>
                      <div className="ingredient-detail">
                        <div className="ingredient-left justify-between" key={l}>

                          <h4 className='font-16'>{list.title}</h4>
                          <div className="ingredient-right">
                            <Link to="#" onClick={(e) => editRecipeStep(list, l)} className="pl-4 edit">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                            <Link to="#" className="pl-4 close">
                              <i className="fa fa-times-circle" aria-hidden="true" onClick={(e) => { recipeDelete(e, l) }}></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className='pt-2 pb-2'>{list.description}</div>
                    </Fragment>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      {isSaveDraft && (
        <div>
          <Modal
            isOpen={true}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
              },
              content: {
                position: "absolute",
                top: "0",
                left: "0%",
                right: "0%",
                bottom: "200px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                padding: "0",
                // outline: 'none',
              },
            }}
          >
            <div className="popup_header flex justify-between items-center">
              <h4 className="page__title">{lang?.Save_in_draft}</h4>
              <button
                className="close_btn--modal"
                onClick={() => setIsSaveDraft(!isSaveDraft)}
              >
                {lang?.close}
              </button>
            </div>
            <div className="card card--block modal_box">
              <p>{lang?.are_you_sure}</p>
              <div className="right__btn text-right">
                <Link
                  className="btn btn1"
                  to="/recipes"
                  onClick={() => setIsSaveDraft(false)}
                >
                  {lang?.Cancel}
                </Link>
                <Link
                  className="btn btn2 ml-3"
                  to="#"
                  onClick={() => saveInDraft()}
                >
                  {explorePercentageSpinner ? <FormSpinner /> : `${lang?.Save}`}

                </Link>
              </div>
            </div>
            <div></div>
          </Modal>
        </div>
      )}
      {/* Footer */}
    </Fragment>
  )
}