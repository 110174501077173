// src/pages/DeleteUser.js

import React, { useEffect, useState } from 'react';
import REACT_APP_API from "../../config/environment"
import auth from '../../helpers/auth'
import headerType from '../../helpers/headerType';


const DeleteUser = () => {
  const [id, setId] = useState('');
  const [message,setMessage]=useState()
  const [toast, setToast] = useState(false);

  useEffect(()=>{
    let data= JSON.parse(localStorage.getItem("USER_TYPE"))
    setId(data.user_id)
   },[])


  const handleDelete = async (event) => {
    event.preventDefault();

    const token = await auth.getAccessToken();

    try {
      const response = await fetch(`${REACT_APP_API.ENV.REACT_APP_API}/account/delete/${id}`, {
        method: 'DELETE',
        headers: headerType.HeadersWithToken(token),
      });

      if (response.ok) {
        setMessage('User deleted successfully.');
        setTimeout(()=>{
          setMessage('')
        },2000)
      } else {
        const errorData = await response.json();
        setMessage(`Error: ${errorData.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-6 text-center">Delete Account</h2>
        <form onSubmit={handleDelete}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="id">
              User Id
            </label>
            <input
              type="text"
              id="id"
              value={id}
              onChange={(e) => setId(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter user id"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Delete
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteUser;
