import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import language from "../../../helpers/language";

import { seletedCityNameArray, postSlectedHubs, reviewHubs, stateHubSelect, newHubStateSelected, selectedHubsState } from "../modules/hubs";

export default function CityName(props) {
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    selectedCityName,
    statehubselect,
    selectedHubsStateData,
    Hubs,
    reviewHubsData
  } = useSelector((state) => state.hubs_State);

  const history = useHistory();
  const [selectedHubId, setSelectedHubId] = useState([]);
  const [selectedCity, setselectedCity] = useState([]);
  useEffect(async () => {
    document.body.classList.add(`hubs`);
    let cityArr = []
    let hubsArr = []
    if (location?.pathname.includes("edit-hubs")) {
      await dispatch(reviewHubs()).then((res) => {
        if (res && res[0]?.cities) {
          res[0].cities?.map((item, index) => {
            let obj = {
              country_id: item.country_id,
              id: item.city.id,
              name: item.city.name,
              state_id: item?.state_id,
              state: item?.state
            }
            cityArr.push(obj)
          })
        }

        if (res && res[0]?.hubs) {
          res[0].hubs?.map((item, index) => {
            hubsArr.push(item)
          })
        }
      })
      await dispatch(selectedHubsState(hubsArr))
      await dispatch(seletedCityNameArray(cityArr))
    }

  }, [])

  useEffect(() => {
    setselectedCity([]);
    selectedCityName?.length > 0 && selectedCityName?.map((item) => {
      let selectedcityObj = ({
        city_id: item.id,
        country_id: item.country_id,
        state_id: item.state_id,
      });
      return (
        setselectedCity((id) => [...id, selectedcityObj])
        // setselectedCity(selectedcityObj)

      );
    });
  }, [selectedCityName]);

  useEffect(() => {
    selectedHubsStateData?.length > 0 && selectedHubsStateData?.map((item) => {
      return (
        setSelectedHubId((id) => [...id, item.id])
      );
    });
  }, [selectedHubsStateData]);

  // Post hubs Into API
  const updateHubs = async () => {
    const params = {
      add_or_update: 2,
      selectedhubs: [...new Set(selectedHubId)],
      selectedcity: selectedCity,
    };

    //console.log(params, "params");
    await dispatch(postSlectedHubs(params));
    history.push("/home");
  };


  const removeHubs = async (index) => {
    if (index > -1) {
      selectedCityName.splice(index, 1);
      await setselectedCity(selectedCityName);
      await dispatch(seletedCityNameArray([...new Set(selectedCityName)]));
      //console.log(selectedCityName,"selectedCityName");
    }
  };
  const removeAllHubs = async () => {
    await dispatch(seletedCityNameArray([]));
    await dispatch(newHubStateSelected([]));
    // history.push("/hubs");
    history.push({ pathname: '/hubs', state: true })
  };
  const onEdit = async () => {
    history.push({ pathname: '/selectedState', state: true })
  };
  return (
    <Fragment>
      <div className="hub__selection--form bg-white cityname">
        <form className="hub__form padding--5">
          <div className="form--inner--div">
            <div className="flex">
              <div className="w-full">
                <div className="coln-left">
                  <h4 className="title-blue-font-30">{lang?.USA}</h4>
                  <p className="title-para">
                    {lang?.selectedHubs}
                  </p>
                  <div className="multiple--hubs forHubView">
                  {/* selected hubs... */}
                  {selectedHubsStateData?.length > 0 && selectedHubsStateData?.map((item) => {
                    {console.log(item,"itemitemitem")}
                    return (
                      // <div className="hub__tab padding--5 md:w-1/4">
                      <div className="hub__tab padding--5">
                        <div className="choose__hub edit--hub">
                          <div className="chicago--hub form__item form__checkbox country-check">
                            <label
                              htmlFor={item}
                              className="chicago-hub flex p-4 rounded-lg"
                              style={{ backgroundImage: `url("${item?.image?.base_url}${item?.image?.attachment_url}")` }}
                            >
                              {/* <img className="w-8" src={`${item?.image?.base_url}${item?.image?.attachment_url}`} alt="hubImg" /> */}
                              <span className="default-hub font-bold font-16">
                                {`  ${item.title} `}
                                <span className="innerSelect font-bold font-14">{item?.state?.name}</span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                  {/* <p className="title-para mb-14">{lang?.Select_city_where_You_from_exported} </p> */}
                  <div className="hub__tab padding--5">
                    <div className="choose__hub">
                      {/* selected cities */}
                      {selectedCityName?.length > 0 && selectedCityName?.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <div className="selected__data">
                              <div className="hub--block">
                                <div className="hub-block-left" onClick={() => removeHubs(index)}>
                                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </div>
                                <div className="hub-block-right">
                                  <h4 className="font-16 font-bold">
                                    {item?.state?.name}
                                    <span>
                                      {item.name}
                                    </span>
                                  </h4>

                                </div>

                                {/* <span className="pr-3" >
                                  
                                </span>
                                
                              </h4> */}
                                <p className="pl-6">

                                </p>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="closeAll mt-4 flex justify-between items-center">
              <div className="btnBlockLeft">
                <div className="bg-btn-gray sky-blue">
                  <button type="button" className="btn" onClick={() => updateHubs()}>
                    {lang?.Next}
                  </button>
                </div>
              </div>
              <div className="btnBlockRight">
                <Link to="#" onClick={() => location?.pathname?.includes("edit-hubs") ? onEdit() : props?.onEdit()}>
                  <p className="pb-2">
                    {lang?.Edit}{" "}
                    <span>
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </span>
                  </p>
                </Link>
                <Link to="#" onClick={() => location?.pathname?.includes("edit-hubs") ? removeAllHubs() : props?.removeAllHubs()}>
                  <p>
                    {lang?.Remove_all}{" "}
                    <span >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </form>

      </div>
    </Fragment>
  );
}
