import React from 'react'
import logo from '../../../assets/images/logo.png'

export default function FooterView() {
  return (
    <>
        <p className=''>Powered by 
            <span>
                <img src={logo} alt="Logo" />
            </span>
        </p>
    </>
  )
}
