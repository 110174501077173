import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Modal from "react-modal";
import language from '../../helpers/language';
import { getBrokerAndShipping } from '../../routes/home/modules/HomeModule';
import Parse from 'html-react-parser';


export default function NewBubblesComponentView() {
    const [lang] = useState(language.getLang());
    const { slug } = useParams();
    const [dataListing, setDataListing] = useState([]);
    const [showDataInModal, setShowDataInModal] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const maxLength = 130;

    useEffect(() => {
        getBrokerAndShippingData(slug);
    }, [slug]);

    const getBrokerAndShippingData = async (slug) => {
        setLoading(true);
        const response = await getBrokerAndShipping(slug);
        if (response?.success) {
            setDataListing(response?.data?.data);
            setLoading(false);
        }
        else {
            setLoading(false);
            setDataListing([]);
            console.log(response?.message);
        }
    }

    // Check post content has URL
    const Checkurl = (text) => {
        if (text)
            var url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
                url2 = /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;
        var html = text;
        if (html) {
            html = html
                .replace(url1, '$1<a target="_blank"  href="http://$2">$2</a>$3')
                .replace(url2, '$1<a target="_blank"  href="$2">$2</a>$5')
                .replace(/(\w+@\w+.\w+)+/g, "<a href='mailto:$1'>$1</a>");
        }

        return Parse(html?.replace(/\n/g, "<br />"));
    };

    const handleEmailClick = (email) => {
        window.location.href = `mailto:${email}`;
    };

    const handlePhoneClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <div className='discover_page sm:mb-1 createTrip newLayout'>
            <div className="page_top_form">
                <div className="flex justify-between items-center">
                    <Link to="/home">
                        {" "}
                        <i className="fa fa-arrow-left pr-2" aria-hidden="true"></i>{slug.charAt(0).toUpperCase() + slug.slice(1)}
                    </Link>
                </div>
            </div>
            <div className="discover__events">
                {!loading && dataListing?.length === 0 && (
                    <h4 className="text-center font-18  mt-16">
                        {lang?.No_data_found}
                        {/* No data found */}
                    </h4>
                )}
                {loading && <p className="text-center font-18 text-black mt-16">Loading...</p>}
                <ul className="event__list">
                    {dataListing?.length > 0 &&
                        dataListing?.map((item, index) => {
                            return (
                                <li className="w-full md:w-1/4" key={index} onClick={() => { setShowDataInModal({ description: item?.description }); }}>
                                    <div className="event__card" >
                                        {item?.attachment &&
                                            <div className="event__img">
                                                {item?.url !== "#" ? <a href={item?.url ? item?.url : "#"} target='_blank'>
                                                    <img
                                                        src={`${item?.attachment?.base_url}${item?.attachment?.attachment_url}`}
                                                        alt="Image"
                                                    />
                                                </a>
                                                    :
                                                    <img
                                                        src={`${item?.attachment?.base_url}${item?.attachment?.attachment_url}`}
                                                        alt="Image"
                                                    />}
                                            </div>
                                        }
                                        <div className="event__deatil itemCenter">
                                            <h5 className="event-title">
                                                {item?.title}
                                            </h5>
                                            <div className="Host Description">
                                                <div className="desTitle mb-4">
                                                    {item?.description?.length > maxLength ? <span onClick={() => setShowModal(true)}>
                                                        {Checkurl(item?.description.replace(/<[^>]+>/g, '')
                                                            .replace(/\s+/g, ' ')
                                                            .trim()
                                                            .slice(0, maxLength))}<Link to="#">
                                                            ...{lang?.Read_More}
                                                        </Link></span> : Checkurl(item?.description)}
                                                </div>
                                                <p className="Host titleGroup mb-4">
                                                    <span className=''>{lang?.Email} :</span><p style={{ color: "#4bb3fd" }} onClick={() => handleEmailClick(item?.email)}> {item?.email}</p>
                                                </p>

                                                <p className="Host titleGroup">
                                                    <span className=''>{lang?.Tel} :</span><p style={{ color: "#4bb3fd" }} onClick={() => handlePhoneClick(item?.phone_number)}> +{`${item?.country_code} ${item?.phone_number}`}</p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
            {
                showModal && < div >
                    <Modal

                        ariaHideApp={false}
                        isOpen={showModal}
                        style={{
                            overlay: {
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(255, 255, 255, 0.75)",
                            },
                            content: {
                                position: "absolute",
                                top: "140px",
                                left: "30%",
                                right: "30%",
                                bottom: "200px",
                                border: "1px solid #ccc",
                                background: "#fff",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch",
                                borderRadius: "4px",
                                // outline: 'none',
                                padding: "20px",
                            },
                        }}
                    >
                        <button className="close_btn--modal" onClick={() => setShowModal(false)} > {lang?.close} </button>
                        <div className="discover__events">
                            {showDataInModal &&
                                <div className="event__card" >
                                    <div className="event__deatil">
                                        <div className="Host Description">
                                            <div className="desTitle">
                                                {Checkurl(showDataInModal?.description)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal>
                </div>
            }
        </div >
    )
}
