import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import language from '../../../helpers/language';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import { editEvent } from '../../home/modules/HomeModule';

export default function ViewEvent() {
  const [lang] = useState(language.getLang());
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [userPhotosData, setUserPhotosData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);

  const {
    editEventData: { data },
    editEventSpinner,
  } = useSelector((state) => state.activityFeedStatus);

  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      await dispatch(editEvent(params?.id));
    };

    fetchData();
  }, [dispatch, params?.id]);

  // photos gallery
  const handlePhotos = (data) => {
    const userPhotos = [{
      url: `${data?.attachment?.base_url}${data?.attachment?.attachment_medium_url}`,
      type: 'photo',
      altTag: 'image',
    }];

    setUserPhotosData(userPhotos);
    setIsOpenImage(true);
  };

  const checkUrlWithHttpsAndHttp = (string) => {
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return regex.test(string);
  };

  let newDescription = data?.description || '';
  if (newDescription) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const match = newDescription.match(urlRegex);
    if (match && match[0]) {
      const url = match[0];
      newDescription = newDescription.replace(urlRegex, `<a target="_blank" href="${url}">${url}</a>`);
    }
  }
  
  return (
    <div className="discover_page">
      <div className="page_top_form">
        <div className="flex justify-between items-center">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left pr-2" aria-hidden="true"></i>
            {lang?.Event_View}
          </Link>
          {/* <div className="editProfileBtn">
                        <Link to="#" className='btn btnPrimary'>Save</Link>
                    </div> */}
        </div>
      </div>
      {editEventSpinner ? (
        <FormSpinner />
      ) : (
        <div className="discover__events create">
          <form>
            <div className="createForm mt-4">
              <div className="xl:w-2/5 lg:w-2/5 md:w-full sm:w-full">
                <div className="colLeftBlock mb-4">
                  <div className="ViewImg" onClick={() =>handlePhotos(data)}>
                    <img
                      src={
                        data && data.attachment
                          ? `${data.attachment.base_url}${data.attachment.attachment_medium_url}`
                          : 'https://alysei.s3.us-west-1.amazonaws.com/uploads/2022/02/844631643978317.jpeg'
                      }
                      alt="Event Image"
                    />
                  </div>
                </div>
                <div className="inline_label">
                  <div className="form__item">
                    {/* <label>Event Name</label> */}
                    <p className="forINput w-full font-bold text-blue font-18">
                      {data && data.event_name}
                    </p>
                  </div>
                </div>
                <div className="inline_label">
                  <div className="form__item">
                    <label>{lang?.Host_Name}</label>
                    <p className="forINput preField w-full">
                      {data && data.host_name}
                    </p>
                  </div>
                </div>
                <div className="inline_label">
                  <div className="form__item">
                    <label>{lang?.Location}</label>
                    <p className="forINput preField w-full">
                      {data && data.location}
                    </p>
                  </div>
                </div>
                <div className="inline_label">
                  <div className="form__item">
                    <label>{lang?.Website}</label>
                    <a
                      className="forINput preField w-full"
                      href={data?.website}
                      target="_blank"
                    >
                      {data?.website}
                    </a>
                  </div>
                </div>
              </div>
              <div className="xl:w-3/5 lg:w-3/5 md:w-full sm:w-full">
                <div className="colRightBlock xl:pl-4 lg:pl-4 md:pl-0 sm:pl-0">
                  <div className="inline_label">
                    <div className="form__item">
                      <label>{lang?.Date}</label>
                      <p className="forINput preField w-full">
                        {moment(data?.date).format('MMM D, YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="inline_label">
                    <div className="form__item">
                      <label>{lang?.Time}</label>
                      <p className="forINput preField w-full">
                        {moment(data?.time, 'HH:mm').format('hh:mm A')}
                      </p>
                    </div>
                  </div>
                  <div className="inline_label">
                    <div className="form__item">
                      <label>
                        {lang?.Event} {lang?.Type}
                      </label>
                      <p className="forINput preField w-full">
                        {data?.event_type === 'private' ? lang?.Private : lang?.Public}
                      </p>
                    </div>
                  </div>
                  <div className="inline_label">
                    <div className="form__item">
                      <label>{lang?.Event_Registration_Type}</label>
                      <p className="forINput preField w-full">
                        {data?.registration_type === 'free' ? lang?.Free : data?.registration_type === 'paid' ? lang?.Paid : lang?.buy_invitation}
                      </p>
                    </div>
                  </div>
                  <div className="inline_label">
                    <div className="form__item">
                      <label>{lang?.Event_Description}</label>
                      <p style={{ whiteSpace: 'pre-wrap' }} className="forINput preField w-full textArea h-12" dangerouslySetInnerHTML={{ __html: newDescription }}></p>
                      {/* <p className="forINput preField w-full textArea h-12">
                        {data?.description}
                        {newContent}
                      </p> */}
                    </div>
                  </div>
                  {data?.url !== null && (
                    <div className="inline_label">
                      <div className="form__item">
                        <label>{lang?.Booking_Url}</label>
                        <p className="forINput preField w-full">
                          <a href={checkUrlWithHttpsAndHttp(data?.url) ? data?.url : `https://${(data?.url)}`} target="_blank">
                            {data?.url ? data?.url : ''}
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

       {/* user photos view */}
       {isOpenImage && (
        <div className="image--light--pop">
          <ReactImageVideoLightbox
            data={userPhotosData}
            startIndex={imageIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setIsOpenImage(false);
              setUserPhotosData([]);
              setImageIndex(0);
            }}
          />
        </div>
      )}
    </div>
  );
}
