import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../assets/images/logo.png';

export default function SideBar() {
    const location = useLocation();
  return (
    <>
        <div className='sideBar'>
            <div className="top_left-header site-align">
                <div className="site__logo">
                    <Link to="/buyer-dashboard">
                        <img src={logo} alt="" />{' '}
                    </Link>
                </div>
            </div>
            <ul>
                <li>
                    <Link to="/buyer-dashboard" className={location.pathname === "/buyer-dashboard" ? "Active" : ""}>
                        <span className='Icon'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20 14H4C2.895 14 2 13.105 2 12C2 10.895 2.895 10 4 10H20C21.105 10 22 10.895 22 12C22 13.105 21.105 14 20 14Z" fill="#000"/>
                                <path d="M20 7H4C2.895 7 2 6.105 2 5C2 3.895 2.895 3 4 3H20C21.105 3 22 3.895 22 5C22 6.105 21.105 7 20 7Z" fill="#000"/>
                                <path d="M20 21H4C2.895 21 2 20.105 2 19C2 17.895 2.895 17 4 17H20C21.105 17 22 17.895 22 19C22 20.105 21.105 21 20 21Z" fill="#000"/>
                            </svg>
                        </span>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link to="/create-request" className={location.pathname === "/create-request" ? "Active" : ""}>
                        <span className='Icon'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.8086 0C21.6522 0 21.496 0.0601875 21.377 0.179688L20.1543 1.40039L22.5996 3.8457L23.8203 2.62305C24.0593 2.38405 24.0593 1.99777 23.8203 1.75977L22.2402 0.179688C22.1207 0.0601875 21.965 0 21.8086 0ZM19.2891 2.26562L12 9.55664V12H14.4434L21.7344 4.70898L19.2891 2.26562ZM5 3C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V8.27148L19.002 10.2715V19H5V9H9.72852L11.7285 7H5V5H13.7285L15.7285 3H5Z" fill="black"/>
                            </svg>
                        </span>
                        <span>Create Request</span>
                    </Link>
                </li>
                <li>
                    <Link to="/recent-request" className={location.pathname === "/recent-request" || location.pathname === "/view-recent-request" ? "Active" : ""}>
                        <span className='Icon'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M4 4C2.895 4 2 4.895 2 6V16C2 17.105 2.895 18 4 18H0V20H24V18H20C21.105 18 22 17.105 22 16V5.99414C22 4.89014 21.105 4 20 4H4ZM4 6H20L20.002 16H4V6ZM14 8V14H16V8H14ZM11 10V14H13V10H11ZM8 12V14H10V12H8Z" fill="black"/>
                            </svg>
                        </span>
                        <span>Recent Requests</span>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/buyer-notification" className={location.pathname === "/buyer-notification" ? "Active" : ""}>
                        <span className='Icon'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 2C11.172 2 10.5 2.672 10.5 3.5V4.19531C7.91318 4.86209 6 7.2048 6 10V16L4 18V19H10.2695C10.0934 19.3039 10.0005 19.6488 10 20C10 20.5304 10.2107 21.0391 10.5858 21.4142C10.9609 21.7893 11.4696 22 12 22C12.5304 22 13.0391 21.7893 13.4142 21.4142C13.7893 21.0391 14 20.5304 14 20C13.9989 19.6486 13.9053 19.3037 13.7285 19H20V18L18 16V10C18 7.2048 16.0868 4.86209 13.5 4.19531V3.5C13.5 2.672 12.828 2 12 2ZM12 6C14.206 6 16 7.794 16 10V16V16.8281L16.1719 17H7.82812L8 16.8281V16V10C8 7.794 9.794 6 12 6Z" fill="black"/>
                            </svg>
                        </span>
                        <span>Notifications</span>
                    </Link>
                </li> */}
                <li>
                    <Link to="/buyer-settings" className={location.pathname === "/buyer-settings" || location.pathname === "/change-password" || location.pathname === "/change-contact-info" || location.pathname === "/delete-account" ? "Active" : ""}>
                        <span className='Icon'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M9.66602 2L9.17578 4.52344C8.35161 4.83425 7.59479 5.26993 6.93164 5.81445L4.50781 4.97852L2.17188 9.02148L4.11328 10.709C4.03865 11.1672 4 11.5918 4 12C4 12.4088 4.03981 12.8326 4.11328 13.291V13.293L2.17188 14.9805L4.50781 19.0215L6.92969 18.1875C7.5929 18.7323 8.35143 19.1656 9.17578 19.4766L9.66602 22H14.334L14.8242 19.4766C15.6489 19.1655 16.4049 18.7306 17.0684 18.1855L19.4922 19.0215L21.8262 14.9805L19.8867 13.291C19.9614 12.8328 20 12.4082 20 12C20 11.5925 19.9611 11.1684 19.8867 10.7109V10.709L21.8281 9.01953L19.4922 4.97852L17.0703 5.8125C16.4071 5.26768 15.6486 4.83443 14.8242 4.52344L14.334 2H9.66602ZM11.3145 4H12.6855L13.0742 6L14.1172 6.39453C14.7459 6.63147 15.3107 6.95675 15.8008 7.35938L16.6641 8.06641L18.5859 7.40625L19.2715 8.5918L17.7363 9.92773L17.9121 11.0273V11.0293C17.9733 11.4042 18 11.7188 18 12C18 12.2812 17.9733 12.5957 17.9121 12.9707L17.7344 14.0703L19.2695 15.4062L18.584 16.5938L16.6641 15.9316L15.7988 16.6406C15.3087 17.0432 14.7459 17.3685 14.1172 17.6055H14.1152L13.0723 18L12.6836 20H11.3145L10.9258 18L9.88281 17.6055C9.25415 17.3685 8.68933 17.0432 8.19922 16.6406L7.33594 15.9336L5.41406 16.5938L4.72852 15.4082L6.26562 14.0703L6.08789 12.9746V12.9727C6.02762 12.5961 6 12.2807 6 12C6 11.7188 6.02674 11.4043 6.08789 11.0293L6.26562 9.92969L4.72852 8.59375L5.41406 7.40625L7.33594 8.06836L8.19922 7.35938C8.68933 6.95675 9.25415 6.63147 9.88281 6.39453L10.9258 6L11.3145 4ZM12 8C9.80348 8 8 9.80348 8 12C8 14.1965 9.80348 16 12 16C14.1965 16 16 14.1965 16 12C16 9.80348 14.1965 8 12 8ZM12 10C13.1115 10 14 10.8885 14 12C14 13.1115 13.1115 14 12 14C10.8885 14 10 13.1115 10 12C10 10.8885 10.8885 10 12 10Z" fill="black"/>
                            </svg>
                        </span>
                        <span>Settings</span>
                    </Link>
                </li>
            </ul>
        </div>
    </>
  )
}
