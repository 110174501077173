import React, { Fragment, useEffect, useState } from "react";

import HubsSearch from "./HubsSearch";
import ImporterSearch from "./ImporterSearch";
import RestaurantSearch from "./RestaurantSearch";
import VoiceExpertSearch from "./VoiceExpertSearch";
import TravelAgencies from "./TravelAgencies";
import ProducerSearch from "./ProducerSearch";
import auth from "../../../helpers/auth";
import constant from "../../../helpers/constant";
import { setProfileTabIndex } from "../../profile/modules/profile";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../../config/firebase";
import { Redirect } from "react-router-dom";

export default function B2B() {
  const dispatch = useDispatch();
  const [typeOfUser, setTypeOfUser] = useState("");
  const [alyseiReview, setAlyseiReview] = useState(false);

  const { profileData } = useSelector((state) => state.memberProfileData);

  useEffect(async () => {
    document.body.classList.remove("hidePageScroll");
    const userType = await auth.getUserType()
    await setTypeOfUser(JSON.parse(userType));
    await dispatch(setProfileTabIndex(0));
  }, []);

  useEffect(async () => {
    const userId = await profileData?.data?.user_data?.user_id
    if (userId) {
      const fetchUserNode = firebase.database().ref("users").child(userId)
      fetchUserNode.on("value", async (snapshot) => {
        const response = snapshot.val();
        await setAlyseiReview(response?.alysei_approval);
      });
    }
  }, [profileData?.data?.user_data?.user_id, alyseiReview, localStorage.getItem("alysei_review")]);

  return (
    <Fragment>
      {(JSON.parse(auth.getUserType())?.role_id !== constant.rolesId.voyagers) && localStorage.getItem("alysei_review") != true && (alyseiReview === 'false' || alyseiReview === false)
        ? <Redirect to='/membership' />
        :
        <div className="home__page b2b hubMember">
          {/* Hubs section */}
          <HubsSearch />
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <hr />}

          {/* Producer section */}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <ProducerSearch />}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <hr />}

          {/* Importers & Distributors section */}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <ImporterSearch />}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <hr />}

          {/* Italian Restaurants section */}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <RestaurantSearch />}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <hr />}

          {/* Italian Voice of Expert section */}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <VoiceExpertSearch />}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <hr />}

          {/* Italian Travel Agencies section */}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <TravelAgencies />}
          {typeOfUser?.role_id != constant?.rolesId?.voyagers && <hr />}

        </div>
      }
    </Fragment>
  );
}
