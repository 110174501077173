import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import MultiSelecter from '../../../components/MutiSelect/MultiSelect';
import FormSpinner from '../../../components/Spinners/FormSpinner';
import cardbg1 from '../../../assets/images/img1.png';
import {
  fetchSelectedHubs,
  fetchStateOfCountry,
} from '../modules/B2B';
import language from '../../../helpers/language';

export default function HubsSearch() {
  const dispatch = useDispatch();
  const [stateSelectedList, setStateselectedList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [country, setCountry] = useState('usa');
  const [lang] = useState(language.getLang());
  const [hubsearchType, setHubSearchType] = useState(3);
  const myItalianHubsRef = useRef(null);
  const { hubsSpinner, getStatesData, getSelectedHubsData } = useSelector(
    (state) => state.b2bState
  );

  useEffect(async () => {
    // await dispatch(fetchAllHubs());
    await dispatch(fetchStateOfCountry(country));
    document.getElementById('search_input').placeholder = lang?.select;
  }, []);

  useEffect(async () => {
    getSelectedHubsData?.length === 0 && (await getSelectedHubs());
  }, []);

  const getSelectedHubs = async () => {
    const searchHubs = {
      searchType: hubsearchType,
      keyword: keyword,
      state: stateSelectedList,
    };
    await dispatch(fetchSelectedHubs(searchHubs));
  };

  const handleHubSearch = async (event) => {
    event.preventDefault();
    await getSelectedHubs();
  };
  const handleMultiSelect = async (id, selectedList) => {
    const arr = [];
    (await selectedList) && selectedList.map((item) => arr.push(item.id));
    setStateselectedList(arr);
  };

  return (
    <div className="servics_blocks">
      <div className="site-align top-align">
        {/* Left Block */}
        <div className="left__section-block sm:mb-2 md:mb-2">
          <div className="card card--block">
            <div className="hubsTitle">
              <h4 className="font-18">{lang?.Hubs}</h4>
            </div>
            <div className="card-form p-3">
              <h6 className="font-14 pb-3">{`${lang?.Search} ${lang?.Hubs}`}</h6>
              <form onSubmit={(event) => handleHubSearch(event)}>
                <div className="form__item">
                  <input
                    type="text"
                    placeholder={lang?.KeywordSearch}
                    value={keyword}
                    onChange={(event) => setKeyword(event.target.value)}
                  />
                </div>
                <div className="form__item">
                  <div className="select--field">
                    <MultiSelecter
                      displayValue={'name'}
                      placeholder={lang?.select}
                      Options={getStatesData}
                      label={lang?.SelectState}
                      required={'no'}
                      fireData={(id, selectedList) =>
                        handleMultiSelect(id, selectedList)
                      }
                      ref={myItalianHubsRef}
                    />
                  </div>
                </div>
                <div className="fomr-actions">
                  <button className="btn btnBgPrimary" type="submit">
                    {' '}
                    {lang?.Search}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Middle long Block */}
        {hubsSpinner ? (
          <FormSpinner />
        ) : (
          <div className="servics_blocks middle-long__section-block middleBlockBg">
            <div className="">
              <div className="medium_title">
                {' '}
                <h1 className="font-18 text-black">{lang?.Hubs}</h1>{' '}
              </div>
              <div className="card--section site-align top-align flex-wrap">
                {getSelectedHubsData?.errors && (
                  <p className="font-18 text-black text-center w-full p-16">
                    {getSelectedHubsData.errors.exception}
                  </p>
                )}
                {getSelectedHubsData?.data?.data?.map((item, index) => {
                  return (
                    <div className="card card--block" key={index}>
                      <div className={`${item?.is_selected ? "hubImg myHub" :"hubImg"}`}>
                        <img
                          src={
                            item.image
                              ? `${item.image.base_url}${item.image.attachment_url}`
                              : cardbg1
                          }
                          alt="Card Image"
                        />
                      </div>
                      <div className="card-form p-3">
                        <div className="address text-center p-0">
                          {' '}
                          <a href="javascript:void(0)">{item.title}</a>
                        </div>
                        <div className="address text-center p-0">
                          <span>
                            {/* <strong>{lang?.Location}:</strong> */}
                            <span className="inner_text">
                              {`${item?.state?.name}`}{' '}
                            </span>
                            {/* <span className="inner_text">{`${item?.country?.name}`}</span> */}
                          </span>
                        </div>
                        <div className="view__hub__btn">
                          {/* <Link to={`${"/viewhub"}/${item.id}`} onClick={() => localStorage.setItem("selectedhub", JSON.stringify(item))} >{`${lang?.View} ${lang?.Hubs}`}</Link> */}

                          <Link
                            to={`${'/viewhub'}/${item.id}`}
                          >{`${lang?.View} ${lang?.Singular_Hub}`}</Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
